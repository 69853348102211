import { ComponentStyleConfig } from "@chakra-ui/react";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 700,
    lineHeight: "14px",
    height: "45px",
    // textTransform: "uppercase",
    fontSize: "14px",
    fontHeight: "100%",
    letterSpacing: "0.015em",
    borderRadius: "8px",
    padding: "0px 16px",
  },
  variants: {
    primary: {
      fontFamily: "Gotham-bold",
      fontSize: "14px",
      border: "none",
      height: "45px",
      bgColor: "sys.primary",
      color: "solid.white",
      _hover: {
        bgColor: "primary.20",
      },
    },
    secondary: {
      fontFamily: "Gotham-bold",
      height: "45px",
      fontSize: "14px",
      border: "none",
      bgColor: "sys.primaryContainer",
      color: "sys.onPrimaryContainer",
      _hover: {
        bgColor: "sys.primary",
        color: "solid.white",
      },
    },
    outline: {
      fontFamily: "Gotham-bold",
      height: "45px",
      fontSize: "14px",
      border: "1px solid",
      borderColor: "solid.primary",
      bgColor: "none",
      color: "solid.primary",
      _hover: {
        bgColor: "sys.primary",
        color: "solid.white",
      },
    },
    textOnly: {
      fontFamily: "Gotham-bold",
      height: "45px",
      fontSize: "14px",
      _hover: {
        // bgColor: "sys.primary",
        // color: "solid.white",
      },
    },
  },
};
