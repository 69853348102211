import { Flex, ButtonGroup, Button, TabPanel } from "@chakra-ui/react"
import { KanbanColumn } from "../KanbanColumn";
import { checkCollaboratorPermission } from "../../../Users/utils/checkPermission";
import { IResponse } from "../../../../../_services/interface/user.interface";
import { BulkFileModal } from "../../modals/BulkFileModal";
import { ICardCompany, ICardList } from "../../interfaces/ICardList";
import useLoginFormStore from "../../../../../store/useLoginFormStore";
import { ReloadIcon } from "../../Icons/Reload";
import { FiltersCards } from "./FiltersCards";
import { useEffect, useState } from "react";
import moment from "moment";
import { AddIcon } from '@chakra-ui/icons'
import {
  ChooseGenericOrAssociatedCard
} from '../../modals/Task/ChooseGenericOrAssociatedCard'
import { IGetCards } from "../../../../../_services/interface/kanban.interface";
import { ECardStatus, ECompanyStatus } from "../../../../../_services/enum/irs.enum";

interface ICards {
  guid_client: string;
  kanbanData: ICardList[];
  isLoading: boolean;
	flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterData: React.Dispatch<React.SetStateAction<IGetCards | undefined>>
}

export const CardsTabPanel = (props: ICards) => {
    const { validate } = useLoginFormStore();
    const [kanbanData, setKanbanData] = useState<ICardList[]>([]);
    const [filteredCards, setFilteredCards] = useState<ICardList[]>([]);
    const [isLoading, setLoadingState] = useState<boolean>(true);
    const [refreshData, setRefreshData] = useState<boolean>(true);

    //Columns
    const [cardsInDue, setCardsInDue] = useState<ICardList[]>([]);
    const [cardsConcluded, setCardsConcluded] = useState<ICardList[]>([]);
    const [cards15Days, setcCards15Days] = useState<ICardList[]>([]);
    const [cards30Days, setcCards30Days] = useState<ICardList[]>([]);
    const [cards7Days, setcCards7Days] = useState<ICardList[]>([]);
    const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState(false);
    /**
     * Everytime the filter get changed the system will then do the separation for the columns
    */
    useEffect(() => {
      setLoadingState(true);
      let cardPool = [...filteredCards];

      //Remove all the cards that are already concluded
      const completedCards: ICardList[] = [];
      cardPool.forEach((x) => {
        let isCompleted = true;
        if (x.guid_obligation) {
          x.companies.forEach((_c) => {
            if (_c.status !== ECompanyStatus.concluded) {
              isCompleted = false;
            }
          });
        } else if (x.guid_card_structure) {
          if (x.status !== ECardStatus.DONE) {
            isCompleted = false
          }
        }

        if (isCompleted) {
          completedCards.push(x);
        }
      });
      setCardsConcluded(completedCards);
      cardPool = cardPool.filter((_x) => !completedCards.map((x) => x.guid_card).includes(_x.guid_card));

      //Now remove all cards that are in due
      setCardsInDue(cardPool.filter((_x) => moment.utc(_x.dueDate).utc().diff(moment.utc(), 'days') < 0));
      cardPool = cardPool.filter((_x) => moment.utc(_x.dueDate).utc().diff(moment.utc(), 'days') >= 0);

      //Now remove all cards that are less than 7 days
      setcCards7Days(cardPool.filter((_x) => moment.utc(_x.dueDate).utc().diff(moment.utc(), 'days') < 7));
      cardPool = cardPool.filter((_x) => moment.utc(_x.dueDate).utc().diff(moment.utc(), 'days') >= 7);

      //Now remove all cards that are less than 15 days
      setcCards15Days(cardPool.filter((_x) => moment.utc(_x.dueDate).utc().diff(moment.utc(), 'days') < 15));
      cardPool = cardPool.filter((_x) => moment.utc(_x.dueDate).utc().diff(moment.utc(), 'days') >= 15);

      //Now remove all cards that are less than 30 days
      setcCards30Days(cardPool.filter((_x) => moment.utc(_x.dueDate).utc().diff(moment.utc(), 'days') < 30));
      cardPool = cardPool.filter((_x) => moment.utc(_x.dueDate).utc().diff(moment.utc(), 'days') >= 30);

      setLoadingState(false);
    }, [filteredCards]);

    useEffect(() => {
      setKanbanData(props.kanbanData);
    }, [props.kanbanData]);

    useEffect(() => {
      setLoadingState(props.isLoading);
    }, [props.isLoading]);

    //Return all the companies
    const companySelection = (): ICardCompany[] => {
      const filteredCompanies: ICardCompany[] = [];
      if (kanbanData) {
        kanbanData.forEach((e) => {
          e.companies.forEach((c) => {
            filteredCompanies.push(c);
          });
        });

        return filteredCompanies.filter((elem, index, self) => self.findIndex(
          (t) => { return (t.name === elem.name && t.guid_company === elem.guid_company) }) === index);
      }

      return [];
    }

    useEffect(() => {
      if(refreshData) {
        props.flushHook(refreshData);
        setRefreshData(false);
      }
    }, [refreshData])

    return (
      <>
        <ChooseGenericOrAssociatedCard
          isOpen={isCreateTaskModalOpen}
          onClose={() => setCreateTaskModalOpen(false)}
          guid_client={props.guid_client}
          flushHook={setRefreshData}
        />

        <TabPanel height="100%"> 
          <Flex flexDirection="column" gap={4} height="100%" >
            <Flex flexDirection="row" justifyContent="space-between" >
              {checkCollaboratorPermission(validate as IResponse, props.guid_client, 'kanban.write') ? (
                <>
                  <Flex>
                    <BulkFileModal guid_client={props.guid_client} kanbanData={kanbanData.filter((card) => !card.guid_card_structure)} flushHook={setRefreshData} />
                  </Flex> 
                  <Flex>
                    <ButtonGroup size="sm" mr="10px" mt="24px" color="white">
                      <Button
                        leftIcon={<AddIcon />}
                        bgColor="#4B4EFF"
                        color="white"
                        onClick={() => setCreateTaskModalOpen(true)}
                        _hover={{ bg: '#282be0' }}
                      >
                        Criar nova tarefa
                      </Button>
                    </ButtonGroup>

                    <ButtonGroup size="sm" mt="24px" color="white">
                      <Button leftIcon={<ReloadIcon />} bgColor="#4B4EFF" color="white" onClick={() => setRefreshData(true)} _hover={{ bg: '#282be0' }}>
                        Atualizar
                      </Button>
                    </ButtonGroup>
                  </Flex>
                </>
              ) : null}
            </Flex>

            <FiltersCards
              guid_client={props.guid_client}
              kanbanData={kanbanData}
              setFilteredCards={setFilteredCards}
              reinitFilter={refreshData}
              setFilterData={props.setFilterData}
            />

            <Flex flexGrow={1} gap={2} justifyContent="space-between" style={{ overflow: "hidden" }}>
              <KanbanColumn title="em 30 dias" color="#52b26f"
                companyList={companySelection()}
                flushHook={setRefreshData}
                guid_client={props.guid_client}
                data={cards30Days}
                isLoading={isLoading}
              />

              <KanbanColumn title="em 15 dias" color="#ff9a56" 
                companyList={companySelection()}
                flushHook={setRefreshData}
                guid_client={props.guid_client}
                data={cards15Days}
                isLoading={isLoading}
              />

              <KanbanColumn title="em 7 dias" color="#f56300"
                companyList={companySelection()}
                flushHook={setRefreshData}
                guid_client={props.guid_client}
                data={cards7Days}
                isLoading={isLoading}
              />

              <KanbanColumn title="Atrasado" color="#D65151"
                companyList={companySelection()}
                flushHook={setRefreshData}
                guid_client={props.guid_client}
                data={cardsInDue}
                isLoading={isLoading}
              />

              <KanbanColumn title="Concluído" color="#4B4EFF"
                companyList={companySelection()}
                flushHook={setRefreshData}
                guid_client={props.guid_client}
                data={cardsConcluded}
                isLoading={isLoading}
              />
            </Flex>
          </Flex>
        </TabPanel>
      </>
    );
}

