import { useDisclosure, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Image, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Pagination } from "../../../../components/Table/Pagination";
import { ICollaborators } from "../../../../_services/interface/company.interface";
import { IHistory, IUser } from "../../../../_services/interface/user.interface";
import { getHistory } from "../../../../_services/user.service";
import { organizeText } from "../utils/user.functions";

interface IHistoryModal {
  guid_client: string;
  user: IUser|undefined;
  openModal: boolean;
  openModalHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HistoryModal = (props: IHistoryModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allHistory, setAllHistory] = useState<IHistory[]>([]);
  const toast = useToast();

  //Pagination
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginatedFilter, setPaginatedFilter] = useState<IHistory[]>([]);
  const itemsPerPage = 5;

  const checkHistory = async (user: IUser) => {
      const { status, response } = await getHistory(props.guid_client, user.guid_user);
      if (status === 200) {
        const resp:IHistory[] = response as IHistory[];
        setTotalPages(Math.ceil(resp.length / itemsPerPage));
        setAllHistory(response as IHistory[]);
      } else {
        toast({
          title: "Não foi possível retornar o Histórico deste usuário.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
  };

  useEffect(() => {
    if (props.openModal && props.user) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  useEffect(() => {
    //Define the pointers
    const ptr1 = (currentPage - 1) * itemsPerPage;
    const ptr2 = ptr1 + itemsPerPage;
    setPaginatedFilter(allHistory.slice(ptr1, ptr2));
    //setPaginatedFilter(currentFilter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allHistory, currentPage]);
  
  useEffect(() => {
    if (isOpen && props.user) {
      checkHistory(props.user);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, props.user]);

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Histórico de Atividade</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column">
              <TableContainer height={320} border="1px" borderColor="gray.200" borderRadius="6px" >
                <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
                  <Thead>
                    <Tr>
                      <Th textTransform="none" fontFamily="Poppins-Medium">Atividade</Th>
                      <Th textAlign="center" width={100} textTransform="none" fontFamily="Poppins-Medium">Data</Th>
                    </Tr>
                  </Thead>
                  <Tbody alignItems="center" justifyContent="center">
                    {paginatedFilter.map((_el, _index) => (
                      <Tr key={_index}>
                        <Td>{organizeText(_el)}</Td>
                        <Td textAlign="center">{moment.utc(_el.createdAt).fromNow()}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Pagination currentPage={currentPage} pageCallback={setCurrentPage} totalPages={totalPages} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}