import { ApiCall, ApiAuth } from "./api";
import { ICertificate, ICertificateParamsForLocation } from "./interface/certificate.interface";
import { TPremiseResponse } from "./interface/IDefaults";

export const getClientCertificates = async (guid_client: string): Promise<TPremiseResponse<ICertificate[]>> => {
  return await new ApiCall(`/certificate/list/${guid_client}`, ApiAuth()).get<ICertificate[]>();
};

export const getClientTaxCurrentAccountIntegrations = async (guid_client: string): Promise<TPremiseResponse<ICertificate[]>> => {
  return await new ApiCall(`/certificate/list/${guid_client}/TAX_CURRENT_ACCOUNT`, ApiAuth()).get<ICertificate[]>();
};

export const getCertificates = async (): Promise<TPremiseResponse<ICertificate[]>> => {
  return await new ApiCall(`/certificate`, ApiAuth()).get<ICertificate[]>();
};

export const getCertificatesTaxCurrentAccount = async (): Promise<TPremiseResponse<ICertificate[]>> => {
  return await new ApiCall(`/certificate/TAX_CURRENT_ACCOUNT`, ApiAuth()).get<ICertificate[]>();
};

export const getParamsForLocation = async (guid_client: string, type: string): Promise<TPremiseResponse<ICertificateParamsForLocation>> => {
  return await new ApiCall(`/certificate/params/${guid_client}/${type}`, ApiAuth()).get<ICertificateParamsForLocation>();
};