import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { ICompanyStateValue } from "./EditCompanyModal";
import { DashInput } from "../../../Users/components/DashInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../../components/login/button.component";

interface ICompanyStateEditForm {
  companyStateCep: string | null;
  companyStateStateReg: string | null;
}

interface IEditCompanyStateModal {
  initial: string;
  getCompanyState: (initial: string) => ICompanyStateValue;
  cepRequired: boolean;
  showCep?: boolean;
  stateRegRequired: boolean;
  showStateReg?: boolean;
  updateData: (companyData: ICompanyStateValue) => void;
}

export const EditCompanyStateModal = (props: IEditCompanyStateModal) => {
  const YupCompanyStateChangeForm = Yup.object().shape({
    companyStateCep: Yup.string()
      .nullable()
      .when([], {
        is: () => isCepRequired,
        then: Yup.string().nullable().required("CEP"),
      }),
    companyStateStateReg: Yup.string()
      .nullable()
      .when([], {
        is: () => isStateRegRequired,
        then: Yup.string().nullable().required("Inscrição Estadual"),
      }),
  });

  const resolverForm = { resolver: yupResolver(YupCompanyStateChangeForm) };
  const [requiredFieldMessage, setRequiredFieldMessage] = useState<string>("");
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [isCepRequired, setCepRequired] = useState<boolean>(props.cepRequired);
  const [isStateRegRequired, setStateRegRequired] = useState<boolean>(
    props.stateRegRequired
  );
  const [companyState, setCompanyState] = useState<ICompanyStateValue>(
    props.getCompanyState(props.initial)
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    formState: { errors },
    setValue,
    register,
    handleSubmit,
  } = useForm<ICompanyStateEditForm>(resolverForm);

  useEffect(() => {
    if (isOpen) {
      setCompanyState(props.getCompanyState(props.initial));
    }
  }, [isOpen]);

  useEffect(() => {
    if (companyState && isOpen) {
      setValue("companyStateCep", companyState.data.cep);
      setValue("companyStateStateReg", companyState.data.stateReg);
    }
  }, [companyState, isOpen]);

  useEffect(() => {
    setCepRequired(props.cepRequired);
  }, [props.cepRequired]);

  useEffect(() => {
    setStateRegRequired(props.stateRegRequired);
  }, [props.stateRegRequired]);

  useEffect(() => {
    if (isCepRequired && companyState?.data.cep === null) {
      setRequiredFieldMessage("Cep obrigatório.");
    } else if (isStateRegRequired && companyState?.data.stateReg === null) {
      setRequiredFieldMessage("Inscrição estadual obrigatório.");
    } else {
      setRequiredFieldMessage("");
    }
  }, [companyState, isCepRequired, isStateRegRequired]);

  //Check Form Errors
  useEffect(() => {
    if (errors) {
      setAccErrorMessage(
        Object.values(errors).map((_x) => _x.message) as string[]
      );
    }
  }, [errors]);

  const handleFormInfo = async (data: ICompanyStateEditForm) => {
    if (companyState) {
      const updatedCompanyState = {
        initials: companyState.initials,
        data: {
          ...companyState.data,
          cep: data.companyStateCep
            ? data.companyStateCep.replace("-", "").replace(".", "")
            : null,
          stateReg: data.companyStateStateReg
            ? data.companyStateStateReg.replace("-", "").replace(".", "")
            : null,
        },
      };
      props.updateData(updatedCompanyState);
      setCompanyState(updatedCompanyState);
      onClose();
    }
  };

  return (
    <>
      <Tooltip label={`Editar dados de ${companyState?.data.state}`}>
        <Flex
          style={{ marginLeft: "6px" }}
          width="18px"
          height="18px"
          resize="none"
        >
          <Image
            src="../icons/edit.svg"
            onClick={onOpen}
            _hover={{ cursor: "pointer" }}
          />
        </Flex>
      </Tooltip>
      {requiredFieldMessage !== "" ? (
        <Tooltip label={requiredFieldMessage}>
          <Flex
            style={{ marginLeft: "5px" }}
            width="21px"
            height="21px"
            resize="none"
          >
            <Image src={`../icons/alert.svg`} />
          </Flex>
        </Tooltip>
      ) : null}

      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="sm"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px)"
          alignItems="center"
        />
        <ModalContent
          flexGrow={1}
          flexShrink={1}
          borderRadius={8}
          borderLeft="12px solid #0263FF"
        >
          <ModalHeader fontFamily="Poppins-Medium">
            Edição de Dados de {companyState?.data.state}
          </ModalHeader>
          <ModalCloseButton />
          {isOpen ? (
            <ModalBody fontFamily="Poppins-Medium">
              {accErrorMessage.length > 0 ? (
                <Flex
                  bg="#FFCFCF"
                  color="#A93333"
                  alignItems="flex-start"
                  flexDirection="column"
                  fontSize="12px"
                  p={2}
                  borderRadius={5}
                >
                  <Text>Por favor, informe os campos obrigatórios:</Text>
                  {accErrorMessage.map((_x, _i) => (
                    <Flex key={_i}>{_x}</Flex>
                  ))}
                </Flex>
              ) : null}
              <Flex
                alignItems="stretch"
                direction="column"
                gap={2}
                flexGrow={1}
                pb={4}
              >
                <Flex direction="column" gap={2} flexGrow={1}>
                  <DashInput
                    display={isStateRegRequired || props.showStateReg ? "block" : "none"}
                    label="Inscrição Estadual"
                    placeholder="Inscrição Estadual"
                    {...register("companyStateStateReg", {
                      required: isStateRegRequired,
                    })}
                  />
                  <DashInput
                    display={isCepRequired || props.showCep ? "block" : "none"}
                    type="text"
                    maxLength={8}
                    placeholder="CEP"
                    {...register("companyStateCep")}
                  />
                </Flex>

                <Flex
                  alignItems="stretch"
                  direction="row"
                  gap={2}
                  flexGrow={1}
                  mt={4}
                  justifyContent="center"
                >
                  <FormButton onClick={handleSubmit(handleFormInfo)}>
                    Atualizar dados
                  </FormButton>
                </Flex>
              </Flex>
            </ModalBody>
          ) : null}
        </ModalContent>
      </Modal>
    </>
  );
};
