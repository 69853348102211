import { Flex, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import MiniLoading from "../../../../components/miniLoading";
import { ICNDResponse } from "../../../../_services/interface/cnd.interface";
import { ICardList } from "../../TaxKanban/interfaces/ICardList";
import { IWidgetIdent } from "../interfaces/IWidget";
import { calcPercent } from "../util/util";
import { WidgetBase } from "./WidgetBase";
import { formatCnpj } from "../../Companies/Company/util/cnpj";
import { ArrowUpDownIcon } from "@chakra-ui/icons";
import ReactPlaceholder from "react-placeholder/lib";

interface IKanbanCompanyList extends IWidgetIdent {
  data?: ICNDResponse[] | ICardList[];
  isLoading?: boolean;
  startDateFilter?: string;
  endDateFilter?: string;
  selectedTypeCompanyValues?: string[];
  selectedCompanyValues?: string[];
}

interface ICardTable {
  cnpj: string,
  guid_company: string;
  name: string;
  type?: string;
  dueCards: number;
  totalCards: number;
}

interface IFirstPass {
  cnpj: string,
  guid_company: string;
  name: string;
  type?: string;
  inDue: boolean;
}

export const KanbanCompanyList = (props: IKanbanCompanyList) => {
  const startDateFilter = props.startDateFilter;
  const endDateFilter = props.endDateFilter;
  const [kanbanData, setKanbanData] = useState<ICardList[]>([]);
  const [cardTable, setCardTable] = useState<ICardTable[]>([]);
  const [alphaOrder, setAlphaOrder] = useState<boolean>(false);
  const [sortByData, setSortByData] = useState<string>();

  const calculateCardTable = async () => {
    if(props.data) setKanbanData(props.data as ICardList[]);

    const theTable: ICardTable[] = [];
    const firstPass: IFirstPass[] = [];

    const dataAtual = new Date();
    const ano = dataAtual.getFullYear().toString();
    const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
    
    let startDateFilterObject = {
      mes: parseInt(mes),
      ano: parseInt(ano)
    };

    let endDateFilterObject = {
      mes: parseInt(mes),
      ano: parseInt(ano)
    };

    const regexDataAmericana = /^\d{4}-\d{2}-\d{2}$/;
    if (startDateFilter) {
      if (regexDataAmericana.test(startDateFilter)) {
        const [ano, mes] = startDateFilter.split('-');
        startDateFilterObject = {
          mes: parseInt(mes),
          ano: parseInt(ano)
        };
      }
    }

    if (endDateFilter) {      
      if (regexDataAmericana.test(endDateFilter)) {
        const [ano, mes] = endDateFilter.split('-');
        endDateFilterObject = {
          mes: parseInt(mes),
          ano: parseInt(ano)
        };
      }
    }

    // const dataClone = kanbanData.filter((_c) => parseInt(moment.utc(_c.dueDate).format('MM')) === parseInt(moment.utc().format('MM')));

    const dataClone = kanbanData.filter((_c) => {
      const itemDate = new Date(_c.dueDate);
      const startDate = new Date(startDateFilterObject.ano, startDateFilterObject.mes - 1);
      const endDate = new Date(endDateFilterObject.ano, endDateFilterObject.mes);
    
      return itemDate >= startDate && itemDate <= endDate;
    });


    dataClone.forEach((_card) => {
      _card.companies.forEach(_company => {
        firstPass.push({
          guid_company: _company.guid_company,
          cnpj: _company.cnpj,
          name: _company.name,
          type: _company.type,
          inDue: moment.utc(_card.dueDate).diff(moment.utc(), 'days') < 0,
        });
      })
    });

    firstPass.forEach((_f) => {
      if (theTable.length > 0) {
        const _el = theTable.find((_x) => _x.guid_company === _f.guid_company);
        if (_el) {
          _el.totalCards++;
          _el.dueCards += (_f.inDue ? 1 : 0);
        } else {
          theTable.push({
            guid_company: _f.guid_company,
            cnpj: _f.cnpj,
            name: _f.name,
            type: _f.type,
            dueCards: (_f.inDue ? 1 : 0),
            totalCards: 1,
          });
        }
      } else {
        theTable.push({
          guid_company: _f.guid_company,
          cnpj: _f.cnpj,
          name: _f.name,
          type: _f.type,
          dueCards: (_f.inDue ? 1 : 0),
          totalCards: 1,
        });
      }
    });

    let theTableFiltered: ICardTable[] = theTable;
    if (props.selectedCompanyValues && props.selectedCompanyValues.length > 0) {
      theTableFiltered = theTableFiltered.filter(data => props.selectedCompanyValues?.includes(data.guid_company))
    }

    if (props.selectedTypeCompanyValues && props.selectedTypeCompanyValues.length > 0) {
      theTableFiltered = theTableFiltered.filter(data => props.selectedTypeCompanyValues?.includes(data.type !== undefined ? data.type.toString() : ''))
    }

    //Generate the table
    setCardTable(theTableFiltered);
  }

  const sortBy = (type: string, returnValue = false, forcePage: number | undefined = undefined) => {
    const originalClone = [...cardTable]

    if (type === "company") {
      originalClone.sort((a, b) => {
        if (!alphaOrder) {
          return a.name! < b.name! ? -1 : a.name! > b.name! ? 1 : 0;
        } else {
          return a.name! > b.name! ? -1 : a.name! < b.name! ? 1 : 0;
        }
      });
    }

    if (type === "overdue") {
      originalClone.sort((a, b) => {
        if (!alphaOrder) {
          return a.dueCards! < b.dueCards! ? -1 : a.dueCards! > b.dueCards! ? 1 : 0;
        } else {
          return a.dueCards! > b.dueCards! ? -1 : a.dueCards! < b.dueCards! ? 1 : 0;
        }
      });
    } 

    if (type === "totalcards") {
      originalClone.sort((a, b) => {
        if (!alphaOrder) {
          return a.totalCards! < b.totalCards! ? -1 : a.totalCards! > b.totalCards! ? 1 : 0;
        } else {
          return a.totalCards! > b.totalCards! ? -1 : a.totalCards! < b.totalCards! ? 1 : 0;
        }
      });
    } 

    if (type === "compliance") {
      originalClone.sort((a, b) => {
        let complianceA = 100 - calcPercent(a.dueCards, a.totalCards)
        let complianceB = 100 - calcPercent(b.dueCards, b.totalCards)
        if (!alphaOrder) {
          return complianceA! < complianceB! ? -1 : complianceA! > complianceB! ? 1 : 0;
        } else {
          return complianceA! > complianceB! ? -1 : complianceA! < complianceB! ? 1 : 0;
        }
      });
    }
    setCardTable(originalClone);
  }

  useEffect(() => {
    setKanbanData(props.data as ICardList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    calculateCardTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanData, props.selectedTypeCompanyValues, props.selectedCompanyValues]);

  useEffect(() => {
    if (sortByData) {
      sortBy(sortByData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByData, alphaOrder]);

  if (props.id && props.index !== undefined) {

    return (
      <WidgetBase title="Tax Kanban - Cards por Empresas" id={props.id} index={props.index} p={0}>
        <Flex position="relative" gap={2} w="101%">
          <TableContainer fontFamily="Poppins-Light" w="102%" maxHeight="300px" overflowX="auto" overflowY="auto">
            <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
              <Thead>

                <Tr>
                  <Th onClick={() => { setAlphaOrder(!alphaOrder); setSortByData('company'); }} color={sortByData === 'company' ? '#0164ff' : 'black'} width={50} textAlign="center" textTransform="none" fontFamily="Poppins-Medium" _hover={{ cursor: "pointer" }}>
                    Empresa <ArrowUpDownIcon />
                  </Th>
                  <Th onClick={() => { setAlphaOrder(!alphaOrder); setSortByData('overdue'); }} color={sortByData === 'overdue' ? '#0164ff' : 'black'} width={50} textAlign="center" textTransform="none" fontFamily="Poppins-Medium" _hover={{ cursor: "pointer" }}>
                    Cards Atrasados <ArrowUpDownIcon />
                  </Th>
                  <Th onClick={() => { setAlphaOrder(!alphaOrder); setSortByData('totalcards'); }} color={sortByData === 'totalcards' ? '#0164ff' : 'black'} width={50} textAlign="center" textTransform="none" fontFamily="Poppins-Medium" _hover={{ cursor: "pointer" }}>
                    Total de Cards <ArrowUpDownIcon />
                  </Th>
                  <Th onClick={() => { setAlphaOrder(!alphaOrder); setSortByData('compliance'); }} color={sortByData === 'compliance' ? '#0164ff' : 'black'} width={50} textAlign="center" textTransform="none" fontFamily="Poppins-Medium" _hover={{ cursor: "pointer" }}>
                    Compliance <ArrowUpDownIcon />
                  </Th>
                </Tr>
              </Thead>
              <Tbody alignItems="center" justifyContent="center">
                {!props.isLoading && cardTable ? (
                  cardTable.length > 0 ?
                    cardTable.map((_el, _index) => (
                      <Tr key={_index}>
                        <Td>
                          <Tooltip label={formatCnpj(_el.cnpj)}>{_el.name}</Tooltip>
                        </Td>
                        <Td textAlign="center">{_el.dueCards}</Td>
                        <Td textAlign="center">{_el.totalCards}</Td>
                        <Td textAlign="center">{100 - calcPercent(_el.dueCards, _el.totalCards)}%</Td>
                      </Tr>
                    ))
                    :
                    (
                      <Tr>
                        <Td colSpan={4}>Nenhum Card Encontrado</Td>
                      </Tr>
                    )
                ) : (
                  Array.from({ length: 10 }, (_, index) => (
                    <Tr key={index}>
                      <Td>
                        <ReactPlaceholder showLoadingAnimation type='text' ready={false} rows={1}>1</ReactPlaceholder>
                      </Td>
                      <Td>
                        <ReactPlaceholder showLoadingAnimation type='text' ready={false} rows={1}>1</ReactPlaceholder>
                      </Td>
                      <Td>
                        <ReactPlaceholder showLoadingAnimation type='text' ready={false} rows={1}>1</ReactPlaceholder>
                      </Td>
                      <Td>
                        <ReactPlaceholder showLoadingAnimation type='text' ready={false} rows={1}>1</ReactPlaceholder>
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      </WidgetBase>
    );

  } else {
    return (
      <WidgetBase title="Tax Kanban - Cards por Empresas" id="loading_company_cards" index={0} p={0}>
        <Flex position="relative" gap={2} w="101%">
          <TableContainer fontFamily="Poppins-Light" w="102%" maxHeight="300px" overflowX="auto" overflowY="auto">
            <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
              <Thead>

                <Tr>
                  <Th>
                    Empresa <ArrowUpDownIcon />
                  </Th>
                  <Th>
                    Cards Atrasados <ArrowUpDownIcon />
                  </Th>
                  <Th>
                    Total de Cards <ArrowUpDownIcon />
                  </Th>
                  <Th>
                    Compliance <ArrowUpDownIcon />
                  </Th>
                </Tr>
              </Thead>
              <Tbody alignItems="center" justifyContent="center">
                {
                  Array.from({ length: 10 }, (_, index) => (
                    <Tr key={index}>
                      <Td>
                        <ReactPlaceholder showLoadingAnimation type='text' ready={false} rows={1}>1</ReactPlaceholder>
                      </Td>
                      <Td>
                        <ReactPlaceholder showLoadingAnimation type='text' ready={false} rows={1}>1</ReactPlaceholder>
                      </Td>
                      <Td>
                        <ReactPlaceholder showLoadingAnimation type='text' ready={false} rows={1}>1</ReactPlaceholder>
                      </Td>
                      <Td>
                        <ReactPlaceholder showLoadingAnimation type='text' ready={false} rows={1}>1</ReactPlaceholder>
                      </Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      </WidgetBase>
    );
  }
}