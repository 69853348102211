
import { useEffect, useState } from "react";
import { Stack, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Button, RadioGroup, Radio } from "@chakra-ui/react";
import { FormButton } from "../../../../../components/login/button.component";
import { FieldValues } from "react-hook-form";
import { ISaveEditTaskProps } from "./Interface/ISaveEditTask";

export const SaveEditTask = <T extends FieldValues>(props: ISaveEditTaskProps<T>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);

  const yesOption = async () => {
    setButtonDisabled(true);
    await props.callback();
    setTimeout(() => {
      setButtonDisabled(false);
    }, 1000);
    onClose();
  };

  const onSubmit = props.handleSubmit((data) => {
    onOpen();
  }, (errors) => {
    if (Object.keys(errors).length === 0) {
      onOpen();
    }
  });

  useEffect(() => {
    if(props.isOpen) {
      onSubmit();
      props.isOpenHook(false);
    }
  }, [props.isOpen])

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px)'
          alignItems="center"
        />

        <ModalContent
          flexGrow={1}
          flexShrink={1}
          borderLeft="12px solid var(--primary-500)"
          borderRadius={8}
        >
          <ModalHeader
            fontFamily="Poppins-Medium"
            fontSize={18}
            fontWeight={400}
          >
            Editar recorrência
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <RadioGroup
                onChange={props.saveForAllCardsHook}
                fontSize={12}
                value={props.saveForAllCards}
              >
                <Stack>
                  <Radio fontSize={12} fontWeight={400} value="0">
                    Esta tarefa
                  </Radio>

                  <Radio fontSize={12} fontWeight={400} value="1">
                    Esta e as próximas tarefas
                  </Radio>
                </Stack>
              </RadioGroup>

              <Flex
                gap={2}
                flexGrow={1}
                fontFamily="Poppins-Medium"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  bgColor="white"
                  color="#171923"
                  fontSize="14px"
                  fontWeight={400}
                  fontFamily="Poppins-Medium"
                  _hover={{ bg: 'white', textDecoration: 'underline' }}
                  onClick={onClose}
                >
                  Fechar
                </Button>

                <FormButton
                  onClick={yesOption}
                  disabled={isButtonDisabled}
                >
                  Salvar
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}