import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Checkbox, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import { Tag } from "../../../../../../components/Table/Tag";
import { ITag } from "../../../../../../components/Table/table.interface";
import './../../../../../../styles/components/AccordionItem.css';
import React, { useEffect, useState } from "react";
import { FormButton } from "../../../../../../components/login/button.component";
import { Step3ConfirmationTable } from "./Step3ConfirmationTable";

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  countCompanies: number;
  cancelBulkCreate: () => void;
  canceling: boolean;
}

export const Step3ConfirmationCancel = (props: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = () => {
    props.setIsOpen(false);
    onClose();
  }

  useEffect(() => {
    if (props.isOpen) {
      onOpen();
    }
  }, [props.isOpen]);
 

  return (
    <>
      <Modal 
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={closeModal}
        size="2xl"
        isCentered={true}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">
            <Text color="#171923" fontSize="21px">Cancelar cadastro de empresas em lote</Text>
          </ModalHeader>

          <Flex
            position="absolute"
            top="0.5rem"
            right="0.75rem"
            cursor="pointer"
            onClick={closeModal}
          >
            <Image src="/icons/close-modal.svg" />
          </Flex>

          <ModalBody>
            <Text color="#171923" fontSize="18px" fontFamily="Poppins-Medium">
              As <strong>{props.countCompanies} empresas</strong> ainda não foram cadastradas no sistema
            </Text>

            <Text color="#2D3748" fontSize="14px" fontFamily="Poppins-Medium">
              Tem certeza que deseja cancelar esse cadastro?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Flex
              width="100%"
              justifyContent="end"
              alignItems="center"
              display="flex"
              fontFamily="Poppins-Medium"
            >
              <Text
                color="#171923"
                fontSize="12px"
                mr="15px"
                cursor="pointer"
                onClick={closeModal}
              >
                Não, voltar
              </Text>

              <FormButton
                width="auto"
                color="#EF4444"
                _hover={{
                  bgColor: "#EF4444", cursor: "pointer", color:"white"
                }}
                onClick={props.cancelBulkCreate}
                disabled={props.canceling}
                isLoading={props.canceling}
              >
                Sim, cancelar
              </FormButton> 
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}