/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color?: string;
  className: any;
}

export const IconWarning = ({
  color = "#EAB308",
  className,
}: Props): JSX.Element => {
  return (
    <svg
      className={`icons-warning ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.86 2.57333L1.21333 12C1.09691 12.2016 1.03531 12.4302 1.03466 12.663C1.03401 12.8958 1.09433 13.1248 1.20962 13.327C1.32491 13.5293 1.49115 13.6978 1.6918 13.8159C1.89246 13.934 2.12053 13.9974 2.35333 14H13.6467C13.8795 13.9974 14.1075 13.934 14.3082 13.8159C14.5088 13.6978 14.6751 13.5293 14.7904 13.327C14.9057 13.1248 14.966 12.8958 14.9653 12.663C14.9647 12.4302 14.9031 12.2016 14.7867 12L9.14 2.57333C9.02115 2.3774 8.85381 2.21541 8.65413 2.10299C8.45444 1.99056 8.22916 1.9315 8 1.9315C7.77084 1.9315 7.54555 1.99056 7.34587 2.10299C7.14618 2.21541 6.97885 2.3774 6.86 2.57333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="path"
        d="M8 6V8.66667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="path"
        d="M8 11.3333H8.00667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconWarning.propTypes = {
  color: PropTypes.string,
};
