import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, ModalOverlay, useDisclosure, Textarea, FormControl, TableContainer, Table, Thead, Tr, Td, Th, Tbody, ButtonGroup, Button } from "@chakra-ui/react"
import { useState, useEffect } from "react";
import { IGetEmployeesResponse } from "../../../../_services/interface/company.interface";
import { useForm } from "react-hook-form";
import { IInviteInputs, IInviteResponse } from "../../../../_services/interface/user.interface";
import { SimpleFormError } from "../../../../components/login/formError.component";
import { postAllowUserSSO } from "../../../../_services/user.service";
import { FormButton } from "../../../../components/login/button.component";
import * as EmailValidator from 'email-validator';
import { UserTag } from "../UserPanel";
import { AddIcon } from "@chakra-ui/icons";
import { IClientResponse } from "../../../../_services/interface/client.interface";

interface IInviteUserModalProps {
    client: IClientResponse;
    flushHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AllowUserModal = (props: IInviteUserModalProps) => {
    const [smplErrorMessage, setSmplErrorMessage] = useState<string>();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [allFieldsFilled, setFieldsState] = useState<boolean>(false);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [isFieldDisabled, setFieldDisabled] = useState<boolean>(false);
    const [mailResult, setMailResult] = useState<IInviteResponse[]>([]);
    //const toast = useToast();

    const { handleSubmit, register, watch, reset } = useForm<IInviteInputs>();

    useEffect(() => {
        watch((value) => {
            if (Object.values(value).filter(_x => _x !== '')) {
                setFieldsState(true);
            } else {
                setFieldsState(false);
            }
        });
    }, [watch]);

    useEffect(() => {
        if (allFieldsFilled) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [allFieldsFilled]);

    const handleFormInfo = async (data: IInviteInputs) => {
        setSubmitDisabled(true);
        setFieldDisabled(true);
        setSmplErrorMessage('');

        const dataList: { list: {}[] } = { list: [] }
        //Separate the emails inside a list
        let emails = data.email.split(";")
        //Make the emails unique
        emails = [...new Set(emails.map(_email => _email.trim()))];

        if (emails.length <= 4) {
            //Check if the emails are in the right format
            let allEmailsOk = true;
            emails.forEach(_email => {
                if (!EmailValidator.validate(_email.trim())) {
                    allEmailsOk = false;
                } else {
                    dataList.list.push({ email: _email.trim() })
                }
            });

            if (allEmailsOk) {
                //Reset the email field
                reset({ email: '' });
                //Create ghost user.
                const { response, status } = await postAllowUserSSO(props.client.guid_client, dataList)
                if (status === 200) {
                    setMailResult(response as IInviteResponse[]);
                    } else {
                    setSmplErrorMessage('Ocorreu algum erro ao liberar os usuários, por favor, tente novamente.');
                    }
            } else {
                setSmplErrorMessage('Algum email da lista é inválido. Lembre-se de separá-los por ";" (Ponto-e-Vírgula)');
            }
        } else {
            setSmplErrorMessage('Apenas 4 (quatro) emails podem ser indicados para enviar convites.');
        }

        setSubmitDisabled(false);
        setFieldDisabled(false);
    }

    const checkResult = (data: IInviteResponse) => {
        if (data.invitationSent) {
            return "-";
        } else {
            if (data.isRegisteredInNewCompany) {
                return "Usuário vinculado com sucesso";
            }
            
            if (data.isBlocked) {
                return "Usuário Inativado";
            }

            if (data.isAlreadyRegistered) {
                return "Usuário já registrado";
            }
        }
    }

    const checkInvitationSent = (_el: IInviteResponse) => {
        if (_el.isRegisteredInNewCompany) {
            return <UserTag text="vinculado" color="#CCECC9" />
        }
        
        return _el.invitationSent ? <UserTag text="enviado" color="#CCECC9" /> : <UserTag text="não enviado" color="#FFD2D2"/>;
    }

    return (
        <>
            <ButtonGroup onClick={onOpen} size="sm" mt="24px" isAttached color="white" >
                <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" _hover={{ bg: '#282be0' }}>
                    Permitir Usuário
                </Button>
            </ButtonGroup>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"xl"} >
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
                <ModalContent flexGrow="1" flexShrink="1" borderRadius="8px" p="16px" borderLeft="12px solid #0263FF" >
                    <ModalHeader fontFamily="Poppins-Medium">Permitir Usuários</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody fontFamily="Poppins-Medium" >
                        <FormControl>
                            {smplErrorMessage ? (<SimpleFormError msg={smplErrorMessage} />) : null}
                            <Flex alignItems="stretch" direction="column" gap="8px" justifyContent="center">
                                <Text fontSize="14px">
                                    Para convidar usuários a empresa {props.client.name}, digite até 4 (quatro) emails no campo abaixo, separando-os por “;” (Ponto-e-vírgula). Emails repetidos serão tratados como únicos.
                                </Text>
                                {mailResult.length === 0 ? (
                                    <>
                                        <Textarea disabled={isFieldDisabled} h="100px" {...register("email", { required: true })} />
                                        <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled} width="100%">Convidar usuários</FormButton>
                                    </>
                                ) : (
                                    <>
                                        <Flex direction="column" fontSize="12px" flexGrow="1" flexShrink="1" mb={4} mt={4}>
                                            <TableContainer fontFamily="Poppins-Medium" flexGrow="1" flexShrink="1" borderRadius={5}>
                                                <Table variant='striped' colorScheme='gray' fontSize="12px">
                                                    <Thead>
                                                        <Tr>
                                                            <Th textTransform="none" textAlign="center" fontFamily="Poppins-Medium">Email</Th>
                                                            <Th textTransform="none" textAlign="center" fontFamily="Poppins-Medium">Status</Th>
                                                            <Th textTransform="none" textAlign="center" fontFamily="Poppins-Medium">Motivo</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody alignItems="center" justifyContent="center">
                                                        {mailResult.map((_el, _key) => (
                                                            <Tr key={_key}>
                                                                <Td textAlign="center" p={2}>{_el.email.split('@')[0]}@...</Td>
                                                                <Td textAlign="center">{checkInvitationSent(_el)}</Td>
                                                                <Td textAlign="center">{checkResult(_el)}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </TableContainer>
                                        </Flex>
                                        <FormButton onClick={() => setMailResult([])} width="100%">Liberar mais usuários</FormButton>
                                    </>
                                )}

                            </Flex>
                        </FormControl>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}