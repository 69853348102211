import { extendTheme } from "@chakra-ui/react";
import { customComponents } from "./components";

export const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        height: "100%",
      },
      "a:hover": {
        textDecoration: "none!important",
      },
    },
  },
  colors: {
    primary: {
      "10": "#001947",
      "20": "#002C72",
      "30": "#19438F",
      "40": "#375CA9",
      "60": "#6D8FDF",
      "70": "#87A9FB",
      "90": "#D8E2FF",
      "100": "#6982BF",
    },
    secondary: {
      "10": "#001F25",
    },
    tertiary: {
      "70": "#F09700",
    },
    neutral: {
      "10": "#1B1B1E",
      "20": "#303033",
      "30": "#46464A",
      "50": "#77777B",
      "60": "#8E9099",
      "70": "#ACABAF",
      "80": "#C8C6CA",
      "90": "#E4E2E6",
      "95": "#F2F0F4",
    },
    heading: {
      "900": "#000000",
    },
    solid: {
      "100": "#FFFFFF",
      primary: "#6982BF",
      white: "#FFFFFF",
    },
    error: {
      "90": "#410001",
      "100": "#FFDAD4",
    },
    outline: "#757780",
    sys: {
      outline: "#757780",
      error: "#BA1B1B",
      primary: "#375CA9",
      onPrimary: "#ffffff",
      primaryContainer: "#D8E2FF",
      onPrimaryContainer: "#001947",
      secondary: "#006878",
      onSecondary: "#FFFFFF",
    },
    readOnly: {
      surface1:
        "linear-gradient(0deg, rgba(55, 92, 169, 0.05), rgba(55, 92, 169, 0.05)), #FDFBFF",
    },
    hero: {
      producers1: "#D8E2FF",
      producers2: "#A1EFFF",
      producers3: "#FFDDB7",
    },
  },
  fonts: {
    body: "Gotham-normal",
    heading: "Gotham-bold",
  },
  textStyles: {
    headingL: {
      fontSize: ["32px", "48px"],
      fontFamily: "Gotham-bold",
      fontWeight: 700,
      lineHeight: "100%",
    },
    headingM: {
      fontSize: ["24px", "32px"],
      fontFamily: "Gotham-bold",
      fontWeight: 700,
      lineHeight: "100%",
    },
    headingXM: {
      fontSize: "18px",
      fontFamily: "Gotham-bold",
      fontWeight: 700,
      lineHeight: "100%",
    },
    headingS: {
      fontSize: ["18px", "24px"],
      fontFamily: "Gotham-bold",
      fontWeight: 700,
      lineHeight: "120%",
    },
    headingXS: {
      fontSize: "16px",
      fontFamily: "Gotham-bold",
      fontWeight: 700,
      lineHeight: "150%",
    },
    bodyL: {
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "150%",
      fontFamily: "Gotham-book",
    },
    bodyM: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "150%",
      fontFamily: "Gotham-book",
    },
    bodyS: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "100%",
      fontFamily: "Gotham-book",
    },
    bodySS: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "140%",
      fontFamily: "Gotham-book",
    },
    button: {
      fontSize: "14px",
      fontFamily: "Gotham-bold",
      lineHeight: "100%",
    },
    thinS: {
      fontSize: ["12px", "14px"],
      fontWeight: 200,
      lineHeight: "100%",
    },
    thinXS: {
      fontSize: "12px",
      fontWeight: 200,
      lineHeight: "100%",
    },
    subtitleS: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "150%",
    },
    caption: {
      small: {
        fontSize: "12px",
        fontFamily: "Gotham-normal",
        fontWeight: 700,
        lineHeight: "140%",
      },
      large: {
        fontSize: "14px",
        fontFamily: "Gotham-bold",
        lineHeight: "100%",
      },
    },
  },
  components: customComponents,
});

export const themeNew = extendTheme({
  components: {
    Button: {
      baseStyle: {
        fontWeight: 500,
      },
    },
  },
});