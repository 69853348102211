/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const IconLoadingBlack = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`icons-loading-black ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M19.9921 12.032C19.9841 14.069 19.2111 16.103 17.6571 17.657C14.5331 20.781 9.46705 20.781 6.34305 17.657C5.78705 17.101 5.33505 16.482 4.97705 15.826"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M4.00403 11.87C4.03603 9.866 4.81403 7.872 6.34303 6.343C9.46703 3.219 14.533 3.219 17.657 6.343C18.213 6.899 18.665 7.518 19.023 8.174"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M15.953 8.17501H19.488V4.63901"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M8.04702 15.825H4.51202V19.361"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
