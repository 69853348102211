import React from 'react';

interface IGroupRadiusProps {
    options: { value: string; label: string }[];
    value?: string;
    onChange?: (value: string) => void;
}

const GroupRadius: React.FC<IGroupRadiusProps> = (props) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(event.target.value);
        }
    };

    return (
        <div style={{ display: 'flex', height: '33px', backgroundColor: 'white' }}>
            {props.options.map((option, index) => (
                <React.Fragment key={option.value}>
                    <input
                        type="radio"
                        id={option.value}
                        name="groupRadius"
                        value={option.value}
                        checked={props.value === option.value}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                    />

                    <label
                        htmlFor={option.value}
                        style={{
                            cursor: 'pointer',
                            width: 120,
                            fontSize: 12,
                            display: 'flex',
                            justifyContent: 'space-around',
                            lineHeight: '16.8px',
                            fontWeight: 500,
                            padding: '5px 10px',
                            alignItems: 'center',
                            border: '1px solid #e4e8ef',
                            borderBottom: 0,
                            borderColor: '#e4e8ef',
                            borderLeft: index !== 1 ? '1px solid #e4e8ef' : 'none',
                            borderRight: index !== 1 ? '1px solid #e4e8ef' : 'none',
                            borderRadius: index === 0 ? '6px 0 0 0' : index === props.options.length - 1 ? '0 6px 0 0' : 'none',
                            backgroundColor: props.value === option.value ? '#4b4eff' : 'transparent',
                            color: props.value === option.value ? '#fff' : '#4B4EFF'
                        }}
                    >
                        {option.label}
                    </label>
                </React.Fragment>
            ))}
        </div>
    );
}

export default GroupRadius;