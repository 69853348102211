import { EFrequencyType, EPriority } from "../../../../_services/enum/irs.enum";
import {ICompanyGroup} from "../../../../_services/interface/company.interface";
import { ICardStructure } from "../../../../_services/interface/irs.interface";

export enum EDisplay {
  buttonEdit = "buttonEdit",
  ruleName = "ruleName",
}
export interface IScheduleObligation {
  guid_obligation: string;
  guid_schedule: string;
  code: string;
  title: string;
  rule: string;
  jurisdiction: string;
  frequency: string;
  scheduleDate: string;
  isCustom: false;
  createdAt: string;
}

export interface IScheduleCompany {
  company: any;
  guid_company: string;
  name: string;
  cnpj: string;
  companyCode: string;
}

export interface IScheduleList {
  guid_schedule: string;
  guid_company: string;
  rulename: string;
  autoGenerate: boolean;
  isCreated: boolean;
  isActive: boolean;
  createdAt: string;
  obligations: IScheduleObligationData[];
  obligationIds: string[];
  companies: IScheduleCompany[];
  groups: ICompanyGroup[];
  lastRun: string | null;
}

export interface IObligationList {
  createdAt: string;
  guid_schedule: string;
  month: number;
  year: number;
  darfs: IObligationResponse[];
}

export interface IScheduleDarfsSlugs {
  slug: string;
}

export interface IObligationResponse {
  guid_darf: string|null;
  guid_schedule: string|null;
  code: string | null;
  title: string|null;
  generatingFactor: string|null;
  scheduleDate: string|null;
  isDaily: boolean|null;
  isAllMonth: boolean|null;
  isDeclaration: boolean|null;
  createdAt: string|null;
  scheduleDarfsSlugs: IScheduleDarfsSlugs | null
}

export interface IObligationResponseCustom extends IObligationResponse {
  jurisdiction: TJurisdiction
  frequency: string,
  collaborators: string[],
  isDatatablesEditable: boolean,
  datatablesIdentify: string|null,
}

export type TChangeLine = "day" | "darf" | "title" | "rule" | "collab" | "origin" | "freq" | "month";
export type TJurisdiction = 'federal' | 'state' | 'municipal';

export interface IObligationModifiedList {
  guid_darf: string|null;
  day: string;
  month: string;
  code: string;
  title: string;
  rule: string;
  jurisdiction: TJurisdiction;
  generatingFactor: string | null;
  frequency: string;
  isNewLine: boolean;
  isDateRedefined: boolean;
  isWeekday: boolean;
  canEditDay: boolean;
  collaborators: string[];
  slug: string | null;
}

export interface IScheduleRegistrationInput {
  scheduleName: string;
  autoGenerate: boolean;
  fixedRule: string;
  companies: string[];
  obligations: IObligationModifiedList[];
}

export interface IScheduleObligationData {
  guid_obligation: string;
  code: string;
  title: string;
  rule: string;
  jurisdiction: string;
  frequency: string;
  scheduleDate: string;
  isCustom: boolean;
  collaborators: string[];
  day: string;
  month: string;
  canEditDay: boolean;
}

export interface IGuidCollaborator {
  guid_collaborator: string;
}

export interface IScheduleData {
  guid_schedule: string;
  rulename: string;
  autoGenerate: boolean;
  fixedRule: string;
  description?: string|null|undefined;
  isCreated: boolean;
  isActive: boolean;
  createdAt: string;
  companies: string[];
  obligations: IScheduleObligationData[];
  cardStructure?: IScheduleDataCardStruture,
  certificates?:  IScheduleDataCertificates[],
}

export interface IScheduleDataCertificates {
  certificateCity: string|null;
  certificateState: string|null;
  certificateSlug: string;
  certificateType: string;
  guidCnd: string;
  guid_company: string;
}

export interface IScheduleDataCardStruture {
  guid_card_structure: string,
  name: string,
  description: string|null,
  dueDate: Date,
  priority: EPriority,
  hasRecurrency: boolean,
  isActive: boolean,
  type: string,
  frequencyType: EFrequencyType|null;
  frequencyUnit: number|null;
  responsibles: IScheduleDataCardStruture[]
}

export interface IScheduleDataCardStruture {
  email: string,
  guid_collaborator: string,
  guid_user: string,
  name: string,
}

export interface IScheduleEdit {
  ruleName: string;
  fixedRule: string;
  isDisabled: boolean;
  autoGenerate: boolean;
  companies: string[];
  allObligations: IScheduleObligationData[];
}

export interface IOCRRequestToken {
  ticket: string;
}

export interface ISchedule {
  guid_schedule: string,
  rulename: string,
  autoGenerate: boolean,
  fixedRule: string,
  isCreated: string,
  isActive: boolean,
  deleted: boolean,
  createdAt: string,
}