import moment from "moment"
import { IKanbanResponse } from "../../../_services/interface/kanban.interface"
//import { confirmMonth } from "../CNDManager/cndUtils"

export const translateStatus = (status: string) => {
  if (status === "open") {
    return "Aberto"
  } else if (status === "rectified") {
    return "Retificado"
  } else if (status === "filled") {
    return "Preenchido"
  } else if (status === "reviewed") {
    return "Revisado"
  } else if (status === "transmitted") {
    return "Transmitido"
  } else if (status === "paidout") {
    return "Pago"
  } else if (status === "concluded") {
    return "Concluído"
  }
}

export const adjustJurisdiction = (jurisdiction: string) => {
  if (jurisdiction === "federal") {
    return "Federal"
  } else if (jurisdiction === "state") {
    return "Estadual"
  } else if (jurisdiction === "municipal") {
    return "Municipal"
  }
}

export const adjustFrequency = (frequency: string) => {
  if (frequency === "weekly") {
    return "Semanal"
  } else if (frequency === "controlled") {
    return "Controlado";
  } else if (frequency === "monthly") {
    return "Mensal"
  } else if (frequency === "quarterly") {
    return "Trimestral"
  } else if (frequency === "semiannual") {
    return "Semestral"
  } else if (frequency === "yearly") {
    return "Anual"
  } else if (frequency === "onetime") {
    return "Única"
  }
}
export const formatDate = (date: Date) => {
  return moment.utc(date).format('DD/MM/YYYY')
}

export const adjustWeekendState = (WeekendState: string) => {
  if (WeekendState === "toAntecipate") {
    return "Antecipar"
  } else if (WeekendState === "toMaintain") {
    return "Manter"
  } else if (WeekendState === "postpone") {
    return "Adiar"
  }
}

export const kanbanFilter = (select: string, filteredData: IKanbanResponse[], search: string, month: string, status: string, obligation: string, responsible: string, jurisdiction: string) => {
    /* if (select === 'Nome') {
        filteredData = filteredData.filter(data => data.name.toUpperCase().includes((search).toUpperCase()))
      }
      if (select === 'Periodicidade') {
        filteredData = filteredData.filter(data => adjustWeekendState(data.weekendState)!.toUpperCase().includes((search).toUpperCase()))
      }
    
      if (select === 'Descrição') {
        
          filteredData = filteredData.filter(data => (data.description).toUpperCase().includes((search).toUpperCase()))
      }
        
      if (month !== '') {
            filteredData = filteredData.filter(data => parseInt((data.dueDate).toString().split('-').slice(1)[0]) === confirmMonth((month)))
          }
      
    if(status !== '') {
        filteredData = filteredData.filter(data => data.statuses[0].status === status)
    }

    if(obligation !== '') {
        filteredData = filteredData.filter(data => data.frequency === obligation)
    }

    if(responsible !== '') {
        filteredData = filteredData.filter(data => data.collaborator.users.name === responsible)
    }
    
    if(jurisdiction !== '') {
        filteredData = filteredData.filter(data => data.jurisdiction === jurisdiction)
    }
      
      return filteredData
    */
}