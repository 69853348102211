import { Flex, Text, Box } from '@chakra-ui/react';
import { FileComponent } from './FileMentionStyle';

interface File {
  createdAt: Date;
  url: string;
  name?: string;
}

interface CommentCardProps {
  username: string;
  userInitials: string;
  date: string;
  mention: string;
  message: string;
  files: File[]|null;
}

const renderMessage = (message: string) => {
  const mentionPattern = /@\{(.*?)\}/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = mentionPattern.exec(message)) !== null) {
    const [fullMatch, mentionName] = match;
    const index = match.index;

    if (index > lastIndex) {
      parts.push(message.slice(lastIndex, index));
    }

    parts.push(
      <Text as="span" fontSize="12px" key={index} color="#4B4EFF">
        @{mentionName}
      </Text>
    );

    lastIndex = index + fullMatch.length;
  }

  if (lastIndex < message.length) {
    parts.push(message.slice(lastIndex));
  }

  return parts.map((part, index) => (
    typeof part === 'string' ? (
      <Text as="span" fontSize="12px" key={index}>
        {part}
      </Text>
    ) : (
      part
    )
  ));
};
const CommentCard: React.FC<CommentCardProps> = ({ username, userInitials, date, mention, message, files }) => {
  const messageLines = message.split('\n').map((line, index) => (
    <Text key={index} fontSize="12px" color="#4A5568" lineHeight="1.5">
      {renderMessage(line)}
    </Text>
  ));

  const extractFileNameFromUrl = (url: string) => {
    const decodedUrl = decodeURIComponent(url);
    const parts = decodedUrl.split('/');
    return parts[parts.length - 1];
  };

  return (
    <Box
      border="1px solid #e0e0e0"
      backgroundColor="white"
      borderRadius="8px"
      p="16px"
      fontFamily="Poppins-medium"
      w="100%"
    >
      <Flex alignItems="center">
        <Flex mr="10px">
            <Box
              width="32px"
              height="32px"
              borderRadius="50%"
              bg="#ECF1FF"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="12px"
              color="#11151F"
              fontWeight={600}
              letterSpacing={-0.1}
            >
              {userInitials}
            </Box>
        </Flex>

        <Flex direction="row" justifyContent="space-between" w={"100%"}>
          <Text fontSize="12px" fontWeight={600} color="#11151F">{username}</Text>
          <Text fontSize="12px" color="#777">{date}</Text>
        </Flex>
      </Flex>

      <Text mt="16px" fontSize="12px" color={"#4A5568"} lineHeight="1.5">
        {messageLines}
      </Text>

      { files?.length ? (
        <Flex
          marginTop="16px"
          w="100%"
          flexDirection="column"
        >
          {files?.map((file, index) => (
            <FileComponent
              key={index}
              name={file.name ? file.name : extractFileNameFromUrl(file.url)}
              url={file.url}
            />
          ))}
        </Flex>
      ) : null}
    </Box>
  );
};

export default CommentCard;
