import { ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { ICityResponse, ICountry, IRegionResponse } from "./interface/region.interface";

export const getStates = async (): Promise<TPremiseResponse<IRegionResponse[]>> => {
  return await new ApiCall('/region/states').get<IRegionResponse[]>();
}

export const getCities = async (): Promise<TPremiseResponse<ICountry[]>> => {
  return await new ApiCall('/region/cities').get<ICountry[]>();
}

export const getCityByState = async (id_state: number): Promise<TPremiseResponse<ICityResponse>> => {
  return await new ApiCall(`/region/states/${id_state}`).get<ICityResponse>();
}

interface IOption {
  value: string|number;
  label: string;
  id_state: number;
}
export const citiesForOptions = async (): Promise<TPremiseResponse<IOption[]>> => {
  return await new ApiCall(`/region/cities/for-options`).get<IOption[]>();
}