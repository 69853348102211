import { ApiAuth, ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import queryString from "query-string";
import { ICompanyGroupEditedInputs, IFilterListCompanyGroup } from "./interface/companyGroup.interface";

export const listCompanyGroup = async (guid_client: string, filters: IFilterListCompanyGroup): Promise<TPremiseResponse<any>> => {
  const queryStringParams = queryString.stringify(filters);
  const url = `/company-group/list/${guid_client}?${queryStringParams}`;
  return await new ApiCall(url, ApiAuth()).get<any>();
};

export const postEditCompanyGroup = async(guid_client: string, guid_company_group: string, formdata: ICompanyGroupEditedInputs): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company-group/edit/${guid_client}/${guid_company_group}`, ApiAuth()).put<any, ICompanyGroupEditedInputs>(formdata);
}

export const removeCompanyOfCompanyGroup = async(guid_client: string, guid_company_group: string, guid_company: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company-group/remove/${guid_client}/${guid_company_group}/${guid_company}`, ApiAuth()).post<any, any>();
}

export const getCompanyGroup = async(guid_client: string, guid_company_group: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company-group/get/${guid_client}/${guid_company_group}`, ApiAuth()).get<any>();
}

export const postCreateCompanyGroup = async(guid_client: string, formdata: ICompanyGroupEditedInputs): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company-group/create/${guid_client}`, ApiAuth()).post<any, ICompanyGroupEditedInputs>(formdata);
}

export const deleteCompanyGroup = async(guid_client: string, guid_company_group: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company-group/delete/${guid_client}/${guid_company_group}`, ApiAuth()).delete<any>();
}