import { Flex } from "@chakra-ui/react";
import { IListDataState } from "../_services/interface/region.interface";
import { stateRegionForTag } from "../components/Table/utils";
import { ICNDListDataState } from "../_services/interface/cnd.interface";

export const jurisdictionTag = (jurisdiction: string): JSX.Element => {
  if (jurisdiction === 'federal') {
    return (
      <Flex 
        color="#065666" 
        backgroundColor="#C4F1F9" 
        alignItems="center"
        justifyContent="center"
        p="2px 15px"
        fontSize="12px"
        fontFamily="Poppins-medium"
        lineHeight="16.8px"
        fontWeight="100"
        borderRadius={5}
        height="25px"
        width="fit-content"
        minWidth="auto"
      >
        Federal
      </Flex>
    );
  } else if (jurisdiction === 'state') {
    return (
      <Flex 
        color="#322659"
        backgroundColor="#E9D8FD"
        alignItems="center"
        justifyContent="center"
        p="2px 15px"
        fontSize="12px"
        fontFamily="Poppins-medium"
        lineHeight="16.8px"
        fontWeight="100"
        borderRadius={5}
        height="25px"
        width="fit-content"
        minWidth="auto"
      >
        Estadual
      </Flex>
    );
  } else if (jurisdiction === 'municipal') {
    return (
      <Flex 
        color="#134E4A"
        backgroundColor="#CCFBF1"
        alignItems="center"
        justifyContent="center"
        p="2px 15px"
        fontSize="12px"
        fontFamily="Poppins-medium"
        lineHeight="16.8px"
        fontWeight="100"
        borderRadius={5}
        height="25px"
        width="fit-content"
        minWidth="auto"
      >
        Municipal
      </Flex>
    );
  }

  return <></>;
}

export const stateRegion = (state: IListDataState | ICNDListDataState | null) => {
  if (!state) {
    return (
      <Flex w="100%"  alignItems="center" justifyContent="center" p="2px" fontSize="10px" textTransform="uppercase" fontWeight="bold" borderRadius={5}>
        -
      </Flex>
    );
  }

  const tag = stateRegionForTag(state.initials);

  return (
    <Flex w="100%" backgroundColor={tag.color} color={tag.textColor} alignItems="center" justifyContent="center" p="2px" fontSize="12px" textTransform="uppercase" borderRadius={5}>
      { state.initials }
    </Flex>
  );
}
