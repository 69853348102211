import { calcPercent, gradient } from "../util/util";
import { CountryState } from "./states";

const states = ['MA', 'PE', 'ES', 'AP', 'RJ', 'SP', 'AL', 'RS', 'PB', 'RN', 'SC', 'BA', 'RR', 'CE', 'SE', 'PR', 'AM', 'MG', 'RO', 'PI', 'MT', 'AC', 'TO', 'GO', 'DF', 'MS', 'PA'];

interface IBrazilMapProps {
  data: any;
}

export const BrazilMap = (props: IBrazilMapProps) => {
  const baseColor = "#eaeaea";
  return (
   <svg version="1.1" id="landmarks-brazil" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" width="800px" height="800px" viewBox="0 0 800 800" enableBackground="new 0 0 800 800" xmlSpace="preserve">
        {states.map((_st, _index) => {
          const found = Object.keys(props.data).find((x) => x === _st);
          if (found) {
            return <CountryState key={_index} state={_st} total={props.data[found].holding + props.data[found].subsidiary} subsidiary={props.data[found].subsidiary} holding={props.data[found].holding} baseColor={gradient('#4B4EFF', '#4B4EFF', (calcPercent(props.data[found].holding, props.data[found].subsidiary) / 100))} name={props.data[found].name} />
          } else {
            return <CountryState key={_index} state={_st} total={0} subsidiary={0} holding={0} baseColor={baseColor} />
          }
        })}
    </svg> 
  )
}