import { DeleteIcon } from "@chakra-ui/icons"
import { Flex, Text } from "@chakra-ui/react"
import { SingleFileIcon } from "../Icons/SingleFile"

interface IFileLineProps {
  isValid?: boolean;
  filename: string;
  mimetype?: string;
  size: number;
  onDelete: () => void;
}

export const FileLine = (props: IFileLineProps) => {

  const formatFileSize = (bytes: number, decimalPoint: number): string => {
    if (bytes === 0) return '0 Bytes';
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  return (
    <Flex p={2} gap={2} bg={props.isValid ? "white" : "red.50"} borderBottom="1px solid" borderColor="gray.400" alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" gap={2}>
        <Flex><SingleFileIcon boxSize="20px" color={props.isValid ? "#4B4EFF" : "red"} /></Flex>
        <Flex flexDirection="column">
          <Text>{props.filename}</Text>
          {props.isValid ? (
            <Text color="gray.400">{props.mimetype} - {formatFileSize(props.size, 1)}</Text>
          ) : (
            <Text color="red.400">Arquivo não aceito pelo o sistema</Text>
          )}
        </Flex>
      </Flex>
      <Flex>
        <Flex bg="#4B4EFF" w="20px" h="20px" alignItems="center" justifyContent="center" onClick={props.onDelete} borderRadius={3} _hover={{ bgColor: "#686AFF", cursor: "pointer" }}>
          <DeleteIcon color="white" />
        </Flex>
      </Flex>
    </Flex>
  )
}