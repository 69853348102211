import { Text } from "@chakra-ui/react";
import { IHistory } from "../../../../_services/interface/user.interface";

export const organizeText = (line: IHistory) => {
  const personaName = line.persona ? line.persona.name : '';
  const companyName = line.company ? line.company.name : '';
  const clientName = line.client ? line.client.name : '';
  switch (line.history_type) {
    case "passwordchanged":
      return (
        <Text whiteSpace="break-spaces">Mudou a própria senha.</Text>
      );
    case "companyregistration":
      return (
        <Text whiteSpace="break-spaces">Registrou a empresa <strong>{companyName}</strong></Text>
      );
    case "clientregistration":
      return (
        <Text whiteSpace="break-spaces">Registrou o cliente <strong>{clientName}</strong></Text>
      );
    case "changedpermissions":
      return (
        <Text whiteSpace="break-spaces">Alterou as permissões do usuário <strong>{line.data.affected_user}</strong> para <strong>{line.data.permissions.join(", ")}</strong></Text>
      )
    case "companyedit":
      return (
        <Text whiteSpace="break-spaces">Editou a empresa <strong>{companyName}</strong></Text>
      );
    case "clientedit":
      return (
        <Text whiteSpace="break-spaces">Editou o cliente <strong>{clientName}</strong></Text>
      );
    case "terminatecompany":
      return (
        <Text whiteSpace="break-spaces">Terminou a empresa <strong>{companyName}</strong></Text>
      );
    case "subsidiaryregistration":
      return (
        <Text whiteSpace="break-spaces">Registrou <strong>{line.data.affected_company}</strong>, subsidiaria da empresa <strong>{companyName}</strong></Text>
      );
    case "userupdated":
      return (
        <Text whiteSpace="break-spaces">Atualizou o usuário <strong>{line.data.affected_user}</strong></Text>
      );
    case "userterminated":
      return (
        <Text whiteSpace="break-spaces">Desligou o usuário <strong>{line.data.affected_user}</strong></Text>
      );
    case "inviteuser":
      return (
        <Text whiteSpace="break-spaces">Convidou o usuário <strong>{line.data.affected_user}</strong></Text>
      );
    case "userunblocked":
      return (
        <Text whiteSpace="break-spaces">Desbloqueou o usuário <strong>{line.data.affected_user}</strong></Text>
      );
    case "cndretrieved":
      return (
        <Text whiteSpace="break-spaces">Buscou a Certidão da empresa <strong>{companyName}</strong></Text>
      );
    case "registercard":
      return (
        <Text whiteSpace="break-spaces">Registrou uma nova obrigação <strong>{line.data.affected_card ? line.data.affected_card : "Sem Nome"}</strong> para <strong>{companyName}</strong></Text>
      );
    case "editcard":
      return (
        <Text whiteSpace="break-spaces">Editou a obrigação  <strong>{line.data.affected_card ? line.data.affected_card : "Sem Nome"}</strong></Text>
      );
    case "deletecard":
      return (
        <Text whiteSpace="break-spaces">Deletou a obrigação  <strong>{line.data.affected_card ? line.data.affected_card : "Sem Nome"}</strong></Text>
      );
    case "cndregistered":
      return (
        <Text whiteSpace="break-spaces">Cadastrou a Certidão <strong>{line.data.affected_cnd ? line.data.affected_cnd : "Sem Código"}</strong></Text>
      );
    case "cnddeleted":
      return (
        <Text whiteSpace="break-spaces">Deletou a Certidão <strong>{line.data.affected_cnd ? line.data.affected_cnd : ""}</strong> da {companyName ? `empresa` : `pessoa`} <strong>{companyName ? companyName : personaName}</strong></Text>
      );
    case "addedFileToCard":
      return (
        <Text whiteSpace="break-spaces">Adicionou um arquivo a obrigação  <strong>{line.data.affected_card ? line.data.affected_card : "Sem Nome"}</strong></Text>
      );
    case "removedFileFromCard":
      return (
        <Text whiteSpace="break-spaces">Removeu o arquivo <strong>{line.data.file}</strong> da obrigação  <strong>{line.data.affected_card ? line.data.affected_card : "Sem Nome"}</strong></Text>
      );
    case "changedCardStatus":
      return (
        <Text whiteSpace="break-spaces">Alterou o status da obrigação  <strong>{line.data.affected_card ? line.data.affected_card : "Sem Nome"}</strong> para <strong>{line.data.status}</strong></Text>
      );
    case "createdSchedule":
      return (
        <Text whiteSpace="break-spaces">Criou o agendamento  <strong>{line.data.affected_schedule}</strong></Text>
      );
    case "editSchedule":
      return (
        <Text whiteSpace="break-spaces">Editou o agendamento  <strong>{line.data.affected_schedule}</strong></Text>
      );
    case "companygroupedit":
      return (
        <Text whiteSpace="break-spaces">Editou grupo de empresas  <strong>{line.data.name}</strong></Text>
      );
    case "companygroupcreate":
      return (
        <Text whiteSpace="break-spaces">Criou grupo de empresas  <strong>{line.data.name}</strong></Text>
      );
    case "companygroupdelete":
      return (
        <Text whiteSpace="break-spaces">Deletou grupo de empresas  <strong>{line.data.name}</strong></Text>
      );
    case "companygroupremovecompany":
      return (
        <Text whiteSpace="break-spaces">Removeu empresa do grupo de empresas  <strong>{line.data.name}</strong></Text>
      );
    case "downloadCndZipFile":
      return (
        <Text whiteSpace="break-spaces">Baixou ZIP de Certidões  <strong>{line.data.name}</strong></Text>
      );
    case "historyCnd":
      return (
        <Text whiteSpace="break-spaces">Acessou o Histórico de certidões  <strong>{line.data.name}</strong></Text>
      );
    case "bulkCompanies":
      return (
        <Text whiteSpace="break-spaces">Cadastrou empresas em lote  <strong>{line.data.name}</strong></Text>
      );
    case "getDividaAtiva":
      return (
        <Text whiteSpace="break-spaces">Baixou lista de dividas detalhada  <strong>{line.data.name}</strong></Text>
      );
    case "configaccount":
      return (
        <Text whiteSpace="break-spaces">Editou seu perfil</Text>
      );
    case "configdisplay":
      return (
        <Text whiteSpace="break-spaces">Editou sua visualização do sistema</Text>
      );
    case "configcompany":
      return (
        <Text whiteSpace="break-spaces">Editou a configuração de empresas</Text>
      );
    case "configtaxkanban":
      return (
        <Text whiteSpace="break-spaces">Editou o produto Kanban</Text>
      );
    case "configcertificate":
      return (
        <Text whiteSpace="break-spaces">Editou o produto Certidão</Text>
      );
    case "configtaxcurrentaccount":
      return (
        <Text whiteSpace="break-spaces">Editou o produto Conta corrente fiscal</Text>
      );
    case "confignotification":
      return (
        <Text whiteSpace="break-spaces">Editou as notificações</Text>
      );
    default:
      return (
        <Text>Texto desconhecido</Text>
      );
  }
}