import { Flex } from "@chakra-ui/react";

export const SUPPORTED_FORMATS = [
  {
    mime: "application/pdf",
    name: "pdf"
  },
  {
    mime: "text/plain",
    name: "txt"
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    name: "xlxs"
  },
  {
    mime: "application/vnd.ms-excel",
    name: "xlx"
  },
  {
    mime: "application/msword",
    name: "doc"
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    name: "docx"
  }
];

export const validateFile = (fileUploadValue: FileList) => {
  if (fileUploadValue) {
    if (fileUploadValue.item(0)) {
      if (SUPPORTED_FORMATS.map((e) => e.mime).includes(fileUploadValue.item(0)?.type as string)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export const cutText = (text: string, size: number): string => {
  return text.length > size ? `${text.substring(0, size)}...` : text;
}
