import PropTypes from "prop-types";
import { IconCheckSizeMd } from "../../utils/Icons/IconCheckSizeMd";
import { IconXSizeMd } from "../../utils/Icons/IconXSizeMd";
import { IconWarning } from "../../utils/Icons/IconWarning";
import { IconInfo } from "../../utils/Icons/IconInfo";
import { IconCheck } from "../../utils/Icons/IconCheck";
import { IconX } from "../../utils/Icons/IconX";
import { IconWarningBlack } from "../../utils/Icons/IconWarningBlack";
import { IconLoadingBlack } from "../../utils/Icons/IconLoadingBlack";
import "./style.css";

interface Props {
  isClosable?: boolean;
  closableFunc?: () => void;
  descriptionHtml: string;
  clicable?: { action: () => void; text: string };
  icon?: boolean;
  title?: boolean;
  status: "warning" | "success" | "error" | "info";
  variant: "top-accent" | "solid" | "subtle" | "alert" | "left-accent";
  type: "two" | "three" | "one";
  className: any;
  justify?: "start";
}

export const Alert = ({
  isClosable = true,
  closableFunc,
  descriptionHtml,
  clicable,
  icon = true,
  title = true,
  status,
  variant,
  type,
  className,
  justify
}: Props): JSX.Element => {
  return (
    <div className={`alert variant-${variant} ${type} ${justify} ${status} ${className}`}>
      {type === "one" && (
        <>
          <>
            {icon && (
              <div className="alert-icon">
                {status === "success" && (
                  <IconCheck
                    className="icons"
                    color={
                      ["left-accent", "subtle", "top-accent"].includes(variant)
                        ? "white"
                        : variant === "solid"
                        ? "#16A34A"
                        : undefined
                    }
                  />
                )}

                {status === "warning" && (
                  <IconWarning
                    className="icons"
                    color={
                      ["left-accent", "subtle", "top-accent"].includes(variant)
                        ? "white"
                        : variant === "solid"
                        ? "#EAB308"
                        : undefined
                    }
                  />
                )}

                {status === "info" && (
                  <IconInfo
                    className="icons"
                    color={
                      ["left-accent", "subtle", "top-accent"].includes(variant)
                        ? "white"
                        : variant === "solid"
                        ? "#00A3C4"
                        : undefined
                    }
                  />
                )}

                {status === "error" && (
                  <IconX
                    className="icons"
                    color={
                      ["left-accent", "subtle", "top-accent"].includes(variant)
                        ? "white"
                        : variant === "solid"
                        ? "#DC2626"
                        : undefined
                    }
                  />
                )}
              </div>
            )}
          </>
          <div className="stack">
            {title && <div className="alert-title">Title</div>}

            <div
              className="alert-description"
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            ></div>
          </div>
        </>
      )}

      {type === "two" && (
        <div className="div">
          {icon && (
            <div className="icons-wrapper">
              {status === "success" && (
                <IconCheck
                  className="icons"
                  color={
                    variant === "solid"
                      ? "#16A34A"
                      : variant === "subtle"
                      ? "white"
                      : undefined
                  }
                />
              )}

              {status === "error" && (
                <IconX
                  className="icons"
                  color={
                    variant === "solid"
                      ? "#DC2626"
                      : variant === "subtle"
                      ? "white"
                      : undefined
                  }
                />
              )}

              {status === "warning" && (
                <IconWarning
                  className="icons"
                  color={
                    variant === "solid"
                      ? "#EAB308"
                      : variant === "subtle"
                      ? "white"
                      : undefined
                  }
                />
              )}

              {status === "info" && (
                <IconInfo
                  className="icons"
                  color={
                    variant === "solid"
                      ? "#00A3C4"
                      : variant === "subtle"
                      ? "white"
                      : undefined
                  }
                />
              )}
            </div>
          )}

          <div className="message">
            <div
              className="text-wrapper"
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            ></div>
          </div>
        </div>
      )}

      {["one", "two"].includes(type) && (
        <IconXSizeMd
          className="instance-node"
          color={
            variant === "solid" && ["error", "info", "success"].includes(status)
              ? "white"
              : "#171923"
          }
        />
      )}

      {type === "three" && (
        <>
          <div className="left">
            {status === "success" && (
              <IconCheckSizeMd className="instance-node" />
            )}

            {status === "warning" && (
              <IconWarningBlack className="instance-node" />
            )}

            {["success", "warning"].includes(status) && (
              <div className="div">
                <div
                  className="alert-title-2"
                  dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                ></div>
                {clicable ? (
                  <div className="alert-clicable" onClick={clicable.action}>
                    {clicable.text}
                  </div>
                ) : null}
              </div>
            )}

            {status === "error" && (
              <>
                <IconXSizeMd className="instance-node" color="#1A202C" />
                <div className="div">
                  <div
                    className="alert-title-2"
                    dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                  ></div>
                  {clicable ? (
                    <div className="alert-clicable" onClick={clicable.action}>
                      {clicable.text}
                    </div>
                  ) : null}
                </div>
              </>
            )}

            {status === "info" && (
              <>
                <IconLoadingBlack className="instance-node" />
                <div className="div">
                  <div
                    className="alert-title-2"
                    dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                  ></div>
                  {clicable ? (
                    <div className="alert-clicable" onClick={clicable.action}>
                      {clicable.text}
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
          <>
            {isClosable && (
              <div className="action" onClick={closableFunc}>
                <IconXSizeMd className="instance-node" color="#171923" />
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
};

Alert.propTypes = {
  isClosable: PropTypes.bool,
  icon: PropTypes.bool,
  title: PropTypes.bool,
  status: PropTypes.oneOf(["warning", "success", "error", "info"]),
  variant: PropTypes.oneOf([
    "top-accent",
    "solid",
    "subtle",
    "alert",
    "left-accent",
  ]),
  type: PropTypes.oneOf(["two", "three", "one"]),
};
