import { Flex, Modal, ModalOverlay, ModalContent, Text, ModalHeader, ModalCloseButton, ModalBody, Image, useDisclosure, Button } from "@chakra-ui/react"
import { useEffect } from "react";
import { FormButton } from "../components/login/button.component";


interface IModalConfirmProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
  callback: () => void;
  buttonConfirmText?: string;
  style?: `default`|`delete`
}

export const ModalConfirm = (props: IModalConfirmProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if(props.isOpen) {
        onOpen();
    }
  }, [props.isOpen])

  const close = () => {
    props.setIsOpen(false);
    onClose();
  }

  const yesOption = () => {
    props.callback();
    close();
  }

  return (
    <>
      {
        props.style === 'delete' 
        ? (
          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={close} size="sm">
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
            <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
              <ModalHeader fontFamily="Poppins-Medium" fontWeight={500}>{props.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody fontFamily="Poppins-Medium" fontSize={12}>
                <Flex justifyContent="center" flexDirection="column" gap={5}>
                  <Text>
                    {props.description}
                  </Text>

                  <Flex gap={2} flexGrow={1} justifyContent="flex-end">
                    <Button
                      bgColor="white"
                      color="#171923"
                      fontSize="12px"
                      height={'34px'}
                      fontFamily="Poppins-medium"
                      _hover={{ bg: 'white', textDecoration: 'underline' }}
                      onClick={close}
                    >
                      Fechar
                    </Button>
                    <FormButton 
                      onClick={yesOption} 
                      width="50%"
                      height={'34px'}
                      color="#EF4444"
                      _hover={{
                        bgColor: "#EF4444", cursor: "pointer", color:"white"
                      }}
                    >
                      {props.buttonConfirmText ?? 'sim'}
                    </FormButton>
                  </Flex>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        ) : (
          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={close} size="sm">
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
            <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
              <ModalHeader fontFamily="Poppins-Medium" fontWeight={500}>{props.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody fontFamily="Poppins-Medium" fontSize={12}>
                <Flex justifyContent="center" flexDirection="column" gap={5}>
                  <Text>
                    {props.description}
                  </Text>

                  <Flex gap={2} flexGrow={1} justifyContent="space-between">
                    <FormButton onClick={yesOption} width="50%">Sim</FormButton>
                    <FormButton onClick={close}  width="50%">Não</FormButton>
                  </Flex>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        )
      }
    </>
  )
}