export const msalConfig = {
    auth: {
        clientId: "ba45161f-b3d5-4777-aa24-e6181e583f3c",
        authority: "https://login.microsoftonline.com/a40c0d68-338e-44ef-ab17-812ee42d12c7", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "https://prd.taxly.energycorp.com/dashboard",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};