import { AttachmentIcon } from "@chakra-ui/icons";
import { Tooltip, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, useToast, Text, Input, Image, Link } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import MiniLoading from "../../../../components/miniLoading";
import { IFirstCardCompanies, IFirstCardFiles } from "../interfaces/ICard";
import { DeleteFileModal } from "../modals/DeleteFileModal";
import { SUPPORTED_FORMATS, validateFile } from "../utils/kanban.functions";
import { getCompanyFiles, putKanbanCardFile } from "../_services/kanban.service";


interface ICompanyAttachment {
  guid_client: string;
  currentCard: string;
  association: IFirstCardCompanies;
  files: number;
  disabled?: boolean;
}

export const CompanyAttachment = (props: ICompanyAttachment) => {
  const fileUpload = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);
  const [isFileLoading, setLoadingFileState] = useState<boolean>(false);
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [allFiles, setAllFiles] = useState<IFirstCardFiles[]>([]);
  const [fileCounting, setFileCounting] = useState<number>(0);
  const toast = useToast();

  const fetchData = async () => {
    setLoadingState(true);
    const { status, response } = await getCompanyFiles(props.guid_client, props.association.guid_cardCompany);
    if (status === 200) {
      setAllFiles(response as IFirstCardFiles[]);
      //Count the ammount of files
      setFileCounting((response as IFirstCardFiles[]).length);
    } else {
      toast({
        title: 'Falha ao retornar os arquivos, tente novamente mais tarde',
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
    setLoadingState(false);
    setButtonDisabled(false);
  }

  const addFileToCard = async (file: FileList) => {
    setButtonDisabled(true);
    setLoadingState(true);
    setLoadingFileState(true);
    if (validateFile(file)) {
      const fileName = file.item(0)?.name;
      const { status } = await putKanbanCardFile(props.guid_client, props.currentCard, props.association.guid_cardCompany, {
        guid_cardCompany: props.association.guid_cardCompany,
        file: file.item(0),
      });

      //Make the system clear the fileUpload input
      if (fileUpload.current) {
        fileUpload.current.value = '';
      }

      if (status === 200) {
        setLoadingFileState(false);
        fetchData();
        toast({
          title: 'Arquivo Enviado com sucesso',
          description: `Arquivo ${fileName} adicionado com sucesso`,
          status: 'success',
          isClosable: true,
          duration: 5000,
        });
      } else {
        toast({
          title: 'Falha ao inserir o arquivo',
          description: `Ocorreu uma falha ao tentar inserir o arquivo ${fileName} ao card`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    } else {
      toast({
        title: 'Formato do arquivo não permitido',
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
    }

    setButtonDisabled(false);
    setLoadingState(false);
    setLoadingFileState(false);
  }

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setFileCounting(props.files);
  }, [props.files]);

  return (
    <>
      <Tooltip label="Enviar Arquivo">
        <Flex bgColor="#4B4EFF" onClick={onOpen} fontSize="12px" h={29} p={1} borderRadius={5} color="white" alignItems="center" justifyContent="center" _hover={{ cursor: "pointer" }}>
          <AttachmentIcon />&nbsp;{fileCounting}
        </Flex>
      </Tooltip>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Arquivos Associados</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Flex alignItems="stretch" gap={2} direction="column">
                <Flex direction="column" gap={2} border="1px solid" alignItems="stretch" borderColor="gray.400" borderRadius={5}>
                  <Flex direction="column" gap={1} overflow="auto" height={150} alignItems="stretch">
                    {isLoading ? (
                      <Flex flexDirection="column" justifyContent="center" w="100%" h="100%">
                        <MiniLoading />
                      </Flex>
                    ) : allFiles.map((_el, _i) => (
                      <Flex key={_i} justifyContent="space-between" bg="gray.200" p={2} borderRadius={5}>
                        <Flex alignItems="center" gap={2}>
                          <Image src="/icons/card_files.svg" />
                          {_el.filename}
                        </Flex>
                        <Flex alignItems="center" gap={2}>
                          </Flex>
                        <Flex alignItems="center" gap={2}>
                          <Text>
                            <Tooltip label={moment.utc(_el.createdAt).format('DD/MM/YYYY HH:mm')}>
                              {moment.utc(_el.createdAt).fromNow()}
                            </Tooltip>
                          </Text>
                          <Tooltip label="Baixar arquivo">
                            <Link color="#0263FF" href={_el.address} target="_blank">
                              <Image src="../icons/download-card-file.svg" />
                            </Link>
                          </Tooltip>
                          <DeleteFileModal fetchData={fetchData} guid_client={props.guid_client} file={_el} />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
                <Input ref={fileUpload} type="file" position="absolute" left="-9999" onChange={event => addFileToCard(event.target.files as FileList)} />
                <Flex className="situations" direction="column" fontSize="12px" opacity={0.5}>O sistema apenas aceitará estes formatos: {SUPPORTED_FORMATS.map((e) => e.name).join(', ')}.</Flex><br/>
                <FormButton onClick={() => fileUpload.current?.click() } disabled={props.disabled}  isLoading={isFileLoading}>Selecionar arquivo</FormButton>
               
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}