import { createIcon } from '@chakra-ui/icons'

export const Attach = createIcon({
  displayName: 'Attach',
  viewBox: '0 0 24 24',
  path: (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6818 9.81886L10.2921 16.2085C10.1046 16.3961 9.85014 16.5016 9.58484 16.5016H7.99818C7.44566 16.5016 6.99776 16.0537 6.99776 15.5012V13.9146C6.99785 13.649 7.102 13.394 7.28788 13.2043L14.3208 6.01127C15.0466 5.26887 16.0393 4.8478 17.0775 4.84197C18.1157 4.83615 19.1131 5.24605 19.8471 5.98026L19.9191 6.05229C20.6688 6.79605 21.0892 7.80926 21.0862 8.8653C21.0832 9.92135 20.6571 10.9321 19.9031 11.6716C17.5442 13.9816 14.3128 17.1969 12.462 19.0437C11.5242 19.9787 10.2538 20.5036 8.92956 20.5033H5.99734C4.3398 20.5033 2.99609 19.1596 2.99609 17.5021V14.5718C2.99609 13.2455 3.52293 11.9734 4.4607 11.0354L10.6793 4.81677"
        stroke="#1A202C" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round"/>
    </svg>
  )
});
