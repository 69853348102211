import { useEffect, useRef, useState } from "react";
import { Flex, IconButton, Input, Text } from "@chakra-ui/react";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { ITableColumnsConfig } from "../../../_services/interface/table-columns-config.interface";
import { IUpdateColumnsConfig } from "./TableColumnsConfig";

interface IEditableConfigName {
  selectedConfig?: ITableColumnsConfig;
  setSelectedConfig: React.Dispatch<
    React.SetStateAction<ITableColumnsConfig | undefined>
  >;
  updateColumnConfig: (params: IUpdateColumnsConfig) => void;
}

export const EditableConfigName = (props: IEditableConfigName) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [configName, setConfigName] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.selectedConfig) {
      setConfigName(props.selectedConfig.config_name);
    }
  }, [props.selectedConfig]);

  const handleSaveName = () => {
    setIsEditingName(false);
    if (
      props.selectedConfig &&
      configName !== props.selectedConfig.config_name
    ) {
      props.updateColumnConfig({ configName });
      props.setSelectedConfig({
        ...props.selectedConfig,
        config_name: configName,
      });
    }
  };

  const handleCancelEdit = () => {
    setConfigName(props.selectedConfig?.config_name || "");
    setIsEditingName(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleSaveName();
      } else if (event.key === "Escape") {
        handleCancelEdit();
      }
    };

    if (isEditingName) {
      document.addEventListener("keydown", handleKeyDown);
      inputRef.current?.focus();
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingName, configName]);

  return (
    <Flex alignItems="center" justifyContent="space-between">
      {isEditingName ? (
        <>
          <Input
            ref={inputRef}
            value={configName}
            onChange={(e) => setConfigName(e.target.value)}
            size={"x-sm"}
            mr={2}
          />
          <IconButton
            aria-label="Salvar"
            icon={<CheckIcon />}
            p={"0"}
            fontSize={"12px"}
            h={"24px"}
            w={"24px"}
            onClick={handleSaveName}
            mr={2}
          />
          <IconButton
            aria-label="Cancelar"
            icon={<CloseIcon />}
            p={"0"}
            fontSize={"12px"}
            h={"24px"}
            w={"24px"}
            onClick={handleCancelEdit}
          />
        </>
      ) : (
        <>
          <Text>{configName}</Text>
          <IconButton
            aria-label="Editar"
            icon={<EditIcon />}
            p={"0"}
            fontSize={"12px"}
            h={"24px"}
            w={"24px"}
            onClick={() => setIsEditingName(true)}
          />
        </>
      )}
    </Flex>
  );
};
