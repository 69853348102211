import { ApiAuth, ApiCall } from "../../../../_services/api";
import { TPremiseResponse } from "../../../../_services/interface/IDefaults";
import { IGetCards, IGetCardsResponse } from "../../../../_services/interface/kanban.interface";
import { IFirstCard, IFirstCardFiles, IKanbanCardInput } from "../interfaces/ICard";
import { ICardList } from "../interfaces/ICardList";
import { IFileAssociation } from "../interfaces/IFileAssociation";
import {IFiles} from "../interfaces/IFiles"
import { IKanbanCardEdit, IKanbanFileInputs, IKanbanResponsibleInput, IKanbanStatusInput } from "../interfaces/Inputs";
import { IObligationList, IOCRRequestToken, IScheduleData, IScheduleEdit, IScheduleList, IScheduleObligation, IScheduleRegistrationInput } from "../interfaces/IObligation";

export const postSchedule = async (guid_client: string, formdata: IScheduleRegistrationInput): Promise<TPremiseResponse<void>> => {
  return await new ApiCall(`/irs/schedule/${guid_client}`, ApiAuth()).post<void, IScheduleRegistrationInput>(formdata);
}

export const getAllObligations = async (guid_client: string): Promise<TPremiseResponse<IObligationList>> => {
  return await new ApiCall(`/irs/from/${guid_client}`, ApiAuth()).get<IObligationList>();
}

export const getAllSchedules = async (guid_client: string): Promise<TPremiseResponse<IScheduleList[]>> => {
  return await new ApiCall(`/irs/schedule/${guid_client}/all`, ApiAuth()).get<IScheduleList[]>();
}

// TODO: Not being used
export const getScheduleObligations = async (guid_company: string): Promise<TPremiseResponse<IScheduleObligation[]>> => {
  return await new ApiCall(`/irs/obligations/${guid_company}`, ApiAuth()).get<IScheduleObligation[]>();
}

export const getKanban = async (guid_client: string): Promise<TPremiseResponse<ICardList[]>> => {
  return await new ApiCall(`/irs/kanban/${guid_client}`, ApiAuth()).get<ICardList[]>()
}

export const getKanbanPost = async (guid_client: string, data?: IGetCards): Promise<TPremiseResponse<IGetCardsResponse>> => {
  return await new ApiCall(`/irs/kanban/${guid_client}`, ApiAuth()).post<IGetCardsResponse, IGetCards>(data)
}

// TODO: Not being used
export const postKanbanCard = async (guid_company: string, formdata: IKanbanCardInput): Promise<TPremiseResponse<void>> => {
  return await new ApiCall(`/irs/kanban/${guid_company}/newcard`, ApiAuth())
    .post<void, IKanbanCardInput>(formdata)
}

export const getKanbanCard = async (guid_client: string, guid_card: string): Promise<TPremiseResponse<IFirstCard>> => {
  return await new ApiCall(`/irs/kanban/${guid_client}/card/${guid_card}`, ApiAuth()).get<IFirstCard>();
}

export const getCompanyFiles = async (guid_client: string, guid_cardCompany: string): Promise<TPremiseResponse<IFirstCardFiles[]>> => {
  return await new ApiCall(`irs/kanban/${guid_client}/files/${guid_cardCompany}`, ApiAuth()).get<IFirstCardFiles[]>();
}

export const putKanbanCardFile = async (guid_client: string, guid_card: string, guid_cardCompany: string, formdata: IKanbanFileInputs): Promise<TPremiseResponse<void>> => {
  return await new ApiCall(`/irs/kanban/${guid_client}/card/${guid_card}/${guid_cardCompany}/addfile`, ApiAuth({ "Content-Type": "multipart/form-data" }))
    .put<void, IKanbanFileInputs>(formdata);
}

export const deleteKanbanCardFile = async (guid_client: string, guid_file: string): Promise<TPremiseResponse<void>> => {
  return await new ApiCall(`/irs/kanban/${guid_client}/file/${guid_file}`, ApiAuth()).delete();
}

// TODO: Not being used
// export const putKanbanCardResponsible = async (guid_company: string, formdata: IKanbanResponsibleInput): Promise<TPremiseResponse<void>> => {
//   return await new ApiCall(`/irs/kanban/${guid_company}/card/responsible`, ApiAuth())
//     .put<void, IKanbanResponsibleInput>(formdata)
// }

// TODO: Not being used
// export const deleteCardResponsible = async(guid_company: string, guid_cardResponsible: string): Promise<TPremiseResponse<void>> => {
//   return await new ApiCall(`/irs/kanban/${guid_company}/card/responsible/${guid_cardResponsible}`, ApiAuth())
//     .delete();
// }

// TODO: Not being used
// export const patchChangeStatus = async (guid_company: string, formdata: IKanbanStatusInput): Promise<TPremiseResponse<void>> => {
//   return await new ApiCall(`/irs/kanban/${guid_company}/changestatus`, ApiAuth()).patch<void, IKanbanStatusInput>(formdata);
// }

export const patchEditCard = async (guid_client: string, guid_card: string, formdata: IKanbanCardEdit): Promise<TPremiseResponse<IFirstCard>> => {
  return await new ApiCall(`/irs/kanban/${guid_client}/card/${guid_card}`, ApiAuth()).patch<IFirstCard, IKanbanCardEdit>(formdata);
}

export const deleteCard = async (guid_client: string, guid_card: string): Promise<TPremiseResponse<void>> => {
  return await new ApiCall(`/irs/kanban/${guid_client}/card/${guid_card}`, ApiAuth()).delete();
}

export const getScheduleInfo = async(guid_client: string, guid_schedule: string): Promise<TPremiseResponse<IScheduleData>> => {
  return await new ApiCall(`/irs/schedule/${guid_client}/${guid_schedule}`, ApiAuth()).get();
}

export const patchSchedule = async (guid_client: string, guid_schedule: string, formdata: IScheduleEdit): Promise<TPremiseResponse<boolean>> => {
  return await new ApiCall(`/irs/schedule/${guid_client}/${guid_schedule}`, ApiAuth()).patch<boolean, IScheduleEdit>(formdata);
}

export const deleteSchedule = async(guid_client: string, guid_schedule: string): Promise<TPremiseResponse<void>> => {
  return await new ApiCall(`/irs/schedule/${guid_client}/${guid_schedule}`, ApiAuth()).delete();
}

export const requestOcr = async (guid_client: string): Promise<TPremiseResponse<IOCRRequestToken>> => {
  return await new ApiCall(`/irs/kanban/${guid_client}/requestocr`, ApiAuth()).get();
}

export const multiUpdate = async (guid_client: string, formdata: { data: IFileAssociation[] }): Promise<TPremiseResponse<boolean>> => {
  return await new ApiCall(`/irs/kanban/${guid_client}/multiupdate`, ApiAuth({ "Content-Type": "multipart/form-data" })).post<boolean, { data: IFileAssociation[] }>(formdata);
}

export const getAllFiles = async (guid_client: string): Promise<TPremiseResponse<IFiles[]>> => {
  return await new ApiCall(`/irs/files/${guid_client}`, ApiAuth()).get()
}

export const downloadAllFiles = async (guid_client: string, files: string[]): Promise<any> => {
  return await new ApiCall(`/irs/files/${guid_client}/downloadall`, ApiAuth()).post<any, { guid_files: string[] }>({ guid_files: files });
}
