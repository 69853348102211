import { Flex, Image, Radio, RadioGroup, Text, Tooltip, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { configDisplay, getConfigDisplay } from "../../../../_services/config.service";
import MiniLoading from "../../../../components/miniLoading";
import { RectShape } from "react-placeholder/lib/placeholders";

interface IDisplayProps {
  refreshData: boolean;
  guid_client: string;
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
  submit: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: boolean;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Display = (props: IDisplayProps) => {
  const toast = useToast();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  
  const [certificateMenu, setCertificateMenu] = useState<'default'|'jurisdiction'>('default');
  const [displayLastValidCertificate, setDisplayLastValidCertificate] = useState<boolean>(false);
  const [customStatusForCertificate, setCustomStatusForCertificate] = useState<boolean>(false);
  const [groupedBranchesIsActive, setGroupedBranchesIsActive] = useState<boolean>(false);
  const [displayCertificateWithoutRegistration, setDisplayCertificateWithoutRegistration] = useState<boolean>(false);

  const [initialCertificateMenu, setInitialCertificateMenu] = useState<'default'|'jurisdiction'>('default');
  const [initialDisplayLastValidCertificate, setInitialDisplayLastValidCertificate] = useState<boolean>(false);
  const [initialCustomStatusForCertificate, setInitialCustomStatusForCertificate] = useState<boolean>(false);
  const [initialGroupedBranchesIsActive, setInitialGroupedBranchesIsActive] = useState<boolean>(false);
  const [initialDisplayCertificateWithoutRegistration, setInitialDisplayCertificateWithoutRegistration] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submit = async () => {
    setSubmitDisabled(true);
    const {status, response} = await configDisplay(props.guid_client,{
      certificateMenu,
      displayLastValidCertificate,
      customStatusForCertificate,
      groupedBranchesIsActive,
      displayCertificateWithoutRegistration
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });

      props.setSubmit(false);
      props.hasChangeHook(false);
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    }    

    setSubmitDisabled(false);
  }

  const configFields = async () => {
    setIsLoading(true);
    const {status, response} = await getConfigDisplay(props.guid_client);

    if(
      status === 200 
      && response
      && 'certificateMenu' in response
    ) {
      const {
        certificateMenu,
        displayLastValidCertificate,
        customStatusForCertificate,
        groupedBranchesIsActive,
        displayCertificateWithoutRegistration
      } = response;

      setCertificateMenu(certificateMenu);
      setDisplayLastValidCertificate(displayLastValidCertificate);
      setCustomStatusForCertificate(customStatusForCertificate);
      setGroupedBranchesIsActive(groupedBranchesIsActive);
      setDisplayCertificateWithoutRegistration(displayCertificateWithoutRegistration);

      setInitialCertificateMenu(certificateMenu);
      setInitialDisplayLastValidCertificate(displayLastValidCertificate);
      setInitialCustomStatusForCertificate(customStatusForCertificate);
      setInitialGroupedBranchesIsActive(groupedBranchesIsActive);
      setInitialDisplayCertificateWithoutRegistration(displayCertificateWithoutRegistration);
    }

    setTimeout(() => setIsLoading(false), 500);
  }

  useEffect(() => {
    configFields();
  }, [])

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  useEffect(() => {
    if(props.submit) {
      submit();
    }
  }, [props.submit]);

  useEffect(() => {
    if(
      certificateMenu === initialCertificateMenu
      && displayLastValidCertificate === initialDisplayLastValidCertificate
      && customStatusForCertificate === initialCustomStatusForCertificate
      && groupedBranchesIsActive === initialGroupedBranchesIsActive
      && displayCertificateWithoutRegistration === initialDisplayCertificateWithoutRegistration
    ) {
      props.hasChangeHook(false);
    } else {
      props.hasChangeHook(true);
    }
  }, [
    certificateMenu,
    displayLastValidCertificate,
    customStatusForCertificate,
    groupedBranchesIsActive,
    displayCertificateWithoutRegistration
  ]);

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="15px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Certidões</Text>
      </Flex>

      <Flex width="80%" flexDirection="column" gap="24px">
        <Flex direction="column" gap={2} mb={4}>
          <Text fontSize="16px" mt={2} fontFamily="Poppins-Medium">Exibição de certidões no menu lateral:</Text>
          {
            isLoading ? (<>
              <Flex direction="column" gap={2} pb={2}>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
              </Flex>
            </>) : (
              <RadioGroup onChange={(value: 'default'|'jurisdiction') => setCertificateMenu(value)} value={certificateMenu}>
                <Flex direction="column" gap={2} pb={2}>
                  <Radio value="default">Agrupado por tipo de certidão (CND, FGTS, CNDT, etc.)</Radio>
                  <Radio value="jurisdiction">Agrupado por jurisdição (Federal, Estadual ou Municipal)</Radio>
                </Flex>
              </RadioGroup>
            )
          }
        </Flex>

        <Flex direction="column" gap={2} mb={4}>
          <Text fontSize="16px" mt={2} fontFamily="Poppins-Medium">Exibição das certidões:</Text>
          {
            isLoading ? (<>
              <Flex direction="column" gap={2} pb={2}>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
              </Flex>
            </>) : (
              <RadioGroup onChange={(value) => setDisplayLastValidCertificate(value === 'true')} value={displayLastValidCertificate ? 'true' : 'false'}>
                <Flex direction="column" gap={2} pb={2}>
                  <Radio value="true">Exibir última certidão válida</Radio>
                  <Radio value="false">Exibir última certidão consultada</Radio>
                </Flex>
              </RadioGroup>
            )
          }
        </Flex>

        <Flex direction="column" gap={2} mb={4}>
          <Text fontSize="16px" mt={2} fontFamily="Poppins-Medium">Exibição de status das certidões:</Text>
          {
            isLoading ? (<>
              <Flex direction="column" gap={2} pb={2}>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
              </Flex>
            </>) : (
              <RadioGroup onChange={(value) => setCustomStatusForCertificate(value === 'true')} value={customStatusForCertificate ? 'true' : 'false'}>
                <Flex direction="column" gap={2} pb={2}>
                  <Radio value="true">Exibir status individualizados</Radio>
                  <Radio value="false">Exibir status agrupados (válido/inválido)</Radio>
                </Flex>
              </RadioGroup>
            )
          }
        </Flex>

        <Flex direction="column" gap={2} mb={4}>
          <Flex alignItems="center">
            <Text fontSize="16px" fontFamily="Poppins-Medium">Exibição de consultas sem registro:</Text>
            <Tooltip label="Consultas que apresentam esse formato de retorno: FGTS e SINTEGRA">
              <Image 
                cursor={'default'}
                height={4}
                width={4}
                ml="10px"
                src="/icons/information-circle-blue.svg" 
                resize="none"
              />
            </Tooltip>
          </Flex>
          {
            isLoading ? (<>
              <Flex direction="column" gap={2} pb={2}>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
              </Flex>
            </>) : (
              <RadioGroup onChange={(value) => setDisplayCertificateWithoutRegistration(value === 'true')} value={displayCertificateWithoutRegistration ? 'true' : 'false'}>
                <Flex direction="column" gap={2} pb={2}>
                  <Radio value="true">Exibir consultas sem registros</Radio>
                  <Radio value="false">Ocultar consultas sem registro</Radio>
                </Flex>
              </RadioGroup>
            )
          }
        </Flex>
      </Flex>

      <hr />

      <Flex marginBottom="15px" marginTop="30px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Empresas</Text>
      </Flex>

      <Flex width="80%" flexDirection="column" gap="24px" marginBottom="30px">
        <Flex direction="column" gap={2} mb={4}>
          <Text fontSize="16px" mt={2} fontFamily="Poppins-Medium">Listagem de empresas:</Text>
          {
            isLoading ? (<>
              <Flex direction="column" gap={2} pb={2}>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
                <RectShape color='#e0e0e0' style={{width: '100%', height: 24}}/>
              </Flex>
            </>) : (
              <RadioGroup onChange={(value) => setGroupedBranchesIsActive(value === 'true')} value={groupedBranchesIsActive ? 'true' : 'false'}>
                <Flex direction="column" gap={2} pb={2}>
                  <Radio value="true">Exibir filiais agrupadas por matriz</Radio>
                  <Radio value="false">Exibir matrizes e filiais separadamente</Radio>
                </Flex>
              </RadioGroup>
            )
          }
        </Flex>
      </Flex>

      <hr />

      <Flex width="100%" justifyContent="flex-start" marginTop="35px">
        <FormButton onClick={submit} disabled={submitDisabled || isLoading}>
          Salvar alterações
        </FormButton>
      </Flex>
    </Flex>
  );
}
