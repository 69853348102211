import create from "zustand";

interface ILoginToken {
  token: string | null;
  setToken: (data: string | null) => void;
}

const useLoginToken = create<ILoginToken>((set) => ({
  token: null,
  setToken: (data) => set((state) => ({ ...state, token: data })),
}));

export default useLoginToken;