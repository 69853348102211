import queryString from "query-string";
import { ApiAuth, ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { ICompleteRegistrationInputs, ICompleteRegistrationResponse, IFilterListUser, IHistory, IInvite, IInvitedUserInfoResponse, IInviteResponse, ILoggedUserUpdateInputs, IPermissionInput, IPermissionResponse, IRegistrationInputs, IRegistrationResponse, IReinviteUserInputs, IReinviteUserResponse, IResendValidationResponse, IUserEditInputs, IUserEditResponse, IUserInfo, IUserUpdateResponse, IValidationInputs, IValidationResponse } from "./interface/user.interface";

/**
 * Register a new user
 * @param formData Registration data
 * @returns 
 */
export const postRegistration = async (formData: IRegistrationInputs): Promise<TPremiseResponse<IRegistrationResponse>> => {
  return await new ApiCall('/users/register').post<IRegistrationResponse, IRegistrationInputs>(formData);
}

/**
 * Validate a user using a 6 digit One-Time-Password
 * @param guid_user ID of the user
 * @param formdata the code
 * @returns 
 */
export const postUserValidation = async (guid_user: string, formdata: IValidationInputs): Promise<TPremiseResponse<IValidationResponse>> => {
  return await new ApiCall(`/users/validate/${guid_user}`).patch<IValidationResponse, IValidationInputs>(formdata);
}

/**
 * Return the information of the logged user
 * @returns 
 */
export const getUserInfo = async (): Promise<TPremiseResponse<IUserInfo>> => {
  return await new ApiCall('/users/info', ApiAuth()).get<IUserInfo>();
}

/**
 * Updates a user
 * @returns 
 */
export const postLoggedUserUpdate = async(formdata: ILoggedUserUpdateInputs): Promise<TPremiseResponse<IUserUpdateResponse>> => {
  return await new ApiCall('/users/edit', ApiAuth()).put<IUserUpdateResponse, ILoggedUserUpdateInputs>(formdata);
}

/**
 * Return a basic information of the invited user for the complete registration form
 * @returns 
 */
export const getBasicUserInfo = async (guid_user: string): Promise<TPremiseResponse<IInvitedUserInfoResponse>> => {
  return await new ApiCall(`/users/invite-data/${guid_user}`).get<IInvitedUserInfoResponse>();
}

/**
 * Send an invitation to a user
 * @returns 
 */
export const postSendInvite = async (guid_client: string, formdata: IInvite): Promise<TPremiseResponse<IInviteResponse[]>> => {
  return await new ApiCall(`/users/invite/${guid_client}`, ApiAuth()).post<IInviteResponse[], IInvite>(formdata);
}

export const postAllowUserSSO = async (guid_client: string, formdata: IInvite): Promise<TPremiseResponse<IInviteResponse[]>> => {
  return await new ApiCall(`/users/allow/sso/${guid_client}`, ApiAuth()).post<IInviteResponse[], IInvite>(formdata);
}

/**
 * Complete the registration of a user that was invited by other user
 * @param guid_user 
 * @param formdata 
 * @returns 
 */
export const postCompleteRegistration = async (guid_user: string, formdata: ICompleteRegistrationInputs): Promise<TPremiseResponse<ICompleteRegistrationResponse>> => {
  return await new ApiCall(`/users/complete/${guid_user}`).post<ICompleteRegistrationResponse, ICompleteRegistrationInputs>(formdata);
}

/**
 * Edit a user
 * @returns 
 */
export const putUserEdit = async (guid_user: string, guid_company: string, formdata: IUserEditInputs): Promise<TPremiseResponse<IUserEditResponse>> => {
  return await new ApiCall(`/users/edit/${guid_company}/${guid_user}`, ApiAuth()).put<IUserEditResponse, IUserEditInputs>(formdata);
}

/**
 * Reinvite User
 * @returns 
 */
export const postReinviteUser = async (guid_client: string, guid_user: string, formdata: IReinviteUserInputs): Promise<TPremiseResponse<IReinviteUserResponse>> => {
  return await new ApiCall(`/users/reinvite/${guid_client}/${guid_user}`, ApiAuth()).post<IReinviteUserResponse, IReinviteUserInputs>(formdata);
}

/**
 * Resend the validation code to the user
 * @returns 
 */
export const patchResendMailId = async (guid_user: string): Promise<TPremiseResponse<IResendValidationResponse>> => {
  return await new ApiCall(`/users/resend/id/${guid_user}`).patch();
}

export const patchResendMailMail = async (email: string): Promise<TPremiseResponse<IResendValidationResponse>> => {
  return await new ApiCall(`/users/resend/email`).patch({ email });
}

export const patchPermissions = async (guid_client: string, guid_user: string, formdata: IPermissionInput): Promise<TPremiseResponse<IPermissionResponse>> => {
  return await new ApiCall(`/permissions/${guid_client}/${guid_user}`, ApiAuth()).patch<IPermissionResponse, IPermissionInput>(formdata);
}

export const getPermissions = async (guid_client: string, guid_user: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/permissions/${guid_client}/${guid_user}`, ApiAuth()).get();
}

export const getHistory = async (guid_client: string, guid_user: string): Promise<TPremiseResponse<IHistory[]>> => {
  return await new ApiCall(`/users/history/${guid_client}/${guid_user}`, ApiAuth()).get();
}

  /**
   * List all user
   *
   * @param guid_client
   * @param filters
   */
  export const listUsers = async (guid_client: string, filters: IFilterListUser): Promise<TPremiseResponse<any>> => {
    const queryStringParams = queryString.stringify(filters);
    const url = `/users/list/${guid_client}?${queryStringParams}`;
    return await new ApiCall(url, ApiAuth()).get<any>();
};