import { AddIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { ButtonGroup, Button, useDisclosure, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Select, Tooltip, Checkbox, Alert, AlertIcon, Input, useToast } from "@chakra-ui/react"
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { SelectCompanyModal } from "../../Companies/Company/modals/SelectCompanyModal";
import { FileLine } from "../components/FileLine";
import { AlertTriangle } from "../Icons/AlertTriangle";
import { FilesIcon } from "../Icons/Files";
import { ICardCompany, ICardList } from "../interfaces/ICardList";
import axios from "axios";
import { multiUpdate, requestOcr } from "../_services/kanban.service";
import { IOCRRequestToken } from "../interfaces/IObligation";
import MiniLoading from "../../../../components/miniLoading";
import { IFileAssociation } from "../interfaces/IFileAssociation";
import { formatCnpj } from "../../Companies/Company/util/cnpj";
import ReactLoading from "react-loading";

interface IFileProcessed {
  filename: string;
  cnpj: string | null;
  type: string | null;
  date: string | null;
  file: File,
}

interface IBulkFileModalProps {
  guid_client: string;
  kanbanData: ICardList[];
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BulkFileModal = (props: IBulkFileModalProps) => {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const fileUpload = useRef<HTMLInputElement>(null);
  const [isUploading, setUploadingState] = useState<boolean>(false);
  const [isSending, setSendingState] = useState<boolean>(false);
  const [filteredCards, setFilteredCards] = useState<ICardList[]>([]);
  const [fileAssociation, setFileAssociation] = useState<IFileAssociation[]>([]);
  const [approveCreation, setApproveCreation] = useState<boolean>(false);
  const [uploadedFileList, setUploadedFileList] = useState<File[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const toast = useToast();

  const filterKanbanData = () => {
    if (props.kanbanData) {
      //Filter the cards this month
      const filtered = props.kanbanData.filter((x) => {
        if (x.dueDate) {
          return moment.utc(x.dueDate).isSame(moment.utc(), 'month');
        }
        return false;
      });
      setFilteredCards(filtered);
    }
  }

  useEffect(() => {
    filterKanbanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.kanbanData]);

  //This is provisory and need to be removed.
  useEffect(() => {
    if (isOpen) {
      //Reset all the information
      setFileAssociation([]);
      setApproveCreation(false);
      setUploadingState(false);
      setUploadedFileList([]);
      setCurrentStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const genEntry = (e: IFileProcessed, c: ICardList | null, cp: ICardCompany | null, isIdentified: boolean): IFileAssociation => {
    return {
      isIdentified,
      filename: e.filename,
      identifier: e.type as string,
      guid_card: c ? c.guid_card : undefined,
      guid_obligation: c ? c.guid_obligation : undefined,
      guid_company: cp ? cp.guid_company : undefined,
      foundCnpj: e.cnpj,
      jurisdiction: c ? c.jurisdiction : undefined,
      period: e.date,
      file: e.file,
    };
  }

  const specialNameRules = (type: string): string[] | RegExpMatchArray | null => {
    if (type === 'EFD Contribuicoes') {
      return ['EFD-C'];
    } else if (type === 'EFD Fiscal') {
      return ['EFD-F']
    } else {
      return type.match(/([A-Z-\\/]{3,})/g);
    }
  }

  const relationBase = (files: IFileProcessed[]) => {
    const association: IFileAssociation[] = [];
    filterKanbanData();
    files.forEach((e) => {
      if (e.type) {
        //We have the type of the document, so we can search
        const sanitizedName = specialNameRules(e.type);
        if (sanitizedName) {
          //Now will search for the cards here
          let found = false;
          for (let k = 0; k < filteredCards.length; k++) {
            const _card = filteredCards[k];
            //Give the same treatment to the name
            const name = _card.name.match(/([A-Z-\\/]{3,})/g) as RegExpMatchArray;
            //Search for all the acronyms in the name and check all of them
            //let foundCompany = true;

            if (name) {
              for (let i = 0; i < name.length; i++) {
                const _n = name[i];
                //if (_n.includes(sanitizedName[0])) {
                if (_n === sanitizedName[0]) {
                  //Now the system will look for the company inside this card
                  const theCompany = _card.companies.find((company) => company.cnpj === e.cnpj && company.status !== 'concluded');
                  if (theCompany) {
                    //Ok, since the company was found, the system needs to check if the period and the duedate are from the same month
                    association.push(genEntry(e, _card, theCompany, true));
                    found = true;
                  } else {
                    //Ok, company was not found, so the system will record the card but not the company
                    association.push(genEntry(e, _card, null, false));
                    found = true;
                  }
                  //Break the loop anyway
                  break;
                } else {
                 /*  //Ok, something wrong happened in the process, so the system make a 
                  //broader check and try to find at least part of the obligation
                  foundCompany = false; */
                }
              }

              /* if (!foundCompany) {
                //Try a more direct search on the name
                if (_card.name.replaceAll(/\s|-|–|\./g, '').toLowerCase().includes(sanitizedName[0].toLowerCase())) {
                  //Remove the found false as flag
                  foundCompany = true;
                  //Transfer the value to found because the Break will not let the system register the found inside this for later
                  found = foundCompany;
                  //Try to make the association with the card
                  const theCompany = _card.companies.find((company) => company.cnpj === e.cnpj && company.status !== 'concluded');
                  if (theCompany) {
                    //Ok, since the company was found, the system needs to check if the period and the duedate are from the same month
                    association.push(genEntry(e, _card, theCompany, true));
                  } else {
                    //Ok, company was not found, so the system will record the card but not the company
                    association.push(genEntry(e, _card, null, false));
                  }
                  //Break the main loop
                  break;
                }
              } */
              //Pass the information to the found variable so the system can deal with it later
              //found = foundCompany;
            }
          }

          if (!found) {
            //No card was found, so the system will register here
            association.push(genEntry(e, null, null, false));
          }
        }
      } else {
        //The type of the document returned as null so will be recorded
        association.push(genEntry(e, null, null, false));
      }
    });

    setFileAssociation(association);
  }

  const checkIfCompanyIsInCard = (card: ICardList, guid_company: string): boolean => {
    const found = card.companies.find((x) => x.guid_company === guid_company);
    return found ? true : false;
  }

  const changeLine = (e: string, pos: number, field: 'company' | 'card') => {
    const lineClone = [...fileAssociation];
    if (field === 'card') {
      if (e) {
        lineClone[pos].guid_card = e as string;
      } else {
        lineClone[pos].guid_card = undefined;
      }

      // check if the company belongs to this card
      if (lineClone[pos].guid_card && lineClone[pos].guid_company) {
        const card = filteredCards.find((x) => x.guid_card === lineClone[pos].guid_card);
        if (card && lineClone[pos].guid_company !== undefined) {
          const isCompanyInCard = checkIfCompanyIsInCard(card, lineClone[pos].guid_company as string);
          if (!isCompanyInCard) {
            lineClone[pos].guid_company = undefined;
          }
        }
      }

    } else if (field === 'company') {
      lineClone[pos].guid_company = e as string;
    }

    if (lineClone[pos].guid_card && lineClone[pos].guid_company) {
      lineClone[pos].isIdentified = true;
    } else {
      lineClone[pos].isIdentified = false;
    }

    setFileAssociation(lineClone);
  }

  const uploadFile = async (file: FileList) => {
    if (fileUpload.current) {
      const fileListClone = [...uploadedFileList];
      for (let i = 0; i < file.length; i++) {
        if (file.item(i)) {
          //Check if the file is already in the system
          if (!fileListClone.find((x) => x.name === (file.item(i) as File).name)) {
            fileListClone.push(file.item(i) as File);
          }
        }
      }
      //Save all the files into the system
      setUploadedFileList(fileListClone);
      fileUpload.current.value = '';
    }
  }

  const deleteUploadedFile = (line: number) => {
    const fileListClone = [...uploadedFileList];
    const filtered: File[] = [];
    fileListClone.forEach((_el, _i) => {
      if (_i !== line) {
        filtered.push(_el);
      }
    });

    setUploadedFileList(filtered);
  }

  const deleteProcessedFile = (line: number) => {
    const fileListClone = [...fileAssociation];
    const filtered: IFileAssociation[] = [];
    fileListClone.forEach((_el, _i) => {
      if (_i !== line) {
        filtered.push(_el);
      }
    });

    setFileAssociation(filtered);
  }

  const haveInvalidFiles = () => {
    let listClear = true;
    uploadedFileList.forEach((l) => {
      if (l.type !== 'application/pdf') {
        listClear = false;
      }
    });

    return listClear;
  }

  const checkAllAssociations = () => {
    let isAllOk = true;
    fileAssociation.forEach((l) => {
      if (!l.isIdentified) {
        isAllOk = false;
      }
    });
    return isAllOk;
  }

  const sendFiles = async () => {
    setUploadingState(true);
    const { status, response } = await requestOcr(props.guid_client);
    if (status === 200) {
      //Grab the ticket from the server to contact the OCR service
      const tokenData = (response as IOCRRequestToken).ticket;
      //Now the system uses this token, to contact the OCR service
      try {
        const _c = axios.create({ baseURL: process.env.REACT_APP_OCR, timeout: 120000 });
        const ocrResponse = await _c.post('/ocr', Object.assign({}, uploadedFileList), {
          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        });

        const finalResponse: IFileProcessed[] = [];
        ocrResponse.data.forEach((_dt: any) => {
          finalResponse.push({
            ..._dt,
            //Appends the file to the request, because the system will send this file to the user
            file: uploadedFileList.find((x) => x.name === _dt.filename)
          })
        });
        //Here the system will get the data of the files
        relationBase(finalResponse);
        //Stop the loading state
        setUploadingState(false);
        //Jump to the next step
        setCurrentStep(2);
      } catch (err: any) {
        setUploadingState(false);
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao transmitir arquivos para o sistema, por favor, tente novamente mais tarde.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }
  }

  const sendInformationData = async () => {
    setSendingState(true);
    const { status } = await multiUpdate(props.guid_client, {
      data: fileAssociation
    });

    if (status === 200) {
      toast({
        title: 'Associação De arquivos',
        description: `As respectivas obrigações foram atualizadas`,
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      onClose();
      props.flushHook(true)
    } else {
      toast({
        title: 'Associação De arquivos',
        description: `O Sistema falhou em atualizar os arquivos`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
    setSendingState(false);
  }

  const stepOne = () => {
    return (
      <Flex justifyContent="center" flexDirection="column" gap={4}>
        <Text>Envio os arquivos para processamento, os arquivos serão processados pelo o sistema.</Text>
        <Flex flexDirection="column">
          <Flex border="1px solid" borderColor="gray.400" borderBottom="0px" flexDirection="column" overflowX="auto" overflowY="auto" h={300}>
            {!isUploading ?
              uploadedFileList.map((_el, _key) => (
                <FileLine key={_key} isValid={_el.type === 'application/pdf'} filename={_el.name} mimetype={_el.type} size={_el.size} onDelete={() => deleteUploadedFile(_key)} />
              ))
              :
              (<Flex w="100%" justifyContent="center" alignItems="center" h={300} bg="gray.50">
                <MiniLoading />
              </Flex>)
            }
          </Flex>
          <Flex bg="gray.200" border="1px solid" borderColor="gray.400" borderTop="0px" p={2} justifyContent="center" alignItems="center">
            <ButtonGroup size="sm" color="white" fontWeight="light">
              <Input ref={fileUpload} type="file" multiple position="absolute" left="-9999" onChange={event => uploadFile(event.target.files as FileList)} />
              <Button leftIcon={<AddIcon />} isDisabled={isUploading} color="white" onClick={() => fileUpload.current?.click()} fontWeight={400} fontFamily="Poppins-Light" fontSize="12px" bgColor="#4B4EFF" _hover={{ bg: '#282be0' }} _disabled={{ bgColor: "#D9D9D9", cursor: "not-allowed", "&:hover": { cursor: "not-allowed", bgColor: "#D9D9D9" } }}>
                Adicionar arquivos
              </Button>
            </ButtonGroup>
          </Flex>
          <Flex mt={2}>Total de Arquivos a enviar: {uploadedFileList.length}</Flex>
        </Flex>

        {!haveInvalidFiles() ? (
          <Alert status="warning">
            <AlertIcon />
            Você enviou arquivos que não são aceitos pelo o sistema, remova eles da listagem para prosseguir.
          </Alert>
        ) : null}

        <Flex gap={2} flexGrow={1} justifyContent="center">
          <FormButton disabled={!isUploading ? uploadedFileList.length > 0 ? !haveInvalidFiles() : true : true} onClick={sendFiles}  width="70%">
            {isUploading ? (<ReactLoading type="spin" color="#fff" height={20} width={20} />) : ('Salvar arquivos')}
          </FormButton>
        </Flex>
      </Flex>
    )
  }

  const stepTwo = () => {
    return (
      <Flex justifyContent="center" flexDirection="column" gap={4}>
        <Text>Para prosseguir com a atualização de todas as obrigações, favor confirmar todos os relacionamentos encontrados pelo o sistema. Ao confirmar, as empresas nas respectivas obrigações terão seus status alterados para concluído.</Text>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex>Total de Arquivos:&nbsp;<strong>{uploadedFileList.length}</strong></Flex>
          <Flex>Associações:&nbsp;<strong>{fileAssociation.length - fileAssociation.filter((x) => !x.guid_company || !x.guid_card).length}/{fileAssociation.length}</strong></Flex>
        </Flex>
        <Flex border="1px solid" borderColor="gray.400" flexDirection="column" overflowX="auto" overflowY="auto" maxHeight={400}>
          {fileAssociation.sort((a, b) => a.isIdentified! < b.isIdentified! ? -1 : a.isIdentified! > b.isIdentified! ? 1 : 0).map((e, _key) => (
            <Flex key={_key} flexDirection="column" justifyContent="stretch">
              <Flex padding={2} background={e.isIdentified ? "green.100" : "red.100"} justifyContent="space-between" alignItems="center">
                <Flex gap={2}>
                  <Flex bg="#4B4EFF" w="20px" h="20px" alignItems="center" justifyContent="center" onClick={() => deleteProcessedFile(_key)} borderRadius={3} _hover={{ bgColor: "#686AFF", cursor: "pointer" }}>
                    <DeleteIcon color="white" />
                  </Flex>
                  <Text>{e.filename}</Text>
                </Flex>
                <Flex>
                  {e.isIdentified ? (
                    <Tooltip label="Associação Encontrada">
                      <Flex bg="green" w="20px" h="20px" alignItems="center" justifyContent="center" borderRadius={3}>
                        <CheckIcon color="white" />
                      </Flex>
                    </Tooltip>) : (
                    <Tooltip label="Associação manual necessária">
                      <Flex bg="orange" w="20px" h="20px" alignItems="center" justifyContent="center" borderRadius={3}>
                        <AlertTriangle color="white" />
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
              <Flex padding={4} background={e.isIdentified ? "white" : "red.50"} flexDirection="column" gap={2}>
                {filteredCards.find((x) => x.guid_card === e.guid_card)?.calculationPeriod ? (
                  <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Flex flexDirection="column" width="50%">
                      <Text fontSize="10px" color="gray.400">Período de Apuração Arquivo</Text>
                      <Text fontSize="10px">{e.period ? moment.utc(e.period, 'MM/YYYY').format('MMM/YYYY') : 'Não Encontrado'}</Text>
                    </Flex>
                    <Flex flexDirection="column" width="50%">
                      <Text fontSize="10px" color="gray.400" textAlign="right">Período de Apuração Obr.</Text>
                      <Text fontSize="10px" textAlign="right">{filteredCards.find((x) => x.guid_card === e.guid_card)?.calculationPeriod}</Text>
                    </Flex>
                  </Flex>
                ) : null}
                <Select size="sm" fontSize={12} value={e.guid_card} borderColor="gray.500" bg="white" placeholder="Associe o Card" onChange={(e) => changeLine(e.target.value, _key, 'card')}>
                  {filteredCards.filter((x) => moment.utc(x.createdAt).diff(moment.utc(), 'month') === 0).map((card, _filtered_card_key) => (
                    <option key={_filtered_card_key} value={card.guid_card} selected={fileAssociation[_key].guid_card === card.guid_card ? true : false}>{card.name}</option>
                  ))}
                </Select>
                {filteredCards.find((x) => x.guid_card === e.guid_card) ? (
                  <Flex flexDirection="column" alignItems="center" gap="2px">
                    <Flex flexDirection="row" width="100%" justifyContent="space-between">
                      <Text fontSize="10px" color="gray.400">Vencimento</Text>
                      <Text fontSize="10px">{moment.utc(filteredCards.find((x) => x.guid_card === e.guid_card)?.dueDate).format('DD/MM/YYYY')}</Text>
                    </Flex>
                    {e.foundCnpj ? (
                      <Flex flexDirection="row" width="100%" justifyContent="space-between">
                        <Text fontSize="10px" color="gray.400">Empresa Encontrada no Arquivo</Text>
                        <Text fontSize="10px">{formatCnpj(e.foundCnpj)}</Text>
                      </Flex>
                    ) : null}
                  </Flex>
                ) : null}
                {e.guid_card ? (
                  <SelectCompanyModal guid_client={props.guid_client} selected={e.guid_company ? [e.guid_company] : []} callback={(ev) => changeLine(ev[0], _key, 'company')} showOnly={filteredCards.find((x) => x.guid_card === e.guid_card)?.companies.map((x) => x.guid_company)} />
                ) : null}
              </Flex>
            </Flex>
          ))}
        </Flex>

        <Flex direction="column" gap={2}>
          <Checkbox size="md" colorScheme="green" checked={approveCreation} onChange={() => setApproveCreation(!approveCreation)}>
            <Text fontSize={14}>Confirmo todas as associações</Text>
            <Text fontSize="11px" opacity={0.5}>
              Ao marcar esta opção você confirma todas as associações feitas pelo o sistema (e confirmadas e ou ajustadas por você). Seu nome será registrado no histórico de todas as obrigações que o sistema atualizar por esta janela.
            </Text>
          </Checkbox>
        </Flex>

        <Flex gap={2} flexGrow={1} justifyContent="center">
          <Button bgColor="#4b4eff" color="white" fontSize="12px" fontWeight="400" width="70%" isDisabled={isSending ? isSending : (checkAllAssociations() ? !approveCreation : true)} onClick={() => sendInformationData()}>{isSending ? (<ReactLoading type="spin" color="#fff" height={20} width={20} />) : ('Atualizar cards')}</Button>
        </Flex>
      </Flex>
    );
  }

  const stepPrint = () => {
    if (currentStep === 1) {
      return stepOne();
    } else if (currentStep === 2) {
      return stepTwo();
    }
  }

  return (
    <>
      <ButtonGroup size="sm" mt="24px" color="white">
        <Button leftIcon={<FilesIcon />} bgColor="#4B4EFF" color="white" onClick={onOpen} _hover={{ bg: '#282be0' }}>
          Carregar recibos
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Processamento de Recibos em Lote</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12} pb={6}>
            {stepPrint()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}