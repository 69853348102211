import React, { useState } from "react";
import {
  Box,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import { TLocationHandler } from "../_types/location.type";
import { ValidationInput } from "../validation/components/ValidationInput";
import { FormButton } from "../../../components/login/button.component";
import { postCheckOtp } from "../../../_services/auth.service";
import { PasswordResetForm } from "../PasswordReset/PasswordReset";
import { SimpleFormError, SimpleFormValid } from "../../../components/login/formError.component";
import { patchResendMailMail } from "../../../_services/user.service";
import { IResendValidationResponse } from "../../../_services/interface/user.interface";

interface VerificationProps {
  locationHandler: TLocationHandler;
  email: string;
}

export const VerificationForm = (props: VerificationProps) => {
  const [counter, setCounter] = React.useState(100);
  const [isResendDisabled, setResendDisabled] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isValidationBtnDisabled, setValidationBtn] = useState<boolean>(true);
  const [validationCode, setValidationCode] = useState<string>("");
  const [okMessage, setOkMessage] = useState("");

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResendDisabled(false);
    }
  }, [counter]);

  /**
   * Resend the validation information
   */
   const resendClick = async () => {
    setCounter(100);
    setResendDisabled(true);
    setOkMessage('');
    setErrorMessage('');

    const {status, response} = await patchResendMailMail(props.email);

    if (status === 200) {
      if ((response as IResendValidationResponse).mailsent) {
        setOkMessage('Código reenviado com sucesso');
      } else {
        setErrorMessage('Falha ao reenviar o código.');  
      }
    } else {
      setErrorMessage('Falha ao reenviar o código.');
    }
  };

  const handleCode = (code: string) => {
    setValidationCode(code);
    if (code.length === 6) {
      setValidationBtn(false);
    } else {
      setValidationBtn(true);
    }
  };

  const sendValidation = async () => {
    setValidationBtn(true);
    setOkMessage('');
    setErrorMessage('');
    //Here the system dispatch the code
    const { status, response } = await postCheckOtp({
      otp: validationCode,
      email: props.email,
    });

    if (status === 200) {
      if ((response as boolean)) {
        props.locationHandler(<PasswordResetForm locationHandler={props.locationHandler} otp={validationCode} email={props.email} />)
      }
    } else {
      setErrorMessage('Código inválido');
    }
    setValidationBtn(false);
  };


  return (
    <Flex fontFamily="Poppins-Medium" alignItems="center" mt="0px" w={396}>
      <Flex direction="column" w="100%">
        <Text fontWeight="700" fontFamily="Poppins-Medium" fontSize="32px" textAlign="center">
          Verificação
        </Text>
        <Box mt={12}>
          <Flex direction="column" w="396px" alignSelf="center">
            <Text fontSize="12px">Para continuar, informe abaixo a senha única que enviamos para o seu email <strong>{props.email}</strong></Text>
          </Flex>
          {errorMessage ? (<SimpleFormError msg={errorMessage} />) : null}
          {okMessage ? (<SimpleFormValid msg={okMessage} />) : null}
          <form>
            <Flex alignItems="stretch" justifyContent="center" direction="column" mt={12}>
              <Flex direction="column" alignItems="stretch">
                <ValidationInput digits={6} onChange={handleCode} />
                <Flex alignItems="stretch" justifyContent="center" direction="column" mt={12} gap={2}>
                  <FormButton disabled={isValidationBtnDisabled} onClick={sendValidation}>Validar</FormButton>
                  <FormButton onClick={resendClick} disabled={isResendDisabled}>Reenviar Código</FormButton>
                  <Flex alignItems="center" justifyContent="center">
                    <Text fontSize="12px" opacity={0.5}>
                      Você pode reenviar um novo código em {counter} segundos
                    </Text>
                  </Flex>
                  <Text fontSize="11px" opacity={0.5} mt={12}>
                    <strong>Precisa de mais ajuda? </strong>Se você tentou resetar a sua conta mas não recebeu o email da Taxly, verifique a lixeira ou a pasta de spam do seu email. Se ainda sim você não consegue receber o seu email, entre em contato com o nosso{" "}
                    <Link textDecoration="none" color="#4B4EFF" href="#">
                      Help Desk
                    </Link>
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};
