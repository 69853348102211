import React, { useEffect, useState } from 'react';
import { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import { SingleValue } from 'react-select';
import { Field, IColumn, IFieldOption } from '../table.interface';


export const customStyles: StylesConfig<{ label: string; value: string | number | readonly string[]; }, false> = {
  control: (provided, state) => ({
    ...provided,
    borderColor: 'gray.500',
    borderRadius: '6px',
    backgroundColor: state.isDisabled ? '#E2E8F0' : 'white',
    color: state.isDisabled ? '#CBD5E0' : provided.color,
    width: '100%',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontFamily: 'Poppins-medium',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontFamily: 'Poppins-medium',
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontFamily: 'Poppins-medium',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    fontFamily: 'Poppins-medium',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '5px',
    },
    '::-webkit-scrollbar-track': {
      background: '#bdbec196',
      borderRadius: '20px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#a5a9b7',
      borderRadius: '20px',
    },
  }),
};
interface CustomAsyncSelectProps {
  field: Field;
  row: any;
  column: IColumn;
  value: any;
  addValueToNewLine: (value: any, column: any, index: any, customName: any) => void;
  index: number;
}

const CustomAsyncSelect: React.FC<CustomAsyncSelectProps> = ({ field, row, column, value, addValueToNewLine, index }) => {
  const [options, setOptions] = useState<IFieldOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IFieldOption | null>(null);

  const loadOptions = async () => {
    setIsLoading(true);
    let newOptions: IFieldOption[] = [];
    if (typeof field.options === 'function') {
      newOptions = await field.options(row);
    } else if (Array.isArray(field.options)) {
      newOptions = field.options;
    }
    setOptions(newOptions);

    if (value !== undefined && value !== null) {
      const initialOption = newOptions.find(option => option.value == value);
      setSelectedOption(initialOption || null);
    }

    setIsLoading(false);
    return newOptions;
  };

  customStyles.control = (provided) => ({
    ...provided,
    borderColor: 'gray.500',
    borderRadius: '6px',
    backgroundColor: 'white',
    width: column.width ?? '100%',
  });

  useEffect(() => {
    loadOptions();
  }, [field.options, row]);

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={async (inputValue) => {
        const filteredOptions = (await loadOptions()).filter(option =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filteredOptions;
      }}
      styles={customStyles}
      defaultOptions={options}
      value={selectedOption}
      isLoading={isLoading}
      onFocus={loadOptions}
      placeholder={field.placeholder}
      isDisabled={field.disabled !== undefined && field.disabled(row) ? true : false}
      menuPortalTarget={document.body}
      onChange={(selectedOption: SingleValue<{ label: string; value: string | number | readonly string[] }>) => {
        const selected = selectedOption as IFieldOption;
        setSelectedOption(selected);
        addValueToNewLine(selected?.value, column, index, field.customName);
      }}
    />
  );
};

export default CustomAsyncSelect;
