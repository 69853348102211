import { ApiAuth, ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { ITableColumnsConfig, ICreateTableColumnsConfigParams, IUpdateTableColumnsConfigParams } from "./interface/table-columns-config.interface";

export const getTableColumnsConfig = async (
  guid_client: string,
  tableId: string,
  forceSearch?: boolean
): Promise<TPremiseResponse<ITableColumnsConfig[]>> => {

  if (localStorage.getItem(`tableColumnsConfig-${tableId}`) && !forceSearch) {
    const tableColumnsConfig = JSON.parse(localStorage.getItem(`tableColumnsConfig-${tableId}`) as string);
    return { response: tableColumnsConfig, status: 200, requestTime: new Date().toISOString() };
  }
  localStorage.removeItem(`tableColumnsConfig-${tableId}`);

  const response = await new ApiCall(
    `/table-config/${guid_client}/${tableId}`,
    ApiAuth()
  ).get<ITableColumnsConfig[]>();

  if (response.response instanceof Array && response.response.length > 0) {
    localStorage.setItem(`tableColumnsConfig-${tableId}`, JSON.stringify(response.response));
  }

  return response;
};

export const createTableColumnsConfig = async (
  guid_client: string,
  createTableColumnsConfigParams: ICreateTableColumnsConfigParams,
): Promise<TPremiseResponse<ITableColumnsConfig>> => {
  return await new ApiCall(
    `/table-config/${guid_client}`,
    ApiAuth()
  ).post<ITableColumnsConfig, ICreateTableColumnsConfigParams>(createTableColumnsConfigParams);
};

export const updateTableColumnsConfig = async (
  guid_client: string,
  updateTableColumnsConfigParams: IUpdateTableColumnsConfigParams,
): Promise<TPremiseResponse<ITableColumnsConfig>> => {
  return await new ApiCall(
    `/table-config/${guid_client}`,
    ApiAuth()
  ).patch<ITableColumnsConfig, IUpdateTableColumnsConfigParams>(updateTableColumnsConfigParams);
};
