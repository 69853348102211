export enum EToastStatus {
    INFO = "info",
    WARNING = "warning",
    SUCCESS = "success",
    ERROR = "error",
    LOADING = "loading",
}



