import React, { useEffect, useState } from "react";
import { FieldErrorsImpl, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import { AppInput } from "../../../components/AppInput";
import { FormError, SimpleFormError } from "../../../components/login/formError.component";
import { FormButton } from "../../../components/login/button.component";
import { postLogin } from "../../../_services/auth.service";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TLocationHandler } from "../_types/location.type";
import { useNavigate } from "react-router-dom";
import { AccountRecovery } from "../AccountRecovery/AccountRecovery";
import { useCookies } from "react-cookie";
import { ILoginResponse } from "../../../_services/interface/login.interface";
import { ValidationForm } from "../validation/ValidationForm";
import { IErrorResponse } from "../../../_services/interface/IDefaults";
import { AccountBlockForm } from "../AccountBlock/AccountBlock";
import useLoginToken from "../../../store/login.store";

interface LoginFormProps {
  locationHandler: TLocationHandler;
}

export const LoginForm = (props: LoginFormProps) => {
  const navigate = useNavigate();

  //Base form structure
  const YupLoginForm = Yup.object().shape({
    email: Yup.string().email('O email precisa ser válido').required('Preencha o campo de email'),
    password: Yup.string().required('Preencha o campo de senha'),
  });
  
  //Generate o resolver
  const resolverForm = { resolver: yupResolver(YupLoginForm) };
  const [errorMessage, setErrorMessage] = useState<FieldErrorsImpl>();
  const [smplErrorMessage, setSmplErrorMessage] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const { handleSubmit, register, formState: { errors } } = useForm(resolverForm);
  const [cookies, setCookie] = useCookies(["token", "deviceCode"]);
  const { setToken } = useLoginToken();

  const handleFormInfo = async (data: any) => {
    //First the system will disable the login button
    setSubmitDisabled(true);
    //Send the information to the system

    let deviceCode = cookies.deviceCode;	
    if (!deviceCode) {
      deviceCode = crypto.randomUUID();
      // Cria um cookie deviceCode que nunca expira (definindo um tempo de expiração para 10 anos, por exemplo)
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 10);
      setCookie("deviceCode", deviceCode, { path: "/", expires: expirationDate });
    }

    const {status, response} = await postLogin({...data, deviceCode});
    
    if (status === 200) {
      if ((response as any).waitingValidation) {
        //This user still needs to validate its user
        props.locationHandler(<ValidationForm locationHandler={props.locationHandler} email={data.email} userid={(response as any).guid_user} />);
      } else {
        //Set the cookie
        const theToken = (response as ILoginResponse).accessToken;
        setToken(theToken);
        setCookie("token", theToken, { path: "/", sameSite: "lax" });
        localStorage.clear();
        navigate('/dashboard');
      }
    } else {
      const err = (response as IErrorResponse).message;
      switch (err) {
        case 'LOGIN_USER_NOT_FOUND':
          setSmplErrorMessage('Usuário inválido ou não encontrado');
          break;
        case 'LOGIN_USER_BLOCKED':
          props.locationHandler(<AccountBlockForm locationHandler={props.locationHandler} email={data.email} />)
          break;
        case 'LOGIN_ACCOUNT_IS_BLOCKED':
          props.locationHandler(<AccountBlockForm locationHandler={props.locationHandler} email={data.email} />)
          break;
        case 'LOGIN_USER_INACTIVE':
          setSmplErrorMessage('Usuário inválido ou não encontrado');
          break;
        case 'LOGIN_INVALID_COMPANY':
          setSmplErrorMessage('Este usuário é parte de uma empresa inválida');
          break;
        case 'LOGIN_WRONG_PASSWORD':
          setSmplErrorMessage('Email ou Senha são inválidos.');
          break;
        case 'LOGIN_SSO_GATEWAY':
          setSmplErrorMessage('Esta empresa apenas aceita acesso via SSO.');
          break;
        default:
          setSmplErrorMessage('Email ou Senha são inválidos.');
          break;
      }
    }

    //Return the state of the button
    setSubmitDisabled(false);
  };

  const pagePasswordReset = () => {
    props.locationHandler(<AccountRecovery locationHandler={props.locationHandler} />);
  }

  useEffect(() => {
    //Check if the person is already logged and send this person to the dashboard
    if (cookies.token) {
      navigate('/dashboard');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (errors) {
      setErrorMessage(errors as any);
    }
  }, [errors]);

  return (
    <Flex display="flex" fontFamily="Poppins-Medium" alignItems="center" width={369}>
      <Flex direction="column" w="100%">
        <Text fontSize="32px" textAlign="center">
          Login
        </Text>
        <Box marginTop="50px">

          {errorMessage ? (<FormError message={errorMessage} />) : null}
          {smplErrorMessage ? (<SimpleFormError msg={smplErrorMessage} />) : null}

          <form onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSubmit(handleFormInfo)();
            }
          }}>
            <Flex direction="column" gap="16px" alignItems="stretch">
              
              <AppInput placeholder="Seu Email" type="email" {...register("email")} />
              <AppInput placeholder="Sua Senha" type="password" {...register("password", { required: true })} />

              <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>Entrar</FormButton>

              <Box marginTop="30px" borderBottom="1px solid #DBDBDB" />
              <Flex justifyContent="center" alignItems="center" fontSize="12px" marginTop="-10px">
                <Link onClick={pagePasswordReset} _hover={{ color: "#8789FF", cursor: "pointer" }} color="#4B4EFF">
                  Esqueci minha senha
                </Link>
                {/* <Link _hover={{ color: "#8789FF", cursor: "pointer" }} color="#4B4EFF">
                  Preciso de ajuda
                </Link> */}
              </Flex>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};
