import { IBulkCompanyList } from "../../pages/dashboard/Companies/Company/modals/CreateBulkCompany/Step1Form";
import { ECompanyStatus } from "../enum/irs.enum";
import * as Yup from "yup";
import { IClientCustomFields } from './client.interface';
import { IDataMeta } from "../../components/Table/table.interface";

export interface IGetEmployeesResponse {
  guid_company: string;
  name: string;
  collaborators: ICollaborators[];
}

export interface ICollaborators {
  guid_collaborator: string;
  guid_user: string;
  permissions?: string;
  products?: string;
  users: {
    guid_user: string;
    name: string;
    phoneNumber: string;
    email: string;
    codeTimeout?: string;
    isValidated?: boolean;
    isActive?: boolean;
    isBlocked?: boolean;
    isInvitationSent?: boolean;
    isInvited?: boolean;
  }
}

export interface ICompanyList {
  guid_company: string;
  guid_client: string;
  id_state: number;
  id_city: number;
  city_name: string;
  state_name: string;
  state_initials: string;
  type: number;
  name: string;
  cnpj: string;
  cnpj_formatted: string;
  cnpj_base: string;
  stateReg: string;
  cep: string;
  companyCode: string;
  isScheduled: boolean;
  isRegFinished: boolean;
  isActive: boolean;
  has_subsidiary: boolean;
}

export interface ICompanyListAll {
  data: ICompany[];
  meta: IDataMeta;
}

export interface ICompany {
  guid_company: string;
  guid_parent?: string;
  situationIe?: string;
  situation?: string;
  type: number;
  name: string;
  nameWithCode?: string;
  grupoLabel?: string;
  cnpj?: string;
  cnpj_formatted?: string;
  stateReg?: string;
  municipalReg?: string;
  companyCode?: string;
  id_state?: number;
  state?: string;
  initials?: string;
  id_city?: number;
  city?: string;
  count_number_updates: number;
  isRegFinished: Boolean;
  isScheduled: Boolean;
  isActive: Boolean;
  isSSOAuth: Boolean;
  cooldownCnd?: Date;
  createdAt: Date;
  updatedAt: Date;
  products?: string;
  productsSettings?: string;
  token_infosimples?: string;
  certSearch: Boolean;
  recurrency: string;
  weeklyUpdate: Boolean;
  weeklyParticipants?: string;
  allowedCerts: string;
  cep?: string;
  companyStates: ICompanyState[];
  has_filial?: boolean;
}

interface ICompanyState {
  state: IState;
  cep: string | null;
  stateReg: string | null;
  isActive: boolean;
  isMainState: boolean;
}

interface IState {
  id_state: number;
  name: string;
  initials: string;
}

export interface IClientCompanies {
  guid_company: string;
  guid_parent: string;
  name: string;
  nameWithCode?: string;
  grupoLabel?: string;
  cnpj: string;
  cnpj_formatted?: string;
  type: string;
  companyCode: string;
  isScheduled: boolean;
  city: string;
  state: string;
  id_city: string;
  id_state: string;
  initials: string;
  stateReg: string;
  situation: string;
  cep: string;
  isActive: boolean;
  isRegFinished: boolean;
  createdAt: string;
  updatedAt: string;
  products?: string;
  productsSettings?: string;
  recurrency: string;
  certSearch?: boolean;
  weeklyUpdate?: boolean;
  weeklyParticipants?: string;
  allowedCerts: string;
  isValidForSearch?: IIsValidForSearch;
  groups: ICompanyGroup[];
  has_filial?: boolean;
  status?: ECompanyStatus
  isForeign: boolean;
}

export interface ICompanyGroup {
  guid_client: string;
  guid_company_group: string;
  isActive: string;
  name: string;
}

export interface IAccessibleCompaniesResponse {
  guid_company: string;
  guid_parent: string;
  name: string;
  cnpj: string;
  type: string;
  companyCode: string;
  isScheduled: boolean;
  city: string;
  state: string;
  initials: string;
  stateReg: string;
  cep: string;
  users: number;
  isActive: boolean;
  isRegFinished: boolean;
  createdAt: string;
  updatedAt: string;
  products?: string;
  productsSettings?: string;
  recurrency: string;
  certSearch?: boolean;
  branches: IAccessibleCompaniesResponse[];
  weeklyUpdate?: boolean;
  weeklyParticipants?: string;
  companyStates: string;
  allowedCerts: string;
  isValidForSearch?: IIsValidForSearch;
}

interface IIsValidForSearch {
  isValid: boolean;
  invalidParams: string[];
}

export interface ICompanyEditedInputs {
  name: string;
  cnpj: string;
  type: number;
  companyCode: string | null;
  stateReg?: string | null;
  municipalReg?: string | null;
  searchCndAfterUpdate?: boolean;
  isScheduled: boolean;
  cep?: string | null;
  situation?: string | null;
  state: number;
  city: number;
  products?: string;
  certSearch?: boolean;
  recurrency?: string;
  companyStates?: CompanyState[];
  allowedCerts?: string[];
  field1?: string|undefined;
  field2?: string|undefined;
  field3?: string|undefined;
  field1Guid?: string;
  field2Guid?: string;
  field3Guid?: string;
  isForeign?: boolean;
}

export interface ICompanyEditedResponse {
  guid_company: string;
  guid_parent: string | null;
  name: string;
  cnpj: string;
  stateReg: string;
  id_state: number;
  id_city: number;
  isRegFinished: boolean;
  isActive: boolean;
  isSSOAuth: boolean;
  createdAt: string;
  updatedAt: string;
  issueCnds?: boolean;
}

export interface ICompanyCreateInputs extends ICompanyEditedInputs {
  searchCnd: boolean;
  isForeign?: boolean;
}

export interface ICompanyBulkCreate {
  companies: IBulkCompanyList[];
}

export interface ICompanyCreatedResponse extends ICompanyEditedResponse {}

export interface CompanyState {
  state: string;
  id_state: number;
  initials: string;
  cep: string;
  stateReg: string;
  isActive: boolean;
  isMainState: boolean;
}

export interface IGetCompany {
  guid_company: string;
  name: string;
  cnpj: string;
  companyCode: string;
  isScheduled: boolean;
  type: string;
  certSearch: boolean;
  recurrency: string;
  allowedCerts: string;
  city: string;
  situation: string;
  municipalReg: string;
  isRegFinished: boolean;
  isActive: boolean;
  isSSOAuth: boolean;
  createdAt: string;
  updatedAt: string;
  companyStates: CompanyState[];
  customFields: ICustomFieldValues[];
}

export interface ICustomFieldValues {
  guid_custom_field_value: string;
  guid_custom_field: string;
  guid_company: string;
  value: string;
  createdAt: string;
  updatedAt: string;
  customField: IClientCustomFields;
}

export interface ICompanyUpdatedResponse {
  guid_company: string;
  companyUpdated: true;
}

export interface ICompanyList {
  guid_company: string;
  name: string;
  cnpj: string;
}

export interface IFilterListCompany {
  type?: string;
  status?: string;
  company?: string;
  company_group?: string;
  cnpj_base?: string;
  code?: string;
  state?: string;
  city?: string;
  situation?: string;
  situationIe?: string;
  groupMatriz?: boolean;

  page: number;
  row_by_page: number;

  order_field?: string;
  order_direction?: string;
}

export interface ICompanyList {
  data: ICompany[],
  meta: {
    isFirstPage: boolean;
    isLastPage: boolean;
    currentPage: number;
    previousPage: number | null;
    nextPage: number | null;
    pageCount: number;
    totalCount: number;
    totalCountWithoutGroup?: number;
  }
}

export interface ICompany {
  guid_company: string;
  guid_parent?: string;
  type: number;
  name: string;
  nameWithCode?: string;
  grupoLabel?: string;
  cnpj?: string;
  cnpj_formatted?: string;
  stateReg?: string;
  municipalReg?: string;
  companyCode?: string;
  id_state?: number;
  state?: string;
  initials?: string;
  id_city?: number;
  city?: string;
  count_number_updates: number;
  isRegFinished: Boolean;
  isScheduled: Boolean;
  isActive: Boolean;
  isSSOAuth: Boolean;
  cooldownCnd?: Date;
  createdAt: Date;
  updatedAt: Date;
  products?: string;
  productsSettings?: string;
  token_infosimples?: string;
  certSearch: Boolean;
  recurrency: string;
  weeklyUpdate: Boolean;
  weeklyParticipants?: string;
  allowedCerts: string;
  cep?: string;
  companyStates: ICompanyState[];
  has_filial?: boolean;
  isForeign: boolean;
}

interface ICompanyState {
  state: IState;
  cep: string | null;
  stateReg: string | null;
  isActive: boolean;
  isMainState: boolean;
}

interface IState {
  id_state: number;
  name: string;
  initials: string;
}

export interface IBulkUpdateCompany {
  guid_bulk_update: string;
  guid_client: string;
  type: string;
  totalItens: number;
  isPending: boolean;
}

export interface IBulkUpdateCompanyItem {
  guid_bulk_item_company: string;
  guid_bulk_update: string;
  guid_company: string;
  guid_datasource_queue: string;
  status: string;
  error: string|null;
  data: string;
  company?: IBulkUpdateCompanyEntity;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBulkUpdateCompanyEntity {
  cep: string | undefined;
  guid_company: string;
  guid_client: string;
  name: string;
  certSearch: boolean;
  cnpj: string;
  draft: boolean;
  id_city: number;
  id_state: number;
  isActive: boolean;
  isRegFinished: boolean;
  products: string;
  productsSettings: string;
  recurrency: string;
  stateReg: string;
  type: number;
}

export const ICompanyGroup = Yup.object().shape({
  guid_client: Yup.string().required(),
  guid_company_group: Yup.string().required(),
  isActive: Yup.string().required(),
  name: Yup.string().required(),
});

export const IIsValidForSearchSchema = Yup.object().shape({
  isValid: Yup.boolean().required(),
  invalidParams: Yup.array().of(Yup.string())
});

export const IClientCompaniesSchema = Yup.object().shape({
  guid_company: Yup.string().required(),
  guid_parent: Yup.string().nullable(),
  name: Yup.string().nullable(),
  nameWithCode: Yup.string().nullable(),
  grupoLabel: Yup.string().nullable(),
  cnpj: Yup.string().required(),
  cnpj_formatted: Yup.string().nullable(),
  type: Yup.string().nullable(),
  companyCode: Yup.string().nullable(),
  isScheduled: Yup.boolean().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  id_city: Yup.string().nullable(),
  id_state: Yup.string().nullable(),
  initials: Yup.string().nullable(),
  stateReg: Yup.string().nullable(),
  cep: Yup.string().nullable(),
  users: Yup.number().nullable(),
  isActive: Yup.boolean().nullable(),
  isRegFinished: Yup.boolean().nullable(),
  createdAt: Yup.string().nullable(),
  updatedAt: Yup.string().nullable(),
  products: Yup.string().nullable(),
  productsSettings: Yup.string().nullable(),
  recurrency: Yup.string().nullable(),
  certSearch: Yup.boolean().nullable(),
  weeklyUpdate: Yup.boolean().nullable(),
  weeklyParticipants: Yup.string().nullable(),
  allowedCerts: Yup.string().nullable(),
  isValidForSearch: IIsValidForSearchSchema.nullable(),
  groups: ICompanyGroup.nullable(),
  has_filial: Yup.boolean().nullable(),
  status: Yup.mixed().oneOf([
    'PENDENCY', 'DOING', 'DONE', 
    'open', 'rectified', 'filled', 
    'reviewed', 'transmitted', 'executed', 
    'inprogress', 'pending', 'paidout', 
    'concluded'
  ]).nullable()
});

export interface ICompanyExportForm {
  csv: boolean;
  xlsx: boolean;
  emails: string[];
}

export interface IListDataCompany {
  guid_company: string;
  cnpj: string;
  cnpjFormatted: string;
  name: string;
  displayName: string;
  type: number;
}
