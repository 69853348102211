import { useEffect, useState } from "react";
import { Datatables } from "../../../../../components/Table/Datatables";
import { IAction, IColumn, IData, IDataMeta, ITableMetaData } from "../../../../../components/Table/table.interface";
import { IObligationResponseCustom } from '../../interfaces/IObligation'
import moment from "moment";
import { BusinessDay } from "../../classes/BusinessDay";
import { ArrowDownIcon, CopyIcon, DeleteIcon } from '@chakra-ui/icons'
import { Image } from "@chakra-ui/react";
import { SelectCollaboratorModal } from '../../../Users/modais/SelectCollaboratorModal';
import { BulkObligationExplanation } from '../BulkObligationModal'



interface IScheduleRulesProps {
  guid_client: string;
  obligations: IData<any>|null;
  setObligations: React.Dispatch<React.SetStateAction<IData<any>|null>>;
  isLoading: boolean;
  viewOnly?: boolean;
}

export const ScheduleRules = (props: IScheduleRulesProps) => {
  const [isResponsibleModalOpen, setIsResponsibleModalOpen] = useState(false);
  const [selectedObligation, setSelectedObligation] = useState<IObligationResponseCustom | null>(null);
  const [obligationsView, setObligationsView] = useState<IData<any> | null>(null);
  const [selectedCollaborators, setSelectedCollaborators] = useState<string[]>([]);

  //Calculates the dates for the Obligations
  const scheduleDateRule = (_line: IObligationResponseCustom) => {
    if (_line.scheduleDate) {
      return moment.utc(_line.scheduleDate).format('DD');
    } else {
      if (_line.isDaily || _line.isAllMonth) {
        //If this obligation is daily the system will set to the last day of the month
        const b = new BusinessDay(parseInt(moment.utc().format("MM")), parseInt(moment.utc().format("YYYY")));
        return b.getLastBusinessDay().format('DD');
      } else {
        return "";
      }
    }
  }

  //Check if the date was redefined with the same rules as the function above
  const checkIfDateWasRedefined = (_line: IObligationResponseCustom) => {
    if (_line.scheduleDate) {
      return false
    } else {
      if (_line.isDaily || _line.isAllMonth) {
        //If this obligation is daily the system will set to the last day of the month
        return true;
      } else {
        return false;
      }
    }
  }

  const columns = [
    {
      title: 'Vencimento',
      name: 'dueDate',
      sortable: false,
      align: 'center',
      formatter: (field: string) => {
        switch (field) {
          case 'util_1': return '1° dia útil';
          case 'util_2': return '2° dia útil';
          case 'util_3': return '3° dia útil';
          case 'util_4': return '4° dia útil';
          case 'util_5': return '5° dia útil';
          case 'util_6': return '6° dia útil';
          case 'util_7': return '7° dia útil';
          case 'util_8': return '8° dia útil';
          case 'util_9': return '9° dia útil';
          case 'util_10': return '10° dia útil';
          case 'util_11': return '11° dia útil';
          case 'util_12': return '12° dia útil';
          case 'util_13': return '13° dia útil';
          case 'util_14': return '14° dia útil';
          case 'util_15': return '15° dia útil';
          case 'antepenultimate': return 'Ante-Penultimo dia útil';
          case 'penultimate': return 'Penúltimo dia útil';
          case 'lastday': return 'Último dia útil';
          case 'specifydate': return 'Especificar Dia';
          default: return '-';
        }
      },
      fieldDataToAdditionalRow: {
        type: 'select',
        placeholder: 'Regras',
        defaultValue: '',
        className: 'form-control',
        required: true,
        options: [
          {
            label: '1° dia útil',
            value: 'util_1'
          },
          {
            label: '2° dia útil',
            value: 'util_2'
          },
          {
            label: '3° dia útil',
            value: 'util_3'
          },
          {
            label: '4° dia útil',
            value: 'util_4'
          },
          {
            label: '5° dia útil',
            value: 'util_5'
          },
          {
            label: '6° dia útil',
            value: 'util_6'
          },
          {
            label: '7° dia útil',
            value: 'util_7'
          },
          {
            label: '8° dia útil',
            value: 'util_8'
          },
          {
            label: '9° dia útil',
            value: 'util_9'
          },
          {
            label: '10° dia útil',
            value: 'util_10'
          },
          {
            label: '11° dia útil',
            value: 'util_11'
          },
          {
            label: '12° dia útil',
            value: 'util_12'
          },
          {
            label: '13° dia útil',
            value: 'util_13'
          },
          {
            label: '14° dia útil',
            value: 'util_14'
          },
          {
            label: '15° dia útil',
            value: 'util_15'
          },
          {
            label: 'Ante-Penultimo dia útil',
            value: 'antepenultimate'
          },
          {
            label: 'Penúltimo dia útil',
            value: 'penultimate'
          },
          {
            label: 'Último dia útil',
            value: 'lastday'
          },
          {
            label: 'Especificar Dia',
            value: 'specifydate'
          },
        ]
      },
    },
    {
      title: 'Dia',
      name: 'scheduleDate',
      sortable: false,
      align: 'center',
      formatter: (field: string) => {
        const date = new Date(field);
        
        if (/^\d{1,2}$/.test(field)) {
          const date = new Date();
          date.setDate(parseInt(field, 10)); // Define o dia do mês para o valor fornecido
          return date.getDate();
        }

        if (isNaN(date.getTime())) {
          return field;
        }

        return date.getDate();
      },
      icon: (row: IObligationResponseCustom) => {
        if(parseInt(scheduleDateRule(row)) < parseInt(moment.utc().format("DD"))) {
          return {
            tooltipText: 'Obrigações já vencidas para o calendário desse mês serão criadas como atrasadas',
            iconUrl: '/icons/alert.svg',
            show: true
          }
        }

        if(checkIfDateWasRedefined(row)) {
          return {
            tooltipText: 'Esta obrigação dura por todo o mês ou não possui dia específico, por esse motivo, o último dia útil do mês será utilizado como regra.',
            iconUrl: '/icons/alert.svg',
            show: true
          }
        }

        return {
          tooltipText: '',
          iconUrl: '',
          show: false
        }
      },
      fieldDataToAdditionalRow: {
        type: 'number',
        placeholder: 'Dia',
        defaultValue: '',
        className: 'form-control',
        required: true,
        disabled: (row: any): boolean => {
          if(row.dueDate === 'specifydate') {
            return false;
          }
          return true;
        },
      },
    },
    {
      title: 'DARF / GPS',
      name: 'code',
      sortable: false,
      align: 'center',
      fieldDataToAdditionalRow: {
        type: 'number',
        placeholder: 'DARF / GPS',
        defaultValue: '',
        className: 'form-control',
        required: true,
      },
    },
    {
      title: 'Jurisdição',
      name: 'jurisdiction',
      sortable: false,
      align: 'center',
      fieldDataToAdditionalRow: {
        type: 'select',
        placeholder: 'Jurisdição',
        defaultValue: '',
        className: 'form-control',
        required: true,
        options: [
          {
            label: 'Federal',
            value: 'federal'
          },
          {
            label: 'Estadual',
            value: 'state'
          },
          {
            label: 'Municipal',
            value: 'municipal'
          }
        ]
      },
      tags: [
        {
          text: 'Federal',
          value: 'federal',
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Municipal',
          value: 'municipal',
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
        {
          text: 'Estadual',
          value: 'state',
          textColor: '#322659',
          color: '#E9D8FD',
        },
      ],
    },
    {
      title: 'Frequência',
      name: 'frequency',
      sortable: false,
      align: 'center',
      formatter: (field: string) => {
        switch (field) {
          case "weekly":
            return "Semanal";

          case "controlled":
            return "Controlado";

          case "monthly":
            return "Mensal";

          case "quarterly":
            return "Trimestral";

          case "semiannual":
            return "Semestral";

          case "yearly":
            return "Anual";

          case "onetime":
            return "Única";

          default:
            return "-";
        }
      },
      fieldDataToAdditionalRow: {
        type: 'select',
        placeholder: 'Frequência',
        defaultValue: '',
        className: 'form-control',
        required: true,
        options: [
          {
            label: 'Mensal',
            value: 'monthly'
          },
          {
            label: 'Trimestral',
            value: 'quarterly'
          },
          {
            label: 'Semestral',
            value: 'semiannual'
          },
          {
            label: 'Anual',
            value: 'yearly'
          },
          {
            label: 'Única',
            value: 'onetime'
          }
        ]
      },
    },
    {
      title: 'Descrição',
      name: 'title',
      sortable: false,
      align: 'center',
      reduce: 35,
      fieldDataToAdditionalRow: {
        type: 'text',
        placeholder: 'Descrição',
        defaultValue: '',
        className: 'form-control',
        required: true,
      },
    },
    {
      title: 'Responsável',
      name: 'collaborators',
      sortable: false,
      align: 'center',
      formatter: (field: any) => {
        return field?.length ?? 0;
      },
      fieldDataToAdditionalRow: {
        type: 'text_line',
        placeholder: 'Responsável',
        defaultValue: '',
        className: 'form-control',
        required: true,
        formatter: (value: any) => {
          if(Array.isArray(value)) {
            return value.length;
          }

          if(!value) {
            return 0;
          }

          return value ?? 0;
        }
      },
    }
  ] as IColumn[];

  const actions = [
    {
      text: 'Adicionar responsável',
      icon: <Image src="/icons/table/user.svg" width={5} height="auto" />,
      isVisible: (obligation: IObligationResponseCustom, fatherRow: any, index: number): boolean => {return true},
      isDisabled: (obligation: IObligationResponseCustom): boolean => {return false},
      action: (obligation: IObligationResponseCustom, fatherRow: any, index: number, rows: IObligationResponseCustom[]) => {
        setSelectedObligation(obligation);
        setSelectedCollaborators(obligation.collaborators);

        setIsResponsibleModalOpen(true);
      },
    },
    {
      text: 'Replicar para a linhas a baixo',
      icon: <ArrowDownIcon boxSize={4} />,
      isVisible: (obligation: IObligationResponseCustom, fatherRow: any, index: number): boolean => {
        if(index === 0) {
          return true;
        }

        return false;
      },
      isDisabled: (obligation: IObligationResponseCustom): boolean => { return false },
      action: (currentObligation: IObligationResponseCustom, fatherRow: any, index: number, rows: any) => {
        if (props.obligations) {
          const updatedAdditionalRowsValues = props.obligations.data.map((row: any) => {
            row['collaborators'] = currentObligation.collaborators;

            return row;
          });

          props.setObligations({
            data: updatedAdditionalRowsValues,
            meta: props.obligations.meta
          });
        }
      }
    },
    {
      text: 'Copiar responsáveis da linha de cima',
      icon: <CopyIcon />,
      isVisible: (obligation: IObligationResponseCustom, fatherRow: any, index: number): boolean => {
        if(index > 0) {
          return true;
        }

        return false;
      },
      isDisabled: (obligation: IObligationResponseCustom): boolean => { return false },
      action: (currentObligation: IObligationResponseCustom, fatherRow: any, index: number, rows: IObligationResponseCustom[]) => {
        if(props.obligations && props.setObligations) {
          let data = rows;
          const previousObligation = data[index - 1];

          data[index]['collaborators'] = previousObligation['collaborators'];
          props.setObligations({
            data: data,
            meta: props.obligations.meta
          });
        }
      }
    }, {
      text: 'Remover obrigação',
      icon: <Image src="/icons/table/minus.svg" width={5} height="auto" />,
      isVisible: (obligation: IObligationResponseCustom, fatherRow: any, index: number): boolean => {return true},
      isDisabled: (obligation: IObligationResponseCustom): boolean => {return false},
      action: (obligation: IObligationResponseCustom, fatherRow: any, index: number, rows: IObligationResponseCustom[]) => {
        if (props.obligations?.meta) {
          const updatedObligations = [...rows];

          updatedObligations.splice(index, 1);
          props.setObligations({
            data: updatedObligations,
            meta: props.obligations.meta
          });
        }
      },
    },
  ] as IAction[];

  const applyReponsibleToObrigations = (collaborators: any) => {
    if (props.obligations && selectedObligation) {
      const updatedObligations = props.obligations.data.map((obligation) => {
        if (obligation.datatablesIdentify === selectedObligation?.datatablesIdentify) {
          return {
            ...obligation,
            collaborators,
          };
        }
        return obligation;
      });

      props.setObligations({
        data: updatedObligations,
        meta: props.obligations.meta
      });
    }
    setSelectedObligation(null);
  }

  useEffect(() => {
    if(props.obligations?.meta) {
      const viewObligations = props.obligations?.data.map((obligation) => {
        return {
          ...obligation,
          isDatatablesEditable: false
        }
      });

      setObligationsView({
        meta: props.obligations.meta,
        data: viewObligations
      });
    }
  }, [props.obligations]);

  return (
    <>
      <Datatables
        name="Obrigações fiscais"
        columns={columns}
        actions={props.viewOnly ? [] : actions}
        data={props.viewOnly ? obligationsView : props.obligations}
        dataHook={props.setObligations}
        isLoading={props.isLoading}
        showTotalRows={true}
        heigth="400px"
        pagination={false}
        rowByPage={false}
        customButtons={
          props.viewOnly === false || props.viewOnly === undefined 
          ? [{
              label: 'Cadastro manual',
              id: 'manual-create',
              className: 'btn btn-primary',
              action: 'addNewRow',
              mr: 3
            }, {
              label: 'Cadastro via excel',
              id: 'manual-create',
              className: 'btn btn-primary ',
              action: 'uploadXslx',
              mr: 3
            }, {
              customComponent: <BulkObligationExplanation />,
              pt: 1.5
            }]
          : []
        }
      />

      <SelectCollaboratorModal
        isMulti={true}
        bigSelector={true}
        guid_client={props.guid_client}
        openModal={isResponsibleModalOpen}
        openModalHook={setIsResponsibleModalOpen}
        callback={(collaborators) => applyReponsibleToObrigations(collaborators)}
        selected={selectedCollaborators}
      />
    </>
  );
};
