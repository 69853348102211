import {
  Flex,
  Text,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  ICompanyResponse,
  IResponse,
} from "../../../_services/interface/user.interface";
import moment from "moment";
import { CardsTabPanel } from "./components/TabPanels/CardsTabPanel";
import { ScheduleTabPanel } from "./components/TabPanels/ScheduleTabPanel";
import { FileTabPanel } from "./components/TabPanels/FileTabPanel";
import {
  ICollaborators,
  IGetEmployeesResponse,
} from "../../../_services/interface/company.interface";
import { getEmployees } from "../../../_services/company.service";
import { ICardList } from "./interfaces/ICardList";
import {
  getAllFiles,
  getAllSchedules,
  getKanban,
  getKanbanPost,
} from "./_services/kanban.service";
import { IScheduleList } from "./interfaces/IObligation";
import { IFiles } from "./interfaces/IFiles";
import { EToastStatus } from "../../../components/ToastEnum";
import { getClientEmployees } from "../../../_services/client.service";
import { IGetCards, IGetCardsResponse } from "../../../_services/interface/kanban.interface";
import { FilterDataSelected, FilterTypeEnum } from "../../../components/Filters";

interface ITaxKanbanProps {
  validation: IResponse;
  guid_client: string;
}

export const TaxKanban = (props: ITaxKanbanProps) => {
  const toast = useToast();

  const [kanbanData, setKanbanData] = useState<ICardList[]>([]);
  const [filterData, setFilterData] = useState<IGetCards>();
  const [refreshCardsData, setRefreshCardsData] = useState<boolean>(true);

  const [allUsers, setAllUsers] = useState<ICollaborators[]>([]);
  const [scheduleData, setScheduleData] = useState<IScheduleList[]>([]);
  const [refreshScheduleData, setRefreshScheduleData] = useState<boolean>(false);

  const [allFiles, setAllFiles] = useState<IFiles[]>([]);
  const [refreshFilesData, setRefreshFilesData] = useState<boolean>(false);

  const [isLoading, setLoadingState] = useState<boolean>(true);

  const [monthFilter, setMonthFilter] = useState<string>(
    parseInt(moment.utc().format("MM")).toString()
  );
  const [yearFilter, setYearFilter] = useState<string>(
    parseInt(moment.utc().format("YYYY")).toString()
  );

  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const errorToast = (message: string) => {
    toast({
      title: "Ocorreu um erro",
      description: message,
      status: EToastStatus.ERROR,
      duration: 5000,
      isClosable: true,
    });
  };

  const getCardsData = async () => {
    setRefreshCardsData(true);
    const { status, response } = await getKanbanPost(props.guid_client, filterData);
    if (status === 200) {
      //Get the Kanban Data
      setKanbanData((response as IGetCardsResponse).allCards as ICardList[]);
    } else {
      errorToast("Ocorreu um erro ao tentar obter os cards do kanban");
    }
    setRefreshCardsData(false);
  };

  const getFilesData = async () => {
    setLoadingState(true);
    const allFilesRequest = await getAllFiles(props.guid_client);
    if (allFilesRequest.status === 200) {
      setAllFiles(allFilesRequest.response as IFiles[]);
    } else {
      errorToast("Ocorreu um erro ao tentar obter os arquivos do kanban");
    }
    setLoadingState(false);
  };

  useEffect(() => {
    getCardsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    if (refreshCardsData) {
      getCardsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCardsData]);

  useEffect(() => {
    if (refreshFilesData) {
      getFilesData();
      setRefreshFilesData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFilesData]);

  const onChangeTabs = (tab: any) => {
    if (tab === 0) {
      setRefreshCardsData(true);
    } else if (tab === 1) {
      setRefreshScheduleData(true);
    } else if (tab === 2) {
      setRefreshFilesData(true);
    }
  };

  return (
    <Flex
      w="100%"
      direction="column"
      p="0"
      flexGrow="1"
      flexShrink="1"
      mr="10px"
      pt="10px"
      height="100%"
      gap={4}
      alignItems="stretch"
    >
      <Flex flexDirection="column" height="100%">
        <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">{`Tax Kanban ${
          !monthFilter || !yearFilter
            ? ""
            : ` - ${months[parseInt(monthFilter) - 1]}/${parseInt(yearFilter)}`
        }`}</Text>
        <Tabs
          onChange={onChangeTabs}
          variant="unstyled"
          align="end"
          colorScheme="blue"
          mt={-8}
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          <TabList borderBottom="1px solid" borderColor="gray.300">
            <Tab
              fontSize={14}
              borderTopRadius={5}
              _selected={{ color: "white", bg: "#4B4EFF" }}
            >
              Kanban
            </Tab>
            <Tab
              fontSize={14}
              borderTopRadius={5}
              _selected={{ color: "white", bg: "#4B4EFF" }}
            >
              Agendamentos
            </Tab>
            <Tab
              fontSize={14}
              borderTopRadius={5}
              _selected={{ color: "white", bg: "#4B4EFF" }}
            >
              Arquivos
            </Tab>
          </TabList>
          <TabPanels height="100%">
            <CardsTabPanel
              guid_client={props.guid_client}
              kanbanData={kanbanData}
              isLoading={refreshCardsData}
              flushHook={setRefreshCardsData}
              setFilterData={setFilterData}

            />
            <ScheduleTabPanel
              guid_client={props.guid_client}
              refreshHook={setRefreshScheduleData}
              refreshData={refreshScheduleData}
            />
            <FileTabPanel
              guid_client={props.guid_client}
              allFiles={allFiles}
              isLoading={isLoading}
            />
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  );
};
