import { Flex, Image, Tooltip } from "@chakra-ui/react";
import { IStatus } from "../../../../../_services/interface/certificate.interface";

interface IPropsStatus {
    hasLastIssued?: boolean;
    message?: string|null;
    status: IStatus | null;
    w?: string;
    fontSize?: string;
}

export const Status = (props: IPropsStatus) => {
  let lastIssuedIcon = null;

  if (props.hasLastIssued) {
    lastIssuedIcon = (
      <Tooltip label="Empresa possui certidão ainda válida.">
        <Image 
          height={5}
          width={5}
          src="/icons/information-circle-blue.svg" 
          resize="none"
          marginLeft="5px"
        />
      </Tooltip>
    );
  }

    if (props.status) {
      let tooltip = null;
      if (props.status.tooltip_text && props.status.tooltip_icon) {
        tooltip = <Tooltip label={`${props.status.tooltip_text}`}><Image marginLeft="5px" src={`../icons/${props.status.tooltip_icon}.svg`} /></Tooltip>;
      }
      const statusHtml = (
      <Flex 
        width={props.w ? props.w : '136.5px'} 
        color={props.status.textColor ? props.status.textColor : '#1A202C'}  
        backgroundColor={props.status.color as string} 
        fontWeight="100" 
        fontSize={props.fontSize ? props.fontSize : "10px"} 
        alignItems="center" 
        justifyContent="center" 
        p="2px" 
        borderRadius={5}
      >
        { props.status.name } { props.status.slug === 'error' && props.message ? "*" : null }
      </Flex>
      );

      if (props.message) {
        return (
          <>
            <Flex width={props.w ? props.w : ''} display="flex" justifyContent="start">
              <Tooltip label={`Mensagem de retorno obtida da fonte de consulta: ${props.message}`}>
                {statusHtml}
              </Tooltip>
            </Flex>
            {tooltip}
            {lastIssuedIcon}
          </>
        );
      }
      return (
        <>
          <Flex width={props.w ? props.w : ''} display="flex" justifyContent="start">
            {statusHtml}
          </Flex>
          {tooltip}
          {lastIssuedIcon}
        </>
      );
    }
    return (<></>);
  }