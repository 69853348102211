export const downloadFile = (url: string, fileName: string) => {
  return new Promise((resolve, reject) => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setTimeout(resolve, 2000);
    } else {
      reject(new Error('Invalid URL'));
    }
  });
};
