import { useDisclosure, Modal, ModalOverlay, ModalContent, Button, ButtonGroup } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { IBulkCompanyList, Step1Form } from "./Step1Form";
import { StepLoading } from "./StepLoading";
import { Step3Confirmation } from "./Step3Confirmation";
import { postBulkCreateCompany } from "../../../../../../_services/company.service";
import { IBulkUpdateCompany, IBulkUpdateCompanyItem } from "../../../../../../_services/interface/company.interface";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";

interface IProps {
  guid_client: string;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
  bulkUpdate: IBulkUpdateCompany | undefined;
  bulkUpdateItens: IBulkUpdateCompanyItem[] | undefined;
  refreshBulkUpdate: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showInfoToast: boolean;
  setShowInfoToast: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateModal = (props: IProps) => {
  const [companyList, setCompanyList] = useState<IBulkCompanyList[]>([]);
  const [step, setStep] = useState<number>(1);

  const close = () => {
    props.setIsOpen(false);
    setStep(1);
    setCompanyList([]);
    props.flushHook(true);
  }

  const nextStep = async (updatedCompanyList?: IBulkCompanyList[]) => {
    const nextStep = step + 1;

    if (nextStep > Object.keys(steps).length) {
      return;
    }

    const currentCompanyList = updatedCompanyList ?? companyList;

    if (nextStep === 2 && currentCompanyList.length === 0) {
      return;
    }

    if (nextStep === 2) {
      await postBulkCreateCompany(props.guid_client, { companies: currentCompanyList });
      props.refreshBulkUpdate();
    }

    if (nextStep === 2 && currentCompanyList.every((company) => company.errorMessage === "CNPJ_INVALIDO")) {
      setStep(5);
      return;
    }

    setStep(nextStep);
  };

  const stepTwo = (companies: IBulkCompanyList[]) => {
    setCompanyList(companies);
    nextStep(companies);
  }

  const steps: { [key: number]: JSX.Element } = {
    1: <Step1Form onClose={close} nextStep={stepTwo} />,
    2: (
      <StepLoading
        title="Estamos pesquisando as suas empresas"
        h1="Consultando todas as nossas fontes de dados..."
        h2="Por favor, aguarde"
        image="/images/bulk-companies/loading1.png"
      />
    ),
    3: (
      <StepLoading
        title="Validando as informações encontradas"
        h1="Verificando se há erros nas informações encontradas..."
        h2="Por favor, aguarde mais um pouco"
        image="/images/bulk-companies/loading2.png"
      />
    ),
    4: (
      <StepLoading
        title="Estamos quase lá"
        h1="Enriquecendo os cadastros das suas empresas..."
        h2="Por favor, aguarde mais um pouco"
        image="/images/bulk-companies/loading3.png"
      />
    ),
    5: <Step3Confirmation
        guid_client={props.guid_client}
        onClose={close}
        onCloseWithToast={() => {props.setShowInfoToast(true); close();}}
        socket={props.socket}
        items={props.bulkUpdateItens}
        bulkUpdate={props.bulkUpdate}
      />,
  };

  useEffect(() => {
    if (props.isOpen) {
      if(props.bulkUpdate && props.bulkUpdateItens && props.bulkUpdateItens.length > 0) {
        setStep(5);
      } else {
        setStep(1);
      }
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (step >= 2 && step < 5) {
      const timer = setTimeout(() => {
        setStep((prevStep) => prevStep + 1);
      }, 20000); // 60000ms = 1 minute

      return () => clearTimeout(timer);
    }
  }, [step]);

  return (
    <>
      <ButtonGroup onClick={() => props.setIsOpen(true)} size="sm" isAttached color="white">
        <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: "#282be0" }}>
          Criar empresas em lote
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={props.isOpen} closeOnEsc={false} onClose={close} size="6xl">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          {steps[step] ?? steps[1]}
        </ModalContent>
      </Modal>
    </>
  );
};
