import { CancelToken } from "axios";
import { ApiCall, ApiAuth } from "./api";
import {
  IAddCNDForm,
  IAddCNDResponse,
  ICNDModalParams,
  ICndMultiDownloadInput,
  ICNDResponse,
  ICNDRetrieved,
  ICNDTimeSaving,
  IDeleteResponse,
  IDividaAtiva,
  IFilterListCertificate,
  IRetriveAllCerts,
  ICNDDetailsResponse,
  IExportCertificates,
  IExportCertificatesResponse,
  ICNDList,
  ICertificateExpiredGraph,
  IExportCertificateGraph,
  ICertificateRenewedGraph,
} from "./interface/cnd.interface";
import { TPremiseResponse } from "./interface/IDefaults";
import queryString from 'query-string';

export const getCNDFederalList = async (guid_company: string): Promise<TPremiseResponse<ICNDResponse[]>> => {
  return await new ApiCall(`/company/cnd/${guid_company}/federal/list`, ApiAuth()).get<ICNDResponse[]>();
};

export const getCNDStateList = async (guid_company: string): Promise<TPremiseResponse<ICNDResponse[]>> => {
  return await new ApiCall(`/company/cnd/${guid_company}/state/list`, ApiAuth()).get<ICNDResponse[]>();
};

export const getCNDCityList = async (guid_company: string): Promise<TPremiseResponse<ICNDResponse[]>> => {
  return await new ApiCall(`/company/cnd/${guid_company}/municipal/list`, ApiAuth()).get<ICNDResponse[]>();
};

export const retrieveCertificaesByGuidCnd = async(guid_client: string, guid_cnd: string[]): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/cnd/retrieve-by-guid-cnd/${guid_client}`, ApiAuth()).post<any,unknown>(guid_cnd);
}

export const reprocessCertificateErrorsBulkUpdate = async(guid_client: string, guid_bulk_update: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/cnd/reprocess/${guid_client}/${guid_bulk_update}`, ApiAuth()).post<any,unknown>();
}

export const listCnds = async (guid_client: string, filters: IFilterListCertificate, cancelToken?: CancelToken): Promise<TPremiseResponse<ICNDList>> => {
  const url = `/cnd/list/${guid_client}`;
  return await new ApiCall(url, ApiAuth(), cancelToken).post<ICNDList, IFilterListCertificate>(filters);
};

export const listCertificateHistoryData = async (guid_client: string, filters: IFilterListCertificate, type: 'vigentes'|'vencer'|'vencidas'|'renovadasAuto'|'renovadasManual'|'renovadasTotal',  cancelToken?: CancelToken): Promise<TPremiseResponse<ICNDList>> => {
  const url = `/cnd/list-history/${guid_client}/${type}`;
  return await new ApiCall(url, ApiAuth(), cancelToken).post<ICNDList, IFilterListCertificate>(filters);
};

export const listCertificateExpiredGraph = async (guid_client: string, filters: IFilterListCertificate, cancelToken?: CancelToken): Promise<TPremiseResponse<ICertificateExpiredGraph>> => {
  const url = `/cnd/list-expired-graph/${guid_client}`;
  return await new ApiCall(url, ApiAuth(), cancelToken).post<ICertificateExpiredGraph, IFilterListCertificate>(filters);
};

export const listCertificateRenewedGraph = async (guid_client: string, filters: IFilterListCertificate, cancelToken?: CancelToken): Promise<TPremiseResponse<ICertificateRenewedGraph>> => {
  const url = `/cnd/list-renewed-graph/${guid_client}`;
  return await new ApiCall(url, ApiAuth(), cancelToken).post<ICertificateRenewedGraph, IFilterListCertificate>(filters);
};

export const exportCertificateGraph = async (guid_client: string, filters: IFilterListCertificate, type: 'expired'|'renewed', cancelToken?: CancelToken): Promise<TPremiseResponse<IExportCertificateGraph>> => {
  const url = `/cnd/export-certificate-graph/${guid_client}/${type}`;
  return await new ApiCall(url, ApiAuth(), cancelToken).post<IExportCertificateGraph, IFilterListCertificate>(filters);
};

export const getMacroCndData = async (guid_client: string, filters: IFilterListCertificate, cancelToken?: CancelToken): Promise<TPremiseResponse<any>> => {
  const queryStringParams = queryString.stringify(filters);
  const url = `/cnd/macro/${guid_client}?${queryStringParams}`;
  return await new ApiCall(url, ApiAuth(), cancelToken).get<any>();
};

export const postAddCND = async (guid_client: string, formdata: IAddCNDForm): Promise<TPremiseResponse<IAddCNDResponse>> => {
  return await new ApiCall(`/cnd/create/${guid_client}`, ApiAuth({ "Content-Type": "multipart/form-data" }))
    .post<IAddCNDResponse, IAddCNDForm>(formdata);
};

export const deleteCND = async (guid_cnd: string, guid_certificate: string, guid_client: string): Promise<TPremiseResponse<IDeleteResponse>> => {
  return await new ApiCall(`/cnd/${guid_client}/${guid_certificate}/${guid_cnd}`, ApiAuth()).delete<IDeleteResponse>();
}

export const getRetrieveCND = async (guid_company: string): Promise<TPremiseResponse<ICNDRetrieved>> => {
  return await new ApiCall(`/company/retrieve/cnd/${guid_company}`, ApiAuth()).get<ICNDRetrieved>();
}

export const getRetrieveCNDByType = async (guid_client: string, guid_company: string, type: string, persona?: boolean): Promise<TPremiseResponse<boolean>> => {
  const url = `/cnd/${guid_client}/${guid_company}/${type}/${persona ? 1 : 0}`;
  return await new ApiCall(url, ApiAuth({timeout: '1200000'})).get<boolean>();
}

export const downloadAllCnds = async (guid_client: string, cnds: ICndMultiDownloadInput): Promise<any> => {
  return await new ApiCall(`/company/cnd/${guid_client}/downloadall`, ApiAuth()).post<ICndMultiDownloadInput, any>(cnds);
}

export const exportCnds = async (guid_client: string, data: IExportCertificates): Promise<TPremiseResponse<IExportCertificatesResponse>> => {
  return await new ApiCall(`/cnd/${guid_client}/export`, ApiAuth()).post<IExportCertificatesResponse, IExportCertificates>(data);
}

export const cndDetails = async (
  data: ICNDModalParams,
  isError: boolean
): Promise<TPremiseResponse<ICNDDetailsResponse>> => {
  const { guid_client, guid_company, guid_persona, type, state, city } = data;
  let route = `/cnd/details/${guid_client}/${guid_company ?? guid_persona}/${type}/${guid_persona ? 1 : 0}`;

  if (state) {
    route += `/${state}`;
    if (city) {
      route += `/${city}`;
    }
  }

  route = isError ? route + `?isError=${isError}` : route;

  return await new ApiCall(route, ApiAuth()).get<ICNDDetailsResponse>();
};

export const retriveAllCnds = async (guid_company: string, type: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company/retriveall/${guid_company}/${type}`, ApiAuth()).get<IRetriveAllCerts>();
}

export const getTimeSavingByType = async (guid_company: string, type: string, startDate: string, endDate: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company/cnd/time-saving/${guid_company}/${type}?startDate=${startDate}&endDate=${endDate}`, ApiAuth()).get<ICNDTimeSaving>();
}

export const getTimeSaving = async (guid_company: string, startDate: string, endDate: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company/cnd/time-saving/${guid_company}?startDate=${startDate}&endDate=${endDate}`, ApiAuth()).get<ICNDTimeSaving>();
}

export const getDividaAtiva = async (guid_client: string, guid_company: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company/cnd/divida-ativa/${guid_client}/${guid_company}`, ApiAuth()).get<IDividaAtiva[]>();
}

export const CNDIsRunning = async (slug: string, guid_company: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/datasource-queue/is-running/${slug}/${guid_company}`, ApiAuth()).get<any>();
};