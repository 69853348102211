import { Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { IAction } from "./table.interface";
import { Overlay, Popover } from 'react-bootstrap';

interface IProps {
  guid_client?: string;
  actions: IAction[];
  customActions?: (row: any) => IAction[];
  row: any;
  rows: any;
  rowFather?: any;
  index: number;
}

export const Actions = (props: IProps) => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);
  const [actions, setActions] = useState<IAction[]>();
  const [placement, setPlacement] = useState<'top' | 'bottom'>('bottom');

  useEffect(() => {
    if (props.customActions) {
      const customActions = props.customActions({cnd: props.row, guid_client: props.guid_client});
      setActions([...props.actions, ...customActions]);
    } else {
      setActions(props.actions);
    }
  }, [props.customActions, props.row]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (targetRef.current && !targetRef.current.contains(event.target as Node)) {
        setOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [targetRef]);

  const toggleOptions = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Se a distância do elemento ao topo da janela for maior que a distância ao final, abre para cima
      if (viewportHeight - rect.bottom < 150) { // 150 é um valor arbitrário, ajuste conforme necessário
        setPlacement('top');
      } else {
        setPlacement('bottom');
      }
    }
    setOptionsOpen(!optionsOpen);
  };

  const onclick = (action: IAction, row: any) => {
    if (action.isDisabled(row, props.rowFather)) {
      return;
    }
    action.action(row, props.rowFather, props.index, props.rows);
    setOptionsOpen(false);
  }

  return (
    <>
      {props.actions.length > 0 && 
        <Flex 
          width="100%"
          justifyContent="end"
        >
          <Flex width="10px" cursor="pointer" onClick={toggleOptions} ref={targetRef}>
            <svg width="10" height="24">
              <circle cx="5" cy="4" r="2" fill="black" />
              <circle cx="5" cy="12" r="2" fill="black" />
              <circle cx="5" cy="20" r="2" fill="black" />
            </svg>
          </Flex>
        </Flex>
      }
      <Overlay
        show={optionsOpen}
        target={targetRef.current}
        placement={placement}
        containerPadding={20}
        rootClose
        onHide={() => setOptionsOpen(false)}
      >
        <Popover id="popover-contained" style={{border:'none', zIndex: '999999'}}>
          <Popover style={{border:'none', zIndex: '999999'}}>
            <Flex 
              flexDirection="column" 
              backgroundColor="white" 
              border="1px solid #ccc" 
              borderRadius="6px" 
              zIndex={999}
              pt="8px"
              pb="8px"
              gap={3}
              width="max-content"
            >
              {actions?.map((action, index) => (
                action.isVisible(props.row, props.rowFather, props.index) && (
                  <Flex
                    key={index}
                    cursor={action.isDisabled(props.row) ? 'not-allowed' : 'pointer'}
                    color={action.isDisabled(props.row) ? '#A0AEC0' : '#171923'}
                    p="8px"
                    alignItems="center"
                    minWidth="150px"
                    _hover={{bg: '#F7FAFC'}}
                    onClick={() => onclick(action, props.row)}
                  >
                    {action.icon}
                    <Text
                      ml="10px"
                      fontFamily="Poppins-medium"
                      fontSize="14px"
                    >
                      {action.text instanceof Function ? action.text(props.row) : action.text}
                    </Text>
                  </Flex>
                )
              ))}
            </Flex>
          </Popover>
        </Popover>
      </Overlay>
    </>
  );
};
