import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { SingleValue } from 'react-select';
import { customStyles } from './Table/Inputs/CustomAsyncSelect';

interface CustomAsyncSelectProps {
  value: string|number|null;
  setValue: (value: string|number) => void;
  placeholder: string;
  options: IOption[] | (() => Promise<IOption[]>);
  isDisabled?: boolean;
}

export interface IOption {
  value: string|number;
  label: string;
}

const CustomAsyncSelect: React.FC<CustomAsyncSelectProps> = ({ value, options, placeholder, isDisabled, setValue }) => {
  const [loadedOptions, setLoadedOptions] = useState<IOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  const loadOptions = async () => {
    setIsLoading(true);
    let newOptions: IOption[] = [];
    if (typeof options === 'function') {
      newOptions = await options();
    } else if (Array.isArray(options)) {
      newOptions = options;
    }
    setLoadedOptions(newOptions);

    setIsLoading(false);
    setInitialized(true);
    return newOptions;
  };

  useEffect(() => {
    loadOptions();
  }, [options]);

  useEffect(() => {
    if (loadedOptions.length > 0 && value !== null && value !== undefined) {
      const matchedOption = loadedOptions.find((option) => String(option.value) === String(value));
      if (matchedOption) {
        setSelectedOption(matchedOption);
      } else {
        setSelectedOption(null);
      }
    } else if (value === null || value === undefined) {
      setSelectedOption(null);
    }
  }, [value, loadedOptions]);

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={async (inputValue) => {
        const filteredOptions = (await loadOptions()).filter(option =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filteredOptions;
      }}
      styles={customStyles}
      defaultOptions={loadedOptions}
      value={selectedOption}
      isLoading={isLoading}
      onFocus={loadOptions}
      placeholder={placeholder}
      isDisabled={isDisabled ? true : false}
      menuPortalTarget={document.body}
      onChange={(selectedOption: SingleValue<{ label: string; value: string | number | readonly string[] }>) => {
        const selected = selectedOption as IOption;
        setSelectedOption(selected);
        setValue(selected.value);
      }}
    />
  );
};

export default CustomAsyncSelect;
