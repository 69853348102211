import { ComponentStyleConfig } from "@chakra-ui/react";

export const Link: ComponentStyleConfig = {
  baseStyle: {
    color: "solid.primary",
    fontFamily: "Gotham-normal",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "140%",
  },
};
