import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../../components/login/button.component";
import { removeCompanyOfCompanyGroup } from "../../../../../_services/companyGroup.service";
import useLoginFormStore from "../../../../../store/useLoginFormStore";
import { ICompany } from "../../../../../_services/interface/company.interface";

interface IDesativarCompanyGroupModalProps {
  group: any;
  company: ICompany;
  guid_client: string;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  openModal?: boolean;
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RemoveCompanyGroupModal = (props: IDesativarCompanyGroupModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);
  const toast = useToast();

  const yesOption = async () => {
    setButtonDisabled(true);

    const { status, response } = await removeCompanyOfCompanyGroup(props.guid_client, props.group.guid_company_group, props.company.guid_company);
  
    if (status === 200) {
      toast({
        title: `Empresa Removida`,
        description: `A empresa ${props.company.name} foi removida do grupo ${props.group.name} com sucesso!`,
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      props.flushHook(true);
      onClose();
    } else {
      toast({
        title: 'Falha ao remover empresa',
        description: `Algum erro ocorreu ao tentar remover a empresa ${props.company.name} dop grupo ${props.group.name}. Tente novamente mais tarde`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
      props.flushHook(true);
      onClose();
    }
    setButtonDisabled(false);
  };

  useEffect(() => {
    if(props.openModalHook && props.openModal && props.group && props.company ) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal, props.group, props.company]);

  return (
    <>

      <Modal closeOnOverlayClick={false} isCentered={true} isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Deseja realmente remover essa empresa?</ModalHeader>
          
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Text>Tem certeza que deseja remover a empresa <strong>{props.company.name}</strong> do grupo <strong>{props.group.name}</strong>?</Text>
              <Flex  justifyContent="end" alignItems={'center'}>
                <Flex marginRight="10px">
                  <Text
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                    cursor="pointer"
                    color="#171923"
                    marginRight={'10px'}
                    onClick={onClose}
                  >
                    Cancelar e Fechar
                  </Text>
                </Flex>
                <FormButton onClick={yesOption} disabled={isButtonDisabled} color="#4B4EFF" width="150px">Remover</FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}