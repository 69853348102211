
import { useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { TrashIcon} from "../Icons/Trash";
import { Tooltip, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Text, Button } from "@chakra-ui/react";
interface IDeleteKanbanCardModalProps {
  cardName?: string;
  callback: () => void;
}

export const DeleteKanbanCardModal = (props: IDeleteKanbanCardModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);

  const yesOption = async () => {
    setButtonDisabled(true);
    props.callback();
    setButtonDisabled(false);
    onClose();
  };

  return (
    <>
      
      <Tooltip label="Deletar card">
        <Flex
          bgColor="#4B4EFF"
          onClick={onOpen}
          fontSize="12px"
          h={29}
          p={3}
          borderRadius={5}
          color="white"
          alignItems="center"
          justifyContent="center"
          _hover={{ cursor: "pointer" }}
        >
          <TrashIcon />
        </Flex>
      </Tooltip>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px)'
          alignItems="center"
        />

        <ModalContent
          flexGrow={1}
          flexShrink={1}
          borderLeft="12px solid #0263FF"
          borderRadius={8}
        >
          <ModalHeader fontFamily="Poppins-Medium">
            Deletar Card
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Text>
                Tem certeza que deseja deletar esse card ?
              </Text>

              <Flex gap={2} flexGrow={1} justifyContent="flex-end" alignItems="center">
                <Button
                  bgColor="white"
                  color="#171923"
                  fontSize="12px"
                  fontFamily="Poppins-medium"
                  _hover={{ bg: 'white', textDecoration: 'underline' }}
                  onClick={onClose}
                >
                  Fechar
                </Button>

                <FormButton
                  onClick={yesOption}
                  disabled={isButtonDisabled}
                  width="50%" 
                  color="#EF4444"
                  _hover={{
                    bgColor: "#EF4444", cursor: "pointer", color:"white"
                  }}
                >
                  Deletar card
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}