export enum EJurisdiction {
  federal = 'federal',
  state = 'state',
  municipal = 'municipal',
}

export enum Efrequency {
  monthly = 'monthly',
  quarterly = 'quarterly',
  semiannual = 'semiannual',
  yearly = 'yearly',
  onetime = 'onetime',
}

export enum EWeekendState {
  toAntecipate = 'toAntecipate',
  toMaintain = 'toMaintain',
  postpone = 'postpone',
}

export enum ECardStatus {
  open = 'Aberto',
  rectified = 'Retificado',
  filled = 'Preenchido',
  reviewed = 'Revisado',
  transmitted = 'Transmitido',
  executed = 'Executado',
  inprogress = 'Em Andamento',
  pending = 'Pendente',
  paidout = 'Pago',
  concluded = 'Concluído',
}

export enum EMonth {
  janeiro = 'Janeiro',
  fevereiro = 'Fevereiro',
  março =  'Março',
  abril = 'Abril',
  maio = 'Maio',
  junho = 'Junho',
  julho = 'Julho',
  agosto = 'Agosto',
  setembro = 'Setembro',
  outubro = 'Outubro',
  novembro = 'Novembro',
  dezembro = 'Dezembro',
}

export enum ESearchField {
  name = 'Nome',
  weekendState = 'Periodicidade',
  description = 'Descrição'
}

export enum EIRSHistory {
  created = 'created',
  statusChanged = 'statusChanged',
  addedFile = 'addedFile',
  updated = 'updated',
  concluded = 'concluded',
  deletedFile = 'deletedFile',
  addedComment = 'addedComment',
  addedResponsible = 'addedResponsible',
  removedResponsible = 'removedResponsible',
  removedCompany = 'removedCompany',
  addedCompany = 'addedCompany',
  certificateStatusChanged = 'certificateStatusChanged',
  addedCertificate = 'addedCertificate',
  removedCertificate = 'removedCertificate',
}

