import { useState } from "react";
import { FormButton } from "../../../../../components/login/button.component";
import { TrashIcon} from "../../Icons/Trash";
import {
  Tooltip,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Image,
} from '@chakra-ui/react'

interface IDeleteModalProps {
  callback: () => void;
  title: string
}

export const DeleteModal = (props: IDeleteModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);

  const yesOption = async () => {
    setButtonDisabled(true);
    props.callback();
    setButtonDisabled(false);
    onClose();
  };

  return (
    <>
      <Tooltip label={props.title}>
        <Flex
          width="32px"
          height="32px"
          bgColor="#4B4EFF"
          onClick={onOpen}
          fontSize="12px"
          borderRadius={5}
          color="white"
          alignItems="center"
          justifyContent="center"
          _hover={{ cursor: "pointer", bgColor: "#d22626" }}
        >
          <Image src="/icons/delete.svg" />
        </Flex>
      </Tooltip>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">{props.title}</ModalHeader>
          <ModalCloseButton />

          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Text>
                Tem certeza que deseja deletar esse {props.title.toLowerCase()}?
              </Text>

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <FormButton onClick={yesOption} disabled={isButtonDisabled} width="50%">
                  Sim
                </FormButton>

                <FormButton onClick={onClose} disabled={isButtonDisabled} width="50%">
                  Não
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}