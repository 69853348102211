import { ApiCall, ApiAuth } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { IRequestAccount, IRequestCertificate, IRequestCompany, IRequestDisplay, IRequestNotification, IRequestTaxCurrentAccount, IRequestTaxKanban, IResponseAccount, IResponseCertificate, IResponseCompany, IResponseDisplay, IResponseNotification, IResponseTaxCurrentAccount, IResponseTaxKanban } from "./interface/config.interface";

export const getConfigAccount = async (): Promise<TPremiseResponse<IResponseAccount>> => {
  return await new ApiCall(`/config/account`, ApiAuth()).get<IResponseAccount>();
};

export const configAccount = async (data: IRequestAccount): Promise<TPremiseResponse<IResponseAccount>> => {
  return await new ApiCall(`/config/account`, ApiAuth()).post<IResponseAccount, IRequestAccount>(data);
};

export const getConfigDisplay = async (guid_client: string): Promise<TPremiseResponse<IResponseDisplay>> => {
  return await new ApiCall(`/config/display/${guid_client}`, ApiAuth()).get<IResponseDisplay>();
};

export const configDisplay = async (guid_client: string, data: IRequestDisplay): Promise<TPremiseResponse<IResponseDisplay>> => {
  return await new ApiCall(`/config/display/${guid_client}`, ApiAuth()).post<IResponseDisplay, IRequestDisplay>(data);
};

export const getConfigCompany = async (guid_client: string): Promise<TPremiseResponse<IResponseCompany>> => {
  return await new ApiCall(`/config/company/${guid_client}`, ApiAuth()).get<IResponseCompany>();
};

export const configCompany = async (guid_client: string, data: IRequestCompany): Promise<TPremiseResponse<IResponseCompany>> => {
  return await new ApiCall(`/config/company/${guid_client}`, ApiAuth()).post<IResponseCompany, IRequestCompany>(data);
};

export const getConfigTaxKanban = async (guid_client: string): Promise<TPremiseResponse<IResponseTaxKanban>> => {
  return await new ApiCall(`/config/tax-kanban/${guid_client}`, ApiAuth()).get<IResponseTaxKanban>();
};

export const configTaxKanban = async (guid_client: string, data: IRequestTaxKanban): Promise<TPremiseResponse<IResponseTaxKanban>> => {
  return await new ApiCall(`/config/tax-kanban/${guid_client}`, ApiAuth()).post<IResponseTaxKanban, IRequestTaxKanban>(data);
};

export const getConfigTaxCurrentAccount = async (guid_client: string): Promise<TPremiseResponse<IResponseTaxCurrentAccount>> => {
  return await new ApiCall(`/config/tax-current-account/${guid_client}`, ApiAuth()).get<IResponseTaxCurrentAccount>();
};

export const configTaxCurrentAccount = async (guid_client: string, data: IRequestTaxCurrentAccount): Promise<TPremiseResponse<IResponseTaxCurrentAccount>> => {
  return await new ApiCall(`/config/tax-current-account/${guid_client}`, ApiAuth()).post<IResponseTaxCurrentAccount, IRequestTaxCurrentAccount>(data);
};

export const getConfigCertificate = async (guid_client: string): Promise<TPremiseResponse<IResponseCertificate>> => {
  return await new ApiCall(`/config/certificate/${guid_client}`, ApiAuth()).get<IResponseCertificate>();
};

export const configCertificate = async (guid_client: string, data: IRequestCertificate): Promise<TPremiseResponse<IResponseCertificate>> => {
  return await new ApiCall(`/config/certificate/${guid_client}`, ApiAuth()).post<IResponseCertificate, IRequestCertificate>(data);
};

export const getConfigNotification = async (guid_client: string): Promise<TPremiseResponse<IResponseNotification>> => {
  return await new ApiCall(`/config/notification/${guid_client}`, ApiAuth()).get<IResponseNotification>();
};

export const configNotification = async (guid_client: string, data: IRequestNotification): Promise<TPremiseResponse<IResponseNotification>> => {
  return await new ApiCall(`/config/notification/${guid_client}`, ApiAuth()).post<IResponseNotification, IRequestNotification>(data);
};