import {
  IClientCompanies
} from '../../../../../../_services/interface/company.interface'
import {
  ITableMetaData
} from '../../../../../../components/Table/table.interface'
import {
  FilterDataSelected,
  FilterTypeEnum,
} from '../../../../../../components/Filters'

export const sortCompanies = (
  companyMetaData: ITableMetaData,
  companies: IClientCompanies[],
  companyChecked: string[]
): IClientCompanies[] => {
  // Mover os registros dentro de companyChecked para o início do array
  const checkedCompanies = companies.filter(company => companyChecked.includes(company.guid_company));
  const uncheckedCompanies = companies.filter(company => !companyChecked.includes(company.guid_company));

  const orderField = companyMetaData.orderField as keyof IClientCompanies;
  const orderDirection = companyMetaData.orderDirection;

  const compareFunction = (a: IClientCompanies, b: IClientCompanies): number => {
    const aValue = a[orderField];
    const bValue = b[orderField];

    if (aValue === undefined || bValue === undefined) {
      return 0;
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      const comparison = aValue.localeCompare(bValue);
      return orderDirection === 'asc' ? comparison : -comparison;
    }

    if (aValue < bValue) {
      return orderDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return orderDirection === 'asc' ? 1 : -1;
    }
    return 0;
  };

  // Ordenar os uncheckedCompanies de acordo com a orderField e orderDirection
  uncheckedCompanies.sort(compareFunction);
  checkedCompanies.sort(compareFunction);

  // Combinar as listas checkedCompanies e sorted uncheckedCompanies
  const sortedData = [...checkedCompanies, ...uncheckedCompanies];

  return sortedData;
};

export const filterCompanies = (
  orderedCompanies: IClientCompanies[],
  filterDataSelected: FilterDataSelected,
  companyChecked: string[]
): IClientCompanies[] => {
  let orderedCompaniesForFilter = orderedCompanies;

  if (orderedCompanies.length > 0) {
    let wasFiltered = false;
    if(filterDataSelected[FilterTypeEnum.companyFilter].length > 0) {
      orderedCompaniesForFilter = orderedCompanies.filter((company) => filterDataSelected[FilterTypeEnum.companyFilter].includes(company.guid_company));
      wasFiltered = true;
    }

    if(filterDataSelected[FilterTypeEnum.companyGroupFilter].length > 0) {
      orderedCompaniesForFilter = orderedCompanies.filter((company) => company.groups.some((r) => filterDataSelected[FilterTypeEnum.companyGroupFilter].includes(r.guid_company_group)));
      wasFiltered = true;
    }

    if(!wasFiltered) {
      orderedCompaniesForFilter = [];
    }

    // Criando uma lista de empresas filtradas
    let filteredCompanies = orderedCompaniesForFilter;

    // Adicionando empresas selecionadas à lista filtrada
    filteredCompanies = filteredCompanies.concat(filteredCompanies.filter(company => companyChecked.includes(company.guid_company)));

    // Removendo duplicatas (se houver)
    filteredCompanies = Array.from(new Set(filteredCompanies));

    return filteredCompanies;
  }

  return [];
}
