export enum EFrequencyType {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum EPriority {
    LOW = 'LOW',
    REGULAR = 'REGULAR',
    HIGH = 'HIGH',
    URGENT = 'URGENT',
}

export enum ECardStatus {
    PENDENCY = 'PENDENCY',
    DOING = 'DOING',
    DONE = 'DONE',
}

export enum ECompanyStatus {
    //card struture
    PENDENCY = 'PENDENCY',
    DOING = 'DOING',
    DONE = 'DONE',

    //card obligation
    open = 'open',
    rectified = 'rectified',
    filled = 'filled',
    reviewed = 'reviewed',
    transmitted = 'transmitted',
    executed = 'executed',
    inprogress = 'inprogress',
    pending = 'pending',
    paidout = 'paidout',
    concluded = 'concluded',
}

export enum FrequencyTypeEnum {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}
