import { Button, ButtonGroup, Checkbox, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IClientCompanies } from "../../../../../_services/interface/company.interface";
import useLoginFormStore from "../../../../../store/useLoginFormStore";
import { EToastStatus } from "../../../../../components/ToastEnum";
import { downloadListCompanies, getCompanyPossibleEmails } from "../../../../../_services/company.service";
import { FormButton } from "../../../../../components/login/button.component";
import SelectMultiEmail from "../../../../../components/SelectMultiEmail";


interface IExportCompaniesProps {
  guid_client: string;
  companiesCount: number|undefined;
}

interface IExportCompaniesForm {
  exportXls: boolean;
  exportCSV: boolean;
}

export const ExportCompanies = (props: IExportCompaniesProps) => {
  const YupCompanyChangeForm = Yup.object().shape({
    exportXls: Yup.bool(),
    exportCSV: Yup.bool(),
    exportGraphic: Yup.bool()
  });

  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  // eslint-disable-next-line
  const [emails, setEmails] = useState<string[]>([])
  const [sendByEmail, setSendByEmail] = useState<boolean>(false)
  const { register, watch, handleSubmit, reset } = useForm<IExportCompaniesForm>(resolverForm);
  const { validate } = useLoginFormStore();

  useEffect(() => {
    if (isOpen) {
      reset({
        exportXls: false,
        exportCSV: false,
      });
    }
  }, [isOpen]);

  const handleFormInfo = async (data: IExportCompaniesForm) => {
    setLoadingStatus(true);

    const toastLoading = toast({
      title: "Processando a Requisição.",
      description: "Aguarde o processamento da requisição, dependendo da quantidade de empresas, pode demorar alguns segundos.",
      status: EToastStatus.LOADING,
      duration: null,
    });

    try {
      if(data.exportXls || data.exportCSV) {
        const response = await downloadListCompanies(props.guid_client, {
          xlsx: data.exportXls,
          csv: data.exportCSV,
          emails: emails && emails.length > 0 && sendByEmail ? emails : [],
        });

        const downloadFile = (url: string, fileName: string) => {
          return new Promise((resolve, reject) => {
            if (url) {
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
              setTimeout(resolve, 2000);
            } else {
              reject(new Error('Invalid URL'));
            }
          });
        };

        const downloadFilesSequentially = async () => {
          try {
            if(Array.isArray(response)) {
              for (const url of response) {
                const urlParts = url.split('/');
                const encodedFileName = urlParts[urlParts.length - 1];
                const fileName = decodeURIComponent(encodedFileName);

                // Baixar o arquivo
                await downloadFile(url, fileName);
              }
            }
          } catch (error) {
            console.error('Error downloading file:', error);
          }
        };
      
        downloadFilesSequentially();
      }
    } catch (error) {
      setLoadingStatus(false);
    }

    onClose();
    toast.close(toastLoading)
    setLoadingStatus(false);
  }

  const getEmailsOptions = async (): Promise<string[]> => {
    let localEmails: string[] = [];
    const { status, response } = await getCompanyPossibleEmails(props.guid_client);
    if (status === 200 && Array.isArray(response)) {
      setEmails(response as string[]);
      localEmails = response as string[];
    }

    return localEmails;
  }

  const getSelectedEmails = async (): Promise<string[]> => {
    if(validate) {
      return [validate.email];
    }

    return [];
  }

  return (
    <Flex>
      <ButtonGroup onClick={onOpen} size="sm" isAttached color="white" >
        <Button color="white" bgColor="#4B4EFF" _hover={{ bg: '#282be0' }}>
          <Flex display="flex" justifyContent="center" alignItems="center">
            <Image height="15px" width="15px" marginRight="5px" src="/icons/download_icon.png" resize="none" />
            Exportar informações
          </Flex>
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="4xl" >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF" maxWidth={800}>
          <ModalHeader fontFamily="Poppins-Medium">Exportar Lista de Empresas</ModalHeader>

          <ModalCloseButton />

          <ModalBody fontFamily="Poppins-Medium">
            <Flex direction="column" fontSize="12px" gap={2} mb={4}>
              <Text>Selecione abaixo os arquivos que deseja exportar:</Text>

              <Text marginTop="15px" fontSize="14px">Exportar relatório geral de empresas:</Text>

              <Checkbox
                size="sm"
                colorScheme="green"
                {...register("exportXls")}>XLS
              </Checkbox>

              <Checkbox
                size="sm"
                colorScheme="green"
                {...register("exportCSV")}>CSV
              </Checkbox>

              <small>Total de empresas no relatório: <strong>{props.companiesCount === undefined ? 0 : props.companiesCount}</strong></small>

              <Flex align="start" mt={6} _hover={{ cursor: "pointer" }} gap={3} onClick={() => setSendByEmail(!sendByEmail)}>
                {sendByEmail ? (
                  <Image w="34px" src="/icons/switch-blue.svg" />
                ) : (
                  <Image w="34px" src="/icons/switch-gray.svg" />
                )}
                <Flex direction="column">
                  <Text fontSize="12px">
                    Gostaria de compartilhar por e-mail? (opcional)
                  </Text>
                </Flex>
              </Flex>

              {sendByEmail && (
                <Flex direction="column">
                  <Text opacity={0.9} mb={2} mt={2}>
                    Digite o(s) email(s) de quem irá receber a exportação (opcional)
                  </Text>
                  <SelectMultiEmail
                    onChange={(_emails: string[]) => {
                      setEmails(_emails);
                    }}
                    getEmailsOptions={getEmailsOptions}
                    getSelectedEmails={getSelectedEmails}
                  />
                </Flex>
              )}

              <FormButton disabled={isLoading} onClick={handleSubmit(handleFormInfo)}>Baixar relatório</FormButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}