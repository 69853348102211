import { Flex, Image, Tooltip } from "@chakra-ui/react";
import React from "react";
import { IElipse, ITag } from "./table.interface";

interface IProps {
  tag: ITag;
  text?: string;
  elipse?: IElipse;
  row?: any;
  height?: string;
}

export const Tag = (props: IProps) => {
  const getText = (): React.ReactElement | null => {
    const text = props.tag.text || props.text;
    return text && text.length > 0 ? <>{text}</> : null;
  };

  const elipse = (): React.ReactNode => {
    if(!props.elipse) {
      return <></>;
    }

    let color = props.elipse.tooltipColorFalse;
    const validation = props.elipse.validation(props.row[props.elipse.columnName]);

    if(props.row && props.row[props.elipse.columnName] && validation) {
      color = props.elipse.tooltipColorTrue;
    }

    if(!validation && !props.elipse.showIfFalse) {
      return (<></>);
    }

    return (
      <Flex marginTop="-4px" marginLeft="-7px">
        <Flex
          w="11px"
          h="11px"
          borderRadius="100%"
          backgroundColor={color}
        />
      </Flex>
    );
  }

  const renderContent = () => (
    <>
      <Flex
        backgroundColor={props.tag.color}
        color={props.tag.textColor}
        alignItems="center"
        justifyContent="center"
        p="2px 15px"
        fontSize="12px"
        fontFamily="Poppins-medium"
        lineHeight="16.8px"
        fontWeight="100"
        borderRadius={5}
        height={props.height ? props.height : '33px'}
        width="fit-content"
        minWidth={props.tag.minWidth || 'auto'}
      >
        {getText()}
      </Flex>

      {elipse()}
    </>
  );


  const renderIcon = (iconUrl: string, tooltipText: string) => (
    <Tooltip label={tooltipText} hasArrow>
      <Image src={iconUrl} />
    </Tooltip>
  );

  if (!getText()) {
    return <Flex>-</Flex>;
  }

  return (
    <Flex justifyContent="left" width="min-content">
      <>
        {props.tag.tootltipText ? (
          <Tooltip label={props.tag.tootltipText} hasArrow>
            {renderContent()}
          </Tooltip>
        ) : (
          renderContent()
        )}

        {props.tag.iconUrl && renderIcon(props.tag.iconUrl, props.tag.tootltipText || "")}
        {
          props.tag.tooltip
          && props.tag.tooltip.length > 0
          ? (
            props.tag.tooltip.map((tooltip) => (
              renderIcon(tooltip.tootltipIconUrl, tooltip.tootltipIconText)
            ))
          ) : null
        }
      </>
    </Flex>
  );
};
