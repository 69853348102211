import { createIcon } from "@chakra-ui/react";

export const SingleFileIcon = createIcon({
  displayName: 'SingleFileIcon',
  viewBox: '0 0 512 512',
  path: [
    (<path fill='currentColor' d="m452.5,11h-393c-11.3,0-20.4,9.1-20.4,20.4v449.2c0,11.3 9.1,20.4 20.4,20.4h279.1c15,2.4 31.1-18.5 31.1-18.5l94.6-94.6c0,0 8.7-6.7 8.6-17v-339.5c-1.13687e-13-11.3-9.1-20.4-20.4-20.4zm-89.3,420.3v-40h40l-40,40zm68.9-80.9h-89.3c-11.3,0-20.4,9.1-20.4,20.4v89.3h-242.5v-408.3h352.2v298.6z" />),
    (<path fill='currentColor' d="m179.4,230.9h153.1c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-153.1c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.2,20.4 20.4,20.4z" />),
    (<path fill='currentColor' d="m179.4,315.6h153.1c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-153.1c-11.3,0-20.4,9.1-20.4,20.4 0,11.2 9.2,20.4 20.4,20.4z" />)
  ]
});