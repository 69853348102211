import { useEffect, useRef } from "react";
import $ from 'jquery';
import { IClientCustomFields } from "../_services/interface/client.interface";
import { getClientCustomFieldOptions } from "../_services/client.service";
import '../styles/components/SelectCustomField.css';
import 'selectize';
import { Flex, Text } from "@chakra-ui/react";

interface ISelectCustomField {
  setSelectedValue: React.Dispatch<React.SetStateAction<string|undefined>>;
  selectedValue: string|undefined;
  customField: IClientCustomFields;
  guid_client: string;
  dropdownParent?: string;
  showLabel?: boolean;
}

interface IOptions {
  value: string;
  label: string;
}

export default function SelectCustomField(props: ISelectCustomField) {
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const selectizeRef = useRef<any | null>(null);

  const configOptions = async (): Promise<IOptions[]> => {
    if (props.customField) {
      const { status, response } = await getClientCustomFieldOptions(props.guid_client, props.customField.guid_custom_field);

      if (status === 200 && response && Array.isArray(response)) {
        return response.map((item) => ({
          value: item.value,
          label: item.value,
          search: item.value.toLowerCase()
        }));
      }
    }
    return [];
  };

  const initializeSelectize = async () => {
    if (!selectRef.current) return;

    const options = await configOptions();

    const $select = $(selectRef.current).selectize({
      plugins: ['remove_button'],
      persist: true, // Set persist to false to avoid retaining removed items
      create: true, // Allow creating new items
      dropdownParent: props.dropdownParent,
      placeholder: `${props.customField.name}`,
      valueField: 'value',
      labelField: 'label',
      options,
      searchField: ['search'],
      hideSelected: true, // Hide selected items from the dropdown

      onChange: (value: string) => {
        if (props.setSelectedValue) {
          props.setSelectedValue(value);
        }

        const items = selectizeRef.current.items;
        if (items.length > 1) {
          selectizeRef.current.removeItem(items[0]);
        }
      },
      render: {
        option_create: function(data, escape) {
          return `<div class="create">Adicionar <span style="font-size: 12px; height: 35px; color: var(--primary-500); padding: 3px; background-color: #dde5ff; border-radius: 15px;">${escape(data.input)}</span></div>`;
        },
        item: function(item, escape) {
          return `<div class="custom-selectize-item">${escape(item.label)}</div>`;
        },
        option: function(item, escape) {
          return `<div class="custom-selectize-option">${escape(item.label)}</div>`;
        }
      }
    });

    selectizeRef.current = $select[0].selectize;

    if (props.selectedValue) {
      if (!selectizeRef.current.options[props.selectedValue]) {
        selectizeRef.current.addOption({ value: props.selectedValue, label: props.selectedValue });
      }
      selectizeRef.current.setValue(props.selectedValue);
    }
  };

  const destroySelectize = () => {
    if (selectizeRef.current) {
      selectizeRef.current.clear(); // Clear the current items
      selectizeRef.current.clearOptions(); // Clear all options
      selectizeRef.current.destroy(); // Destroy the instance
    }
  };

  useEffect(() => {
    if (props.customField) {
      destroySelectize();
      initializeSelectize();
    }
  }, [props.customField]);

  return (
    <Flex flexDirection="column" w="100%">
      {props.showLabel ? <Text color="#171923" fontFamily="Poppins-Medium" fontSize="12px">{props.customField.name}</Text> : null}
      <select className="custom-select" multiple ref={selectRef}></select>
    </Flex>
  );
}
