import { Flex, Image, Select, Table, Tbody, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BlockSwitch } from "../../../../components/BlockSwitch";
import { FormButton } from "../../../../components/login/button.component";
import { SelectCompanies } from "../../../../modal/SelectCompanies";
import { getCertificatesTaxCurrentAccount } from "../../../../_services/certificate.service";
import { getClientCompanies } from "../../../../_services/company.service";
import { configTaxCurrentAccount, getConfigTaxCurrentAccount } from "../../../../_services/config.service";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { IClientCompanies } from "../../../../_services/interface/company.interface";

interface ITaxCurrentAccountProps {
  refreshData: boolean;
  guid_client: string;
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
  submit: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: boolean;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ICertificateConfigPJ {
  companies: string[];
  recurrency: string;
  isActive: boolean;
  slug: string;
}

interface ICertificateConfigPF {
  personas: string[];
  recurrency: string;
  isActive: boolean;
  slug: string;
}

export const TaxCurrentAccount = (props: ITaxCurrentAccountProps) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  const [isLoadingAllCompanies, setIsLoadingAllCompanies] = useState<boolean>(false);

  // const [allPersonas, setAllPersonas] = useState<IPersona[]>([]);
  // const [isLoadingAllPersonas, setIsLoadingAllPersonas] = useState<boolean>(false);
  
  const [certificatesActive, setCertificatesActive] = useState<string[]>([]);
  const [initialCertificatesActive, setInitialCertificatesActive] = useState<string[]>([]);

  const [certificatesPJConfig, setCertificatesPJConfig] = useState<ICertificateConfigPJ[]>([]);
  const [initialCertificatesPJConfig, setInitialCertificatesPJConfig] = useState<ICertificateConfigPJ[]>([]);
  // const [certificatesPFConfig, setCertificatesPFConfig] = useState<ICertificateConfigPF[]>([]);

  const submit = async () => {
    setSubmitDisabled(true);
    const {status, response} = await configTaxCurrentAccount(props.guid_client,{
      productsCompany: certificatesPJConfig,
      // productsPersona: certificatesPFConfig,
      slugsActive: certificatesActive
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });

      props.hasChangeHook(false);

      props.setSubmit(false);
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });

      props.setSubmitError(true);
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    }

    setSubmitDisabled(false);
  }

  const getAllCompanies = async () => {
    setIsLoadingAllCompanies(true);
    const { status, response } = await getClientCompanies(props.guid_client);
    if (status === 200) {
      if (Array.isArray(response)) {
        setAllCompanies(response);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }

    setIsLoadingAllCompanies(false);
  }

  // const getAllPersonas = async () => {
  //   setIsLoadingAllPersonas(true);

  //   if (props.guid_client) {
  //     const filters = {
  //       page: 1,
  //       row_by_page: '-1'
  //     };

  //     const { status, response } = await listWithoutPermissionPersonas(props.guid_client, filters);

  //     if (
  //       status === 200
  //       && 'meta' in response 
  //       && 'data' in response
  //       && response.data !== undefined
  //     ) {
  //       const data: IPersona[] = response.data;
  //       setAllPersonas(data);
  //     } else {
  //       toast({
  //         title: 'Ocorreu um erro',
  //         description: 'Ocorreu um erro ao tentar puxar as informações das pessoas físicas',
  //         status: 'error',
  //         duration: 5000,
  //         isClosable: true
  //       });
  //     }
  // }

  //   setIsLoadingAllPersonas(false);
  // }

  const configFields = async () => {
    setIsLoading(true);
    const {status, response} = await getConfigTaxCurrentAccount(props.guid_client);

    if(
      status === 200 
      && response
      && 'productsCompany' in response
      && 'slugsActive' in response
    ) {
      setCertificatesPJConfig(response.productsCompany);
      setInitialCertificatesPJConfig(response.productsCompany);
      // setCertificatesPFConfig(response.productsPersona);
      setCertificatesActive(response.slugsActive);
      setInitialCertificatesActive(response.slugsActive);
    }

    setTimeout(() => setIsLoading(false), 500);
  }

  const configCertificates = async () => {
    const {status, response} = await getCertificatesTaxCurrentAccount();

    if(status === 200 && response && Array.isArray(response)) {
      setCertificates(response);
    }
  }

  useEffect(() => {
    props.hasChangeHook(false);

    if (certificatesActive.sort().join() !== initialCertificatesActive.sort().join()) {
      props.hasChangeHook(true);
    }

    type CertificateConfig = ICertificateConfigPJ | ICertificateConfigPF;

    const areConfigsEqual = (arr1: CertificateConfig[], arr2: CertificateConfig[]) => {
      const normalize = (arr: CertificateConfig[]) =>
        arr
          .map(config => ({
            ...config,
            // Verifica se é `companies` ou `personas` e ordena o array correspondente
            companies: 'companies' in config ? config.companies.sort() : undefined,
            personas: 'personas' in config ? config.personas.sort() : undefined,
          }))
          .sort((a, b) => a.slug.localeCompare(b.slug)); // Ordena pelo `slug`
    
      return JSON.stringify(normalize(arr1)) === JSON.stringify(normalize(arr2));
    };
    
    if (!areConfigsEqual(certificatesPJConfig, initialCertificatesPJConfig)) {
      props.hasChangeHook(true);
    }
  }, [
    certificatesPJConfig,
    initialCertificatesActive
  ]);

  useEffect(() => {
    if(props.submit) {
      submit();
    }
  }, [props.submit]);

  useEffect(() => {
    configCertificates();
    configFields();
    getAllCompanies();
    // getAllPersonas();
  }, [])

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  const changeStatusCertificate = (value: boolean, slug: string) => {
    if(value) {
      setCertificatesActive([...certificatesActive, slug]);
    } else {
      setCertificatesActive(certificatesActive.filter(value => value !== slug));
    }
  }

  const handleCertificateChange = (slug: string, field: keyof ICertificateConfigPJ, value: any, entity: 'company'|'persona') => {
    if(entity === 'company') {
      setCertificatesPJConfig(prevConfig => {
        const exists = prevConfig.some(config => config.slug === slug);

        if (exists) {
          return prevConfig.map(config =>
            config.slug === slug ? { ...config, [field]: value } : config
          );
        } else {
          const newConfig: ICertificateConfigPJ = {
            slug,
            [field]: value,
            companies: [],
            recurrency: 'manual',
            isActive: true
          };
          
          return [...prevConfig, newConfig];
        }
      });
    }

    // if(entity === 'persona') {
    //   console.log(slug);
    //   setCertificatesPFConfig(prevConfig => {
    //     const exists = prevConfig.some(config => config.slug === slug);

    //     if (exists) {
    //       return prevConfig.map(config =>
    //         config.slug === slug ? { ...config, [field]: value } : config
    //       );
    //     } else {
    //       const newConfig: ICertificateConfigPF = {
    //         slug,
    //         [field]: value,
    //         personas: [],
    //         recurrency: 'manual',
    //         isActive: true
    //       };
          
    //       return [...prevConfig, newConfig];
    //     }
    //   });
    // }
  };

  const getCertificateIsActive = (slug: string, entity: 'company'|'persona') => {
    // if(entity === 'company') {
      const config = certificatesPJConfig.find((config) => config.slug === slug);
      return config ? config.isActive : false;
    // }

    // const config = certificatesPFConfig.find((config) => config.slug === slug);
    // return config ? config.isActive : false;
  };

  const getCertificateCompanies = (slug: string) => {
    const config = certificatesPJConfig.find((config) => config.slug === slug);
    return config ? config.companies : [];
  };

  // const getCertificatePersonas = (slug: string) => {
  //   const config = certificatesPFConfig.find((config) => config.slug === slug);
  //   return config ? config.personas : [];
  // };

  const getCertificateRecurrency = (slug: string, entity: 'company'|'persona') => {
    // if(entity === 'company') {
      const config = certificatesPJConfig.find((config) => config.slug === slug);
      return config ? config.recurrency : 'manual';
    // }

    // const config = certificatesPFConfig.find((config) => config.slug === slug);
    // return config ? config.recurrency : 'manual';
  };

  return (
    !certificatesActive.some((slug) => certificates.some((certificate) => certificate.slug === slug)) ? (
      <Flex direction="column" w="100%">
        <Flex marginBottom="32px">
          <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Gerenciador de conta corrente fiscal (Em Breve)</Text>
        </Flex>
      </Flex>
    ) : (<>
      <Flex direction="column" w="100%">
        <Flex marginBottom="32px">
          <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Gerenciador de conta corrente fiscal</Text>
        </Flex>
  
        <Flex width="100%" flexDirection="row" gap="24px" flexWrap="wrap" justifyContent="flex-start">
          {
            isLoading ? (
              Array.from({ length: 1 }, (_, index) => (
                <BlockSwitch
                  key={index}
                  isActive={false}
                  setIsActive={(value: boolean) => console.log(value)}
                  name="carregando"
                  icon="/icons/gerenciado_cnds.png"
                  isLoading={true}
                />
              ))
            ) : (
              certificates.map((certificate, index) => (
                <BlockSwitch
                  key={certificate.slug || index}
                  isActive={certificatesActive.includes(certificate.slug)}
                  // setIsActive={(value: boolean) => changeStatusCertificate(value, certificate.slug)}
                  setIsActive={() => {}}
                  name={certificate.name}
                  icon="/icons/gerenciado_cnds.png"
                  minWidth="260px"
                />
              ))
            )
          }
  
        </Flex>
  
        <Flex margin="32px 0">
          <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Para empresas</Text>
        </Flex>
  
        <Flex width="100%">
          <Table>
            <Thead>
              <Tr border="none">
                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  Certidão
                </Th>
  
                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  Empresas
                </Th>
  
                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  Recorrência
                </Th>
  
                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  Período de Recorrência
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                certificates.filter((certificate) => certificate.config_company === 'ALL' || certificate.config_company === 'AUTOMATIC').map((certificate, index) => {
  
                  if(!certificatesActive.includes(certificate.slug)) {
                    return <></>;
                  }
  
                  return (
                    <Tr>
                      <Th
                        fontFamily="Poppins-Medium"
                        fontStyle="normal"
                        lineHeight="normal"
                      >{certificate.name}</Th>
  
                      <Th
                        fontFamily="Poppins-Medium"
                        fontStyle="normal"
                        lineHeight="normal"
                      >
                        <SelectCompanies
                          guid_client={props.guid_client}
                          companies={allCompanies}
                          showInText={true}
                          selectedCompanies={getCertificateCompanies(certificate.slug)}
                          setSelectedCompanies={(selected) => {
                            handleCertificateChange(certificate.slug, 'companies', selected, 'company');
                          }}
                          header="Selecione as empresas para ativar a recorrência de certidões e consultas"
                          description="Selecione as empresas que você deseja ativar a recorrência de certidões e consultas"
                          buttonText="Salvar alteração"
                          isLoading={isLoadingAllCompanies}
                        />
                      </Th>
  
                      <Th
                        fontFamily="Poppins-Medium"
                        fontStyle="normal"
                        lineHeight="normal"
                      >
                        <Flex
                          display="flex"
                          justifyContent="start"
                          cursor="pointer"
                          onClick={() =>
                            handleCertificateChange(certificate.slug, 'isActive', !getCertificateIsActive(certificate.slug, 'company'), 'company')
                          }
                          marginTop="1.5%"
                        >
                          {getCertificateIsActive(certificate.slug, 'company') ? (
                            <Image w="50px" src="../icons/switch-blue.svg" />
                          ) : (
                            <Image w="50px" src="../icons/switch-gray.svg" />
                          )}
                        </Flex>
                      </Th>
  
                      <Th
                        fontFamily="Poppins-Medium"
                        fontStyle="normal"
                        lineHeight="normal"
                      >
                        {
                          <Select
                            variant="outline"  
                            bg="gray.100" 
                            width="100%"
                            borderRadius={6} 
                            p="6px"
                            border="1px" 
                            borderColor="gray.300"
                            fontSize="12px"
                            placeholder="Selecione o período de atualização"
                            disabled={!getCertificateIsActive(certificate.slug, 'company')}
                            onChange={(event) =>
                              handleCertificateChange(certificate.slug, 'recurrency', event.target.value, 'company')
                            }
                          > 
                            {
                              certificate.recurrency 
                              ? certificate.recurrency.map(option => (
                                <option key={option.value} selected={option.value === getCertificateRecurrency(certificate.slug, 'company')} value={option.value}>{option.name}</option>
                              )) : null
                            }
                          </Select> 
                        }
                      </Th>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Flex>
  
        {/* <Flex margin="32px 0">
          <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Para pessoa física</Text>
        </Flex>
  
        <Flex width="100%">
          <Table>
            <Thead>
              <Tr border="none">
                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  Certidão
                </Th>
  
                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  CPF
                </Th>
  
                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  Recorrência
                </Th>
  
                <Th
                  color="#292689"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  Período de Recorrência
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                certificates.filter((certificate) => certificate.config_persona === 'ALL' || certificate.config_persona === 'AUTOMATIC').map((certificate, index) => {
  
                  if(!certificatesActive.includes(certificate.slug)) {
                    return <></>;
                  }
  
                  return (
                    <Tr>
                      <Th
                        fontFamily="Poppins-Medium"
                        fontStyle="normal"
                        lineHeight="normal"
                      >{certificate.name}</Th>
  
                      <Th
                        fontFamily="Poppins-Medium"
                        fontStyle="normal"
                        lineHeight="normal"
                      >
                        <SelectPersonas
                          guid_client={props.guid_client}
                          personas={allPersonas}
                          selectedPersonas={getCertificatePersonas(certificate.slug)}
                          setSelectedPersonas={(selected) => {
                            handleCertificateChange(certificate.slug, 'personas', selected, 'persona');
                          }}
                          header="Selecione as pessoas para ativar a recorrência de certidões e consultas"
                          description="Selecione as pessoas que você deseja ativar a recorrência de certidões e consultas"
                          buttonText="Ativar recorrência"
                          isLoading={isLoadingAllPersonas}
                        />
                      </Th>
  
                      <Th
                        fontFamily="Poppins-Medium"
                        fontStyle="normal"
                        lineHeight="normal"
                      >
                        <Flex
                          display="flex"
                          justifyContent="start"
                          cursor="pointer"
                          onClick={() =>
                            handleCertificateChange(certificate.slug, 'isActive', !getCertificateIsActive(certificate.slug, 'persona'), 'persona')
                          }
                          marginTop="1.5%"
                        >
                          {getCertificateIsActive(certificate.slug, 'persona') ? (
                            <Image w="50px" src="../icons/switch-blue.svg" />
                          ) : (
                            <Image w="50px" src="../icons/switch-gray.svg" />
                          )}
                        </Flex>
                      </Th>
  
                      <Th
                        fontFamily="Poppins-Medium"
                        fontStyle="normal"
                        lineHeight="normal"
                      >
                        {
                          <Select
                            variant="outline"  
                            bg="gray.100" 
                            width="100%"
                            borderRadius={6} 
                            p="6px"
                            border="1px" 
                            borderColor="gray.300"
                            fontSize="12px"
                            placeholder="Selecione o período de atualização"
                            onChange={(event) =>
                              handleCertificateChange(certificate.slug, 'recurrency', event.target.value, 'persona')
                            }
                          > 
                            {
                              certificate.recurrency 
                              ? certificate.recurrency.map(option => (
                                <option key={option.value} selected={option.value === getCertificateRecurrency(certificate.slug, 'persona')} value={option.value}>{option.name}</option>
                              )) : null
                            }
                          </Select> 
                        }
                      </Th>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Flex> */}
  
        <hr />
  
        <Flex width="100%" justifyContent="flex-start" marginTop="35px">
          <FormButton onClick={submit} disabled={submitDisabled || isLoading}>
            Salvar alterações
          </FormButton>
        </Flex>
      </Flex>
    </>)
  );
}
