import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { IUserInfo, IUserUpdateResponse } from '../../../../_services/interface/user.interface';
import { getUserInfo, postLoggedUserUpdate } from '../../../../_services/user.service';
import { DashInput } from '../components/DashInput';
import { Avatar } from '../components/UserAvatar';
//import { TwoFactorModal } from './twoFactorModal'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { FormButton } from '../../../../components/login/button.component';
import { getIsSSO, putChangePassword } from "../../../../_services/auth.service";
import { IChangePasswordInput, IChangePasswordResponse } from '../../../../_services/interface/login.interface';
import { IErrorResponse } from '../../../../_services/interface/IDefaults';

interface IUserModalProps {
  st: 'complete' | 'simple',
  username: string;
  guid_user: string;
  onUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IAccChange {
  name: string;
  email: string;
  phone: string;
}

interface IPasswordChange {
  actualPassword: string;
  newPassword: string;
  confirmPassword: string;
  email: string;
}

interface IUserEditForm {
  name: string;
  phone: string;
  email: string;
  actualPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const UserModal = (props: IUserModalProps) => {

  const YupAccChangeForm = Yup.object().shape({
    name: Yup.string().required('Preencha o campo com um nome válido.'),
    email: Yup.string().required('O campo de email não pode ser vazio.').email('É necessário informar um email válido.')
  });

  const resolverForm = { resolver: yupResolver(YupAccChangeForm) };

  const [cookies, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [pssErrorMessage, setPssErrorMessage] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [accConfigStatus, setAccConfigStatus] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [accPassStatus, setAccPassStatus] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState<string>('#e8e8e8');
  const [passwordStrengthMeter, setPasswordStrengthMeter] = useState<number>(0);
  const [getOldEmail, setOldEmail] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formState: { errors }, setValue, register, watch, handleSubmit } = useForm<IUserEditForm>(resolverForm);
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
  const toast = useToast();
  const [isSSO, setIsSSO] = useState<any>(false)

  //Check Form Errors
  useEffect(() => {
    if (errors) {
      setAccErrorMessage(Object.values(errors).map((_x) => _x.message) as string[]);
    }
  }, [errors]);

  //First the system will retrieve some informations from the backend to fill them here
  useEffect(() => {
    const returnUserData = async () => {
      const { status, response } = await getUserInfo();

      if (status === 200) {
        setOldEmail((response as IUserInfo).email);
        setValue("name", (response as IUserInfo).name);
        setValue("phone", (response as IUserInfo).phoneNumber);
        setValue("email", (response as IUserInfo).email);

      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao retornar as informações do usuário',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
      const _isSSO = (await getIsSSO()).response
      setIsSSO(_isSSO);
    }

    if (isOpen) {
      returnUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  //Check the fields to validate the buttons
  useEffect(() => {
    watch((value) => {
      //This structure will deal with the Account Configuration
      if (value.name !== '' && value.email !== '') {
        setAccConfigStatus(false);
      } else {
        setAccConfigStatus(true);
      }

      //This structure will deal with the Password Fields
      if (value.actualPassword !== '' && value.confirmPassword !== '' && value.newPassword !== '') {
        setAccPassStatus(false);
      } else {
        setAccPassStatus(true);
      }

      if (value.newPassword) {
        if (strongRegex.test(value.newPassword)) {
          setPasswordStrength("#0F9D58");
          setPasswordStrengthMeter(2);
        } else if (mediumRegex.test(value.newPassword)) {
          setPasswordStrength("#F4B400");
          setPasswordStrengthMeter(1);
        } else {
          setPasswordStrength("#DB4437");
          setPasswordStrengthMeter(0);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const accConfigChange = async (data: IAccChange) => {
    setAccConfigStatus(true);
    const { status, response } = await postLoggedUserUpdate({
      name: data.name,
      email: data.email,
      phonenumber: data.phone !== '' ? data.phone : undefined
    });

    if (status === 200) {
      if ((response as IUserUpdateResponse).updated) {
        //Update is ok, now the system checks if the user's email is different from the new one
        if (getOldEmail !== data.email) {
          //Its different, so the system will logout this user
          removeCookie('token', null, { expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT') });
          //Navigate the user back to the login page
          navigate('/');
        } else {
          //Update the main object
          props.onUpdate(true);
          //Closes the window
          onClose();

        }
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Algo deu errado na requisição, tente novamente mais tarde',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Algo deu errado na requisição, tente novamente mais tarde',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    
    setAccConfigStatus(false);
  }

  const passwordChange = async (data: IPasswordChange) => {
    setAccPassStatus(true);

    if (data.actualPassword.length <= 0) {
      setPssErrorMessage(["Preencha a senha atual"]);
      setAccPassStatus(false);
      return;
    }

    if (data.newPassword.length <= 0) {
      setPssErrorMessage(["Preencha a nova senha"]);
      setAccPassStatus(false);
      return;
    }

    if (data.confirmPassword.length <= 0) {
      setPssErrorMessage(["Confirme a nova senha"]);
      setAccPassStatus(false);
      return;
    }

    if (passwordStrengthMeter < 2) {
      setPssErrorMessage(['A senha precisa ser forte']);
      setAccPassStatus(false);
      return;
    } 

    if (data.newPassword !== data.confirmPassword) {
      setPssErrorMessage(['As Senhas não são iguais']);
      setAccPassStatus(false);
      return;
    }

    const { status, response } = await putChangePassword({
      oldPassword: data.actualPassword,
      newPassword: data.newPassword,
      email: getOldEmail
    } as IChangePasswordInput);

    if (status === 200 && (response as IChangePasswordResponse).passwordChanged) {
      //Password foi alterado com sucesso
      toast({
        title: 'Password Alterado com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      onClose();
      setValue('actualPassword', '');
      setValue('confirmPassword', '');
      setValue('newPassword', '');
    } else if(status === 400 && (response as IErrorResponse).message === 'OLD_PASSWORD_WRONG') {
      setPssErrorMessage(['A senha atual está incorreta']);
      setAccPassStatus(false);
      return;
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Algo deu errado na requisição, entre em contato com o suporte.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
 
    setAccPassStatus(false);
  }

  return (
    <>
      {props.st === 'complete' ? (
        <Flex gap="8px" alignItems="center" onClick={onOpen} _hover={{ cursor: "pointer" }}>
          <Avatar username={props.username} />
          <Text fontSize="12px">
            {props.username}
          </Text>
        </Flex>
      ) : (
        <Tooltip label="Editar Usuário">
          <Flex gap="8px" alignItems="center" justifyContent="center" onClick={onOpen} _hover={{ cursor: "pointer" }}>
            <Avatar username={props.username} />
          </Flex>
        </Tooltip>
      )}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow="1" flexShrink="1" borderLeft="12px solid #0263FF" borderRadius="8px">
          <ModalHeader paddingBottom="0px" fontFamily="Poppins-SemiBold">Configuração de Conta</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium">
            {accErrorMessage.length > 0 ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                {accErrorMessage.map((_x, _i) => (
                  <Flex key={_i}>{_x}</Flex>
                ))}
              </Flex>
            ) : null}

            <Flex gap={12} mt={4} justifyContent="space-between">
              <DashInput placeholder="Nome" {...register("name", { required: true })} />
              <DashInput placeholder="Telefone" {...register("phone")} />
            </Flex>
            <Flex gap={12} mt={4} justifyContent="space-between">
              <DashInput placeholder="Email" {...register("email", { required: true })} />
            </Flex>
            <Flex mb="12px">
              <Text fontSize="11px" opacity={0.5} mt={1}>
                *Alterar o email deslogará o seu usuário, você deverá passar pelo o processo de Login novamente.
              </Text>
            </Flex>
            <Flex gap={12} mt={4} flexDirection="column" alignItems="stretch">
              <FormButton onClick={handleSubmit(accConfigChange)}>Modificar Configurações da Conta</FormButton>
            </Flex>

            {isSSO ? null : (
              <>
                <Text fontSize="20px" fontFamily="Poppins-SemiBold" margin="20px 0">Senha</Text>
                {pssErrorMessage.length > 0 ? (
                  <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                    {pssErrorMessage.map((_x, _i) => (
                      <Flex key={_i}>{_x}</Flex>
                    ))}
                  </Flex>
                ) : null}
                <Flex mt={4} justifyContent="space-between" direction="column">
                  <Flex gap={12} justifyContent="space-between">
                    <DashInput placeholder="Senha Atual" type="password" {...register("actualPassword")} />
                  </Flex>
                  <Flex gap={12} mt={4} justifyContent="space-between">
                    <DashInput placeholder="Nova Senha" type="password" {...register("newPassword")} />
                    <DashInput placeholder="Confirme sua Senha" type="password" {...register("confirmPassword")} />
                  </Flex>
                  <Flex flexDirection="column" gap={2} mt={2} justifyContent="space-between">
                    <Flex borderRadius={5} bg={passwordStrength} h={2}></Flex>
                    <Text fontSize={11} opacity={0.5}>A senha precisa ter pelo menos 6 caracteres dentre eles letras maísculas, minusculas, números e simbolos.</Text>
                  </Flex>
                  <Flex gap={12} mt={4} mb={4} flexDirection="column" alignItems="stretch">
                    <FormButton onClick={handleSubmit(passwordChange)}>Alterar Senha</FormButton>
                  </Flex>
                </Flex>
              </>
            )}


            {/* <Text fontSize="20px" fontFamily="Poppins-SemiBold" margin="20px 0">Autenticação de Dois-Fatores</Text>
            <Text fontSize="12px">Para adicionar uma camada maior de segurança a sua conta, você pode configurar a Autenticação de Dois-Fatores, utilizando o seu aplicativo favorito.</Text>
            
            <Flex alignItems="center" gap="8px" mt={5} justifyContent="space-between" mb="20px">
              <Flex alignItems="center" gap="8px" _hover={{cursor: "pointer"}} onClick={() => setIsActive(!isActive)} >
                {isActive ? (<Image w="40px" src="/icons/switch-blue.svg" />) : (<Image w="40px" src="/icons/switch-gray.svg" />)}
                <Text fontSize="12px">
                    Habilitar Autenticação de Dois-Fatores
                </Text>
              </Flex>
              <TwoFactorModal />
            </Flex> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
