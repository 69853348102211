import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex, Image,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import {
  ICNDListData
} from '../../../../../_services/interface/cnd.interface'
import { AddNewTask } from './Create/AddNewTask';
import { getCardsForCertificate } from '../../../../../_services/irs.service';
import { IGetCardsForCertificateResponseData } from '../../../../../_services/interface/irs.interface';
import moment from 'moment';
import { EPriority } from '../../../../../_services/enum/irs.enum';
import { EditTask } from './EditTask';

interface IListCardsForCertificate {
  isOpen: boolean;
  onClose: () => void;
  guid_client: string;
  type: 'certificate'|'company';
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  dataCertificate: ICNDListData;
}

export const RelatedCard = (props: IListCardsForCertificate) => {
  const { isOpen, onClose } = props;
  const [isOpenModalEditTask, setIsOpenModalEditTask] = useState(false);
  const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [currentGuidCard, setCurrentGuidCard] = useState<string>();
  const [cards, setCards] = useState<IGetCardsForCertificateResponseData[]>([]);

  const priorityOptions = [
    { value: EPriority.URGENT, label: 'Urgente', icon: <Image w="22px" h="22px" src="/icons/flags/red.svg" /> },
    { value: EPriority.HIGH, label: 'Alta', icon: <Image w="22px" h="22px" src="/icons/flags/orange.svg" /> },
    { value: EPriority.REGULAR, label: 'Normal', icon: <Image w="22px" h="22px" src="/icons/flags/blue.svg" /> },
    { value: EPriority.LOW, label: 'Baixa', icon: <Image w="22px" h="22px" src="/icons/flags/gray.svg" /> },
  ];

  const recurrencyLabel = {
    DAY: 'Diário',
    WEEK: 'Semanalmente',
    MONTH: 'Mensal',
    YEAR: 'Anual',
  }

  useEffect(() => {
    if(!isOpenModalEditTask) {
      fetchCardsForCertificate();
      setCurrentGuidCard(undefined);
    }
  }, [isOpenModalEditTask])

  useEffect(() => {
    if(isOpenModalCreateTask) {
      onClose();
    }
  }, [isOpenModalCreateTask])

  useEffect(() => {
    if(isOpen) {
      fetchCardsForCertificate();
    }
  }, [isOpen])


  const fetchCardsForCertificate = async () => {
    if(!props.dataCertificate.guid_company) {
      return;
    }

    const { status, response } = await getCardsForCertificate(props.guid_client, {
      guid_company: props.dataCertificate.guid_company,
      certificateSlug: props.dataCertificate.slug,
      certificateType: props.dataCertificate.slug.split('_')[0],
      certificateState: props.dataCertificate.state ? props.dataCertificate.state.initials : null,
      certificateCity: props.dataCertificate.city ? props.dataCertificate.city.name : null,
      row_by_page: -1,
      page: 1,
      order_field: 'createdAt',
      order_direction: 'desc'
    });

    if (status === 200) {
      if ('allCards' in response && 'data' in response) {
        setCards(response.allCards);
      }
    }
  }

  const getPriorityLabelAndIcon = (priority: EPriority) => {
    const option = priorityOptions.find(opt => opt.value === priority);
    return option ? option : { label: 'Desconhecido', icon: null };
  }

  const OpenEditModal = (card: IGetCardsForCertificateResponseData) => {
    setCurrentGuidCard(card.guid_card);
    setIsOpenModalEditTask(true);
  }

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px)'
          alignItems="center"
        />

        <ModalContent
          flexGrow={1}
          flexShrink={1}
          borderLeft="12px solid var(--primary-500)"
          borderRadius={6}
          maxW={637}
        >
          <ModalHeader
            pl={4}
            fontFamily="Poppins-Medium"
            fontSize={21}
            pb={3}
          >
            Cards relacionados
          </ModalHeader>

          <ModalBody pl={4}>
            <Text
              fontFamily="Poppins-Medium"
              fontSize={14}
              color="var(--gray-900)"
              fontWeight={400}
              marginBottom={5}
            >
              {
                !cards.length ?
                  `Não há cards vinculados a essa certidão.` :
                  cards.length === 1 ? 
                    `Há ${cards.length} card vinculado a essa certidão. Qual card você gostaria de acompanhar?` : 
                    `Há ${cards.length} cards vinculados a essa certidão. Qual card você gostaria de acompanhar?`}
            </Text>

            <Flex flexDirection="row" gap={5} justifyContent="center" flexWrap="wrap">
              {
                cards.map((card) => {

                  const { label, icon } = getPriorityLabelAndIcon(card.priority);
                  return (
                    <Flex w="40%">
                      <Flex
                        flexDirection="column"
                        width={254}
                        border="1px solid var(--primary-500)"
                        borderRadius={6}
                        _hover={{cursor:'pointer' }}
                        onClick={() => OpenEditModal(card)}
                      >
                        <Flex
                          flexDirection="column"
                          padding="16px"
                        >
                          <Flex
                            flexDirection="row"
                            fontSize={12}
                            fontFamily="Poppins-Medium"
                            marginBottom="16px"
                          >
                            <Image
                              src={'/icons/calendar.svg'}
                              width="16px"
                              height="16px"
                              marginRight="8px"
                            />

                            {moment.utc(card.dueDate).format('DD/MM/YYYY')}
                          </Flex>

                          <Flex
                            flexDirection="row"
                            fontSize={12}
                            fontFamily="Poppins-Medium"
                            marginBottom="16px"
                          >
                            {card.name}
                          </Flex>

                          <Flex
                            flexDirection="row"
                            fontSize={12}
                            fontFamily="Poppins-Medium"
                          >
                            <Flex>
                              {icon}

                              <Text
                                fontFamily="Poppins-Medium"
                                fontSize={12}
                              >
                                {label}
                              </Text>
                            </Flex>

                            {
                              card.has_recurrency && card.schedule.frequencyType && (
                                <Flex marginLeft="16px">
                                  <Image
                                    src={'/icons/recurrency-black.svg'}
                                    width="16px"
                                    height="16px"
                                    marginRight="4px"
                                  />

                                  {recurrencyLabel[card.schedule.frequencyType]}
                                </Flex>
                              )
                            }
                          </Flex>
                        </Flex>

                        <Flex
                          borderTop="1px solid var(--black-gray-gray-100)"
                          flexDirection="row"
                          fontSize={12}
                          fontFamily="Poppins-Medium"
                          padding="16px"
                          justifyContent={'space-between'}
                          color="black"
                        >
                          <Flex alignItems={'center'}>
                            <Image
                              src={'/icons/attachments.svg'}
                              width="24px"
                              height="24px"
                              marginRight="4px"
                            />

                            {card.files}
                          </Flex>

                          <Flex alignItems={'center'}>
                            <Image
                              src={'/icons/user.svg'}
                              width="24px"
                              height="24px"
                              marginRight="4px"
                            />

                            {card.responsibles.length}
                          </Flex>

                          <Flex alignItems={'center'}>
                            <Image
                              src={'/icons/comment.svg'}
                              width="24px"
                              height="24px"
                              marginRight="4px"
                            />

                            {card.commentsCount}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  )
                })
              }
            </Flex>
          </ModalBody>

          <ModalFooter borderTop={'1px solid #CBD5E0'} mt={5}>
            <Button
              bgColor="white"
              color="#171923"
              _hover={{ bg: 'white', textDecoration: 'underline' }}
              onClick={onClose}
              mr={5}
              fontSize={'14px'}
              fontFamily={'Poppins-Medium'}
              fontWeight={400}
            >
              Fechar
            </Button>

            <Button
              bgColor="#4B4EFF"
              color="white"
              height={37}
              _hover={{ bg: '#282be0' }}
              onClick={() => setIsOpenModalCreateTask(true)}
              fontSize={'14px'}
              fontFamily={'Poppins-Medium'}
              fontWeight={400}
            >
              Criar novo card
            </Button>
          </ModalFooter>

          <ModalCloseButton />
        </ModalContent>
      </Modal>

      <AddNewTask
        isOpen={isOpenModalCreateTask}
        onClose={() => setIsOpenModalCreateTask(false)}
        flushHook={setRefreshData}
        guid_client={props.guid_client}
        type="certificate"
        dataCertificate={props.dataCertificate}
      />

      {
        currentGuidCard
        && (
          <EditTask
            guid_client={props.guid_client}
            guid_card={currentGuidCard}
            isOpen={isOpenModalEditTask}
            onClose={() => {setIsOpenModalEditTask(false);}}
            flushHook={setRefreshData}
          />
        )
      }
    </>
  );
};
