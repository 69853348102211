import { Button, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IReinviteUserResponse } from "../../../../_services/interface/user.interface";
import { postReinviteUser } from "../../../../_services/user.service";

interface IReinvitedUserProps {
  guid_user: string;
  guid_client: string;
  email: string;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  openModalHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReinviteUserModal = (props: IReinvitedUserProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);
  const toast = useToast();

  const SendReinvite = async () => {
    setButtonDisabled(true);
    const { status, response } = await postReinviteUser(
      props.guid_client, 
      props.guid_user, 
      {
        email: props.email,
      }
    );

    if (status === 200) {
      if ((response as IReinviteUserResponse).invitationSent) {
        props.flushHook(true);
        //Close the modal
        onClose();
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar convidar usuários',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        //Close the modal
        onClose();
      }
    }
    setButtonDisabled(false);
  }

  useEffect(() => {
    if (props.openModal && props.guid_user) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Reenviar Convite</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              Deseja reenviar o email de convite para esta pessoa?

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <Button fontSize={12} width="50%" bg="#4B4EFF" onClick={SendReinvite} disabled={isButtonDisabled} isLoading={isButtonDisabled} color="white" _hover={{ bgColor: "#BABBFF", cursor: "pointer" }}>Sim</Button>
                <Button fontSize={12} width="50%" bg="#4B4EFF" onClick={onClose} color="white" _hover={{ bgColor: "#BABBFF", cursor: "pointer" }}>Não</Button>
              </Flex>

            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}