import moment from 'moment';
import {
  Divider,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  ITaxCurrentAccountHistory,
  ITaxCurrentAccountListData,
} from '../../../_services/interface/taxCurrentAccount.interface';
import { useEffect, useState } from 'react';
import { FormButton } from '../../../components/login/button.component';
import { formatDocument } from '../Persona/utils/cpf';
import { jurisdictionTag, stateRegion } from '../../../utils/utils.functions';
import { Status } from '../CNDManager/components/Table/certificate';
import CertificateSearchType from '../CNDManager/components/CertificateSearchType';
import { useClientContext } from '../../../components/sidebar';
import { getTaxCurrentAccountHistory } from '../../../_services/taxCurrentAccount.service';

interface ITaxCurrentAccountHistoryProps {
  taxCurrentAccount: ITaxCurrentAccountListData;
  openModal?: boolean;
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TaxCurrentAccountHistory = (props: ITaxCurrentAccountHistoryProps) => {
  // Global
  const { guid_client } = useClientContext();
  const toast = useToast();

  // Component
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [taxCurrentAccountHistory, setTaxCurrentAccountHistory] = useState<ITaxCurrentAccountHistory[]>([]);

  useEffect(() => {
    if (props.openModal && props.openModalHook) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  useEffect(() => {
    if (isOpen && props.taxCurrentAccount) {
      setRefreshData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, props.taxCurrentAccount]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      fetchTaxCurrentAccountHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  const fetchTaxCurrentAccountHistory = async () => {
    const { status, response } = await getTaxCurrentAccountHistory(guid_client as string, props.taxCurrentAccount.company.guid_company, props.taxCurrentAccount.slug);

    if (status && status === 200) {
      setTaxCurrentAccountHistory(response as ITaxCurrentAccountHistory[]);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: "Ocorreu um erro ao buscar o histórico da conta corrente fiscal.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const flexCentralized = (data: any) => {
    return (
      <Flex alignItems='center' justifyContent='center'>
        {data}
      </Flex>
    );
  };

  const tableHeader = () => {
    return (
      <Thead fontFamily='Poppins-Medium'>
        <Tr>
          <Th>{flexCentralized('Status')}</Th>
          <Th>{flexCentralized('Data de Consulta')}</Th>
          <Th>{flexCentralized('Tipo de Pesquisa')}</Th>
          <Th>{flexCentralized('Relatório')}</Th>
          <Th>{flexCentralized('Débitos')}</Th>
        </Tr>
      </Thead>
    );
  };

  const tableRow = (taxCurrentAccount: ITaxCurrentAccountHistory) => {
    return (
      <Tr>
        <Td textAlign='left'>
          <Flex
            justifyContent='flex-start'
            alignItems='center'
            position='relative'
          >
            <Status fontSize='12px' status={taxCurrentAccount.status} />
          </Flex>
        </Td>
        <Td>
          {flexCentralized(
            taxCurrentAccount.createdAt &&
              moment.utc(taxCurrentAccount.createdAt).isValid()
              ? taxCurrentAccount.createdAtFormatted
              : '-'
          )}
        </Td>
        <Td>
          {flexCentralized(
            <CertificateSearchType isManual={taxCurrentAccount.isManual} fontSize='10px' />
          )}
        </Td>
        <Td textAlign='center'>
          {flexCentralized(iconFile(taxCurrentAccount, 'report'))}
        </Td>
        <Td textAlign='center'>
          {flexCentralized(iconFile(taxCurrentAccount, 'debt'))}
        </Td>
      </Tr>
    );
  };

  const iconFile = (
    taxCurrentAccount: ITaxCurrentAccountHistory,
    type: 'report' | 'debt'
  ) => {
    const file =
      type === 'report'
        ? taxCurrentAccount.file
        : type === 'debt'
        ? taxCurrentAccount.fileXlsDebts
        : null;
    let showFile = file && taxCurrentAccount.status.slug !== 'error';

    if(type === 'debt' && taxCurrentAccount.isManual === true) {
      showFile = false;
    }

    if (showFile) {
      return (
        <Flex
          width='35px'
          height='35px'
          justifyContent='center'
          alignItems='center'
        >
          <Tooltip label='Baixar arquivo'>
            <Flex className='icon-bg-blue'>
              <Link
                color='white'
                href={file || ''}
                target='_blank'
                alignItems='center'
                justifyContent='center'
                display='flex'
                width='30px'
                height='30px'
                minWidth='30px'
                minHeight='30px'
              >
                <Image
                  height='20px'
                  width='20px'
                  src='/icons/download_icon.png'
                  resize='none'
                  _hover={{ cursor: 'pointer' }}
                />
              </Link>
            </Flex>
          </Tooltip>
        </Flex>
      );
    }

    return (
      <Flex
        width='35px'
        height='35px'
        justifyContent='center'
        alignItems='center'
      >
        <Tooltip label='Não há arquivos a serem baixados'>
          <Flex className='icon-bg-blue'>
            <Link
              color='white'
              alignItems='center'
              justifyContent='center'
              _hover={{ cursor: 'default' }}
              display='flex'
              width='30px'
              height='30px'
              minWidth='30px'
              minHeight='30px'
            >
              <Image
                height='20px'
                width='20px'
                opacity={0.3}
                src='/icons/download_icon.png'
                resize='none'
              />
            </Link>
          </Flex>
        </Tooltip>
      </Flex>
    );
  };

  const closeModal = () => {
    setTaxCurrentAccountHistory([]);
    onClose();
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={closeModal}
        size='6xl'
        blockScrollOnMount={false}
      >
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px)'
          alignItems='center'
        />

        <ModalContent
          flexGrow={1}
          flexShrink={1}
          borderLeft='12px solid #0263FF'
          borderRadius={8}
        >
          <ModalHeader fontFamily='Poppins-SemiBold'>
            <Flex w='100%' alignItems='center' display='flex'>
              <Flex>Histórico de Pesquisa</Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton onClick={closeModal} />

          <ModalBody fontFamily='Poppins-medium' fontSize={12}>
            <Flex justifyContent='center' flexDirection='column' gap={5}>
              <TableContainer
                fontFamily='Poppins-Light'
                border='1px solid'
                borderColor='gray.300'
                borderRadius='5px'
              >
                <Table
                  variant='striped'
                  colorScheme='gray'
                  fontSize='12px'
                  fontFamily='Poppins-Medium'
                >
                  <Tbody>
                    <Tr>
                      <Td textAlign='left' fontWeight='bold' w={4}>
                        Empresa:
                      </Td>
                      <Td>{props.taxCurrentAccount.company.name}</Td>
                    </Tr>

                    <Tr>
                      <Td textAlign='left' fontWeight='bold' w={4}>
                        CNPJ:
                      </Td>
                      <Td>
                        {formatDocument(
                          props.taxCurrentAccount.company.cnpj,
                          props.taxCurrentAccount.company.type
                        )}
                      </Td>
                    </Tr>

                    <Tr>
                      <Td textAlign='left' fontWeight='bold' w={4}>
                        Origem:
                      </Td>
                      <Td>
                        <Flex w='120px;'>
                          {jurisdictionTag(
                            props.taxCurrentAccount.jurisdiction
                          )}
                        </Flex>
                      </Td>
                    </Tr>

                    <Tr>
                      <Td textAlign='left' fontWeight='bold' w={4}>
                        Estado:
                      </Td>
                      <Td>
                        <Flex w='60px;'>
                          {stateRegion(props.taxCurrentAccount.state)}
                        </Flex>
                      </Td>
                    </Tr>

                    {props.taxCurrentAccount.jurisdiction === 'municipal' &&
                    props.taxCurrentAccount.city ? (
                      <Tr>
                        <Td textAlign='left' fontWeight='bold' w={4}>
                          Cidade:
                        </Td>

                        <Td>
                          <Flex w='100%'>
                            <Flex
                              w='auto'
                              backgroundColor='#DBE2E9'
                              alignItems='center'
                              justifyContent='center'
                              p='2px'
                              pl='1rem'
                              pr='1rem'
                              fontSize='12px'
                              textTransform='uppercase'
                              borderRadius={5}
                            >
                              {props.taxCurrentAccount.city.name}
                            </Flex>
                          </Flex>
                        </Td>
                      </Tr>
                    ) : null}
                    <Tr>
                      <Td textAlign='left' fontWeight='bold' w={4}>
                        Link de Origem:
                      </Td>
                      <Td>
                        {props.taxCurrentAccount.integrationOriginLink ? (
                          <Link
                            width='26px'
                            height='26px'
                            minWidth='26px'
                            href={
                              props.taxCurrentAccount.integrationOriginLink ??
                              undefined
                            }
                            target='_blank'
                            resize='none'
                          >
                            <Flex>
                              Acessar
                              <Image
                                width='18px'
                                marginLeft='5px'
                                src='../icons/external-link.svg'
                                resize='none'
                              />
                            </Flex>
                          </Link>
                        ) : (
                          <Flex w='60px;'>
                            <Flex
                              w='100%'
                              alignItems='center'
                              justifyContent='center'
                              p='2px'
                              fontSize='10px'
                              textTransform='uppercase'
                              fontWeight='bold'
                              borderRadius={5}
                            >
                              -
                            </Flex>
                          </Flex>
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <Divider />

              <TableContainer
                fontFamily='Poppins-Light'
                border='1px solid'
                borderColor='gray.300'
                borderRadius='5px'
                h='300px'
                overflowX='auto'
                overflowY='auto'
              >
                <Table
                  variant='striped'
                  colorScheme='gray'
                  fontSize='12px'
                  fontFamily='Poppins-Medium'
                >
                  {tableHeader()}
                  <Tbody alignItems='center' justifyContent='center'>
                    {!taxCurrentAccountHistory.length
                      ? null
                      : taxCurrentAccountHistory.map((_history, key) =>
                          tableRow(_history)
                        )}
                  </Tbody>
                </Table>
              </TableContainer>

              <Flex gap={2} flexGrow={1} justifyContent='space-between'>
                <FormButton onClick={closeModal} width='100%'>
                  Fechar
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
