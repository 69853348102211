import { Flex } from "@chakra-ui/react"
import { FieldErrorsImpl } from "react-hook-form";

interface IFormatErrorProps {
  message: FieldErrorsImpl;
}

interface IErrorMessage {
  msg: string;
}

const ErrorLine = (props: IErrorMessage) => {
  return (
    <div>- {props.msg}</div>
  )
}

export const FormError = (props: IFormatErrorProps) => {
  
  const allMessages: any[] = [];
  Object.keys(props.message).forEach((_c: any) => {
    allMessages.push(props.message[_c]?.message);
  });

  if (Object.keys(props.message).length > 0) {
    return (
      <Flex className="error" bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" p="8px" fontSize="12px" borderRadius="4px" mb={2}>
        {allMessages.map((_msg, _i) => (
          <ErrorLine key={_i} msg={_msg} />
        ))}
      </Flex>
    );
  } else {
    return null;
  }
}

export const SimpleFormError = (props: IErrorMessage) => {
  return (
    <Flex className="error" bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" p="8px" fontSize="12px" borderRadius="4px" mb={2}>
      {props.msg}
    </Flex>
  )
}

export const SimpleFormValid = (props: IErrorMessage) => {
  return (
    <Flex className="error" bg="#d3ffce" color="#307f1e" alignItems="flex-start" flexDirection="column" p="8px" fontSize="12px" borderRadius="4px" mb={2} mt={2}>
      {props.msg}
    </Flex>
  )
}