import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import ReactPlaceholder from "react-placeholder/lib";
import { RectShape } from "react-placeholder/lib/placeholders";
import MiniLoading from "../../../../components/miniLoading";

interface IDashCard {
  isLoading: boolean;
  title: string;
  value: number | undefined | string;
  color: string;
  icon: React.ReactElement;
}

export const DashCard = (props: IDashCard) => {
  return (
    <Flex bgColor="white" boxShadow="1px 1px 5px #b7b4b4" borderRadius={4} gap={4} p={4} alignItems="center" w="100%">
      <Flex border={`1px solid ${props.color}`} width="40px" height="40px" alignItems="center" justifyContent="center" borderRadius={100}>
        {React.cloneElement(props.icon, { color: props.color, boxSize: 6 })}
      </Flex>
      <Flex flexDirection="column">
        { 
          props.isLoading ? (
            <RectShape color='#E0E0E0' style={{ width: 25, height: 25, marginBottom:'5px' }} />
          ) : (
            <Text fontSize={22} fontWeight="bold" color={props.color}>{props.value}</Text>
          )
        }
        
        <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1} style={{width: '160px'}}>
          <Text fontSize={14}>{props.title}</Text>
        </ReactPlaceholder>
      </Flex>
    </Flex>
  )
}