import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { DashboardContainer } from "../../components/DashboardContainer";
import Sidebar from "../../components/sidebar";
import { ApiAuth, ApiCall } from "../../_services/api";
import { IResponse } from "../../_services/interface/user.interface";
import useLoginFormStore from "../../store/useLoginFormStore"
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import io, { Socket } from 'socket.io-client';
import { DefaultEventsMap } from "@socket.io/component-emitter";

export default function Dashboard() {
  const { setGuid_user, setGuid_company, setGuid_collaborator, setValidate } = useLoginFormStore()
  const [cookies, removeCookie] = useCookies(["token"]);
  const [validationResponse, setValidationResponse] = useState<IResponse>();
  const navigate = useNavigate();
  const [updateUserData, setUpdateUserData] = useState<boolean>(false);
  const [changeComponent, setChangeComponent] = useState<string>()
  const [socket, setSocket] = useState<Socket<DefaultEventsMap, DefaultEventsMap> | undefined>()
  const toast = useToast();

  const ValidateUser = async (completeWarning = false) => {
    const resp = await new ApiCall('/auth/validate', ApiAuth(cookies.token)).get();

    let socket: Socket<DefaultEventsMap, DefaultEventsMap>;
    const initWebSocket = (guid_user: string) => {
      if (socket) {
        socket.close();
      }
      
      socket = io(`${process.env.REACT_APP_WEBSOCKET_URL}`, {
        query: {
          guid_user: guid_user
        }
      }); 
  
      setSocket(socket);
    }

    if (resp.status !== 200) {
      removeCookie('token', null, { expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT') });
      //Navigate the user back to the login page
      navigate('/');
    } else if (resp.status === 200) {
      const response = (resp.response as IResponse);
      const companies = response.companies.map((value) => value.guid_company);
      const collaborator = response.companies.map((value) => value.guid_collaborator);

      if (process.env.REACT_APP_WEBSOCKET_URL) {
        initWebSocket(response.guid_user);
      }

      //Check if any company needs to have their registration finished

      const companyToComplete = response.companies.find((x) => !x.isRegFinished);
      if (companyToComplete) {
        if (completeWarning) {
          toast({
            isClosable: true,
            duration: null,
            position: 'top-right',
            render: ({ onClose }) => (
              <>
                <Box color='white' pt={3} pb={3} pl={5} pr={5} bg='orange.500' borderRadius={5} height="100%">
                  <Flex justifyContent="space-between" mb={2}>
                    <Text>
                      Para que o sistema comece buscando informações de sua empresa ${companyToComplete.name} é necessário concluir o cadastro. Para isso, por favor clique no menu lateral e em "Empresas" para inserir as informações necessárias ou
                      <span onClick={() => { setChangeComponent('companyPanel'); onClose(); }} style={{ cursor: 'pointer' }}> <u>clique aqui</u></span>.
                    </Text>
                    <Button
                      onClick={onClose}
                      variant="ghost"
                      _hover={{ bg: 'none' }}><CloseIcon w={2.5} h={2.5} /></Button>
                  </Flex>
                </Box>
              </>
            )
          });
        }
      }

      setValidationResponse(response);
      setValidate(response);
      setGuid_user(response.guid_user);
      setGuid_company(companies);
      setGuid_collaborator(collaborator);
    }
  }

  useEffect(() => {
    if (!cookies.token) {
      //Send the person back to the login page
      removeCookie('token', null, { expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT') });
      navigate('/');
    } else {
      ValidateUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //This hook is used when the main user is changed on the system and his data need to be updated
  useEffect(() => {
    if (updateUserData) {
      ValidateUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserData]);

  if (validationResponse) {
    return (
      <DashboardContainer translate="no">
        <Sidebar socket={socket} validation={validationResponse} onUpdate={setUpdateUserData} defaultComponent={changeComponent} />
      </DashboardContainer>
    );
  } else {
    //TODO inserir um tipo de Loading aqui
    return null;
  }
}
