import { Flex, Image, Select, Table, Tbody, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BlockSwitch } from "../../../../components/BlockSwitch";
import { FormButton } from "../../../../components/login/button.component";
import { SelectCompanies } from "../../../../modal/SelectCompanies";
import { SelectPersonas } from "../../../../modal/SelectPersonas";
import { getClientCompanies } from "../../../../_services/company.service";
import { configCertificate, getConfigCertificate } from "../../../../_services/config.service";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { IPersona } from "../../../../_services/interface/persona.interface";
import { listWithoutPermissionPersonas } from "../../../../_services/persona.service";

interface ICertificateProps {
  refreshData: boolean;
  guid_client: string;
  certificates: ICertificate[];
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
  submit: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: boolean;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ICertificateConfigPJ {
  companies: string[];
  recurrency: string;
  isActive: boolean;
  slug: string;
}

interface ICertificateConfigPF {
  personas: string[];
  recurrency: string;
  isActive: boolean;
  slug: string;
}

export const Certificate = (props: ICertificateProps) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  const [isLoadingAllCompanies, setIsLoadingAllCompanies] = useState<boolean>(false);

  const [allPersonas, setAllPersonas] = useState<IPersona[]>([]);
  const [isLoadingAllPersonas, setIsLoadingAllPersonas] = useState<boolean>(false);
  
  const [certificatesActivePJ, setCertificatesActivePJ] = useState<string[]>([]);
  const [certificatesActivePF, setCertificatesActivePF] = useState<string[]>([]);

  const [certificatesPJConfig, setCertificatesPJConfig] = useState<ICertificateConfigPJ[]>([]);
  const [certificatesPFConfig, setCertificatesPFConfig] = useState<ICertificateConfigPF[]>([]);

  const [initialCertificatesActivePJ, setInitialCertificatesActivePJ] = useState<string[]>([]);
  const [initialCertificatesActivePF, setInitialCertificatesActivePF] = useState<string[]>([]);

  const [initialCertificatesPJConfig, setInitialCertificatesPJConfig] = useState<ICertificateConfigPJ[]>([]);
  const [initialCertificatesPFConfig, setInitialCertificatesPFConfig] = useState<ICertificateConfigPF[]>([]);

  const submit = async () => {
    setSubmitDisabled(true);
    const {status, response} = await configCertificate(props.guid_client,{
      productsCompany: certificatesPJConfig,
      productsPersona: certificatesPFConfig,
      slugsActivePJ: certificatesActivePJ,
      slugsActivePF: certificatesActivePF
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });

      props.setSubmit(false);
      props.hasChangeHook(false);
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    }

    setSubmitDisabled(false);
  }

  const getAllCompanies = async () => {
    setIsLoadingAllCompanies(true);
    const { status, response } = await getClientCompanies(props.guid_client);
    if (status === 200) {
      if (Array.isArray(response)) {
        setAllCompanies(response);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }

    setIsLoadingAllCompanies(false);
  }

  const getAllPersonas = async () => {
    setIsLoadingAllPersonas(true);

    if (props.guid_client) {
      const filters = {
        page: 1,
        row_by_page: '-1'
      };

      const { status, response } = await listWithoutPermissionPersonas(props.guid_client, filters);

      if (
        status === 200
        && 'meta' in response 
        && 'data' in response
        && response.data !== undefined
      ) {
        const data: IPersona[] = response.data;
        setAllPersonas(data);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das pessoas físicas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
  }

    setIsLoadingAllPersonas(false);
  }

  const configFields = async () => {
    setIsLoading(true);
    const {status, response} = await getConfigCertificate(props.guid_client);

    if(
      status === 200 
      && response
      && 'productsCompany' in response
      && 'productsPersona' in response
      && 'slugsActivePJ' in response
      && 'slugsActivePF' in response
    ) {
      setCertificatesPJConfig(response.productsCompany);
      setCertificatesPFConfig(response.productsPersona);
      setCertificatesActivePJ(response.slugsActivePJ);
      setCertificatesActivePF(response.slugsActivePF);

      setInitialCertificatesPJConfig(response.productsCompany);
      setInitialCertificatesPFConfig(response.productsPersona);
      setInitialCertificatesActivePJ(response.slugsActivePJ);
      setInitialCertificatesActivePF(response.slugsActivePF);
    }

    setTimeout(() => setIsLoading(false), 500);
  }

  useEffect(() => {
    configFields();
    getAllCompanies();
    getAllPersonas();
  }, [])

  useEffect(() => {
    if(props.submit) {
      submit();
    }
  }, [props.submit]);

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  useEffect(() => {
    props.hasChangeHook(false);

    if (certificatesActivePJ.sort().join() !== initialCertificatesActivePJ.sort().join()) {
      props.hasChangeHook(true);
    }
    
    if (certificatesActivePF.sort().join() !== initialCertificatesActivePF.sort().join()) {
      props.hasChangeHook(true);
    }

    type CertificateConfig = ICertificateConfigPJ | ICertificateConfigPF;

    const areConfigsEqual = (arr1: CertificateConfig[], arr2: CertificateConfig[]) => {
      const normalize = (arr: CertificateConfig[]) =>
        arr
          .map(config => ({
            ...config,
            // Verifica se é `companies` ou `personas` e ordena o array correspondente
            companies: 'companies' in config ? config.companies.sort() : undefined,
            personas: 'personas' in config ? config.personas.sort() : undefined,
          }))
          .sort((a, b) => a.slug.localeCompare(b.slug)); // Ordena pelo `slug`
    
      return JSON.stringify(normalize(arr1)) === JSON.stringify(normalize(arr2));
    };
    
    if (!areConfigsEqual(certificatesPJConfig, initialCertificatesPJConfig)) {
      props.hasChangeHook(true);
    }
    
    if (!areConfigsEqual(certificatesPFConfig, initialCertificatesPFConfig)) {
      props.hasChangeHook(true);
    }
  }, [
    certificatesActivePJ,
    certificatesActivePF,
    certificatesPJConfig,
    certificatesPFConfig
  ]);

  const changeStatusCertificatePJ = (value: boolean, slug: string) => {
    if(value) {
      setCertificatesActivePJ([...certificatesActivePJ, slug]);
    } else {
      setCertificatesActivePJ(certificatesActivePJ.filter(value => value !== slug));
    }
  }

  const changeStatusCertificatePF = (value: boolean, slug: string) => {
    if(value) {
      setCertificatesActivePF([...certificatesActivePF, slug]);
    } else {
      setCertificatesActivePF(certificatesActivePF.filter(value => value !== slug));
    }
  }

  const handleCertificateChange = (slug: string, field: keyof ICertificateConfigPJ|keyof ICertificateConfigPF, value: any, entity: 'company'|'persona') => {
    if(entity === 'company') {
      setCertificatesPJConfig(prevConfig => {
        const exists = prevConfig.some(config => config.slug === slug);

        if (exists) {
          return prevConfig.map(config =>
            config.slug === slug ? { ...config, [field]: value } : config
          );
        } else {
          const newConfig: ICertificateConfigPJ = {
            slug,
            [field]: value,
            companies: [],
            recurrency: 'manual',
            isActive: true
          };
          
          return [...prevConfig, newConfig];
        }
      });
    }

    if(entity === 'persona') {
      console.log(slug);
      setCertificatesPFConfig(prevConfig => {
        const exists = prevConfig.some(config => config.slug === slug);

        if (exists) {
          return prevConfig.map(config =>
            config.slug === slug ? { ...config, [field]: value } : config
          );
        } else {
          const newConfig: ICertificateConfigPF = {
            slug,
            [field]: value,
            personas: [],
            recurrency: 'manual',
            isActive: true
          };
          
          return [...prevConfig, newConfig];
        }
      });
    }
  };

  const getCertificateIsActive = (slug: string, entity: 'company'|'persona') => {
    if(entity === 'company') {
      const config = certificatesPJConfig.find((config) => config.slug === slug);
      return config ? config.isActive : false;
    }

    const config = certificatesPFConfig.find((config) => config.slug === slug);
    return config ? config.isActive : false;
  };

  const getCertificateCompanies = (slug: string) => {
    const config = certificatesPJConfig.find((config) => config.slug === slug);
    return config ? config.companies : [];
  };

  const getCertificatePersonas = (slug: string) => {
    const config = certificatesPFConfig.find((config) => config.slug === slug);
    return config ? config.personas : [];
  };

  const getCertificateRecurrency = (slug: string, entity: 'company'|'persona') => {
    if(entity === 'company') {
      const config = certificatesPJConfig.find((config) => config.slug === slug);
      return config ? config.recurrency : 'manual';
    }

    const config = certificatesPFConfig.find((config) => config.slug === slug);
    return config ? config.recurrency : 'manual';
  };

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Gerenciador de certidões</Text>
      </Flex>

      <Flex margin="32px 0">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Para empresas</Text>
      </Flex>

      <Flex width="100%" flexDirection="row" gap="24px" flexWrap="wrap" justifyContent="flex-start">
        {
          isLoading ? (
            Array.from({ length: 10 }, (_, index) => (
              <BlockSwitch
                key={index}
                isActive={false}
                setIsActive={(value: boolean) => console.log(value)}
                name="carregando"
                icon="/icons/gerenciado_cnds.png"
                isLoading={true}
              />
            ))
          ) : (
            props.certificates.map((certificate, index) => (
              <BlockSwitch
                key={certificate.slug || index}
                isActive={certificatesActivePJ.includes(certificate.slug)}
                setIsActive={(value: boolean) => changeStatusCertificatePJ(value, certificate.slug)}
                name={certificate.name}
                icon="/icons/gerenciado_cnds.png"
                minWidth="260px"
              />
            ))
          )
        }
      </Flex>

      <Flex width="100%" mt="35px">
        <Table>
          <Thead>
            <Tr border="none">
              <Th
                color="#292689"
                fontFamily="Poppins-Medium"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                w="35%"
              >
                Certidão
              </Th>

              <Th
                color="#292689"
                fontFamily="Poppins-Medium"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                w="15%"
              >
                Empresas
              </Th>

              <Th
                color="#292689"
                fontFamily="Poppins-Medium"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                w="15%"
              >
                Recorrência
              </Th>

              <Th
                color="#292689"
                fontFamily="Poppins-Medium"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                w="35%"
              >
                Período de Recorrência
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              props.certificates.filter((certificate) => certificate.config_company === 'ALL' || certificate.config_company === 'AUTOMATIC').map((certificate, index) => {

                if(!certificatesActivePJ.includes(certificate.slug)) {
                  return <></>;
                }

                return (
                  <Tr>
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="35%"
                    >{certificate.name}</Th>

                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="15%"
                    >
                      <SelectCompanies
                        guid_client={props.guid_client}
                        companies={allCompanies}
                        showInText={true}
                        selectedCompanies={getCertificateCompanies(certificate.slug)}
                        setSelectedCompanies={(selected) => {
                          handleCertificateChange(certificate.slug, 'companies', selected, 'company');
                        }}
                        header="Selecione as empresas para ativar a recorrência de certidões e consultas"
                        description="Selecione as empresas que você deseja ativar a recorrência de certidões e consultas"
                        buttonText="Salvar alteração"
                        isLoading={isLoadingAllCompanies}
                      />
                    </Th>

                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="15%"
                    >
                      <Flex
                        display="flex"
                        justifyContent="start"
                        cursor={ getCertificateCompanies(certificate.slug).length > 0 ? "pointer" : "default" }
                        title={ getCertificateCompanies(certificate.slug).length > 0 ? "Clique para ativar a recorrência" : "Selecione uma empresa para ativar a recorrência" }
                        onClick={() =>
                          {
                            if(getCertificateCompanies(certificate.slug).length > 0) {
                              handleCertificateChange(certificate.slug, 'isActive', !getCertificateIsActive(certificate.slug, 'company'), 'company')
                            }
                          }
                        }
                        marginTop="1.5%"
                      >
                        {getCertificateIsActive(certificate.slug, 'company') ? (
                          <Image w="50px" src="../icons/switch-blue.svg" />
                        ) : (
                          <Image w="50px" src="../icons/switch-gray.svg" />
                        )}
                      </Flex>
                    </Th>

                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="35%"
                    >
                      {
                        <Select
                          variant="outline"  
                          bg="gray.100" 
                          width="100%"
                          borderRadius={6} 
                          p="6px"
                          border="1px" 
                          borderColor="gray.300"
                          fontSize="12px"
                          disabled={!getCertificateIsActive(certificate.slug, 'company') || getCertificateCompanies(certificate.slug).length === 0}
                          placeholder="Selecione o período de atualização"
                          onChange={(event) =>
                            handleCertificateChange(certificate.slug, 'recurrency', event.target.value, 'company')
                          }
                        > 
                          {
                            certificate.recurrency 
                            ? certificate.recurrency.map(option => (
                              <option key={option.value} selected={option.value === getCertificateRecurrency(certificate.slug, 'company')} value={option.value}>{option.name}</option>
                            )) : null
                          }
                        </Select> 
                      }
                    </Th>
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>
      </Flex>

      <Flex margin="32px 0">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Para pessoa física</Text>
      </Flex>

      <Flex width="100%" flexDirection="row" gap="24px" flexWrap="wrap" justifyContent="flex-start">
        {
          isLoading ? (
            Array.from({ length: 10 }, (_, index) => (
              <BlockSwitch
                key={index}
                isActive={false}
                setIsActive={(value: boolean) => console.log(value)}
                name="carregando"
                icon="/icons/gerenciado_cnds.png"
                isLoading={true}
              />
            ))
          ) : (
            props.certificates
              .filter((certificate) => certificate.config_persona === 'ALL' || certificate.config_persona === 'AUTOMATIC')
              .map((certificate, index) => (
                <BlockSwitch
                  key={certificate.slug || index}
                  isActive={certificatesActivePF.includes(certificate.slug)}
                  setIsActive={(value: boolean) => changeStatusCertificatePF(value, certificate.slug)}
                  name={certificate.name}
                  icon="/icons/gerenciado_cnds.png"
                  minWidth="260px"
                />
              ))
          )
        }
      </Flex>

      <Flex width="100%" mt="35px">
        <Table>
          <Thead>
            <Tr border="none">
              <Th
                color="#292689"
                fontFamily="Poppins-Medium"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                w="35%"
              >
                Certidão
              </Th>

              <Th
                color="#292689"
                fontFamily="Poppins-Medium"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                w="15%"
              >
                CPF
              </Th>

              <Th
                color="#292689"
                fontFamily="Poppins-Medium"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                w="15%"
              >
                Recorrência
              </Th>

              <Th
                color="#292689"
                fontFamily="Poppins-Medium"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                w="35%"
              >
                Período de Recorrência
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              props.certificates.filter((certificate) => certificate.config_persona === 'ALL' || certificate.config_persona === 'AUTOMATIC').map((certificate, index) => {

                if(!certificatesActivePF.includes(certificate.slug)) {
                  return <></>;
                }

                return (
                  <Tr>
                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="35%"
                    >{certificate.name}</Th>

                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="15%"
                    >
                      <SelectPersonas
                        guid_client={props.guid_client}
                        personas={allPersonas}
                        selectedPersonas={getCertificatePersonas(certificate.slug)}
                        setSelectedPersonas={(selected) => {
                          handleCertificateChange(certificate.slug, 'personas', selected, 'persona');
                        }}
                        header="Selecione as pessoas para ativar a recorrência de certidões e consultas"
                        description="Selecione as pessoas que você deseja ativar a recorrência de certidões e consultas"
                        buttonText="Ativar recorrência"
                        isLoading={isLoadingAllPersonas}
                      />
                    </Th>

                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="15%"
                    >
                      <Flex
                        display="flex"
                        justifyContent="start"
                        cursor={ getCertificatePersonas(certificate.slug).length > 0 ? "pointer" : "default" }
                        onClick={() =>
                          {
                            if(getCertificatePersonas(certificate.slug).length > 0) {
                              handleCertificateChange(certificate.slug, 'isActive', !getCertificateIsActive(certificate.slug, 'persona'), 'persona')
                            }
                          }
                        }
                        title={ getCertificatePersonas(certificate.slug).length > 0 ? "Clique para ativar a recorrência" : "Selecione uma pessoa para ativar a recorrência" }
                        marginTop="1.5%"
                      >
                        {getCertificateIsActive(certificate.slug, 'persona') ? (
                          <Image w="50px" src="../icons/switch-blue.svg" />
                        ) : (
                          <Image w="50px" src="../icons/switch-gray.svg" />
                        )}
                      </Flex>
                    </Th>

                    <Th
                      fontFamily="Poppins-Medium"
                      fontStyle="normal"
                      lineHeight="normal"
                      w="35%"
                    >
                      {
                        <Select
                          variant="outline"  
                          bg="gray.100" 
                          width="100%"
                          borderRadius={6} 
                          p="6px"
                          border="1px" 
                          borderColor="gray.300"
                          fontSize="12px"
                          placeholder="Selecione o período de atualização"
                          disabled={!getCertificateIsActive(certificate.slug, 'persona') || getCertificateCompanies(certificate.slug).length === 0}
                          onChange={(event) =>
                            handleCertificateChange(certificate.slug, 'recurrency', event.target.value, 'persona')
                          }
                        > 
                          {
                            certificate.recurrency 
                            ? certificate.recurrency.map(option => (
                              <option key={option.value} selected={option.value === getCertificateRecurrency(certificate.slug, 'persona')} value={option.value}>{option.name}</option>
                            )) : null
                          }
                        </Select> 
                      }
                    </Th>
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>
      </Flex>

      <hr />

      <Flex width="100%" justifyContent="flex-start" marginTop="35px">
        <FormButton onClick={submit} disabled={submitDisabled || isLoading}>
          Salvar alterações
        </FormButton>
      </Flex>
    </Flex>
  );
}
