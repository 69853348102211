import { Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DashInput } from "../components/DashInput";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormButton } from "../../../../components/login/button.component";
import { putUserEdit } from "../../../../_services/user.service";
import { IResponse, IUser } from "../../../../_services/interface/user.interface";
import { checkCollaboratorPermission } from "../utils/checkPermission";

interface IUserEdit {
  name: string;
  email: string;
  phone: string;
  isBlocked: boolean;
}

interface IEditUserModalProps {
  user: IUser|undefined;
  validation: IResponse;
  guid_client: string;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  openModalHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditUserModal = (props: IEditUserModalProps) => {

  const YupCompanyChangeForm = Yup.object().shape({
    // name: Yup.string(),
    //email: Yup.string().required('Você precisa informar o email do usuário').email('Você precisa informar um email válido'),
  });

  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };
  const [isBlocked, setIsBlocked] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [accErrorMessage] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [allFieldsFilled, setFieldsState] = useState<boolean>(false);

  const { setValue, register, watch, handleSubmit } = useForm<IUserEdit>(resolverForm);
  const toast = useToast();

  useEffect(() => {
    if(props.user) {
      setValue("name", props.user.name);
      setValue("phone", props.user.phoneNumber);
      setIsBlocked(props.user.isBlocked as boolean);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])

  useEffect(() => {
    if (props.openModal && props.user) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  useEffect(() => {
    watch((value) => {
      if (value.name) {
        setFieldsState(true);
      } else {
        setFieldsState(false);
      }
    });
  }, [watch]);

  useEffect(() => {
    if (allFieldsFilled) {
      //All fields are filled and captcha challenge completed
      //so the system will reveal the Button
      setSubmitDisabled(false);
    } else {
      //If nothing meets the system will keep the button disabled
      //setSubmitDisabled(true);
    }
  }, [allFieldsFilled]);

  const handleFormInfo = async (data: IUserEdit) => {
    setSubmitDisabled(true);
    try {
      if(props.user === undefined) {
        toast({
          title: "Usuário não encontrado.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setSubmitDisabled(false);
        return;
      }

      const { status } = await putUserEdit(props.user.guid_user, props.guid_client, {
        name: data.name,
        //email: data.email,
        phonenumber: data.phone,
        isBlocked,
      });
  
      if (status === 200) {
        //Refresh the list
        props.flushHook(true);
        //Close the modal
        onClose();
      } else {
        toast({
          title: "Não foi possível editar o usuário.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setSubmitDisabled(false);
    } catch (error) {
      setSubmitDisabled(false); 
    }
  };

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF">
          <ModalHeader fontFamily="Poppins-Medium">Editar Usuário</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-Medium">
          {accErrorMessage.length > 0 ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Text>Por favor, informe os campos obrigatórios:</Text>
                {accErrorMessage.map((_x, _i) => (
                  <Flex key={_i}>{_x}</Flex>
                ))}
              </Flex>
            ) : null}
            {simpleErrorMessage ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                  <Flex>{simpleErrorMessage}</Flex>
              </Flex>
            ) : null}

            <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} pb={4}>
              <DashInput placeholder="Nome do Usuário" {...register("name")} borderBottomColor="gray.300" backgroundColor="gray.70" borderRadius={6}/>
              {/* <DashInput placeholder="Email" {...register("email")} /> */}<p/><p/>
              <DashInput placeholder="Telefone" {...register("phone")}  borderBottomColor="gray.300" backgroundColor="gray.70" borderRadius={6}/>
              
              {checkCollaboratorPermission(props.validation, props.guid_client, 'user.delete') ? (
                <Flex align="start" mt={8} _hover={{ cursor: "pointer" }} gap={8} onClick={() => setIsBlocked(!isBlocked)}>
                  {isBlocked ? (
                    <Image w="40px" src="/icons/switch-blue.svg" />
                  ) : (
                    <Image w="40px" src="/icons/switch-gray.svg" />
                  )}
                  <Flex direction="column">
                    <Text fontSize="14px">
                        Desativar acesso deste usuário ao sistema
                    </Text>
                    <Text fontSize="12px" color="#B5B5B5">
                        Marcando esta opção o usuário perderá acesso ao sistema e apenas o administrador ou pessoas com permissões especiais podem remover o bloqueio
                    </Text>
                  </Flex>
                </Flex>
              ) : null}

              <Flex mt={8} alignItems="stretch" direction="column" gap={2} flexGrow={1}>
                <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled} >Atualizar dados</FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};