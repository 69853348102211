import { Flex, Select, Text } from "@chakra-ui/react";

interface IResultsPerPage {
  rowByPage: number;
  setRowByPage: (value: React.SetStateAction<number>) => void;
  removeAllOption?: boolean;
}

export const ResultsPerPage = (props: IResultsPerPage) => {
  return (
    <Flex justifyContent="flex-end">
      <Flex gap={2} alignItems="center">
        <Text fontSize="12px" fontFamily="Poppins-Medium" color="#171923">
          Resultados por página
        </Text>
        <Select
          size="xs"
          fontSize={12}
          value={props.rowByPage}
          borderColor="gray.500"
          borderRadius="6px"
          width="75px"
          bg="white"
          onChange={(e) => props.setRowByPage(parseInt(e.target.value))}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
          {props.removeAllOption ? null : (<option value="-1">Todas</option>)}
        </Select>
      </Flex>
    </Flex>
  );
};
