import { ApiCall, ApiAuth } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import {
  IClientCustomFields,
  IClientResponse,
  IClientValuesResponse,
} from "./interface/client.interface";
import { ICustomFieldValues, IGetEmployeesResponse } from "./interface/company.interface";

export const getClientEmployees = async (
  guid_client: string
): Promise<TPremiseResponse<IGetEmployeesResponse>> => {
  return await new ApiCall(
    `/client/employees/${guid_client}`,
    ApiAuth()
  ).get<IGetEmployeesResponse>();
};

export const getUserClients = async (
  guid_user: string
): Promise<TPremiseResponse<IClientValuesResponse[]>> => {
  return await new ApiCall(`/client/list/${guid_user}`, ApiAuth()).get<
    IClientValuesResponse[]
  >();
};

export const getClient = async (
  guid_user: string
): Promise<TPremiseResponse<IClientResponse>> => {
  return await new ApiCall(
    `/client/${guid_user}`,
    ApiAuth()
  ).get<IClientResponse>();
};

export const getClientCustomFields = async (
  guid_client: string
): Promise<TPremiseResponse<IClientCustomFields[]>> => {
  return await new ApiCall(
    `/client/fields/${guid_client}`,
    ApiAuth()
  ).get<IClientCustomFields[]>();
};

export const getClientCustomField = async (
  guid_custom_field: string
): Promise<TPremiseResponse<IClientCustomFields>> => {
  return await new ApiCall(
    `/client/field/${guid_custom_field}`,
    ApiAuth()
  ).get<IClientCustomFields>();
};

export const getClientCustomFieldOptions = async (
  guid_client: string,
  guid_custom_field: string,
): Promise<TPremiseResponse<ICustomFieldValues>> => {
  return await new ApiCall(
    `/client/fields/values/${guid_client}/${guid_custom_field}`,
    ApiAuth()
  ).get<ICustomFieldValues>();
};

export const editConfig = async (
  guid_client: string,
  data: any
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/client/config/${guid_client}`,
    ApiAuth()
  ).post<any, any>(data);
};

export const getCertificateLimit = async (
  guid_client: string
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/client/certificate-limit/${guid_client}`,
    ApiAuth()
  ).get<number>();
};
