import { 
    IFilterListPersona,
    IPersonaCreateInputs,
    IPersona,
    IPersonaEditInputs,
    IPersonaList
  } from "./interface/persona.interface";
  
  import { TPremiseResponse } from "./interface/IDefaults";
  import queryString from 'query-string';
  import { ApiAuth, ApiCall } from "./api";
  
  /**
   * List all persona
   *
   * @param guid_client
   * @param filters
   */
  export const listPersonas = async (guid_client: string, filters: IFilterListPersona): Promise<TPremiseResponse<any>> => {
      const url = `/persona/list/${guid_client}`;
      return await new ApiCall(url, ApiAuth()).post<any, any>(filters);
  };

  export const listWithoutPermissionPersonas = async (guid_client: string, filters: IFilterListPersona): Promise<TPremiseResponse<any>> => {
    const url = `/persona/list/without-permission/${guid_client}`;
    return await new ApiCall(url, ApiAuth()).post<any, any>(filters);
};
  
  /**
   * Add a persona
   *
   * @param guid_client
   * @param formdata
   */
  export const postAddPersona = async (
      guid_client: string,
      formdata: IPersonaCreateInputs
    ): Promise<TPremiseResponse<IPersona>> => {
      return await new ApiCall(
        `/persona/create/${guid_client}`,
        ApiAuth()
      ).post<IPersona, IPersonaCreateInputs>(formdata);
  };
  
  /**
   * Edit a persona
   *
   * @param guid_client
   * @param guid_persona
   * @param formdata
   */
  export const postEditPersona = async (
    guid_client: string,
    guid_persona: string,
    formdata: IPersonaEditInputs
  ): Promise<TPremiseResponse<IPersona>> => {
    return await new ApiCall(
      `/persona/${guid_client}/${guid_persona}`,
      ApiAuth()
    ).put<IPersona, IPersonaEditInputs>(formdata);
  };
  
  /**
   * Get a persona by guid_persona
   *
   * @param guid_persona
   */
  export const getPersona = async (guid_client: string, guid_persona: string): Promise<TPremiseResponse<IPersona>> => {
    return await new ApiCall(`/persona/${guid_client}/${guid_persona}`, ApiAuth()).get<IPersona>();
  };