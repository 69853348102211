
import { useEffect, useState } from "react";
import { Tooltip, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Text } from "@chakra-ui/react";
import { FormButton } from "../components/login/button.component";
interface IProps {
  callback: (location: string) => void;
  callbackCancel?: () => void;
  callbackSubmit?: (location: string) => void;
  open: boolean;
  openHook: React.Dispatch<React.SetStateAction<boolean>>;
  location: string;
  onClose?: boolean;
  setOnClose?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalContinueEditing = (props: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);
  const [onSubmit, setOnSubmit] = useState<boolean>(false);

  const yesOption = async () => {
    setButtonDisabled(true);
    props.callback(props.location);
    setButtonDisabled(false);
    onClose();
  };

  const submit = async () => {
    setOnSubmit(true);
    setButtonDisabled(true);
    props.callbackSubmit && props.callbackSubmit(props.location);
    setButtonDisabled(false);
  }

  const noOption = () => {
    if(props.callbackCancel) {
      props.callbackCancel();
    }
    onClose();
  }

  useEffect(() => {
    if(props.open) {
      onOpen();
      setOnSubmit(false);
      props.openHook(false);
    }
  }, [props.open]);

  useEffect(() => {
    if(props.onClose) {
      onClose();

      if(props.setOnClose) {
        props.setOnClose(false);
      }
    }
  }, [props.onClose]);

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} isCentered={true} onClose={onClose} size="sm">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Deseja realmente sair?</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Text>
                Você possui alterações que ainda não foram salvas
              </Text>

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                {
                  props.callbackSubmit &&
                  <FormButton onClick={() => submit()} isLoading={onSubmit} disabled={isButtonDisabled} width="50%">Salvar e sair</FormButton>
                }

                <FormButton 
                  onClick={yesOption}
                  disabled={isButtonDisabled}
                  width="50%"
                  color="white"
                  textColor="#4B4EFF"
                  border="1px solid #4B4EFF"
                >
                  Sair sem salvar
                </FormButton>

                <FormButton 
                  onClick={noOption}
                  disabled={isButtonDisabled}
                  width="50%"
                  color="white"
                  textColor="#4B4EFF"
                  border="1px solid #4B4EFF"
                >
                  Cancelar
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}