import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, ModalOverlay, useDisclosure, FormControl, OrderedList, TableContainer, Table, Thead, Tr, Td, Th, Tbody, ButtonGroup, Button } from "@chakra-ui/react"
import { useState, useEffect } from "react";
import { IGetEmployeesResponse } from "../../../../_services/interface/company.interface";
import { useForm } from "react-hook-form";
import { IInviteInputs, IInviteResponse } from "../../../../_services/interface/user.interface";
import { SimpleFormError } from "../../../../components/login/formError.component";
import { postSendInvite } from "../../../../_services/user.service";
import { FormButton } from "../../../../components/login/button.component";
import * as EmailValidator from 'email-validator';
import { UserTag } from "../UserPanel";
import { AddIcon } from "@chakra-ui/icons";
import MiniLoading from "../../../../components/miniLoading"
import { ReactMultiEmail } from "react-multi-email";
import { IClientResponse } from "../../../../_services/interface/client.interface";

interface IInviteUserModalProps {
  client: IClientResponse;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InviteUserModal = (props: IInviteUserModalProps) => {
  const [smplErrorMessage, setSmplErrorMessage] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [allFieldsFilled, setFieldsState] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [mailResult, setMailResult] = useState<IInviteResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [emails, setEmails] = useState<string[]>([])
  //const toast = useToast();

  const { handleSubmit, watch, reset } = useForm<IInviteInputs>();

  //quantity of emails that is possible to send each time.
  const EMAIL_LIMIT = 10

  useEffect(() => {
    watch((value) => {
      if (Object.values(value).filter(_x => _x !== '')) {
        setFieldsState(true);
      } else {
        setFieldsState(false);
      }
    });
  }, [watch]);

  useEffect(() => {
    if (allFieldsFilled) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [allFieldsFilled]);

  const handleFormInfo = async (data: IInviteInputs) => {
    setSubmitDisabled(true);
    setIsLoading(true)
    setSmplErrorMessage('');

    const dataList: { list: {}[] } = { list: [] }
    /* //Separate the emails inside a list
    let emails = data.email.split(";")
    //Make the emails unique
    emails = [...new Set(emails.map(_email => _email.trim()))]; */

    if (emails.length <= EMAIL_LIMIT) {
      //Check if the emails are in the right format
      let allEmailsOk = true;
      emails.forEach(_email => {
        if (!EmailValidator.validate(_email.trim())) {
          allEmailsOk = false;
        } else {
          dataList.list.push({ email: _email.trim() })
        }
      });

      if (allEmailsOk) {
        //Reset the email field
        reset({ email: '' });
        //Send the email
        const { status, response } = await postSendInvite(props.client.guid_client, dataList);
        if (status === 200) {
          setMailResult(response as IInviteResponse[]);
        } else {
          setSmplErrorMessage('Ocorreu algum erro ao enviar o convite, por favor, tente novamente.');
        }
      } else {
        setSmplErrorMessage('Algum email da lista é inválido. Lembre-se de separá-los por ";" (Ponto-e-Vírgula)');
      }
    } else {
      setSmplErrorMessage('Apenas 10 (quatro) emails podem ser indicados para enviar convites.');
    }

    setSubmitDisabled(false);
    setIsLoading(false);
  }

  const checkResult = (data: IInviteResponse) => {
    if (data.invitationSent) {
      return "-";
    } else {
      if (data.isRegisteredInNewCompany) {
        return "Usuário vinculado com sucesso";
      }
      
      if (data.isBlocked) {
        return "Usuário Inativado";
      }

      if (data.isAlreadyRegistered) {
        return "Usuário já registrado";
      }
    }
  }

  const checkInvitationSent = (_el: IInviteResponse) => {
    if (_el.isRegisteredInNewCompany) {
      return <UserTag text="vinculado" color="#CCECC9" />
    }

    return _el.invitationSent ? <UserTag text="enviado" color="#CCECC9" /> : <UserTag text="não enviado" color="#FFD2D2"/>;
  }

  return (
    <>
      <ButtonGroup onClick={onOpen} size="sm" mt="24px" isAttached color="white" >
        <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
          Convidar usuário
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => { props.flushHook(true); onClose() }} size={"xl"} >
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow="1" flexShrink="1" borderRadius="8px" p="16px" borderLeft="12px solid #0263FF" >
          <ModalHeader fontFamily="Poppins-Medium">
            {
              mailResult.length === 0 ? (
                "Convidar Usuários"
              ) : (
                "Usuários Convidados"
              )
            }
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-Medium" >
            <FormControl>
              {smplErrorMessage ? (<SimpleFormError msg={smplErrorMessage} />) : null}
              <Flex alignItems="stretch" direction="column" gap="8px" justifyContent="center">
                {mailResult.length === 0 ? (
                  <>
                    <Text fontSize="14px">
                      Para convidar usuários da empresa {props.client.name}, digite até 4 (quatro) e-mails no campo abaixo, separando-os por “;” (ponto-e-vírgula). E-mails repetidos serão desconsiderados e tratados como únicos.
                    </Text>
                    <ReactMultiEmail
                      placeholder='exemplo@email.com.br'
                      emails={emails}
                      onChange={(_emails: string[]) => {
                        setEmails(_emails);
                      }}
                      autoFocus={true}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                    {/* <Textarea disabled={isFieldDisabled} h="100px" {...register("email", { required: true })} /> */}
                    <Flex direction="column" fontSize="12px" opacity={0.5}>
                      Situações de não recebimento do convite pelo usuário::
                      <OrderedList ml={6} mt={2} mb={4}>
                        <li>E-mails podem cair na caixa de Spam ou na Lixeira dos destinatários, você terá a opção de reencaminhar o e-mail pela a listagem de usuários novamente.</li>
                        <li>Usuários já registrados no sistema receberão novos e-mails de confirmação, e serão ignorados pelo sistema.</li>
                      </OrderedList>
                    </Flex>
                    <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled && isLoading} isLoading={isLoading} width="100%">
                      Convidar Usuários
                    </FormButton>
                  </>
                ) : (
                  <>
                    <Text fontSize="14px">
                      Para convidar novos usuários ou corrigir os convites enviados, clique abaixo.
                    </Text>
                    <Flex direction="column" fontSize="12px" flexGrow="1" flexShrink="1" mb={4} mt={4}>
                      <TableContainer fontFamily="Poppins-Medium" flexGrow="1" flexShrink="1" borderRadius={5}>
                        <Table variant='striped' colorScheme='gray' fontSize="12px">
                          <Thead>
                            <Tr>
                              <Th textTransform="none" textAlign="center" fontFamily="Poppins-Medium">Email</Th>
                              <Th textTransform="none" textAlign="center" fontFamily="Poppins-Medium">Status</Th>
                              <Th textTransform="none" textAlign="center" fontFamily="Poppins-Medium">Motivo</Th>
                            </Tr>
                          </Thead>
                          <Tbody alignItems="center" justifyContent="center">
                            {mailResult.map((_el, _key) => (
                              <Tr key={_key}>
                                <Td textAlign="center" p={2}>{_el.email.split('@')[0]}@...</Td>
                                <Td textAlign="center">{checkInvitationSent(_el)}</Td>
                                <Td textAlign="center">{checkResult(_el)}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Flex>
                    <FormButton onClick={() => setMailResult([])} width="100%">Enviar mais convites</FormButton>
                  </>
                )}

              </Flex>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}