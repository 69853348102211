import { WidgetBase } from "./WidgetBase";
import { Flex } from "@chakra-ui/react";
import { BrazilMap } from "../Map/Brazil";
import { IWidgetIdent } from "../interfaces/IWidget";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { RectShape } from "react-placeholder/lib/placeholders";

interface IMapWidget extends IWidgetIdent {
  data?: IClientCompanies[]
  isLoading?: boolean;
  id?: string,
  index?: number
}

export const MapWidget = (props: IMapWidget) => {
  if (props.id && props.index !== undefined) {
    let allCompanies: any = {}
    if (props.data) {
      props.data.forEach(item => {
        if (!allCompanies[item.initials]) {
          allCompanies[item.initials] = {
            name: "",
            subsidiary: 0,
            holding: 0
          }
        }
        allCompanies[item.initials][item.type] += 1
        allCompanies[item.initials].name = item.state
      })

      /* 
        the code above will generate a object like this:
        {
          "PR": {
            name: "string",
            subsidiary: number,
            holding: number
          },
          "RJ": {
            name: "string",
            subsidiary: number,
            holding: number
          }
        }
      */
    }
    return (
      <WidgetBase title="Mapa das empresas" id={props.id} index={props.index}>
        <Flex alignItems="center" justifyContent="center" position="relative" width="100%">
          <Flex w="600px" h="550px" alignItems="center" justifyContent="center">
            { 
              props.isLoading ? (
                <RectShape color='#E0E0E0' style={{ width: 500, height: 700, marginBottom:'5px' }} />
              ) : (<>
                <BrazilMap data={allCompanies} />
                <Flex position="absolute" bg="gray.100" borderRadius={5} bottom={10} right={10} w={150} p={2} fontSize={11}>Passe o mouse sob um estado para ver as informações sobre empresas presentes no mesmo</Flex>
              </>)
            }
          </Flex>
        </Flex>
      </WidgetBase>
    )
  } else {
    return null
  }

}
