import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { CNDIsRunning, getRetrieveCNDByType } from "../../../../_services/cnd.service";
import { ICNDListData } from "../../../../_services/interface/cnd.interface";

interface ISearchCndModalProps {
  guid_client: string;
  certificate: ICNDListData;
  openModal?: boolean;
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchCndModal = (props: ISearchCndModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // eslint-disable-next-line
  const [running, setRunning] = useState<boolean>(false);
  const [isLoadingCnds, setLoadingCndStatus] = useState<boolean>(false);
  const toast = useToast();
  const toastOverseer = useRef<any>();

  const yesOption = async () => {
    setLoadingCndStatus(true);
    toastOverseer.current = toast({
      title: 'Estamos pesquisando a certidão…',
      description: 'Em breve a consulta será realizada',
      status: 'loading',
      isClosable: true,
      duration: 2000,
    });

    onClose();

    try {
      let status: number | null = null;

      if(props.certificate.guid_persona) {
        const apiResponse = await getRetrieveCNDByType(
          props.guid_client,
          props.certificate.guid_persona,
          props.certificate.slug,
          true
        );

        status = apiResponse.status;
      } else {
        if(!props.certificate.guid_company) return false;
        const apiResponse = await getRetrieveCNDByType(
          props.guid_client,
          props.certificate.guid_company,
          props.certificate.slug
        );

        status = apiResponse.status;
      }

      if (status && status === 200) {
        toast.close(toastOverseer.current);

        toast({
          title: 'Estamos pesquisando a certidão em segundo plano',
          description: 'Em breve a consulta será concluída e essa tela poderá ser atualizada.',
          status: 'loading',
          isClosable: true,
          duration: 5000,
        });
      } else if (!!status) {
        toast({
          title: 'Falha ao encontrar certidão',
          description: `Ocorreu uma falha ao tentar buscar certidões, por favor, tente novamente mais tarde.`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });  
      }
    } catch (err) {
      toast({
        title: 'Falha ao encontrar Certidões',
        description: `O serviço demorou mais de 2 minuto para retornar um resultado.`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
    setLoadingCndStatus(false);
  }

  const isRunning = async () => {
    if(!props.certificate.guid_company) return false;
    const response = await CNDIsRunning(props.certificate.slug, props.certificate.guid_company);
    if (response.status === 200) {
      setRunning(response.response as boolean);
    }
  }

  useEffect(() => {
    if (props.openModal && props.openModalHook) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  useEffect(() => {
    if(isOpen) {
      isRunning();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
      <>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
          <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
            <ModalHeader fontFamily="Poppins-SemiBold">Buscar Certidões</ModalHeader>
            <ModalCloseButton zIndex={99999} />
            <ModalBody fontFamily="Poppins-medium" fontSize={12}>
              <Flex justifyContent="center" flexDirection="column" gap={5}>
                <Flex flexDirection="column" gap={5}>
                  {running ? (
                      <Flex flexDirection="column" gap={8} alignItems="center" backgroundColor="whiteAlpha.900" position="absolute" top={0} bottom={0} left={0} right={0} alignContent="center" justifyContent="center" zIndex={99}>
                        <Text textAlign="center" fontSize={20}>Buscando Certidão, por favor aguarde</Text>
                        <Spinner width={50} height={50} />
                      </Flex>
                  ) : null}
                  <Text>
                    Deseja pesquisar certidões para esse CNPJ? Após clicar em sim, você apenas poderá pesquisar novamente por certidões depois de <strong>5 dias</strong>.
                  </Text>
                  <Text>
                    Esta ação não afetará o agendamento desta empresa.
                  </Text>
                </Flex>
                <Flex gap={2} flexGrow={1} justifyContent="space-between">
                  <Button isDisabled={isLoadingCnds} bg="#4B4EFF" color="white" fontWeight="thin" fontSize="12px" h="32px" width="50%" onClick={() => yesOption()}>Sim</Button>
                  <Button isDisabled={isLoadingCnds} bg="#4B4EFF" color="white" fontWeight="thin" fontSize="12px" h="32px" width="50%" onClick={() => onClose()}>Não</Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
  );
}