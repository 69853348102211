import { createIcon } from "@chakra-ui/react";

export const DragIcon = createIcon({
  displayName: 'DragIcon',
  viewBox: '0 0 24 24',
  path: [
    (<path d="M7 18.005C7 19.1078 7.9 20 9 20C10.1 20 11 19.1078 11 18.005C11 16.9023 10.1 16 9 16C7.9 16 7 16.9023 7 18.005Z" fill="currentColor"/>),
    (<path d="M7 12.005C7 13.1078 7.9 14 9 14C10.1 14 11 13.1078 11 12.005C11 10.9023 10.1 10 9 10C7.9 10 7 10.9023 7 12.005Z" fill="currentColor"/>),
    (<path d="M7 6.00501C7 7.10777 7.9 8 9 8C10.1 8 11 7.10777 11 6.00501C11 4.90226 10.1 4 9 4C7.9 4 7 4.90226 7 6.00501Z" fill="currentColor"/>),
    (<path d="M13 6.00501C13 7.10777 13.9 8 15 8C16.1 8 17 7.10777 17 6.00501C17 4.90226 16.1 4 15 4C13.9 4 13 4.90226 13 6.00501Z" fill="currentColor"/>),
    (<path d="M13 12.005C13 13.1078 13.9 14 15 14C16.1 14 17 13.1078 17 12.005C17 10.9023 16.1 10 15 10C13.9 10 13 10.9023 13 12.005Z" fill="currentColor"/>),
    (<path d="M13 18.005C13 19.1078 13.9 20 15 20C16.1 20 17 19.1078 17 18.005C17 16.9023 16.1 16 15 16C13.9 16 13 16.9023 13 18.005Z" fill="currentColor"/>)
  ]
});