import { Flex, Text } from '@chakra-ui/react';
import { de } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import 'react-multi-email/dist/style.css';
import { ModalContinueEditing } from '../../../modal/ModalContinueEditing';
import { ICertificate } from "../../../_services/interface/certificate.interface";
import { ConfigPanelSidebar } from './ConfigPanelSidebar';
import { Account } from './content/Account';
import { Certificate } from './content/Certificate';
import { ChangePassword } from './content/ChangePassword';
import { Company } from './content/Company';
import { Display } from './content/Display';
import { Notification } from './content/Notification';
import { TaxCurrentAccount } from './content/TaxCurrentAccount';
import { TaxKanban } from './content/TaxKanban';

interface IConfigPanelProps {
  guid_client: string;
  certificates: ICertificate[];
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
  hasChange: boolean;

  submit: boolean;
  submitError: boolean;
  onClose: boolean;

  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;
  setOnClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfigPanel = (props: IConfigPanelProps) => {
  const [selectedContent, setSelectedContent] = useState<string>('account');
  const [desiredContent, setDesiredContent] = useState<string>('account');
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [openModalContinueEditing, setOpenModalContinueEditing] = useState<boolean>(false);

  const [submit, setSubmit] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [onClose, setOnClose] = useState<boolean>(false);

  const getContent = (): React.ReactNode => {
    switch (selectedContent) {
      case 'account':
        return <Account refreshData={refreshData} setSelectedContent={setDesiredContent} hasChangeHook={props.hasChangeHook} submit={submit} setSubmit={setSubmit} submitError={submitError} setSubmitError={setSubmitError} />;
      case 'changePassword':
        return <ChangePassword setSelectedContent={setDesiredContent} hasChangeHook={props.hasChangeHook} submit={submit} setSubmit={setSubmit} submitError={submitError} setSubmitError={setSubmitError} />;
      case 'display':
        return <Display refreshData={refreshData} guid_client={props.guid_client} hasChangeHook={props.hasChangeHook} submit={submit} setSubmit={setSubmit} submitError={submitError} setSubmitError={setSubmitError} />;
      case 'company':
        return <Company refreshData={refreshData} guid_client={props.guid_client} hasChangeHook={props.hasChangeHook} submit={submit} setSubmit={setSubmit} submitError={submitError} setSubmitError={setSubmitError} />;
      case 'taxKanban':
        return <TaxKanban refreshData={refreshData} guid_client={props.guid_client} hasChangeHook={props.hasChangeHook} submit={submit} setSubmit={setSubmit} submitError={submitError} setSubmitError={setSubmitError} />;
      case 'notification':
        return <Notification refreshData={refreshData} guid_client={props.guid_client} hasChangeHook={props.hasChangeHook} submit={submit} setSubmit={setSubmit} submitError={submitError} setSubmitError={setSubmitError} />;
      case 'certificate':
        return <Certificate refreshData={refreshData} guid_client={props.guid_client} certificates={props.certificates} hasChangeHook={props.hasChangeHook} submit={submit} setSubmit={setSubmit} submitError={submitError} setSubmitError={setSubmitError} />;
      case 'taxCurrentAccount':
        return <TaxCurrentAccount refreshData={refreshData} guid_client={props.guid_client} hasChangeHook={props.hasChangeHook} submit={submit} setSubmit={setSubmit} submitError={submitError} setSubmitError={setSubmitError} />;
      default:
        return <></>;
    }
  }

  useEffect(() => {
    setRefreshData(true);

    props.hasChangeHook(false);

    setTimeout(() => {
      setRefreshData(false);
    }, 1000);
  }, [selectedContent]);

  useEffect(() => {
    if(desiredContent === selectedContent) {
      return;
    }

    if(props.hasChange) {
      setOpenModalContinueEditing(true);
    } else {
      setSelectedContent(desiredContent);
    }
  }, [desiredContent]);

  useEffect(() => {
    if(!submit && desiredContent !== selectedContent) {
      if(desiredContent !== selectedContent) {
        setSelectedContent(desiredContent);
      }

      setOnClose(true);
    }
  }, [submit]);

  useEffect(() => {
    if(submitError) {
      setSubmit(false);
      setSubmitError(false);
      setDesiredContent(selectedContent);
      setOnClose(true);
    }
  }, [submitError]);

  useEffect(() => {
    if(props.submit) {
      setSubmit(props.submit);
      props.setSubmit(!props.submit);
    }
  }, [props.submit]);

  useEffect(() => {
    if(props.submitError) {
      setSubmitError(props.submitError);
      props.setSubmitError(!props.submitError);
    }
  }, [props.submitError]);

  useEffect(() => {
    if(props.onClose) {
      setOnClose(props.onClose);
      props.setOnClose(!props.onClose);
    }
  }, [props.onClose]);

  return (<>
    <Flex direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" pr="30px" mt="10px">
      <Flex flexDirection="row" justifyContent="space-between" borderBottom="1px" borderBottomColor="gray.300" pb={4}>
        <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">
          Configurações
        </Text>
      </Flex>

      <Flex 
        width="100%"
        height="auto"
        marginTop="35px"
        justifyContent={'space-between'}
      >
        <ConfigPanelSidebar
          setSelectedContent={setDesiredContent}
          selectedContent={selectedContent}
        />

        <Flex
          backgroundColor="white"
          height={'100%'}
          width="75%"
          padding="24px 16px"
        >
          {getContent()}
        </Flex>
      </Flex>
    </Flex>


    <ModalContinueEditing
      callback={setSelectedContent}
      open={openModalContinueEditing}
      openHook={setOpenModalContinueEditing}
      location={desiredContent}

      callbackCancel={() => setDesiredContent(selectedContent)}
      callbackSubmit={(value) => {setDesiredContent(value); setSubmit(true);}}
      onClose={onClose}
      setOnClose={setOnClose}
    />
  </>)
}
