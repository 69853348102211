import { createIcon } from '@chakra-ui/icons'

export const At = createIcon({
  displayName: 'At',
  viewBox: '0 0 24 24',
  path: (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 16.5C14.2091 16.5 16 14.7091 16 12.5C16 10.2909 14.2091 8.5 12 8.5C9.79086 8.5 8 10.2909 8 12.5C8 14.7091 9.79086 16.5 12 16.5Z"
        stroke="#171923" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round"/>
      <path
        d="M16 8.49999V13.5C16 14.2956 16.3161 15.0587 16.8787 15.6213C17.4413 16.1839 18.2044 16.5 19 16.5C19.7957 16.5 20.5587 16.1839 21.1213 15.6213C21.6839 15.0587 22 14.2956 22 13.5V12.5C21.9999 10.243 21.2362 8.05247 19.8333 6.28452C18.4303 4.51658 16.4706 3.27521 14.2726 2.76229C12.0747 2.24936 9.76794 2.49503 7.72736 3.45936C5.68677 4.42368 4.03241 6.04995 3.03327 8.07371C2.03413 10.0975 1.74898 12.3997 2.22418 14.6061C2.69938 16.8125 3.90699 18.7932 5.65064 20.2263C7.39429 21.6593 9.57144 22.4603 11.8281 22.4991C14.0847 22.5379 16.2881 21.8122 18.08 20.44"
        stroke="#171923" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round"/>
    </svg>
  )
});
