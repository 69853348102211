import { ComponentStyleConfig } from "@chakra-ui/react";

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    color: "neutral.50",
    fontSize: "14px",
    mb: "4px",
    fontWeight: "bold",
    textStyle: "caption.large",
  },
};
