import { Input, Select } from "@chakra-ui/react"

interface IFilterProps {
  value: any;
  placeholder: string;
  onChange: any;
  children?: any;
  disabled?: boolean;
}

export const SelectFilter = (props: IFilterProps) => {
  return (
    <Select
      display="flex"
      borderRadius="6"
      border="1px"
      fontFamily="Poppins-Medium"
      borderColor="gray.300"
      bg="gray.100"
      placeholder={props.placeholder}
      _hover={{ cursor: "pointer" }}
      color="blue"
      textColor="Black"
      fontSize="12px"
      value={props.value}
      onChange={props.onChange}
      isDisabled={props.disabled ? props.disabled : false}
      

    >
      {props.children}
    </Select>
  );
}

export const InputFilter = (props: IFilterProps) => {
  return (
    <Input
      display="flex"
      fontSize="12px"
      bg="#F3F3F3"
      fontFamily="Poppins-Medium"
      borderRadius="6px"
      borderTop="none"
      borderX="none"
      bgColor="ray.70"
      borderBottom="1px solid"
      borderBottomColor="blackAlpha.300"
      type="search"
      placeholder={props.placeholder}
      color="#A1A1A1"
      value={props.value}
      onChange={props.onChange} 

    />
  );
}

export const DateFilter = (props: IFilterProps) => {
  return (
    <Input
      display="flex"
      fontSize="12px"
      bg="#F3F3F3"
      fontFamily="Poppins-Medium"
      borderRadius="0px"
      borderTop="none"
      borderX="none"
      bgColor="transparent"
      borderBottom="1px solid"
      borderBottomColor="blackAlpha.300"
      type="date" // Utilize o tipo "date" para o input de data
      placeholder={props.placeholder}
      color="#A1A1A1"
      value={props.value}
      onChange={props.onChange}
    />
  );
};