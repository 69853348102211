export const calcPercent = (base: number, total: number) => {
  return base > 0 ? Math.ceil((base / total) * 100) > 100 ? 100 : Math.ceil((base / total) * 100) : 0;
}

export function gradient(color1: string, color2: string, ratio: number): string {
  const from = rgb(color1)
  const to = rgb(color2)

  const r = Math.ceil(from.r * ratio + to.r * (1 - ratio));
  const g = Math.ceil(from.g * ratio + to.g * (1 - ratio));
  const b = Math.ceil(from.b * ratio + to.b * (1 - ratio));

  return "#" + hex(r) + hex(g) + hex(b);
}

/** eg. rgb("#FF0080") => { r: 256, g: 0, b: 128 } */
function rgb(color: string) {
  const hex = color.replace("#", "")
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
  }
}

/** eg. hex(123) => "7b" */
function hex(num: any): string | number {
    num = num.toString(16);
    return (num.length === 1) ? '0' + num : num;
}