import { Flex, Text } from "@chakra-ui/react";
import { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContainer } from "../../components/GlobalContainer";
import { LoginForm } from "./login/LoginForm";
import { LoginFormSSO } from "./login/LoginSSOForm"
import { getIsSSO } from "../../_services/auth.service";


export default function Login() {
  const [location, setLocation] = useState<ReactElement>();
  const [isSSO, setIsSSO] = useState(false)

  const routerLocation = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (routerLocation.hash !== '') {
      const path = (/#!(\/.*)$/.exec(routerLocation.hash) || [])[1];
      navigate(path)
    }
  });

  useEffect(() => {
     const getSSO = async () => {
      const {response} = await getIsSSO();
      if(response) setIsSSO(true)
     }
     getSSO();
  }, []);

  return (
    <GlobalContainer translate="no" display="flex" style={{ backgroundColor: '#f8f7f7'}}>
      <Flex id="teste" position="absolute" left="0" top="0" right="0" bottom="0">
        <Flex w="60%" bgGradient="linear(to-r, #4B4EFF, #5649ff)" boxShadow="0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 5px rgba(60,64,67,0.15)" justifyContent="center" alignItems="center">
          <Text color="white" fontFamily="Comfortaa-Bold" m="0" fontSize="120px" textShadow="5px 5px #3032c9">
            taxly
          </Text>
        </Flex>
        <Flex w="40%" justifyContent="center" alignItems="center">
          {/* Zenziski: Substituir o componente <LoginForm /> abaixo pelo o componente do SSO */}

          {
            !isSSO ?
              (!location ? <LoginForm locationHandler={setLocation} /> : location)
              :
              (<LoginFormSSO></LoginFormSSO>)
          }
        </Flex>
      </Flex>
    </GlobalContainer>
  );
}
