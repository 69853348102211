import { Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { TaxlyInput } from "../../../../components/Input";
import { FormButton } from "../../../../components/login/button.component";
import { configAccount, getConfigAccount } from "../../../../_services/config.service";
import "react-placeholder/lib/reactPlaceholder.css";
import { RectShape, TextBlock } from "react-placeholder/lib/placeholders";

interface IAccountProps {
  refreshData: boolean;
  setSelectedContent: React.Dispatch<React.SetStateAction<string>>;
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
  submit: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: boolean;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IAccountForm {
  name: string
  email: string,
  position?: string | null
}

export const Account = (props: IAccountProps) => {
  const toast = useToast();

  const YupCompanyChangeForm = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().required('E-mail é obrigatório'),
    position: Yup.string().optional().nullable(),
  });

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<IAccountForm>({ resolver: yupResolver(YupCompanyChangeForm) });

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  const handleFormInfo = async (data: IAccountForm) => {
    setSubmitDisabled(true);
    const { status, response } = await configAccount({
      name: data.name,
      email: data.email,
      phone: phone?.replace(/\D/g, '') ?? null,
      position: data.position ?? null
    });

    if (status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });

      props.setSubmit(false);
      props.hasChangeHook(false);
    } else if (status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    } else {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    }

    setSubmitDisabled(false);
  }

  const configFields = async () => {
    setIsLoading(true);
    const { status, response } = await getConfigAccount();

    if (status === 200 && response && 'name' in response) {
      const { name, email, phone, position } = response;
      setValue('name', name);
      setValue('email', email);
      setValue('position', position);
      setPhone(phone ?? undefined);
    }
    setTimeout(() => setIsLoading(false), 500);
  }

  useEffect(() => {
    configFields();
  }, [])

  useEffect(() => {
    if (props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  useEffect(() => {
    if(props.submit) {
      handleSubmit(
        handleFormInfo, 
        (errors) => {
          props.setSubmitError(true);
        }
      )();
    }
  }, [props.submit]);

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px" justifyContent="space-between" width="80%">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Minha conta</Text>

        <Button
          type="button"
          borderRadius="4px"
          bgColor={"transparent"}
          color={"#171923"}
          _hover={{ bgColor: "#686AFF", cursor: "pointer", color:"white" }}
          border={"1px solid #4B4EFF"}
          h="32px"
          fontSize="12px"
          fontWeight={400}
          fontFamily="Poppins-medium"
          onClick={() => props.setSelectedContent('changePassword')}
        >
          <Flex gap="2">
            <Image w="15px" src="/icons/key.svg" />
            <Text>Alterar Senha</Text>
          </Flex>
        </Button>
      </Flex>

      <Flex width="80%" flexDirection="column" gap="24px" mb="35px">
        <Flex gap="15px">
          <Flex w="50%" flexDirection="column">
            {isLoading ? (
              <Flex flexDirection="column" width="100%">
                <Flex
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  fontSize="12px"
                  fontFamily='Poppins-Medium'
                  color="#1A202C"
                >
                  <label>Nome Completo</label>
                </Flex>

                <RectShape color='#e0e0e0' style={{width: '100%', height: 40}}/>
              </Flex>
            ) : (
              <TaxlyInput
                w="100%"
                placeholder="Nome Completo"
                label={'Nome Completo'}
                {...register("name")}
                onChange={(e) => {props.hasChangeHook(true);}}
              />
            )}
            {errors.name && <Text color="red.500" fontSize="12px">{errors.name.message}</Text>}
          </Flex>

          <Flex w="50%" flexDirection="column">
            {isLoading ? (
              <Flex flexDirection="column" width="100%">
                <Flex
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  fontSize="12px"
                  fontFamily='Poppins-Medium'
                  color="#1A202C"
                >
                  <label>E-mail</label>
                </Flex>

                <RectShape color='#e0e0e0' style={{width: '100%', height: 40}}/>
              </Flex>
            ) : (
              <TaxlyInput
                w="100%"
                placeholder="E-mail"
                label={'E-mail'}
                {...register("email")}
                onChange={(e) => {props.hasChangeHook(true);}}
              />
            )}
            {errors.email && <Text color="red.500" fontSize="12px">{errors.email.message}</Text>}
          </Flex>
        </Flex>

        <Flex gap="15px">
          <Flex w="50%" flexDirection="column">
            {isLoading ? (
              <Flex flexDirection="column" width="100%">
                <Flex
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  fontSize="12px"
                  fontFamily='Poppins-Medium'
                  color="#1A202C"
                >
                  <label>Telefone</label>
                </Flex>

                <RectShape color='#e0e0e0' style={{width: '100%', height: 40}}/>
              </Flex>
            ) : (
              <TaxlyInput
                w="100%"
                placeholder="Telefone"
                label={'Telefone'}
                mask="(99) 99999-9999"
                onChange={(e) => { setPhone(e.target.value); props.hasChangeHook(true); }}
                value={phone}
              />
            )}
          </Flex>

          <Flex w="50%" flexDirection="column">
            {isLoading ? (
              <Flex flexDirection="column" width="100%">
                <Flex
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  fontSize="12px"
                  fontFamily='Poppins-Medium'
                  color="#1A202C"
                >
                  <label>Cargo</label>
                </Flex>

                <RectShape color='#e0e0e0' style={{width: '100%', height: 40}}/>
              </Flex>
            ) : (
              <TaxlyInput
                w="100%"
                placeholder="Cargo"
                label={'Cargo'}
                {...register("position")}
                onChange={(e) => {props.hasChangeHook(true);}}
              />
            )}
            {errors.position && <Text color="red.500" fontSize="12px">{errors.position.message}</Text>}
          </Flex>
        </Flex>
      </Flex>

      <hr />

      <Flex width="100%" mt="35px" justifyContent="flex-start">
        <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled || isLoading}>
          Salvar alterações
        </FormButton>
      </Flex>
    </Flex>
  );
};
