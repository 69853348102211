import { ComponentStyleConfig } from "@chakra-ui/react";

export const Tag: ComponentStyleConfig = {
  baseStyle: {
    container: {
      textStyle: "bodyS",
      color: "sys.onSecondary",
    },
  },
  defaultProps: {
    variant: "none",
  },
};
