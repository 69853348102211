import { Button, ButtonGroup, Checkbox, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { EToastStatus } from "../../../../components/ToastEnum";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SelectMultiEmail from "../../../../components/SelectMultiEmail";
import { getCompanyPossibleEmails } from "../../../../_services/company.service";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { useJune } from "../../../../components/June";
import { IFilterListTaxCurrentAccount } from "../../../../_services/interface/taxCurrentAccount.interface";
import { exportTaxCurrentAccount } from "../../../../_services/taxCurrentAccount.service";
import * as moment from 'moment';
import { downloadFile } from "../../../../utils/utils";
import LoadingComponent from "../../../../components/loading";
import { useClientContext } from "../../../../components/sidebar";


interface ITaxCurrentAccountMultiDownloadProps {
  getTaxCurentAccountFilter: () => IFilterListTaxCurrentAccount;
  slug?: string;
}

export interface ITaxCurrentAccountMultiDownloadForm {
  exportZip: boolean;
  exportXlsx: boolean;
  exportCsv: boolean;
  count: boolean;
  emails: string[];
}

export const TaxCurrentAccountMultiDownload = (props: ITaxCurrentAccountMultiDownloadProps) => {
  const analytics = useJune();
  const { guid_client } = useClientContext();

  const YupCompanyChangeForm = Yup.object().shape({
    exportZip: Yup.bool(),
    exportXlsx: Yup.bool(),
    exportCsv: Yup.bool(),
  });

  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);
  // eslint-disable-next-line
  const [emails, setEmails] = useState<string[]>([])
  const [countFiles, setCountFiles] = useState<number>(0);
  const [sendByEmail, setSendByEmail] = useState<boolean>(false)
  const { register, watch, handleSubmit, reset } = useForm<ITaxCurrentAccountMultiDownloadForm>(resolverForm);
  const { validate } = useLoginFormStore();

  useEffect(() => {
    if (isOpen && guid_client) {
      countFilesToExport(guid_client);
    }
  }, [isOpen, guid_client]);

  const countFilesToExport = async (guid_client: string) => {
    setLoadingData(true);

    const {status, response} = await exportTaxCurrentAccount(guid_client, {
      ...props.getTaxCurentAccountFilter(),
      page: 1,
      row_by_page: -1,
      emails: [],
      exportZip: false,
      exportXlsx: false,
      exportCsv: false,
      count: true,
    });

    if(status === 200 && 'count' in response) {
      setCountFiles(response.count ?? 0);
    }

    setLoadingData(false);
  }

  const handleFormInfo = async (data: ITaxCurrentAccountMultiDownloadForm) => {
    if(!guid_client) {
      return;
    }

    setLoadingStatus(true);

    const toastLoading = toast({
      title: "Processando a Requisição.",
      description: "Aguarde o processamento da requisição, dependendo da quantidade de arquivos, pode demorar alguns segundos.",
      status: EToastStatus.LOADING,
      duration: null,
    });

    let {status, response} = await exportTaxCurrentAccount(guid_client, {
      ...props.getTaxCurentAccountFilter(),
      page: 1,
      row_by_page: -1,
      exportZip: data.exportZip,
      exportXlsx: data.exportXlsx,
      exportCsv: data.exportCsv,
      emails: emails,
      count: false,
    });

    if (response) {
      const downloadFilesSequentially = async () => {
        try {
          if ('xlsxUrl' in response && response.xlsxUrl) {
            const xslxFileName = `Taxly XLSX - ${moment.utc().format('DD_MM_YYYY')}.xlsx`;
            await downloadFile(response.xlsxUrl as string, xslxFileName);
          }
    
          if ('csvUrl' in response && response.csvUrl) {
            const csvFileName = `Taxly CSV - ${moment.utc().format('DD_MM_YYYY')}.csv`;
            await downloadFile(response.csvUrl as string, csvFileName);
          }

          if ('zipUrl' in response && response.zipUrl) {
            const csvFileName = `Taxly ZIP - ${moment.utc().format('DD_MM_YYYY')}.zip`;
            await downloadFile(response.zipUrl as string, csvFileName);
          }
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };
    
      downloadFilesSequentially();
    }  

    onClose();
    toast.close(toastLoading)

    setLoadingStatus(false);
  }

  const disabledButton = () => {
    const { exportZip, exportXlsx, exportCsv } = watch();

    if (!isLoading && (exportZip || exportXlsx || exportCsv)) {
      return false;
    }

    return true;
  }

  const getEmailsOptions = async (): Promise<string[]> => {
    if(!guid_client) {
      return [];
    }

    let localEmails: string[] = [];
    const { status, response } = await getCompanyPossibleEmails(guid_client);
    if (status === 200 && Array.isArray(response)) {
      setEmails(response as string[]);
      localEmails = response as string[];
    }

    return localEmails;
  }

  const getSelectedEmails = async (): Promise<string[]> => {
    if(validate) {
      return [validate.email];
    }

    return [];
  }

  return (
    <Flex>
      <ButtonGroup onClick={onOpen} size="sm" mt="24px" isAttached color="white" >
        <Button color="white" bgColor="#4B4EFF" _hover={{ bg: '#282be0' }}>
          <Flex display="flex" justifyContent="center" alignItems="center">
            <Image height="15px" width="15px" marginRight="5px" src="/icons/download_icon.png" resize="none" />
            Exportar informações
          </Flex>
        </Button>
      </ButtonGroup>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="4xl" >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF" maxWidth={800}>
          <ModalHeader fontFamily="Poppins-Medium">Exportar arquivos</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-Medium">
            {!isLoadingData ? (
              <Flex direction="column" fontSize="12px" gap={2} mb={4}>
                <Text>Selecione abaixo os arquivos que deseja exportar (considerando a visualização atual do sistema):</Text>
                <small>Relatórios serão gerados considerando a visualização e filtros aplicados</small>

                <Text marginTop="15px" fontSize="14px">Exportar relatório geral de certidões:</Text>

                <Checkbox
                  size="sm"
                  colorScheme="green"
                  {...register("exportXlsx")}>XLS
                </Checkbox>

                <Checkbox
                  size="sm"
                  colorScheme="green"
                  {...register("exportCsv")}>CSV
                </Checkbox>

                <Checkbox
                  marginTop="15px"
                  size="sm"
                  colorScheme="green"
                  {...register("exportZip")}
                >
                  Exportar certidões (arquivo ZIP)
                </Checkbox>
                <small>Total de certidões a serem baixadas: <strong>{countFiles}</strong></small>

                <Flex align="start" mt={6} _hover={{ cursor: "pointer" }} gap={3} onClick={() => setSendByEmail(!sendByEmail)}>
                  {sendByEmail ? (
                    <Image w="34px" src="/icons/switch-blue.svg" />
                  ) : (
                    <Image w="34px" src="/icons/switch-gray.svg" />
                  )}
                  <Flex direction="column">
                    <Text fontSize="12px">
                    Gostaria de compartilhar por e-mail? (opcional)
                    </Text>
                  </Flex>
                </Flex>

                {sendByEmail && (
                  <Flex direction="column">
                    <Text opacity={0.9} mb={2} mt={2}>
                      Digite o(s) email(s) de quem irá receber a exportação (opcional)
                    </Text>
                    <SelectMultiEmail
                      onChange={(_emails: string[]) => {
                        setEmails(_emails);
                      }}
                      getEmailsOptions={getEmailsOptions}
                      getSelectedEmails={getSelectedEmails}
                    />
                  </Flex>
                )}

                <FormButton disabled={disabledButton() ? true : false} onClick={handleSubmit(handleFormInfo)}>Baixar arquivos</FormButton>
              </Flex>
            ) : (<LoadingComponent />)}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}