import { Flex, Text, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react"
import { useState } from "react";
import 'moment/locale/pt-br';
import { CompanyGroupPanel } from "./CompanyGroup/CompanyGroupPanel";
import { CompanyPanel } from "./Company/CompanyPanel";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";

interface ICompanyPanelProps {
  guid_client: string;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
}

export const Company = (props: ICompanyPanelProps) => {
  const [refreshCompaniesData, setRefreshCompaniesData] = useState<boolean>(true);
  const [refreshCompaniesGroupsData, setRefreshCompaniesGroupsData] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState(0);
  const [totalCompanies, setTotalCompanies] = useState<number>(0);
  const [totalGroupCompanies, setTotalGroupCompanies] = useState<number>(0);

  const onChangeTabs = (tab: any) => {
    setActiveTab(tab);
    if (tab === 0) {
      setRefreshCompaniesData(true);
    } else if (tab === 1) {
      setRefreshCompaniesGroupsData(true);
    } 
  };

  return (<>
    <Flex direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" mt="10px">
      <Flex flexDirection="column" height="100%">
        {
          activeTab === 0 ? 
            <Text 
              fontSize="18px" 
              mt={8} 
              fontFamily="Poppins-Medium">Listagem Geral de Empresas {`(${totalCompanies})`}</Text> 
          : null
        }

        {
          activeTab === 1 ? 
            <Text 
              fontSize="18px" 
              mt={8} 
              fontFamily="Poppins-Medium">Lista de Grupos de Empresas {`(${totalGroupCompanies})`}</Text>
          : null
        }
        
        <Tabs
          onChange={onChangeTabs}
          variant="unstyled"
          align="end"
          colorScheme="blue"
          mt={-8}
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          <TabList borderBottom="1px solid" borderColor="gray.300">
            <Tab
              fontSize={14}
              borderTopRadius={5}
              _selected={{ color: "white", bg: "#4B4EFF" }}
            >
              Empresas
            </Tab>
            <Tab
              fontSize={14}
              borderTopRadius={5}
              _selected={{ color: "white", bg: "#4B4EFF" }}
            >
              Grupos de Empresas
            </Tab>
          </TabList>

          <TabPanels height="100%">
            <TabPanel height="100%">
              <CompanyPanel
                guid_client={props?.guid_client as string}
                refreshData={refreshCompaniesData}
                refreshDataHook={setRefreshCompaniesData}
                totalCompaniesHook={setTotalCompanies}
                socket={props.socket}
              />
            </TabPanel>

            <TabPanel height="100%">
              <CompanyGroupPanel
                guid_client={props?.guid_client as string}
                setRefresh={setRefreshCompaniesGroupsData}
                refresh={refreshCompaniesGroupsData}
                totalCompaniesGroupHook={setTotalGroupCompanies}
              />
            </TabPanel>

          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  </>)
}