import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, InputGroup, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useRef } from "react";
import { IOptions, IProps } from "./Interfaces/SelectWithIcon"

export default function SelectWithIcon(props: IProps) {
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const changeSelectedOption = (option: IOptions) => {
    if(props.setSelectedOptions) {
      props.setSelectedOptions(option);
    }
  }

  const handleLabelClick = () => {
    if (menuButtonRef.current) {
      menuButtonRef.current.click();
    }
  }

  return (
    <Flex w={props.width ?? '100%'} flexDirection={'column'}>
      <label
        htmlFor={props.id}
        style={{
          fontSize: '12px',
          flexDirection: 'column',
          fontWeight: 500,
          fontFamily: 'Poppins-Medium',
          marginBottom: '5px',
          ...props.customLabelStyle,
        }}
        onClick={handleLabelClick}
      >
        {props.label}
      </label>

      <InputGroup
        border="1px solid var(--chakra-colors-chakra-border-color)"
        borderRadius={6}
        zIndex={9999999}
        style={{ ...props.customInputStyle }}
      >
        <Menu>
          <MenuButton
            as={Button}
            display="flex"
            alignItems="center"
            fontSize="12px"
            width="auto"
            w={'100%'}
            backgroundColor="white"
            p="4px 8px"
            fontFamily={'Poppins-Medium'}
            fontWeight={500}
            justifyContent="flex-start"
            height="32px"
            _hover={{ cursor: 'pointer' }}
            rightIcon={<ChevronDownIcon/>}
            disabled={props.isDisabled}
            isDisabled={props.isDisabled}
            ref={menuButtonRef}
          >
            <Flex alignItems="center">
              {props.selected.icon && (
                <Flex mr="5px">
                  {props.selected.icon}
                </Flex>
              )}

              <Box as="span" mr="2">
                {props.selected.label}
              </Box>
            </Flex>
          </MenuButton>

          <MenuList>
            {props.options
            .filter(option => option.value !== props.selected.value)
              .map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => changeSelectedOption(option)}
                  display="flex"
                  alignItems="center"
                  fontFamily={'Poppins-Medium'}
                  fontWeight={500}
                  zIndex={999}
                  fontSize={'12px'}
                >
                  {option.icon && (
                    <Box as="span" mr="2">
                      {option.icon}
                    </Box>
                  )}

                  {option.label}
                </MenuItem>
              ))}
          </MenuList>
        </Menu>
      </InputGroup>
    </Flex>
  )
}