import { Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Select, useToast, Tooltip, Text, Checkbox, Grid, GridItem, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IClientCompanies } from "../../../../../_services/interface/company.interface";
import { IGetCompany } from "../../../../../_services/interface/company.interface";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../../components/login/button.component";
import { getCompany, postEditCompany } from "../../../../../_services/company.service";
import { IClientCustomFields } from "../../../../../_services/interface/client.interface";
import LoadingComponent from "../../../../../components/loading";
import { getClient } from "../../../../../_services/client.service";
import SelectCustomField from "../../../../../components/SelectCustomField";
import { TaxlyInput } from "../../../../../components/Input";

interface ICompanyForeignEditForm {
  name: string;
  taxid: string;
  type: string;
  companyCode: string;
  field1Guid?: string;
  field2Guid?: string;
  field3Guid?: string;
}

interface IEditCompanyForeignModalProps {
  company: IClientCompanies|undefined;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  guid_client: string;
  openModal?: boolean;
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditForeignCompanyModal = (props: IEditCompanyForeignModalProps) => {
  const YupCompanyChangeForm = Yup.object().shape({
    name: Yup.string().required('Razão Social'),
    taxid: Yup.string().required('Tax ID'),
    field1Guid: Yup.string(),
    field2Guid: Yup.string(),
    field3Guid: Yup.string(),
  });

  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [isLoadingData, setLoadingData] = useState<boolean>(true);
  const [companyData, setCompanyData] = useState<IGetCompany>();
  const [field1, setField1] = useState<string>();
  const [field2, setField2] = useState<string>();
  const [field3, setField3] = useState<string>();
  const [customFields, setCustomFields] = useState<IClientCustomFields[]>([]);
  const { formState: { errors }, setValue, register, reset, handleSubmit } = useForm<ICompanyForeignEditForm>(resolverForm);
  const toast = useToast();

  useEffect(() => {
    if(props.openModalHook && props.openModal && props.company) {
      onOpenModal(props.company);
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal, props.company]);

  const configCustomFields = async (guid_client: string, companyData: IGetCompany) => {
    const { status, response } = await getClient(guid_client);
    if (status === 200) {
      if('clientCustomFields' in response && response.clientCustomFields) {
        setCustomFields(response.clientCustomFields);

        if(response.clientCustomFields[0]) {
          const fieldValue = companyData.customFields.find((cf) => cf.guid_custom_field === response.clientCustomFields[0].guid_custom_field);
          setField1(fieldValue?.value);
          setValue("field1Guid", response.clientCustomFields[0].guid_custom_field);
        }

        if(response.clientCustomFields[1]) {
          const fieldValue = companyData.customFields.find((cf) => cf.guid_custom_field === response.clientCustomFields[1].guid_custom_field);
          setField2(fieldValue?.value);
          setValue("field2Guid", response.clientCustomFields[1].guid_custom_field);
        }

        if(response.clientCustomFields[2]) {
          const fieldValue = companyData.customFields.find((cf) => cf.guid_custom_field === response.clientCustomFields[2].guid_custom_field);
          setField3(fieldValue?.value);
          setValue("field3Guid", response.clientCustomFields[2].guid_custom_field);
        }
      }
    }
  }

  const setEditInfoData = async (companyData: IGetCompany) => {
    setField1(undefined);
    setField2(undefined);
    setField3(undefined);
    setCustomFields([]);
    setValue("field1Guid", '');
    setValue("field2Guid", '');
    setValue("field3Guid", '');

    setCompanyData(companyData);
    configCustomFields(props.guid_client, companyData);

    setValue("name", companyData.name);
    setValue("taxid", companyData.cnpj);
    setValue('companyCode', companyData.companyCode);
    setValue('type', '2');
  };

  const fetchCompanyData = async (guid_company: string) => {
    const { status, response } = await getCompany(props.guid_client, guid_company, true);

    if (status === 200) {
      setEditInfoData(response as IGetCompany);
    } else {
      toast({
        title: 'Falha ao obter dados de empresa',
        description: 'Houve um problema ao obter os dados da empresa para edição.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  //Check Form Errors
  useEffect(() => {
    if (errors) {
      setAccErrorMessage(Object.values(errors).map((_x) => _x.message) as string[]);
    }
  }, [errors]);

  const handleFormInfo = async (data: ICompanyForeignEditForm) => {
    setSubmitDisabled(true);

    if (companyData) {
      const { status, response } = await postEditCompany(props.guid_client, companyData.guid_company, {
        name: data.name,
        cnpj: data.taxid,
        companyCode: data.companyCode ? data.companyCode : null,
        type: 2,
        isScheduled: false,
        searchCndAfterUpdate: false,
        state: 1,
        city: 1,
        field1: Array.isArray(field1) && field1[0] ? field1[0] : null,
        field2: Array.isArray(field2) && field2[0] ? field2[0] : null,
        field3: Array.isArray(field3) && field3[0] ? field3[0] : null,
        field1Guid: data.field1Guid,
        field2Guid: data.field2Guid,
        field3Guid: data.field3Guid,
        isForeign: true,
      });

      if (status === 200) {
        toast({
          title: 'Empresa Editada',
          description: 'As alterações foram salvas com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: 'Falha ao Editar',
          description: 'Falha ao editar a empresa',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }

      props.flushHook(true);
      onClose();
    }
    
    setSubmitDisabled(false);
  }

  const onOpenModal = async (company: IClientCompanies) => {
    reset({ name: '', taxid: '', type: '2' });
    setAccErrorMessage([]);
    setLoadingData(true);
    await fetchCompanyData(company.guid_company);
    onOpen();
    setLoadingData(false);
  }

  return (
    <>
      {!props.openModalHook && props.company !== undefined ? (
        <Tooltip label={`Editar ${props.company.name}`}>
          <Flex width="26px" height="26px" resize="none">
            <Image src="../icons/edit.svg" onClick={() => {onOpenModal(props.company!)}} _hover={{ cursor: "pointer" }} />
          </Flex>
        </Tooltip>
        ) : null
      }
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
        onClose();
      }} size="2xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF">
          <ModalHeader fontFamily="Poppins-Medium">Edição de Empresa</ModalHeader>
          <ModalCloseButton />
          { !isLoadingData ? (
            <ModalBody fontFamily="Poppins-Medium">
              {accErrorMessage.length > 0 ? (
                <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                  <Text>Por favor, informe os campos obrigatórios:</Text>
                  {accErrorMessage.map((_x, _i) => (
                    <Flex key={_i}>{_x}</Flex>
                  ))}
                </Flex>
              ) : null}

              <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} pb={4}>
                <Flex mt="10px">
                  <TaxlyInput
                    placeholder="Razão Social"
                    label={'Razão Social'}
                    isRequired={true}
                    {...register("name", { required: true })}
                  />
                </Flex>

                <Flex  direction="row" gap={2} flexGrow={1}>
                  <Flex width="40%" direction="column">
                    <TaxlyInput
                      placeholder="Digite para preencher"
                      label={'Tax ID'}
                      isRequired={true}
                      isDisabled={true}
                      color="#171923"
                      {...register("taxid", { required: true })}
                    />
                  </Flex>

                  <Flex width="30%" direction="column">
                    <Flex
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      fontSize="12px"
                    >
                      Tipo
                      <span style={{ color: 'red' }}>*</span>
                    </Flex>

                    <Select 
                      variant="outline" 
                      borderRadius="4px" 
                      border="1px solid #E2E8F0" 
                      fontSize="12px" 
                      _disabled={{ bg: '#E2E8F0', color: '#CBD5E0'}}
                      {...register("type")}
                      isDisabled={true}
                    >
                      <option value="2">Estrangeira</option>
                    </Select>
                  </Flex>

                  <Flex width="30%">
                    <TaxlyInput
                      placeholder="Código da Empresa"
                      label={'Código da Empresa'}
                      {...register("companyCode")}
                    />
                  </Flex>
                </Flex>

                {
                  customFields.length > 0
                  && customFields.some((field) => field.isActive)
                    ? (
                      <Flex mt="10px">
                        <Text
                          color="#171923"
                          fontSize="12px"
                          fontFamily="Poppins-Medium"
                        >
                          Campos personalizados
                        </Text>
                      </Flex>
                    ) : null
                }

                <Flex direction="row" gap={2} flexGrow={1} mt="10px">
                  {
                    customFields[0] && customFields[0].isActive
                      ? (
                        <Flex
                          w={`${100 / customFields.filter((field) => field.isActive).length}%`}
                        >
                          <SelectCustomField
                            setSelectedValue={setField1}
                            selectedValue={field1}
                            customField={customFields[0]}
                            guid_client={props.guid_client}
                            showLabel={true}
                          />
                        </Flex>
                      ) : null
                  }

                  {
                    customFields[1] && customFields[1].isActive
                      ? (
                        <Flex
                          w={`${100 / customFields.filter((field) => field.isActive).length}%`}
                        >
                          <SelectCustomField
                            setSelectedValue={setField2}
                            selectedValue={field2}
                            customField={customFields[1]}
                            guid_client={props.guid_client}
                            showLabel={true}
                          />
                        </Flex>
                      ) : null
                  }

                  {
                    customFields[2] && customFields[2].isActive
                      ? (
                        <Flex
                          w={`${100 / customFields.filter((field) => field.isActive).length}%`}
                        >
                          <SelectCustomField
                            setSelectedValue={setField3}
                            selectedValue={field3}
                            customField={customFields[2]}
                            guid_client={props.guid_client}
                            showLabel={true}
                          />
                        </Flex>
                      ) : null
                  }
                </Flex>

                <Flex alignItems="stretch" direction="row" gap={2} flexGrow={1} mt={4} justifyContent="center">
                  <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>Atualizar dados</FormButton>
                </Flex>
              </Flex>

            </ModalBody>
          )
          : (<LoadingComponent />) }
        </ModalContent>
      </Modal>
    </>
  );
}