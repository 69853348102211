import create from "zustand";
import { IResponse } from "../_services/interface/user.interface";

interface IUserValidateState {
  validate: IResponse | undefined;
  setValidate: (data: IResponse) => void;
  guid_user: string;
  setGuid_user: (guid_user: string) => void;
  guid_company: string[];
  setGuid_company: (guid_company: string[]) => void;
  guid_collaborator: string[];
  setGuid_collaborator: (guid_collaborator: string[]) => void;
};


const useLoginFormStore = create<IUserValidateState>((set) => ({
  guid_user: "",
  validate: undefined,
  setValidate: (data) => 
    set((state) => ({
      ...state,
      validate: {...data},
    })),
  setGuid_user: (guid_user) =>
    set((state) => ({
      ...state,
      guid_user
    })),
    guid_company: [],
    setGuid_company: (guid_company) =>
    set((state) => ({
      ...state,
      guid_company
    })),

    guid_collaborator: [],
    setGuid_collaborator: (guid_collaborator) =>
    set((state) => ({
      ...state,
      guid_collaborator
    }))
}));

export default useLoginFormStore;


