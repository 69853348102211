import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from "react";
import { AddNewTask } from './Create/AddNewTask'

interface IChooseGenericOrAssociatedCardProps {
  isOpen: boolean;
  onClose: () => void;
  guid_client: string;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChooseGenericOrAssociatedCard = (props: IChooseGenericOrAssociatedCardProps) => {
  const { isOpen, onClose } = props;
  const [isCreateIndividualTaskModalOpen, setCreateIndividualTaskModalOpen] = useState(false);
  const [isCreateCertificateTaskModalOpen, setCreateCertificateTaskModalOpen] = useState(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);

  useEffect(() => {
    if(refreshData) {
      props.flushHook(refreshData);
      setRefreshData(false);
    }
  }, [refreshData])

  return (
    <>
      <AddNewTask
        isOpen={isCreateIndividualTaskModalOpen}
        onClose={() => {setCreateIndividualTaskModalOpen(false); onClose();}}
        guid_client={props.guid_client}
        flushHook={setRefreshData}
        type="company"
      />

      <AddNewTask
        isOpen={isCreateCertificateTaskModalOpen}
        onClose={() => {setCreateCertificateTaskModalOpen(false); onClose();}}
        guid_client={props.guid_client}
        flushHook={setRefreshData}
        type="certificate"
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px)'
          alignItems="center"
        />

        <ModalContent
          flexGrow={1}
          flexShrink={1}
          borderLeft="12px solid var(--primary-500)"
          borderRadius={8}
          maxW={756}
        >
          <ModalHeader
            pl={4}
            fontFamily="Poppins-Regular"
            fontSize={18}
            pb={3}
          >
            Nova tarefa
          </ModalHeader>

          <ModalBody p={4}>
            <Text
              fontSize={16}
              fontFamily={'Poppins-Medium'}
              color={'#000000'}
            >
              O que você deseja criar?
            </Text>

            <Flex flexDirection={'row'} gap={'24px'}>
              <Flex
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'space-around'}
                marginTop={'24px'}
                border={'1px solid var(--black-gray-gray-300)'}
                borderRadius={6}
                padding={'16px'}
                width={367}
              >
                <Image
                  width={225}
                  height={188}
                  title={'Criar uma tarefa individual'}
                  src="/icons/createindividualtask.svg"
                  marginBottom={'28px'}
                />

                <Text
                  fontFamily={'Poppins-SemiBold'}
                  fontSize={12}
                  color="var(--primary-500)"
                >
                  Criar uma tarefa individual
                </Text>

                <Text
                  fontFamily={'Poppins-Regular'}
                  fontSize={12}
                  color="var(--black-gray-gray-600)"
                  textAlign={'center'}
                  paddingTop={'8px'}
                >
                  Aqui você pode criar tarefas individuais <br />
                  para acompanhar no kanban
                </Text>

                  <Button
                    bgColor="#4B4EFF"
                    color="white"
                    onClick={() => {
                      setCreateIndividualTaskModalOpen(true);
                      onClose();
                    }}
                    _hover={{ bg: '#282be0' }}
                    style={{
                      fontFamily: 'Poppins-Medium',
                      fontSize: 12,
                      color: 'var(--colors-white-white-50)',
                      fontWeight: 300,
                      marginTop: '28px',
                      height: '30px',
                    }}
                  >
                    Criar tarefa individual
                  </Button>
              </Flex>

              <Flex
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'space-around'}
                marginTop={'24px'}
                border={'1px solid var(--black-gray-gray-300)'}
                borderRadius={6}
                padding={'16px'}
                width={367}
              >
                <Image
                  width={225}
                  height={188}
                  title={'Criar uma tarefa individual'}
                  src="/icons/createassociatedtask.svg"
                  marginBottom={'28px'}
                />

                <Text
                  fontFamily={'Poppins-SemiBold'}
                  fontSize={12}
                  color="var(--primary-500)"
                >
                  Criar uma tarefa vinculada a certidão
                </Text>

                <Text
                  fontFamily={'Poppins-Regular'}
                  fontSize={12}
                  color="var(--black-gray-gray-600)"
                  textAlign={'center'}
                  paddingTop={'8px'}
                >
                  Crie tarefas para acompanhar o <br/>
                  status de uma ou mais certidões
                </Text>

                <Button
                  bgColor="#4B4EFF"
                  color="white"
                  onClick={() => {
                    setCreateCertificateTaskModalOpen(true);
                    onClose();
                  }}
                  _hover={{ bg: '#282be0' }}
                  style={{
                    fontFamily: 'Poppins-Medium',
                    fontSize: 12,
                    color: 'var(--colors-white-white-50)',
                    fontWeight: 300,
                    marginTop: '28px',
                    height: '30px',
                  }}
                >
                  Criar tarefa vinculada a certidão
                </Button>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
