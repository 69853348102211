import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useParams } from "react-router-dom";
import { AppInput } from "../../../components/AppInput";
import { GlobalContainer } from "../../../components/GlobalContainer";
import { FormButton } from "../../../components/login/button.component";
import { IInvitedUserInfoResponse } from "../../../_services/interface/user.interface";
import { getBasicUserInfo, postCompleteRegistration } from "../../../_services/user.service";
import { FieldErrorsImpl, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormError, SimpleFormError } from "../../../components/login/formError.component";

interface ICompleteRegistrationInputs {
  name: string;
  password: string;
  confirmPassword: string;
}

/* interface ICompleteRegistrationProps {
  guid_user: string;
} */

export const CompleteRegistration = () => {

  const YupCompleteRegistrationForm = Yup.object().shape({
    name: Yup.string().required('Informe seu nome'),
    password: Yup.string().required('Informe o Password'),
    confirmPassword: Yup.string().required('Confirme o password'),
  });

  //Generate o resolver
  const resolverForm = { resolver: yupResolver(YupCompleteRegistrationForm) };

  const navigation = useNavigate();
  const { guid_user } = useParams();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [userEmail, setUserEmail] = useState<string>();
  const [clientName, setClientName] = useState<string>();
  const [isDataLoaded, setDataLoaded] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<FieldErrorsImpl>();
  const [captchaChallenge, setCaptchaChallenge] = useState<boolean>(false);
  const [smplErrorMessage, setSmplErrorMessage] = useState<string>();
  const [allFieldsFilled, setFieldsState] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [passwordStrength, setPasswordStrength] = useState<string>('#e8e8e8');
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

  const { formState: { errors }, handleSubmit, register, reset, watch } = useForm<ICompleteRegistrationInputs>(resolverForm);

  const getBasicInformation = async () => {
    const { status, response } = await getBasicUserInfo(guid_user as string);
    if (status === 200) {
      setUserEmail((response as IInvitedUserInfoResponse).email);
      setClientName((response as IInvitedUserInfoResponse).client);
    } else {
      //If fails, the system will send this user back to the navigation
      navigation('/');
    }
  }

  getBasicInformation();

  useEffect(() => {
    if (clientName && userEmail) {
      setDataLoaded(true);
    }
  }, [clientName, userEmail]);

  useEffect(() => {
    if (errors) {
      setErrorMessage(errors as any);
    }
  }, [errors]);

  useEffect(() => {
    watch((value) => {
      if (Object.values(value).filter(_x => _x !== '').length === 3) {
        setFieldsState(true);
      } else {
        setFieldsState(false);
      }

      if (value.password) {
        if(strongRegex.test(value.password)) {
          setPasswordStrength("#0F9D58");
        } else if(mediumRegex.test(value.password)) {
          setPasswordStrength("#F4B400");
        } else {
          setPasswordStrength("#DB4437");
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (allFieldsFilled && captchaChallenge) {
      //All fields are filled and captcha challenge completed
      //so the system will reveal the Button
      setSubmitDisabled(false);
    } else {
      //If nothing meets the system will keep the button disabled
      setSubmitDisabled(true);
    }
  }, [allFieldsFilled, captchaChallenge]);

  function onChangeCaptcha(value: any) {
    if (value) {
      setCaptchaChallenge(true);
    } else {
      setCaptchaChallenge(false);
    }
  }

  const handleFormInfo = async (data: ICompleteRegistrationInputs) => {
    //Disable everything
    setSubmitDisabled(true);
    //Check if the password is the same
    if (data.password === data.confirmPassword) {
      //Complete the registration
      const { status } = await postCompleteRegistration(guid_user as string, data);

      if (status === 200) {
        //Registration was ok, so the system will send this user to the login page
        navigation('/');
      } else {
        //Something happened and failed the request
        setSmplErrorMessage('Falha ao realizar o registro. Tente novamente mais tarde');
      }
    } else {
      reset({ password: '', confirmPassword: '' });
      setSmplErrorMessage('As senhas precisam ser iguais para prosseguir com o cadastro');
    }
    setSubmitDisabled(false);
  }

  return (
    <GlobalContainer translate="no" display="flex">
      <Flex id="teste" position="absolute" left="0" top="0" right="0" bottom="0">
        <Flex w="50%" backgroundImage="../images/loginImage-cropped.png" backgroundSize="contain" backgroundRepeat="no-repeat" backgroundAttachment="fixed" backgroundPosition="-25px center" justifyContent="center" alignItems="center">
          <Flex fontFamily="Comfortaa-Bold">
            <Text color="white" m="0" fontSize="96px">
              taxly
            </Text>
          </Flex>
        </Flex>
        <Flex w="50%" justifyContent="center" backgroundColor="white" alignItems="center">
        <Flex fontFamily="Poppins-Medium" display="flex" alignItems="center" mt="0px" w={396}>
          <Flex direction="column" w="100%">
            <Text fontSize="32px" textAlign="center">Complete o Cadastro</Text>
            <Flex alignItems="center" fontSize="12px" marginTop="10px" justifyContent="center">
              <Text>
                Você está vendo esta tela porque recebeu um convite em seu email <strong>{userEmail} </strong> 
                da empresa <strong>{clientName}</strong>. Ao terminar o seu cadastro você será redirecionado 
                para a tela de login.
              </Text>
            </Flex>
            <Box marginTop={18} opacity={isDataLoaded ? 1.0 : 0.1}>
              <form>
                <Flex direction="column" alignItems="stretch">

                  {errorMessage ? (<FormError message={errorMessage} />) : null}
                  {smplErrorMessage ? (<SimpleFormError msg={smplErrorMessage} />) : null}

                  <Flex direction="column" gap={2}>
                    <AppInput placeholder="Nome" type="text" {...register("name", { required: true })} />
                  </Flex>
                  <Flex flexDirection="row" gap={2} mt={2} justifyContent="space-between">
                    <AppInput placeholder="Senha" type="password" {...register("password", { required: true })} />
                    <AppInput placeholder="Confirmação de Senha" type="password" {...register("confirmPassword", { required: true })} />
                  </Flex>
                  <Flex flexDirection="column" gap={2} justifyContent="space-between" mt={2}>
                    <Flex borderRadius={5} bg={passwordStrength} h={2}></Flex>
                    <Text fontSize={11} opacity={0.5}>A senha precisa ter pelo menos 6 caracteres dentre eles letras maísculas, minusculas, números e simbolos.</Text>
                  </Flex>
                  <Flex direction="row" justifyContent="center" mt="18" mb="18">
                    <ReCAPTCHA ref={captchaRef} onChange={onChangeCaptcha} sitekey="6LelrG4iAAAAAGZUt_aPuZp1cInj7M1f_pGD98fM" />
                  </Flex>
                  <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>Finalizar Cadastro</FormButton>
                </Flex> 
              </form>
            </Box>
          </Flex>
        </Flex>
        </Flex>
      </Flex>
    </GlobalContainer>
  );
}