import { useToast } from "@chakra-ui/react";
import { Alert } from "../Alert/Alert";

interface AlertProps {
  isClosable?: boolean;
  closableFunc?: () => void;
  duration?: number;
  descriptionHtml: string;
  clicable?: { action: () => void; text: string };
  icon?: boolean;
  title?: boolean;
  status: "warning" | "success" | "error" | "info";
  variant: "top-accent" | "solid" | "subtle" | "alert" | "left-accent";
  type: "two" | "three" | "one";
  className: any;
}

const AlertToast = ({ 
  isClosable,
  closableFunc,
  duration,
  descriptionHtml,
  clicable,
  icon = true,
  title = true,
  status,
  variant,
  type,
  className}: AlertProps) => {
  const toast = useToast();

  const showToast = () => {
    toast.closeAll();
    toast({
      isClosable: !!isClosable,
      duration: duration ? duration : null,
      status: status,
      render: ({ onClose }) => (
        <>
          <Alert
            isClosable={isClosable}
            closableFunc={() => {
              onClose();
              if (closableFunc !== undefined) {
                closableFunc();
              }
            }}
            descriptionHtml={descriptionHtml}
            clicable={clicable}
            icon={true}
            title={true}
            status={status}
            variant={variant}
            type={type}
            className={className}
          />
        </>
      )
    });
  }

  return (<>
    {showToast()}
  </>);
};

export default AlertToast;
