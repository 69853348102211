import { useState, useEffect } from "react";
import { EFrequencyType } from "../../../../../_services/enum/irs.enum";
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Button, MenuList, MenuItem, Menu, InputGroup, MenuButton, Text } from "@chakra-ui/react";
import { FormButton } from "../../../../../components/login/button.component";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { DashInput } from "../../../Users/components/DashInput";

interface FrequencyTypeOptions {
  labelSingle: string;
  labelPlural: string;
  value: EFrequencyType;
}

interface DatePickerRecurrencyCustomProps {
  callback: (type: EFrequencyType, unit: number) => void;
  frequencyType: EFrequencyType | null;
  frequencyUnit: number | null;
  showSelected: boolean;
}

const DatePickerRecurrencyCustom = (props: DatePickerRecurrencyCustomProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [frequencyType, setFrequencyType] = useState<EFrequencyType | null>(props.frequencyType ?? null);
  const [frequencyUnit, setFrequencyUnit] = useState<number | null>(props.frequencyUnit ?? 1);

  useEffect(() => {
    setFrequencyType(props.frequencyType ?? null);
    setFrequencyUnit(props.frequencyUnit ?? 1);
  }, [isOpen, props.frequencyType, props.frequencyUnit]);

  const frequencyTypeOptions: FrequencyTypeOptions[] = [
    { labelSingle: "Dia", labelPlural: "Dias", value: EFrequencyType.DAY },
    { labelSingle: "Semana", labelPlural: "Semanas", value: EFrequencyType.WEEK },
    { labelSingle: "Mês", labelPlural: "Mêses", value: EFrequencyType.MONTH },
    { labelSingle: "Ano", labelPlural: "Anos", value: EFrequencyType.YEAR },
  ];

  const confirmModal = async () => {
    if (frequencyType && frequencyUnit) {
      props.callback(frequencyType, frequencyUnit);
      closeModal();
    }
  };

  const getFrequencyLabel = (type: EFrequencyType, unit: number) => {
    if (unit > 1) {
      return `${frequencyTypeOptions.find((option) => option.value === type)?.labelPlural}`;
    }

    return frequencyTypeOptions.find((option) => option.value === type)?.labelSingle;
  }

  const closeModal = () => {
    setFrequencyType(null);
    setFrequencyUnit(null);
    onClose();
  }

  return (
    <>
      <Flex
        onClick={onOpen}
        fontFamily="Poppins-Medium"
        fontSize="12px"
        cursor="pointer"
        height={'42px'}
        width="100%"
        alignItems={'center'}
        color={props.showSelected ? '#4B4EFF' : '#171923'}
      >
        Personalizado...
      </Flex>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal} size="xs" isCentered>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-Medium">
            <Text
              color="#171923"
              fontSize="14px"
              fontFamily="Poppins-Medium"
            >
              Repetir
            </Text>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Text
              color="#171923"
              fontSize="14px"
              mb="15px"
            >
              A cada
            </Text>

            <Flex justifyContent="center" flexDirection="column" gap={5}>

              <Flex justifyContent={'space-between'} p={'0 8px'}>
                <Flex w="49%">
                  <InputGroup zIndex={999}>
                    <DashInput
                      type="number"
                      borderTop="1px solid #CBD5E0"
                      borderBottom="1px solid #CBD5E0"
                      borderRight="1px solid #CBD5E0"
                      borderLeft="1px solid #CBD5E0"
                      p="6px"
                      backgroundColor="white"
                      borderRadius={6}
                      onChange={(e) => setFrequencyUnit(parseInt(e.target.value))}
                      value={frequencyUnit?.toString()}
                    />
                  </InputGroup>
                </Flex>
                
                <Flex w="49%">
                  <InputGroup zIndex={999}>
                    <Menu>
                      <MenuButton
                        as={Button}
                        display="flex"
                        alignItems="center"
                        border="1px solid #CACACA"
                        fontSize="12px"
                        width="auto"
                        w={"100%"}
                        borderRadius={6}
                        backgroundColor="white"
                        p="4px 8px"
                        fontWeight={500}
                        justifyContent="flex-start"
                        height="40px"
                        _hover={{ cursor: "pointer" }}
                        rightIcon={<ChevronDownIcon />}
                      >
                        <Flex alignItems="center">
                          {frequencyUnit && frequencyType ? getFrequencyLabel(frequencyType, frequencyUnit) : 'Selecione'}
                        </Flex>
                      </MenuButton>

                      <MenuList>
                        {frequencyTypeOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            onClick={() => setFrequencyType(option.value)}
                            display="flex"
                            alignItems="center"
                          >
                            {getFrequencyLabel(option.value, frequencyUnit ?? 0)}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </InputGroup>
                </Flex>

              </Flex>

              <Flex justifyContent="space-between" alignItems="center">
                <Button
                  bgColor="white"
                  color="#171923"
                  fontSize="14px"
                  fontWeight={500}
                  fontFamily="Poppins-medium"
                  _hover={{ bg: 'white', textDecoration: 'underline' }}
                  onClick={closeModal}
                >
                  Fechar
                </Button>

                <FormButton
                  onClick={confirmModal}
                  disabled={frequencyType === null || frequencyUnit === null}
                  width="50%"
                >
                  Confirmar
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DatePickerRecurrencyCustom;
