import { IColumn, IData, IDataMeta, ITableMetaData } from "../../../../../../components/Table/table.interface";
import './../../../../../../styles/components/AccordionItem.css';
import { useEffect, useState } from "react";
import { IBulkUpdateCompanyItem } from "../../../../../../_services/interface/company.interface";
import { formatCEP, formatCnpj } from "../../util/cnpj";
import { normalizeString, stateRegionForTag } from "../../../../../../components/Table/utils";
import { Datatables } from "../../../../../../components/Table/Datatables";
import { allStates } from "../../../../../../_services/region/states.service";
import { ICompanyBulk } from "./company.interface";
import { allCities } from "../../../../../../_services/region/cities.service";
import { Flex } from "@chakra-ui/react";



interface IProps {
  items: IBulkUpdateCompanyItem[];
  cnpjEditable?: boolean;
  allFieldsEditable?: boolean;
  setCompanies?: React.Dispatch<React.SetStateAction<ICompanyBulk[]>>;
}

export const Step3ConfirmationTable = (props: IProps) => {
  const [companies, setCompanies] = useState<ICompanyBulk[]>([]);
  const [companiesList, setCompaniesList] = useState<IData<any>|null>(null);
  const [companyMetaData, setCompanyMetaData] = useState<ITableMetaData|undefined>();

  const columns = [
    {
      title: 'CNPJ',
      name: 'cnpj',
      sortable: false,
      align: 'center',
      width: '120px',
      formatter: (value: string) => {
        return formatCnpj(value || '');        
      },
      alwaysField: ((props.allFieldsEditable && props.cnpjEditable) || props.cnpjEditable),
      ...(((props.allFieldsEditable && props.cnpjEditable) || props.cnpjEditable) && {
        fieldDataToAdditionalRow: {
          type: 'text',
          placeholder: 'CNPJ',
          defaultValue: '',
          className: 'form-control',
          required: true,
          formatter: (value: string) => {
            return formatCnpj(value || '');
          },
        },
      })
    },
    {
      title: 'Empresa',
      name: 'name',
      sortable: false,
      align: 'center',
      width: '200px',
      alwaysField: props.allFieldsEditable,
      ...(props.allFieldsEditable && {
        fieldDataToAdditionalRow: {
          type: 'text',
          placeholder: 'Nome da empresa',
          defaultValue: '',
          className: 'form-control',
          required: true,
        },
      })
    },
    {
      title: 'Tipo',
      name: 'type',
      sortable: false,
      align: 'center',
      width: '120px',
      tags: [
        {
          text: 'Matriz',
          value: 0,
          color: '#ECFCCB',
          textColor: '#365314'
        },
        {
          text: 'Filial',
          value: 1,
          color: '#C4F1F9',
          textColor: '#065666'
        },
      ],
      alwaysField: props.allFieldsEditable,
      ...(props.allFieldsEditable && {
        fieldDataToAdditionalRow: {
          type: 'select',
          placeholder: 'Matriz/Filial',
          defaultValue: '',
          className: 'form-control',
          required: true,
          options: [
            {
              label: 'Matriz',
              value: '0'
            },
            {
              label: 'Filial',
              value: '1'
            },
          ]
        },
      })
    },
    {
      title: 'Inscrição estadual',
      name: 'stateReg',
      sortable: false,
      align: 'center',
      alwaysField: props.allFieldsEditable,
      ...(props.allFieldsEditable && {
        fieldDataToAdditionalRow: {
          type: 'number',
          placeholder: 'Inscrição estadual',
          defaultValue: '',
          className: 'form-control',
          required: true,
        },
      })
    },
    {
      title: 'CEP',
      name: 'cep',
      sortable: false,
      align: 'center',
      width: '120px',
      alwaysField: props.allFieldsEditable,
      ...(props.allFieldsEditable && {
        fieldDataToAdditionalRow: {
          type: 'text',
          placeholder: 'CEP',
          defaultValue: '',
          className: 'form-control',
          required: true,
          formatter: (value: string) => {
            return formatCEP(value);
          },
        },
      })
    },
    {
      title: 'Estado',
      name: 'state',
      sortable: false,
      align: 'center',
      width: '170px',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state'
      },
      alwaysField: props.allFieldsEditable,
      ...(props.allFieldsEditable && {
        fieldDataToAdditionalRow: {
          type: 'select',
          placeholder: 'Estado',
          defaultValue: '',
          className: 'form-control',
          required: true,
          options: allStates
        },
      })
    },
    {
      title: 'Cidade',
      name: 'city',
      sortable: false,
      align: 'center',
      width: '180px',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ],
      alwaysField: props.allFieldsEditable,
      ...(props.allFieldsEditable && {
        fieldDataToAdditionalRow: {
          type: 'select',
          placeholder: 'Cidade',
          defaultValue: '',
          className: 'form-control',
          required: true,
          options: (row: any) => {
            return allCities.filter((city) => city.id_state === Number(row.state));
          }
        },
      })
    }
  ] as IColumn[];

  const getStateInitials = (id: number) => {
    const state = allStates.find((state) => state.value === id);
    return state?.initials;
  }

  const getCityName = (id: number) => {
    const city = allCities.find((city) => city.value === id);
    return city?.label;
  }

  const getStateIdByInitials = (initials: string): number|undefined => {
    const state = allStates.find((state) => state.initials.toUpperCase() === initials.toUpperCase());
    return state?.value;
  }

  const getCityIdByName = (name: string): number|undefined => {
    const city = allCities.find((city) => normalizeString(city.label) === normalizeString(name));
    return city?.value;
  }

  useEffect(() => {
    if (props.items.length > 0) {
      const companies = props.items.map((item) => {

        if(item.company && Object.keys(item.company).length > 0) {
          return {
            cnpj: item.company.cnpj,
            name: item.company.name,
            type: item.company.type,
            stateReg: item.company.stateReg,
            state: getStateInitials(item.company.id_state),
            city: getCityName(item.company.id_city),
            cep: item.company.cep,
            guid_bulk_item_company: item.guid_bulk_item_company,
          }
        } else {
          const data = JSON.parse(item.data);

          let type = data.tipo || data.type;

          if(
            data.tipo !== null 
            && data.tipo !== undefined 
            && data.tipo.toUpperCase() === 'MATRIZ'
          ) {
            type = 0;
          }

          if(
            data.tipo !== null 
            && data.tipo !== undefined 
            && data.tipo.toUpperCase() === 'FILIAL'
          ) {
            type = 1;
          }

          let state = undefined;
          if(data.state) {
            state = typeof data.state === 'number' ? data.state : getStateIdByInitials(data.state);
          }

          if(data.uf) {
            state = typeof data.uf === 'number' ? data.uf : getStateIdByInitials(data.uf);
          }

          let city = undefined;
          if(data.city) {
            city = typeof data.city === 'number' ? data.city : getCityIdByName(data.city);
          }

          if(data.municipio) {
            city = typeof data.municipio === 'number' ? data.municipio : getCityIdByName(data.municipio);
          }

          let company: ICompanyBulk = {
            cnpj: data.cnpj,
            name: data.name,
            type: type,
            stateReg: '',
            state: state,
            city: city,
            cep: data.cep,
            guid_bulk_item_company: item.guid_bulk_item_company,
          };

          return company;
        }
      });

      setCompanies(companies);
    }
  }, [props.items]);

  useEffect(() => {
    if (companies) {
      const meta: IDataMeta = {
        currentPage: 1,
        isFirstPage: true,
        isLastPage: true,
        nextPage: null,
        pageCount: 1,
        previousPage: null,
        totalCount: companies.length
      }

      setCompaniesList({
        data: companies,
        meta
      });
    }
  }, [companies]);

  useEffect(() => {
    if(props.setCompanies && companiesList && companiesList.data) {
      const companies = companiesList?.data.map((comp) => {
        return {
          cnpj: comp.cnpj,
          name: comp.name,
          type: comp.type,
          stateReg: comp.stateReg,
          state: comp.state,
          city: comp.city,
          cep: comp.cep,
          guid_bulk_item_company: comp.guid_bulk_item_company,
        }
      }) 

      props.setCompanies(companies);
    }
  }, [companiesList])

  return (
    <>
      <Flex zIndex={9999999}>
        <Datatables
          name="Empresas"
          columns={columns}
          metaDataHook={setCompanyMetaData}
          data={companiesList}
          dataHook={setCompaniesList}
          isLoading={false}
          showTotalRows={false}
          heigth="400px"
          rowByPage={false}
        />
      </Flex>
    </>
  );
}