import React, { useState, useEffect } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FormButton } from "../../../components/login/button.component";
import { TLocationHandler } from "../_types/location.type";
import { patchResendMailId, postUserValidation } from "../../../_services/user.service";
import { IResendValidationResponse, IValidationResponse } from "../../../_services/interface/user.interface";
import { SimpleFormError, SimpleFormValid } from "../../../components/login/formError.component";
import { IErrorResponse } from "../../../_services/interface/IDefaults";
import { LoginForm } from "../login/LoginForm";
import { ValidationInput } from "./components/ValidationInput";


interface ValidationProps {
  locationHandler: TLocationHandler;
  email: string;
  userid: string;
}

export const ValidationForm = (props: ValidationProps) => {
  
  const [counter, setCounter] = React.useState(100);
  const [isResendDisabled, setResendDisabled] = useState<boolean>(true);
  const [isValidationBtnDisabled, setValidationBtn] = useState<boolean>(true);
  const [validationCode, setValidationCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [okMessage, setOkMessage] = useState("");

  //Timer to resend the information
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResendDisabled(false);
    }
  }, [counter]);

  const handleCode = (code: string) => {
    setValidationCode(code);
    if (code.length === 6) {
      setValidationBtn(false);
    } else {
      setValidationBtn(true);
    }
  }

  /**
   * Resend the validation information
   */
  const resendClick = async () => {
    setCounter(100);
    setResendDisabled(true);
    setOkMessage('');
    setErrorMessage('');

    const {status, response} = await patchResendMailId(props.userid);

    if (status === 200) {
      if ((response as IResendValidationResponse).mailsent) {
        setOkMessage('Código reenviado com sucesso');
      } else {
        setErrorMessage('Falha ao reenviar o código.');  
      }
    } else {
      setErrorMessage('Falha ao reenviar o código.');
    }
  };

  const sendValidation = async () => {
    setValidationBtn(true);
    setOkMessage('');
    setErrorMessage('');
    //Here the system dispatch the code
    const { status, response } = await postUserValidation(props.userid, {
      code: validationCode,
    });

    if (status === 200) {
      //Check if the user was validated or not
      if ((response as IValidationResponse).isValidated) {
        //Everything ok, so the system will send the user to the login page
        props.locationHandler(<LoginForm locationHandler={props.locationHandler} />)
      } else {
        setErrorMessage('Código inválido ou usuário já validado');
      }
    } else {
      const err = (response as IErrorResponse).message;

      switch (err) {
        case 'VALIDATION_FAILED':
          setErrorMessage('Usuário inválido ou não possui acesso a essa organização');
          break;
        case 'VALIDATION_ALREADY_ACTIVE':
          setErrorMessage('Usuário já se encontra validado.');
          break;
        case 'VALIDATION_MAIL_FAILED':
          setErrorMessage('Falha ao gerar um novo código, não foi possível enviar o email, Tente reenviar novamente.');
          break;
        case 'VALIDATION_CODE_EXPIRED':
          setErrorMessage('Código enviado foi expirado, o sistema irá enviar um novo código para o seu email.');
          break;
        default:
          break;
      }
    }

    setValidationBtn(false);
  }

  return (
    <Flex fontFamily="Poppins-Medium" alignItems="center" mt="0px" w={396}>
      <Flex direction="column" w="100%">
        <Text fontSize="32px" textAlign="center">Validação</Text>
        <Box mt={12}>
          <Flex m="0px"direction="column"justifyContent="center">
            <Text fontSize="12px" textAlign="center">Como medida de segurança para validar o seu cadastro, enviamos para o seu email <strong>{props.email}</strong> um código de 6 dígitos. (O email pode demorar alguns minutos para chegar até sua caixa)</Text>
            <Text fontSize="12px" textAlign="center" mt={2}>Escreva o código abaixo para validar sua conta.</Text>
          </Flex>
          {errorMessage ? (<SimpleFormError msg={errorMessage} />) : null}
          {okMessage ? (<SimpleFormValid msg={okMessage} />) : null}
          <form>
            <Flex alignItems="stretch" justifyContent="center" direction="column" mt={12}>
              <ValidationInput digits={6} onChange={handleCode} />
              <Flex direction="column" gap={2} mt={12}>
                <FormButton disabled={isValidationBtnDisabled} onClick={sendValidation}>Validar</FormButton>
                <FormButton disabled={isResendDisabled} onClick={resendClick}>Reenviar Código</FormButton>

                <Flex alignItems="center" justifyContent="center">
                  <Text fontSize="12px" opacity={0.5}>
                    Você pode reenviar um novo código em {counter} segundos
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};
