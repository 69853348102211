import queryString from "query-string";
import { ApiAuth, ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import {
  IFilterListBulkUpdate,
  IGetBulkUpdateResponse,
} from "./interface/bulk-update.interface";
import { CancelToken } from "axios";

export const getBulkUpdateByGuidBulkUpdate = async (
  guid_client: string,
  guid_bulk_update: string,
  filters: IFilterListBulkUpdate,
): Promise<TPremiseResponse<IGetBulkUpdateResponse>> => {
  const queryStringParams = queryString.stringify(filters);
  return await new ApiCall(
    `/bulk-update/${guid_client}/id/${guid_bulk_update}?${queryStringParams}`,
    ApiAuth()
  ).get<IGetBulkUpdateResponse>();
};

export const getBulkUpdateByBulkType = async (
  guid_client: string,
  type: string,
  filters: IFilterListBulkUpdate,
  cancelToken?: CancelToken
): Promise<TPremiseResponse<IGetBulkUpdateResponse>> => {
  const queryStringParams = queryString.stringify(filters);
  return await new ApiCall(
    `/bulk-update/${guid_client}/type/${type}?${queryStringParams}`,
    ApiAuth(),
    cancelToken
  ).get<any>();
};


export const postFinishBulkUpdate = async (
  guid_client: string,
  type: string,
  guid_bulk_update: string,
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/bulk-update/${guid_client}/finish/${type}/${guid_bulk_update}`,
    ApiAuth(),
  ).post<any, any>();
};
