import { ModalHeader, ModalCloseButton, Text, ModalBody, Image, Flex } from "@chakra-ui/react";

interface IProps {
  title: string;
  h1: string;
  h2: string
  image: string;
}

export const StepLoading = (props: IProps) => {
  return (
    <>
      <ModalHeader fontFamily="Poppins-SemiBold">
        <Text fontSize="21px">{props.title}</Text>
      </ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <Flex
          direction="column"
          align="center"
          justify="center"
          m="50px 0"
        >
          <Image
            alt="Loading"
            w="280px" 
            src={props.image}
          />
        </Flex>

        <Flex
          direction="column"
          align="center"
          justify="center"
          mt="20px"
          mb="50px"
        >
          <Text
            color="#171923"
            fontFamily="Poppins-Medium"
            fontSize="14px"
          >
            {props.h1}
          </Text>

          <Text
            color="#718096"
            fontFamily="Poppins-Medium"
            fontSize="12px"
          >
            {props.h2}
          </Text>
        </Flex>
      </ModalBody>
    </>
  );
}