interface IOption {
  label: string;
  value: number;
  initials: string;
};

export const allStates: IOption[] = [
  {
    value: 1,
    label: 'Acre - AC',
    initials: 'AC'
  },
  {
    value: 2,
    label: 'Alagoas - AL',
    initials: 'AL'
  },
  {
    value: 3,
    label: 'Amazonas - AM',
    initials: 'AM'
  },
  {
    value: 4,
    label: 'Amapá - AP',
    initials: 'AP'
  },
  {
    value: 5,
    label: 'Bahia - BA',
    initials: 'BA'
  },
  {
    value: 6,
    label: 'Ceará - CE',
    initials: 'CE'
  },
  {
    value: 7,
    label: 'Distrito Federal - DF',
    initials: 'DF'
  },
  {
    value: 8,
    label: 'Espírito Santo - ES',
    initials: 'ES'
  },
  {
    value: 9,
    label: 'Goiás - GO',
    initials: 'GO'
  },
  {
    value: 10,
    label: 'Maranhão - MA',
    initials: 'MA'
  },
  {
    value: 11,
    label: 'Minas Gerais - MG',
    initials: 'MG'
  },
  {
    value: 12,
    label: 'Mato Grosso do Sul - MS',
    initials: 'MS'
  },
  {
    value: 13,
    label: 'Mato Grosso - MT',
    initials: 'MT'
  },
  {
    value: 14,
    label: 'Pará - PA',
    initials: 'PA'
  },
  {
    value: 15,
    label: 'Paraíba - PB',
    initials: 'PB'
  },
  {
    value: 16,
    label: 'Pernambuco - PE',
    initials: 'PE'
  },
  {
    value: 17,
    label: 'Piauí - PI',
    initials: 'PI'
  },
  {
    value: 18,
    label: 'Paraná - PR',
    initials: 'PR'
  },
  {
    value: 19,
    label: 'Rio de Janeiro - RJ',
    initials: 'RJ'
  },
  {
    value: 20,
    label: 'Rio Grande do Norte - RN',
    initials: 'RN'
  },
  {
    value: 21,
    label: 'Rondônia - RO',
    initials: 'RO'
  },
  {
    value: 22,
    label: 'Roraima - RR',
    initials: 'RR'
  },
  {
    value: 23,
    label: 'Rio Grande do Sul - RS',
    initials: 'RS'
  },
  {
    value: 24,
    label: 'Santa Catarina - SC',
    initials: 'SC'
  },
  {
    value: 25,
    label: 'Sergipe - SE',
    initials: 'SE'
  },
  {
    value: 26,
    label: 'São Paulo - SP',
    initials: 'SP'
  },
  {
    value: 27,
    label: 'Tocantins - TO',
    initials: 'TO'
  },
];