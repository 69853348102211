import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { FieldErrorsImpl, useForm } from "react-hook-form";
import { AppInput } from "../../../components/AppInput";
import { TLocationHandler } from "../_types/location.type";
import { FormButton } from "../../../components/login/button.component";
import { FormError, SimpleFormError } from "../../../components/login/formError.component";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postResetPassword } from "../../../_services/auth.service";
import { IResetPasswordResponse } from "../../../_services/interface/login.interface";

interface IPasswordResetForm {
  locationHandler: TLocationHandler;
  otp: string;
  email: string;
}

export const PasswordResetForm = (props: IPasswordResetForm) => {

  const YupLoginForm = Yup.object().shape({
    newPassword: Yup.string().required('Repita a senha no campo apropriado'),
    password: Yup.string().required('Preencha o campo de senha'),
  });

  const resolverForm = { resolver: yupResolver(YupLoginForm) };
  const [errorMessage, setErrorMessage] = useState<FieldErrorsImpl>();
  const { handleSubmit, register, watch, formState: { errors } } = useForm(resolverForm);
  const [smplErrorMessage, setSmplErrorMessage] = useState<string>();
  const [passwordStrength, setPasswordStrength] = useState<string>('#e8e8e8');
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

  useEffect(() => {
    if (errors) {
      setErrorMessage(errors as any);
    }
  }, [errors]);

  useEffect(() => {
    watch((value) => {
      if (value.password) {
        if(strongRegex.test(value.password)) {
          setPasswordStrength("#0F9D58");
        } else if(mediumRegex.test(value.password)) {
          setPasswordStrength("#F4B400");
        } else {
          setPasswordStrength("#DB4437");
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const handleFormInfo = async (data: any) => {
    if (data.password === data.newPassword) {
      const {status, response} = await postResetPassword({
        otp: props.otp,
        email: props.email,
        password: data.password
      });

      if (status === 200) {
        //Send the person to the login page again
        if ((response as IResetPasswordResponse).passwordChanged) {
          props.locationHandler(undefined);
        } else {
          setSmplErrorMessage('Algum erro ocorreu ao tentar validar o password, por favor, tente novamente.');  
        }
      } else {
        setSmplErrorMessage('Algum erro ocorreu ao tentar validar o password, por favor, tente novamente.');
      }
    } else {
      setSmplErrorMessage('As senhas não são iguais');
    }
  }

  return (
    <Flex display="flex" fontFamily="Poppins-Medium" alignItems="center" w={396}>
      <Flex direction="column" w="100%" mt="48px">
        <Text fontSize="32px" textAlign="center">Nova Senha</Text>
        <Flex alignSelf="center" w="380px" mt={10}>
          <Text fontSize="12px" textAlign="center">Informe a sua nova senha</Text>
        </Flex>
        <Box mt={10}>
          {errorMessage ? (<FormError message={errorMessage} />) : null}
          {smplErrorMessage ? (<SimpleFormError msg={smplErrorMessage} />) : null}
          <form>
            <Flex direction="column" gap={2} alignItems="stretch">
              <AppInput placeholder="Nova Senha" type="password" {...register("password", { required: true })} />
              <AppInput placeholder="Digite a Senha Novamente" type="password" {...register("newPassword", { required: true })} />
              <Flex flexDirection="column" gap={2} justifyContent="space-between" mb={10}>
                <Flex borderRadius={5} bg={passwordStrength} h={2}></Flex>
                <Text fontSize={11} opacity={0.5}>A senha precisa ter pelo menos 6 caracteres dentre eles letras maísculas, minusculas, números e simbolos.</Text>
              </Flex>
              <FormButton onClick={handleSubmit(handleFormInfo)}>Alterar Senha</FormButton>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};
