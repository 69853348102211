import { getClientCompanies } from "../_services/company.service";
import { IClientCompanies } from "../_services/interface/company.interface";

export const loadCompanies = async (guid_client: string): Promise<IClientCompanies[]> => {
  const { status, response } = await getClientCompanies(guid_client);
  if (status === 200) {
    return response as IClientCompanies[];
  }

  return [] as IClientCompanies[];
}