import { Flex, Text,   } from "@chakra-ui/react";
import MiniLoading from "../../../../components/miniLoading";
import { IResponse } from "../../../../_services/interface/user.interface";
import { ICardCompany, ICardList } from "../interfaces/ICardList";
import { KanbanCard } from "./KanbanCard";
import React, { useEffect, useState } from 'react';
import { RectShape } from "react-placeholder/lib/placeholders";

interface IKanbanColumnProps {
  title: string;
  color: string;
  data: ICardList[];
  companyList: ICardCompany[];
	flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  guid_client: string;
  isLoading: boolean;
}

export const KanbanColumn = (props: IKanbanColumnProps) => {
  const [isLoading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    setIsloading(props.isLoading);
  }, [props.isLoading]);

  const reorderData = () => {
    const sortedData = [...props.data];
    sortedData.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
    return sortedData;
  }

  const sortedData = reorderData();

  return (
    <Flex direction="column" gap={2} textAlign="center"  alignItems="start" flexGrow={1} width="100%" height="100%"overflowX="auto" overflowY="auto" >

      <Flex borderBottom={`5px solid ${props.color}`} width="100%" >
        <Flex justifyContent="center" alignItems="center" gap="8px" width="100%">
          <Text fontFamily="Poppins-Medium" fontSize={16}>{props.title}</Text>
          <Flex bg={props.color} fontFamily="Poppins-Medium" w="16px" h="16px" color="white" fontSize="12px" borderRadius="4px" alignItems="center" justifyContent="center">
            {sortedData.length}
          </Flex>
        </Flex>
      </Flex>
      <Flex className="kanbansidebar" direction="column" flexGrow={1} width="100%"  gap={2} h={400}  paddingRight="2px" overflow="auto"  >
        {isLoading ? (
          Array.from({ length: Math.floor(Math.random() * 3) + 1 }, (_, index) => (
            <RectShape color='#E0E0E0' style={{ width: 332, height: 134, borderRadius: "5px" }} />
          ))
        ) : sortedData.map((info, index) => (<>
          <KanbanCard key={index} title={props.title} color={props.color} data={info} companyList={props.companyList} flushHook={props.flushHook} guid_client={props.guid_client} />
        </>))}
      </Flex>
    </Flex>
  )
}
