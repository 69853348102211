import { IErrorMessage } from '../../_services/interface/IDefaults';
import { IIcon, ITag } from './table.interface';

export const stateRegionForTag = (initials: string): ITag => {
  const norte = ['AM', 'RR', 'AP', 'PA', 'TO', 'RO', 'AC'];
  const nordeste = ['MA', 'PI', 'CE', 'RN', 'PE', 'PB', 'SE', 'AL', 'BA'];
  const centroOeste = ['MT', 'MS', 'GO', 'DF'];
  const sudeste = ['SP', 'RJ', 'ES', 'MG'];
  const sul = ['PR', 'RS', 'SC'];
  let color = null;
  let textColor = null;

  if (norte.includes(initials)) {
    color = '#ECFCCB';
    textColor = '#365314';
  }

  if (nordeste.includes(initials)) {
    color = '#EDE9FE';
    textColor = '#4C1D95';
  }

  if (centroOeste.includes(initials)) {
    color = '#FAE8FF';
    textColor = '#701A75';
  }

  if (sudeste.includes(initials)) {
    color = '#DDE5FF';
    textColor = '#292689';
  }

  if (sul.includes(initials)) {
    color = '#E0F2FE';
    textColor = '#0C4A6E';
  }

  return {
    text: initials,
    color: color,
    textColor: textColor,
  } as ITag;
};

export const normalizeString = (str: string) => {
  return str
    .normalize('NFD') // Normaliza a string para decompor caracteres especiais
    .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace(/[^a-zA-Z0-9]/g, '') // Remove caracteres especiais
    .toUpperCase(); // Converte para maiúsculas
};

export const validFilterField = (
  field: string | string[] | undefined
): boolean => {
  if (field) {
    if (typeof field === 'string') {
      if (field.trim() !== '') {
        return true;
      }
    } else if (field instanceof Array) {
      if (field.length > 0) {
        return true;
      }
    }
  }
  return false;
};

export const entityTags = (type: 'number' | 'text') => {
  return [
    {
      text: 'Matriz',
      value: type === 'number' ? 0 : 'holding',
      textColor: '#365314',
      color: '#ECFCCB',
    },
    {
      text: 'Filial',
      value: type === 'number' ? 1 : 'subsidiary',
      textColor: '#065666',
      color: '#C4F1F9',
    },
    {
      text: 'Estrangeira',
      value: type === 'number' ? 2 : 'foreign',
      color: '#CCFBF1',
      textColor: '#134E4A',
    },
    {
      text: 'Pessoa Física',
      value: type === 'number' ? 3 : 'persona',
      textColor: '#134E4A',
      color: '#CCFBF1',
    },
  ];
};

export const jurisdictionTags = () => {
  return [
    {
      text: 'Federal',
      value: 'federal',
      textColor: '#065666',
      color: '#C4F1F9',
    },
    {
      text: 'Municipal',
      value: 'municipal',
      textColor: '#134E4A',
      color: '#CCFBF1',
    },
    {
      text: 'Estadual',
      value: 'state',
      textColor: '#322659',
      color: '#E9D8FD',
    },
  ];
};

export const errorMessage = (obj: IErrorMessage): IIcon => {
  if(!obj.errorMessage) {
    return {
      tooltipText: '',
      iconUrl: '',
      show: false
    };
  }

  return {
    tooltipText: obj.errorMessage,
    iconUrl: '/icons/alert.svg',
    show: true  
  };
}