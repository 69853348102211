import { useState, useRef, useEffect } from "react";
import { FieldErrorsImpl, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";
import { AppInput } from "../../../components/AppInput";
import { GetUserInformation } from "../../../utils/getUserInformation";
import { TLocationHandler } from "../_types/location.type";
import { FormButton } from "../../../components/login/button.component";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormError, SimpleFormError } from "../../../components/login/formError.component";
import { IFormRequestInput, IRequestResponse } from "../../../_services/interface/login.interface";
import { postRecoveryPass } from "../../../_services/auth.service";
import { VerificationForm } from "../Verification/VerificationForm";
import { IErrorResponse } from "../../../_services/interface/IDefaults";

interface AccountRecoveryProps {
  locationHandler: TLocationHandler;
}

export const AccountRecovery = (props: AccountRecoveryProps) => {
  GetUserInformation();

  const YupRequestRecovery = Yup.object().shape({
    email: Yup.string().email().required('Preencha o campo com um email válido')
  });

    //Generate o resolver
    const resolverForm = { resolver: yupResolver(YupRequestRecovery) };

  const captchaRef = useRef<ReCAPTCHA>(null);
  const [captchaChallenge, setCaptchaChallenge] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<FieldErrorsImpl>();
  const [smplErrorMessage, setSmplErrorMessage] = useState<string>();
  const [allFieldsFilled, setFieldsState] = useState<boolean>(false);

  const {
    formState: { errors }, handleSubmit, register, watch } = useForm<IFormRequestInput>(resolverForm);

  function onChangeCaptcha(value: any) {
    if (value) {
      setCaptchaChallenge(true);
    } else {
      setCaptchaChallenge(false);
    }
  }

  useEffect(() => {
    if (errors) {
      setErrorMessage(errors as any);
    }
  }, [errors]);

  //This useEffect exists to watch all the values on the form
  useEffect(() => {
    watch((value) => {
      if (Object.values(value).filter(_x => _x !== '').length === 1) {
        setFieldsState(true);
      } else {
        setFieldsState(false);
      }
    });
  }, [watch]);

  useEffect(() => {
    if (allFieldsFilled && captchaChallenge) {
      //All fields are filled and captcha challenge completed
      //so the system will reveal the Button
      setSubmitDisabled(false);
    } else {
      //If nothing meets the system will keep the button disabled
      setSubmitDisabled(true);
    }
  }, [allFieldsFilled, captchaChallenge]);

  const handleFormInfo = async (data: IFormRequestInput) => {
    //Disable everything
    setSubmitDisabled(true);

    const {status, response} = await postRecoveryPass(data);
    if (status === 200) {
      if ((response as IRequestResponse).mailsent) {
        // IF everything when ok, the system will send the user to the window
        props.locationHandler(<VerificationForm email={data.email} locationHandler={props.locationHandler} />)
      } else {
        setSmplErrorMessage('Um erro ocorreu ao tentar enviar um email');
      }
    } else {
      const err = (response as IErrorResponse).message;
      switch (err) {
        case 'RECOVERY_NOT_FOUND':
          setSmplErrorMessage('Usuário não encontrado, ou email inválido');
          break;
        default:
          setSmplErrorMessage('Usuário não encontrado, ou email inválido');
          break;
      }
    }

    setSubmitDisabled(false);
  }

  return (
    <Flex fontFamily="Poppins-Medium" display="flex" alignItems="center" mt="0px" width={396}>
      <Flex direction="column" w="100%">
        <Text fontSize="32px" textAlign="center">Recuperação</Text>
        <Flex alignSelf="center" marginTop="50px">
          <Text fontSize="12px">
            Informe o endereço de email associado a sua conta, iremos enviar
            para você uma senha única onde você deverá informá-la na tela
            seguinte
          </Text>
        </Flex>
        <Box marginTop="50px">
          <form>
            <Flex direction="column" gap="16px" alignItems="stretch" justifyContent="center">

              {errorMessage ? (<FormError message={errorMessage} />) : null}
              {smplErrorMessage ? (<SimpleFormError msg={smplErrorMessage} />) : null}

              <AppInput border="none" type="email" placeholder="Email" {...register("email", { required: true })} />
              <ReCAPTCHA
                sitekey="6LelrG4iAAAAAGZUt_aPuZp1cInj7M1f_pGD98fM"
                ref={captchaRef}
                onChange={onChangeCaptcha}
              />
              <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>Continuar</FormButton>
              <Flex alignItems="center" fontSize="12px" marginTop="-10px" justifyContent="center">
                  <Text m="0">
                    <Link onClick={() => props.locationHandler(undefined)} _hover={{ color: "#8789FF", cursor: "pointer" }} ml={2} color="#4B4EFF">
                      Retornar ao Login
                    </Link>
                  </Text>
                </Flex>
              <Flex
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Text fontSize="11px">
                  <strong>Seu email mudou? </strong>Se você não possui mais
                  acesso ao endereço de email associado com a sua conta no
                  Taxly, entre em contato com o nosso{" "}
                  <Link textDecoration="none" color="#4B4EFF" href="#">
                    Help Desk
                  </Link>{" "}
                  para ajudá-lo a recuperar a sua conta.
                </Text>
              </Flex>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};
