import { Flex, Input, InputProps, Text } from "@chakra-ui/react";
import { forwardRef } from "react";
import Form from 'react-bootstrap/Form';
import InputMask from "react-input-mask";

interface CustomStyleProps {
  [key: string]: any;
}

interface AppInputProps {
  errors?: { message?: string };
  label?: string;
  mask?: string;
  disabled?: boolean;
  inputCustomStyle?: CustomStyleProps;
  labelCustomStyle?: CustomStyleProps;
}

export const DashInput = forwardRef(({ errors, label, ...otherProps }: AppInputProps & InputProps, ref) => {
  return (
    <Input
      ref={ref as React.Ref<HTMLInputElement>}
      fontSize="12px"
      bg="#F3F3F3"
      fontFamily="Poppins-medium"
      borderRadius="0px"
      borderTop="none"
      borderX="none"
      bgColor="transparent"
      borderBottom="1px solid #A1A1A1"
      borderColor="#C3BFBF"
      type="text"
      color="#3a3a3a"
      _placeholder={{ opacity: 0.7 }}
      {...otherProps}
    />
  );
});

export const DashInputLabel = forwardRef(({ errors, label, labelCustomStyle, inputCustomStyle, ...otherProps }: AppInputProps & InputProps, ref) => {
  return (
    <Flex display="flex" flexDirection="column" width="100%">
      <Text
        fontFamily={ labelCustomStyle?.fontFamily ?? 'Poppins-medium' }
        padding={ labelCustomStyle?.padding ?? undefined }
        fontSize="12px"
        fontWeight="400"
        >{label}
      </Text>

      <Input
        ref={ref as React.Ref<HTMLInputElement>}
        fontSize="12px"
        bg="#F3F3F3"
        fontFamily={ inputCustomStyle?.fontFamily ?? 'Poppins-medium' }
        padding={ inputCustomStyle?.padding ?? undefined }
        borderRadius="4px"
        bgColor="transparent"
        borderBottom="1px solid #CACACA"
        borderColor="#C3BFBF"
        type="text"
        color="#3a3a3a"
        _placeholder={{ opacity: 0.7 }}
        {...otherProps}
      />
    </Flex>
  );
});

export const DashNumber = forwardRef(({ errors, label, ...otherProps }: AppInputProps & InputProps, ref) => {
  return (
    <Input
      ref={ref as React.Ref<HTMLInputElement>}
      fontSize="12px"
      bg="#F3F3F3"
      fontFamily="Poppins-medium"
      borderRadius="0px"
      borderTop="none"
      borderX="none"
      bgColor="transparent"
      borderBottom="1px solid #A1A1A1"
      borderColor="#C3BFBF"
      type="number"
      color="#3a3a3a"
      _placeholder={{ opacity: 0.7 }}
      {...otherProps}
    />
  );
});

export const DashInputMask = forwardRef(({ errors, label, ...otherProps }: AppInputProps & InputProps, ref) => {
  return (
    <Flex display="flex" flexDirection="column" width="100%">
      <Text
        fontFamily={otherProps.labelCustomStyle?.fontFamily ?? 'Poppins-medium'}
        padding={otherProps.labelCustomStyle?.padding ?? undefined}
        fontSize="12px"
        fontWeight="400"
      >
        {label}
      </Text>

      <Input
        id={otherProps.id}
        ref={ref as React.Ref<HTMLInputElement>}
        fontSize="12px"
        as={InputMask}
        bg="#F3F3F3"
        fontFamily="Poppins-medium"
        borderRadius="0px"
        borderTop="none"
        borderX="none"
        bgColor="transparent"
        borderBottom="1px solid #A1A1A1"
        borderColor="#C3BFBF"
        type="text"
        color="#3a3a3a"
        _placeholder={{ opacity: 0.7 }}
        {...otherProps}
      />
    </Flex>
  );
});

export const DashInputDate = forwardRef(({ errors, label, ...otherProps }: AppInputProps & InputProps, ref) => {
  return (
    <Input
      ref={ref as React.Ref<HTMLInputElement>}
      //as={DatePicker}
      fontSize="12px"
      bg="#F3F3F3"
      fontFamily="Poppins-medium"
      borderRadius="0px"
      borderTop="none"
      borderX="none"
      bgColor="transparent"
      borderBottom="1px solid #A1A1A1"
      borderColor="#C3BFBF"
      type="date"
      lang="pt-BR"
      color="#3a3a3a"
      _placeholder={{ opacity: 0.7 }}
      {...otherProps}
    />
  );
});

export const DashFileInput = forwardRef(({ errors, label, accept, style, ...otherProps }: AppInputProps & InputProps, ref) => {
  return (
    <Form.Group controlId="formFileLg">
      <Input
        className="form-control form-control-lg"
        ref={ref as React.Ref<HTMLInputElement>}
        style={{ ...style }}
        accept={accept}
        type="file"
        size="lg"
        height="calc(1.5em + 1rem + calc(var(--bs-border-width) * 2))"
        {...otherProps}
      />
    </Form.Group>
  );
});