import { Flex } from "@chakra-ui/react";
import { ReactElement, useState } from "react";
import { AppInput } from "../../../../components/AppInput";
import { IDigitInput, IInputElement, IValidationInputProps } from "./interfaces";

const DigitInput = (props: IDigitInput) => {
  const [inputValue, setInputValue] = useState("");
  
  const handleInputSize = (e: any) => {
    const _t: HTMLInputElement =  (e.target as HTMLInputElement);
    if (_t.value.length <= 1) {
      setInputValue(_t.value);
    }
  }

  const handleKey = (e: any) => {
    const _t: HTMLInputElement =  (e.target as HTMLInputElement);
    if (props.onChange) {
      //Send the focus to the next input and the value of the current input
      if (e.keyCode === 8) {
        props.onChange.call(undefined, _t, 'prev', _t.value);
      } else {
        props.onChange.call(undefined, _t, 'next', _t.value);
      }
    }
  }

  return (
    <AppInput 
      w="50px" 
      h="60px" 
      textAlign="center" 
      onChange={handleInputSize} 
      onKeyUp={handleKey} 
      value={inputValue} 
      pl={0} 
      fontSize={28} 
      fontWeight="bold" 
      border="none" 
      type="number" 
      name={props.name} 
    />
  )
}

const allFields: IInputElement[] = [];

/**
 * Generates the validation Input
 * @param {IValidationInputProps} props
 * @returns 
 */
export const ValidationInput = (props: IValidationInputProps) => {

  const inputElements: ReactElement[] = [];

  const fieldHandler = (_input: HTMLInputElement, segment: 'prev' | 'next', value: string) => {
    const currentField = _input.name;
    //Find the next field
    let nextField: IInputElement;

    if (segment === 'next') {
      nextField = allFields[allFields.indexOf(allFields.find((x) => x.name === currentField) as IInputElement) + 1];
      //Add the value on the current field
      (allFields.find((x) => x.name === currentField) as IInputElement).value = _input.value;
    } else {
      nextField = allFields[allFields.indexOf(allFields.find((x) => x.name === currentField) as IInputElement) - 1];
      //Remove the value from the current field
      (allFields.find((x) => x.name === currentField) as IInputElement).value = null;
    }

    if (nextField) {
      const _next = document.querySelector<HTMLInputElement>(`input[name=${nextField.name}]`);
      if (_next) {
        _next.focus();
      }
    }

    //Dispatch the change
    props.onChange.call(undefined, allFields.map(_x => _x.value).join(''));
  }

  for (let i = 0; i < props.digits; i++) {
    //Set the field name
    const fieldName = `field-${i+1}`;
    if (!allFields.find((x) => x.name === fieldName)) {
      allFields.push({
        name: fieldName,
        value: null,
      });
    }
    
    inputElements.push(
      <DigitInput pos={i+1} name={fieldName} onChange={fieldHandler} />
    );
  }
  
  return (
    <Flex gap={3} >
      {inputElements.map((_el, i) => (
        <DigitInput key={i} {..._el.props} />
      ))}
    </Flex>
  )
}