import { Button } from "./Button";
import { Link } from "./Link";
import { Tag } from "./Tag";
import { TooltipIcon } from "./TooltipIcon";
import { FormLabel } from "./FormLabel";
import { Input } from "./Input";

export const customComponents = {
  Button,
  Link,
  Tag,
  TooltipIcon,
  Input,
  FormLabel,
};
