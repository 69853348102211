import { ComponentStyleConfig } from "@chakra-ui/react";

export const TooltipIcon: ComponentStyleConfig = {
  baseStyle: {
    background: "solid.white",
  },
  variants: {
    primary: {
      _hover: {
        bgColor: "primary.20",
      },
    },
  },
};
