import { useDisclosure, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, Link, TableContainer, Table, Tbody, Td, Tr, Divider, Thead, Th, Image } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { cndDetails } from "../../../../_services/cnd.service";
import { ICNDModalParams, ICNDDataResponse, ICNDListData, ICNDDetailsResponse } from "../../../../_services/interface/cnd.interface";
import { Status } from "../components/Table/certificate";
import CertificateSearchType from "../components/CertificateSearchType";
import { formatDocument } from "../../Persona/utils/cpf";
import { AddNewTask } from '../../TaxKanban/modals/Task/Create/AddNewTask';
import { RelatedCard } from '../../TaxKanban/modals/Task/RelatedCard'
import { DeleteCndModal } from "./DeleteCndModal";
import { checkCollaboratorPermission } from "../../Users/utils/checkPermission";
import { IResponse } from "../../../../_services/interface/user.interface";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { jurisdictionTag, stateRegion } from "../../../../utils/utils.functions";

interface ICertificateHistoryProps {
  guid_client: string;
  certificate: ICNDListData;
  openModal?: boolean;
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
  flushHook?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CertificateHistory = (props: ICertificateHistoryProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cndDetailsData, setCndDetails] = useState<ICNDDetailsResponse|null>();
  const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState(false);
  const [isOpenModalRelatedTask, setIsOpenModalRelatedTask] = useState(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const { validate } = useLoginFormStore();

  const openCreateTaskModal = () => {
    if (!cndDetailsData?.hasCard) {
      setIsOpenModalCreateTask(true);
    } else {
      setIsOpenModalRelatedTask(true);
    }
  };

  const checkCNDs = async () => {
    const data = {
      guid_client: props.guid_client,
      guid_company: props.certificate.guid_company,
      guid_persona: props.certificate.guid_persona,
      type: props.certificate.slug,
      state: props.certificate.state ? props.certificate.state.initials : null,
      city: props.certificate.city ? props.certificate.city.name : null,
    } as ICNDModalParams;

    const { status, response } = await cndDetails(data, props.certificate.slug_status === 'error');
    if (status === 200) {
      const cndDetails = response as ICNDDetailsResponse;
      setCndDetails(cndDetails);
    }
  };

  useEffect(() => {
    if (props.openModal && props.openModalHook) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  useEffect(() => {
    if (isOpen && props.certificate) {
      setRefreshData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, props.certificate]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      checkCNDs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  const issuedAt = (cnd: ICNDListData | ICNDDataResponse) => {
    const status = cnd.status;
    if(cnd.slug_status !== 'error' && cnd.issuedAt && status?.name !== 'Não Emitido') return moment.utc(cnd.issuedAt).utc().format('DD/MM/YYYY');
    return '-';
  }

  const flexCentralized = (data: any) => {
    return (
      <Flex alignItems="center" justifyContent="center">
        {data}
      </Flex>
    );
  }

  const checkAdmin = (type: 'history'|'lastIssued') => {
    return type === 'history' && checkCollaboratorPermission(validate as IResponse, props.guid_client, 'org.admin');
  };

  const tableHeader = (type: 'history'|'lastIssued') => {
    return (
      <Thead fontFamily="Poppins-Medium">
        <Tr>
          <Th>{flexCentralized('Status')}</Th>
          <Th>{flexCentralized('Data de Emissão')}</Th>
          <Th>{flexCentralized('Data de Venc.')}</Th>
          <Th>{flexCentralized('Data de Consulta')}</Th>
          <Th>{flexCentralized('Tipo de Pesquisa')}</Th>
          <Th>{flexCentralized('Arquivo')}</Th>
          {checkAdmin(type) && cndDetailsData && cndDetailsData?.history.filter((h) => h.isManual).length > 0 ? (<Th>{flexCentralized('Deletar')}</Th>) : (null)}
        </Tr>
      </Thead>
    );
  }

  const tableRow = (certificate: ICNDDataResponse, type: 'history'|'lastIssued') => {
    return (
      <Tr>
        <Td textAlign="left">
          <Flex justifyContent="flex-start" alignItems="center" position="relative">
            <Status
              fontSize='12px'
              message={certificate.message}
              status={certificate.status}
            />
          </Flex>
        </Td>
        <Td>{flexCentralized(issuedAt(certificate))}</Td>
        <Td>{flexCentralized(certificate.validUntil && moment.utc(certificate.validUntil).isValid() ? moment.utc(certificate.validUntil).format('DD/MM/YYYY') : '-')}</Td>
        <Td>{flexCentralized(certificate.createdAt && moment.utc(certificate.createdAt).isValid() ? moment.utc(certificate.createdAt).format('DD/MM/YYYY') : '-')}</Td>
        <Td>
          {
            flexCentralized((<CertificateSearchType 
              isManual={certificate.isManual}
              fontSize="10px"
            />))
          }
          
        </Td>
        <Td textAlign="center">
          {flexCentralized(iconFile(certificate as unknown as ICNDListData))}
        </Td>
        {checkAdmin(type) ? (
          <Td>
            {flexCentralized(
              <DeleteCndModal
                certificate={props.certificate}
                cnd={certificate}
                guid_client={props.guid_client}
                flushHook={setRefreshData}
                closeModal={closeModal}
                isManual={certificate.isManual}
              />
              )}
          </Td>
        ) : (null)}
      </Tr>
    );
  }

  const iconFile = (cnd: ICNDListData) => {
    const status = cnd.status;

    const showFile = cnd.guid_file && status && status.show_file && cnd.slug_status !== 'error';

    if (showFile){
      return (
        <Flex width="35px" height="35px" justifyContent="center" alignItems="center">
          <Tooltip label="Baixar arquivo">
              <Flex className="icon-bg-blue">
                  <Link color="white" href={cnd.file||''} target="_blank" alignItems="center" justifyContent="center" display="flex" width="30px" height="30px" minWidth="30px" minHeight="30px">
                    <Image height="20px" width="20px" src="/icons/download_icon.png" resize="none" _hover={{ cursor: "pointer" }}/>
                  </Link>
              </Flex>
          </Tooltip>
        </Flex>
      );
    }

    return (
      <Flex width="35px" height="35px" justifyContent="center" alignItems="center">
        <Tooltip label="Não há arquivos a serem baixados">
            <Flex className="icon-bg-blue">
                <Link color="white" alignItems="center" justifyContent="center" _hover={{ cursor: "default" }} display="flex" width="30px" height="30px" minWidth="30px" minHeight="30px">
                  <Image height="20px" width="20px" opacity={0.3} src="/icons/download_icon.png" resize="none" />
                </Link>
            </Flex>
        </Tooltip>
      </Flex>
    );
  }

  const closeModal = () => {
    setCndDetails(null);
    if (props.flushHook) props.flushHook(true);
    onClose();
  }

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal} size="6xl" blockScrollOnMount={false}>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />

        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">
            <Flex w="100%" alignItems="center" display="flex">
              <Flex>Histórico de Pesquisa</Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton onClick={closeModal} />

          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <TableContainer fontFamily="Poppins-Light" border="1px solid" borderColor="gray.300" borderRadius="5px">
                <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
                  <Tbody>
                    <Tr>
                      <Td textAlign="left" fontWeight="bold" w={4}>{props.certificate.entity.type === 3 ? 'Pessoa:' : 'Empresa:'}</Td>
                      <Td>{props.certificate.entity.name}</Td>
                    </Tr>

                    <Tr>
                      <Td textAlign="left" fontWeight="bold" w={4}>{props.certificate.entity.type === 3 ? 'CPF:' : 'CNPJ:'}</Td>
                      <Td>{formatDocument(props.certificate.entity.document, props.certificate.entity.type)}</Td>
                    </Tr>

                    <Tr>
                      <Td textAlign="left" fontWeight="bold" w={4}>Origem:</Td>
                      <Td><Flex w="120px;">{jurisdictionTag(props.certificate.jurisdiction)}</Flex></Td>
                    </Tr>

                    <Tr>
                      <Td textAlign="left" fontWeight="bold" w={4}>Estado:</Td>
                      <Td><Flex w="60px;">{stateRegion(props.certificate.state)}</Flex></Td>
                    </Tr>

                    {props.certificate.jurisdiction === 'municipal' && props.certificate.city ? (
                      <Tr>
                        <Td textAlign="left" fontWeight="bold" w={4}>Cidade:</Td>

                        <Td>
                          <Flex w="100%">
                            <Flex
                              w="auto"
                              backgroundColor="#DBE2E9"
                              alignItems="center"
                              justifyContent="center"
                              p="2px"
                              pl="1rem"
                              pr="1rem"
                              fontSize="12px"
                              textTransform="uppercase"
                              borderRadius={5}
                            >
                              { props.certificate.city.name }
                            </Flex>
                          </Flex>
                        </Td>
                      </Tr>
                    ) : null}
                    <Tr>
                      <Td textAlign="left" fontWeight="bold" w={4}>
                        Link de Origem:
                      </Td>

                      <Td>
                        {cndDetailsData?.certificateOriginLink ? (
                          <Link
                            width="26px"
                            height="26px"
                            minWidth="26px"
                            href={cndDetailsData?.certificateOriginLink as string}
                            target="_blank"
                            resize="none"
                          >
                            <Flex>
                              Acessar

                              <Image
                                width="18px"
                                marginLeft="5px"
                                src="../icons/external-link.svg"
                                resize="none"
                              />
                            </Flex>
                          </Link>  
                        ): null }
                      </Td>
                    </Tr>

                    {
                      props.certificate.guid_company 
                      && (
                        <Tr>
                          <Td textAlign="left" fontWeight="bold" w={4}>
                            Acompanhar certidão:
                          </Td>

                          <Td>
                            <Flex
                              onClick={openCreateTaskModal}
                              cursor={'pointer'}
                              width={'min-content'}
                              _hover={{
                                textDecoration: 'underline',
                              }}
                            >
                              Ir para o Kanban

                              <Image
                                width="18px"
                                marginLeft="5px"
                                src="../icons/external-link.svg"
                                resize="none"
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      ) 
                    }
                  </Tbody>
                </Table>
              </TableContainer>

              <Divider />

              {cndDetailsData?.lastIssued && cndDetailsData.lastIssued.type ? (<>
                <Flex fontWeight="600" fontSize="12px">Última certidão emitida válida:</Flex>

                <TableContainer
                  position="relative"
                  overflow="initial"
                  overflowY="initial"
                  fontFamily="Poppins-Light"
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius="5px"
                >
                  <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
                    {tableHeader('lastIssued')}

                    <Tbody alignItems="center" justifyContent="center">
                      {tableRow(cndDetailsData.lastIssued, 'lastIssued')}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>) : (
                <TableContainer
                  position="relative"
                  overflow="initial"
                  overflowY="initial"
                  fontFamily="Poppins-Light"
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius="5px"
                >
                  <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
                    <Tbody alignItems="center" justifyContent="center">
                      <Tr>
                        <Td textAlign="center" fontWeight="bold" w="80px">
                          <Flex alignItems="center" justifyContent="center">
                            Não há nenhuma certidão emitida do tipo consultado
                            ainda válida para essa {props.certificate.entity.type === 0 ? 'empresa' : 'pessoa'}.
                          </Flex>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              )}

            <Divider />

              <TableContainer fontFamily="Poppins-Light" border="1px solid" borderColor="gray.300" borderRadius="5px" h="300px" overflowX="auto" overflowY="auto">
                <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
                  {tableHeader('history')}
                  <Tbody alignItems="center" justifyContent="center">
                    {!cndDetailsData?.history ? null : cndDetailsData?.history.map((_history, key) => (
                      tableRow(_history, 'history')
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <FormButton onClick={closeModal} width="100%">Fechar</FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal >

      <AddNewTask
        isOpen={isOpenModalCreateTask}
        onClose={() => setIsOpenModalCreateTask(false)}
        flushHook={setRefreshData}
        guid_client={props.guid_client}
        type="certificate"
        dataCertificate={props.certificate}
      />

      <RelatedCard
        isOpen={isOpenModalRelatedTask}
        onClose={() => setIsOpenModalRelatedTask(false)}
        flushHook={setRefreshData}
        guid_client={props.guid_client}
        type="certificate"
        dataCertificate={props.certificate}
      />
    </>
  );
}