import { Flex } from "@chakra-ui/react";

const MANUAL_SEARCH_TYPE = {
  name: "Manual",
  color: "#9b77ff",
};

const AUTOMATIC_SEARCH_TYPE = {
  name: "Automática",
  color: "#EFAD2C",
};

interface IProps {
  isManual: boolean;
  fontSize: string;
}

const CertificateSearchType = (props: IProps) => {
  const searchTypeName = props.isManual
    ? MANUAL_SEARCH_TYPE.name
    : AUTOMATIC_SEARCH_TYPE.name;
  const searchTypeColor = props.isManual
    ? MANUAL_SEARCH_TYPE.color
    : AUTOMATIC_SEARCH_TYPE.color;

  return (
    <Flex 
      backgroundColor={searchTypeColor}
      alignItems="center"
      justifyContent="center"
      p="2px 15px"
      fontSize="12px"
      fontFamily="Poppins-medium"
      lineHeight="16.8px"
      fontWeight="100"
      borderRadius={5}
      height="22px"
      width="fit-content"
      minWidth="auto"
      textTransform="uppercase"
    >
      {searchTypeName}
    </Flex>
  );
};

export default CertificateSearchType;
