import moment from 'moment';

export class BusinessDay {
  month: number;
  year: number;

  constructor(month: number, year: number) {
    this.month = month;
    this.year = year;
  }

  private calculateEaster(): moment.Moment {
    const C = Math.floor(this.year / 100);
    const N = this.year - 19 * Math.floor(this.year / 19);
    const K = Math.floor((C - 17) / 25);
    let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor(I / 30);
    I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
    let J = this.year + Math.floor(this.year / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    const L = I - J;
    const M = 3 + Math.floor((L + 40) / 44);
    const D = L + 28 - 31 * Math.floor(M / 4);
    const month = M < 10 ? '0' + M : M;
    const day = D < 10 ? '0' + D : D;
    const easterDate = this.year + '-' + month + '-' + day;
    return moment.utc(easterDate);
  }

  private allHolidays() {
    const easterDate = this.calculateEaster();

    return [
      { date: easterDate.utc() }, //Pascoa
      { date: easterDate.clone().add(60, 'd') }, //Corpus Christi
      { date: easterDate.clone().add(-47, 'd') }, //Carnaval
      { date: easterDate.clone().add(-2, 'd') }, //Sexta-Feira Santa
      { date: moment.utc(`${String(this.year)}-01-01`).utc() },
      { date: moment.utc(`${String(this.year)}-04-21`).utc() },
      { date: moment.utc(`${String(this.year)}-05-01`).utc() },
      { date: moment.utc(`${String(this.year)}-09-07`).utc() },
      { date: moment.utc(`${String(this.year)}-10-12`).utc() },
      { date: moment.utc(`${String(this.year)}-11-02`).utc() },
      { date: moment.utc(`${String(this.year)}-11-15`).utc() },
      { date: moment.utc(`${String(this.year)}-12-25`).utc() },
    ];
  }

  public allWeekDays(): moment.Moment[] {
    const allWeekDays = [];
    for (let i = 1; i <= 31; i++) {
      const testDate = new Date(this.year, this.month - 1, i);
      const day = testDate.getDay();
      if (day > 0 && day < 6) {
        const currentDay = moment.utc(`${this.month}/${i}/${this.year}`, 'MM/DD/YYYY').utc();
        if (
          !this.allHolidays()
            .map((e) => e.date.format())
            .includes(currentDay.format())
        ) {
          allWeekDays.push(currentDay);
        }
      }
    }
    return allWeekDays;
  }

  public isWeekDay(date: moment.Moment) {
    return this.allWeekDays().filter((x) => x.format('MM/DD/YYYY') === date.format('MM/DD/YYYY')).length > 0;
  }

  public nextWeekDay(date: moment.Moment) {
    const currentDay = parseInt(date.format('DD'));
    return this.allWeekDays().filter((x) => parseInt(x.format('DD')) > currentDay)[0];
  }

  public previousWeekDay(date: moment.Moment) {
    const currentDay = parseInt(date.format('DD'));
    const found = this.allWeekDays().filter((x) => parseInt(x.format('DD')) < currentDay);
    return found[found.length - 1];
  }

  public getNthBusinessDay(n: number): moment.Moment {
    const allDays = this.allWeekDays();
    return allDays[n - 1];
  }

  public getLastNthBusinessDay(n: number): moment.Moment {
    const allDays = this.allWeekDays();
    return allDays[allDays.length - n];
  }

  public getLastBusinessDay(): moment.Moment {
    return this.getLastNthBusinessDay(1);
  }

  public getFirstBusinessDay(): moment.Moment {
    return this.getNthBusinessDay(1);
  }
}
