import { Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import SelectMultiEmail from "../../../../components/SelectMultiEmail";
import { getCompanyPossibleEmails } from "../../../../_services/company.service";
import { configNotification, getConfigNotification } from "../../../../_services/config.service";
import { RectShape } from "react-placeholder/lib/placeholders";

interface INotificationProps {
  refreshData: boolean;
  guid_client: string;
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
  submit: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: boolean;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Notification = (props: INotificationProps) => {
  const toast = useToast();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  
  const [isActive, setIsActive] = useState<boolean>(false);
  const [emails, setEmails] = useState<string[]>([]);
  
  const [initialIsActive, setInitialIsActive] = useState<boolean>(false);
  const [initialEmails, setInitialEmails] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submit = async () => {
    setSubmitDisabled(true);
    const {status, response} = await configNotification(props.guid_client, {
      isActive,
      emails
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });

      props.setSubmit(false);
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    }

    setSubmitDisabled(false);
  }

  const configFields = async () => {
    setIsLoading(true);
    const {status, response} = await getConfigNotification(props.guid_client);

    if(
      status === 200 
      && response
      && 'isActive' in response
      && 'emails' in response
    ) {
      const {isActive, emails} = response;

      setIsActive(isActive);
      setEmails(emails);

      setInitialIsActive(isActive);
      setInitialEmails(emails);
    }

    setTimeout(() => setIsLoading(false), 500);
  }

  const getEmailsOptions = async (): Promise<string[]> => {
    let localEmails: string[] = [];
    const { status, response } = await getCompanyPossibleEmails(props.guid_client);
    if (status === 200 && Array.isArray(response)) {
      localEmails = response as string[];
    }

    return localEmails;
  }

  const handleCheck = (e: { target: { value: string, checked: boolean } }) => {
    setIsActive(e.target.checked);
  }

  useEffect(() => {
    configFields();
  }, [])

  useEffect(() => {
    if(props.submit) {
      submit();
    }
  }, [props.submit]);

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  useEffect(() => {
    if (initialIsActive !== isActive || (initialEmails ?? []).join(',') !== (emails ?? []).join(',')) {
      props.hasChangeHook(true);
    } else {
      props.hasChangeHook(false);
    }
  }, [isActive, emails]);

  const image = isActive ? "../icons/switch-blue.svg" : "../icons/switch-gray.svg";

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Notificações</Text>
      </Flex>

      <Flex width="100%" flexDirection="column" mb="35px">
        <Text fontSize="14px" fontFamily="Poppins-Medium" borderBottom="1px" borderBottomColor="gray.300">Update semanal por email</Text>
        
        <Flex mt="30px">
          <Flex
            display="flex"
            justifyContent="start"
            cursor="pointer"
            onClick={() => setIsActive(!isActive)}
          >
            {
              isLoading ? (
                <Flex alignItems="center">
                  <RectShape color='#e0e0e0' style={{width: '50px', height: '23px', borderRadius: "50px"}}/>
                </Flex>
              ) : (
                <Image w="50px" src={image} />
              )
            }
          </Flex>

          <Flex direction="column" ml="10px">
            <Text fontSize={12} fontFamily="Poppins-Medium">Agendar atualização semanal por email?</Text>
            <Text fontSize="12px" opacity={0.7} fontFamily="Poppins-Medium">
              Com essa opção ativada, o sistema irá enviar semanalmente um email com informações sobre as empresas cadastradas.
            </Text>
          </Flex>
        </Flex>
        
        <br/>
        
        {isActive && !isLoading ?
          <Flex direction="column">
            <Text fontSize="12px" opacity={0.9} mb={2} mt={2}>
              Digite o(s) email(s) de quem irá receber a atualização semanal.
            </Text>
            <SelectMultiEmail
              onChange={(_emails: string[]) => {
                setEmails(_emails);
              }}
              dropdownParent='body'
              getSelectedEmails={async () => emails}
              getEmailsOptions={getEmailsOptions}
            />
          </Flex>
        : null}
      </Flex>

      <hr />

      <Flex width="100%" justifyContent="flex-start" marginTop="35px">
        <FormButton onClick={submit} disabled={submitDisabled}>
          Salvar alterações
        </FormButton>
      </Flex>
    </Flex>
  );
}
