import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { ITaxCurrentAccountListData } from "../../../_services/interface/taxCurrentAccount.interface";
import { CNDIsRunning } from "../../../_services/cnd.service";
import { useClientContext } from "../../../components/sidebar";
import { searchTaxCurrentAccount } from "../../../_services/taxCurrentAccount.service";

interface ITaxCurrentAccountSearchModalProps {
  taxCurrentAccount: ITaxCurrentAccountListData;
  openModal?: boolean;
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TaxCurrentAccountSearchModal = (props: ITaxCurrentAccountSearchModalProps) => {
  // Global
  const toast = useToast();
  const toastOverseer = useRef<any>();
  const { guid_client } = useClientContext();
  
  // Component
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [running, setRunning] = useState<boolean>(false);
  const [isLoadingTaxCurrentAccount, setLoadingTaxCurrentAccountStatus] = useState<boolean>(false);

  const yesOption = async () => {
    setLoadingTaxCurrentAccountStatus(true);
    toastOverseer.current = toast({
      title: 'Estamos pesquisando o relatório',
      description: 'Em breve a consulta será realizada',
      status: 'loading',
      isClosable: true,
      duration: 2000,
    });

    onClose();

    try {
      const { status } = await searchTaxCurrentAccount(
        guid_client as string,
        props.taxCurrentAccount.company.guid_company,
        props.taxCurrentAccount.slug
      );

      if (status && status === 200) {
        toast.close(toastOverseer.current);

        toast({
          title: 'Estamos pesquisando o relatório em segundo plano',
          description: 'Em breve a consulta será concluída e essa tela poderá ser atualizada.',
          status: 'loading',
          isClosable: true,
          duration: 5000,
        });
      } else if (!!status) {
        toast({
          title: 'Falha ao encontrar relatório',
          description: `Ocorreu uma falha ao tentar buscar o relatório, por favor, tente novamente mais tarde.`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });  
      }
    } catch (err) {
      toast({
        title: 'Falha ao encontrar relatório',
        description: `O serviço demorou mais de 2 minuto para retornar um resultado.`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
    setLoadingTaxCurrentAccountStatus(false);
  }

  const isRunning = async () => {
    const response = await CNDIsRunning(props.taxCurrentAccount.slug, props.taxCurrentAccount.company.guid_company);
    if (response.status === 200) {
      setRunning(response.response as boolean);
    }
  }

  useEffect(() => {
    if (props.openModal && props.openModalHook) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  useEffect(() => {
    if(isOpen) {
      isRunning();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
      <>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
          <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
            <ModalHeader fontFamily="Poppins-SemiBold">Buscar Relatório</ModalHeader>
            <ModalCloseButton zIndex={99999} />
            <ModalBody fontFamily="Poppins-medium" fontSize={12}>
              <Flex justifyContent="center" flexDirection="column" gap={5}>
                <Flex flexDirection="column" gap={5}>
                  {running ? (
                      <Flex flexDirection="column" gap={8} alignItems="center" backgroundColor="whiteAlpha.900" position="absolute" top={0} bottom={0} left={0} right={0} alignContent="center" justifyContent="center" zIndex={99}>
                        <Text textAlign="center" fontSize={20}>Buscando Relatório, por favor aguarde</Text>
                        <Spinner width={50} height={50} />
                      </Flex>
                  ) : null}
                  <Text>
                    Deseja pesquisar o relatório para esse CNPJ?
                  </Text>
                  <Text>
                    Esta ação não afetará o agendamento desta empresa.
                  </Text>
                </Flex>
                <Flex gap={2} flexGrow={1} justifyContent="space-between">
                  <Button isDisabled={isLoadingTaxCurrentAccount} bg="#4B4EFF" color="white" fontWeight="thin" fontSize="12px" h="32px" width="50%" onClick={() => yesOption()}>Sim</Button>
                  <Button isDisabled={isLoadingTaxCurrentAccount} bg="#4B4EFF" color="white" fontWeight="thin" fontSize="12px" h="32px" width="50%" onClick={() => onClose()}>Não</Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
  );
}