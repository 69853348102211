import { Checkbox, Flex, Text, Grid, GridItem, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure, useToast, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Input, Link, Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingComponent from "../../../../components/loading";
import { FormButton } from "../../../../components/login/button.component";
import { SelectCompanies } from "../../../../modal/SelectCompanies";
import { getClientCertificates } from "../../../../_services/certificate.service";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { getClientCompanies } from "../../../../_services/company.service";
import { IClientCompanies, ICollaborators } from "../../../../_services/interface/company.interface";
import { IErrorResponse } from "../../../../_services/interface/IDefaults";
import { IResponse, IUser } from "../../../../_services/interface/user.interface";
import { getPermissions, patchPermissions } from "../../../../_services/user.service";
import { formatCnpj } from "../../Companies/Company/util/cnpj";
import { allPermissions } from "../utils/permissions";
import { SelectPersonas } from "../../../../modal/SelectPersonas";
import { listWithoutPermissionPersonas } from "../../../../_services/persona.service";
import { IPersona } from "../../../../_services/interface/persona.interface";
import useLoginFormStore from "../../../../store/useLoginFormStore";

interface IPermissionsModalProps {
  user: IUser|undefined;
  validation: IResponse;
  guid_client: string;
  openModal: boolean;
  openModalHook: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IPermissionsForm {
  permission: string[];
  companiesAccess: string | string[];
}

export const PermissionsModal = (props: IPermissionsModalProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [openSelectCompanies, setOpenSelectCompanies] = useState<boolean>(false);
  const [openSelectPersonas, setOpenSelectPersonas] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [loadedCurretPermission, setLoadedCurretPermission] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>();
  const [currentPermissions, setCurrentPermissions] = useState<string[]>();
  const [accessCompanies, setAccessCompanies] = useState<string | string[]>([]);
  const [accessPersona, setAccessPersona] = useState<string | string[]>([]);
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[] | IErrorResponse>()
  const [checkboxAll, setCheckboxAll] = useState<1|2|3>(1);
  const [checkboxPersonaAll, setCheckboxPersonaAll] = useState<1|2|3>(1);
  const [certificatesInUse, setCertificatesInUse] = useState<ICertificate[]>([])
  const [products, setProducts] = useState<string[]>([]);
  const [accessAllCompanies, setAccessAllCompanies] = useState<boolean>(true);
  const [accessAllPersona, setAccessAllPersona] = useState<boolean>(true);
  const [filteredCompanies, setFilteredCompanies] = useState<IClientCompanies[] | IErrorResponse>([])
  const [personas, setPersonas] = useState<IPersona[]>([]);
  const { watch, handleSubmit, setValue } = useForm<IPermissionsForm>();
  const toast = useToast();
  const { validate } = useLoginFormStore();

  const fetchListPersonaData = async () => {
    if (props.guid_client) {
      const filters = {
        page: 1,
        row_by_page: '-1'
      };

      const { status, response } = await listWithoutPermissionPersonas(props.guid_client, filters);

      if (
        status === 200
        && response.data !== undefined
      ) {
        const data: IPersona[] = response.data;
        setPersonas(data);
      }
    }
  }

  const getUserPermissions = async () => {
    setLoadedCurretPermission(false);
    if (!props.user) {
      return;
    }

    fetchListPersonaData();
    const { status, response } = await getPermissions(props.guid_client, props.user.guid_user);
    const companyList = await getClientCompanies(props.guid_client);

    if (status === 200 && companyList.status === 200) {
      setCurrentPermissions(response.permissions as string[]);
      setProducts(response.products as string[]);
      setAllCompanies(companyList.response as IClientCompanies[]);
      setFilteredCompanies(companyList.response as IClientCompanies[]);
      let checkBoxAll: 1|2|3 = 1;
      if(response.companiesAccess === 'all') {
        checkBoxAll = 1;
      } else {
        if(response.companiesAccess.length === 0) {
          checkBoxAll = 2;
        } else {
          checkBoxAll = 3;
        }
      }

      setCheckboxAll(checkBoxAll);

      let checkBoxPersonaAll: 1|2|3 = 1;
      if(response.personaAccess === 'all') {
        checkBoxPersonaAll = 1;
      } else {
        if(response.personaAccess.length === 0) {
          checkBoxPersonaAll = 2;
        } else {
          checkBoxPersonaAll = 3;
        }
      }
      setCheckboxPersonaAll(checkBoxPersonaAll);
      setAccessAllCompanies(response.companiesAccess === 'all')
      if(response.companiesAccess !== 'all') {
        if(Array.isArray(response.companiesAccess)) {
          setAccessCompanies(response.companiesAccess)
        } else {
          setAccessCompanies([])
        }
      }
      if(response.personaAccess !== 'all') {
        if(Array.isArray(response.personaAccess)) {
          setAccessPersona(response.personaAccess)
        } else {
          setAccessPersona([])
        }
      }
      setValue('permission', response.permissions as string[]);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar retornar as permissões deste usuário',
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
    setLoadedCurretPermission(true);
  }

  useEffect(() => {
    if (isOpen) {
      getUserPermissions();
      setProductsInUse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (watch().permission! && watch().permission?.includes('org.admin') && watch().permission?.length! > 1) {
      setValue('permission', ['org.admin']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch().permission!]);

  const handleFormInfo = async (data: IPermissionsForm) => {
    setSubmitDisabled(true);
    setSimpleErrorMessage('');
    if (data.permission && accessCompanies && props.user) {
      if (data.permission.length > 0 || (!accessAllCompanies && accessCompanies.length === 0)) {

        let companiesAccess: string|string[] = accessAllCompanies ? 'all' : [];
        if(checkboxAll === 3) {
          companiesAccess = accessCompanies;
        }

        let personaAccess: string|string[] = accessAllPersona ? 'all' : [];
        if(checkboxPersonaAll === 3) {
          personaAccess = accessPersona;
        }

        const { status } = await patchPermissions(props.guid_client, props.user.guid_user, {
          permissions: data.permission,
          products: products,
          companiesAccess: companiesAccess,
          personaAccess: personaAccess
        });

        if (status === 200) {
          toast({
            title: 'Permissões atualizadas com sucesso.',
            status: 'success',
            isClosable: true,
            duration: 5000,
          });
          onClose();
        } else {
          toast({
            title: 'Ocorreu um erro ao atualizar as permissões',
            description: 'Ocorreu um erro ao tentar atualizar as permissões deste usuário, por favor, tente novamente mais tarde.',
            status: 'error',
            isClosable: true,
            duration: 5000,
          });
        }
      } else {
        setSimpleErrorMessage('Você precisa pelo menos selecionar uma permissão');
      }
    } else {
      setSimpleErrorMessage('Você precisa pelo menos selecionar uma permissão');
    }
    setSubmitDisabled(false);
  }

  const onChangeCheckboxLine = (permission: string) => {
    if(watch().permission?.includes(permission)) {
      setValue('permission', watch().permission?.filter((item: string) => item !== permission)!);
    } else {
      setValue('permission', [...watch().permission!, permission]);
    }
  }

  const checkboxLine = (type: string, _permission: any) => {
    const _p = _permission.available.find((x: any) => x.id === type);
    if (_p && currentPermissions && Array.isArray(filteredCompanies) && loadedCurretPermission) {
      return (
        
          <Checkbox 
            value={`${_permission.id}.${_p.id}`}
            isDisabled={_p.id !== 'admin' ? watch().permission?.includes('org.admin') : false}
            isChecked={watch().permission?.includes(`${_permission.id}.${_p.id}`)} 
            colorScheme='green'
            onChange={()=>{onChangeCheckboxLine(`${_permission.id}.${_p.id}`)}}
            size='lg'
            title={_p.description}
          />
        
      );
    } else {
      return null;
    }
  }

  /**
   * Check if the current logged user can grant admin access to other user
   * @returns 
   */
  const canGrantAdmin = () => {
    return props.validation.clients.find((_x) => _x.guid_client === props.guid_client)?.permissions.includes('org.admin') || props.validation.clients.find((_x) => _x.guid_client === props.guid_client)?.permissions.includes('org.owner');
  }

  useEffect(() => {
    if (props.openModal && props.user) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  const handleInputChange = (e: any) => {
    if (allCompanies) {
      const inputValue = e.target.value.toLowerCase();
      const filteredItems = (allCompanies as IClientCompanies[]).filter((item) =>
        item.name.toLowerCase().includes(inputValue) ||
        item.cnpj.toString().includes(inputValue)
      );
      setFilteredCompanies(filteredItems);
    }
  };

  const setProductsInUse = async () => {
    const { status, response } = await getClientCertificates(props.guid_client);
    if (status === 200) {
      setCertificatesInUse(response as ICertificate[]);
    }
  }

  const handleProductsChange = (slug: string) => {
    if (products?.includes(slug)) {
      setProducts(products.filter((item: string) => item !== slug));
    } else if (!products) {
      setProducts([slug]);
    } else {
      setProducts([...products, slug]);
    }
  }

  const areAllCertificatesSelected = () => {
    return certificatesInUse.length === products?.length;
  }

  const handleSelectAllCertificate = () => {
    if (areAllCertificatesSelected()) {
      setProducts([]);
    } else {
      setProducts(certificatesInUse.map((item: ICertificate) => item.slug));
    }
  }

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Permissões de acesso para: {props.user ? props.user.name ? props.user.name : props.user.email : null}</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={4} mb={4}>
              As permissões de acesso abaixo podem ser alteradas a qualquer momento. Apenas o administrador da empresa ou o dono da conta pode definir permissões dos usuários.

              {simpleErrorMessage ? (
                <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                  <Flex>{simpleErrorMessage}</Flex>
                </Flex>
              ) : null}
              {currentPermissions && Array.isArray(filteredCompanies) && loadedCurretPermission ? (
                <>
                  <Accordion allowToggle index={currentStep}>
                    <AccordionItem border="none">
                      <AccordionButton  bg="white" p={4} mt={4} fontSize="14px" onClick={() => { setCurrentStep(0) }}>
                        <Box as="span" flex='1' textAlign='left'  >
                          <Text fontSize="14px" fontFamily="Poppins-Medium">
                            Configurações de Acesso
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Flex justifyContent="space-between">
                          <Flex flexDirection="column" ml="15px" mt="15px">
                            <Flex>
                              <Image 
                                cursor={'default'}
                                height={4}
                                width={4}
                                src="/icons/offices.svg" 
                                resize="none"
                                mr="5px"
                              />
                              
                              <Text fontSize="14px" fontFamily="Poppins-Medium">
                                Selecionar empresas para acesso
                              </Text>
                            </Flex>

                            <Flex flexDirection="column" mt="15px">
                              <Flex  mb={4} flexDirection="column">
                                <Checkbox isChecked={checkboxAll === 1} onChange={() => {setCheckboxAll(1); setAccessAllCompanies(true)}} colorScheme='green'>
                                  <Flex>
                                    <Text fontSize="12px" mr="5px" fontFamily="Poppins-Medium">
                                      Acesso a todas as empresas
                                    </Text>
                                    <Tooltip label="Todas as novas empresas serão adicionadas automaticamente.">
                                      <Image 
                                        cursor={'default'}
                                        height={4}
                                        width={4}
                                        src="/icons/information-circle-blue.svg" 
                                        resize="none"
                                      />
                                    </Tooltip>
                                  </Flex>
                                </Checkbox>
                                <Checkbox mt="5px" isChecked={checkboxAll === 2} onChange={() => {setCheckboxAll(2); setAccessAllCompanies(false)}} colorScheme='green'>
                                  <Text fontSize="12px" fontFamily="Poppins-Medium">
                                    Sem acesso a empresas
                                  </Text>
                                </Checkbox>
                                <Checkbox mt="5px" isChecked={checkboxAll === 3} onChange={() => {setCheckboxAll(3); setAccessAllCompanies(false)}} colorScheme='green' >
                                  <Text fontSize="12px" fontFamily="Poppins-Medium">
                                    Selecionar empresas para acesso
                                  </Text>
                                </Checkbox>
                              </Flex>
                              {checkboxAll === 3 ? (
                                <>
                                  <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                                    <Link color="#4B4EFF" fontWeight="bold" onClick={() => setOpenSelectCompanies(true)}>
                                    {
                                      Array.isArray(accessCompanies) && accessCompanies.length > 0 
                                      ? accessCompanies.length > 1 
                                        ? `${accessCompanies.length} empresas selecionadas` 
                                        : `${accessCompanies.length} empresa selecionada` 
                                      : 'Selecione as Empresas'
                                    }
                                    </Link>
                                  </Flex>
                                  <SelectCompanies
                                    guid_client={props.guid_client}
                                    companies={filteredCompanies}
                                    open={openSelectCompanies}
                                    setOpen={setOpenSelectCompanies}
                                    selectedCompanies={accessCompanies as string[]}
                                    setSelectedCompanies={setAccessCompanies as React.Dispatch<React.SetStateAction<string[]>>}
                                    header="Selecionar Empresas"
                                    description=""
                                    buttonText="Salvar alteração"
                                    isLoading={!loadedCurretPermission}
                                  />
                                </>
                              ) : null}
                            </Flex>
                          </Flex>

                          <Flex flexDirection="column" ml="15px" mt="15px">
                            <Flex>
                              <Image 
                                cursor={'default'}
                                height={4}
                                width={4}
                                src="/icons/users-black.svg" 
                                resize="none"
                                mr="5px"
                              />
                              
                              <Text fontSize="14px" fontFamily="Poppins-Medium">
                                Selecionar pessoas físicas para acesso
                              </Text>
                            </Flex>

                            <Flex flexDirection="column" mt="15px">
                              <Flex  mb={4} flexDirection="column">
                                <Checkbox isChecked={checkboxPersonaAll === 1} onChange={() => {setCheckboxPersonaAll(1); setAccessAllPersona(true)}} colorScheme='green'>
                                  <Flex>
                                    <Text fontSize="12px" mr="5px" fontFamily="Poppins-Medium">
                                      Acesso a todas as pessoas físicas
                                    </Text>
                                    <Tooltip label="Todas as novas pessoas serão adicionadas automaticamente.">
                                      <Image 
                                        cursor={'default'}
                                        height={4}
                                        width={4}
                                        src="/icons/information-circle-blue.svg" 
                                        resize="none"
                                      />
                                    </Tooltip>
                                  </Flex>
                                </Checkbox>
                                <Checkbox mt="5px" isChecked={checkboxPersonaAll === 2} onChange={() => {setCheckboxPersonaAll(2); setAccessAllPersona(false)}} colorScheme='green'>
                                  <Text fontSize="12px" fontFamily="Poppins-Medium">
                                    Sem acesso a pessoas físicas
                                  </Text>
                                </Checkbox>
                                <Checkbox mt="5px" isChecked={checkboxPersonaAll === 3} onChange={() => {setCheckboxPersonaAll(3); setAccessAllPersona(false)}} colorScheme='green' >
                                  <Text fontSize="12px" fontFamily="Poppins-Medium">
                                    Selecionar pessoas físicas para acesso
                                  </Text>
                                </Checkbox>
                              </Flex>
                              {checkboxPersonaAll === 3 ? (
                                <>
                                  <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                                    <Link color="#4B4EFF" fontWeight="bold" onClick={() => setOpenSelectPersonas(true)}>{accessPersona.length > 0 ? accessPersona.length > 1 ? `${accessPersona.length} pessoas selecionadas` : `${accessPersona.length} pessoa selecionada` : 'Selecione as Pessoas'}</Link>
                                  </Flex>
                                  <SelectPersonas
                                    guid_client={props.guid_client}
                                    personas={personas}
                                    open={openSelectPersonas}
                                    setOpen={setOpenSelectPersonas}
                                    selectedPersonas={accessPersona as string[]}
                                    setSelectedPersonas={setAccessPersona as React.Dispatch<React.SetStateAction<string[]>>}
                                    header="Selecionar Pessoas"
                                    description=""
                                    buttonText="Salvar alteração"
                                    isLoading={!loadedCurretPermission}
                                  />
                                </>
                              ) : null}
                            </Flex>
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem border="none">
                      <AccordionButton  bg="white" p={4} mt={4} fontSize="14px" onClick={() => setCurrentStep(1)}>
                        <Box as="span" flex='1' textAlign='left'  >
                          <Text fontSize="14px" fontFamily="Poppins-Medium">
                            Configurações de Produtos
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <TableContainer>
                          <Table variant="simple" fontSize="12px" fontFamily="Poppins-Medium">
                            <Thead>
                              <Tr>
                                <Th textTransform="none" fontFamily="Poppins-Medium">
                                  {props.validation.clients.find((_x) => _x.guid_client === props.guid_client)?.name}
                                </Th>
                                {canGrantAdmin() ? (<Th textAlign="center" width={30} textTransform="none" fontFamily="Poppins-Medium">Admin</Th>) : null}
                                <Th textAlign="center" width={30} textTransform="none" fontFamily="Poppins-Medium">Leitura</Th>
                                <Th textAlign="center" width={30} textTransform="none" fontFamily="Poppins-Medium">Escrita</Th>
                                <Th textAlign="center" width={30} textTransform="none" fontFamily="Poppins-Medium">Remoção</Th>
                              </Tr>
                            </Thead>
                            <Tbody alignItems="center" justifyContent="center">
                              {allPermissions.map((_permission, _index) => {
                                if (_permission.product && !validate?.clients.find((_x) => _x.guid_client === props.guid_client)?.products[_permission.product]) {
                                  return null;
                                }

                                return (
                                  <Tr key={_index}>
                                    <Td fontWeight="bold">
                                      <Tooltip label={_permission.description}>
                                        {_permission.name}
                                      </Tooltip>
                                    </Td>
                                    {canGrantAdmin() ? (<Td textAlign="center">{checkboxLine('admin', _permission)}</Td>) : null}
                                    <Td textAlign="center">{checkboxLine('read', _permission)}</Td>
                                    <Td textAlign="center">{checkboxLine('write', _permission)}</Td>
                                    <Td textAlign="center">{checkboxLine('delete', _permission)}</Td>
                                  </Tr>
                                )
                              }
                              )}
                            </Tbody>
                          </Table>
                        </TableContainer>

                        <Accordion allowToggle index={0} display={watch().permission?.includes('cnd.read') ? 'block': 'none'}>
                          <AccordionItem isDisabled={!watch().permission?.includes('cnd.read')}>
                            <h2>
                              <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'>
                                  <Flex direction="column">
                                    <Text fontSize="12px">Permissionamento por tipos de certidão</Text>
                                    <Text fontSize="11px" opacity={0.5}>
                                      Selecione abaixo as certidões que o usuário terá acesso.
                                    </Text>
                                  </Flex>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              <Checkbox mb={4}
                                colorScheme="green"
                                onChange={() => handleSelectAllCertificate()}
                                isChecked={areAllCertificatesSelected()}
                              >
                                <Text fontSize={12}>Selecionar tudo</Text>
                              </Checkbox>
                              <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                                {certificatesInUse.map((_el, _id) => (
                                  <GridItem>
                                    <Flex maxW="150px" minW="100px" minH="15px" key={_id}>
                                      <Checkbox
                                        value={`${_el.slug}`}
                                        isChecked={(products && products.includes(_el.slug)) || watch().permission?.includes(`org.admin`) || watch().permission?.includes(`org.owner`)}
                                        onChange={() => handleProductsChange(_el.slug)}
                                        colorScheme="green"
                                        isDisabled={(watch().permission?.includes(`org.admin`) || watch().permission?.includes(`org.owner`)) || !watch().permission?.includes(`cnd.read`)}
                                      >
                                        <Text fontSize={12}>{_el.slug.toUpperCase()}</Text>
                                      </Checkbox>
                                    </Flex>
                                  </GridItem>
                                ))}
                              </Grid>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </>
              ) : (
                <LoadingComponent />
              )}

              <Flex gap={2} flexGrow={1} justifyContent="center">
                <FormButton width="70%" onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>Salvar permissões</FormButton>
              </Flex>

            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}