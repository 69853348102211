import { Flex, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { TaxlyInputPassword } from "../../../../components/Input";
import { FormButton } from "../../../../components/login/button.component";
import "react-placeholder/lib/reactPlaceholder.css";
import { putChangePassword } from "../../../../_services/auth.service";
import useLoginFormStore from "../../../../store/useLoginFormStore";

interface IChangePasswordProps {
  setSelectedContent: React.Dispatch<React.SetStateAction<string>>;
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
  submit: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: boolean;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IChangePasswordForm {
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string,
}

export const ChangePassword = (props: IChangePasswordProps) => {
  const toast = useToast();
  const { validate } = useLoginFormStore();

  const YupCompanyChangeForm = Yup.object().shape({
    currentPassword: Yup.string().optional(),
    newPassword: Yup.string().required('Nova senha é obrigatória').min(6, 'A senha precisa ter pelo menos 6 caracteres'),
    confirmNewPassword: Yup.string().required('Confirmação de nova senha é obrigatória').oneOf([Yup.ref('newPassword')], 'As senhas precisam ser iguais')
  });

  const { register, handleSubmit, watch, formState: { errors } } = useForm<IChangePasswordForm>({ resolver: yupResolver(YupCompanyChangeForm) });

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const handleFormInfo = async (data: IChangePasswordForm) => {
    if(!validate) {
      return;
    }

    setSubmitDisabled(true);

    const { status, response } = await putChangePassword({
      oldPassword: data.currentPassword ?? null,
      newPassword: data.newPassword ?? null,
      email: validate.email
    });

    if (status === 200) {
      toast({
        title: 'Senha Atualizada',
        description: 'Sua senha foi atualizada com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });

      props.setSubmit(false);
      props.hasChangeHook(false);
    } else if (status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar senha',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    } else {
      toast({
        title: 'Erro ao atualizar senha',
        description: 'Ocorreu um erro ao atualizar a senha',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
      props.setSubmitError(true);
    }

    setSubmitDisabled(false);
  }
  
  useEffect(() => {
    props.hasChangeHook(false);

    watch((value) => {
      if (
        value.currentPassword
        || value.newPassword
        || value.confirmNewPassword
      ) {
        props.hasChangeHook(true);
      } else {
        props.hasChangeHook(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);  

  useEffect(() => {
    if(props.submit) {
      handleSubmit(
        handleFormInfo, 
        (errors) => {
          props.setSubmitError(true);
        }
      )();
    }
  }, [props.submit]);

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Alterar senha</Text>
      </Flex>

      <Flex width="80%" flexDirection="column" gap="24px">
        <Flex w="100%" flexDirection="column">
          <TaxlyInputPassword
            w="100%"
            placeholder="Digite sua senha atual"
            label={'Senha Atual'}
            {...register("currentPassword")}
          />
          {errors.currentPassword && <Text color="red.500" fontSize="12px">{errors.currentPassword.message}</Text>}
        </Flex>

        <Flex gap="15px">
          <Flex w="50%" flexDirection="column">
            <TaxlyInputPassword
              w="100%"
              placeholder="Digite a nova senha"
              label={'Nova senha'}
              {...register("newPassword")}
            />
            {errors.newPassword && <Text color="red.500" fontSize="12px">{errors.newPassword.message}</Text>}
          </Flex>

          <Flex w="50%" flexDirection="column">
            <TaxlyInputPassword
              w="100%"
              placeholder="Confirme a nova senha"
              label={'Confirme sua nova senha'}
              {...register("confirmNewPassword")}
            />
            {errors.confirmNewPassword && <Text color="red.500" fontSize="12px">{errors.confirmNewPassword.message}</Text>}
          </Flex>
        </Flex>

        <Text color="#718096" fontFamily="Poppins-Medium" fontSize="12px">
          A senha precisa ter pelo menos 6 caracteres, incluindo letras maiúsculas, minúsculas, números e símbolos.
        </Text>

        <hr />

        <Flex width="100%" justifyContent="flex-start">
          <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>
            Salvar alterações
          </FormButton>
        </Flex>
      </Flex>
    </Flex>
  );
};
