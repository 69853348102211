import { ComponentStyleConfig } from "@chakra-ui/react";

export const Input: ComponentStyleConfig = {
  variants: {
    input: {
      variant: "flushed",
      borderColor: "primary.60",
      color: "primary.10",
      borderBottomWidth: "1px",
      p: "0",
      h: "32px",
      fontSize: "14px",
    },
  },
};
