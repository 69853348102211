import { IDataMeta } from "../../components/Table/table.interface";
import { ICNDDataResponse } from "./cnd.interface";

export interface IGetBulkUpdateResponse {
  guid_bulk_update: string;
  guid_client: string;
  type: string;
  items: IBulkItemCertificateResponse;
  isPending?: boolean;
  can_retry?: boolean;
}

export interface IBulkItemCertificate {
  guid_bulk_item_certificate: string;
  guid_bulk_update: string;
  cnd?: ICNDDataResponse;
  status: BulkUpdateItemCertificateStatus;
  totalItems: number;
}

export interface IBulkItemCertificateResponse {
  data: IBulkItemCertificate[];
  meta: IDataMeta;
}

export enum BulkUpdateItemCertificateStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED', // When do not search the certificate
  ERROR = 'ERROR', // When search the certificate and return an error
  SUCCESS = 'SUCCESS',
}

export interface IFilterListBulkUpdate {
  page: number;
  row_by_page: number;

  order_field?: string;
  order_direction?: string;
}
