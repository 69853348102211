import { LinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Image,
  useDisclosure,
  Alert,
  AlertIcon,
  useToast,
  Select,
  Tooltip,
  Button,
  Link,
  InputGroup
} from '@chakra-ui/react'
import moment from "moment";
import { useEffect, useState } from "react";
import {
  EDisplay,
  IObligationResponseCustom,
  IScheduleData,
  IScheduleObligationData,
  TJurisdiction
} from '../interfaces/IObligation';
import { cutText } from "../utils/kanban.functions";
import { getScheduleInfo, patchSchedule, deleteSchedule } from "../_services/kanban.service";
import { DeleteModal } from "./Automation/DeleteModal";
import { checkCollaboratorPermission } from "../../Users/utils/checkPermission";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { IResponse } from "../../../../_services/interface/user.interface";
import { SelectCompanies } from "../../../../modal/SelectCompanies";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { getClientCompanies } from "../../../../_services/company.service";
import { TaxlyInput } from '../../../../components/Input';
import { BusinessDay } from '../classes/BusinessDay';
import { IData, IDataMeta } from "../../../../components/Table/table.interface";
import { SelectCollaboratorModal } from '../../Users/modais/SelectCollaboratorModal';
import SelectWithIcon from '../../../../components/SelectWithIcon';
import { priorityOptions } from './Task/Util/UEditTask';
import DatePickerWithOptions from './Task/DatePickerWithOptions';
import { EFrequencyType } from '../../../../_services/enum/irs.enum';
import { editScheduleCardStructure } from "../../../../_services/irs.service";

interface IEditCardScheduleModalProps {
  guid_client: string,
  guid_schedule: string,
  flushHook: React.Dispatch<React.SetStateAction<boolean>>,
  display?: EDisplay,
  ruleName?: string,
  icon?: boolean,
  openModal?: boolean,
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>,
}

export const EditCardScheduleModal = (props: IEditCardScheduleModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [ isSubmitDisabled, setSubmitDisabled ] = useState<boolean>(false);
  const [scheduleData, setScheduleData] = useState<IScheduleData|undefined>();
  const [ruleName, setRuleName] = useState<string>("");
  const [description, setDescription] = useState<string | number | readonly string[] | undefined>("");
  const [companyList, setCompanyList] = useState<string[]>([]);
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>("");
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const { validate } = useLoginFormStore();
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  const [openSelectCompanies, setOpenSelectCompanies] = useState<boolean>(false);
  const [isLoadingCompany, setLoadingCompany] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState(priorityOptions[0]);
  const [openSelectCollaborator, setOpenSelectCollaborator] = useState<boolean>(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState<string[]>([]);
  const [dueDate, setDueDate] = useState<Date|undefined>();
  const [frequencyType, setFrequencyType] = useState<EFrequencyType|null>(null);
  const [frequencyUnit, setFrequencyUnit] = useState<number|null>(null);


  const getScheduleData = async () => {
    setLoadingState(true);
    const { status, response } = await getScheduleInfo(props.guid_client, props.guid_schedule);
    if (status === 200) {
      const schedule: IScheduleData = response as IScheduleData;

      setScheduleData(schedule);
      setCompanyList(schedule.companies);

      setIsDisabled(!schedule.isActive);

      if(schedule.cardStructure) {
        const priority = schedule.cardStructure?.priority;
        const priorityOption = priorityOptions.find((x) => x.value === priority);

        if(priorityOption) {
          setSelectedOption(priorityOption);
        }

        const collaborators: string[] = schedule.cardStructure?.responsibles.map((collaborator) => collaborator.guid_collaborator);
        setSelectedCollaborators(collaborators);

        setFrequencyType(schedule.cardStructure.frequencyType)
        setFrequencyUnit(schedule.cardStructure.frequencyUnit)

        setRuleName(schedule.cardStructure.name);
        setDescription(schedule.cardStructure.description === null ? '' : schedule.cardStructure.description);
      }
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar obter as informações desta automação',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingState(false);
  }

  const loadCompanies = async () => {
    setLoadingCompany(true);
    const { status, response } = await getClientCompanies(props.guid_client, true);
    if (status === 200) {
      setAllCompanies(response as IClientCompanies[]);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar obter as informações dos usuários',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingCompany(false);
  }

  useEffect(() => {
    if (isOpen) {
      getScheduleData();
      loadCompanies();
    }
  }, [isOpen]);

  const validateFields = (): boolean => {
    if(companyList.length === 0) {
      toast({
        title: 'Sem empresas selecionadas',
        description: 'Você pelo menos precisa ter uma empresa selecionada para criar um agendamento.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(selectedCollaborators.length === 0) {
      toast({
        title: 'Sem responsáveis selecionadas',
        description: 'Você pelo menos precisa ter um responsável selecionadaopara criar um agendamento.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    return true;
  }

  const EditSchedule = async () => {
    setSubmitDisabled(true);
    if(!validateFields() || !scheduleData?.cardStructure?.guid_card_structure) {
      setSubmitDisabled(false);
      return false;
    }

    const frequencyTypeToSend = frequencyType ? (frequencyType as EFrequencyType) : undefined;
    const frequencyUnitToSend = frequencyUnit ? frequencyUnit : undefined;

    let companies =  scheduleData.companies.map((guid_company) => ({guid_company: guid_company}));
    if(scheduleData.cardStructure.type === 'CERTIFICATE_COMPANY' && scheduleData.certificates) {
      companies = scheduleData.certificates.map((certificate) => ({
        guid_company: certificate.guid_company,
        guidCnd: certificate.guidCnd,
        certificateType: certificate.certificateType,
        certificateSlug: certificate.certificateSlug,
        certificateState: certificate.certificateState,
        certificateCity: certificate.certificateCity,
      }))
    }

    const { status } = await editScheduleCardStructure(props.guid_client, scheduleData.cardStructure.guid_card_structure, {
      name: ruleName,
      description: !description ? '' : String(description),
      hasRecurrency: frequencyTypeToSend && frequencyUnitToSend ? true : false,
      frequencyType: frequencyTypeToSend,
      frequencyUnit: frequencyUnitToSend,
      priority: selectedOption.value,
      responsibles: selectedCollaborators,
      companies: companies,
      cardStructureType: scheduleData.cardStructure.type
    });

    if (status === 200) {
      toast({
        title: 'Automação Editada com sucesso',
        description: 'Automação Editado com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      props.flushHook(true);
      onClose();
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar editar a automação',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }

    setSubmitDisabled(false);
  }

  const removeCard = async () => {
    setSubmitDisabled(true);
    setSimpleErrorMessage('');

    const { status } = await deleteSchedule(props.guid_client, props.guid_schedule);
    if (status === 200) {
      toast({
        title: 'Automação Removida com sucesso',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      props.flushHook(true);
      onClose();
    } else {
      toast({
        title: 'Ocorreu um erro ao tentar remover a automação',
        description: 'Ocorreu um erro ao tentar remover a automação, por favor, tente mais tarde.',
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
  }

  const colorText = () => {
    return props.icon ? "" : "#4B4EFF";
  }

  useEffect(() => {
    if (props.openModal && props.openModalHook) {
      setRuleName('');
      setDescription('');
      setScheduleData(undefined);
      setSelectedOption(priorityOptions[0]);
      setSelectedCollaborators([]);
      setDueDate(undefined);
      setFrequencyType(null);
      setFrequencyUnit(null);

      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  return (
    <>
      {props.display && props.display === EDisplay.buttonEdit ? (
        <Flex width="26px" height="26px" resize="none">
          <Image src="../icons/edit.svg" onClick={onOpen} _hover={{ cursor: "pointer" }} />
        </Flex>
      ) : null}

      {props.display && props.display === EDisplay.ruleName && props.icon ? (
        <Flex alignItems="center">
          <Flex backgroundColor="#e2e8f0" alignItems="center" justifyContent="center" p="2px 8px" fontSize="10px" textTransform="uppercase" fontWeight="bold" borderRadius={5}>
              <LinkIcon color="#4B4EFF" />
            <Tooltip label={props.ruleName}>
              <Text cursor="pointer" marginLeft="5px" color={colorText()} textAlign="left" onClick={onOpen}>{cutText(props.ruleName ? props.ruleName : '', 20)}</Text>
            </Tooltip>
          </Flex>
        </Flex>
      ) : null}

      {props.display && props.display === EDisplay.ruleName && !props.icon ? (
        <Flex alignItems="center">
          <Tooltip label={props.ruleName}>
            <Text cursor="pointer" marginLeft="5px" color="#4B4EFF" textAlign="left" onClick={onOpen}>{props.ruleName}</Text>
          </Tooltip>
        </Flex>
      ) : null}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>

          <ModalHeader flexDirection="row" fontFamily="Poppins-Medium" fontWeight={500}  w="95%">
            <Flex>
              Editar regra de automação &nbsp;

              {
                checkCollaboratorPermission(validate as IResponse, props.guid_client, "kanban.delete") ? (
                  <DeleteModal callback={removeCard} title="Deletar Automação"/>
                ): null
              }
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>

            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Alert
                status="warning"
                backgroundColor="#FEF9C3"
                color="#171923"
                border="1px solid #EAB308"
                fontSize="14px"
                borderRadius="6px"
                fontFamily="Poppins-Medium"
                boxShadow="0px 4px 4px 0px #00000040"
              >
                <Image src="/icons/warning.svg" mr={3} />
                As informações alteradas desta regras, quando salvas, serão aplicadas apenas no próxima automação.
              </Alert>

              {simpleErrorMessage ? (
                <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                  <Flex>{simpleErrorMessage}</Flex>
                </Flex>
              ) : null}

              <Flex flexDirection="row" gap={4} >
                <TaxlyInput
                  placeholder="Nome da automação"
                  label={'Nome da automação'}
                  labelCustomStyle={{ mb: 1, fontWeight: 500 }}
                  value={ruleName}
                  borderColor="gray.300"
                  isRequired={true}
                  onChange={(e: any) => setRuleName(e.target.value)}
                />
              </Flex>

              <Flex flexDirection="row" gap={4} >
                <TaxlyInput
                  placeholder="Digite uma descrição para os cards..."
                  label={'Descrição'}
                  labelCustomStyle={{ mb: 1, fontWeight: 500 }}
                  value={description}
                  borderColor="gray.300"
                  isRequired={false}
                  onChange={(e: any) => setDescription(e.target.value)}
                />
              </Flex>

              <Flex flexDirection="row" gap={4} >
                <Flex flexDirection="column" w="25%">
                  <label
                    htmlFor="dueDate"
                    style={{
                      fontSize: '12px',
                      flexDirection: 'column',
                      fontWeight: 500,
                      fontFamily: 'Poppins-Medium',
                      marginBottom: '5px',

                    }}
                  >
                    Data de Vencimento
                  </label>

                  <InputGroup
                    border="1px solid #CBD5E0"
                    borderRadius={6}
                  >
                    <Flex flexDirection="column" w="100%" cursor={'pointer'}>
                      <DatePickerWithOptions
                        selectedDay={dueDate}
                        setSelectedDay={setDueDate}
                        setFrequencyType={setFrequencyType}
                        frequencyType={frequencyType}
                        setFrequencyUnit={setFrequencyUnit}
                        frequencyUnit={frequencyUnit}
                        placeholder="Data de vencimento"
                        id="dueDate"
                        showOnlyFrequency={true}
                      />
                    </Flex>
                  </InputGroup>
                </Flex>

                <Flex w="25%" flexDirection={'column'}>
                  <SelectWithIcon
                    id="priority"
                    label="Prioridade"
                    options={priorityOptions}
                    selected={selectedOption}
                    setSelectedOptions={setSelectedOption}
                    customInputStyle={{ borderColor: "#CBD5E0" }}
                  />
                </Flex>

                <Flex w="25%" flexDirection={'column'}>
                  <label
                    htmlFor="collaborator"
                    onClick={() => setOpenSelectCollaborator(true)}
                    style={{
                      fontSize: '12px',
                      flexDirection: 'column',
                      fontWeight: 500,
                      fontFamily: 'Poppins-Medium',
                      marginBottom: '5px',
                    }}
                  >
                    Responsável
                  </label>

                  <InputGroup
                    border="1px solid var(--chakra-colors-chakra-border-color)"
                    borderRadius={6}
                    borderColor="#CBD5E0"
                  >
                    <Flex flexDirection="column" w="100%">
                      <Flex
                        fontSize="12px"
                        width="auto"
                        w={"100%"}
                        height="32px"
                        p="4px 8px"
                        justifyContent="flex-start"
                        alignItems="center"
                        cursor={'pointer'}
                        onClick={() => setOpenSelectCollaborator(true)}
                      >
                        <Image src="/icons/persona.svg"/>

                        <Link
                          ml="5px"
                          fontSize="12px"
                          fontFamily="Poppins-medium"
                          color="#4A5568"
                        >
                          {
                            selectedCollaborators.length > 0
                              ? selectedCollaborators.length > 1
                                ? `${selectedCollaborators.length} selecionados`
                                : `${selectedCollaborators.length} selecionado`
                              : 'Responsável'
                          }
                        </Link>
                      </Flex>
                    </Flex>
                  </InputGroup>
                </Flex>

                <Flex width="25%" flexDirection={'column'}>
                  {isLoadingCompany && !scheduleData ? (
                      <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                        Carregando Empresas
                      </Flex>
                    ) :
                    <>
                      <label
                        htmlFor="collaborator"
                        onClick={() => setOpenSelectCompanies(true)}
                        style={{
                          fontSize: '12px',
                          flexDirection: 'column',
                          fontWeight: 500,
                          fontFamily: 'Poppins-Medium',
                          marginBottom: '5px',
                        }}
                      >
                        Empresas
                      </label>

                      <Flex
                        border="1px solid"
                        fontSize="12px"
                        w={'100%'}
                        borderColor="gray.300"
                        borderRadius={5}
                        h="35px"
                        p={'4px'}
                      >
                        <Link
                          width="100%"
                          color="#4A5568"
                          fontWeight="500"
                          alignItems="center"
                          onClick={() => setOpenSelectCompanies(true)}
                        >
                          <Flex gap={2} pl={2.5} justifyContent={'start'} alignItems="center">
                            <Image src="/icons/company.svg" w="24px"/>

                            {
                              companyList.length > 0
                                ? companyList.length > 1
                                  ? `${companyList.length} empresas selecionadas`
                                  : `${companyList.length} empresa selecionada`
                                : 'Selecione a Empresa'
                            }
                          </Flex>
                        </Link>
                      </Flex>
                    </>
                  }

                  <SelectCompanies
                    guid_client={props.guid_client}
                    companies={allCompanies}
                    open={openSelectCompanies}
                    setOpen={setOpenSelectCompanies}
                    selectedCompanies={companyList}
                    setSelectedCompanies={setCompanyList}
                    header="Selecionar Empresas"
                    description=""
                    buttonText="Salvar alteração"
                    isLoading={isLoadingCompany}
                    allCompanies={true}
                  />
                </Flex>
              </Flex>

              <SelectCollaboratorModal
                isMulti={true}
                bigSelector={true}
                guid_client={props.guid_client}
                openModal={openSelectCollaborator}
                openModalHook={setOpenSelectCollaborator}
                callback={(collaborators: string[]) => setSelectedCollaborators(collaborators)}
                selected={selectedCollaborators}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              bgColor="white"
              color="#171923"
              _hover={{ bg: 'white', textDecoration: 'underline' }}
              onClick={onClose}
              mr={5}
            >
              Fechar
            </Button>

            <Button
              bgColor="#4B4EFF"
              color="white"
              disabled={isSubmitDisabled}
              height={37}
              _hover={{ bg: '#282be0' }}
              onClick={EditSchedule}
            >
              Salvar alteração
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}