import { useDisclosure, useToast, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, Link, Image, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { IKanbanFiles } from "../../../../_services/interface/kanban.interface"
import { deleteKanbanCardFile } from "../_services/kanban.service";

interface IDeleteFileModalProps {
  guid_client: string;
  file: IKanbanFiles;
  fetchData: () => void;
}

export const DeleteFileModal = (props: IDeleteFileModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);
  const toast = useToast();

  const yesOption = async () => {
    setButtonDisabled(true);
    const { status } = await deleteKanbanCardFile(props.guid_client, props.file.guid_file);
    if (status === 200) {
      toast({
        title: 'Arquivo foi removido com sucesso',
        description: `O Arquivo ${props.file.filename} foi removido com sucesso`,
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
    } else {
      toast({
        title: 'Falha ao remover o arquivo',
        description: `Ocorreu uma falha ao tentar remover o arquivo ${props.file.filename}. Tente novamente mais tarde`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }

    props.fetchData();
    setButtonDisabled(false);
    onClose();
  }

  return (
    <>
      <Tooltip label="Deletar">
        <Link color="red" onClick={onOpen}>
          <Image src="../icons/delete-card-file.svg" />
        </Link>
      </Tooltip>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Deletar Arquivo</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Text>
                Deseja remover o arquivo <strong>{props.file.filename}</strong>? Esta opção não pode ser desfeita.
              </Text>

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <FormButton onClick={yesOption} disabled={isButtonDisabled} width="50%">Sim</FormButton>
                <FormButton onClick={onClose} disabled={isButtonDisabled} width="50%">Não</FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}