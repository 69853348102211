import { useState, useRef, useEffect } from "react";
import { FieldErrorsImpl, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";
import { AppInput, Mask } from "../../../components/AppInput";
import { GetUserInformation } from "../../../utils/getUserInformation";
import { FormButton } from "../../../components/login/button.component";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormError, SimpleFormError } from "../../../components/login/formError.component";
import { postRegistration } from "../../../_services/user.service";
import { TLocationHandler } from "../../Login/_types/location.type";
import { IRegistrationInputs, IRegistrationResponse } from "../../../_services/interface/user.interface";
import { ValidationForm } from "../../Login/validation/ValidationForm";
import { IErrorResponse } from "../../../_services/interface/IDefaults";
import { validate } from "cnpj";
import { clearCnpj } from "../../dashboard/Companies/Company/util/cnpj";
import { useNavigate } from "react-router-dom";

interface SignupFormProps {
  locationHandler: TLocationHandler;
}

export const SignupForm = (props: SignupFormProps) => {
  GetUserInformation();
  const navigate = useNavigate();

  //Base form structure
  const YupLoginForm = Yup.object().shape({
    clientName: Yup.string().required('Preencha o nome da empresa'),
    companyName: Yup.string().required('Preencha a razão social da empresa'),
    companyCNPJ: Yup.string().required('Informe o CNPJ'),
    password: Yup.string()
      .required('Informe a senha')
      .min(6, 'Senha precisa ter pelo menos 6 characteres'),
    confirmPassword: Yup.string()
      .required('Confirme a senha')
      .oneOf([Yup.ref("password"), null], "As senhas precisam ser iguais para prosseguir com o cadastro"),
    name: Yup.string().required('O Nome do colaborador precisa ser informado'),
    email: Yup.string().required('O Email do colaborador precisa ser informado').email('É necessário informar um email válido'),
  });

  //Generate o resolver
  const resolverForm = { resolver: yupResolver(YupLoginForm) };

  const captchaRef = useRef<ReCAPTCHA>(null);
  const [errorMessage, setErrorMessage] = useState<FieldErrorsImpl>();
  const [smplErrorMessage, setSmplErrorMessage] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [allFieldsFilled, setFieldsState] = useState<boolean>(false);
  const [captchaChallenge, setCaptchaChallenge] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState<string>('#e8e8e8');
  const [passwordStrengthMeter, setPasswordStrengthMeter] = useState<number>(0);
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

  const { formState: { errors }, handleSubmit, register, reset, watch } = useForm<IRegistrationInputs>(resolverForm);

  useEffect(() => {
    if (errors) {
      setErrorMessage(errors as any);
    }
  }, [errors]);

  function onChangeCaptcha(value: any) {
    if (value) {
      setCaptchaChallenge(true);
    } else {
      setCaptchaChallenge(false);
    }
  }

  //This useEffect exists to watch all the values on the form
  useEffect(() => {
    watch((value) => {
      if (Object.values(value).filter(_x => _x !== '').length === Object.values(value).length) {
        setFieldsState(true);
      } else {
        setFieldsState(false);
      }

      if (value.password) {
        if(strongRegex.test(value.password)) {
          setPasswordStrength("#0F9D58");
          setPasswordStrengthMeter(2);
        } else if(mediumRegex.test(value.password)) {
          setPasswordStrength("#F4B400");
          setPasswordStrengthMeter(1);
        } else {
          setPasswordStrength("#DB4437");
          setPasswordStrengthMeter(0);
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (allFieldsFilled && captchaChallenge) {
      //All fields are filled and captcha challenge completed
      //so the system will reveal the Button
      setSubmitDisabled(false);
    } else {
      //If nothing meets the system will keep the button disabled
      setSubmitDisabled(true);
    }
  }, [allFieldsFilled, captchaChallenge]);

  const handleFormInfo = async (data: IRegistrationInputs) => {
    //Disable everything
    setSubmitDisabled(true);
    //Validate the cnpj
    if (passwordStrengthMeter === 2) {
      if (validate(data.companyCNPJ)) {
        //Return the ip and information of the user
        const userdata = await GetUserInformation();
        //Send the registration
        const { status, response } = await postRegistration({
          name: data.name,
          companyCNPJ: clearCnpj(data.companyCNPJ),
          password: data.password,
          clientName: data.clientName,
          companyName: data.companyName,
          email: data.email,
          confirmPassword: data.confirmPassword,
          ...userdata,
        });

        if (status === 200) {
          //Registration was ok, so the system sends to the validation form so the user can finish
          props.locationHandler(
            <ValidationForm 
              locationHandler={props.locationHandler} 
              email={(response as IRegistrationResponse).email} 
              userid={(response as IRegistrationResponse).user} 
            />
          );
        } else {
          //Something happened and failed the request
          reset({ password: '', confirmPassword: '', companyCNPJ: '' });
          //Print Error
          const err = (response as IErrorResponse).message;

          switch (err) {
            case 'SIGNUP_VALIDATION_MAIL_FAILED':
              setSmplErrorMessage('Falha ao registrar um novo usuário, email de validação não pode ser enviado');
              break;
            case 'SIGNUP_MAIL_ALREADY_EXIST':
              setSmplErrorMessage('Falha ao registrar um novo usuário, email já cadastrado.');
              break;
            case 'SIGNUP_COMPANY_ALERADY_EXIST':
              setSmplErrorMessage('Falha ao registrar um novo usuário, CNPJ já cadastrado.');
              break;
            case 'SOMETHING_WENT_WRONG':
              setSmplErrorMessage('Falha ao registrar um novo usuário');
              break;
            default:
              break;
          }
        }
      } else {
        setSmplErrorMessage('Por favor, inclua um CNPJ válido.');
        reset({companyCNPJ: ''});
      }
    } else {
      setSmplErrorMessage('Por favor, utilize um password forte.');
      reset({password: '', confirmPassword: ''});
      setPasswordStrength("#DB4437");
      setPasswordStrengthMeter(0);
    }
    //setSubmitDisabled(false);
  };

  return (
    <Flex fontFamily="Poppins-Medium" display="flex" alignItems="center" mt="0px" w={396}>
      <Flex direction="column" w="100%">
        <Text fontSize="32px" textAlign="center">Cadastre-se</Text>
        <Box marginTop={18}>
          <form>
            <Flex direction="column" alignItems="stretch">

              {errorMessage ? (<FormError message={errorMessage} />) : null}
              {smplErrorMessage ? (<SimpleFormError msg={smplErrorMessage} />) : null}

              <Flex direction="column" gap={2}>

                <AppInput placeholder="Nome da Empresa" type="text" {...register("clientName", { required: true })} />
                <AppInput placeholder="Razão Social" type="text" {...register("companyName", { required: true })} />

                <Mask mask="99.999.999/9999-99" placeholder="CNPJ" type="text" {...register("companyCNPJ", { required: true })}/>
                
                <Box borderBottom="1px solid #DBDBDB" />
                <AppInput placeholder="Nome do Usuário" type="text" {...register("name", { required: true })} />
                <AppInput placeholder="Email do Usuário" type="email" {...register("email", { required: true })} />

                <Flex flexDirection="row" gap={2} justifyContent="space-between">
                  <AppInput placeholder="Senha" type="password" {...register("password", { required: true })} />
                  <AppInput placeholder="Confirmação de Senha" type="password" {...register("confirmPassword", { required: true })} />
                </Flex>
                <Flex flexDirection="column" gap={2} justifyContent="space-between">
                  <Flex borderRadius={5} bg={passwordStrength} h={2}></Flex>
                  <Text fontSize={11} opacity={0.5}>A senha precisa ter pelo menos 6 caracteres dentre eles letras maísculas, minusculas, números e simbolos.</Text>
                </Flex>
              </Flex>
              
              <Flex direction="row" justifyContent="center" mt="18" mb="18">
                <ReCAPTCHA
                  sitekey="6LelrG4iAAAAAGZUt_aPuZp1cInj7M1f_pGD98fM"
                  ref={captchaRef}
                  onChange={onChangeCaptcha}
                />
              </Flex>

              <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>Cadastrar</FormButton>
              <Flex alignItems="center" fontSize="12px" marginTop="10px" justifyContent="center">
                <Text m="0">
                  Possui uma conta?
                  <Link onClick={() => navigate('/login')} _hover={{ color: "#8789FF", cursor: "pointer" }} ml={2} color="#4B4EFF">
                    Faça seu Login
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};
