import { Flex, Image, Text } from "@chakra-ui/react";
import { RectShape } from "react-placeholder/lib/placeholders";

interface IBlockSwitchProps {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  name: string;
  icon: string;
  isLoading?: boolean;
  minWidth?: string;
}

export const BlockSwitch = (props: IBlockSwitchProps) => {

  const image = props.isActive ? "../icons/switch-blue.svg" : "../icons/switch-gray.svg";

  return (<>
    <Flex width="fit-content">
      <Flex
        border="1px solid #D0D5DD"
        borderRadius="12px"
        padding="16px"
        justifyContent="space-between"
        display="inline-flex"
        width="100%"
        minWidth={props.minWidth}
      >
        <Flex
          alignItems={'center'}
        >
          {
            props.isLoading ? (
              <RectShape color='#e0e0e0' style={{width: '32px', height: '32px'}}/>
            ) : (
              <Image 
                cursor="pointer"
                height="32px"
                width="32px"
                src={props.icon}
                resize="none"
                marginLeft="2px"
              />
            )
          }

          {
            props.isLoading ? (
              <RectShape color='#e0e0e0' style={{width: '50px', height: '23px', marginLeft: "8px"}}/>
            ) : (
              <Text
                fontSize="14px"
                fontFamily="Poppins-Medium"
                fontWeight="500"
                marginLeft="8px"
              >
                {props.name}
              </Text>
            )
          }
        </Flex>

        <Flex marginLeft="15px">
          <Flex
            display="flex"
            justifyContent="start"
            cursor="pointer"
            onClick={() => props.setIsActive(!props.isActive)}
            marginTop={'1.5%'}
          >
            {
              props.isLoading ? (
                <Flex alignItems="center">
                  <RectShape color='#e0e0e0' style={{width: '50px', height: '23px', borderRadius: "50px"}}/>
                </Flex>
              ) : (
                <Image w="50px" src={image} />
              )
            }
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </>);
}
