import { ApiCall, ApiAuth } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { SendBulkResponse, SendBulkRequest } from "./interface/sendmail.interface";

export const sendBulkEmail = async (
  data: SendBulkRequest
): Promise<TPremiseResponse<SendBulkResponse>> => {
  const formData = new FormData();
  formData.append('mailtype', data.mailtype);
  formData.append('list', data.list);
  formData.append('guid_client', data.guid_client);
  formData.append('data', JSON.stringify(data.data));
  const links = data.links ? data.links : '';
  formData.append('links', links);

  data.attachments.forEach((attachment, index) => {
    formData.append(`attachments`, attachment);
  });

  try {
    return await new ApiCall(
      '/mail/bulk/send',
      ApiAuth()
    ).post<SendBulkResponse, FormData>(formData);
  } catch (error) {
    console.error('Erro ao enviar e-mail em massa:', error);
    throw error;
  }
};