import React from "react";
import {
  Box,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import { TLocationHandler } from "../_types/location.type";
import { FormButton } from "../../../components/login/button.component";

interface IAccountBlockProps {
  locationHandler: TLocationHandler;
  email: string;
}

export const AccountBlockForm = (props: IAccountBlockProps) => {
  return (
    <Flex fontFamily="Poppins-Medium" display="flex" alignItems="center" mt="0px" w={396}>
      <Flex direction="column" w="100%">
        <Text fontSize="32px" textAlign="center">Cadastre-se</Text>
        <Box alignSelf="center" mt={12}>
          <Text fontSize="12px" textAlign="center">
            Depois de muitas tentativas de login falhas, por medida de segurança a conta associada ao email <strong>{props.email}</strong> foi inativada.
          </Text>
          <Text fontSize="12px" textAlign="center" mt={6}>
            Para solicitar o desbloqueio de sua conta, entre em contato com o administrador de sua organização
          </Text>
          <Flex direction="column" mt={12}>
            <FormButton onClick={() => props.locationHandler(undefined)}>Retornar</FormButton>
          </Flex>
          <Text fontSize="11px" mt={6} opacity={0.5}>
            <strong>Não possui administrador? </strong>Entre em contato com o nosso 
            <Link textDecoration="none" color="#4B4EFF" href="#">Help Desk</Link> para ajudarmos a recuperar a sua conta.
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
