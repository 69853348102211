import { Flex, Text, Tooltip } from "@chakra-ui/react"

interface IStateProps {
  baseColor: string;
  state: string;
  name?: string;
  total: number;
  subsidiary: number;
  holding: number;
}

export const CountryState = (props: IStateProps) => {
  let stateSelected = null;

  if (props.state === 'MA') {
     
    stateSelected = (
      <g id="MA" transform="translate(-3550.8686,-814.85365)">
      <path id="path24" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4148.97,1003.23l1.08-1.48l1.9-2.28l0.3-0.34l1.42-1.59
        l0.08-0.04l1,0.42l1.27-0.3l0.21-0.05l0.08-0.05l1.59-0.72l2.8,0.25l2.12,0.29l-0.19-1.52l-0.3-0.78l-0.16-0.25l-0.04-0.23
        l0.04-0.09l0.17-0.23l0.08-0.05l0.53-0.12h0.12l1.67-0.04l0.44,0.02l0.12,0.02l0.66,0.09l2.07,0.42l0.09,0.04l0.26,0.13l0.78,0.42
        l1.44,0.53l0.61,0.19l0.21,0.06l0.12,0.01l0.47-0.04l0.12,0.02l0.42,0.09l0.12,0.04l0.09,0.04l0.72,0.53l1.57,1.04l0.09,0.04
        l0.21,0.04h0.13l0.55-0.09l0.93,0.09l0.38,0.29l0.05,0.08l0.23,0.29l0.87,0.95l2.32,1.63l0.83,0.34l0.12,0.02l1.88,0.34l0.12,0.02
        l0.23-0.02l0.12-0.02l0.38-0.16l0.4-0.12l0.23-0.04l0.12,0.02l0.17,0.09l0.05,0.08l0.51,0.76l0.05,0.08l-0.05,0.61l0.04-0.09
        l0.25-0.38l0.08-0.06l0.19-0.08l0.09-0.02l1.76-0.05l0.93,0.32l0.65,0.21l1.37,0.3l0.12-0.02l0.05-0.05l0.17-0.36l0.02-0.12
        l0.05-0.34v-0.25l-0.04-0.09l-0.08-0.05l-0.7-0.42l-0.09-0.04l0.59-0.19l1.5-0.25l0.47-0.02l0.29,0.04l0.05,0.08v0.13l-0.02,0.12
        l-0.04,0.09l-0.16,0.25l-0.17,0.26l-0.36,1l-0.04,0.36l0.01,0.23l0.12,0.4l0.16,0.36l0.26,0.25l0.17,0.08l0.17,0.09l0.15,0.12
        l0.05,0.08l0.04,0.09l0.02,0.12l-0.06,0.34l-0.47,1.27l-0.09,0.17l-2.12,2.71l-0.76,0.79l-4.15,4.02l-0.26,0.12l-1.03,0.23
        l-2.9,0.29l-0.99-0.19l-2.24,2.49l-0.66,0.78l-0.66,0.91l-0.08,0.32l0.15,0.49v0.26l-0.01,1.06l-0.02,0.12l-0.96,2.04l-0.09,0.17
        l-0.17,0.21l-1.06,0.99l-1.03,0.93l-0.05,0.08l-0.05,0.21l-0.05,0.47l-0.05,0.23l-0.08,0.19l-0.66,1.27l-0.13,0.13l-0.08,0.05
        l-0.57,0.05l-0.16,0.08l-0.61,0.57l-0.09,0.17l-0.13,0.29l-0.01,0.09l-0.04,0.26l0.02,0.61l0.08,0.66l0.01,0.12l0.32,0.85
        l0.43,0.47l0.23,0.02l0.21,0.05l0.17,0.09l0.43,0.36l0.04,0.05l0.43,1.44l0.02,0.12l-0.25,1.34l-0.09,0.43l-0.96,2.36l-0.44,1
        l-0.02,0.12v0.12l0.05,0.21l0.04,0.09l1.08,1.78l0.21,0.3l0.55,0.99l0.68,3.64l-0.12,2.92l-0.02,0.09l-0.19,0.55l-0.12,0.16
        l-4.86,5.16l-0.08,0.08l-0.08,0.17l-0.02,0.12l-0.13,2.84l0.13,2.39l0.04,0.36l0.15,0.82l0.42,1.74l0.02,0.12l0.05,0.08l0.51,0.74
        l0.05,0.08l1.55,1.55l0.57,0.36l0.25,0.16l0.19,0.08h0.12l0.21,0.05l0.17,0.09l0.15,0.12l0.23,0.17l0.13,0.23l0.13,0.49l0.15,1.03
        l-0.47,2.69l-0.36,0.53l-0.05,0.87l-0.08,0.55l-0.04,0.09l-0.57,1.69l-0.05,0.08l-0.44,0.49l-0.95,1.04l-0.08,0.05l-0.09,0.04
        l-0.46,0.08l-2.11,0.29l-0.44-0.21l-0.38-0.16l-0.23-0.04l-0.26-0.02l-0.12,0.02l-0.46,0.05l-0.12,0.02l-2.12,0.66l-0.15,0.06
        l-0.63,0.26l-0.04,0.12l-1.84,0.08l-0.87-0.72l-2.14-1.48l-0.08-0.05l-0.44-0.09l-0.7-0.13h-0.12l-1.57,0.13l-0.17,0.08l-0.26,0.26
        l-0.95,0.49l-1.21,0.38l-0.12-0.02l-0.08-0.04l-0.05-0.08l-0.05-0.21l-0.05-0.08l-0.09-0.04h-0.12l-0.83,0.32l-0.09,0.04
        l-0.47,0.19l-0.15,0.09l-0.08,0.05l-2.14,2.15l-0.08,0.19l0.08,0.3l-0.02,0.12l-0.04,0.09l-0.05,0.08l-0.72,0.93l-2.32,2.46
        l-0.23-0.05l-0.12,0.02l-0.65,0.19l-0.66,0.25l-0.09,0.04l-1.24,0.62l-0.08,0.05l-0.38,0.29l-2.99,2.71l-0.17,0.21l-1.54,0.53
        l-1.21,0.02l-0.12,0.02l-0.08,0.05l-1.04,0.85l-0.46,0.44l-0.22,0.3l-0.12,0.15l-0.25,0.16l-1.2,0.36l-0.74,0.09l-1.23,0.08
        l-1.17,0.19l-0.12,0.02l-3.16,0.85l-0.17,0.08l-0.08,0.05l-0.55,0.49l-1.9,1.78l-0.34,0.57l-0.86,3.03l-0.38,1.63l-0.05,1.08
        l-0.05,0.91l-0.26,1.34l-0.49,1.48l-1.56,2.94l-0.19,0.34l-0.05,0.08l-0.19,0.08l-0.08,0.05l-0.05,0.08l-0.23,0.3l-0.21,0.72
        l-0.04,0.78l0.08,0.19l-0.02,0.12l-0.17,0.76l-1.33,2.79l-0.09,0.19l-0.05,0.08l-0.21,0.21l-0.42,0.21l-0.91,0.66l-0.51,0.55
        l-0.05,0.08l-0.25,0.42l-0.61,1.88l-0.04,0.12l-0.01,0.25l0.08,0.3l0.13,0.26l0.68,1.54l0.46,2.46l0.12,1.41l1.08,2.12l0.47,0.32
        l0.08,0.08l0.23,0.43l0.26,0.63l0.04,0.23l-0.02,0.26l-0.04,0.25l-0.04,0.09l-0.04,0.09l-0.19,0.21l-0.25,0.29l-0.05,0.08
        l-0.15,0.26l-0.23,0.7l-0.12,4.57l0.02,2.75l-0.02,0.25l-0.09,0.3l-0.85,1.97l-0.08,0.17l-0.42,0.66l-0.32,1.29l-0.12,1.74
        l0.04,0.57l-0.34-0.09l-0.29-0.13l-0.7-0.36l-0.21-0.17l-0.13-0.13l-0.29-0.38l-0.25-0.4l-0.19-0.21l-0.33-0.23l-0.59-0.21
        l-1.1-0.32l-0.21-0.05l-0.51-0.06l-0.12,0.02l-0.09,0.04l-0.62,0.17l-0.55,0.09l-0.36,0.04l-0.46-0.08l-0.23-0.17l-1.65-2.43
        l-0.68-1.57l0.02-0.25v-0.49l-0.05-0.21l-0.44-0.87l-0.13-0.13l-2.49-1.9h-0.12l-0.12-1.99l1.95-2.01l0.04-0.09l0.04-0.12
        l0.02-0.25l-0.05-0.21l-0.08-0.17l-0.08-0.08l-0.32-0.21l-0.17-0.08l-0.7-0.25l-0.49-0.17l-0.42-0.13l-0.55-0.25l-1.38-0.76
        l-0.57-0.36l-0.13-0.13l-0.12-0.16l-0.17-0.23l-0.09-0.16l-0.26-0.53l-0.09-0.29l-0.02-0.36l0.02-0.4l0.05-0.61v-0.12l-0.05-0.32
        l-0.02-0.12l-0.21-0.7l-0.08-0.19l-0.09-0.16l-0.46-0.72l-0.16-0.21l-0.38-0.29l-0.57-0.36l-0.25-0.16l-0.25-0.04l-0.26-0.02
        l-0.7,0.08l-0.26-0.02l-0.25-0.04l-0.43-0.12l-0.29-0.12l-0.08-0.05l-0.1-0.16l-0.05-0.21l0.02-0.26l0.21-0.59l0.05-0.09l0.12-0.16
        l1.2-1.2l1.21-1.03l0.08-0.05l0.29-0.25l0.29-0.25l0.09-0.17l0.02-0.12l-0.04-0.65l-0.02-0.12l-0.04-0.09l-0.13-0.13l-0.15-0.12
        l-0.04-0.09l-0.05-0.34l0.76-2.85l0.13-0.4l0.16-0.38l0.57-1.17l0.06-0.09l0.17-0.23l0.42-0.38l0.19-0.17l0.08-0.05l0.72-0.32
        l0.66-0.25l0.34-0.06l3.96-0.4l-0.47-1.65l0.49-2.01l0.29-1.94v-1.37l-0.01-0.51l-0.04-0.21l-0.08-0.19l-0.05-0.08l-1.73-1.2
        l-0.09-0.04l-0.23-0.05l-0.12-0.02l-0.12,0.02l-2.45,0.42l-0.21,0.05l-0.15,0.12l-0.12,0.16l-0.05,0.05l-0.26,0.12l-0.96,0.17
        l-0.81,0.06l-0.66,0.29l-0.08,0.05l-0.74,0.7l-1.14,0.61l-0.76-1.08l-1.91-2.5l-0.21-0.19l-1-0.95l-1.21-1.74l-0.89-1.37
        l-1.52-1.94l-0.09-0.04l-0.47-0.02l-0.12,0.02l-0.08,0.04l-0.05,0.08l-0.09,0.04l-0.21,0.02l-0.08-0.05l-0.08-0.08l-0.89-1.69
        l0.25-0.87l0.25-0.16l0.19-0.21l0.08-0.15l0.01-0.12l0.02-0.25l-0.02-0.12l-0.09-0.55l-0.13-0.26l-0.25-0.29l-0.36-0.17l-1.06-0.17
        l-0.21,0.02l-0.21,0.05l-0.36,0.3l-2.24-1.37l-0.09-0.17l-0.08-0.19v-0.12l0.09-0.3l0.19-0.34l0.61-0.3l0.23-0.02l1.31-0.47
        l1.95-2.12l0.17-0.36l0.34-1.71l-0.08-0.4l-0.23-0.55l-0.08-0.44v-0.13l0.34-2.67l0.01-0.12l0.02-0.12l0.53-2.04l0.08-0.17
        l0.85-2.79l0.66-2.43l0.02-0.47l-0.02-0.12l-0.05-0.21l-0.12-0.26l-0.05-0.08l-0.12-0.16l-0.38-0.4l-0.09-0.16l-0.08-0.19
        l-0.21-1.2l-0.04-1.5v-0.53l0.04-0.3l0.15-0.61l0.12-1.57l-0.02-0.12l-0.57-1.44l-0.19-0.34l-0.23-1.84l0.01-0.12l0.02-0.74
        l-0.17-1l-0.17-0.47l-0.09-0.17l-0.29-0.38l-0.47-0.57l-0.25-0.29l-0.06-0.05l-3.79-1.69h-0.25l-0.09,0.04l-0.17,0.09l-0.61,0.02
        l-0.25-0.02l-0.32-0.08l-0.16-0.08l-0.13-0.13l-0.15-0.23l-0.04-0.09l-0.08-0.57l0.04-0.36v-0.23l-0.23-0.42l-0.12-0.16l-0.19-0.21
        l-0.09-0.05l-0.09-0.04l-0.29-0.12l-0.12-0.02l-0.85-0.17l-0.66-0.09l-0.12,0.02l-0.19,0.08l-0.08,0.05l-0.19,0.21l-0.12,0.16
        l-0.13,0.13l-0.08,0.05l-0.12,0.02l-0.34,0.02h-0.19l-1.42-0.08l-0.21-0.05l-0.4-0.16l-0.17-0.08l-1.42-0.72l-0.49-0.3l-0.36-0.3
        l-0.4-0.25l-0.08-0.02l-0.49-0.05l-0.4-0.02l-0.25,0.02l-0.55,0.09l-0.21,0.04l-1.48,0.47l-0.09,0.04l-3.03,2.16l-0.22,0.17
        l-0.12,0.13l-0.22,0.29l-0.19,0.26l-0.61-0.09l8.11-6.54l1.71-1.37l3.74-3.02l4.08-3.29l0.34-0.27l1.08,0.06l0.47,0.13l0.38,0.16
        h0.12l0.74-0.02l0.17-0.08l1.25-0.96l0.19-0.21l0.29-0.38l0.12-0.16l1.41-2.03l0.36-0.7l0.05-0.21l0.02-0.25l0.05-0.23l0.05-0.08
        l0.25-0.29l0.26-0.26l0.44-0.34l0.91-0.51l-0.05-0.4l0.08-0.46l0.63-2.54l0.04-0.09l0.15-0.12l0.66-0.34l0.17-0.08l0.3-0.02
        l0.3-0.08l0.49-0.47l2.12-2.11l0.19-0.19l0.05-0.08l0.08-0.17l0.4-1.16l0.05-0.19l0.32-2.18l-0.02-0.12l0.79-2.56l1.04-2.99
        l0.22-0.29l0.76-0.78l0.05-0.05l0.09-0.04l0.29-0.08l1.08-0.78l1.76-2.12l0.05-0.08l0.68-1.2l0.23-0.42l0.12-0.29l0.49-1.44
        l0.02-0.53l-0.25-1.71l0.96-0.99l-0.46-0.61l-0.79-1.16l-0.04-0.09l-0.02-0.12l0.08-0.19l0.26-0.26l0.93-0.76l0.46-0.36l1.65-1.24
        l1.16-0.85l0.04-0.23l0.01-1.1l-0.08-0.29l-0.12-0.4l0.61-3.03l0.49-0.4l1.52-1.41l0.13-0.13l0.05-0.08l0.38-0.79l1.07-3.53
        l0.05-0.34l0.08-1.74l-0.02-0.12l-0.08-0.19l-0.17-0.23l-0.16-0.12l-0.26-0.12l-0.12-0.02l-0.32,0.08l-0.25,0.16l-0.26,0.13
        l-0.21-0.02l-0.05-0.08l-0.19-0.47l-0.05-0.21l-0.08-0.46l0.04-0.09l0.05-0.08l0.05-0.05l0.09-0.04l0.85-0.15l0.3,0.09l0.12,0.02
        l0.09-0.04l0.08-0.05l0.47-0.7l0.08-0.17l0.02-0.12l0.36-1.76l0.47-3.53l-0.09-1.48l-0.15-0.12l-0.02-0.23l0.04-0.09l0.47-0.95
        l0.05-0.08l0.08-0.08l0.16-0.12l0.78-0.42h0.7l0.34-0.19l0.04-0.23l-0.05-0.46l-0.08-0.19l0.34-0.7l0.21-0.26l0.44-0.51l0.08-0.05
        h0.12l0.21,0.16l1.37,1.06l0.05,0.08l0.23,0.55l0.3,1.24l-0.04,0.29l-0.02,0.08l-0.04,0.08l0.29,0.23l1.27-0.08l0.95-1.67l0.3,0.74
        l0.3,2.77l0.08,0.08l0.08,0.04l0.22,0.04l0.79,0.04l0.23-0.04l0.12-0.16l0.15-0.38l0.05-0.21l0.02-0.26l-0.05-0.21l-0.05-0.08
        l-0.05-0.17l0.02-0.12l0.08-0.26l0.08-0.05l1.08,0.05l2.07,0.65l0.61,0.86l-1.74,2.01l-0.05,0.16l0.09,0.04l0.74,0.61l0.08,0.17
        v0.25l-0.02,0.4l0.12-0.02l0.08-0.05l1.29-2.45l0.62-1.21l-0.04-0.19l-0.09-0.16l-0.02-0.12l0.02-0.12l0.04-0.05l0.08-0.05
        l0.17-0.08l0.32,0.08l0.08,0.05l0.21,0.32l0.08,0.19l0.17,0.59l0.02,0.09l0.02,0.38l-0.02,0.34l-0.02,0.12l-0.32,0.38l-0.72,0.7
        l-0.32,1.21l0.09,3.28l0.02,0.12l0.25,0.29l0.12,0.13l0.51,0.08l0.59-0.12l0.19-0.08l0.08-0.05l1.03-0.93l0.05-0.08l-0.02-0.12
        l-0.08-0.19l-0.09-0.15l-0.19-0.21l-0.06-0.08l-0.21-0.44l0.01-0.26l0.05-0.34l0.08-0.19l0.08-0.17l0.05-0.08l0.76-0.82l0.72-0.74
        l0.09-0.04l1.2-0.3l0.08,0.02l1.61,0.47l0.95,0.25l1.69-1.48l0.12,0.02l0.05,0.08l0.09,0.29l0.08,0.32v0.25l-0.04,0.23l-0.04,0.09
        l-0.12,0.02l-0.12-0.02l-0.26,0.21l-0.04,0.04l-0.66,0.82l-0.68,1.06l0.33,0.34l0.4,0.38l0.25-0.53l1.25-0.74l0.29,0.12l0.05,0.08
        l0.29,0.38l0.15,0.25l0.08,0.19l0.02,0.12l-0.17,1.42l1.51,1.69l0.47,0.46l1.04-0.55l1.34,0.53l0.57,0.3l0.21,0.51l0.3,0.93
        l0.4,1.41l0.04,0.23l-0.13,1l-0.02,0.09l-1.74,2.29l-0.91,1.03l-0.47,1.1l0.09,0.95l0.04-0.23l0.01-0.09l0.36-0.57l0.3-0.47
        l0.05-0.08l1.97-1.99l0.09-0.04l0.3-0.08l0.74,0.04l0.21,0.05l0.19,0.08l0.15,0.12l0.05,0.08l0.42,0.59l0.08,0.19l0.49,1.69
        l0.02,0.12l0.08,0.44l0.04,0.36l-0.02,0.12l-0.29,0.95l-0.09,0.17l-0.08,0.06l-0.42,0.08l-0.61,0.05l-0.12-0.02l-1.2,1.12
        l-1.42,1.65v0.38l0.08,0.76l-0.59,1.1l-0.78,1.63l-0.12,0.29l-0.53,2.62l-0.17,1.48v0.13l0.02,0.12l0.09,0.16l0.09,0.12l0.09,0.04
        l0.09,0.04l0.89,0.26l0.02,0.12l-0.02,0.25l-0.04,0.12l-0.09,0.17l-2.39,2.39l-0.34,0.04l-0.17,0.09l-0.12,0.13v0.12l0.05,1.59
        l0.63-0.44l1.78-1.65l0.83-1.48l1.33-1.03l1.2-0.85l0.08-0.05l0.13-0.13l0.3-0.36l0.38-0.53l0.21-0.3l0.09-0.17l0.19-0.61
        l0.09-0.57l0.39-3.16l0.32-2.79l0.21-0.55l1.31-0.85l0.19-0.04l0.36,0.05l0.12-0.02l0.65-0.12l0.29-0.09l0.7-0.32l0.79-0.49
        l0.08-0.08l0.23-0.16l0.19-0.05l0.7-0.12l0.42-0.04l0.08,0.05l0.15,0.25l0.02,0.12l0.04,1.65l-0.09,0.3l-0.17,0.36l-0.05,0.08
        l-1.65,2.18l-0.12,0.13l-0.46,0.32l-0.08,0.05l-0.19,0.05l-0.21,0.04l-0.12-0.02l-0.09-0.04l-0.68,0.38l-0.66,0.38l-1.31,0.78
        l-0.05,0.08l-0.04,0.09l-0.02,0.12l-0.04,0.23l0.02,0.49l0.05,0.08l0.08,0.04l0.74,0.08l0.08-0.02l0.05-0.08l0.23-0.4l0.13-0.3
        l0.02-0.12l0.04-0.09l0.12-0.15l0.08-0.05l0.09-0.02l1.08-0.16l0.12-0.02l0.09,0.02l0.22,0.79l0.01,0.12l-0.04,0.09l-0.05,0.08
        l-0.13,0.13h-0.25l-0.38,0.29l-0.04,0.09l-0.02,0.12l0.04,0.12l0.3,0.19h0.05L4148.97,1003.23L4148.97,1003.23z"/>
      <path id="path26" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4120.19,974.91l-0.02-0.09l0.83-3.72l0.08-0.26
        l0.13-0.32l0.05-0.04l0.1-0.02l0.12,0.02l0.19,0.08l0.05,0.08v0.25l-0.02,0.25l-0.47,3.53l-0.79,0.3L4120.19,974.91L4120.19,974.91
        z"/>
      <path id="path28" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4133.33,975.67l-0.15-0.12l-0.13-0.13l-0.16-0.25
        l-0.04-0.09l-0.09-0.65l0.02-0.09l0.62-0.91l0.34-0.42l0.93-0.05h0.13l0.09,0.04l0.63,0.42l0.05,0.08l0.09,0.16l0.04,0.23
        l-0.04,0.09l-0.21,0.4l-1.46,1.08l-0.23,0.15l-0.29,0.12l-0.12,0.02L4133.33,975.67L4133.33,975.67z"/>
      <path id="path30" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4137.59,980.8l-0.08-0.05l-0.12-0.13l-0.04-0.09
        l-0.01-0.12l0.08-0.59l0.23-0.21l0.38-0.34l0.65-0.53l0.08-0.02l0.51,0.08l0.08,0.02l0.17,0.21l0.12,0.16l0.17,0.34l0.12,0.29v0.12
        l-0.09,0.68l-0.08,0.05l-0.12-0.02l-0.36-0.21l-1.07,0.26l-0.49,0.17L4137.59,980.8L4137.59,980.8z"/>
      <path id="path32" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4157.56,993.79h-0.13l-0.12-0.02l-0.01-0.12l0.12-0.29
        l0.29-0.51l0.09-0.17l0.26-0.25l0.29-0.25l0.29-0.12l0.62-0.16l0.26,0.02l0.08,0.05l0.09,0.12l-0.01,0.09l-0.05,0.08l-0.08,0.05
        l-0.4,0.09l-0.44,0.23l-0.05,0.06l-0.42,0.49l-0.04,0.12l-0.09,0.49H4157.56L4157.56,993.79z"/>
      <path id="path34" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4189.01,1001.56l-2.04-1.48l-0.01-0.09l0.09-0.04
        l1.56,0.08l0.43,0.09l0.12,0.04l0.53,0.51l0.16,0.21l0.12,0.4l-0.05,0.08l-0.23,0.17l-0.08,0.05l-0.09,0.04l-0.12,0.02
        L4189.01,1001.56L4189.01,1001.56z"/>
      <path id="path36" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4141.05,1006.36l0.32-0.46l0.33-1.44l-0.49-1.25
        l0.01-0.12l0.15-0.76l0.12-0.3l0.08-0.17l0.12-0.16l0.82-0.89l0.16-0.09l0.09-0.04l0.42-0.09l0.05,0.3l0.08,0.44l-0.08,2.16
        l-0.25,1.57l-0.02,0.12l-0.04,0.08l-0.42,0.23l-0.26,0.12l-0.21,0.19l-0.12,0.16l-0.4,0.65l-0.3,0.53l-0.08,0.19L4141.05,1006.36
        L4141.05,1006.36z"/>
      <path id="path38" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4140.46,1006.33l-0.16-0.09l-0.25-0.26l-0.12-0.16
        l-0.08-0.19v-0.13l0.04-0.09l0.82-1.42l0.22,0.04l0.21,0.05l0.19,0.08l0.08,0.05l0.04,0.09l0.02,0.12l-0.02,0.23l-0.01,0.12
        l-0.05,0.3l-0.19,0.61l-0.05,0.09l-0.4,0.51l-0.19,0.08L4140.46,1006.33L4140.46,1006.33z"/>
    </g>
    )
  } else if (props.state === 'PE') {
    stateSelected = (
      <g id="PE" transform="translate(-3550.8686,-814.85365)">
        <path id="path122" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4320.71,1118.58l-0.17-0.12l-2.33-0.26l-1.1-0.02
          l-2.24-0.93l-0.15-0.12l-1.25,0.12l-0.4,0.13l-0.19,0.08l-1.07,0.51l-0.83,0.49l-0.63,0.44l-0.19,0.08l-0.21,0.04l-0.23-0.04
          l-1.04-0.44l-0.15-0.16l0.05-0.19l-1.48-0.25l-2.45,0.3l-0.33,0.21l-1.07,0.79l-0.51,0.51l-0.15,0.53l-2.54,2.69l-0.22,0.15
          l-1.91,1.25l-0.25,0.05l-0.64-0.02l-0.61-0.12l-0.19,0.02l-1.74,1l-0.44,0.26l-1.33,0.16l-0.19-0.05l-1.03-0.13l-1.08-0.09
          l-1.51-0.09l-0.25,0.02l-0.19,0.02l-0.23,0.04l-0.44,0.12l-2.07,0.61l-2.22-0.55l-0.23-0.08l-0.86-0.42l-1.2-0.83l-0.4-0.38
          l-1.41-1.5l-0.4-0.59l-0.22-0.23l-0.43-0.34l-2.46-1.94l-0.21-0.12l-0.7,0.02l-0.42,0.12l-0.19,0.12l-1.9-0.34l-0.08-0.17
          l-0.57-0.87l-0.51-0.53l-0.21,0.05l-0.89,0.7l-0.12,0.72l-1.44,2.25l-1.41,1.78l-0.13,0.13l-0.16,0.12l-0.17,0.09l-0.42,0.12
          l-1.08,0.21l-0.42,0.12l-0.34,0.19l-0.4,0.4l-0.29,0.78l-0.08,0.17l-0.26,0.27l-0.55,0.51l-0.83,0.51l-0.34-0.51l-0.08-0.19
          l-1.04-2.82l-0.05-0.21l-0.02-0.23l0.02-0.85l-1.57-0.83l-0.22,0.05l-0.23-0.02l-0.38-0.16l-0.32-0.17l-0.33-0.23l-0.25-0.26
          l-0.43-0.87l0.29-0.51l0.12-0.13l0.17-0.12l0.12-0.16l0.16-0.34l0.04-0.34l-0.05-0.19l-0.12-0.16l-0.15-0.12l-0.36-0.17l-0.42-0.09
          l-0.22-0.02l-0.44,0.05l-0.23,0.05l-0.17,0.17l-0.25,0.29l-0.05,0.19l-0.12,0.68l0.01,0.25l-0.08,0.83l-0.08,0.19l-0.09,0.16
          l-0.16,0.12l-0.43-0.05l-0.32-0.21l-0.09-0.17l-0.36-0.95l-0.06-0.19l-0.17-0.62l-0.01-0.23l-0.09-0.17l-1.78-1.12l-0.15-0.09
          l-0.89-0.17l-0.44,0.05l-2.24-0.34l-2.88-0.95l-1.06-0.47l-0.61-0.51l-0.68-0.89l-0.09-0.16l-0.05-0.47l-0.08-0.17l-0.16-0.13
          l-0.15-0.09l-1.42-0.51l-0.36,0.02l-0.46,0.08l-0.17,0.08l-0.68,0.38l-0.29,0.23l-0.13,0.16l-0.16,0.09l-1.24,0.61l-0.53,0.26
          l-0.96,0.34l-0.96,0.08l-0.16,0.12l-0.05,0.23l-0.7,2.25l-0.23,0.02l-1.99,0.36l-0.23,0.05l-0.4,0.12l-0.3,0.21l-0.08,0.19
          l0.02,0.49l0.06,0.21l0.17,0.34l0.05,0.21l0.04,0.21l-0.01,0.21l-0.19,0.61l-0.09,0.17l-0.22,0.3l-0.91,0.93l-0.13,0.13l-0.17,0.12
          l-2.6,1.06l-0.21,0.05l-0.62-0.05l-0.17-0.09l-0.3-0.23l-0.15-0.13l-0.33-0.21l-0.21-0.06l-0.22-0.04l-0.47,0.13l-0.25,0.29
          l-0.23,0.3l-0.12,0.16l-0.09,0.19l-0.05,0.21l-0.05,0.61l-0.12,0.82l-0.74,2.88l-0.05,0.19l-0.36,0.17l-0.22,0.04l-0.46-0.09
          l-0.19-0.08l-2.14,0.83l-0.16,0.12l-0.13,0.16l-0.4,0.66l-0.29,0.25l-0.19,0.08l-0.21,0.05l-0.85,0.19l-0.95-0.26l-1.37-0.47
          l0.38-0.76l0.12-0.16l0.29-0.38l0.34-0.68l-0.04-1.08l-0.22-1.29l-0.09-0.17l-0.12-0.15l-1.42-0.51l-1.06-0.38l-0.12-0.02
          l-0.08-0.05l-0.72-2.15l-0.08-0.32l-0.04-1.73l0.09-0.7l0.09-0.44l0.01-0.25l-0.04-0.09l-0.17-0.23l-0.29-0.25l-0.25-0.17
          l-3.32-1.61l-2.66-0.38l-0.08,0.05l-0.25,0.15l-0.19,0.08l-0.43,0.09h-0.4l-0.23-0.04l-0.42-0.13l-0.57-0.34l0.15-0.05l0.93-0.38
          l0.36-0.16l0.27-0.13l1-0.55l0.49-0.29l2.35-1.99l0.12-0.16l0.61-0.99l2.79-1.61l0.96-0.4l0.17,0.08l0.12,0.02l0.09-0.04l1-1.25
          l1.73-1.78l1.51-1.33l0.21-0.19l0.21-0.3l0.21-0.46l0.49-1.1l0.09-0.44l0.05-0.36l0.01-0.25l0.04-0.76l-0.02-1.25l-0.01-0.12
          l-0.05-0.34l-0.3-0.34l-0.55-0.4l-0.17-0.09l-1.27-1.44l0.17-2.54l-0.16-0.25l-0.65-1.21l-0.04-0.09l-0.09-0.42l0.01-0.51
          l0.04-0.36l0.04-0.23l0.04-0.09l0.04-0.08l0.13-0.13l0.34-0.19l0.19-0.08l0.32-0.08l1.86-0.12l0.05-0.02l0.74-0.02l3.36,0.16
          l1.84,0.13l0.4,0.16l0.09,0.02l0.43,0.09l0.25,0.04l0.74-0.04l1-0.17l0.21-0.05l0.19-0.05l0.38-0.16l0.17-0.08l0.25-0.16l0.38-0.15
          l0.47-0.19l0.21-0.05l0.42-0.12l0.25-0.02l1.33-0.12h0.26l1.31,0.17l1.9,0.25l0.42,0.08l3.47,2.32l2.2,0.99l0.08,0.05l0.21,0.32
          l0.16,0.38l0.13,0.4l0.02,0.12l0.12,0.16l0.36,0.44l0.62,0.62l0.16,0.12l0.26,0.13l0.4,0.13l0.34,0.05l0.21,0.08l0.4,0.13
          l0.09,0.04l0.59,0.34l0.15,0.12l0.13,0.13l0.12,0.16l0.08,0.19l0.33,0.99l0.04,0.23l0.05,0.21l0.08,0.19l0.09,0.17l0.08,0.05h0.13
          l1.78-0.08l0.13-0.13l0.49-0.83l0.4-0.76l0.17-0.23l0.72-0.87l0.08-0.05l1.27-0.99l1.27-0.4l0.3-0.09l0.36-0.09l0.47,0.09
          l0.38,0.57l0.44,0.74l0.82,0.96l0.05,0.08l0.16,0.09l1,0.3l1.91-0.4l0.62-0.49l0.05-0.05l0.34-0.3l0.21-0.05l0.12-0.02l0.19,0.05
          l0.15,0.12l0.12,0.15l0.4,0.79l0.17,0.49l0.01,0.12l-0.01,0.25l-0.06,0.21l-0.04,0.09v0.23l0.05,0.08l0.08,0.05l0.76,0.25
          l0.12,0.02l0.12-0.02l3.05-1.17l1.99-0.29l1.42-1.55l0.12-0.16l0.21-0.19l0.22-0.17l3.09-2.16l0.17-0.12l0.26-0.13l0.33-0.08
          l0.44-0.08l0.21-0.05l0.29-0.12l1.48-0.89l0.05-0.05l0.76-1.08l0.32-0.74l2.62-1.52l0.21-0.05l1.61-0.49l0.12,0.01l0.19,0.06
          l3.94,2.2l0.15,0.12l0.12,0.16l0.43,1.44l0.02,0.12l-0.02,0.23l-0.05,0.08l-0.05,0.05l-0.17,0.09l-1.54,0.57l-1.17,0.23l-0.09,0.02
          l-0.53,0.26l-0.17,0.09l-0.21,0.17l-0.21,0.59l-0.02,0.25v0.26l0.05,0.21l0.04,0.09l0.43,0.89l0.38,0.79l0.08,0.32l-0.02,0.25
          l-0.08,0.3l-0.09,0.17l-0.08,0.05l-1.16,1.04l-1.86,2.11l-0.05,0.08l-0.16,0.38l0.02,0.12l0.19,0.19l0.09,0.04l1.44-0.38l0.19-0.08
          l0.13-0.13l0.05-0.08l0.34-0.19l0.12-0.02l0.25,0.02l0.21,0.05l0.78,0.29l0.19,0.08l0.12,0.16l0.04,0.09l0.02,0.12l-0.05,0.19
          l-0.13,0.16l-0.12,0.16l-0.05,0.08l-0.04,0.09l-0.02,0.12l0.02,0.12l0.34,1.73l0.05,0.21l0.09,0.42l0.04,0.09l1.06,1.04l0.16,0.12
          l1.2,0.66l0.36,0.17l0.21,0.05l0.12,0.02l1.65-0.85l0.26-0.13l0.21-0.05l0.21-0.05l0.25-0.02l0.63-0.04h0.38l0.15-0.12l2.08-1.8
          l0.34-0.3l0.16-0.25l0.04-0.09v-0.25l-0.05-0.32l-0.04-0.09l-0.19-0.32l-0.05-0.08l-0.19-0.21l-0.08-0.17l0.01-0.12l0.09-0.16
          l0.59-0.72l0.79-0.7l0.25-0.15l0.09-0.04l0.21-0.05l0.76,0.04l0.7,0.06l0.25-0.02l0.19-0.08l0.08-0.05l0.16-0.12l0.42-0.38
          l0.08-0.19l0.02-0.09l-0.02-0.25l-0.04-0.09l1.97-0.74l2.35,0.19l2.25,0.13l0.25-0.02l2.54-0.36l0.96-0.62v-0.74l1.38,0.02
          l2.35-0.51l0.21-0.05l2.07-1.08l0.16-0.12l0.05-0.08l0.04-0.09l0.25-0.95l0.09-0.57l0.09-0.57l1.8-1.55l2.66-1.27l0.34-0.05
          l0.63,0.05l2.45,0.25l0.42,0.12l0.57,0.21l0.4,0.25l0.16,0.12l0.08,0.05l0.23,0.29l1.55,1.38l0.66,0.4l1,0.21l0.13,0.13l0.29,0.55
          l0.34,0.78l-0.04,0.19l-0.4,0.78l-0.13,0.02l-0.4,0.23l-0.12,0.16l-0.47,0.79l-0.13,0.68l-0.05,0.46l0.05,0.19l0.13,0.16l0.34,0.17
          l0.4,0.05l0.29,0.51l0.08,0.17l0.17,0.62l0.02,0.21l-0.29,1.69l-0.05,0.21l-0.21,0.83l-0.12,0.16l-0.13,0.13l-0.34-0.02l-0.57,2.33
          l-0.7,2.81l-1.91,5.21l-0.49,1.08l-0.7,1.97l-0.51,1.8l-0.33,0.82L4320.71,1118.58L4320.71,1118.58z"/>
      </g>
    )
  } else if (props.state === 'ES') {
    stateSelected = (
      <path id="ES" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M657.4,538.25l-0.12-0.02l-0.51-0.02l-0.7,0.16l-0.25,0.17
      l-0.21,0.05l-0.13,0.01l-1.67,0.08l-0.26-0.02l-5.16-1.16l-3.06-1.29l-0.29-2.35v-0.13l-0.51-2.84l-0.04-0.09l-0.08-0.08l-0.25-0.17
      l-0.19-0.09l-1.86-0.51l-0.32-0.12l-0.05-0.08l0.51-2.54l0.08-0.21l0.64-1.41l-0.38-2.71l-0.3-0.62l-0.05-0.21l0.01-0.12l0.04-0.09
      l0.09-0.19l0.17-0.22l1.91-2.19l0.17-0.15l1.69,0.04l2.81,0.05l1.69,0.02l0.02-0.02l0.53-0.26l0.08-0.05l0.05-0.08l0.05-0.09
      l0.3-0.66l0.08-0.19l0.09-0.3l0.53-2.01l0.09-0.46l1.34-1.46l1.08-2.56l-0.02-0.12l0.02-0.12l0.25-1.69l0.09-0.32l0.23-0.3
      l0.08-0.05l0.44-0.25l0.38-0.17l0.66-0.57l1.78-1.9l0.13-0.15l0.16-0.27l0.08-0.19l0.47-1.99l0.13-1.23v-0.27l-0.21-2.12l-0.08-0.32
      l-0.05-0.21l-0.04-0.09l-1.54-3.23l-1.27-1.42l-0.08-0.08l-0.17-0.12l-0.25-0.04l-0.25,0.02l-0.27,0.13l-0.15,0.13l-0.05,0.08
      l-0.08,0.05l-0.13,0.02l-0.74-0.34l-0.08-0.05l-0.57-0.65l-0.05-0.08v-0.13l0.08-0.19l0.05-0.08l0.21-0.21l0.17-0.09l0.09-0.04
      l0.23-0.05l0.51-0.04h0.89l0.26,0.02l0.08,0.05l0.79,0.34l1.2,0.04l0.4-0.02l0.23-0.05l0.49-0.19l0.09-0.05l0.21-0.19l0.16-0.25
      l0.04-0.12l0.02-0.12l0.01-0.4l-0.08-0.59l-0.12-0.66l-0.04-0.12l-0.12-0.16l-0.13-0.13l-0.08-0.05l-0.53-0.04h-0.55l-0.25-0.04
      l-0.09-0.04l-0.13-0.13l-0.04-0.23v-0.23l0.12-0.72l0.08-0.32l0.15-0.53l0.05-0.23l0.12-1.25l0.02-0.38l-0.05-0.34l-0.12-0.29
      l-0.22-0.3l-0.36-0.32l-0.91-0.59l-0.7-0.19l-0.32-0.61l-0.04-0.09l-0.04-0.23l0.02-0.12l1.41-1.99l0.08-0.08l2.62-1.5l0.12-0.02
      l0.25-0.02l0.12,0.02l0.09,0.04l0.17,0.12l0.05,0.08l0.19,0.21l0.08,0.05l0.51,0.3l0.17,0.09l1.16,0.19l0.09,0.02l0.09-0.17
      l0.02-0.12l0.02-0.53l-0.05-0.21l-0.04-0.09l-0.12-0.17l-1.97-2.42l0.12,0.02l1.57,0.16l2.66-0.34l0.3-0.09l0.26-0.16l0.3-0.34
      l0.08-0.05l0.17-0.09l0.09-0.04l1.08-0.29l0.13-0.02l1.88-0.08l1.86,0.51l1.25,0.44l1.06,0.59l0.05,0.05l0.36,0.61l0.04,0.02
      l5.48,3.33l4.38,2.64l-0.3,0.76l-0.49,1.65l-0.09,0.3l-0.21,0.99l-0.05,0.36l-0.29,2.56l-0.05,0.61l0.02,1.67l0.13,2.49l0.17,1.91
      l0.16,1.04l0.13,0.53l0.22,1.61l0.04,0.36l0.16,1.69v0.4l-0.09,1.41l-0.05,0.47l-0.05,0.21l-0.17,0.38l-1.41,2.94l-0.26,0.55
      l-0.08,0.16l-0.08,0.05L681,508.4l-0.96,0.42l-0.4,0.16l-0.09,0.04l-0.69,0.29l-0.96,0.53l-0.32,0.23l-0.13,0.13l-0.21,0.34
      l-2.03,3.85l-0.32,1.16l-0.42,1.76l-0.4,1.34l-0.08,0.19l-0.85,1.61l-0.08,0.02l-0.08-0.06l-0.79-0.59l-0.02-0.12l-0.05-0.08
      l-0.05-0.08l-0.08-0.05l-0.96,0.01l-0.29,0.95l-0.09,0.46l0.02,0.12l0.05,0.08l0.47,0.09l0.36,0.08l0.76,0.32l-1.02,2.39l-0.17,0.36
      l-1.16,2.12l-0.21,0.32l-3.87,4.02l-0.08,0.05l-0.12,0.04l-0.08-0.02l-0.02-0.25l-0.05-0.08l-0.08-0.05l-0.25-0.02l-1.63,0.59
      l-0.34,0.21l-0.08,0.05l-0.05,0.08l-0.85,1.21l-0.05,0.09l-0.06,0.21l-0.08,0.4l-0.04,0.25l0.01,0.25l0.09,0.43v0.13l-0.29,0.79
      l-0.15,0.4l-0.12,0.29l-0.05,0.09l-0.23,0.3l-0.15,0.13l-0.4,0.42l-0.83,0.93l-0.23,0.3l-0.29,0.51l-0.12,0.3l-0.01,0.25l0.05,0.23
      L657.4,538.25L657.4,538.25z"/>
    )
  } else if (props.state === 'AP') {
    stateSelected = (
      <g id="AP" transform="translate(-3550.8686,-814.85365)">
        <path id="path165" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4001.61,970.85l-0.49-0.02l-0.93,0.21l-0.42,0.21
          l-0.83,0.17l-0.74-0.02l-2.79-0.61l-0.21-0.05l-0.38-0.16l-0.08-0.05l-1.69-1.2l-0.08-0.05l-0.05-0.08l-0.43-1.71l-0.02-0.12
          l-2.16-1.59l-0.12-0.02l-0.09-0.04l-0.05-0.08l0.02-1.48l0.02-0.12l0.25-0.79v-0.13l-0.04-0.23l-0.29-1.41l-0.08-0.3l-0.04-0.09
          l-0.16-0.25l-0.15-0.12l-0.09-0.04l-0.12-0.02l-0.36,0.04h-0.26l-0.21-0.05l-0.08-0.06l-0.13-0.13l-0.09-0.16l-0.19-0.46
          l-0.15-0.51l-0.04-0.36l0.02-0.36l0.16-0.36l0.02-0.47v-0.76l-0.12-0.79l-1.31-1.56l-0.74-0.83l-1.37-1.52l-0.09-0.04l-0.19,0.05
          l-0.34,0.17l-0.32,0.21l-0.19,0.08l-0.25-0.02l-0.21-0.05l-0.38-0.16l-0.16-0.12l-1.76-4.21l-0.02-0.38l0.04-0.23l0.09-0.42
          l0.04-0.09l0.02-0.12l0.02-0.36l-0.02-0.25l-0.04-0.23l-0.63-1.99l-0.08-0.19l-0.13-0.13l-0.25-0.16l-0.15-0.12l-0.16-0.13
          l-0.16-0.22l-0.57-1.27l-0.08-0.19l-0.12-0.29l-0.02-0.12l-0.02-0.12l-0.17-2.2l-0.01-0.36l0.19-1.34l0.19-0.86l0.15-0.63
          l0.02-0.25l-0.02-0.51l-0.04-0.23l-0.05-0.19l-0.06-0.08l-0.08-0.05l-0.19-0.05l-0.89-0.16l-0.7-0.09l-0.3-0.08l-0.59-0.19
          l-0.34-0.19l-0.21-0.17l-0.21-0.17l-0.19-0.19l-1.91-2.28l-0.06-0.08l-0.82-3.02l-0.09-0.44l-0.02-0.38l0.02-0.25l0.06-0.19
          l0.05-0.08l0.09-0.04l0.38,0.02l0.38-0.13l0.09-0.04l0.05-0.05l0.04-0.09l-0.02-0.12l-0.02-0.09l-0.47-1.1l-0.12-0.16l-0.12-0.02
          l-0.25,0.02l-0.12,0.02l-0.42,0.09l-1.54-1.34l-1.88-1.9l-0.09-0.02l-1.1,0.19l-0.44,0.47l-0.05,0.05l-1.42,0.16l-0.36-0.02
          l-0.79-0.3l-0.09-0.04l-0.59-0.72l-0.34-0.47l-1.41-1.03l-1.44-0.16l-0.6-1.57l-0.4-0.49l-0.36-0.44l-0.05-0.05l-0.15-0.12
          l-2.16-1.16l-0.09-0.04l-1.54-0.59l-4.13-0.46l-0.79,0.12l-0.19,0.05l-0.34,0.19l-0.7,0.08l-0.68-0.08l-0.09-0.04l-0.29-0.49
          l-0.05-0.19l-0.04-0.23l-0.22-1.44l-0.02-0.12l0.19-3.89l0.09-1.59l0.05-0.08l0.7-1.52l0.04-0.09l-0.44-2.67l-0.05-0.21l-0.04-0.09
          l-0.05-0.08l-0.13-0.13l-0.23-0.16l-0.26-0.13l0.21-0.23l0.09-0.04l0.25-0.02l0.12,0.02l0.82,0.23l0.17,0.09l0.06,0.08l0.01,0.12
          l0.04,0.76l-0.05,0.08l-0.12,0.02l-0.23,0.04l-0.08,0.05l-0.09,0.17l0.32,1l0.05,0.08l0.17,0.09l0.12,0.02l0.61,0.05l0.23-0.02
          l0.12-0.02l0.47-0.16l0.13-0.02l0.12,0.02l0.33,0.08l0.51,0.13l0.09,0.04l0.08,0.05l0.29,0.34l-0.16,0.21l0.25,0.49l0.79,0.74
          l0.25,0.16l0.09,0.04l2.58,0.89l0.12,0.02l3.7,0.89l0.25,0.02l0.12-0.02l0.09-0.04l3.03-1.99l0.15-0.12l0.05-0.08l0.05-0.21
          l-0.01-0.25l0.34-0.47l2.04-1.41l0.12-0.08l0.29-0.16l0.17-0.09l0.19-0.08l0.33-0.05l0.09,0.04l0.05,0.08l0.29,0.36l0.05,0.08
          l-0.02,0.12l-0.05,0.08l0.42,0.47l3.16,1.13l0.12,0.02l1.34-0.16l2.03-1.12l0.05-0.05l0.04-0.09l0.02-0.09l0.02-0.25l0.01-0.12
          l0.08-0.08l0.09-0.02l0.12,0.02l0.17,0.09l0.08,0.08l0.36,0.49l1.34,1.04l-0.25,1.06l0.82-0.09l1.48-0.17l0.49,0.02l0.19,0.05
          l0.13,0.13l0.23,0.3l0.13,0.13l0.08,0.06l0.99,0.17l0.25,0.02l0.43,0.02l0.12-0.02l0.19-0.05l0.74-0.34l0.25-0.15l0.05-0.05
          l0.17-0.23l0.04-0.09l1.78-1.5l2.03-1.24l0.15-0.12l0.05-0.05l1.48-1.84l0.51-0.79l0.15-0.25l0.3-0.61l0.08-0.17l0.02-0.25
          l-0.02-0.12l-0.21-0.57l-0.04-0.09l0.65-1.42l1.06-2.2l1.06-2.07l0.49-0.85l0.13-0.26l0.46-1.12l0.15-0.87l-0.02-0.09l-0.15-0.78
          l0.15-0.49l1.13-1.42l0.13-0.13l0.15-0.12l0.19-0.08l0.17,0.05l0.05,0.08l0.08,0.05l0.34-0.26l0.12-0.16l1.04-1.55l1.24-2.03
          l1.94-3.24l0.12-0.29l0.05-0.47l0.09-0.57l0.05-0.21l0.08-0.17l0.91-1.25l0.13-0.16l0.32-0.19l1.61-1.42l0.19-0.19l0.09-0.16
          l0.26-0.62l0.19-0.85l0.17-0.36l0.12-0.15l0.13-0.16l0.3-0.23l0.34-0.17l0.46-0.19l0.38-0.16l0.13-0.02l0.08-0.04l0.12-0.05
          l0.51-0.29l0.13-0.16l0.38-0.68l0.16-0.38l1.27-0.72l0.63,1.71l0.09,0.17l1.03,1.57l-0.22-0.93l-0.4-0.65l-0.44-1.12l-0.13-0.38
          l-0.74-2.39l-0.12-0.4l-0.08-0.44l-0.02-0.49l0.02-0.63l0.05-0.21l0.09-0.17l0.12-0.16l0.17,0.02l3.77,2.96l0.29,0.23l1.12,1.21
          l0.25,0.29l0.99,1.35l0.19,0.34l0.46,1.12l0.04,1.94l0.53,0.38l0.29-0.49l0.19-0.19l0.16-0.12l0.17-0.02l0.12,0.15l0.09,0.17
          l0.05,0.19l0.04,0.21l0.13,2.81l-0.13,1.73l-0.02,0.25l0.04,0.85l0.21,3.94l0.09,0.93l0.12,0.93l0.08,0.44l0.53,2.45l0.12,0.42
          l0.49,1.25l1.48,3.2l1.21,2.54l0.46,1.57l0.17,0.61l0.04,0.23l0.62,2.41l1.97,6.14l1.65,2.62l1.74,2.69l0.3,0.44l0.26,0.3
          l0.17,0.17l0.32,0.23l0.74,0.12l1.5-0.19l2.49,0.08l0.19,0.05l2.66,0.83l0.65,0.29l1.5,0.93l0.13,0.16l0.09,0.16l0.68,2.29
          l0.06,0.4l0.05,0.4l0.02,0.4l0.05,1.37l-0.21,2.2l-0.05,0.17l-0.16,0.16l-1.37,0.95l-0.16,0.08l-0.83,0.12l-0.4,0.12l-0.82,0.26
          l-0.51,0.21l-0.17,0.17l0.26,0.12l0.17,0.02l0.4-0.02l0.3-0.04l1.27-0.25l0.47-0.12l0.15-0.09l0.49-0.29l0.29-0.04l0.13,0.13
          l0.12,0.34l0.08,0.38l0.04,0.36l-0.02,0.32l-0.21,0.38l-0.44,0.53l-0.13,0.16l-0.62,0.49l-0.16,0.08l-4.79,3.74l-1.44,2.58
          l-0.76,0.59l-0.78,0.55l-0.16,0.12l-0.38,0.16l-0.51,0.29l-0.13,0.13l-0.36,0.68l-0.33,0.74l-0.36,0.68l-1.08,2.04l-0.19,0.34
          l-0.44,0.61l-0.83,1.03l-2.16,2.54l-0.26,0.26l-0.15,0.12l-0.3,0.23l-0.17,0.09l-0.38-0.09l-0.19-0.08l-0.51,0.02l-0.23,0.02
          l-0.21,0.05l-0.6,0.17l-0.4,0.12l-0.19,0.08l-0.16,0.09l-0.29,0.25l-0.33,0.36l-0.12,0.16l-0.34,0.44l-0.57,0.76l-0.21,0.3
          l-0.29,0.51l-0.05,0.32l-0.16,0.3l-0.29,0.32l-0.09,0.05l-0.44-0.02l-0.76,0.02l-0.44,0.08l-0.32,0.19l-0.12,0.16l-0.47,0.78
          l-0.44,0.3l-0.19,0.19l-0.74,0.93l-0.42,0.65l-0.59,0.99l-0.16,0.38l-0.08,0.44l-0.05,0.21l-0.08,0.19l-0.25,0.55l-0.38,0.66
          l-0.93,1.55l-0.12,0.16l-0.19,0.19l-0.15,0.12l-0.47,0.3l-0.3,0.23l-1.65,1.82l-0.12,0.16l-1.74,2.16l-0.25,0.55l-0.05,0.21
          l-0.12,1.48l-0.04,0.72l0.04,0.74l0.02,0.25l0.09,0.42l0.09,0.68l-0.05,0.19l-0.09,0.15l-2.2,1.95l-0.15,0.12l-0.68,0.12
          l-0.47,0.02L4001.61,970.85L4001.61,970.85z"/>
        <path id="path167" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4028.69,908.63l-0.15-0.08l-0.63-0.53l-0.04-0.19
          l0.05-0.34l0.29-0.47l0.15-0.13l0.16-0.06l0.78-0.02l0.46,0.09l0.3,0.13l0.29,0.17l0.15,0.3l0.02,0.16l-0.04,0.16l-0.13,0.29
          l-0.16,0.12l-1.2,0.38l-0.19,0.04L4028.69,908.63L4028.69,908.63z"/>
        <path id="path169" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4030.07,913.03l-0.16-0.09l-0.12-0.17l-1.33-2.2
          l-0.04-0.16l0.12-1l0.04-0.3l0.08-0.16l0.16-0.05l1.57-0.55l0.15-0.04l0.3,0.04l0.44,0.3l0.09,0.17l0.89,2.08l0.13,0.32l0.02,0.3
          l-0.04,0.16l-0.08,0.16l-0.16,0.15l-0.96,0.99l-0.34,0.12l-0.32,0.08l-0.16,0.02L4030.07,913.03L4030.07,913.03z"/>
        <path id="path171" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4036.65,930.98l0.83-1.9l0.12-0.16l0.15-0.04l0.3-0.02
          l1.03,0.02l0.21,0.36l0.02,0.16l-0.09,0.47l-0.08,0.16l-0.12,0.17l-0.65,0.61l-0.47,0.36l-0.29,0.12L4036.65,930.98L4036.65,930.98
          z"/>
        <path id="path173" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4036.88,932.15l-0.3-0.13l-0.15-0.09l-0.09-0.16
          l-0.04-0.19l0.15-0.29l0.49,0.08l0.34,0.12l0.15,0.12l0.09,0.16l-0.02,0.16l-0.16,0.25l-0.17,0.04L4036.88,932.15L4036.88,932.15z"
          />
        <path id="path175" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4033.13,934.77l-0.15-0.12l-0.05-0.17l-0.09-0.87
          l0.13-0.29l0.53-0.49l0.3-0.26l0.3-0.26l0.15-0.08l0.16-0.05l0.83-0.09l0.49,0.05l0.46,0.19l0.25,0.05l0.34-0.02l0.57-0.13
          l0.49-0.79l0.09,0.02l0.05,0.42l-0.05,0.29l-0.78,1.34l-0.12,0.16l-0.29,0.29l-0.47,0.34l-0.3,0.16l-0.32,0.09l-0.17,0.04
          l-0.57,0.12l-0.15,0.02l-1.34,0.08h-0.29L4033.13,934.77L4033.13,934.77L4033.13,934.77z"/>
      </g>
    );
  } else if (props.state === 'RJ') {
    stateSelected = (
      <g id="RJ" transform="translate(-3550.8686,-814.85365)">
        <path id="path178" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4138.45,1393.86l-0.3-0.13l-0.08-0.06l-0.23-0.21
          l-1.73-1.65l-0.13-0.13l-0.05-0.08l-0.06-0.21l0.02-0.13l0.08-0.21l1.29-3.85l1.17-1.17l0.16-0.13l0.09-0.05l2.56-1.04l0.09-0.04
          l0.25-0.04l0.13,0.01l0.17,0.12l0.09,0.04l0.25,0.05l3.24-0.46l1.76-0.74l0.13-0.16l1.91-2.25l0.05-0.23l-0.05-0.21l-0.06-0.21
          l-0.21-0.46l-0.17-0.25l-0.46-0.63l-1.42-0.05l-0.65,0.04l-1.21,0.12l-0.34,0.08l-0.59,0.21l-0.09,0.04l-1.44,0.17l-0.29-0.02
          l-1.25-0.13l-0.63-0.23l-0.09-0.04l-0.08-0.05l-1.44-1.76l-0.13-0.4l-0.04-0.21l-0.12-0.29l-0.12-0.17l-0.29-0.26l-0.42-0.29
          l-0.3-0.13l-0.43-0.15l-0.42-0.05l0.15-0.13l0.25-0.17l0.46-0.25l0.49-0.19l0.51-0.17l1.94-0.47l3.33-1.41l0.17-0.12l0.08-0.05
          l0.21-0.08l2.67-0.16l0.15,0.02l0.21,0.08l0.08,0.06l0.13,0.13l0.17,0.09l0.29,0.02l1.88-0.89l0.08-0.05l0.25-0.17l0.15-0.13
          l0.85-0.55l3.91-1.61l1.1-0.42l0.46-0.09l0.13-0.01l0.13,0.01l0.08,0.05l0.15,0.25l0.09,0.05l0.12,0.04l3.81-0.12l1.46-0.17
          l0.12-0.02l0.79-0.46l0.99-0.55l0.29-0.12h0.55l1.42,0.08l1.65,0.23l0.47,0.08l0.12,0.04l0.08,0.05v0.23l-0.04,0.25l-0.12,0.16
          l1.74-0.3l2.6-1.12l3.36-1.48l1.99-0.93l3.6-1.63l0.09-0.04l0.93-0.3l0.97-0.27l0.34-0.08l0.65-0.32l0.08-0.05l0.05-0.08l0.17-0.25
          l0.05-0.09l0.05-0.23v-0.13l-0.02-0.12l-0.16-0.36l-0.08-0.08l-0.17-0.12l-0.21-0.08l-0.13-0.02l-0.13,0.02l-0.49,0.19l-0.74-0.15
          l-0.04-0.09l0.13-0.79l0.23-0.46l2.08-3.79l1.23-2.81l1.61-3.89l0.05-0.21v-0.12l-0.08-0.05l-0.12-0.04h-0.29l-0.12-0.04
          l-0.17-0.09l-0.08-0.05l-0.12-0.17l-0.08-0.21l0.02-0.12l0.05-0.09l0.08-0.05l0.63-0.36l0.66-0.16l0.78-0.17h0.13l3.01-2.71
          l1.86,0.51l0.19,0.09l0.25,0.17l0.08,0.08l0.04,0.09l0.51,2.84v0.13l0.29,2.35l3.05,1.29l5.16,1.16l0.26,0.02l1.67-0.08l0.13-0.01
          l0.21-0.05l0.25-0.17l0.7-0.16l0.51,0.02l0.12,0.02l1.91,1.21l0.04,0.04l0.05,0.21l-0.16,1.08l-0.05,0.21l-0.05,0.09l-0.79,1.25
          l-0.29,0.4l-0.26,2.42l0.47,1.88l0.96,5.25v0.25l-0.04,0.25l-0.05,0.21l-0.3,0.4l-0.29,0.29l-0.34,0.21l-3.27,1.72l-0.29,0.13
          l-0.51,0.17l-0.86,0.26l-0.43,0.12l-0.79,0.17l-1.08,0.3l-2.29,0.72l-0.42,0.13l-0.74,0.25l-0.49,0.19l-1.21,0.59l-0.53,0.3
          l-1.21,0.85l-1.65,1.74l-2.08,1.76l-0.12,0.04l-0.08,0.08l-0.05,0.08l-0.17,0.25l-0.09,0.17l-0.09,0.19l-0.04,0.12l-0.05,0.89
          l0.02,0.76l0.04,0.61l0.06,0.21l0.12,0.3l0.12,0.17l0.15,0.13l-0.3,1.77l-0.89,1.67l-2.21,0.59l-5.31-0.17l-2.28-0.04l-1.37,0.08
          l-0.25,0.04l-0.34,0.08l-0.34,0.08l-0.17,0.12l-0.08,0.05l-4.8,0.38l-0.29-0.02l-2.73-0.49l-0.08-0.05l-0.53-0.32l-0.05-0.08
          l-0.01-0.12l-0.04-0.64l0.61-1.06l0.19-0.25l0.08-0.08l0.38-0.3l0.68-1.08l0.05-0.09l0.09-0.47l0.02-0.66l-0.09-0.04l-0.34-0.09
          l-0.65-0.12l-3.26,1.21l-0.19,0.08l-0.13,0.16l-0.12,0.57v0.13l0.17,0.49l0.16,0.26l0.12,0.17l0.21,0.21h0.12l0.09-0.17l0.12-0.01
          l1.04,1.12l0.12,0.17l0.26,0.79l0.02,0.12l-0.05,0.22l-0.08,0.05l-0.61,0.38l-0.09,0.05l-0.59,0.25l-0.34,0.08l-1.03,0.23
          l-0.83-0.06l-1.08,0.02l-4.76,0.66l-4.89,0.51l-0.25,0.04l-0.23,0.04l-0.43,0.12l-0.63,0.21l-0.38,0.17l-0.13-0.02l-0.06-0.08
          v-0.38l0.08-0.34l0.06-0.09l0.22-0.19l0.09-0.05l0.21-0.08h0.16l1.33,0.23l1.5,0.05l0.44,0.02l0.4-0.02l1.12-0.13l0.34-0.08
          l1.65-0.42l0.09-0.05l-0.04-0.09l-3.11-1.67l-1.59-0.57l-0.13-0.02l-0.23,0.04l-3.19,0.74l-0.68,0.91l-0.06,0.09l-0.19,0.23
          l-0.21,0.21l-0.4,0.3l-0.29,0.13l-1.12,0.3h-0.74l-0.13-0.01l-0.08-0.06l-0.08-0.08l-0.04-0.09l-1.41-2.21l-0.08-0.05h-0.49
          l-0.09,0.04l-0.95,0.38l-0.21,0.16l-0.16,0.13l-0.08,0.05l-0.05,0.08l-0.05,0.23l-0.72,0.62l-1.16,0.38l-1.25,0.27l-0.12-0.04
          l-0.64,0.05l-0.23,0.04l-0.21,0.08l-0.19,0.08l-0.17,0.13l-0.05,0.08l-0.25,0.89l-0.34,1.67v0.12l0.08,0.3l0.04,0.09l1.25,0.08
          l1.17,0.02h0.13l0.23,0.01l0.09,0.04l1.04,1.04l0.08,0.08v0.12l-1.33,1.04l-0.17,0.12l-0.12,0.04l-0.29-0.02l-0.12-0.04l-0.53-0.21
          l-0.12-0.04l-1.37,0.04l-0.13,0.01l-0.09,0.05l-0.08,0.05l-0.02,0.05L4138.45,1393.86L4138.45,1393.86z"/>
        <path id="path180" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4146.09,1391.34l-0.72-0.82l-0.04-0.09v-0.13l0.08-0.05
          l2.49-1.71l0.12-0.02l2.22,1.06l0.55,0.66l0.04,0.09l-0.05,0.09l-0.09,0.05l-0.3,0.12l-1.38,0.21l-1.17,0.12h-0.12l-0.12-0.16
          l-0.33-0.25l-0.51-0.02l-0.12,0.04l-0.21,0.36l-0.23,0.47L4146.09,1391.34L4146.09,1391.34z"/>
      </g>
    );
  } else if (props.state === 'SP') {

    stateSelected = (
      <g id="SP" transform="translate(-3550.8686,-814.85365)">

        <path id="path198" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4074.4,1429.77l-0.97-0.25l-0.23-0.08l-0.47-0.21
          l-0.09-0.15l-0.04-0.09l-0.82-3.37l-0.02-0.21v-0.13l0.04-0.25l0.12-0.3l0.22-0.47l0.17-0.93l-0.09-0.05l-0.83-0.26l-1-0.27
          l-0.7-0.09l-0.79,0.05l-0.12,0.02l-0.08,0.08l-0.02,0.12l0.02,0.12l0.04,0.09l-1.31,2.81l-0.66,0.91l-0.08,0.05h-0.09l-0.12-0.04
          l-0.82-0.36l-0.21-0.09l-0.08-0.05l-0.05-0.08l-0.09-0.17l-0.3-0.62l0.82-3.7l0.08-0.08l0.25-0.17l0.12-0.04l0.38-0.19l0.25-0.17
          l0.19-0.22l0.05-0.09l0.04-0.12l0.02-0.26l-0.04-0.09l-1.88-1.16l-0.12-0.04l-0.13-0.02l-1.73-0.12l-0.12,0.01l-1.88,0.21
          l-0.55-0.42l-0.13-0.02l-2.66,0.36l-1.44,0.25l-2.9,0.13l-0.47-0.02l-0.61-0.16l-0.21-0.08l-0.21-0.08l-0.29-0.16l-0.17-0.12
          l-0.05-0.08l-0.08-0.19l-0.32-1.84v-0.12l0.02-0.13l0.04-0.12l0.08-0.08l0.12-0.02l1.34-2.11l0.06-0.09l0.26-1l0.01-0.12v-0.25
          l-0.32-0.25l-0.59-0.29l-0.12-0.04l-0.15-0.01l-0.1-0.05l-0.08-0.08l-0.85-2.03l0.02-0.12l0.08-0.79l-0.02-0.23l-0.05-0.08
          l-0.38-0.43l-0.08-0.08l-0.17-0.12l-0.19-0.09l-0.47-0.13l-0.21-0.08l-1.38-1.24l-0.04-0.09l-0.82-1.54l-1.04-1.67l0.16-0.13
          l0.36-0.49l0.02-0.12l0.12-1.57v-0.36l-0.02-0.12l-0.05-0.05l-0.34-0.23l-0.79-0.74l-0.15-0.13l-0.13-0.15l-0.05-0.08l-0.08-0.19
          l-0.12-0.42l-0.36-1.65l0.17-0.87l0.05-0.09l0.42-0.57l0.08-0.08l-0.05-2.99v-0.12l-0.53-1.17l-0.05-0.08l-0.46-0.62l-0.05-0.08
          l-4.36-3.16l-1.08-1.54l-0.12-0.16l-0.08-0.05l-0.09-0.05l-1,0.17l-0.09,0.05l-0.13,0.16l-0.55,0.19l-1.84,0.47l-5.43-0.08
          l-0.95-0.04l-0.26-0.03l-0.13-0.15l-0.13-0.26l-0.3-0.13l-0.23-0.08l-1.67-0.17l-0.3,0.05l-0.19,0.09l-0.15,0.13l-0.05,0.08
          l-0.12,0.17l-2.03-0.19l-0.83-1.46l-0.05-0.08l-0.29-0.26l-0.34-0.23l-0.21-0.09l-2.12-0.44l-1.94-0.38l-0.22-0.08l-1.94-0.85
          l-0.19-0.09l-0.23-0.17l-0.15-0.25l-0.08-0.19l-0.08-0.17l-0.12-0.16l-0.16-0.12l-0.09-0.05l-0.13-0.01l-1.54-0.04l-1.34,0.05
          l-0.15,0.12l-0.21,0.21l-0.25,0.17l-0.08,0.04l-0.21,0.05l-0.47,0.05h-0.66l-0.69-0.04l-0.22-0.08l-0.19-0.09l-0.53-0.34
          l-2.41-0.79l-0.26-0.05l-2.82-0.4l-0.25-0.19l-0.74-0.42l-0.95-0.38l-0.44-0.15l-0.47-0.13l-0.4-0.08l-0.23,0.05l-0.19,0.08
          l-0.19,0.08l-0.19,0.09l-0.22,0.17l-0.05,0.05l-0.29,0.51l-0.08,0.21l-1.44,0.61l-1.71,0.02l-0.25-0.02l-0.34-0.12l-1.48-0.22
          l-0.15-0.02l-0.12,0.02l-0.21,0.05l-1.65,0.13l-2.94-0.05h-0.13l-2.49-0.25l-0.25-0.05l-0.34-0.12l-0.4-0.21l-0.46-0.16l-0.29-0.04
          l-0.25,0.01l-0.32,0.08l-0.26,0.13l-1.52,1.1l-2.03,1.31l1.03-1.21l0.63-1.25l0.13-0.3l0.12-0.16l0.06-0.05l1.52-1.27l4.21-2.49
          l2.96-1.69l0.93-0.4l0.74-0.34l0.26-0.16l0.25-0.15l0.47-0.34l0.3-0.23l0.3-0.25l0.83-0.83l0.33-0.38l0.91-1.46l0.15-0.26
          l0.21-0.51l0.09-0.34l0.05-0.09l0.21-0.23l1.08-1l0.79-0.59l0.13-0.16l0.06-0.08l0.04-0.26l0.16-0.59l0.12-0.3l0.12-0.17l0.05-0.06
          l0.72-0.59l0.38-0.17l0.3-0.09l0.17-0.12l0.16-0.12l0.08-0.08l0.09-0.19l0.21-1.08l-0.08-0.3l-0.3-0.63l-0.13-0.26l1.25-1.48
          l0.33-0.04l0.36-0.23l0.57-0.7l1.17-2.11l0.3-0.57l0.09-0.49l-0.01-0.7l-0.06-0.44l-0.13-0.38l-0.17-0.38l-0.05-0.78l0.02-0.09
          l0.13-0.3l0.13-0.16l0.08-0.05l0.36-0.17l0.3-0.09l0.29-0.13l0.16-0.12l0.05-0.08l2.67-3.43l0.12-0.15l0.29-0.42l0.09-0.23
          l-0.04-0.89l0.08-1.57l0.09-0.78l0.7-1.86l0.12-0.16l0.59-0.21l0.12-0.02h0.36l0.19-0.09l0.79-0.7l1.04-1.48l1.29-1.52l0.21-0.21
          l0.43-0.38l0.38-0.3l0.16-0.12l0.4-0.29l0.44-0.25l0.21-0.04l0.4,0.04l0.12,0.04l0.4,0.08h0.13l0.09-0.01l0.4-0.13l1.65-0.78
          l0.09-0.04l0.15-0.12l0.13-0.16l0.12-0.17l0.08-0.21l0.44-2.25l0.02-0.47l0.15-0.19l0.19-0.23l0.44-0.4l0.74-0.49l2.43-1.21
          l0.09-0.04l0.09-0.04l0.34-0.05h0.09l0.42,0.04l0.36-0.02l0.53-0.13l0.55-0.21l0.09-0.05l0.23-0.17l0.19-0.22l0.3-0.53l0.32-0.38
          l0.6-0.61l0.08-0.04l1.74-0.66l0.09-0.02l0.25-0.01l0.25,0.05l0.21,0.08l0.3,0.13l0.53,0.44l0.32,0.36l0.21,0.32l0.12,0.16
          l0.15,0.13l0.15,0.12l0.09,0.05l0.26,0.13l0.13,0.02l1.34,0.04l1.76,0.05l3.11,0.65l1.99,0.46l0.26,0.04l0.21-0.05l0.15-0.08
          l1.41-0.32l0.26,0.01l0.68,0.08l1.03,0.17h0.25l0.3-0.09l0.38-0.16l0.26-0.13l0.36-0.19l0.93-0.12l0.15,0.02l0.36,0.08l0.29,0.16
          l1.95,1.41l2.04,0.85l-0.02,0.12l-0.12,0.61l-0.04,0.4l0.12,0.76l0.04,0.21l0.25,0.66l1.04,1.88l0.15,0.25l0.12,0.16l0.08,0.08
          l0.16,0.12l0.21,0.08h0.26l0.21-0.05l0.09-0.05l0.25-0.13l0.34-0.21l0.08-0.05l0.15-0.13l0.19-0.22l0.29-0.4l0.16-0.26l0.08-0.19
          l0.17-0.53l0.05-0.25l0.08-0.21l0.05-0.09l0.12-0.16l0.08-0.05l0.19-0.08l0.38-0.02l0.27,0.04l0.12,0.04l0.17,0.12l0.19,0.34
          l0.15,0.38l0.05,0.19l0.09,0.53l0.08,0.43l-0.01,0.25l-0.12,1.54l-0.08,0.47l0.08,0.42l0.12,0.16l0.46,0.25l0.66,0.34h0.21
          l0.09-0.04l0.25-0.16l0.17-0.21l0.04-0.09l0.01-0.13v-0.12l-0.15-1.67l-0.04-0.09l-0.17-0.34l-0.08-0.19l-0.02-0.12l0.02-0.13
          l0.08-0.19l0.59-1.37l0.22-0.32l0.32-0.23l4.89-0.53l3.13-0.25l3.2-1.44l1.38,1.31l0.13,0.15l0.33,0.23l0.38,0.19l0.7,0.26
          l0.25,0.05h0.13l0.12-0.01l2.92-0.64l0.25-0.09l0.08-0.05l0.13-0.13l0.34-0.93l-0.01-0.09l-0.19-0.34l-0.04-0.09l-0.04-0.09
          l0.02-0.12l0.19-0.38l0.05-0.09l0.08-0.05l0.09-0.05l0.09-0.04l0.82-0.08l1.74-0.05l0.13,0.02l0.21,0.08l0.09,0.05l0.23,0.21
          l1.57,0.63l1.03-0.74l1.44-0.42l0.12,0.04l0.16,0.12l0.36,0.32l3.49,3.43l0.05,0.08l0.21,0.96l0.02,0.12v0.12l-0.02,0.25
          l-0.08,0.21l-0.05,0.09l-0.13,0.13l-0.17,0.12l-0.16,0.12l-0.12,0.17l-0.04,0.12l-0.04,0.25l-0.34,2.28v0.12l0.04,0.09l0.13,0.13
          l0.51,0.46l0.15,0.12l0.85,0.44l1.38,0.91l0.04,0.09l0.13,0.4l0.17,0.6l0.12,0.4l0.04,0.21v0.12v0.25l-0.04,0.25l-0.09,0.17
          l-1.1,1.65l-0.21,0.21l-0.36,0.19l-0.13,0.13l-0.08,0.19l-0.21,1.95l0.02,0.09l0.06,0.08l0.13,0.16l0.19,0.09l0.55,0.47l0.19,0.22
          l0.29,0.59l0.22,1.14l0.16,1.25l0.04,0.09l0.09,0.17l1.54,1.95l0.96,2.03l0.05,0.21l0.02,0.12v0.13l-0.19,0.51l-0.02,0.13
          l0.04,0.09l0.08,0.08l0.09,0.04l0.13,0.02l0.76-0.05l0.74-0.05l0.23-0.04l0.21-0.05l0.74-0.29l0.09-0.05l0.13-0.16l0.15-0.26
          l0.06-0.08h0.13h0.89l0.34,0.09l4.13,1.33l0.19,0.09l0.08,0.05l0.13,0.15l0.05,0.08l0.04,0.09l0.01,0.23l-0.04,0.83l-0.05,0.49
          l-0.86,2.49l-1.37,1.99l-0.19,0.22l-0.34,0.21l-0.16,0.12l-0.06,0.08l-0.05,0.09l-0.09,0.32v0.13l0.32,3.28l-0.23,3.92l-0.68,1.42
          v0.34l0.05,0.21l0.86,1.63l2.18,1.41l1.41,0.59l0.82,0.34l0.68,1.8l0.02,0.12l-0.05,0.09l-0.19,0.08l-1.04,0.34l-0.17,0.09
          l-0.13,0.13l-0.04,0.12v0.12l0.04,0.09l2.33,3.37l0.13,0.13l0.17,0.12l0.83,0.16l0.42,0.04l0.83-0.05l0.21-0.05l1.41-0.15
          l0.23,0.61l2.35-0.51l2.04-0.61l0.63,0.46l0.12,0.04l1.38-0.29l0.09-0.04l0.15-0.13l0.78-0.93l0.08-0.19l0.08-0.63l-0.04-0.21
          l-0.04-0.09l-0.05-0.08l-0.08-0.05l-0.09-0.05l-0.13-0.01l0.08-1.08l0.13-1.37l0.01-0.12l0.08-0.05l0.09-0.04l1.17-0.3l0.12-0.02
          l0.12,0.04l0.08,0.08l0.04,0.09v0.12l-0.08,0.19l-0.12,0.17l-0.12,0.15l-0.09,0.05l-0.15,0.12l-0.09,0.19l-0.04,0.09v0.12
          l0.05,0.08l0.08,0.05l0.09,0.04l0.26,0.04l2.56-0.02l2.92-0.29l1.99-0.64l0.19-0.08l0.08-0.05l0.05-0.09l0.38-0.47l0.91-0.76
          l0.17-0.09l0.99-0.53l0.26-0.16l0.96-0.42l0.12-0.04l1.38-0.26l0.25-0.02l0.26,0.02l0.38,0.08l0.26-0.02l0.23-0.05l0.4-0.15
          l0.36-0.19l0.64-0.46l0.42,0.05l0.44,0.16l0.3,0.13l0.42,0.29l0.29,0.26l0.12,0.17l0.12,0.29l0.04,0.21l0.13,0.4l1.44,1.76
          l0.08,0.05l0.09,0.04l0.63,0.23l1.25,0.13l0.29,0.02l1.44-0.17l0.09-0.04l0.59-0.21l0.34-0.08l1.21-0.12l0.65-0.04l1.42,0.05
          l0.46,0.63l0.17,0.25l0.21,0.46l0.05,0.21l0.05,0.21l-0.05,0.22l-1.91,2.25l-0.13,0.16l-1.77,0.74l-3.24,0.46l-0.25-0.05
          l-0.09-0.04l-0.17-0.12l-0.13-0.02l-0.25,0.04l-0.09,0.04l-2.56,1.04l-0.09,0.05l-0.15,0.13l-1.17,1.17l-1.29,3.85l-0.08,0.21
          l-0.01,0.13l0.05,0.21l0.06,0.08l0.13,0.13l1.72,1.65l0.23,0.21l0.08,0.05l0.3,0.13h0.09l-0.04,0.16l-0.05,0.09l-0.08,0.05
          l-1.93,0.4l-0.12-0.04l-0.08-0.08l-0.08-0.19l-0.12-0.17l-0.08-0.08l-0.7-0.46h-0.15l-0.09,0.04l-0.26,0.16l-0.42,0.29l-1.1,0.86
          l-0.53,0.74l0.29,0.19l0.12,0.13l-0.99,0.66l-0.61,0.29l-3.32,1.46l-2.75,0.79l-0.29,0.29l-0.08,0.13l-0.01,0.12v0.25l0.04,0.72
          l0.04,0.09l0.05,0.08l0.4,0.7l0.21,0.7l-0.02,0.13l-0.04,0.09l-0.16,0.29l-0.05,0.08l-0.08,0.05l-0.17,0.09l-0.23,0.06l-1.34,0.21
          l-0.13-0.01l-0.12-0.04l-0.32-0.25l-0.08-0.08l-0.32-0.36l-1.63-0.32l-0.59-0.09l-3.09-0.46l-0.23-0.02l-0.93,0.17l-0.44,0.12
          l-0.22,0.05l-0.79,0.17l-0.3,0.09l-1.95,0.87l-0.83,0.42l-1.8,1l-0.16,0.13l-0.04,0.09h0.29l0.49-0.05l0.25-0.17l0.46-0.53
          l0.13-0.13l0.17-0.12l0.19-0.08l0.23-0.04h0.09l0.05,0.05l-1.07,1.76l-0.57,0.61l-1.48,0.63l-0.13,0.01l-0.25,0.02l-0.25-0.05
          l-0.09-0.05l-0.08-0.08v-0.12l0.05-0.09l0.13-0.13l0.12-0.04l0.29,0.02l0.12-0.04l0.08-0.05l0.04-0.08v-0.12l-0.19-0.4l-0.4-0.76
          l-0.05-0.08l-0.08-0.08l-1.27-0.89l-0.12,0.02l-0.05,0.08l-0.91,1.16l0.01,0.3l0.29,0.66l0.08,0.08l-0.4,0.64l-0.79,0.3l-2.18,1.04
          l-4.4,2.28l-0.36,0.19l-1.13,0.66l-0.44,0.26l-0.51,0.32l-0.4,0.3l-0.08,0.06l-0.19,0.22l-0.05,0.09l-0.02,0.05l-0.3,0.96
          l-0.01,0.13l0.01,0.12l0.05,0.08l-4.04,3.2l-1.95,1.12l-2.01,1.06l-1.17,0.32l-1.16,0.26l-0.96,0.57l-2.29,1.61l-1.55,0.96
          l-0.68,0.44l-2.84,2.18l-0.82,0.09l-0.57,0.57l0.04,0.21l0.08,0.08l0.09,0.05l0.93,0.26l0.61,0.08h0.12l0.12-0.02l0.21-0.05
          l0.29-0.16l0.25-0.17l0.19-0.08l0.15,0.02l0.09,0.05l0.01,0.05l-0.12,0.78l-0.29,0.79l-0.16,0.26l-0.32,0.4l-0.26,0.3l-0.15,0.13
          l-0.3,0.25l-0.09,0.06l-0.12,0.01l-0.36-0.08l-0.25,0.19l-0.13,0.13l-0.4,0.43l-0.38,0.44l-0.17,0.25l-0.09,0.17l-0.12,0.29
          L4074.4,1429.77L4074.4,1429.77z"/>
        <path id="path200" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4128.1,1405.26l-1.29-0.42l-0.19,0.09l-0.09,0.04
          l-0.25,0.04l-0.61,0.04l-0.49-0.05l-0.21-0.09l-0.05-0.08l-0.12-0.17l-0.04-0.09l-0.09-0.3l0.02-0.13l0.19-0.36l0.05-0.08
          l0.08-0.05l0.09-0.05l0.4-0.17l0.33-0.22l0.23-0.19l0.4-0.44l0.19-0.23l0.16-0.29l0.04-0.12l0.09-0.32l0.04-0.25l0.04-0.12
          l0.15-0.26l0.08-0.05l0.12-0.04l0.13,0.02l0.78,0.25l0.09,0.04l0.17,0.12l0.57,0.55l0.05,0.08l0.13,3.16l-0.01,0.12l-0.08,0.08
          l-0.08,0.05l-0.19,0.09l-0.21,0.08L4128.1,1405.26L4128.1,1405.26z"/>
        <path id="path202" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4130.67,1402.76l-0.08-0.08l-0.04-0.09l0.15-0.25
          l0.09-0.04l0.09,0.02l0.3,0.12l0.08,0.05l0.04,0.09l-0.05,0.09l-0.4,0.09l-0.13,0.01L4130.67,1402.76L4130.67,1402.76z"/>
        <path id="path204" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4106.73,1405.83l-0.12-0.04l-0.05-0.08l-0.23-0.46
          l0.04-0.09l0.42-0.36l0.09-0.05h0.13l1.08,0.13l0.09,0.05l0.62,0.87l0.04,0.09l0.02,0.12l-0.04,0.08L4106.73,1405.83
          L4106.73,1405.83z"/>
        <path id="path206" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4078.23,1426.13l-0.09-0.05l-0.05-0.26l0.34-0.91
          l0.87-1.1l0.38-0.47l3.45-2.24l0.55,0.02l-3.58,2.69l-0.25,0.19l-0.29,0.26l-0.4,0.44l-0.12,0.17l-0.23,0.34l-0.16,0.26l-0.15,0.26
          l-0.17,0.38L4078.23,1426.13L4078.23,1426.13z"/>
      </g>
    );
  } else if (props.state === 'AL') {
    stateSelected = (
      <g id="AL" transform="translate(-3550.8686,-814.85365)">
        <path id="path215" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4296.65,1147.84l-0.34-0.44l-4.28-2.73l-0.19,0.08
          l-0.38-0.02l-0.17-0.08l-1.93-0.93l-0.46-0.32l-1.61-1.33l-0.12-0.15l-0.09-0.17l-0.15-0.38l-0.15-0.59l-1.16-1.8l-2.94-1.5
          l-2.01-1.24l-0.93-0.64l-0.74-0.34l-1.78-0.59l-0.66-0.13l-2.07-0.44l-4.02-1.8l-2.71-1.37l-1.16-0.44l-0.04-0.36l-0.12-0.4
          l-0.08-0.17l-0.16-0.09l-1.59-0.79l-0.42,0.12l-0.25-0.02l-0.66-0.16l-0.38-0.16l-0.16-0.09l-0.55-1.34l-0.05-0.19l0.01-0.09
          l0.83-0.51l0.55-0.51l0.26-0.26l0.08-0.17l0.29-0.78l0.4-0.4l0.34-0.19l0.42-0.12l1.08-0.21l0.42-0.12l0.17-0.09l0.16-0.12
          l0.13-0.13l1.41-1.78l1.44-2.03l0.12-0.95l0.89-0.7l0.21-0.05l0.51,0.53l0.57,0.87l0.08,0.17l1.9,0.34l0.19-0.12l0.42-0.12
          l0.25-0.02l0.64,0.12l2.46,1.93l0.44,0.34l0.23,0.23l0.4,0.59l1.41,1.5l0.4,0.38l1.2,0.83l0.85,0.42l0.23,0.08l2.21,0.55l2.07-0.61
          l0.44-0.12l0.22-0.04l0.19-0.02l0.25-0.02l1.52,0.09l1.08,0.09l1.03,0.13l0.19,0.05l1.33-0.16l0.44-0.26l1.74-1l0.19-0.02
          l0.61,0.12l0.43,0.02l0.46-0.05l1.91-1.25l0.22-0.16l2.54-2.69l0.16-0.53l0.51-0.51l1.07-0.79l0.32-0.21l2.45-0.3l1.48,0.25
          l-0.05,0.19l0.16,0.16l1.04,0.44l0.23,0.04l0.21-0.04l0.19-0.08l0.62-0.44l0.83-0.49l1.06-0.51l0.19-0.08l0.4-0.13l1.12-0.12
          l0.29,0.12l2.24,0.93l1.1,0.02l2.33,0.26l0.17,0.12l0.25,0.21l-0.25,0.72l-0.08,0.17l-0.29,0.51l-0.21,0.32l-0.26,0.25l-0.16,0.12
          l-0.23,0.3l-1.16,2.25l-0.08,0.21l-0.04,0.21l-0.66,0.93l-0.95,1.17l-0.47,0.55l-4.13,4.49l-1.06,1.06l-2.39,1.5l-0.93,1.78
          l-0.57,1.08l-0.53,0.79l-2.52,3.54l-0.12,0.13l-0.13,0.13l-1.38,1.25l-0.16,0.13l-0.64,0.4l-1.04,0.83l-0.66,0.7l-0.47,0.57
          l-0.12,0.16l-0.06,0.34l-0.57,1.38l-1.25,1.91l-0.13,0.13l-0.23,0.12L4296.65,1147.84L4296.65,1147.84z"/>
      </g>
    );
  } else if (props.state === 'RS') {
    stateSelected = (
      <g id="RS" transform="translate(-3550.8686,-814.85365)">
        <path id="path236" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M3979.05,1572.65l0.06-0.08l0.04-0.08l-0.04-0.12
          l-0.06-0.09l-0.16-0.16l-1.03-0.96l-0.51-0.46l-0.21-0.12l-1.2-0.57l-1.71-0.42l-0.4-0.08l-0.29-0.04l-0.25-0.05l-0.19-0.09
          l-2.12-1.74l-0.3-0.26l-0.3-0.29l-0.26-0.29l-1.08-1.17l-0.12-0.16l-0.08-0.19l-0.15-0.63l-0.04-0.21l0.15-0.55l-0.04-0.23
          l-0.51-1.12l-1.5-2.1l-2.42-2.11l-0.04-0.21l-0.15-0.15l-0.51-0.34l-0.19-0.12l-0.19-0.09l-2.6-1.16l-0.09-0.04l-0.16-0.02
          l-0.12,0.04l-0.29,0.15l-1.31,0.05l-2.32-1.57l-1.17-0.96l-0.13-0.16l-0.47-0.51l-0.15-0.16l-0.53-0.34l-0.51-0.23l-0.42-0.17
          l-0.02-1.33l-0.08-0.3l-0.04-0.12l-0.05-0.09l-0.05-0.08l-2.07-2.03l-0.08-0.06l-0.21-0.09l-0.13-0.01l-1.74-0.3l-0.29-0.04
          l-0.3-0.02l-0.51,0.05l-0.85-0.08l-0.25-0.08l-0.23-0.08l-0.33-0.13l-0.43-0.26l-0.91-0.66l-0.55-0.46l-0.66-0.61l-1.51,0.04
          l-0.08,0.08l-0.09,0.04l-0.12,0.02h-0.13l-0.13-0.02l-0.13-0.04l-0.19-0.09l-2.29-1.27l-0.26-0.17l-0.15-0.15l-0.17-0.25
          l-1.44-2.41l0.02-0.12l-0.02-0.23l-0.04-0.12l-0.05-0.09l-0.29-0.4l-0.12-0.16l-1.29-1.5l-1.9-1.42l-1.06-0.76l-0.12-0.05
          l-0.13-0.02l-0.55-0.02l-0.21,0.08l-0.08,0.08l-0.05,0.08l-0.08,0.21l-0.02,0.13l-0.59,1.44l-1.67,1.29l-1.31,0.96l-0.17,0.12
          l-0.12,0.02l-0.89,0.09l-0.38,0.04l-1.44-0.01l-0.42-0.04l-0.05-0.25l0.12-1.95l0.04-0.49l0.04-0.27l0.06-0.21l0.25-0.32l0.08-0.19
          l0.12-0.36l0.02-0.25l-0.02-0.22l-0.25-0.55l-0.23-0.32l-2.18-2.29l-2.28-2.24l-0.59-0.44l-0.93-0.53l-1.38-0.93l-2.35-1.94
          l-0.3-0.25l-0.4-0.44l-0.38-0.46l-1.27-1.27l-0.17-0.16l-2.79-2.08l-0.4-0.19l-0.93-0.17l-1.38,0.01l-2.52,0.17l-0.19,0.12
          l-0.46,0.38l-0.13,0.16l-0.65,0.78l-0.12,0.17l-0.01,0.25l0.01,0.23l0.02,0.36l-0.08,0.19l-0.74,0.68l-0.17,0.13l-0.17,0.09
          l-0.25,0.05l-1.51-0.29l-0.17-0.13l-0.19-0.09l-0.15-0.01l-0.57,0.12l-2.58,0.16l-0.16-0.01l-0.42-0.08l-0.12-0.01l-0.21-0.08
          l-0.3-0.15l-0.17-0.12l-0.08-0.08l0.04-0.36l0.05-0.09l0.02-0.25l-0.02-0.12l-0.05-0.09l-0.16-0.13l-0.09-0.05l-0.55-0.21
          l0.49-0.08l0.55-0.16l0.38-0.17l0.76-0.51l0.25-0.17l2.6-2.42l0.3-0.29l0.12-0.17v-0.13l0.05-1.1l-0.02-0.55l0.02-0.13l0.05-0.23
          l0.26-0.57l0.17-0.26l0.13-0.16l0.22-0.21l0.17-0.09l0.29-0.13l0.42-0.13l0.63-0.06l1.08-0.04l0.53-0.17l0.17-0.12l0.16-0.13
          l0.21-0.21l0.21-0.21l0.49-0.51l2.52-2.81l1.9-1.74l2.18-2.28l0.05-0.08l0.12-0.17l0.65-1.21l0.04-0.23v-0.13l-0.02-0.34l0.02-0.13
          l0.15-0.42l0.12-0.17l0.3-0.42l0.55-0.57l0.16-0.13l0.32-0.25l0.72-0.4l0.36-0.21l1.59-0.36l0.15-0.16l0.21-0.36l2.12-4.07
          l0.04-0.38l0.05-0.23l0.04-0.12l0.12-0.17l0.16-0.12l0.23-0.05l1.9-0.89l1.97-1.95l1.04-1.23l0.61-1.38l1.61-2.21l0.06-0.08
          l0.16-0.12l0.19-0.09l0.12-0.04l0.25-0.04h0.42l1.86,0.17l0.09,0.05l0.12,0.16l0.04,0.21v0.12l0.12,0.29l0.13,0.13l0.12,0.04
          l0.12-0.04l0.08-0.05l0.12-0.17l0.08-0.21l0.29-0.83l0.02-0.26l-0.04-0.21l-0.04-0.09l-0.16-0.25l-0.08-0.08l-0.17-0.12l-0.51-0.23
          l-0.89-0.42l-0.08-0.08l-0.06-0.08l-0.04-0.09v-0.12l0.09-0.19l0.08-0.08l0.17-0.12l0.3-0.12l0.59-0.12l0.59-0.25l0.79-0.62
          l0.06-0.08l0.12-0.17l0.04-0.17l0.83-0.15l2.56-0.89l1.25-1.12l0.05-0.08l0.04-0.25l-0.05-0.32v-0.12l0.02-0.12l0.12-0.16
          l0.91-0.93l0.19-0.09l2.49-1.2l0.25-0.02l2.03-0.19h0.12l0.74-0.23l2.08-1.84l0.15-0.26l0.05-0.23l0.05-0.78l1.46-2.41l0.13-0.16
          l0.08-0.05l0.09-0.04l0.12-0.02l0.13,0.02l0.08,0.05v0.12l-0.04,0.09l-0.02,0.13l0.02,0.12l0.04,0.09l0.09,0.05l0.12,0.04
          l0.25-0.02l1.42-0.17l0.12-0.04l1.08-0.46l1.86-0.76l1.5-0.42l1.76-0.23l1.34-0.51l0.08-0.05l0.16-0.13l0.78-0.74l1.63-1.76
          l0.12,0.04l0.44,0.02l0.23-0.05l0.3-0.12l0.4-0.29l0.38-0.3l0.08-0.06l0.33-0.38l0.13-0.16l0.3-0.53l0.06-0.08l1.99-0.64l0.79-0.12
          l0.36,0.42l1.41,0.08l2.88,0.34l3.09-0.96l0.83-0.29l0.09-0.05l0.13-0.16l0.15-0.25l0.04-0.09l0.05-0.09l0.09-0.16l0.15-0.13
          l0.08-0.02l0.12,0.04l1.31,0.47l0.12,0.04l0.08,0.05l0.05,0.08l0.04,0.09v0.12l-0.01,0.12l-0.23,0.61l-0.09,0.17h1.06l2.58-0.08
          l0.34-0.09l1.65,1.06l0.08,0.08l0.12,0.04l0.23-0.05l1.1-0.3l1.78-0.08l1.63,0.93l0.7,0.32l1.12-0.17l2.03-0.29l1.88,0.21
          l2.39-0.04l0.83-0.17h0.13l0.12,0.01l0.12,0.04l1.48,0.53l1.07,0.42l0.09,0.04l2.86,1.37l0.16,0.13l0.05,0.08l0.04,0.09v0.13
          l0.17,0.62l0.82,1.65l0.16,0.13l0.08,0.05l1.69,0.15l0.13-0.01l0.09-0.05l0.08-0.06l-0.02-0.12l-0.05-0.08l-0.08-0.19l0.05-0.21
          l0.08-0.05l2.04-0.23l0.21,0.08l0.65,0.36l3.49,1.95l2.56,1.5l1.08,1.24l0.08,0.08l0.47,0.36l0.12,0.04l0.13,0.02l0.62-0.09
          l0.12,0.04l1.8,1.03l0.08,0.05l2.92,2.98l0.74,0.78l1.95,2.29l0.08,0.19l0.21,0.59l0.34,0.87l0.05,0.08l2.58,2.81l0.09,0.05
          l0.93,0.44l0.46,0.13l3.47,0.4l4.7,0.74l1.84-0.23l0.09-0.05l0.57-0.16l0.13-0.02l3.98,0.34l0.08,0.06l0.46,0.76l0.21,0.55
          l0.38,1.06l-0.05,0.23l-2.45,1.55l-0.09,0.05l-0.12,0.04l-0.76,0.25l-0.51,0.05l-0.12-0.02l-0.17,0.12l-0.12,0.16l-0.05,0.09
          l-0.51,1.24l-0.08,0.32l-0.04,0.36l-0.02,0.25l0.02,0.36l0.12,0.68l0.17,0.76l0.02,0.23v0.26l-0.08,0.21l-0.4,1.08l-0.61,1.38
          l-0.09,0.05l-0.63,0.21l-0.19-0.08l-0.09-0.19l-0.49,0.25l-0.95,1.03l-0.62,0.79l-0.12,1.24v0.25l0.04,0.09l0.13,0.16l2.01,1.03
          l0.12,0.04l0.13-0.01l0.15-0.13l0.04-0.09v-0.26l-0.08-0.3l-0.13-0.29l-0.15-0.13l-0.08-0.05l0.06-0.99l1.74-0.87l0.13-0.01
          l0.25,0.04l0.23,0.08l0.33,0.12l0.32,0.12l0.63,0.26l0.26,0.17l0.13,0.16l0.04,0.09l0.29,0.4l0.3,0.26l1.42,0.91l0.55-0.02
          l-0.95,0.86l-0.66,0.87l-0.19,0.25l-2.01,2.98l-0.83,1.37l-1.82,3.05l-0.25,0.46l-0.13,0.29l-0.36,0.74l-0.08,0.19l-1.67,4.23
          l-1,2.67l-0.12,0.44l-0.13,0.42l-0.33,0.78l-0.13,0.29l-0.17,0.38l-0.76,1.44l-1.12,1.8l-0.89,1.21l-0.19,0.23l-0.95,1.29
          l-2.96,4.06l-0.17,0.25l-0.19,0.36l-0.04,0.12l-0.4,0.72l-0.12,0.17l-0.7,0.86l-0.65,0.76l-1.88,2.01l-1.31,1.37l-2.39,2.42
          l-0.44,0.42l-1.33,1.1l-1.9,1.52l-4.72,3.61l-2.54,1.38l-0.42,0.17l-0.66,0.21l-0.21,0.08l-0.29,0.16l-2.56,1.34l-0.29,0.16
          l-0.51,0.34l-0.55,0.43l-0.08,0.08l-0.12,0.17l-0.61,0.79l-0.6,0.82l-1.71,2.15l-0.05,0.08h-0.12l-0.06-0.08l-0.08-0.19l-0.04-0.12
          l0.02-0.36l0.19-0.38l0.17-0.26l0.17-0.25l0.17-0.27l0.04-0.09l0.08-0.34l0.43-1.9v-0.12l-0.08-0.21l-0.05-0.08l-0.57-0.68
          l-0.46-0.53l-0.3-0.29l-0.08-0.19v-0.13l0.12-0.17l0.05-0.08l0.21-0.09l0.36-0.08h0.16l0.42,0.05l0.49,0.12l0.25,0.05l0.21,0.08
          l0.19,0.09l0.17,0.12l0.21,0.21l0.13,0.16l0.05,0.08l0.08,0.08l0.09,0.05l0.33,0.13l0.12,0.01l0.29-0.01l0.38-0.08l0.34-0.09
          l2.98-1.42l0.68-0.47l0.33-0.25l2.08-2.32l1.41-1.55l0.76-0.83l0.02,0.08l0.08,0.08l0.34,0.13l0.12,0.04l0.29,0.04l0.42-0.04
          l0.25-0.04l0.34-0.09l0.21-0.08l0.86-0.59l0.34-0.25l0.36-0.36l0.7-0.86l0.12-0.17l0.08-0.21l0.34-1.31l0.19-0.79l0.04-0.22
          l0.02-0.27l-0.08-0.93l-0.04-0.36l-0.04-0.12l-0.17-0.38l-0.02-0.12l0.02-0.25l0.12-0.99l0.06-0.23l0.05-0.08l0.21-0.09l1.72,0.08
          l0.91,0.26l-0.02,0.12l0.15,0.74l0.02,0.12l0.09,0.06l0.36-0.08l0.05-0.08l0.08-0.19l-0.36-1.42l0.02-0.12l0.61-2.12l0.59-0.6
          l1.03-0.42l1.9-0.96l0.09-0.06l0.34-0.22l0.3-0.25l0.16-0.16l0.19-0.22l0.19-0.36l0.12-0.3l0.09-0.3l0.06-0.22l0.3-1.61l0.05-0.61
          l-0.26-2.41l0.61-0.85l0.08,0.21l0.12,0.16l0.49,0.62l0.21,0.08l0.13-0.02l0.12-0.02l0.29-0.13l0.13-0.16l0.05-0.09l0.04-0.09
          l0.08-0.25l0.38-1.78l0.12-0.83v-0.38l-0.05-0.34l-0.16-0.27l-1.03-1.09l-0.16-0.13l-0.21-0.08h-0.29l-0.12,0.02l-0.09,0.05
          l-0.08,0.05l-0.05,0.08l-0.25,0.46v0.34l0.12,0.01l0.08,0.08l0.04,0.09l0.02,0.12v0.13l-0.09,0.3l-0.05,0.09l-0.05,0.08l-0.08,0.05
          l-0.09,0.05l-0.12,0.04l-0.25,0.05l-0.13,0.02l-1.59-0.06l-2.39,0.4l-0.12,0.04l-0.13,0.16l-0.05,0.09l-0.04,0.12l-0.04,0.22
          l-0.13,0.82l-0.01,0.25l0.01,0.12l0.09,0.19l-0.72-0.21l-0.99-0.47l-0.08-1.21l0.19-0.36l0.04-0.12v-0.09l-0.02-0.12l-0.04-0.09
          l-0.17-0.25l-0.66-0.44l-0.09-0.05l-0.12-0.04h-0.13l-0.61,0.13l-0.12,0.04l-0.13,0.15l-0.09,0.05l-0.12,0.02l-0.21-0.05
          l-0.57-0.47l-0.89-1.33l-0.51-1.44l0.05-0.09l0.08-0.21l0.08-0.34v-0.13l-0.59-0.68l-0.09-0.05l-0.13,0.02l-0.09,0.05l-0.08,0.08
          l-0.04,0.09l-0.53,2.66l0.01,0.12l0.49,2.25l0.04,0.09l0.08,0.08l1.52,2.07l-0.15,0.13l-0.63,0.78l-0.46,1.07l-0.08,0.19
          l-0.32,1.33l-0.05,0.22l0.05,0.83v0.08v0.08l0.04,0.13l0.04,0.12l0.05,0.25l0.29,1.61l-0.02,0.26l-0.05,0.34l-0.04,0.12l-0.05,0.08
          l-0.08,0.05l-0.12-0.04l-0.44-0.29l-0.05-0.08l0.02-0.09l0.08-0.05h0.15l0.12-0.02l0.08-0.08l-0.04-0.36l-0.08-0.44l-0.49-1.27
          l-0.05-0.09l-0.05-0.04l-0.05-0.01h-0.08l-0.38-0.32l-0.38,0.19l-0.19,0.23l-0.08,0.21l-0.02,0.12l-0.02,1.38l-1.31,2.75
          l-0.16,0.13l-0.13,0.15l-0.13,0.42l-0.12,0.57l0.04,1.25l0.02,0.25l0.05,0.21l0.12,0.16l0.21,0.21l-0.96,0.62l-1.12,0.47
          l-0.65,0.08l-0.12,0.04l-0.17,0.12l-0.13,0.16l-0.25,0.46l-0.16,0.4l-0.15,0.55v0.13l-4.93,1.57l-0.12,0.02l-0.78,0.51l-0.05,0.08
          l-0.49,1.12l-0.12,0.3l-0.04,0.38l-0.12,2.18l-0.7,1.59l-0.05,0.08l-0.38,0.63l-0.12,0.15l-0.17,0.13l-0.3,0.12l-0.57,0.17
          l-0.66,0.21l-0.63,0.25l-0.19,0.09l-0.16,0.12l-0.08,0.05l-0.13,0.16l-0.05,0.23v1.57l0.59,0.87l0.34,1.04l-0.12,0.04l-0.91,0.4
          l-0.19,0.09l-0.05,0.09l-0.05,0.23l-0.19,1.33l0.38,0.59l0.08,0.05l0.12,0.04h0.15l0.23-0.08l0.4-0.16l1.16,0.43l0.42,0.23
          l0.21,0.86v0.13l-0.08,0.08l-1.16,0.59l-1.27,0.89l-0.16,0.13l-0.19,0.23l-0.3,0.42l-0.26,0.46l-1.99,3.47l-0.09,0.17l-0.04,0.12
          l-0.89,2.71l-0.3,0.96l-0.05,0.36l-0.08,0.34l-0.12,0.44l-0.83,2.12l-0.38,0.76l-2.08,3.85l-0.16,0.26l-0.12,0.17l-1.67,2.03
          l-0.21,0.23l-3.24,2.63l-0.74,0.57l-3.75,2.88l-1.31,1.2l-0.25,0.19l-0.25,0.19l-0.51,0.36l-0.7,0.47l-0.17,0.12l-1.12,0.59
          l-0.23,0.05l-0.13-0.01l-0.25-0.08l-2.07-0.99l-0.09-0.05l-0.08-0.05l-0.13-0.16l-0.09-0.3l0.12-0.87l0.12-0.7l0.17-0.95l0.12-0.74
          l0.06-0.49l0.08-0.61v-0.38l-0.15-1.65l-0.09-0.43l-0.08-0.32l-0.09-0.44l-0.09-0.57l-0.05-0.34v-0.36l0.08-0.62l0.08-0.36
          l0.04-0.12l0.02-0.09l0.15-0.29l0.16-0.29l0.15-0.29l0.19-0.23l0.21-0.23l0.25-0.19l0.51-0.36l2.62-1.78l1-1.61l1.42-1.13
          L3979.05,1572.65L3979.05,1572.65z"/>
      </g>
    );
  } else if (props.state === 'PB') {
    stateSelected = (
      <g id="PB" transform="translate(-3550.8686,-814.85365)">
        <path id="path412" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4253.22,1094.07l-0.12-0.15l0.04-0.21l0.4-0.34
          l0.15-0.12l0.19-0.09l0.29-0.21l0.4-0.4l0.09-0.17l1.03-2.03l0.61-1.24l0.29-0.78l0.04-0.23l-0.29-1.2l-0.05-0.19l-0.19-0.02
          l-0.47,0.04l-0.22-0.04l-0.17-0.12l-1.29-0.96l-0.15-0.12l-0.05-0.21l0.04-0.21l0.09-0.19l0.12-0.15l0.05-0.21l0.02-0.23
          l-0.13-0.66l-0.05-0.19l-0.19-0.61l-0.12-0.16l-0.15-0.12l-0.17-0.09l-0.22-0.04l-0.17-0.08l-0.16-0.12l-0.12-0.16l-0.05-0.19
          l-0.04-0.23l0.15-1.03l0.15-0.4l0.12-0.15l0.4-0.13l0.17-0.09l0.17-0.09l0.47-0.3l0.12-0.16l0.74-1.27l0.06-0.21l-0.06-0.19
          l-0.15-0.36l-0.13-0.16l-0.16-0.12l-0.22-0.55l0.01-0.23l0.05-0.21l1.03-2.92l0.21-0.57l0.17-0.34l0.23-0.32l0.29-0.23l0.26-0.29
          l0.09-0.17l0.04-0.21l0.05-0.49l0.7-0.57l1.13,1.13l1.21,0.87l1.1,0.51l3.43,0.79l0.16-0.12l1.34-1.33l2.29-1.21l1.57-0.78
          l0.17-0.09l0.91-0.82l0.19-0.61l0.05-0.21l-0.17-0.44l0.02-0.17l0.34-0.83l0.13-0.13l0.19-0.09l2.08-0.79l1.65-0.61l1.54-0.17
          l0.36,0.05l2.29-0.68l1.46-0.63l0.15,0.02l0.72,0.09l0.61,0.44l0.05,0.21l0.09,1.06l-0.13,0.4l-0.15,0.12l-0.16,0.09l-2.88,2.99
          l-0.44,0.63l-0.29,0.51l-0.08,0.17l-0.16,0.38l-0.05,0.21l-0.12,0.83l-0.04,0.38l-0.09,0.38l-0.19,0.44l-0.4,0.42l-0.21,0.09
          l-0.46,0.09l-0.19,0.02l-0.19,0.08l-0.21,0.13l-0.12,0.21l-0.05,0.21l-0.17,0.82l-0.04,0.21l0.02,0.4l0.05,0.44l0.13,0.26
          l1.77,0.29l0.61-0.09l0.85,0.04l0.23,0.02l0.68,0.21l0.21,0.08l0.21,0.16l0.13,0.21l0.09,0.21l0.02,0.19l0.02,0.63l0.04,0.25
          l0.08,0.21l0.12,0.19l0.19,0.16l0.21,0.05l3.15-1.25l0.6-0.3l2.16-0.09l1.21,0.13l0.89,0.12l0.23,0.08l0.21,0.16l0.93,1.1
          l0.02,0.19l-0.04,0.19l-0.29,0.61l-0.13,0.19l-0.19,0.42l-0.05,0.23l0.05,0.38l0.13,0.19l0.16,0.19l0.19,0.17l0.29,0.23l0.23,0.02
          l2.81-1.06l0.12-0.16l0.44-0.62l0.09-0.16l0.09-0.19l0.59-1.25l0.04-0.17l-0.01-0.25l-0.05-0.16l-0.13-0.16l-0.16-0.12l-0.21-0.3
          l-0.08-0.17l-0.13-0.61l-0.02-0.23l-0.01-0.68l0.01-0.23l0.08-0.19l0.25-0.3l0.16-0.12l0.21-0.02l0.33,0.19l0.29,0.25l0.17,0.09
          l0.21,0.05l0.25-0.16l-0.04-0.19l-0.19-0.61l-0.08-0.17l-0.42-0.87l-0.12-0.15l-0.29-0.23l-0.43-0.36l-0.13-0.21l0.3-1.65
          l0.05-0.21l0.12-0.17l2.07-1.48l0.19-0.05l1.48-0.05l0.21,0.04l0.4,0.34l-0.02,0.21l-0.21,0.61l-0.02,0.19l0.05,0.21l0.08,0.23
          l0.17,0.21l0.44,0.25l0.21,0.05l0.85,0.09l1.46,0.15l1.67,0.19l0.21,0.08l0.43,0.36l0.23,0.04l0.64,0.02l1.76-0.17l2.25-0.26
          l0.51-0.09l3.79,0.23l0.44,0.05l0.19,0.05l0.19,0.08l0.82,0.38l0.21,0.21l0.17,0.12l0.34,0.17l1.2,0.4l0.23,0.04l0.25,0.02
          l0.17-0.08l0.3-0.21l0.15-0.13l0.17-0.09l0.25-0.02l4.15,0.26l1.13-0.29l0.09,0.64l-0.04,1.12l-0.04,0.23l-0.01,0.25l0.01,0.25
          l0.08,0.44l0.12,0.4l1.37,4.34l0.29,0.61l0.15,0.63l-0.02,0.21l-0.26,0.23v0.87l-0.46,0.55l0.02,0.55l-0.19,0.59l0.19-0.05
          l0.12-0.17l0.51-0.76l0.32-1.06l0.19-0.85l0.21,0.17l0.04,0.87l-0.25,0.59l0.12,0.87l0.72,1.12l0.01,0.23l-0.13,3.02l-0.05,1
          l-0.16,2.2l-0.44,0.74h-1.14l-0.66-0.4l-1.55-1.38l-0.23-0.29l-0.08-0.05l-0.15-0.12l-0.4-0.25l-0.57-0.21l-0.42-0.12l-2.45-0.25
          l-0.62-0.05l-0.34,0.05l-2.66,1.27l-1.8,1.55l-0.09,0.57l-0.09,0.57l-0.25,0.95l-0.04,0.09l-0.05,0.08l-0.16,0.12l-2.07,1.08
          l-0.21,0.05l-2.35,0.51l-1.38-0.02v0.74l-0.96,0.63l-2.54,0.36l-0.25,0.02l-2.25-0.13l-2.35-0.19l-1.97,0.74l0.04,0.09l0.01,0.12
          l-0.01,0.23l-0.08,0.19l-0.42,0.38l-0.16,0.12l-0.08,0.05l-0.19,0.08l-0.12,0.02l-0.86-0.05l-0.76-0.04l-0.21,0.05l-0.09,0.04
          l-0.25,0.16l-0.79,0.7l-0.59,0.72l-0.09,0.16l-0.02,0.12l0.08,0.17l0.19,0.21l0.05,0.08l0.19,0.32l0.04,0.09l0.05,0.32v0.25
          l-0.04,0.09l-0.16,0.25l-0.34,0.3l-2.08,1.8l-0.15,0.12h-0.38l-0.62,0.04l-0.25,0.02l-0.21,0.05l-0.21,0.05l-0.26,0.13l-1.65,0.85
          l-0.12-0.02l-0.21-0.05l-0.36-0.17l-1.2-0.66l-0.16-0.12l-1.06-1.04l-0.04-0.09l-0.09-0.42l-0.05-0.21l-0.34-1.73l-0.01-0.12
          l0.01-0.12l0.04-0.09l0.05-0.08l0.12-0.16l0.13-0.16l0.05-0.19l-0.02-0.12l-0.04-0.09l-0.12-0.16l-0.19-0.08l-0.78-0.29l-0.21-0.05
          l-0.25-0.02l-0.12,0.02l-0.34,0.19l-0.06,0.08l-0.13,0.13l-0.19,0.08l-1.33,0.38l-0.21-0.04l-0.19-0.19l-0.02-0.12l0.15-0.38
          l0.05-0.08l1.86-2.11l1.16-1.04l0.08-0.05l0.09-0.17l0.08-0.3l0.02-0.12l-0.08-0.44l-0.38-0.79l-0.44-0.89l-0.04-0.09l-0.05-0.21
          v-0.26l0.01-0.25l0.21-0.59l0.21-0.17l0.17-0.09l0.53-0.26l0.09-0.02l1.17-0.23l1.54-0.57l0.17-0.09l0.05-0.05l0.05-0.08l0.02-0.12
          l-0.02-0.23l-0.44-1.44l-0.12-0.16l-0.15-0.12l-3.94-2.2l-0.19-0.05l-0.12-0.02l-1.61,0.49l-0.21,0.05l-2.62,1.52l-0.32,0.74
          l-0.76,1.08l-0.05,0.05l-1.48,0.89l-0.29,0.12l-0.21,0.05l-0.43,0.08l-0.33,0.08l-0.26,0.13l-0.17,0.12l-3.09,2.16l-0.23,0.17
          l-0.21,0.19l-0.12,0.16l-1.42,1.55l-1.99,0.29l-3.05,1.17l-0.12,0.02l-0.12-0.02l-0.76-0.25l-0.08-0.05l-0.06-0.08V1098l0.04-0.09
          l0.05-0.21l0.01-0.25l-0.01-0.12l-0.17-0.49l-0.4-0.79l-0.12-0.16l-0.15-0.12l-0.19-0.05l-0.12,0.02l-0.21,0.05l-0.34,0.3
          l-0.05,0.05l-0.62,0.49l-1.76,0.4l-1.14-0.3l-0.16-0.09l-0.05-0.08l-0.82-0.96l-0.43-0.74l-0.38-0.57l-0.47-0.09L4253.22,1094.07
          L4253.22,1094.07z"/>
        <path id="path414" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4326.41,1082.6l-0.05-0.19l0.02-0.05l0.05-0.08
          l0.04-0.04l0.08-0.02l0.04,0.04l0.02,0.08l-0.02,0.12l-0.09,0.19L4326.41,1082.6L4326.41,1082.6z"/>
        <path id="path416" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4325.94,1083.79l-0.02-0.08l0.36-0.4l0.05-0.04
          l0.01,0.12l-0.17,0.36l-0.17,0.09L4325.94,1083.79L4325.94,1083.79z"/>
      </g>
    );
  } else if (props.state === 'RN') {
    stateSelected = (
      <path id="RN" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M705.6,255.91l-0.16,0.12l-0.36,0.13l-0.21,0.05l-0.51-0.05
      l-0.17-0.09l-0.08-0.19l0.49-2.29l1.73-2.75l0.87-0.96l0.3-0.23l0.22-0.04l0.32,0.05l0.17,0.12l0.29,0.23l0.12,0.16l0.15,0.12
      l0.3,0.02l0.43-0.05l0.42-0.12l0.19-0.08l0.16-0.12l0.13-0.13l0.12-0.16l0.13-0.4l0.05-0.25l0.08-0.22l0.16-0.32l0.85-0.96
      l0.13-0.13l0.74-0.57l1.67-2.9l0.05-0.19l-0.02-0.21l-0.09-0.17l-0.06-0.19l-0.04-0.21l0.63-1.88l0.12-0.17l0.16-0.09l0.53-0.25
      l0.17-0.12l0.16-0.12l0.23-0.29l0.91-1.21l0.51-0.91l1.9-3.89l1.16-2.54l0.13-0.4l0.09-0.16l0.12-0.16l0.26-0.29l1.12-0.99
      l0.59-0.47l0.61-0.46l0.76-0.42l2.88-0.96l1.88-0.61l1.2-0.38l0.25,0.46l0.09,0.16l0.12,0.16l0.22,0.29l0.59,0.59l0.36,0.17
      l0.47,0.05l0.23-0.04l0.96-0.02l2.08-0.02l0.12,0.02l1.33,0.4l0.08,0.05l0.29,0.25l0.89,0.95l0.12,0.15l0.09,0.26l0.06,0.08
      l0.13,0.12l0.53,0.38l1.55,0.61h0.09l1.31,0.02l0.59-0.21l2.04-0.19l0.12-0.02l0.82,0.05l1.59,0.25l0.91,0.32l3.13-0.26l0.19-0.05
      l0.95-0.38l1.24-0.44l0.47-0.04l0.21,0.02l0.64,0.05l1.61,0.51l3.11,0.55l0.85,0.05l2.39,0.7l0.17,0.08l0.51,0.26l1.2,0.93
      l0.12,0.16l0.63,0.93l0.19,0.32l1,1.9l1.71,3.75l0.59,2.01l0.05,0.21v0.25l-0.08,0.46l0.01,0.25l0.05,0.23l0.91,3.37l0.19,0.32
      l0.13,0.13l0.53,2.46l0.16,1.67l0.09,0.38l0.89,0.66l1.16,3.28l0.16,0.63l0.09,0.83l0.02,0.42l-1.13,0.29l-3.64-0.26l-0.76,0.02
      l-0.17,0.09l-0.16,0.13l-0.3,0.21l-0.17,0.08l-0.25-0.02l-0.22-0.04l-1.2-0.4l-0.34-0.17l-0.17-0.12l-0.21-0.21l-0.82-0.38
      l-0.19-0.08l-0.19-0.05l-0.44-0.05l-1.97-0.23l-2.33,0.09l-2.25,0.26l-1.76,0.17l-0.65-0.02l-0.23-0.04l-0.44-0.36l-0.21-0.08
      l-1.67-0.19l-1.46-0.16l-0.85-0.09l-0.21-0.05l-0.44-0.25l-0.17-0.21l-0.08-0.23l-0.06-0.21l0.02-0.19l0.21-0.61l0.02-0.21
      l-0.4-0.34l-0.21-0.04l-1.48,0.05l-0.19,0.05l-2.07,1.48l-0.12,0.17l-0.05,0.21l-0.3,1.41l0.13,0.46l0.44,0.36l0.29,0.23l0.12,0.15
      l0.42,0.87l0.08,0.17l0.19,0.61l0.04,0.19l-0.12,0.16l-0.36-0.05l-0.17-0.09l-0.29-0.25l-0.32-0.19l-0.21,0.02l-0.16,0.12l-0.25,0.3
      l-0.08,0.19l-0.02,0.23l0.02,0.68l0.01,0.23l0.13,0.61l0.08,0.17l0.21,0.3l0.16,0.12l0.13,0.16l0.05,0.16l0.01,0.25l-0.04,0.17
      l-0.59,1.25l-0.09,0.19l-0.09,0.16l-0.44,0.62l-0.12,0.16l-2.81,1.07l-0.23-0.02l-0.29-0.23l-0.19-0.17l-0.15-0.19l-0.13-0.19
      l-0.05-0.19l0.05-0.42l0.19-0.42l0.13-0.19l0.29-0.61l0.04-0.19l-0.02-0.19l-0.93-1.1l-0.21-0.16l-0.23-0.08l-0.89-0.12l-1.21-0.13
      l-2.16,0.09l-0.61,0.3l-3.15,1.25l-0.21-0.05l-0.19-0.16l-0.12-0.19l-0.08-0.21l-0.04-0.25l-0.02-0.63l-0.02-0.19l-0.09-0.21
      l-0.13-0.21l-0.21-0.16l-0.21-0.08l-0.68-0.21l-0.23-0.02l-0.86-0.04l-0.4,0.09l-1.95-0.29l-0.13-0.26l-0.06-0.44l-0.01-0.4
      l0.04-0.21l0.17-0.82l0.05-0.21l0.12-0.21l0.21-0.13l0.19-0.08l0.19-0.02l0.46-0.09l0.21-0.09l0.4-0.42l0.19-0.44l0.09-0.38
      l0.04-0.25l0.12-0.96l0.05-0.21l0.16-0.38l0.08-0.17l0.29-0.51l0.44-0.63l2.88-2.99l0.15-0.09l0.16-0.12l0.13-0.13l-0.09-1.31
      l-0.05-0.21l-0.61-0.44l-0.72-0.09l-0.16-0.02l-1.46,0.63l-2.29,0.68l-0.23-0.05l-1.67,0.17l-1.65,0.61l-2.08,0.79l-0.19,0.09
      l-0.13,0.13l-0.34,0.83l-0.01,0.17l0.17,0.3l-0.05,0.34l-0.19,0.61l-0.91,0.82l-0.17,0.09l-1.57,0.78l-2.29,1.21l-1.34,1.33
      l-0.15,0.12l-3.44-0.79l-1.1-0.51l-1.21-0.87l-1.13-1.13l-0.7,0.57L705.6,255.91L705.6,255.91z"/>
    );
  } else if (props.state === 'SC') {
    stateSelected = (
      <g id="SC" transform="translate(-3550.8686,-814.85365)">
        <path id="path588" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4058.28,1494.78l-0.19,0.09l-0.26,0.16l-4.32,2.81
          l-0.42,0.3l-3.36,2.69l-0.15,0.13l-1.04,1l-3.47,3.7l-0.51,0.86l-0.57,0.82l-0.25,0.3l-0.55,0.02l-1.42-0.91l-0.3-0.26l-0.29-0.4
          l-0.04-0.09l-0.13-0.15l-0.26-0.17l-0.63-0.26l-0.32-0.12l-0.32-0.12l-0.23-0.08l-0.25-0.04l-0.13,0.01l-1.74,0.87l-0.05,0.99
          l0.08,0.05l0.16,0.13l0.13,0.29l0.08,0.3v0.26l-0.04,0.09l-0.15,0.13l-0.13,0.02l-0.12-0.04l-2.01-1.03l-0.13-0.16l-0.04-0.09
          v-0.25l0.12-1.24l0.63-0.79l0.95-1.02l0.49-0.25l0.09,0.19l0.08,0.08l0.74-0.21l0.09-0.05l0.6-1.38l0.4-1.08l0.08-0.21v-0.26
          l-0.02-0.23l-0.17-0.76l-0.12-0.68l-0.02-0.36l0.02-0.25l0.04-0.36l0.08-0.32l0.51-1.24l0.05-0.09l0.12-0.16l0.17-0.12l0.12,0.02
          l0.51-0.05l0.76-0.25l0.12-0.04l0.09-0.05l2.45-1.55l0.05-0.23l-0.38-1.06l-0.21-0.55l-0.46-0.76l-0.08-0.05l-3.98-0.34l-0.13,0.02
          l-0.57,0.16l-0.09,0.05l-1.84,0.23l-4.7-0.74l-3.47-0.4l-0.46-0.13l-0.93-0.44l-0.09-0.05l-2.58-2.8l-0.05-0.08l-0.34-0.87
          l-0.21-0.59l-0.08-0.19l-1.95-2.29l-0.74-0.78l-2.92-2.98l-0.08-0.05l-1.8-1.03l-0.12-0.04l-0.63,0.09l-0.13-0.01l-0.12-0.04
          l-0.47-0.36l-0.08-0.08l-1.08-1.24l-2.56-1.5l-3.49-1.95l-0.65-0.36l-0.21-0.08l-2.04,0.23l-0.08,0.05l-0.05,0.21l0.08,0.19
          l0.05,0.08l0.02,0.12l-0.08,0.05l-0.09,0.05l-0.13,0.02l-1.69-0.15l-0.08-0.06l-0.15-0.13l-0.82-1.65l-0.17-0.62v-0.13l-0.04-0.09
          l-0.06-0.08l-0.15-0.13l-2.86-1.37l-0.09-0.04l-1.06-0.42l-1.48-0.53l-0.12-0.04l-0.12-0.01h-0.13l-0.83,0.17l-2.39,0.04
          l-1.88-0.21l-2.03,0.29l-1.12,0.17l-0.7-0.32l-1.63-0.93l-1.78,0.08l-1.1,0.3l-0.23,0.05l-0.12-0.04l-0.08-0.08l-1.65-1.06
          l-0.34,0.09l-2.58,0.08h-1.06l0.09-0.17l0.23-0.61l0.02-0.12v-0.12l-0.04-0.09l-0.05-0.08l-0.08-0.05l-0.12-0.04l-1.31-0.47
          l-0.12-0.04l-0.08,0.02l-0.15,0.13l-0.09,0.16l-0.05,0.09l-0.04,0.09l-0.15,0.25l-0.13,0.16l-0.09,0.05l-0.83,0.29l-3.09,0.96
          l-2.88-0.34l-1.41-0.08l0.59-0.79l1.2-2.41l0.21-0.47l0.55-1.27l0.13-0.42l-0.34-1.99l-0.04-0.09l-0.34-1.61l0.19-0.74l0.3-2.77
          l-0.06-2.52l-0.01-0.22l0.01-0.12l0.08-0.19l0.29-0.26l0.09-0.05l0.38-0.46l0.23-0.46l0.12-0.32l0.02-0.12l0.47-0.12l0.53,0.04
          l0.47,0.12l0.21,0.08l2.73-0.09l0.46-0.25l0.36-0.17l0.12-0.02h0.4l0.36,0.05l1.03,0.19l0.12,0.04l0.16,0.13l0.13,0.38l0.08,0.08
          l0.17,0.12l2.2,1.37l0.19,0.09l0.12,0.04l0.44,0.02l2.66-0.05h0.25l0.34-0.08l0.65-0.19l0.3-0.12l0.17-0.09l0.21-0.08l0.36-0.05
          l0.53-0.01l0.13,0.01l1.94,0.53l0.44,0.13l1.91,0.57l2.11,0.61l1.9,0.27l0.25-0.04l0.81-0.16l2.11,0.26l0.12,0.04l0.29,0.13
          l0.91,0.51l0.13,0.13l0.04,0.22l0.04,0.09l0.12,0.15l0.08,0.08l1.8,1.13l2.83,0.38l1.78,0.09h4.4l0.7-0.16l0.25,0.04l1.48,2.04
          l0.13-0.49l0.08-0.05l0.17-0.09l0.68-0.29l0.09-0.04l0.4,0.01l0.49,0.02l0.22-0.04l0.08-0.05l0.61-0.47l0.15-0.12l0.08-0.08
          l0.05-0.09l0.02-0.05l0.04-0.12v-0.13l-0.01-0.36l-0.09-0.3l-0.17-0.38l-0.36-0.59l-0.12-0.13l-0.09-0.17l-0.23-1.1l-0.02-0.12
          l0.06-0.21l0.74-1.44l0.12-0.16l0.7-0.68l0.22-0.19l2.22-1.12l0.21-0.09l1.17,0.12l0.25,0.05l0.79,0.17l0.62,0.08l1.27,0.08
          l0.25-0.04l2.16-1.06l0.81-0.83l0.12-0.16l0.09-0.17l0.23-0.47l0.04-0.09l0.05-0.36l0.09-0.3l0.09-0.17l0.16-0.13l0.12-0.04
          l1.69-0.59l1.25-0.16h0.16l0.12,0.02l1.57,0.82l0.99,0.04l2.84-0.19l1.04-0.17l1.33-0.36l1.03-0.29l0.13-0.01l0.78,0.01h0.15
          l1.07,0.36l0.21,0.08l0.15,0.12l0.34,0.34l0.49,0.46l1.86,1.44l0.89,0.51l0.38,0.19l1.08,0.47l0.09,0.04l0.59,0.13l0.13,0.01
          l0.99,0.02l0.4-0.17l2.62-1.59l0.53-0.29l0.53-0.29l0.29-0.26l0.05-0.09l0.34-0.47l0.26-0.29l0.21-0.19l0.34-0.23l0.29-0.13
          l1.63-0.59l0.12-0.02l0.09,0.04l0.02,0.09l-0.05,0.23l0.04,0.12l0.08,0.05l0.12,0.01h0.79l2.03-0.19l0.12-0.04l0.08-0.05l0.05-0.08
          l0.04-0.09l0.05-0.21l0.05-0.09l0.09-0.04l0.26-0.02l1.54,0.05l2.12,0.05l1.91,0.05l0.26-0.02l0.29-0.05l-0.47,1.08l-0.04,0.23
          l0.08,0.32l0.36,1.12l0.08,0.19l0.13,0.22l0.05,0.21l-0.01,0.22l-0.09,0.17l-0.15,0.12l-1.17,0.76l-0.42,0.26l-0.09,0.05
          l-0.29-0.01l-0.21-0.08l-0.57-0.29l-0.08-0.05l-0.09-0.17l0.02-0.25l-0.04-0.36l-0.01-0.09l-0.47-1.37l-0.3-0.63l-0.06-0.08
          l-0.08-0.05l-0.12,0.02l-0.05,0.08l0.17,1.24l0.72,2.99l2.03,1.74l0.12,0.16l0.63,0.87l0.04,0.08l-0.04,0.21l-0.09,0.3l-0.05,0.09
          l-0.38,0.57l-0.66,1.25l-0.08,0.19l-0.09,0.46l-0.05,1.38v0.61l0.01,0.22l0.05,0.21l0.06,0.09l0.12,0.16l0.72,0.53l0.17,0.12
          l-0.08,1.93l0.3,4.38l0.04,0.12l0.23,0.44l0.09,0.17l0.34,0.09l0.04,0.04l0.09,0.05h0.15l0.13-0.05l0.13-0.09l0.02-0.05v-0.08
          l0.29-0.17l0.13-0.16l0.12-0.01l0.13,0.01l0.08,0.08l0.22,0.57l0.09,0.32l0.12,0.74l-0.05,0.08l-0.3,0.12l-0.25,0.04l-0.21-0.08
          l-0.04-0.09l0.05-0.08l0.12-0.04l0.13-0.01l0.08-0.05l-0.02-0.12l-0.42,0.05l-0.04-0.04l-0.06-0.02l-0.17-0.02l-0.15,0.04
          l-0.16,0.09l-1.03,0.51l-0.08,0.19l-0.38,4.1l0.78,2.75l-0.16,0.13l-0.61,0.65l-0.05,0.09l0.04,0.23l0.38,1.88l0.53,0.89l0.15,0.13
          l0.12,0.16l0.16,0.26l0.15,0.59l-1.07,2.54l-0.19,1.69l-0.46,2.66l-2.01,5.02l-0.08,0.05l-0.13,0.02l-0.13-0.02l-0.09-0.05
          l-0.08-0.08l-0.17-0.25l-0.25-0.44l-0.05-0.09l-0.02-0.12l0.02-0.12l0.09-0.3l0.17-0.36l0.04-0.09l0.04-0.12l-0.04-0.23l-0.05-0.08
          l-0.96-0.96l-0.13-0.02l-0.12,0.02l-0.05,0.08l-0.09,0.19l-0.05,0.21l-0.01,0.12l0.08,1.44l0.17,0.89l0.04,0.09l0.08,0.05
          l1.16,0.79l0.12,0.01l0.12-0.01l0.12-0.04l-1.29,1.82l-1.42,0.46L4058.28,1494.78L4058.28,1494.78z"/>
        <path id="path590" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4064.24,1451.78l-1.52-1.5l-0.04-0.09l0.05-0.08
          l2.94-2.58l0.09-0.04l0.13-0.02l0.21,0.06l0.17,0.12l0.05,0.08l0.57,0.93l-0.09,0.26l-0.53,0.7l-0.17,0.22l-0.08,0.06l-0.17,0.22
          l-0.09,0.17l-0.65,1.33l-0.04,0.09l-0.09,0.64L4064.24,1451.78L4064.24,1451.78z"/>
        <path id="path592" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4065.45,1478.99l-0.04-0.09l-0.02-0.12l0.02-0.34
          l0.29-1.84l0.36-0.93l0.55-1.63l0.04-0.12l-0.04-0.23l-0.34-0.49l-0.12-0.51l0.08-0.47l0.09-0.59l0.01-0.09l1.65-0.78l0.12-0.02
          l0.21,0.05l0.05,0.08l0.74,0.91l0.01,0.12l-0.66,2.73l-0.12,0.15l-0.68,0.57l-0.21,0.21l-0.72,1.33l-0.05,0.21l-0.02,0.12
          l0.02,0.12l0.02,0.12l0.12,0.16l0.13,0.15l0.09,0.17l-0.05,0.23l-0.08,0.05l-1.04,0.7l-0.19,0.09l-0.12,0.01L4065.45,1478.99
          L4065.45,1478.99z"/>
      </g>
    );
  } else if (props.state === 'BA') {
    stateSelected = (
      <g id="BA" transform="translate(-3550.8686,-814.85365)">
        <path id="path619" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4113.87,1232.09l0.04-0.09l0.82-1.2l0.23-0.3l0.15-0.12
          l0.08-0.05l0.29-0.25l0.13-0.13l0.12-0.16l0.04-0.12l0.19-0.63l0.01-0.23l-0.04-0.34l-0.04-0.21l-0.17-0.74l-0.02-0.12l-0.04-0.09
          l-0.32-0.68l-0.21-0.32l-0.04-0.09l-0.21-0.7l-0.04-0.34l0.08-0.47l0.04-0.12l0.09-0.17l0.55-1.04l0.15-0.25l0.09-0.17l0.04-0.25
          l0.02-0.26l-0.02-0.12l-0.09-0.16l-0.05-0.05l-2.12-1.74l-1.56-1.04l-0.13-0.13l-0.09-0.16l-1.8-3.54l-0.19-0.47l-0.17-0.59
          l-0.27-1.12l-0.12-0.66l-0.12-1.03l-0.08-0.82l0.02-1.16l0.04-0.64l0.02-0.12l1.31-4.31l0.09-0.17l0.13-0.15l0.51-0.26l0.29-0.12
          l0.33-0.19l0.62-0.46l0.21-0.19l0.16-0.26l0.05-0.21l0.08-0.34l0.02-0.49l-0.02-0.12l-0.01-0.09l-0.08-0.19l-0.05-0.08l-0.34-0.05
          l-1-0.34l-0.43-0.25l-0.13-0.13l-0.05-0.21v-0.25l0.34-2.15l0.04-0.23l0.09-0.3l0.08-0.17l0.09-0.17l0.89-0.57l0.69-0.38l0.12-0.16
          l0.04-0.09l0.01-0.12l-0.01-0.12l-0.04-0.09l-0.04-0.09l-0.09-0.17l-0.59-0.61l-1.21-0.79l-0.34-0.19l-1.08-1.16l0,0l-0.08-0.19
          l-0.04-0.47v-0.13l0.08-0.32l0.16-0.36l0.09-0.17l0.3-0.49l0.15-0.25l0.27-0.4l0.34-0.46l0.08-0.05l0.08-0.05l0.15-0.26l0.04-0.36
          l-0.04-1.1l-0.04-0.36l-0.08-0.19l-0.12-0.16l-0.08-0.06l-0.34-0.19l-0.19-0.08l-0.66-0.16l-0.47-0.08l-0.12-0.04l-0.62-0.3
          l-0.23-0.17l-0.13-0.13l-0.21-0.32l-0.09-0.17l-0.05-0.21l-0.19-0.96l0.02-1.48l0.05-2.03l0.08-0.74l0.12-0.3l0.13-0.13l2.16-1.8
          l0.25-0.16l0.38-0.16l0.42-0.12l0.4-0.13l0.17-0.09l1.03-0.68l0.08-0.05l0.04-0.12l-0.01-0.12l-0.3-0.47l-0.15-0.25l-0.19-0.21
          l-0.32-0.23l-0.25-0.16l-0.47-0.21l-0.23-0.05l-0.25-0.04h-0.12l-0.25,0.02l-0.33,0.05l-0.3,0.09l-0.32,0.08h-0.13l-0.08-0.04
          l-0.13-0.13l-0.08-0.19l-0.06-0.21l-0.05-0.46l0.02-0.38l0.08-0.32l0.04-0.09l0.05-0.08l0.12-0.16l0.16-0.13l0.15-0.09l0.55-0.25
          l0.46-0.19l0.3-0.09l0.4-0.12l0.59-0.21l0.17-0.09l0.47-0.3l0.13-0.13l0.04-0.09l0.02-0.12l0.02-0.38l-0.02-0.22l-0.04-0.09
          l-0.05-0.08l-0.16-0.12l-0.4-0.29l-0.44-0.23l-0.19-0.08l-0.57-0.25l-0.59-0.21l-1.04-0.3l-0.32-0.09l-0.49-0.05l-1.21-0.16
          l-1.21-0.16l-0.19-0.08l-0.15-0.12l-0.26-0.25l-1.69-1.88l-0.12-0.16l-0.09-0.17l-0.12-0.29l-0.01-0.12l-0.02-0.25l0.02-0.25
          l0.17-0.51l0.13-0.29l0.3-0.61l0.23-0.3l0.17-0.09l0.38-0.16l0.17-0.08l0.17-0.09l0.25-0.29l0.17-0.38l0.21-0.59l0.09-0.3
          l0.17-0.49l0.12-0.3l0.61-1.31l0.32-0.63l0.12-0.13l0.21-0.19l0.23-0.17l0.59-0.32l0.61-0.17l0.53-0.12l0.21-0.05l0.26-0.13
          l0.05-0.05l0.05-0.09l0.01-0.12l-0.01-0.12l-0.08-0.19l-0.72-0.99l-0.79-0.74l-0.04-0.09v-0.12l0.05-0.21l0.05-0.08l0.26-0.25
          l1.67-1.5l2.12-1.54l0.51-0.29l1.65-0.79l1.8-0.95l0.59-0.32l0.23-0.16l0.49-0.44l0.13-0.13l0.12-0.29l0.05-0.23l0.02-0.12v-0.25
          l0.01-0.25l0.25-0.42l0.13-0.16l0.08-0.05l0.26-0.12l0.13-0.04l0.74-0.05l0.51-0.02l1.5,0.02l0.19,0.05l0.16,0.12l0.16,0.12
          l0.21,0.19l1.13,1.17l0.43,0.62l0.25,0.4l0.13,0.25l0.04,0.09l0.09,0.42l0.01,0.49l0.09,0.66l0.02,0.12l0.34,0.83l0.04,0.08
          l2.12,2.96l3.4,1.55l0.19,0.08l1.31,0.57l0.09,0.04l0.12,0.02h0.25l0.25-0.04l0.29-0.12l0.08-0.05l0.13-0.13l0.08-0.05l0.26-0.12
          l0.09-0.04l0.55-0.09l0.36-0.02l0.36,0.06l0.57,0.12l0.42,0.12l0.36,0.05h0.13l0.25-0.02l0.21-0.05l0.19-0.08l0.25-0.16l0.13-0.13
          l0.12-0.29l0.12-0.29l0.06-0.21l0.21-0.47l0.13-0.26l0.13-0.13l0.17-0.09l0.57-0.23l0.4-0.12l0.34-0.05l0.82-1.06l1.34-1.03
          l0.17-0.08l2.54-0.86l0.12-0.02l0.51,0.04l1.04,0.17l0.38,0.13l0.47,0.21l0.29,0.12l0.08,0.04l0.19,0.08l0.21,0.05l0.12,0.02h0.13
          l0.12-0.02l1.38-0.46l0.17-0.08l0.3-0.23l3.23-2.66l1.71-2.63l0.13-0.26l0.89-1.76l0.05-0.09l0.82-1.34l0.74-0.85l0.13-0.13
          l0.05-0.08l0.08-0.19l0.02-0.09l0.05-0.23l0.09-0.57l0.34-2.92l0.02-0.34l-0.04-0.47l-0.04-0.36l-0.09-0.4l-2.41-4.86l-0.26-0.38
          l-0.12-0.26l-0.04-0.23v-0.26l0.08-0.34l0.02-0.12l0.09-0.17l0.08-0.05l3.47-1.71l0.38-0.16l2.39-0.02l1.84,0.57l0.34,0.96
          l0.05,0.08l0.19,0.21l0.08,0.05l0.08,0.04l0.12,0.02h0.13l2.5-0.53l0.91-0.34l0.63-0.3l0.09-0.04l0.21,0.04l1.42,0.44l0.09,0.04
          l1.95,1l0.08,0.05l0.08,0.17l-0.02,0.12l-0.21,0.32l-0.04,0.25l0.02,0.12l0.09,0.16l0.05,0.08l0.19,0.08l0.91,0.3l0.79,0.09
          l2.86-0.25l1.8-0.78l2.08-0.13l0.57-0.91l0.32-0.47l0.21-0.19l1.59-1.42l0.3-0.23l0.15-0.09l0.12-0.02l0.12,0.02l0.96,0.05
          l1.74-0.21l0.21-0.05l0.65-0.29l0.17-0.09l0.15-0.12l0.34-0.46l0.32-0.47l0.12-0.16l0.15-0.12l0.21-0.06l0.91,0.04l1.31,0.08
          l0.3,0.08l0.19,0.08l0.08,0.05l0.09,0.16l0.02,0.12l0.08,0.19l0.05,0.08l0.15,0.12l0.12,0.04h0.13l0.17-0.09l0.29-0.25l1.73-1.61
          l0.21-0.3l0.02-0.12l-0.09-1.31l-0.02-0.36l-0.17-0.74l-0.02-0.12l0.05-0.23l0.04-0.09l0.05-0.08l0.08-0.05l2.2-0.29l0.25-0.02
          l0.23,0.04l0.08,0.05l0.29,0.13l0.23,0.05l0.19-0.05l1.1-0.76l0.06-0.05l1.74-2.63l0.13-0.26l0.49-1.27l0.57,0.34l0.42,0.13
          l0.23,0.04h0.26l0.57-0.09l0.19-0.08l0.25-0.16l0.08-0.05l2.66,0.38l3.32,1.61l0.25,0.17l0.29,0.25l0.17,0.23l0.04,0.09l-0.02,0.25
          l-0.09,0.44l-0.09,0.7l0.04,1.73l0.08,0.32l0.72,2.15l0.08,0.06l0.12,0.01l1.07,0.38l1.42,0.51l0.12,0.15l0.09,0.17l0.23,1.29
          l0.04,0.96l-0.34,0.79l-0.29,0.38l-0.12,0.16l-0.38,0.76l1.37,0.47l0.79,0.26l0.99-0.19l0.21-0.05l0.19-0.08l0.29-0.25l0.4-0.66
          l0.13-0.16l0.15-0.12l2.14-0.83l0.19,0.08l0.46,0.09l0.23-0.04l0.36-0.17l0.06-0.19l0.74-2.88l0.12-0.82l0.06-0.47l0.05-0.34
          l0.09-0.19l0.12-0.16l0.23-0.3l0.25-0.29l0.23-0.13l0.47,0.04l0.21,0.05l0.32,0.21l0.16,0.13l0.3,0.23l0.17,0.09l0.49,0.05
          l0.33-0.05l2.6-1.07l0.17-0.12l0.13-0.13l0.91-0.93l0.23-0.3l0.09-0.17l0.19-0.61l0.02-0.21l-0.04-0.21l-0.05-0.21l-0.17-0.34
          l-0.05-0.21l-0.02-0.49l0.08-0.19l0.3-0.21l0.4-0.12l0.22-0.06l1.99-0.36l0.22-0.02l0.7-2.25l0.05-0.23l0.15-0.12l0.44-0.08
          l1.48-0.34l0.53-0.26l1.23-0.61l0.15-0.09l0.13-0.16l0.29-0.23l0.68-0.38l0.17-0.08l0.46-0.08l0.36-0.02l1.42,0.51l0.16,0.09
          l0.15,0.13l0.08,0.17l0.05,0.47l0.09,0.15l0.68,0.89l0.6,0.51l1.07,0.47l2.88,0.95l2.24,0.34l0.44-0.05l0.89,0.17l0.16,0.09
          l1.78,1.12l0.09,0.17l0.02,0.23l0.17,0.63l0.05,0.19l0.36,0.95l0.09,0.17l0.33,0.21l0.19,0.05l0.4-0.12l0.09-0.16l0.08-0.19
          l0.08-0.83l-0.02-0.25l0.12-0.68l0.05-0.19l0.25-0.29l0.17-0.17l0.22-0.05l0.44-0.05l0.22,0.02l0.42,0.09l0.36,0.17l0.16,0.12
          l0.12,0.16l0.05,0.19l-0.04,0.34l-0.16,0.34l-0.12,0.16l-0.17,0.12l-0.12,0.13l-0.29,0.51l0.44,0.87l0.25,0.26l0.32,0.23l0.33,0.17
          l0.38,0.16l0.23,0.02l0.23-0.05l1.57,0.83l-0.01,0.85l0.01,0.23l0.05,0.21l1.04,2.82l0.08,0.19l0.34,0.51l-0.02-0.04l0.06,0.32
          l0.55,1.34l0.16,0.09l0.38,0.16l0.66,0.16l0.25,0.02l0.42-0.12l1.59,0.79l0.16,0.09l0.08,0.17l0.12,0.4l0.04,0.36l-0.17,0.12
          l-0.13,0.13l-0.47,1l-0.05,0.19l-0.04,0.23l0.3,2.21l0.04,0.21l1.08,2.07l0.46,0.85l1.59,1.61l0.72,0.61l0.25,0.3l0.57,0.76
          l0.19,0.34l0.05,0.19l0.04,0.21l-0.17,3.51l-0.09,0.44l-0.09,0.44l-0.61,1.08l-0.12,1.06l0.21,0.57l0.72,2.75l-0.25,0.55
          l-0.13,0.21l-0.19,0.17l-0.63,0.42l-0.4,0.23l-0.23,0.12l-0.89,0.38l-0.4,0.17l-0.43,0.17l-0.65,0.19l-0.23-0.05l-0.21-0.21
          l-0.25-0.19l-0.44-0.26l-0.4-0.21l-0.61-0.12l-0.44-0.02l-0.7,0.02l-0.19,0.05l-0.25,0.09l-0.19,0.21l-0.17,0.21l-0.61,1.63
          l-0.05,0.21l0.01,0.72l0.02,0.25l0.04,0.21l0.08,0.21l0.08,0.17l0.96,1.29l1.24,0.74l0.19,0.05l0.08,0.17l0.29,0.74l0.19,0.91
          l1.48,1.55l0.29,0.26l0.15,0.36l0.04,0.21l-0.08,1.48l-0.08,0.19l-0.21,0.16l-0.19,0.17l-0.08,0.21l0.19,0.4l0.63,0.61l0.19,0.05
          l2.2,1l0.09,0.17l0.4,0.64l0.12,0.13l0.3,0.21l0.36,0.17l0.38,0.16l1.52,0.61l2.6-0.68l0.59-0.21l0.17-0.08l0.17-0.12l0.78-0.01
          l0.43,0.49l-0.61,1.24l-2.08,4.52l-0.08,0.17l-0.25,0.57l-0.44,1.03l-0.27,0.66l-0.26,0.53l-0.53,1.06l-0.47,0.83l-0.3,0.49
          l-1.76,2.46l-1.5,2.07l-1,1.12l-0.21,0.19l-0.32,0.61l-0.08,0.17l-0.05,0.34l-0.7,1.55l-0.87,1.08l-5.23,5.77l-0.08,0.05
          l-0.53,0.38l-0.08,0.05l-2.18,1l-0.19,0.08l-0.22,0.04l-0.7-0.02l-0.12-0.02l-0.09-0.04l-0.05-0.08l0.47-1.57l0.05-0.08l0.08-0.06
          l0.3-0.08l0.12-0.02l0.13-1.21l-0.02-0.12l-0.02-0.09l-0.47-2.12l-0.04-0.09h-0.12l-0.7,0.04l-1.14-0.08l-0.32-0.21l-0.29-0.38
          l-0.01-0.12l-0.23-1.08l-0.7-0.96l-0.05-0.02l-0.13,0.13l-0.05,0.08l-0.32,0.61l-0.53,1.76l-0.02,0.12v0.13l0.05,0.08l0.13,0.13
          l0.01,0.12l-0.19,0.47l-0.57,1l-0.08,0.08l-0.47,0.46h-0.12l-0.12-0.02l-0.09-0.04l-0.51-0.25l-0.04-0.09l-0.38-1.25l-0.19-0.72
          l-0.02-0.12l0.09-0.3l0.08-0.05l0.12-0.02l0.21-0.05l0.08-0.05l0.12-0.15l-0.01-0.12l-0.17-0.23l-0.08-0.05l-0.08,0.02l-0.62,0.87
          l-0.3,0.72l0.38,0.79l0.74,1.2l0.08,0.08l0.42,0.23l0.17,0.05l0.15-0.05l0.08-0.05l0.12-0.15l0.12-0.02l0.12,0.02l1.16,0.29
          l0.19,0.12l-0.65,2.11l-0.51,0.68l-0.93,0.66l-0.08,0.05l-0.22,2.12l-0.08,0.05l-1.37,1l-0.13,0.13l-0.17,0.23l-0.25,0.57
          l-0.16,0.38l-0.09,0.63l0.23,1.03l-0.23-0.13l-0.57-0.25l-0.86-0.08l-0.25,0.42l-0.01,0.12l-0.44,2.96l0.32-0.72l0.08-0.17
          l0.12-0.15l0.09-0.02l0.12,0.02l0.55,0.34l0.16,0.12l0.21,0.59l-0.29,0.49l0.08,0.74l0.38,0.36l0.42,1.1l-0.04,0.23l-0.66,1.86
          l-0.59,0.99l0.38,2.46l0.29,0.74l0.04,0.79l0.02,0.05l-0.02,0.08l-0.08,0.09l-0.55,0.51l-0.09-0.04l-0.05-0.05l-0.32-0.34
          l-0.34,0.12l-0.05,0.08l-0.04,0.09l-0.06,1.16l0.04,0.09l0.66,0.51l0.12-0.05l1.38-1.95l0.01-0.08l0.04-0.34l-0.25-1.2l-0.13-0.59
          l-0.25-0.3l-0.04-0.09l0.02-0.25l0.04-0.09l0.06-0.05l0.83-0.47l0.12,0.02l0.16,0.25l0.13,0.26v0.36l-0.08,1.91l-0.44,1.37
          l-0.02,0.05l-0.13,0.17l-0.06,0.09l-0.04,0.12l-0.46,1.25l-0.02,0.09l-0.08,0.83l-0.19,1.8l-0.62,3.79l-0.43,1.55l-0.13,0.26
          l-0.05,0.21l0.01,0.25l0.05,1.1l0.17,1.65l0.01,0.12l0.08,0.17l0.05,0.05l0.12,0.02l0.29,0.19l0.4,2.79l0.02,0.21l0.02,0.99
          l-0.04,0.22l0.23,4.06l0.76,5.36l0.3,2.22l0.47,1.55l0.46,0.99l0.29,1.5l-1.38,3.91l-1.33,3.96l-0.62,2.56l-0.34,1.46l-0.25,1.21
          l-0.08,0.85l-0.05,0.47l-0.25,1.08l-0.08,0.3l-0.04,0.09l-0.17,0.23l-0.16,0.25l-0.17,0.49l-0.05,0.34l-0.04,0.36l-0.01,1l0.08,0.3
          l0.08,0.19l-0.25,1.16l-0.53,1.99l-0.47,1.57l-0.12,0.16l-0.13,0.29l-0.04,0.25l-0.01,0.23l-0.08,1.52l-0.01,1.1l0.08,1.33
          l0.3,3.16l0.08,0.57l0.04,0.23l0.05,0.21l0.12,0.29l0.3,0.51l0.12,0.16l0.29,0.25l0.17,0.23l0.04,0.09l0.02,0.12l-0.02,0.12
          l-0.04,0.09l-2.69,3.33l-0.34,0.21l-0.17,0.09l-0.49,0.19l-1.21,0.29l-0.47,0.19l-0.17,0.12l-0.23,0.17l-0.26,0.29l-1,1.29
          l-2.24,3.13l-0.15,0.25l-0.26,0.55l-0.09,0.3l-0.19,0.76l-0.19,0.89l-4.38-2.63l-5.48-3.33l-0.04-0.02l-0.36-0.61l-0.38-0.74
          l-0.08-0.19l0.02-0.25l0.13-0.29l0.17-0.23l0.27-0.29l0.12-0.15l0.09-0.17l0.08-0.19l0.04-0.12l0.02-0.25l-0.05-0.34l-0.13-0.4
          l-0.13-0.26l-0.3-0.49l-0.16-0.12l-2.88-2.12l-1.97-1.29l-0.09-0.04l-0.08-0.08l-0.04-0.09l-0.08-0.32l-0.05-0.34l0.01-0.25
          l0.05-0.21l0.27-0.57l0.01-0.12l-0.04-0.36l-0.05-0.19l-0.55-1.29l-0.06-0.08l-0.13-0.02l-0.59,0.19l-0.09,0.05l-0.06,0.08
          l-0.08,0.17l-0.05,0.21l-0.15,0.26l-0.17,0.09l-0.12,0.02l-0.12-0.02l-0.08-0.05l-0.08-0.08l-0.05-0.08l-0.02-0.12v-0.26l0.12-1.37
          l0.05-0.23l0.13-0.26l0.05-0.08l0.33-2.83v-0.26l0.89-3.53l0.04-0.12l0.08-0.17l0.12-0.16l0.66-0.82l0.08-0.05l0.29,0.09l0.4,0.13
          l1.04,0.08l1.27,0.04l0.26-0.02l0.25-0.16l0.79-0.55l0.08-0.05l0.04-0.09l0.01-0.12l-0.01-0.25l-0.05-0.21l-0.16-0.38l-0.12-0.17
          l-0.26-0.26l-0.08-0.05l-0.05-0.08l-0.04-0.12v-0.25l0.19-2.82l0.06-0.21l0.09-0.17l0.16-0.13l2.69-2.01l2.94-2.43l0.29-0.26
          l0.29-0.38l1.34-2.84l0.04-0.25l-0.02-0.23l-0.09-0.3l-0.32-0.72l-0.04-0.09l-0.12-0.16l-0.7-0.95l-0.12-0.13l-0.08-0.05
          l-0.19-0.08l-0.22-0.04l-0.25,0.01l-0.3,0.09l-0.21-0.05l-0.29-0.12l-0.44-0.25l-0.15-0.12l-1.67-1.52l-1.55-1.31l-0.32-0.08
          l-0.36-0.02l-0.95,0.08l-0.21,0.05l-0.89-0.05l-2.67-0.72l-1.52-0.59l-0.26-0.26l-0.17-0.09l-0.66-0.29l-0.32-0.08l-3.16-0.62
          l-2.21-0.21l-0.33,0.04l-0.09,0.04l-0.47,0.21l-0.08,0.05l-0.08,0.05l-0.04,0.09l-0.12,0.16l-0.63,0.57l-0.47,0.19l-1.33,0.4
          l-1.41-0.17l-0.21-0.08l-0.21-0.05l-1.06-0.25l-0.25-0.02l-0.22,0.04l-0.51,0.25l-0.17-1.63l-0.19-1.63l-0.08-0.65l-0.08-0.65
          l-0.04-0.25l-7.9-7.2l-0.04-0.04l-0.3-0.17l-0.12-0.02l-0.12,0.02l-4.52,1.16l-0.76,0.3l-0.72-0.74l-0.93-0.79l-0.12-0.02
          l-0.12,0.02l-0.29,0.25l-0.21,0.05h-0.23l-0.76-0.19l-0.21-0.06l-0.19-0.08l-2.82-1.31l-0.25-0.17l-0.7-0.49l-0.08-0.08l-0.12-0.15
          l-0.19-0.21l-0.47-0.32l-0.85-0.57l-0.09-0.04l-0.12,0.02l-0.53,0.09l-0.29,0.12l-0.25,0.01l-0.12-0.01l-0.55-0.36l-0.08-0.05
          l-1.29-0.96l-0.13-0.13l-3.2-2.79l-1.34-0.65l-3.23-0.87l-0.23-0.04l-0.26-0.02l-0.63,0.02l-0.34,0.05l-0.79,0.25l-2.11,0.93
          l-0.17,0.09l-0.08,0.06l-0.12,0.15l-0.02,0.25l-0.09,0.17l-0.64,0.68l-0.13,0.12l-0.55,0.12l-0.32,0.08l-0.25,0.01l-0.22-0.04
          l-5.88-1.74l-0.36-0.17l-0.08-0.06l-0.27-0.26l-0.09-0.17l-0.08-0.32l-0.21-1.33v-0.08l0.08-0.59l0.12-0.44l0.62-1.34l0.61-1.24
          l0.34-0.64l-0.02-0.08l-1.04-0.51l-0.72-0.21l-0.21-0.05l-1.84-0.36l-2.52-0.05l-0.12,0.02l-0.12-0.02l-0.83-0.21l-0.09-0.04
          l-0.08-0.05l-0.3-0.21l-0.08-0.08l-0.53-0.05l-1.67-0.05l-4.21,1.69l-0.47,0.19l-1.8,1.06l-3.41,2.54l-0.3,0.78l-0.47,0.53
          l-4.02,2.18l-0.19,0.08l-0.4,0.13l-0.12,0.02l-0.21-0.08l-0.12-0.02l-1.29,0.17l-0.3,0.23l-0.05,0.08l-1.06,1.31l-2.08,1.76
          l-0.25,0.16l-0.79,0.4l-0.19,0.08l-0.57,0.09h-0.13l-0.26-0.13l-0.34-0.08l-0.51,0.02l-0.08,0.05l-0.68,0.91l-0.53,0.79l-0.97,1.29
          l-0.05,0.08l-0.21,0.19l-0.29,0.25l-0.76,0.43l-0.17,0.08l-0.19-0.08l-0.12-0.02l-2.12-0.42l-0.7,0.04l-0.09,0.04l-0.16,0.12
          l-0.12,0.16l-0.17,0.36l-0.12,0.16l-0.34,0.46l-0.08,0.05l-1.41,0.99l-0.08,0.05l-0.19,0.08h-0.12l-0.12-0.02l-0.16-0.12
          l-0.05-0.08l-0.02-0.12l0.02-0.12l0.04-0.09l0.21-0.46l0.25-0.29l0.29-0.51l0.09-0.17l0.04-0.09l0.25-0.57l0.08-0.19l0.55-1.59
          l-0.29-1.44l-0.34-1.42L4113.87,1232.09L4113.87,1232.09z"/>
        <path id="path621" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4251.69,1199.18l-0.04-0.09l-0.08-0.57l-0.02-0.23
          l-0.04-0.47l0.04-0.09l0.15-0.12l0.57-0.23l0.17-0.09l0.36-0.3l0.32-0.34l0.61-0.62l0.21-0.46l0.04-0.12l-0.05-0.21l-0.16-0.25
          l-0.13-0.13l-0.08-0.05l-0.12-0.4l0.05-0.08l0.23-0.04l0.12,0.02l0.47,0.19l0.09,0.04l0.08,0.05l0.29,0.25l0.12,0.15l0.49,0.79
          l0.08,0.26l-0.08,0.3l-0.13,0.13l-1.21,0.89l-1.76,1.37l-0.47,0.42L4251.69,1199.18L4251.69,1199.18z"/>
        <path id="path623" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4247.99,1207.39l-0.09-0.04l0.05-0.78l0.32-0.44
          l-0.96-0.26l-0.49-0.44l-0.04-0.09l0.08-1.13l0.04-0.09l0.08-0.05l0.12-0.02l2.07-0.15l0.12,0.01l0.08,0.06l0.08,0.19l0.15,1.03
          v0.25l-0.02,0.12l-0.02,0.12l-0.15,0.38l-0.53,1.25l-0.05,0.08l-0.08,0.05L4247.99,1207.39L4247.99,1207.39z"/>
        <path id="path625" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4247.78,1208.02l-0.01-0.08v-0.09l0.04-0.09l0.08-0.06
          l0.4-0.09l0.21,0.02l0.7,0.17l0.08,0.04l0.21,0.32l0.26,0.59v0.12l-0.04,0.09l-0.17,0.3l-0.16,0.21l-0.08,0.05l-0.66,0.02
          L4247.78,1208.02L4247.78,1208.02z"/>
      </g>
    );
  } else if (props.state === 'RR') {
    stateSelected = (
      <g id="RR" transform="translate(-3550.8686,-814.85365)">
        <path id="path870" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M3867.36,926.58l0.25,2.73l0.3,3.15l0.33,2.45l0.4,4.19
          l0.3,3.92l0.17,1.69l-11.42-0.05l-7.02-0.04l-4.15,0.02l0.04,0.42v0.42l-0.27,0.4l-1.37,1.88l-1.24,1.74l-0.7,1.17l-1.29,2.46
          l-0.08,0.19l-0.02,0.4l0.02,0.38l-0.02,0.38l-0.02,0.17l-0.05,0.23l-0.4,0.93l-0.34,0.57l-0.44,1.1l-0.15,0.4l-0.13,0.65
          l-0.02,0.23l0.02,0.21l0.23,0.55l0.12,0.16l0.4,0.38l0.38,0.4l0.36,0.42l0.12,0.15l0.08,0.17l0.04,0.23l0.02,0.4l-0.05,0.23
          l-0.12,0.16l-0.3,0.22l-0.19,0.08l-1.44,0.4l-0.44,0.09l-0.4,0.09l-0.21,0.08l-0.17,0.08l-0.12,0.16l-0.34,0.64l-0.99,0.82
          l-1.44,0.53l-0.19,0.05l-1.86-0.19l-0.21-0.05l-0.17-0.09l-0.12-0.16l-0.05-0.23l0.02-0.25l-0.13-0.61l-0.13-0.36l-0.57-1.41
          l-0.12-0.13l-2.15-2.41l-0.17-0.08l-3.26-1.08l-0.19-0.05l-0.23-0.04l-0.25-0.02l-1.25,0.09l-0.23,0.02l-0.12,0.16l-0.09,0.42
          l-0.08,0.19l-0.09,0.17l-0.12,0.15l-0.16,0.12l-0.15,0.09l-1.54,0.57l-0.21,0.04l-0.26,0.02l-0.19,0.05l-0.19,0.08l-1.03,0.55
          l-0.34,0.19l-0.15,0.12l-0.13,0.13l-1.03,1.31l-0.12,0.16l-0.17,0.59l-0.65,2.45l-0.12,0.42l-0.01,0.23l0.16,0.89l0.19,0.61
          l0.12,0.42l0.04,0.23V969l-0.32,1.84l-0.29,1.03l-0.29,0.74l-0.25,0.55l-0.12,0.4l0.02,0.23l0.42,2.2l0.12,0.29l-0.13-0.12
          l-0.16-0.09l-2.43-1.04l-0.21-0.05l-0.23,0.04l-0.42,0.12l-0.29,0.12l-0.27,0.13l-0.08,0.05l-0.17,0.08l-0.3,0.05l-0.25-0.02
          l-0.19-0.05l-0.16-0.09l-0.08-0.05l-0.16-0.25l-0.13-0.26l-0.23-0.55l-0.17-0.47l-0.32-0.72l-0.16-0.23l-1.57-1.8l-0.3-0.23
          l-2.16-1.46l-0.19-0.08l-0.38,0.02l-0.23-0.04l-0.17-0.09l-2.79-2.58l-2.86-2.33l-0.12-0.16l-0.09-0.17l-0.02-0.25l0.02-0.49
          l0.09-0.68l0.08-0.17l0.19-0.08l0.63,0.16l0.4,0.12l0.19,0.05l0.26,0.26l0.23,0.02l0.25-0.02l0.21-0.05l0.7-0.83l0.51-0.62
          l0.09-0.17l0.05-0.21l-0.01-0.47l-0.19-1.42l-1.17-3.89l-0.08-0.19l-0.19-0.32l-0.32-0.47l-0.17-0.19l-0.17-0.12l-0.4-0.12
          l-0.21-0.09l-0.21-0.16l-0.17-0.16l-0.19-0.19l-0.16-0.3l-0.99-2.52l-0.4-4.74v-1.21l0.04-0.21l0.16-0.25l0.29-0.4l0.27-0.4
          l0.08-0.17l0.89-3.57l0.02-0.19l-0.02-0.25l-0.05-0.22l-0.16-0.42l-0.13-0.17l-0.38-0.61l-0.12-0.23l-0.05-0.19l-0.12-0.44
          l-0.25-1.16l0.02-0.23l0.16-0.17l0.19-0.16l0.19-0.13l0.21-0.08l0.4-0.23l0.43-0.38l0.34-0.4l0.08-0.21l0.04-0.19l-0.08-0.19
          l-0.12-0.23l-0.29-0.36l-0.4-0.4l-0.16-0.19l-0.13-0.23l-0.08-0.17l-0.12-0.61l-0.05-0.47l-0.04-1.61l-0.13-0.42l-1.06-3.19
          l-0.83-2.24l-1.27-1.88l-0.59-0.44l-0.15-0.19l-1.31-1.93l-0.05-0.23l0.04-0.17l0.85-1.59l1.06-3.54l0.09-0.4l-0.05-0.26
          l-0.09-0.23l-0.19-0.23l-0.21-0.19l-0.19-0.09l-2.29-1.12l-1.65-0.13l-0.59,0.12l-0.17,0.08l-0.19,0.05l-1.17-0.4l-0.23-0.12
          l-1.31-1.21l-0.09-0.23l0.04-0.65l0.04-0.19l-0.04-0.21l-0.17-0.19l-0.17-0.05l-0.21-0.02l-0.21,0.02l-0.21,0.05l-0.38,0.16
          l-0.4,0.16l-0.17,0.09l-0.21,0.05l-0.23,0.06l-0.62-0.04l-0.4-0.13l-0.42-0.19l-0.17-0.17l-0.78-0.85l0.32-0.47l0.05-0.08
          l0.05-0.21l0.21-2.6l-0.12-0.15l-0.17-0.09l-0.19-0.08l-0.12-0.02l-3.89-0.21l-3.81,0.16l-0.57,0.04l-0.36-0.04l-3.74-0.74
          l-0.17-0.08l-0.15-0.23l-0.04-0.21v-0.25v-0.13l0.05-0.21l0.21-0.46l0.05-0.08l0.25-0.42l0.38-0.79l0.05-0.21l0.02-0.12l0.12-1.48
          v-0.25l-0.04-0.09l-0.85-1.97l-1.03-2.07l-1.08-1.88l-0.25-0.4l-0.89-2.52l-0.4-2.75l-0.19-1.71l0.05-0.23l0.12-0.42l0.08-0.17
          l0.12-0.16l0.4-0.38l0.12-0.16l0.12-0.44l0.05-0.34v-0.12l-0.05-0.46l-0.05-0.15l-0.08-0.19l-0.12-0.17l-2.11-2.2l-1.1-0.61
          l-0.76-0.32l-0.65-0.29l-0.17-0.09l-0.23-0.17l-1.12-1.12l-2.07-2.12l-0.19-0.21l-0.21-0.3l-0.19-0.34l-0.13-0.25l-0.57-1.12
          l-0.19-0.7l-0.12-0.4l-0.04-0.09l-0.09-0.16l-0.16-0.12l-0.3-0.09l-0.65-0.29l-0.09-0.04l-0.23-0.17l-0.05-0.05l-0.09-0.17
          l-0.04-0.34l0.02-0.49l0.02-0.38l0.02-0.12l0.02-0.12l0.05-0.08l0.17-0.09l0.21-0.04l0.12-0.02l0.27,0.02l0.36,0.05l0.09,0.02
          l0.29,0.12l0.46,0.21l0.34,0.19l0.3,0.21l0.25,0.29l0.05,0.16l0.66,1.5l0.12,0.16l0.12,0.16l0.16,0.12l0.12,0.04l0.74-0.02
          l1.44-0.21l0.55-0.08l0.32-0.05l0.38-0.16l0.3-0.08l0.23-0.04h0.74l1.65,0.12l0.53,0.13l1.74,0.53l0.08,0.05l0.13,0.13l0.17,0.34
          l0.04,0.09l0.38,0.91l0.42,1.37l0.04,0.21l0.12,0.65l0.04,0.09l0.09,0.17l0.05,0.08l0.17,0.09l0.4,0.13l0.34,0.05h0.23l0.51-0.12
          l0.19-0.08l0.08-0.06l0.12-0.13l0.04-0.09l0.05-0.08l0.42-0.36l0.17-0.08l1-0.44l0.09-0.04l0.12-0.02l3.62,0.21l0.25,0.02
          l0.34,0.04l0.23,0.05l0.36,0.29l0.3,0.36l0.36,0.42l0.25,0.26l0.08,0.05l0.26,0.13l0.42,0.12l0.36-0.02l0.4-0.12l0.08-0.05
          l0.05-0.08l0.16-0.38l0.09-0.3l0.08-0.32l0.05-0.23l0.02-0.25l0.05-0.21l0.12-0.13l0.17-0.09l0.12-0.02l0.49,0.02l0.44,0.09
          l0.3,0.09l0.29,0.12l0.57,0.25l0.09,0.04l0.16,0.12l0.66,0.51l0.08,0.05l0.13,0.13l0.13,0.25l1.31,1.69l3.24,3.57l0.13,0.13
          l1.21,0.65l0.23,0.05l0.34,0.08l0.25,0.02h0.12l1.65-0.76l0.26-0.13l0.06-0.05l0.4-0.53l0.3-0.63l0.06-0.21l0.09-0.44v-0.49
          l-0.19-0.96l-0.05-0.21l-0.12-0.29l-0.25-0.53l-0.3-0.78l-0.12-0.29l-0.02-0.25l0.02-0.25l0.3-1.78l0.02-0.12l0.09-0.17l0.23-0.16
          l0.29-0.12l0.12-0.02l1-0.16h0.12l1.03,0.08l0.34,0.05l0.99-1.2l0.08-0.32l0.05-0.08l1.65-1.08l0.09-0.04l0.09-0.02l0.25-0.02h0.13
          l0.36,0.05l0.3,0.09l1.2,0.47l0.76,0.3l0.36,0.17l0.79,0.29l0.61,0.21l0.3,0.09l0.46,0.08l0.25-0.02l0.3-0.06l0.29-0.12l0.08-0.05
          l0.16-0.12l0.21-0.19l0.12-0.16l0.4-0.38l0.09-0.04l1.06-0.36h0.25l0.12,0.02l0.36,0.17l0.08,0.05l0.26,0.13l0.09,0.04l0.38,0.04
          h0.13l0.12-0.02l1.03-0.25l0.63-0.4l0.16-0.12l0.25-0.42l0.17-0.23l0.32-0.32l0.23-0.16l1.03-0.23h0.15l0.61,0.08l0.55,0.15
          l0.29,0.12h0.12l1.06-0.12l0.09-0.04l0.08-0.05l0.42-0.36l0.09-0.17l0.17-0.36l0.05-0.21l0.04-0.23l-0.02-0.25l-0.09-0.42
          l-0.02-0.23l0.05-0.34l0.09-0.17l0.12-0.13l0.25-0.15l3.28-1.84l2.73,0.21l0.23,0.04l2.01-0.29l1.21-0.19l0.08-0.05l0.32-0.34
          l0.32-0.36l0.4-0.53l0.04-0.09l-0.02-0.36l0.02-0.38l0.05-0.21l0.12-0.29l0.57-1.03l0.05-0.08l0.23-0.04l0.57-0.05l0.19-0.08
          l1.99-0.96l0.33-0.19l0.68-0.62l0.44-0.47l0.12-0.16l1.03-1.48l0.21-0.32l0.04-0.09l0.06-0.21v-0.13l-0.08-0.32l-0.09-0.29
          l-1.12-3.11l-0.16-0.38l-0.05-0.08l-0.16-0.12l-0.38-0.17l-0.09-0.04l-0.32-0.08l-0.25-0.04l-0.25-0.02l0.61-0.19l0.34-0.05
          l1.5,0.08l0.12,0.02l0.09,0.04l0.13,0.13l0.16,0.12l0.09,0.04l0.12,0.04l0.22,0.04l4.4-0.12l0.12-0.02l0.09-0.04l0.74-0.57
          l0.12-0.15l0.09-0.17l0.17-0.21l0.09-0.04l0.66-0.25l0.09-0.04h0.13l0.23,0.05l1.38,0.46l0.05,0.08l0.29,0.51l0.3,0.83l0.02,0.23
          l1.27,0.99l0.59,1.67l-0.43,3.53l-0.29,1.95l-0.09,0.51l-0.85,1.65l-0.05,0.09l-0.12,0.16l-0.21,0.19l-0.12,0.02l-0.12-0.02
          l-0.42,0.04l-0.53,0.44l0.05,0.96l0.04,0.09l0.26,0.13l0.09,0.04l1.38,0.29h0.38l1.29-0.16l0.13-0.13h0.38l0.12,0.02l0.09,0.02
          l2.63,0.96l1.41,0.79l0.79,0.47l0.06,0.08l0.02,0.12l-0.05,0.09l-1.06,1.52l-0.04,1.25l0.05,0.32l0.13,0.38l0.19,0.32l0.13,0.13
          l2.35,3.2l0.25,1.9l-1.84,3.58l-2.29,1.73l-0.87,1.84l0.27,0.38l0.12,0.16l0.04,0.09l0.02,2.46l-0.04,0.09l-0.38,0.55l-0.17,0.23
          l-0.3,0.34l-0.26,0.4l-0.66,1.38l-0.86,2.52l-0.25,0.87l-0.3,2.41l-0.08,0.74l-0.13,3.16l0.02,0.12l0.05,0.17l0.61,1.17l0.55,0.93
          l0.61,2.11l0.02,0.12l0.08,0.78l-0.02,0.13l-0.23,0.3l-0.04,0.12l0.04,0.09l0.13,0.25l0.05,0.08l0.08,0.05l0.72,0.49l0.78,0.49
          l0.25,0.16l0.09,0.04l0.23,0.04l0.63,0.05l0.21,0.05l0.06,0.08l0.04,0.09l0.05,0.61v0.12l-0.02,0.12l-0.3,3.07l-0.25,3.07v0.12
          l0.08,0.78l0.12,0.16l0.19,0.08l0.12,0.02l0.12-0.02l0.19-0.08l1.54,0.21l0.08,0.09l-0.04,0.13l-0.42,0.62l-0.46,0.59l0.09,0.17
          l0.38,0.4l0.21,0.19l0.17,0.08l0.42,0.13l0.25,0.02l1.74,0.61l0.36,0.55l0.21,0.3l1.21,1.52l3.01,3.16l0.21,0.19l0.08,0.05
          l0.17,0.08l2.42,0.66l2.52,0.47L3867.36,926.58L3867.36,926.58z"/>
      </g>
    );
  } else if (props.state === 'CE') {
    stateSelected = (
      <g id="CE" transform="translate(-3550.8686,-814.85365)">
        <path id="path458" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4256.58,1071.05l-0.04,0.21l-0.09,0.17l-0.26,0.29
          l-0.29,0.23l-0.22,0.32l-0.17,0.34l-0.21,0.57l-1.03,2.92l-0.05,0.21l-0.02,0.23l0.23,0.55l0.16,0.12l0.13,0.16l0.15,0.36
          l0.06,0.19l-0.06,0.21l-0.74,1.27l-0.12,0.16l-0.47,0.3l-0.17,0.09l-0.17,0.09l-0.4,0.13l-0.12,0.16l-0.15,0.4l-0.16,1.03
          l0.04,0.23l0.05,0.19l0.12,0.16l0.16,0.12l0.17,0.08l0.23,0.04l0.17,0.09l0.16,0.12l0.12,0.16l0.19,0.61l0.05,0.19l0.13,0.66
          l-0.02,0.23l-0.06,0.21l-0.12,0.16l-0.09,0.19l-0.04,0.21l0.05,0.21l0.16,0.12l1.29,0.96l0.17,0.12l0.23,0.04l0.47-0.04
          l0.19,0.02l0.05,0.19l0.29,1.2l-0.04,0.23l-0.29,0.78l-0.61,1.24l-1.03,2.03l-0.09,0.17l-0.4,0.4l-0.29,0.21l-0.19,0.09
          l-0.15,0.12l-0.39,0.34l-0.04,0.21l0.12,0.16l0.16,0.09l-0.36,0.09l-0.3,0.09l-1.27,0.4l-1.27,0.99l-0.08,0.05l-0.72,0.87
          l-0.17,0.23l-0.4,0.76l-0.49,0.83l-0.13,0.13l-1.78,0.08h-0.13l-0.08-0.05l-0.09-0.17l-0.08-0.19l-0.05-0.21l-0.04-0.23
          l-0.33-0.99l-0.08-0.19l-0.12-0.16l-0.13-0.13l-0.16-0.12l-0.59-0.34l-0.09-0.04l-0.4-0.13l-0.21-0.08l-0.34-0.05l-0.4-0.13
          l-0.26-0.13l-0.16-0.12l-0.63-0.62l-0.36-0.44l-0.12-0.15l-0.02-0.12l-0.13-0.4l-0.16-0.38l-0.21-0.32l-0.08-0.06l-2.2-0.99
          l-3.47-2.32l-0.42-0.08l-1.9-0.25l-1.31-0.17h-0.26l-1.33,0.12l-0.25,0.02l-0.42,0.12l-0.21,0.05l-0.47,0.19l-0.38,0.16
          l-0.25,0.16l-0.17,0.08l-0.38,0.16l-0.19,0.05l-0.21,0.05l-1,0.17l-0.61,0.04l-0.36-0.04l-0.44-0.09l-0.09-0.02l-0.4-0.16
          l-1.84-0.13l-3.36-0.16l-0.74,0.02l-0.05,0.02l0.09-0.21l0.05-0.21l0.02-0.96l-0.05-0.19l-0.12-0.17l-0.72-0.61l-0.09-0.17
          l-0.09-0.4l-0.04-0.23l0.05-0.36l0.05-0.21l0.55-1.57l0.57-1.57l0.25-0.55l0.21-0.32l0.57-0.76l0.57-0.76l0.4-0.66l0.08-0.17
          l0.05-0.23l-0.02-0.61l-0.02-0.15l-0.83-1.29l-0.19-0.12l-0.4-0.05l-0.44-0.04l-0.43-0.08l-0.65-0.12l-1.12-0.25l-1.5-0.68
          l-0.19-0.21l-0.12-0.23l-1-2.81l-0.08-0.23l-0.38-1.67l-0.86-4.57l-0.93-3.13l0.25-0.46l0.09-0.21l-0.44-2.08l-0.44-3.96
          l-0.16-1.5l-0.17-2.2l-0.01-0.29l-0.05-0.21l-0.16-0.23l-0.22-0.16l-0.83-0.57l0.79-0.51l0.13-0.13l0.17-0.23l0.3-0.61l0.21-0.46
          l0.08-0.32l0.01-0.4l-0.61-3.15l-0.09-0.42l-0.08-0.19l-0.53-0.91l-0.38-0.55l-0.08-0.17l-0.19-0.47l-0.04-0.23v-0.13l0.05-0.21
          l0.26-0.53l0.12-0.16l0.13-0.26l0.05-0.34l-0.3-2.6l-0.42-1.33l-0.19-0.46l-0.05-0.21l-0.02-0.36l0.08-0.32l0.21-0.46l0.25-0.4
          l0.23-0.3l0.13-0.26l0.05-0.21l0.05-0.36v-0.13l-0.01-0.25l-0.05-0.19l-0.15-0.25l-0.86-1.12l-0.19-0.21l-0.13-0.12l-0.15-0.12
          l-0.15-0.08l0.02-0.25l-0.02-0.21l-0.23-0.82l-0.13-0.38l-0.19-0.46l-0.29-0.62l-0.05-0.12l-0.36-0.51l-0.32-0.59l-1.74-3.62
          l-0.76-1.71l-0.3-0.74l-0.27-0.65l-0.32-1.06l-0.4-1.03l-0.61-1.54l-0.42-1.03l-0.17-0.49l-0.12-0.4l-0.25-1.17l-0.02-0.21
          l-0.05-0.61l0.08-0.46l0.29-0.62l0.34-0.36l0.22-0.21l0.47-0.59l0.78-1.06l0.12-0.16l1.2-1.97l0.08-0.17l0.04-0.16l0.09-0.7
          v-0.16h0.16l0.08-0.05l0.05-0.21l-0.02-0.12l-0.05-0.46l-0.05-0.21l-0.4-0.65l-0.13-0.13l0.86-0.93l5.95-0.16l2.28-0.13
          l2.56-0.49l0.85-0.02l0.25-0.02l0.96-0.3l0.72-0.3l0.16-0.12l0.57-0.05l2.9,0.19l2.46,0.29l3.58,0.49l0.12,0.02l0.09,0.04
          l2.22,1.17l0.19,0.09l0.08,0.05l0.25,0.26l0.29,0.38l0.13,0.12l1.67,1.08l0.39,0.21l2.71,1.29l1.88,1.16l0.09,0.04l1.65,0.59
          l0.21,0.08l2.96,1.88l2.62,1.71l0.09,0.04l0.21,0.05l0.12,0.02l0.72,0.02l0.47,0.12l0.15,0.12l0.63,0.44l0.53,0.78l1.48,1.54
          l3.57,2.54l0.16,0.09l0.36,0.16l0.12,0.04l1.9,0.55l0.12,0.04l0.47,0.05h0.25l2.86,3.57l0.42,0.38l0.23,0.17l0.09,0.04l0.36,0.05
          l0.3,0.08l0.16,0.12l1.48,1.88l0.12,0.15l0.17,0.49l0.02,0.09l0.09,0.16l0.34,0.42l0.57,0.63l0.12,0.13l0.51,0.53l0.25,0.26
          l1.03,0.95l1.76,1.24l2.16,1.48l1.41,1.71l0.29,0.63l0.12,0.15l0.13,0.13l1.08,1l0.08,0.05l0.74,0.46l0.33,0.21l0.62,0.3
          l0.21,0.04l1.29,0.02l1,0.3l1.48,0.63l0.29,0.12l0.25,0.16l0.53,0.4l0.19,0.21l0.25,0.26l0.47,1.54l-1.2,0.38l-1.88,0.61
          l-2.88,0.96l-0.76,0.42l-0.61,0.46l-0.59,0.47l-1.12,0.99l-0.26,0.29l-0.12,0.16l-0.09,0.15l-0.13,0.4l-1.16,2.54l-1.9,3.89
          l-0.51,0.91l-0.91,1.21l-0.23,0.29l-0.15,0.12l-0.17,0.12l-0.53,0.25l-0.15,0.09l-0.12,0.17l-0.63,1.74l0.04,0.34l0.05,0.19
          l0.09,0.17l0.02,0.21l-0.05,0.19l-1.67,2.9l-0.74,0.57l-0.13,0.13l-0.85,0.96l-0.16,0.32l-0.08,0.23l-0.05,0.25l-0.13,0.4
          l-0.12,0.15l-0.13,0.13l-0.15,0.12l-0.19,0.08l-0.42,0.12l-0.44,0.05l-0.3-0.02l-0.15-0.12l-0.12-0.16l-0.29-0.23l-0.17-0.12
          l-0.19-0.05l-0.36,0.04l-0.3,0.23l-0.87,0.96l-1.73,2.75l-0.49,2.29l0.08,0.19l0.17,0.09l0.51,0.05l0.21-0.05l0.36-0.13
          l0.15-0.12l0.17-0.19L4256.58,1071.05L4256.58,1071.05z"/>
      </g>
    );
  } else if (props.state === 'SE') {
    stateSelected = (
      <g id="SE" transform="translate(-3550.8686,-814.85365)">
        <path id="path1072" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4276.24,1168.57l-0.17,0.08l-0.59,0.21l-2.6,0.68
          l-1.52-0.61l-0.38-0.16l-0.36-0.17l-0.3-0.21l-0.12-0.13l-0.4-0.64l-0.09-0.17l-2.2-1l-0.19-0.05l-0.62-0.61l-0.19-0.4l0.08-0.21
          l0.19-0.17l0.21-0.16l0.08-0.19l0.08-1.48l-0.04-0.21l-0.16-0.36l-0.29-0.26l-1.48-1.55l-0.19-0.91l-0.29-0.74l-0.08-0.17
          l-0.19-0.05l-1.24-0.74l-0.96-1.29l-0.08-0.17l-0.08-0.21l-0.04-0.21l-0.01-0.25l-0.02-0.46l0.05-0.46l0.61-1.63l0.17-0.21
          l0.19-0.21l0.25-0.09l0.19-0.05l0.29-0.02l0.87,0.02l0.61,0.12l0.4,0.21l0.44,0.26l0.25,0.19l0.21,0.21l0.23,0.05l0.64-0.19
          l0.44-0.17l0.4-0.17l0.89-0.38l0.23-0.12l0.4-0.23l0.62-0.42l0.19-0.17l0.13-0.21l0.25-0.55l-0.72-2.75l-0.21-0.57l0.12-1.07
          l0.6-1.08l0.09-0.44l0.09-0.44l0.17-3.11l-0.04-0.61l-0.05-0.19l-0.19-0.34l-0.57-0.76l-0.25-0.3l-0.72-0.61l-1.59-1.61l-0.46-0.86
          l-1.08-2.07l-0.04-0.21l-0.3-2.21l0.04-0.23l0.05-0.19l0.47-1l0.13-0.13l0.17-0.12l1.16,0.44l2.71,1.37l4.02,1.8l2.07,0.44
          l0.66,0.13l1.78,0.59l0.74,0.34l0.93,0.65l2.01,1.24l2.94,1.5l1.16,1.8l0.16,0.59l0.15,0.38l0.09,0.17l0.12,0.16l1.61,1.33
          l0.46,0.32l1.93,0.93l0.17,0.08l0.38,0.02l0.19-0.08l4.28,2.73l0.34,0.43l0.4,1.13l-1.12,0.38l-1.1,0.21l-0.7,0.32l-1.9,1.14
          l-0.81,0.49l-2.86,1.91l-1.12,1l-0.12,0.13l-0.69,0.89l-0.12,0.16l-0.05,0.19l-0.66,0.93l-0.32,0.42l-0.19,0.12l-0.3-0.17
          l-0.04-0.17l0.04-0.38l0.04-0.17l0.19-0.55l-0.62-1.13l-0.64-1.06l-1.16,0.09l-0.09,0.21l0.3,1.25l0.19,0.09l0.82-0.04l0.17-0.02
          l0.38-0.02l0.19,0.04l0.17,0.17l0.01,0.19l-0.64,2.28l-1.42,2.49l-0.17,0.13l-0.19-0.09l-0.38-0.34l-0.08-0.17l-0.08-0.4
          l-0.25-0.29l-0.19-0.08l-0.4-0.13l-0.63-0.13l-0.16,0.12l-0.01,0.23l0.29,0.51l0.09,0.16l0.66,0.51l0.66,0.17l-0.32,2.14
          l-1.07,1.38l-0.22,0.42l-0.68,1.27l-0.05,0.21l-0.08,0.46l-0.12,0.15l-0.13,0.08l-0.62,0.05l-0.44-0.05l-0.09-0.51l0.53-1.63
          l0.13-0.13l0.44-0.09l0.26-0.4l0.23-0.25l0.19-0.44l0.19-0.57l0.02-0.17l-0.23-0.12l-0.74-0.29l-0.21,0.02l0.23,0.82l-0.47,1.31
          l-0.16,0.12l-0.51,0.55l-0.33,1.31l0.36,0.72l-0.08,0.38l-0.19,0.34l-0.13,0.13l-0.66,0.4l-0.36,0.17l-0.38,0.12L4276.24,1168.57
          L4276.24,1168.57z"/>
        <path id="path1074" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4282.92,1160.6l1.44-2.52l0.25-0.25l0.21-0.17
          l0.19-0.04l0.12,0.09l-0.12,0.51l-0.09,0.17l-1.54,2.43l-0.17,0.15l-0.21,0.02L4282.92,1160.6L4282.92,1160.6z"/>
      </g>
    );
  } else if (props.state === 'PR') {
    stateSelected = (
      <g id="PR" transform="translate(-3550.8686,-814.85365)">
        <path id="path1083" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4064.75,1443.99l-0.26,0.02l-1.91-0.05l-2.12-0.05
          l-1.54-0.05l-0.26,0.02l-0.1,0.04l-0.05,0.09l-0.05,0.21l-0.04,0.09l-0.05,0.08l-0.08,0.05l-0.12,0.04l-2.03,0.19h-0.79l-0.12-0.01
          l-0.08-0.05l-0.04-0.12l0.05-0.23l-0.02-0.09l-0.09-0.04l-0.12,0.01l-1.63,0.59l-0.29,0.13l-0.34,0.23l-0.21,0.19l-0.26,0.29
          l-0.34,0.47l-0.05,0.09l-0.29,0.26l-0.53,0.29l-0.53,0.29l-2.62,1.59l-0.4,0.17l-0.99-0.02l-0.13-0.02l-0.59-0.13l-0.1-0.04
          l-1.08-0.47l-0.38-0.19l-0.89-0.51l-1.86-1.44l-0.49-0.46l-0.34-0.34l-0.15-0.12l-0.21-0.08l-1.07-0.36H4039l-0.78-0.02l-0.13,0.02
          l-1.02,0.29l-1.33,0.36l-1.04,0.17l-2.84,0.19l-0.99-0.04l-1.57-0.81l-0.12-0.02h-0.16l-1.25,0.16l-1.69,0.59l-0.12,0.04
          l-0.16,0.13l-0.09,0.17l-0.09,0.3l-0.05,0.36l-0.04,0.09l-0.23,0.47l-0.09,0.17l-0.12,0.16l-0.82,0.83l-2.16,1.06l-0.25,0.04
          l-1.27-0.08l-0.62-0.08l-0.79-0.17l-0.25-0.05l-1.17-0.12l-0.21,0.09l-2.21,1.12l-0.23,0.19l-0.7,0.68l-0.12,0.15l-0.74,1.44
          l-0.05,0.21l0.02,0.12l0.23,1.1l0.09,0.17l0.12,0.13l0.36,0.59l0.17,0.38l0.09,0.3l0.02,0.36v0.13l-0.04,0.12l-0.01,0.06
          l-0.06,0.09l-0.08,0.08l-0.16,0.12l-0.61,0.47l-0.08,0.05l-0.12,0.04l-0.63-0.01l-0.4-0.02l-0.09,0.04l-0.68,0.29l-0.17,0.09
          l-0.08,0.05l-0.13,0.49l-1.48-2.04l-0.09-0.04l-0.83,0.16h-1.48l-4.69-0.09l-2.82-0.38l-1.8-1.14l-0.08-0.08l-0.12-0.16l-0.04-0.09
          l-0.04-0.23l-0.13-0.13l-0.91-0.51l-0.29-0.13l-0.12-0.04l-1.82-0.26l-1.1,0.16l-0.25,0.04l-1.9-0.26l-2.11-0.61l-1.91-0.57
          l-0.44-0.13l-1.94-0.53l-0.13-0.01l-0.53,0.01l-0.36,0.05l-0.21,0.08l-0.17,0.09l-0.3,0.12l-0.65,0.19l-0.34,0.08h-0.25l-2.66,0.05
          l-0.44-0.02l-0.12-0.04l-0.19-0.09l-2.2-1.37l-0.17-0.12l-0.08-0.08l-0.13-0.38l-0.16-0.13l-0.12-0.04l-1.03-0.19l-0.36-0.05h-0.4
          l-0.12,0.02l-0.36,0.17l-0.46,0.25l-2.73,0.09l-0.21-0.08l-0.47-0.12l-0.25-0.04l-0.76,0.12l0.02-0.13l-0.02-0.23l-0.04-0.34
          l-0.08-0.3l-0.08-0.19l-0.16-0.25l-1.34-1.9l-1.65-2.49l-0.04-0.09l-0.02-0.09l-0.13-1.25l0.08-0.78l0.12-0.3l0.04-0.09l-0.02-0.23
          l-0.02-0.12l-0.66-2.12l-0.05-0.16l-0.46-0.87l-0.05-0.08l-3.96-2.62l-0.12-0.02l-0.12,0.04l-0.17,0.12l-0.72,0.64l-0.4,0.51
          l-1.59,0.12l-0.12-0.16l-0.12-0.15l-0.09-0.05h-0.12h-0.13l-0.42,0.12l-1.27,0.42l-0.16,0.12l-1.24,0.93l-0.3-0.25l-1.33-0.93
          l-0.12-0.04h-0.13l-0.91,0.05l-0.32-2.35l-0.02-0.12v-0.12l0.72-1.48l0.19-0.23l0.3-0.25l0.16-0.13l0.3-0.38l0.19-0.22l0.17-0.21
          l0.19-0.23l1.41-2.32l0.08-0.19l0.02-0.12v-0.12l-0.09-0.17l-0.53-0.57l-0.04-0.09l-0.04-0.21l0.01-0.38l0.06-0.62l0.04-0.23
          l0.85-3.28l0.23-0.66l1.25-2.39l0.12-0.34l0.15-0.53l0.06-0.23l0.04-0.25v-0.25l-0.12-0.62l-0.05-0.19l-0.13-0.26l-0.09-0.29
          l-0.02-0.21l0.08-0.38l0.12-0.34l0.08-0.21l0.13-0.22l0.05-0.08l0.34-0.38l0.25-0.17l0.08-0.08l0.05-0.09l0.05-0.09l0.42-0.89
          l0.02-0.12l-0.02-0.23l-0.21-0.79l-0.05-0.19l-0.05-0.09l-0.13-0.16l-0.26-0.17l-0.25-0.17l-0.08-0.05l-0.17-0.17l-0.09-0.17
          l-0.32-1.56l-0.04-0.22l0.02-0.13l0.15-0.3l0.13-0.16l0.44-0.44l0.21-0.21l0.23-0.19l0.27-0.17l0.36-0.16l0.09-0.04l0.29-0.13
          l0.17-0.09l2.24-1.35l0.16-0.13l0.17-0.26l0.21-0.53l0.04-0.12l0.04-0.26l0.72-3.98l0.44-1.31l0.04-0.12l0.53-2.32l0.02-0.7
          l0.09-0.21l0.05-0.08l0.08-0.08l0.3-0.25l0.42-0.26l0.17,0.04l0.66-0.09l0.19-0.08l1.94-1.08l0.46-0.36l0.27-0.29l0.17-0.25
          l0.38-0.62l0.09-0.21l1.2-2.94l0.04-0.27v-0.47l-0.02-0.23l-0.02-0.46l0.53-1.37l0.34-0.82l0.09-0.19l0.09-0.19l0.12-0.15
          l0.34-0.25l0.83-0.53l0.26-0.15l1.16-0.46l1.37-0.53l0.59-0.21l1.03-0.36l1.03-0.32l0.19-0.08l0.47-0.21l0.19-0.08l0.46-0.25
          l2.03-1.31l1.51-1.1l0.27-0.13l0.32-0.08l0.25-0.01l0.29,0.04l0.46,0.16l0.4,0.21l0.34,0.12l0.25,0.05l2.49,0.25h0.13l2.94,0.05
          l1.65-0.13l0.21-0.06l0.12-0.02l0.15,0.02l1.48,0.23l0.34,0.12l0.13,0.02l1.82-0.02l1.44-0.61l0.08-0.21l0.29-0.51l0.05-0.05
          l0.23-0.17l0.19-0.09l0.19-0.08l0.19-0.08l0.22-0.05l0.4,0.08l0.47,0.13l0.43,0.15l0.95,0.38l0.74,0.42l0.25,0.19l2.45,0.4
          l0.65,0.06l2.41,0.79l0.53,0.34l0.19,0.09l0.23,0.08l0.68,0.04h0.66l0.47-0.05l0.21-0.05l0.08-0.04l0.25-0.17l0.21-0.21l0.16-0.12
          l1.35-0.05l1.54,0.04l0.13,0.02l0.09,0.05l0.15,0.12l0.12,0.16l0.08,0.17l0.08,0.19l0.15,0.25l0.22,0.17l0.19,0.09l1.94,0.85
          l0.23,0.08l1.94,0.38l2.12,0.43l0.21,0.09l0.34,0.22l0.29,0.26l0.05,0.08l0.83,1.46l2.03,0.19l0.12-0.17l0.05-0.08l0.16-0.13
          l0.19-0.09l0.17-0.06l1.8,0.17l0.23,0.08l0.3,0.13l0.13,0.26l0.13,0.16l0.25,0.05l0.95,0.04l5.42,0.08l1.84-0.47l0.55-0.19
          l0.13-0.16l0.09-0.05l0.89-0.17l0.23,0.05l0.08,0.05l0.12,0.16l1.08,1.54l4.36,3.16l0.05,0.08l0.46,0.62l0.05,0.08l0.53,1.17v0.12
          l0.05,2.99l-0.08,0.08l-0.42,0.57l-0.05,0.09l-0.17,0.87l0.36,1.65l0.12,0.42l0.08,0.19l0.05,0.08l0.13,0.16l0.16,0.13l0.79,0.74
          l0.34,0.22l0.05,0.05l0.02,0.12v0.36l-0.12,1.57l-0.02,0.12l-0.36,0.49l-0.15,0.13l1.04,1.67l0.81,1.54l0.04,0.09l1.38,1.24
          l0.21,0.08l0.47,0.13l0.19,0.09l0.17,0.12l0.08,0.08l0.38,0.44l0.05,0.08l0.02,0.23l-0.08,0.79l-0.02,0.12l0.85,2.03l0.08,0.08
          l0.09,0.05l0.15,0.02l0.12,0.04l0.59,0.29l0.32,0.25v0.25l-0.01,0.12l-0.26,1l-0.05,0.09l-1.35,2.11l-0.12,0.02l-0.08,0.08
          l-0.04,0.12l-0.02,0.13v0.12l0.33,1.84l0.08,0.19l0.05,0.08l0.17,0.12l0.29,0.16l0.21,0.08l0.21,0.08l0.61,0.16l0.47,0.01l2.9-0.13
          l1.44-0.25l2.66-0.36l0.13,0.01l0.55,0.42l1.88-0.21l0.12-0.02l1.73,0.12l0.13,0.01l0.12,0.04l1.88,1.16l0.04,0.09l-0.02,0.26
          l-0.04,0.12l-0.05,0.09l-0.19,0.23l-0.25,0.17l-0.38,0.19l-0.12,0.04l-0.25,0.17l-0.08,0.08l-0.82,3.57l0.3,0.74l0.09,0.17
          l0.05,0.08l0.08,0.05l0.21,0.09l0.82,0.36l0.12,0.04h0.09l0.08-0.05l0.66-0.91l1.31-2.81l-0.04-0.09l-0.02-0.12l0.02-0.12
          l0.08-0.08l0.12-0.02l0.79-0.05l0.7,0.09l1,0.26l0.83,0.26l0.09,0.05l-0.17,0.93l-0.23,0.47l-0.12,0.3l-0.04,0.25v0.13l0.02,0.21
          l0.82,3.37l0.04,0.09l0.09,0.15l0.47,0.21l0.23,0.08l0.96,0.25l0.19,1.48l-1.57,1.06l-0.15,0.13l-0.22,0.3l-0.21,0.46l-0.08,0.32
          l-0.08,0.46l-0.08,0.08l-0.12,0.01l-0.36-0.05l-0.21-0.08l-0.08-0.06l-0.01-0.12l0.01-0.38l0.05-0.23l0.36-1.42l0.21-0.19
          l0.25-0.17l0.46-0.29l0.21-0.08l0.25-0.04l0.3-0.12l0.05-0.04l0.16-0.17l-0.05-0.21l-0.09-0.02l-1.67,0.57l-1.08,0.55l-1.06-0.04
          l-0.42-0.44l-0.68-0.19l-0.66,0.42l-0.21,0.61l-0.12,0.42l0.21,0.32l0.33,0.36l0.09,0.17l0.02,0.09l-0.02,0.12l-0.93,0.87
          l-0.3,0.12l-0.13,0.02l-1.57-0.29l-1.38-0.3l-0.87-0.51l-0.16-0.13l-0.05-0.08l-0.44,0.25l-0.22,1l0.34,0.36l0.17,0.09l1.13,0.43
          l0.61,0.12l0.29,0.04l0.68-0.13l0.82-0.17l0.13,0.02l2.45,0.61l0.12,0.04l0.15,0.12l0.17,0.36l-0.02,0.12l-0.05,0.08l-0.16,0.12
          l-0.08,0.05l-0.3,0.12l-0.53,0.3l-0.08,0.05l-0.48,0.6l-0.79,1.27l-0.59,1.16l-1.52,0.86l-2.88,0.57l-0.15,0.12l-0.17,0.36
          l-0.01,0.12l0.05,0.08l2.41-0.15l1.16-0.19l0.17,1.31l-0.22,0.76L4064.75,1443.99L4064.75,1443.99z"/>
        <path id="path1085" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4070.57,1434.51l-0.09-0.04l-0.47-0.34l-0.05-0.08
          l-0.13-0.68l0.02-0.25l0.05-0.09l0.32-0.62l0.3-0.25l0.15-0.12l0.27-0.15l0.26-0.16l0.12-0.02l0.12,0.04l0.12,0.16l0.04,0.09
          l-0.02,0.12l-0.23,1.93l-0.02,0.12l-0.08,0.17l-0.43,0.19l-0.09,0.04L4070.57,1434.51L4070.57,1434.51z"/>
      </g>
    );
  } else if (props.state === 'AM') {
    stateSelected = (
      <g id="AM" transform="translate(-3550.8686,-814.85365)">
        <path id="path1105" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M3882.93,1088.95l-1.25,1.31l-0.23,0.63l-0.05,0.19
          l-0.05,0.74l0.02,0.19l0.15,0.38l0.25,0.4l0.04,0.17l0.02,0.23l-0.09,0.38l-0.29,0.59l-0.23,0.38l-1.14,1.74l-0.19,0.19
          l-0.57,0.34l-0.38,0.34l-0.29,0.36l-0.12,0.19l-0.08,0.19l-0.04,0.21l-0.02,0.19l0.02,0.21l0.01,0.17l0.09,0.55l0.13,0.38
          l0.51,0.89l0.16,0.38l0.25,0.82l0.25,1.03l0.05,0.42l-0.7,2.54l-0.79,2.22l-0.15,0.38l-0.09,0.21l-0.63,2.66l-0.76,3.58
          l-1.46,0.89l-2.42-0.04l-3.36,0.02l-3.26,0.05l-4.24,0.05l-3.75-0.02l-4.34,0.09l-7.13,0.09l-3.62-0.09l-8.61,0.16l-4.21-0.04
          l-3.57,0.05l-2.84,0.05l-7.06,0.02l0.09-0.7l-0.16-0.42l-0.19-0.16l-0.26-0.09l-1.46-0.3l-0.21-0.02l-2.15,1.17l-0.15,0.19
          l-0.12,0.19l-0.15,0.44l-0.08,0.42l-0.02,0.59l-0.05,0.19l-0.12,0.17l-0.17,0.13l-0.23,0.13l-0.17,0.08l-0.23,0.02l-0.21-0.05
          l-0.82-0.26l-2.92-1.34l-0.19-0.17l-0.05-0.21l-0.08-0.38l-0.23-1.24l-0.3-1.55l-0.12-0.17l-0.17-0.09l-0.23-0.08l-0.38,0.02
          l-0.61,0.13l-0.21,0.02l-0.4-0.02l-0.36-0.12l-0.17-0.25l-0.19-0.78l-0.42-1.29l-0.47-1.34l-0.12-0.17l-0.17-0.19l-0.17-0.17
          l-0.38-0.19l-0.42-0.16l-0.38-0.09l-0.36,0.02l-1.46-0.68l-1.24-1.07l-0.21-0.21l-0.13-0.17l-0.12-0.19l-0.46-0.87l-0.19-0.42
          l0.08-0.17l0.04-0.17l-0.02-0.17l-0.36-0.4l-0.55-0.59l-0.21-0.21l-0.17-0.13l-0.4-0.21l-1.82-0.82l-13.24,0.16l-0.3,0.4
          l-0.13,0.19l-0.34,0.59l-0.12,0.19l-0.02,0.19l-0.63,1.46l-0.17,0.02l-1.31,0.3l-0.59,0.19l-0.17,0.12l-0.12,0.19l0.08,0.38
          l0.12,0.38l0.08,0.17l0.04,0.19l-0.04,0.19l-0.21,0.16l-0.19,0.05l-1.37,0.36l-0.4,0.02l-0.38-0.02l-0.17,0.02l-0.21,0.05
          l-0.4,0.17l-1.38,1.84l-0.13,0.19l-0.08,0.23l-0.02,0.17l0.06,0.21l0.09,0.19l0.17,0.17l0.21,0.16l0.59,0.34l0.17,0.17l0.09,0.17
          l0.05,0.19l-0.05,0.38l-0.42,0.96l-0.93,1.29l-0.15,0.21l-0.16,0.17l-0.17,0.09l-0.99,0.4l-0.21,0.08l-0.17,0.02l-0.74,0.61
          l-0.13,2.08l0.02,0.21l0.09,0.42l0.25,0.78l0.04,0.21l-0.01,0.21l-0.19,0.16l-1.74-0.23l-1.42-0.19l-0.93-0.19l-0.21-0.04
          l-0.23-0.02l-0.21,0.02l-1.91,0.29l-0.57,0.17l-0.4,0.23l-0.21,0.17l-0.17,0.21l-0.05,0.17l-0.12,0.19l-0.17,0.19l-0.21,0.09
          l-0.25,0.04l-0.47-0.02l-1.48-0.12l-0.21-0.02l-0.19-0.05l-0.21-0.17l-0.36-0.38l-0.21-0.09l-0.23-0.04l-0.19,0.02l-0.21,0.08
          l-1.17,0.7l-0.21,0.17l-0.57,0.51l-0.15,0.17l-0.51,0.76l-0.09,0.23l0.12,0.61l0.17,0.36l0.16,0.42l0.05,0.23l-0.08,0.36
          l-0.09,0.19l-2.37,3.16l-0.19,0.19l-0.4,0.32l-0.17,0.12l-0.17,0.08l-0.19,0.02l-0.25-0.02l-0.21-0.08l-0.19-0.12l-0.25-0.19
          l-0.19-0.19l-0.17-0.19l-0.13-0.25l-0.05-0.17l-0.34-1.27l-0.09-0.83l-0.05-0.17l-0.19-0.12l-0.19,0.02l-0.19,0.08l-2.07,1.12
          l-0.21,0.17l-1.38,1.29l-0.19,0.17l-1.44,0.47l-0.19-0.13l-0.17-0.09l-0.21-0.05l-0.19-0.02l-0.21,0.02l-0.19,0.05l-1.21,0.59
          l-0.21,0.15l-0.19,0.21l-0.08,0.17l-0.05,0.21l-0.09,0.63l-0.05,0.17l-0.09,0.19l-1.29,0.68l-0.21,0.09l-0.25,0.04l-0.19-0.12
          l-1.04-1.06l-1.69-1.71l-0.3-0.26l-0.21-0.09l-0.19-0.08l-0.36-0.05l-0.19-0.02l-0.21,0.02l-0.79,0.15l-0.7,0.13l-0.4,0.05
          l-0.44,0.05l-0.49,0.04l-2.75-0.01l-1.76-0.42l-0.17-0.05l-0.21,0.04l-0.12,0.17l-0.02,0.21l0.26,0.7l0.05,0.23l0.02,0.17
          l-0.05,0.38l-0.09,0.4l-0.16,0.19l-1.71,2.01l-0.21,0.16l-0.19,0.05l-0.26,0.08l-0.44,0.02l-0.7,0.17l-0.66,0.3l-0.43,0.29
          l-2.22,1.74l-1.1,0.89l-0.23,0.19l0,0l-0.4-0.16l-1.48-0.61l-1.21-0.51l-0.79-0.34l-0.93-0.4l-1.08-0.46l-8.11-3.37l-3.62-1.5
          l-1.86-0.76l-1.74-0.72l-4.02-1.73l-0.26-0.12l-2.41-1.04l-5.14-2.25l-1.73-0.78l-6.16-3.26l-0.53-0.29l-6.64-3.53l-5.7-3.03
          l-0.53-0.29l-1.86-1l-3.19-1.71l-5.82-3.13l-1.72-0.93l-0.21-0.09l-11.68-3.02l-1.46-0.38l-1.55-0.38l-0.93-0.23l-2.12-0.51
          l-1.59-0.38l-5.31-1.25l-6.1-1.44l-2.25-0.53l-6.35-1.52l-2.12-0.51l-1.99-0.47l-0.29-0.09l-3.85-1.69l-2.75-1.12l-2.46-0.96
          l-5.7-2.32l-4.69-1.97l-2.33-1l-0.09-0.79l0.85-3.57l0.19-0.32l1.29-1.38l1.61-1.27l0.16-0.12l4.08-2.77l0.3-0.19l0.34-0.05
          l0.53-0.02l0.32,0.05l0.26-0.02l1.13-0.16l0.47-0.26l0.21-0.21l0.38-0.51l0.3-0.51l0.4-0.79l0.08-0.55l0.05-0.55l-0.05-0.29
          l-0.89-2.54l-0.95-1.63l-0.12-0.3l-0.08-0.32l-0.08-0.53l0.23-0.93l0.25-0.53l1.44-2.69l0.91-0.93l0.65-0.66l1.12-1.54l0.49-1.1
          l0.05-0.21l0.34-1.9l-0.02-0.23l-0.08-0.21l-0.08-0.17l-0.12-0.16l-0.15-0.38l0.02-0.49l0.05-0.21l0.79-2.39l0.38-2.85l0.89-0.91
          l1.25-0.68l0.57-0.23l2.66-1.08l2.67-1.52l1.21-1.06l0.13-0.13l0.12-0.16l0.09-0.17l0.08-0.46l0.04-0.74l0.25-0.29l0.95-0.46
          l0.46-0.08l0.42,0.13l0.49-0.04l0.36-0.16l2.01-1.13l1.94-1.54l2.39-1.8l0.4-0.13l2.56-0.4l0.4,0.06l1.34,0.29l0.23,0.02l5-1.5
          l1.74-0.91l0.17-0.08l2.12-0.4l1.88,0.08l0.25,0.02l0.42,0.34l1.42,0.02l0.23-0.04l1.46-1.84l0.05-0.19l0.04-0.46l0.55-1.03
          l0.09-0.17l0.16-0.12l0.32-0.21l0.66-0.38l0.19-0.08l0.23,0.02l2.39,0.19l0.12,0.16l0.74,0.59l1.61-0.3l0.66-0.47l0.42-0.36
          l0.19-0.08l0.25-0.02l1.84,0.12l0.16,0.12l0.29,0.49l0.05,0.21l0.02,0.25l-0.04,0.25l-0.15,0.38l-0.05,0.21l0.05,0.17l0.21,0.21
          l0.76,0.68l1.04,0.78l0.16,0.02l3.22,0.13l0.12-0.02l0.05-0.02l0.13-0.05l0.47-0.32l0.19-0.21l0.21-0.32l0.13-0.26l0.13-0.26
          l-0.13-0.49l0.25-0.4l0.04-0.13l1.16-5.49l1.69-9.56l3.02-16.86l0.17-0.99l0.34-1.82l0.26-1.44l1.37-7.56l1.33-7.39l1.04-2.52
          l0.12-0.16l0.06-0.08l0.12-0.29l0.08-0.3l0.02-0.23v-0.12l-0.23-1.63l-0.42-2.18l-0.34-1.63l-0.05-0.19l-0.17-0.34l0.13-0.13
          l0.04-0.09l-0.04-0.23l-0.76-0.66l-0.23-0.16l-0.34-0.19l-0.17-0.08l-0.15-0.12l-0.13-0.13l-1.55-3.13l-0.08-0.17l-0.08-0.44
          l0.13-0.13l0.16-0.12l0.16-0.25l0.08-0.17l0.17-0.47l0.17-0.49l0.02-0.09l-0.02-0.12l-0.61-2.07l-0.04-0.09l-0.3-0.32l-0.13-0.13
          l-0.09-0.04l-0.89-0.4l-0.29-0.12l-0.21-0.04l-0.38-0.02l-0.12-0.02l-0.25-0.13l-3.47-2.71l-0.19-0.19l-1.57-1.82l-0.7-0.87
          l0.02-1.33l0.04-1.67l0.05-2.73l0.05-3.11l0.02-1.17l0.04-2.73l0.02-1.29l0.02-0.23l1-0.13l0.12-0.02l0.12,0.02l3.45-0.05
          l0.08-0.05l0.83-0.59l1.17-0.79l0.19-0.05l3.75-1.12l0.21-0.06l0.12-0.02l0.12,0.02l0.17,0.09l2.32,1.48l0.78,0.61l0.02,0.08
          l1.48,0.09l1.38-0.26l0.09-0.04l0.12-0.17l0.02-0.08l-0.02-0.12l-0.66-1.9l0.04-0.09l0.34-1.99l0.02-0.13l-1.61-2.45l-0.16-0.16
          l-0.53-0.51l-0.19-0.17l-1.21-0.62l-0.19-0.08l-0.53,0.29l-0.19,0.16l-1.03,0.29l-3.4-0.76l-1.99,0.12h-2.35l-0.08-12.16
          l0.91,0.12l0.21-0.02l0.19-0.08l1.46-0.68l2.66-0.72l0.12-0.02l1.84,0.46l1.12,0.3l0.44,0.3l5.21,0.02l5.21,0.02l12.87,0.04
          l-0.44-0.74l-0.26-0.13l-0.17-0.05l-0.36,0.04l-0.12-0.02l-0.17-0.08l-0.47-1.48l0.38-1.08l0.19-0.46l0.57-0.83l1.24,0.21
          l0.29,0.09l0.16,0.12l0.16,0.16l0.23,0.29l0.04,0.25l0.19,0.82l0.08,0.21l0.15,0.25l0.57,0.99l0.38,0.53l0.19,0.21l0.23,0.16
          l0.09,0.04l0.29,0.12l0.19,0.05l0.21,0.04l0.7-0.05l0.65-0.16l0.33-0.09l0.79-0.38l0.17-0.12l0.15-0.09l0.42-0.36l0.27-0.26
          l0.05-0.05l1.44-1.74l0.12-0.16l0.15-0.26l0.21-0.7l1.46-1.46l0.16-0.12l0.3-0.21l0.93-0.62l0.17-0.12l0.17-0.12l1.33-0.61
          l0.09-0.01l0.12,0.01l1.41,0.53l0.17,0.08l0.16,0.29l0.08,0.3l0.08,0.32l0.21,0.57l0.79,1.55l0.09,0.17l0.55,0.85l0.21,0.21
          l0.17,0.09l0.16,0.12l0.05,0.05l0.82,1.03l0.89,1.59l0.78,2.08l0.05,0.19l0.23,1.61l0.02,0.12l-0.13,1.65l-0.19,0.46l-0.12,0.44
          l-0.04,0.25l-0.12,1.76l0.08,1.76l0.15,0.4l0.02,0.02l0.65-0.13l0.33-0.12l2.62-0.68h0.05h0.26l1.59,1.37l8.55,7.3l0.4,0.26
          l0.12,0.04l0.47,0.05l2.88,0.29l0.21-0.02l0.19-0.08l2.39-1.06l0.32-0.19l0.21-0.17l0.19-0.21l0.33-0.47l0.17-0.21l0.12-0.13
          l0.21-0.19l0.7-0.61l2.49-1.41l0.21-0.04l1.38-0.12l1.06-0.04l0.21,0.05l0.57,0.34l0.38,0.4l0.29,0.36l0.09,0.16l0.17,0.34
          l0.02,0.12l0.04,0.34l0.02,0.36l-0.05,0.34l-0.08,0.19l-0.17,0.36l-0.21,0.32l-0.55,0.49l-0.23,0.3l-0.15,0.38l-0.04,0.21v0.13
          l0.12,0.29l0.12,0.29l0.17,0.34l0.17,0.21l0.12,0.13l0.19,0.08l0.25,0.02l0.12-0.02l0.57-0.21l0.09-0.04l0.08-0.05l0.83-0.59
          l0.63-0.93l0.04-0.09l0.04-0.12l0.02-0.25v-0.25l0.05-0.66l0.04-0.12l0.08-0.17l0.16-0.25l1.03-1.17l0.25-0.26l0.25-0.15
          l0.12-0.02h0.13l0.21,0.05l0.38,0.16l0.23,0.04l0.26,0.02l0.21-0.04l0.49-0.16l0.15-0.09l0.29-0.4l0.15-0.38l0.05-0.21l0.05-0.34
          l0.09-0.72l0.02-0.38l0.12-0.7l0.05-0.21l0.08-0.17l0.17-0.23l0.21-0.17l0.33-0.19l0.09-0.02h0.12l1.63-0.38l1.21-0.85l2.24-1.31
          l0.09-0.04l0.12-0.02l0.09,0.02l0.15,0.12l0.36,0.29l0.38,0.29l0.25,0.02l0.12-0.02l0.19-0.04l0.12-0.02l0.19-0.08l0.4-0.23
          l1.95-1.29l0.16-0.12l0.17-0.23l0.04-0.08l0.08-0.19l0.12-0.42l0.13-0.26l0.09-0.17l0.21-0.3l0.13-0.13l0.25-0.15l2.37-1.38
          l0.19-0.08l0.26,0.02l0.12,0.04l0.17,0.09l0.12,0.13l1.73,0.44l2.83-1.95l0.12-0.16l0.53-0.78l0.09-0.17l0.13-0.26l0.13-0.53
          l0.02-0.12l0.23-2.28v-0.13l-0.04-0.47l-0.04-0.23v-0.72l0.02-0.12l0.05-0.21l0.3-0.38l0.12-0.13l0.34-0.3l0.13-0.12l0.25-0.16
          l0.36-0.16l0.49-0.16l0.3-0.08l1.97,0.02l1.63-0.47l0.4-0.4l2.08-1.54l0.08-0.05l0.91-0.38l0.19-0.05l0.12-0.02h0.13l0.89,0.02
          l1.46-0.25l0.21-0.05l0.15-0.09l0.04-0.12l0.21-1.38l0.78,0.85l0.17,0.17l0.42,0.19l0.4,0.13l0.63,0.04l0.23-0.05l0.21-0.05
          l0.17-0.09l0.4-0.16l0.38-0.16l0.21-0.05l0.21-0.02l0.21,0.02l0.17,0.05l0.17,0.19l0.04,0.21l-0.04,0.19l-0.04,0.42l0.09,0.44
          l1.31,1.21l0.23,0.12l0.96,0.4l0.4-0.05l0.17-0.08l0.59-0.12l1.65,0.13l2.29,1.12l0.19,0.09l0.21,0.19l0.19,0.23l0.09,0.23
          l0.05,0.27l-0.09,0.4l-1.06,3.54l-0.85,1.59l-0.04,0.17l0.05,0.23l1.31,1.93l0.15,0.19l0.59,0.44l1.27,1.88l0.83,2.24l1.06,3.19
          l0.13,0.42l0.04,1.2l0.05,0.89l0.12,0.61l0.08,0.17l0.13,0.23l0.15,0.19l0.4,0.4l0.29,0.36l0.12,0.23l0.08,0.19l-0.04,0.19
          l-0.08,0.21l-0.34,0.4l-0.43,0.38l-0.4,0.23l-0.21,0.08l-0.19,0.13l-0.19,0.16l-0.16,0.17l-0.01,0.23l0.25,1.16l0.12,0.44
          l0.05,0.19l0.12,0.23l0.38,0.61l0.13,0.17l0.15,0.42l0.05,0.23l0.02,0.25l-0.02,0.19l-0.89,3.57l-0.08,0.17l-0.26,0.4l-0.29,0.4
          l-0.15,0.25l-0.04,0.21v1.21l0.4,4.74l0.99,2.52l0.16,0.3l0.19,0.19l0.17,0.16l0.21,0.16l0.21,0.09l0.4,0.12l0.17,0.12l0.17,0.19
          l0.32,0.47l0.19,0.32l0.08,0.19l1.17,3.89l0.19,1.42l0.01,0.47l-0.05,0.21l-0.09,0.17l-0.51,0.63l-0.7,0.83l-0.21,0.05l-0.25,0.02
          l-0.23-0.02l-0.26-0.26l-0.19-0.05l-0.4-0.12l-0.62-0.16l-0.19,0.08l-0.08,0.17l-0.09,0.68l-0.02,0.49l0.02,0.25l0.09,0.17
          l0.12,0.15l2.86,2.33l2.79,2.58l0.17,0.09l0.23,0.04l0.38-0.02l0.19,0.08l2.16,1.46l0.3,0.23l1.57,1.8l0.15,0.23l0.33,0.72
          l0.17,0.47l0.23,0.55l0.13,0.26l0.15,0.25l0.08,0.05l0.16,0.09l0.19,0.05l0.25,0.02l0.3-0.05l0.17-0.08l0.08-0.05l0.27-0.13
          l0.29-0.12l0.42-0.12l0.23-0.04l0.21,0.05l2.43,1.04l0.16,0.09l0.13,0.12l-0.12-0.29l-0.42-2.2l-0.02-0.23l0.12-0.4l0.25-0.55
          l0.29-0.74l0.29-1.03l0.33-1.84v-0.23l-0.04-0.23l-0.12-0.42l-0.19-0.61l-0.15-0.89l0.02-0.22l0.12-0.42l0.65-2.45l0.17-0.59
          l0.12-0.16l1.03-1.31l0.13-0.13l0.15-0.12l0.34-0.19l1.03-0.55l0.19-0.08l0.19-0.05l0.26-0.02l0.21-0.04l1.54-0.57l0.16-0.09
          l0.16-0.12l0.12-0.16l0.09-0.17l0.08-0.19l0.09-0.42l0.12-0.16l0.22-0.02l1.25-0.09l0.25,0.02l0.23,0.04l0.19,0.05l3.26,1.08
          l0.17,0.08l2.15,2.41l0.12,0.13l0.57,1.41l0.13,0.36l0.13,0.61l-0.02,0.25l0.05,0.23l0.12,0.16l0.17,0.09l0.21,0.05l1.86,0.19
          l0.19-0.05l1.44-0.53l0.99-0.82l0.34-0.65l0.12-0.16l0.17-0.08l0.21-0.08l0.4-0.09l0.44-0.09l1.44-0.4l0.19-0.08l0.3-0.23
          l0.12-0.15l0.06-0.23l-0.02-0.4l-0.04-0.23l-0.08-0.17l-0.12-0.16l-0.36-0.42l-0.38-0.4l-0.4-0.38l-0.12-0.16l-0.23-0.55
          l-0.02-0.21l0.02-0.23l0.13-0.65l0.15-0.4l0.43-1.1l0.34-0.57l0.4-0.93l0.05-0.23l0.02-0.17l0.02-0.38l-0.02-0.38l0.02-0.4
          l0.08-0.19l1.29-2.46l0.7-1.17l1.24-1.74l1.37-1.88l0.26-0.4v-0.42l-0.04-0.42l4.15-0.02l7.02,0.04l9.35,0.05l2.28,3.36l0.34,2.5
          l-0.12,0.23l-0.17,0.44l-0.13,0.47l-0.17,0.76l-0.02,0.42l0.05,0.7l0.05,0.66l0.13,1l0.12,0.47l0.17,0.36l0.38,0.3l0.21,0.16
          l0.38,0.16l0.61,0.23l0.42,0.19l0.19,0.09l0.13,0.19l0.04,0.19l-0.08,0.23l-0.29,0.42l-0.13,0.25l-0.06,0.17l-0.09,1.74
          l-0.02,0.46l0.05,0.19l0.12,0.21l0.3,0.4l0.53,0.61l1.24,1.2l0.19,0.19l0.25,0.13l0.21,0.04l0.63-0.29l0.25,0.05l2.04,1.37
          l0.16,0.23l0.59,2.03l0.02,0.23l-0.04,0.4l0.04,0.59l0.09,0.19l0.3,0.4l1.2,1.16l2.98,2.36l1.48,1.12l0.46,0.26l1.08,0.53l1,0.51
          l2.96,1.73l0.19,0.17l0.19,0.25l0.42,0.68l0.15,0.21l0.21,0.17l1.25,1l0.21,0.12l0.61,0.17l0.95,0.3l0.62,0.29l1.21,0.66
          l0.34,0.29l0.23,0.17l0.21,0.12l0.82,0.34l0.17,0.06l1.08,0.32l1.57,0.44l1.37,0.16l0.25,0.02l0.23,0.04l1.31,0.76l0.15,0.12
          l0.05,0.19l-0.05,0.21l-0.02,0.21l0.05,0.21l0.12,0.17l0.47,0.57l0.13,0.13l0.82,0.76l0.16,0.12l2.82,1.59l0.38,0.13l1.16,2.2
          l0.02,0.25l0.09,0.17l0.16,0.12l0.87,0.44l0.17,0.08l0.19,0.08l0.96,0.32l1.16-0.82l1.08-0.38l0.34-0.05l0.19,0.08l0.09,0.17
          l0.09,0.3v0.25l-0.17,0.72l0.05,0.23l1.67,1.42l0.51,0.53l-2.69,5.95l-4.21,8.98l-2.8,6.11l-2.45,5.21l-4.53,9.82l-6.07,12.97
          l-2.67,5.81l-3.19,6.82l-3.57,7.79l-2.79,6.12l-1.5,3.09l-0.29,0.51l-0.59,0.96l-0.17,0.21l-0.61,0.49l-0.44,0.32l-0.64,0.47
          l-0.32,0.4l-0.21,0.38l-0.17,0.4l-0.09,0.42l-0.02,0.38l0.02,0.19l0.05,0.38l0.38,1.41l0.15,0.42l0.26,0.61l0.23,0.43l0.38,0.59
          l0.13,0.19l0.42,0.25l0.66,0.26l0.21,0.12l0.21,0.17l1.59,1.63l0.17,0.17l0.46,0.83l0.09,0.21l0.15,0.42l0.16,0.82l-0.09,0.76
          v0.38l0.12,0.44l0.32,0.4l0.36,0.29L3882.93,1088.95L3882.93,1088.95z"/>
      </g>
    );
  } else if (props.state === 'MG') {
    stateSelected = (
      <g id="MG" transform="translate(-3550.8686,-814.85365)">
        <path id="path1246" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4224.56,1291.42l-1.06-0.59l-1.25-0.43l-1.86-0.51
          l-1.88,0.08l-0.13,0.02l-1.08,0.29l-0.09,0.04l-0.17,0.09l-0.08,0.05l-0.3,0.34l-0.26,0.16l-0.3,0.09l-2.66,0.34l-1.57-0.16
          l-0.12-0.01l1.97,2.42l0.12,0.17l0.04,0.09l0.05,0.21l-0.02,0.53l-0.02,0.12l-0.09,0.17l-0.09-0.02l-1.16-0.19l-0.17-0.09
          l-0.51-0.3l-0.08-0.06l-0.19-0.21l-0.05-0.08l-0.17-0.12l-0.09-0.04l-0.12-0.02l-0.25,0.02l-0.12,0.02l-2.62,1.5l-0.08,0.08
          l-1.41,1.99l-0.02,0.12l0.04,0.23l0.04,0.09l0.32,0.61l0.7,0.19l0.91,0.59l0.36,0.32l0.23,0.3l0.12,0.29l0.05,0.34l-0.01,0.38
          l-0.12,1.25l-0.05,0.23l-0.16,0.53l-0.08,0.32l-0.12,0.72v0.23l0.04,0.23l0.13,0.13l0.09,0.04l0.25,0.04h0.55l0.53,0.04l0.08,0.06
          l0.13,0.13l0.12,0.16l0.04,0.12l0.12,0.66l0.08,0.59l-0.01,0.4l-0.02,0.12l-0.04,0.12l-0.16,0.25l-0.21,0.19l-0.09,0.05l-0.49,0.19
          l-0.22,0.05l-0.4,0.01l-1.2-0.04l-0.79-0.34l-0.08-0.06l-0.26-0.02h-0.89l-0.51,0.04l-0.23,0.05l-0.09,0.04l-0.17,0.09l-0.21,0.21
          l-0.05,0.08l-0.08,0.19v0.13l0.05,0.08l0.57,0.64l0.08,0.05l0.74,0.34l0.13-0.02l0.08-0.05l0.05-0.08l0.16-0.13l0.26-0.13
          l0.12-0.02l0.38,0.04l0.17,0.12l0.08,0.08l1.27,1.42l1.54,3.23l0.04,0.09l0.05,0.21l0.08,0.32l0.21,2.12v0.26l-0.13,1.24
          l-0.47,1.99l-0.08,0.19l-0.16,0.26l-0.13,0.16l-1.78,1.9l-0.66,0.57l-0.38,0.17l-0.44,0.25l-0.08,0.06l-0.23,0.3l-0.09,0.32
          l-0.25,1.69l-0.02,0.12l0.02,0.12l-1.08,2.56l-1.34,1.46l-0.09,0.46l-0.53,2.01l-0.09,0.3l-0.08,0.19l-0.3,0.66l-0.05,0.09
          l-0.05,0.08l-0.08,0.05l-0.53,0.27l-0.01,0.01l-1.69-0.01l-2.8-0.05l-1.69-0.04l-0.17,0.16l-1.91,2.18l-0.17,0.23l-0.09,0.19
          l-0.04,0.09l-0.02,0.12l0.05,0.21l0.3,0.62l0.38,2.71l-0.64,1.41l-0.08,0.21l-0.51,2.54l0.05,0.08l0.33,0.12l-3.02,2.71h-0.13
          l-0.78,0.17l-0.66,0.16l-0.63,0.36l-0.08,0.05l-0.05,0.09l-0.01,0.12l0.08,0.21l0.12,0.17l0.08,0.05l0.17,0.09l0.12,0.04h0.29
          l0.12,0.04l0.08,0.05v0.12l-0.05,0.21l-1.61,3.89l-1.24,2.81l-2.08,3.79l-0.23,0.46l-0.13,0.79l0.04,0.09l0.63,0.16l0.61-0.19
          l0.13-0.02l0.13,0.02l0.21,0.08l0.17,0.12l0.08,0.08l0.15,0.36l0.02,0.12v0.13l-0.06,0.23l-0.05,0.09l-0.17,0.25l-0.05,0.08
          l-0.08,0.05l-0.65,0.32l-0.34,0.08l-0.96,0.26l-0.93,0.3l-0.09,0.04l-3.61,1.63l-1.99,0.93l-3.36,1.48l-2.6,1.12l-1.74,0.3
          l0.12-0.16l0.04-0.25v-0.23l-0.08-0.05l-0.12-0.04l-0.47-0.08l-1.65-0.22l-1.42-0.08h-0.55l-0.29,0.12l-0.99,0.55l-0.79,0.46
          l-0.12,0.02l-1.46,0.17l-3.81,0.12l-0.12-0.04l-0.09-0.05l-0.16-0.25l-0.08-0.05l-0.13-0.02l-0.13,0.02l-0.46,0.09l-1.1,0.42
          l-3.91,1.61l-0.85,0.55l-0.16,0.13l-0.25,0.17l-0.08,0.05l-1.88,0.89l-0.29-0.02l-0.17-0.09l-0.13-0.13l-0.08-0.05l-0.21-0.08
          l-0.15-0.02l-2.67,0.16l-0.21,0.08l-0.08,0.05l-0.17,0.12l-3.33,1.41l-1.94,0.47l-0.51,0.17l-0.49,0.19l-0.46,0.25l-0.25,0.17
          l-0.15,0.13l-0.64,0.46l-0.36,0.19l-0.4,0.16l-0.22,0.05l-0.26,0.02l-0.38-0.08l-0.26-0.02l-0.25,0.02l-1.38,0.26l-0.12,0.04
          l-0.96,0.42l-0.27,0.16l-0.99,0.53l-0.17,0.09l-0.91,0.76l-0.38,0.47l-0.05,0.09l-0.08,0.05l-0.19,0.08l-1.99,0.64l-2.92,0.29
          l-2.56,0.02l-0.27-0.04l-0.09-0.04l-0.08-0.05l-0.05-0.08v-0.12l0.04-0.09l0.09-0.19l0.16-0.12l0.09-0.06l0.12-0.16l0.12-0.17
          l0.08-0.19v-0.12l-0.04-0.09l-0.08-0.08l-0.12-0.04l-0.12,0.02l-1.17,0.3l-0.09,0.04l-0.08,0.05l-0.02,0.12l-0.13,1.37l-0.08,1.08
          l0.13,0.02l0.09,0.06l0.08,0.05l0.06,0.08l0.04,0.09l0.04,0.21l-0.08,0.62l-0.08,0.19l-0.78,0.93l-0.16,0.13l-0.09,0.04l-1.38,0.29
          l-0.12-0.04l-0.62-0.46l-2.04,0.61l-2.35,0.51l-0.23-0.61l-1.41,0.16l-0.21,0.05l-0.83,0.05l-0.42-0.04l-0.83-0.16l-0.17-0.12
          l-0.13-0.13l-2.33-3.37l-0.04-0.09v-0.12l0.04-0.12l0.13-0.13l0.17-0.09l1.04-0.34l0.19-0.08l0.05-0.09l-0.02-0.12l-0.68-1.8
          l-0.82-0.34l-1.41-0.59l-2.18-1.41l-0.85-1.63l-0.05-0.21v-0.34l0.68-1.42l0.23-3.92l-0.32-3.28v-0.13l0.09-0.32l0.05-0.09
          l0.06-0.08l0.15-0.12l0.34-0.21l0.19-0.23l1.37-1.99l0.85-2.49l0.05-0.49l0.04-0.83l-0.01-0.23l-0.04-0.09l-0.05-0.08l-0.13-0.16
          l-0.08-0.05l-0.19-0.09l-4.13-1.33l-0.34-0.09h-0.89h-0.13l-0.05,0.08l-0.15,0.26l-0.13,0.16l-0.09,0.05l-0.74,0.29l-0.21,0.05
          l-0.22,0.04l-0.74,0.05l-0.76,0.05l-0.13-0.02l-0.09-0.04l-0.08-0.08l-0.04-0.09l0.02-0.13l0.19-0.51v-0.13l-0.02-0.12l-0.05-0.21
          l-0.96-2.03l-1.54-1.95l-0.09-0.17l-0.04-0.09l-0.16-1.25l-0.22-1.14l-0.29-0.59l-0.19-0.23l-0.55-0.47l-0.19-0.09l-0.13-0.16
          l-0.06-0.08l-0.01-0.09l0.21-1.95l0.08-0.19l0.13-0.13l0.36-0.19l0.21-0.21l1.1-1.65l0.09-0.17l0.04-0.25v-0.25v-0.12l-0.04-0.21
          l-0.12-0.4l-0.17-0.61l-0.13-0.4l-0.04-0.09l-1.38-0.91l-0.85-0.44l-0.16-0.12l-0.51-0.46l-0.13-0.13l-0.04-0.09v-0.12l0.34-2.28
          l0.04-0.25l0.04-0.12l0.12-0.17l0.15-0.12l0.17-0.12l0.13-0.13l0.05-0.09l0.08-0.21l0.01-0.25v-0.12l-0.01-0.12l-0.21-0.96
          l-0.05-0.08l-3.49-3.43l-0.36-0.32l-0.15-0.12l-0.12-0.04l-1.44,0.42l-1.03,0.74l-1.57-0.62l-0.23-0.21l-0.09-0.05l-0.21-0.08
          l-0.13-0.01l-1.74,0.05l-0.81,0.08l-0.09,0.04l-0.09,0.05l-0.08,0.05l-0.05,0.09l-0.19,0.38l-0.02,0.12l0.04,0.09l0.04,0.09
          l0.19,0.34l0.02,0.09l-0.34,0.93l-0.13,0.13l-0.08,0.05l-0.25,0.09l-2.92,0.64l-0.12,0.02h-0.13l-0.25-0.05l-0.7-0.26l-0.38-0.19
          l-0.32-0.22l-0.13-0.16l-1.38-1.31l-1.24,1.44l-5.08,0.25l-4.89,0.53l-0.33,0.23l-0.23,0.32l-0.59,1.37l-0.08,0.19l-0.01,0.13
          l0.01,0.12l0.08,0.19l0.17,0.34l0.04,0.09l0.15,1.67v0.12l-0.02,0.13l-0.04,0.09l-0.17,0.21l-0.25,0.15l-0.09,0.04h-0.21
          l-0.66-0.34l-0.46-0.25l-0.12-0.16l-0.08-0.17l0.08-0.72l0.12-1.54l0.02-0.25l-0.08-0.44l-0.09-0.53l-0.05-0.19l-0.15-0.38
          l-0.19-0.34l-0.17-0.12l-0.12-0.04l-0.26-0.04l-0.38,0.01l-0.19,0.08l-0.08,0.05l-0.12,0.16l-0.05,0.09l-0.08,0.21l-0.05,0.25
          l-0.17,0.53l-0.08,0.19l-0.15,0.26l-0.29,0.4l-0.19,0.23l-0.15,0.13l-0.08,0.05l-0.34,0.21l-0.25,0.13l-0.09,0.05l-0.21,0.05h-0.26
          l-0.21-0.08l-0.16-0.12l-0.08-0.08l-0.12-0.16l-0.16-0.25l-1.04-1.88l-0.25-0.66l-0.04-0.21l-0.12-0.76l0.04-0.4l0.12-0.61
          l0.02-0.12l-2.04-0.85l-1.95-1.41l-0.29-0.15l-0.36-0.08l-0.15-0.01l-0.93,0.12l-0.36,0.19l-0.26,0.13l-0.38,0.16l-0.3,0.09h-0.25
          l-1.03-0.17l-0.68-0.08l-0.26-0.01l-1.41,0.32l-0.16,0.08l-0.21,0.05l-0.26-0.04l-1.99-0.46l-3.11-0.64l-1.77-0.06l-1.34-0.04
          l-0.13-0.02l-0.26-0.13l-0.09-0.05l-0.16-0.12l-0.15-0.13l-0.12-0.15l-0.21-0.32l-0.32-0.36l-0.53-0.43l-0.3-0.13l-0.21-0.08
          l-0.25-0.05l-0.25,0.02l-0.09,0.01l-1.74,0.66l-0.08,0.04l-0.61,0.61l-0.32,0.38l-0.3,0.53l-0.19,0.23l-0.23,0.17l-0.09,0.05
          l-0.55,0.21l-0.53,0.13l-0.12,0.02l-0.66-0.04h-0.09l-0.34,0.05l-0.09,0.04l-0.09,0.04l-2.42,1.21l-0.74,0.49l-0.44,0.4l-0.19,0.23
          l-0.15,0.19l-0.05-0.16l-0.05-0.21l0.04-0.25l0.25-1.29l0.21-1l0.02-0.13v-0.25l-0.3-1.54l-0.09-0.29l-0.19-0.34l-0.05-0.19
          l-0.08-0.55v-0.47l0.02-0.13l1.12-4.61l0.04-0.09l0.04-0.09l0.13-0.15l0.17-0.12l0.23-0.02l0.19,0.04l0.59,0.42l0.26,0.13
          l0.12,0.01l0.12-0.01l0.17-0.08l0.15-0.12l0.12-0.16l0.02-0.12l-0.09-0.29l-0.08-0.08l-0.53-0.42l-0.08-0.08l-0.05-0.08l-0.02-0.12
          l0.02-0.12l0.76-1.94l0.25-0.3l1.74-2.12l1.06-0.79l0.17-0.09l0.46-0.12l0.65,0.17l0.4,0.02l0.44-0.05l0.47-0.19l0.08-0.06
          l0.21-0.21l0.57-0.81l0.21-0.34l0.04-0.12l0.02-0.13v-0.12l-0.08-0.96l1.48-2.86l1.54-2.32l0.05-0.08l0.17-0.12l0.19-0.08
          l0.51-0.16l1.99-0.34l2.86-1.08l0.19-0.08h0.25l0.36,0.08l0.32,0.12l0.79,0.23l2.62,0.36l0.25-0.02l0.09-0.04l3.72-1.86l1.86,0.36
          l1.5,1.61l0.21,0.08l0.09,0.01l0.23-0.04l0.23-0.17l3.3-3.26l4.95-3.09h0.12l0.12,0.04l1.55,0.76l0.08,0.05l0.68,0.19l1.33-0.05
          l1.06-0.09l2.25-0.04l0.09,0.05l0.61,0.17l1.13,0.13l2.12-0.02l0.68-0.02l0.02-0.12l0.05-0.08l0.53-0.42l0.08-0.05l0.21,0.02
          l5.12,2.18l0.61,0.49l0.19,0.21l0.05,0.19l0.26-0.12l2.73-1.31l1.84-0.99l1.06-0.74l0.16-0.12l0.25-0.3l-0.04-0.09l0.29-0.86
          l0.61-0.76l0.3-0.23l0.29-0.12l0.12-0.02l0.13,0.02l0.08,0.06l0.01,0.12l0.46-0.12l0.49-0.21l3.77-2.41l-0.04-0.26l-0.09-0.3
          l-0.91-2.45l-0.34-0.89l-0.05-0.08l-0.16-0.13l-0.01-0.12l0.01-0.12l0.3-0.87l0.34-0.89l0.15-0.26l0.42-0.66l0.23-0.19l0.08-0.05
          l0.19-0.09l0.04,0.04l0.05-0.79l-0.02-0.47l-0.01-0.23l-0.13-0.38l-0.08-0.19l-0.08-0.17l-0.17-0.23l-0.15-0.13l-0.25-0.17
          l-0.38-0.19l-0.21-0.08l-1.16-0.32l-0.13-0.02l-0.4,0.13l-0.09,0.05l-0.16,0.12l-0.17,0.23l-0.08,0.05l-0.09,0.04h-0.12l-0.09-0.06
          l-0.25-0.17l-0.89-0.99l-0.02-0.32l0.36-1.84l0.16-0.46l0.05-0.08l3.92-3.49l2.77-3.03l0.08-0.19l0.04-0.25l-0.08-0.43l-0.13-0.38
          l-1.88-4.99l-1.74-1.21l-0.21-0.08l-0.36-0.08l-0.38-0.17l-0.15-0.13l-0.05-0.05l-0.57-0.65l-0.12-0.16l-0.04-0.08l-0.21-0.44
          l0.19-0.83l0.19-0.79l0.13-0.13l0.25-0.16l0.36-0.3l0.12-0.16l0.53-0.83l0.36-0.7l0.44-1.08l0.05-0.21l-0.02-0.12l-0.16-0.32
          l-0.12-0.51l-0.13-0.74l0.01-0.12l0.23-0.61l0.42-1.12l1.33-0.09l0.22-0.02l0.09-0.04l2.69-1.74l2.11-0.46l0.12-0.02l0.83-0.21
          l0.09-0.04l0.96-0.34l0.42-0.23l0.08-0.08l0.15-0.25v-0.13l-0.64-4.62l-0.05-0.21l-0.04-0.09l-0.05-0.08l-0.17-0.08l-0.47-0.08
          l-0.09-0.04l-0.23-0.17l-0.05-0.05l-0.09-0.17l-0.04-0.09l-0.06-0.21l-0.12-2.04l0.05-0.23l0.05-0.08l0.38-0.29l0.25-0.17
          l0.25-0.29l0.66-0.95l0.04-0.09l0.02-0.12l-0.02-0.23l-0.08-0.17l-0.36-0.78l-0.05-0.08l-0.19-0.21l-0.12-0.02l-0.22,0.02
          l-0.3,0.09h-0.13l-0.09-0.04l-0.05-0.08v-0.13l0.79-3.23l0.04-0.12l0.09-0.17l0.17-0.21l0.13-0.13l0.17-0.09l0.19-0.08l0.4-0.13
          l0.34-0.05l0.49-0.02h0.13l0.47,0.08l0.42,0.13l0.7,0.38l0.89,0.46l0.19,0.09l0.49,0.19l0.12,0.01h0.13l0.12-0.01l0.19-0.08
          l0.25-0.15l0.55-0.49l0.02-0.12l-0.21-1.63l-0.04-0.09l-0.34-0.85l-0.13-1.74l0.02-0.25l0.19-0.34l0.23-0.3l0.49-0.57l0.32-0.34
          l0.16-0.12l0.12-0.02l0.09,0.04l1.88,1.08l0.25,0.16l0.32,0.23l0.34,0.3l0.53,0.66l0.05,0.08l0.09,0.17l0.08,0.19l0.09,0.29
          l0.08,0.3l0.04,0.21l0.08,0.19l0.16,0.12l3.68,0.34l0.21-0.02l0.17-0.09l1-1.12l0.34,1.42l0.29,1.44l-0.55,1.59l-0.08,0.19
          l-0.25,0.57l-0.04,0.09l-0.09,0.17l-0.29,0.51l-0.25,0.29l-0.21,0.46l-0.04,0.09l-0.01,0.12l0.01,0.12l0.05,0.08l0.16,0.12
          l0.12,0.02h0.12l0.19-0.08l0.08-0.05l1.41-0.99l0.08-0.06l0.34-0.46l0.12-0.16l0.17-0.36l0.12-0.16l0.16-0.12l0.09-0.04l0.7-0.04
          l2.12,0.42l0.12,0.02l0.19,0.08l0.17-0.08l0.76-0.44l0.29-0.25l0.21-0.19l0.05-0.08l0.96-1.29l0.53-0.79l0.68-0.91l0.08-0.05
          l0.51-0.02l0.34,0.08l0.26,0.13h0.13l0.57-0.09l0.19-0.08l0.79-0.4l0.25-0.16l2.08-1.76l1.06-1.31l0.05-0.08l0.3-0.23l1.29-0.17
          l0.12,0.02l0.21,0.08l0.12-0.02l0.4-0.13l0.19-0.08l4.02-2.18l0.47-0.53l0.3-0.78l3.41-2.54l1.8-1.06l0.47-0.19l4.21-1.69
          l1.67,0.05l0.53,0.05l0.08,0.08l0.3,0.21l0.08,0.06l0.09,0.04l0.83,0.21l0.12,0.02l0.12-0.02l2.52,0.05l1.84,0.36l0.21,0.05
          l0.72,0.21l1.04,0.51l0.01,0.08l-0.34,0.64l-0.61,1.24l-0.63,1.34l-0.12,0.44l-0.08,0.59v0.08l0.21,1.33l0.08,0.32l0.09,0.17
          l0.26,0.26l0.08,0.05l0.36,0.17l5.88,1.74l0.23,0.04l0.25-0.02l0.33-0.08l0.55-0.12l0.13-0.12l0.64-0.68l0.09-0.17l0.02-0.25
          l0.12-0.16l0.08-0.05l0.17-0.09l2.11-0.93l0.79-0.25l0.34-0.05l0.62-0.02l0.26,0.02l0.22,0.04l3.23,0.87l1.34,0.65l3.2,2.79
          l0.13,0.13l1.29,0.96l0.08,0.05l0.55,0.36l0.12,0.02l0.25-0.02l0.29-0.12l0.53-0.09l0.12-0.02l0.09,0.04l0.86,0.57l0.47,0.32
          l0.19,0.21l0.12,0.16l0.08,0.08l0.7,0.49l0.25,0.17l2.82,1.31l0.19,0.08l0.21,0.05l0.76,0.19h0.23l0.21-0.06l0.29-0.25l0.12-0.02
          l0.12,0.02l0.93,0.79l0.72,0.74l0.76-0.3l4.52-1.16l0.12-0.02l0.12,0.02l0.3,0.17l0.04,0.04l7.91,7.21l0.04,0.25l0.08,0.65
          l0.08,0.65l0.19,1.63l0.17,1.63l0.51-0.25l0.22-0.04l0.25,0.02l1.06,0.25l0.21,0.05l0.21,0.08l1.41,0.17l1.33-0.4l0.47-0.19
          l0.62-0.57l0.12-0.16l0.04-0.09l0.08-0.05l0.08-0.05l0.47-0.21l0.09-0.04l0.21-0.04l2.32,0.21l3.16,0.63l0.33,0.08l0.66,0.29
          l0.17,0.09l0.26,0.26l1.52,0.59l2.67,0.72l0.51,0.05l0.61-0.05l0.7-0.08l0.61,0.02l0.33,0.08l1.55,1.31l1.67,1.52l0.16,0.12
          l0.44,0.25l0.29,0.12l0.21,0.05l0.3-0.09l0.25-0.02l0.23,0.04l0.19,0.08l0.08,0.05l0.12,0.13l0.7,0.95l0.12,0.16l0.04,0.09
          l0.33,0.72l0.09,0.3l0.02,0.12l-0.04,0.38l-1.34,2.84l-0.29,0.38l-0.29,0.26l-2.94,2.42l-2.69,2.01l-0.16,0.13l-0.09,0.17
          l-0.05,0.21l-0.19,2.83v0.25l0.04,0.12l0.05,0.08l0.08,0.05l0.26,0.26l0.12,0.17l0.15,0.38l0.05,0.21l0.01,0.25l-0.01,0.12
          l-0.04,0.09l-0.08,0.05l-0.79,0.55l-0.25,0.16l-0.26,0.02l-1.27-0.04l-1.04-0.08l-0.4-0.13l-0.29-0.09l-0.08,0.05l-0.66,0.82
          l-0.12,0.16l-0.08,0.17l-0.04,0.12l-0.89,3.53v0.26l-0.32,2.82l-0.05,0.08l-0.13,0.26l-0.05,0.23l-0.12,1.37v0.26l0.02,0.12
          l0.05,0.08l0.08,0.08l0.08,0.06l0.12,0.01l0.12-0.01l0.17-0.09l0.16-0.26l0.05-0.21l0.08-0.17l0.05-0.08l0.09-0.05l0.59-0.19
          l0.13,0.01l0.05,0.08l0.55,1.29l0.05,0.19l0.04,0.36l-0.01,0.12l-0.27,0.57l-0.05,0.21l-0.02,0.25l0.05,0.34l0.08,0.32l0.04,0.09
          l0.08,0.08l0.09,0.04l1.98,1.29l2.88,2.12l0.15,0.12l0.3,0.49l0.13,0.26l0.13,0.4l0.05,0.34l-0.02,0.25l-0.04,0.12l-0.08,0.19
          l-0.09,0.17l-0.12,0.16l-0.26,0.29l-0.17,0.23l-0.13,0.29l-0.01,0.12l0.08,0.32l0.38,0.74L4224.56,1291.42L4224.56,1291.42z"/>
      </g>
    );
  } else if (props.state === 'RO') {
    stateSelected = (
      <path id="RO" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M257.01,386.96l-0.46-1.67l-0.02-0.49l-0.04-0.09
        l-0.19-0.08l-1.57-0.51l-0.34-0.04l-0.34,0.08l-0.17,0.12l-0.21,0.17l-0.32,0.09l-1.03,0.08l-1.03-0.04l-0.66-0.25l-4.28-1.88
        l-0.06-0.05l-0.01-0.13l0.08-0.32l-0.05-0.21l-0.49-0.44l-0.26-0.16l-0.12-0.02l-0.12,0.04l-1.34,0.74l-1.41-1.21l-1-0.79
        l-1.46-0.91l-0.4-0.26l-1-1.67l-0.09-0.17l-0.19-0.74l-0.04-0.23l0.02-0.36l-0.04-0.25l-0.06-0.08l-0.15-0.12l-1.04-0.29l-0.13,0.02
        l-0.51,0.25l-0.85,0.49l-0.16,0.12l-0.25,0.29l-0.21,0.19l-0.21,0.05l-1.25-0.04l-0.25-0.04l-1.21-0.66l-0.4-0.29l-0.93-0.95v-0.32
        l-0.04-0.21l-0.17-0.23l-3.74-1.78l-2.39-0.21l-0.66,0.26l-0.72,0.38l-0.53,0.66l-0.38,0.3l-0.29,0.12l-0.12-0.02l-1.42-0.19
        l-0.17-0.09l-0.08-0.08l-0.17-0.23l-0.13-0.13l-0.09-0.04l-2.42-0.47l-1.2-0.12L215,371.9l-1.52-0.27l-0.4-0.13l-0.21-0.13
        l-0.32-0.25l-1.42-1.38l-0.05-0.08l-0.29-2.32l-0.12-0.02l-1.76-0.55l-0.19-0.08l-2.16-1.06l-0.05-0.08l-0.04-0.09l-0.4-0.89
        l-0.47-1l-0.13-0.16l-0.25-0.15l-0.09-0.04l-0.32-0.08l-0.25-0.02l-1.03,0.02l-1.54-0.17l-0.29-0.12l-0.32-0.36l-0.17-0.38
        l-0.36-1.69l-0.16-1.37l-0.34-1l-0.04-0.09l-0.93-1.03l-0.08-0.08l-0.12-0.06l-0.16,0.13l-0.09,0.17l-0.16,1.16l-0.12,0.15
        l-0.3-0.09l-0.4-0.29l-0.16-0.12l-0.17-0.23l-0.13-0.53l0.16-0.49l0.25-0.55l0.34-0.74l0.04-0.12l-1-1.74l-0.05-0.08l-0.13-0.13
        l-0.08-0.05l-0.09-0.04l-0.34-0.06l-0.47,0.08l-0.25-0.02l-0.09-0.04l-0.08-0.06l-0.13-0.13l-0.17-0.23l-0.51-1.44l-0.76-2.32
        l-0.08-0.32l-0.17-1.84l0.16-0.38l0.96-1.74l0.68-1.12l0.12-0.17l0.02-0.09l-0.29-1.99l-0.13-0.4l-0.15-0.23l-0.13-0.13l-0.99-1.61
        l-0.89-2.5l-0.04-0.23l-0.19-2.54l0.13-0.19l0.32-0.05l0.36-0.3l0.16-0.25l0.04-0.09l0.09-0.29l0.02-0.12l-0.09-0.32l0,0l0.13-0.4
        l0.16-0.26l0.12-0.16l0.4-0.4l0.23-0.17l0.22-0.17l0.21-0.19l0.12-0.16l0.08-0.19l0.38-1.2l0.02-0.12l0.02-0.25l-0.04-0.25
        l-0.25-1.57l-0.05-0.21l-0.16-0.38l-0.3-0.49l-0.04-0.09l-0.05-0.21l-0.05-1.44l0.04-0.49l0.16-0.38l0.09-0.17l0.17-0.25l0.25-0.55
        l0.02-0.34l-0.04-0.12l-0.09-0.17l-0.68-1.25l-0.57-1.03l-0.19-0.21l-0.16-0.12l-0.29-0.12l-0.12-0.02l-0.21-0.05l-0.38-0.06
        l-0.95,0.76l-0.38,0.42l-0.05,0.47v0.25l-0.04,0.09l-0.91,0.95l-0.08,0.05l-0.12,0.02l-0.49-0.02l-0.12-0.02l-0.09-0.04l-2.54-1.2
        l-0.89,0.08l-0.99,0.05l-2.45,0.04l-1.95-0.08l-0.12-0.02l-1.65,0.38l-0.3,0.09l-3.81,1.27l-0.78,0.4l-0.12,0.02l-0.25-0.02
        l-0.12-0.02l-0.43-0.12l-0.25-0.02l-1.08,0.12l-0.12,0.02l-1.44,0.29l-0.19,0.08l-0.08,0.08l-0.04,0.15l-0.12-0.09l-2.67-1.08
        l-0.79-0.32l-0.04-0.02l0.23-0.19l1.1-0.89l2.21-1.74l0.44-0.29l0.66-0.3l0.7-0.17l0.44-0.02l0.26-0.08l0.19-0.05l0.21-0.16
        l1.71-2.01l0.16-0.19l0.09-0.4l0.05-0.38l-0.02-0.17l-0.05-0.23l-0.26-0.7l0.02-0.21l0.12-0.17l0.21-0.04l0.17,0.05l1.76,0.42
        l2.58,0.02l0.66-0.04l0.44-0.05l0.4-0.05l0.7-0.13l0.79-0.16l0.21-0.02l0.19,0.02l0.36,0.05l0.19,0.08l0.21,0.09l0.3,0.26l1.69,1.71
        l1.04,1.06l0.19,0.12l0.25-0.04l0.21-0.09l1.29-0.68l0.09-0.19l0.05-0.17l0.09-0.62l0.05-0.21l0.08-0.17l0.19-0.21l0.21-0.16
        l1.21-0.59l0.19-0.06l0.21-0.02l0.19,0.02l0.21,0.06l0.17,0.09l0.19,0.13l1.44-0.47l0.19-0.17l1.38-1.29l0.21-0.17l2.07-1.12
        l0.19-0.08l0.19-0.02l0.19,0.12l0.05,0.17l0.09,0.83l0.34,1.27l0.05,0.17l0.13,0.25l0.17,0.19l0.19,0.19l0.25,0.19l0.19,0.12
        l0.21,0.08l0.25,0.02l0.19-0.02l0.17-0.08l0.17-0.12l0.4-0.32l0.19-0.19l2.37-3.16l0.09-0.19l0.08-0.17l-0.05-0.4l-0.16-0.42
        l-0.17-0.36l-0.12-0.38l0.09-0.46l0.51-0.76l0.16-0.17l0.57-0.51l0.21-0.17l1.17-0.7l0.21-0.08l0.19-0.02l0.23,0.04l0.21,0.09
        l0.36,0.38l0.21,0.17l0.19,0.05l0.21,0.02l1.48,0.12l0.47,0.02l0.25-0.04l0.21-0.09l0.17-0.19l0.12-0.19l0.05-0.17l0.17-0.21
        l0.21-0.17l0.4-0.23l0.57-0.17l1.91-0.29l0.21-0.02l0.23,0.02l0.21,0.04l0.93,0.19l1.42,0.19l1.74,0.23l0.19-0.16l0.02-0.21
        l-0.04-0.21l-0.25-0.78l-0.09-0.42l-0.02-0.21l0.13-2.08l0.74-0.61l0.17-0.02l0.21-0.08l0.99-0.4l0.17-0.09l0.16-0.17l0.16-0.21
        l0.93-1.29l0.42-0.96l0.05-0.19l-0.05-0.36l-0.09-0.17l-0.17-0.17l-0.59-0.34l-0.21-0.16l-0.17-0.17l-0.09-0.19l-0.05-0.21
        l0.02-0.17l0.08-0.23l0.13-0.19l1.38-1.84l0.4-0.17l0.21-0.05l0.17-0.02l0.38,0.02l0.4-0.02l1.37-0.36l0.19-0.05l0.21-0.16
        l0.04-0.19l-0.04-0.19l-0.08-0.17l-0.12-0.38l-0.08-0.38l0.12-0.19l0.17-0.12l0.59-0.19l1.31-0.3l0.17-0.02l0.63-1.46l0.02-0.19
        l0.12-0.19l0.34-0.59l0.13-0.19l0.3-0.4l0.16-0.16l14.92,0.82l0.4,0.21l0.17,0.13l0.21,0.21l0.55,0.59l0.36,0.4l0.02,0.17
        l-0.04,0.17l-0.08,0.17l0.19,0.42l0.46,0.87l0.12,0.19l0.13,0.17l0.21,0.21l1.23,1.06l1.46,0.68l0.17-0.02l0.57,0.09l0.42,0.16
        l0.38,0.19l0.17,0.17l0.17,0.19l0.12,0.17l0.47,1.34l0.42,1.29l0.19,0.78l0.17,0.25l0.36,0.12l0.21,0.02l0.4-0.02l0.61-0.13
        l0.19-0.02l0.42,0.08l0.17,0.09l0.12,0.17l0.3,1.55l0.23,1.24l0.08,0.38l0.05,0.21l0.19,0.17l2.92,1.34l0.82,0.27l0.21,0.05
        l0.23-0.02l0.17-0.08l0.23-0.13l0.17-0.13l0.12-0.17l0.05-0.19l0.02-0.17l0.08-0.83l0.16-0.44l0.12-0.19l0.16-0.19l2.15-1.17
        l0.21,0.02l1.46,0.3l0.26,0.09l0.19,0.15l0.16,0.19l-0.09,0.91l0.83,0.53l0.79,0.55l0.16,0.19l0.32,0.47l0.23,0.44l0.12,0.4
        l-0.02,0.19l-0.38,0.61l-0.34,0.65l-0.19,0.38l-0.4,1.33l-0.06,0.23l-0.87,3.22l-0.21,1.16l-0.04,0.21l0.04,0.23l0.12,0.21
        l0.23,0.21l0.23,0.12l0.44,0.16l0.3,0.66l0.02,0.17l0.17,1.59l0.53,1.61l0.57,0.87l-0.16,0.44l-1.08,1.86l0.09,0.42l0.38,1.84
        l-0.38,1.59l-0.44,1.63l-0.25,0.51l-0.17,0.62l-0.04,0.96l0.29,2.01l0.08,0.23l0.82,1.78l0.12,0.21l0.21,0.16l0.17,0.16l0.17,0.21
        l0.13,0.21l0.17,0.4l-0.53,5.19l-0.57,5.74l19.41-0.02l1.54,0.02l-0.02,0.09l-0.26,0.36l-0.09,0.17l0.17,0.38l1.1,1.02l0.19,0.09
        l0.19-0.01l0.19-0.08l1.99-0.05l2.11,0.02l1.27,0.13l1.2,0.46l0.19,0.16l1.59,4.15l0.08,0.19l-0.02,0.26l-0.08,0.21l-0.09,0.21
        l-0.13,0.21l-1.65,1.99l-0.63,0.53l-0.82,0.57l-0.21,0.17l-0.12,0.17l-0.05,0.93l-0.02,0.42l0.02,0.21l0.12,1.38l0.3,1.99l0.12,0.42
        l0.19,0.34l0.16,0.19l0.25,0.09l0.23-0.04l0.38-0.16l0.17-0.02l0.21,0.02l0.19,0.05l0.15,0.21l1.71,3.62l0.04,0.21l0.02,0.25
        l-0.04,0.68l-0.04,0.3l-0.02,0.57l0.02,0.61l0.02,0.25l0.04,0.21l0.05,0.26l0.16,0.42l0.12,0.23l0.34,0.55l-0.09,2.04l-0.65,2.6
        l-0.08,0.19l-0.42,0.59l-0.16,0.19l-0.42,0.44l-0.7,0.62l-0.17,0.19l-0.12,0.19l-0.08,0.19l-0.42,1.42l-0.13,1.06l-0.12,0.21
        l-0.23,0.36l-0.4,0.55l-0.29,0.36l-0.17,0.13l-0.23,0.08l-0.43,0.02l-0.19,0.02l-0.42,0.09l-0.21,0.05l-0.21,0.09l-0.38,0.29
        l-0.95,1.03l-0.17,0.21l-0.12,0.17l-1.61,4.27l-0.05,0.23l-0.08,0.38l-0.19,0.95l-0.05,0.59l-0.06,0.4l-0.04,0.21l-0.09,0.19
        l-0.19,0.21l-0.3,0.19l-0.66,0.34l-1.29,0.72l-2.66,1.71l-0.79,0.65l-0.21,0.19l-0.4,0.38l-0.4,0.49l-0.49,0.02l-0.25-0.02
        l-0.3-0.12l-3.02-1.78l-0.89-0.79l-0.66-0.91l-4.24,0.57l-4.4,0.57l-0.38-0.02l-0.32-0.08l-0.85-0.36l-0.13-0.16l-0.25-0.29
        l-0.44,0.12l-2.12,0.44l-0.09,0.01l-1.48,0.21l-0.46-0.08l-0.16-0.12l-0.17-0.21l-0.32-0.89l-0.08-0.46l-0.04-0.09l-0.05-0.05
        l-1.27-1l-0.55-0.4l-0.57-0.25L257.01,386.96L257.01,386.96z"/>
    );
  } else if (props.state === 'PI') {
    stateSelected = (
      <g id="PI">
        <path id="path882" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M665.59,275.02l-0.32,0.08l-0.19,0.08l-0.34,0.19
          l-0.13,0.13l-0.04,0.08l-0.04,0.09l-0.04,0.23l-0.04,0.36l-0.02,0.51l0.09,0.42l0.04,0.09l0.64,1.21l0.15,0.25l-0.17,2.54
          l1.27,1.44l0.17,0.09l0.55,0.4l0.3,0.34l0.05,0.34l0.01,0.12l0.02,0.74l-0.04,1.27l-0.02,0.25l-0.05,0.36l-0.09,0.44l-0.49,1.1
          l-0.21,0.46l-0.21,0.3l-0.21,0.19l-1.52,1.33l-1.73,1.78l-1,1.25l-0.09,0.04l-0.12-0.02l-0.17-0.08l-0.96,0.4l-2.79,1.61
          l-0.61,0.99l-0.12,0.16l-2.35,1.99l-0.49,0.29l-1,0.55l-0.27,0.13l-0.36,0.16l-0.93,0.38l-0.16,0.06l-0.49,1.27l-0.13,0.26
          l-1.74,2.63l-0.05,0.05l-1.1,0.76l-0.19,0.05l-0.23-0.05l-0.29-0.13l-0.08-0.05l-0.23-0.04l-0.25,0.02l-2.2,0.29l-0.08,0.05
          l-0.05,0.08l-0.04,0.09l-0.05,0.23l0.02,0.12l0.17,0.74l0.02,0.36l0.09,1.31l-0.02,0.12l-0.21,0.3l-1.72,1.61l-0.29,0.25
          l-0.17,0.09h-0.13l-0.12-0.04l-0.16-0.12l-0.05-0.08l-0.08-0.19l-0.02-0.12l-0.09-0.16l-0.08-0.05l-0.19-0.08l-0.3-0.08l-1.3-0.08
          l-0.91-0.04l-0.21,0.05l-0.16,0.12l-0.12,0.16l-0.32,0.47l-0.34,0.46l-0.16,0.12l-0.17,0.09l-0.64,0.29l-0.21,0.05l-1.74,0.21
          l-0.96-0.05l-0.12-0.02l-0.12,0.02l-0.15,0.09l-0.3,0.23l-1.59,1.42l-0.21,0.19l-0.32,0.47l-0.57,0.91l-2.08,0.13l-1.8,0.78
          l-2.86,0.25l-0.79-0.09l-0.91-0.3l-0.19-0.08l-0.05-0.08l-0.09-0.16l-0.02-0.12l0.04-0.25l0.21-0.32l0.02-0.12l-0.08-0.17
          l-0.08-0.05l-1.95-1l-0.09-0.04l-1.42-0.44l-0.12-0.04l-0.21,0.04l-0.62,0.3l-0.91,0.34l-2.5,0.53h-0.13l-0.12-0.02l-0.08-0.04
          l-0.08-0.05l-0.19-0.21l-0.05-0.08l-0.34-0.96l-1.84-0.57l-2.39,0.02l-0.38,0.16l-3.47,1.71l-0.08,0.05l-0.09,0.17l-0.01,0.12
          l-0.08,0.34v0.26l0.04,0.23l0.12,0.26l0.26,0.38l2.41,4.86l0.09,0.4l0.04,0.36l0.04,0.47l-0.02,0.34l-0.34,2.92l-0.09,0.57
          l-0.05,0.23l-0.02,0.09l-0.08,0.19l-0.05,0.08l-0.13,0.13l-0.74,0.85l-0.82,1.34l-0.05,0.09l-0.89,1.76l-0.13,0.26l-1.71,2.63
          l-3.23,2.66l-0.3,0.23l-0.17,0.08l-1.38,0.46l-0.12,0.02h-0.13l-0.12-0.02l-0.21-0.05l-0.19-0.08l-0.08-0.04l-0.29-0.12
          l-0.47-0.21l-0.38-0.13l-1.04-0.17l-0.51-0.04l-0.12,0.02l-2.54,0.85l-0.17,0.08l-1.34,1.03l-0.82,1.06l-0.34,0.05l-0.4,0.12
          l-0.57,0.23l-0.17,0.09l-0.13,0.13l-0.13,0.26l-0.21,0.47l-0.05,0.21l-0.12,0.29l-0.12,0.29l-0.13,0.13l-0.25,0.16l-0.19,0.08
          l-0.21,0.05l-0.25,0.02h-0.13l-0.36-0.05l-0.42-0.12l-0.57-0.12l-0.36-0.06l-0.36,0.02l-0.55,0.09l-0.09,0.04l-0.26,0.12
          l-0.08,0.05l-0.13,0.13l-0.08,0.05l-0.29,0.12l-0.25,0.04h-0.27l-0.12-0.02l-0.09-0.04l-1.31-0.57l-0.19-0.08l-3.4-1.55
          l-2.12-2.96l-0.04-0.08l-0.34-0.83l-0.01-0.12l-0.09-0.66l-0.02-0.49l-0.09-0.42l-0.04-0.09l-0.13-0.25l-0.25-0.4l-0.44-0.63
          l-1.13-1.17l-0.21-0.19l-0.16-0.12l-0.16-0.12l-0.19-0.05l-1.12-0.02l-0.89,0.02l-0.74,0.05l-1.82-0.04l-1.76-0.23l-0.04-0.57
          l0.12-1.74l0.32-1.29l0.42-0.66l0.08-0.17l0.86-1.97l0.09-0.3l0.01-0.25l-0.01-0.12l0.12-7.21l0.22-0.7l0.16-0.26l0.06-0.08
          l0.25-0.29l0.19-0.21l0.04-0.09l0.04-0.09l0.04-0.25l0.02-0.26l-0.04-0.23l-0.27-0.63l-0.22-0.44l-0.08-0.08l-0.47-0.32
          l-1.08-2.12l-0.12-1.41l-0.46-2.46l-0.68-1.54l-0.13-0.26l-0.08-0.3l0.01-0.25l0.04-0.12l0.61-1.88l0.25-0.42l0.05-0.08l0.51-0.55
          l0.91-0.66l0.42-0.21l0.21-0.21l0.05-0.08l0.09-0.19l1.33-2.79l0.17-0.76l0.02-0.12l-0.08-0.19l0.04-0.78l0.21-0.72l0.23-0.3
          l0.05-0.08l0.08-0.05l0.19-0.08l0.05-0.08l0.19-0.34l1.55-2.94l0.49-1.48l0.26-1.34l0.05-0.91l0.05-1.08l0.38-1.63l0.86-3.03
          l0.34-0.57l1.9-1.78l0.55-0.49l0.08-0.05l0.17-0.08l3.16-0.85l0.12-0.02l1.17-0.19l1.24-0.08l0.74-0.09l1.2-0.36l0.25-0.16
          l0.12-0.16l0.23-0.3l0.46-0.44l1.04-0.86l0.08-0.05l0.12-0.02l1.21-0.02l1.54-0.53l0.17-0.21l2.99-2.71l0.38-0.29l0.08-0.06
          l1.24-0.62l0.09-0.04l0.66-0.25l0.64-0.19l0.12-0.02l0.22,0.05l2.32-2.46l0.72-0.93l0.06-0.08l0.04-0.09l0.02-0.12l-0.08-0.3
          l0.08-0.19l2.14-2.15l0.08-0.05l0.16-0.09l0.47-0.19l0.09-0.04l0.83-0.32h0.12l0.09,0.04l0.05,0.08l0.06,0.21l0.05,0.08l0.08,0.04
          l0.12,0.02l1.21-0.38l0.95-0.49l0.27-0.26l0.17-0.08l1.57-0.13h0.12l0.7,0.13l0.43,0.09l0.08,0.05l2.14,1.48l0.87,0.72l1.84-0.08
          l0.04-0.12l0.63-0.27l0.15-0.05l2.12-0.66l0.12-0.02l0.46-0.05l0.12-0.02l0.26,0.02l0.23,0.04l0.38,0.16l0.3,0.21l2.21-0.29
          l0.46-0.08l0.09-0.04l0.08-0.06l0.95-1.04l0.44-0.49l0.05-0.08l0.57-1.69l0.04-0.09l0.08-0.55l0.05-0.87l0.36-0.53l0.47-2.69
          l-0.16-1.03l-0.13-0.49l-0.13-0.23l-0.22-0.17l-0.16-0.12l-0.17-0.09l-0.21-0.05h-0.12l-0.19-0.08l-0.25-0.15l-0.57-0.36
          l-1.55-1.55l-0.05-0.08l-0.51-0.74l-0.05-0.08l-0.02-0.12l-0.42-1.74l-0.16-0.82l-0.04-0.36l-0.13-2.39l0.13-2.84l0.02-0.12
          l0.08-0.17l0.08-0.08l4.86-5.16l0.12-0.16l0.19-0.55l0.02-0.09l0.12-2.92l-0.68-3.64l-0.55-0.99l-0.21-0.3l-1.08-1.78l-0.04-0.09
          l-0.05-0.21v-0.12l0.01-0.12l0.44-1l0.96-2.35l0.09-0.44l0.25-1.21l-0.02-0.25l-0.44-1.44l-0.04-0.05l-0.43-0.36l-0.17-0.09
          l-0.21-0.05l-0.23-0.02l-0.44-0.47l-0.32-0.85l-0.02-0.12l-0.08-0.66l-0.02-0.25l0.04-0.65l0.02-0.09l0.13-0.29l0.09-0.17
          l0.61-0.57l0.15-0.08l0.57-0.06l0.08-0.05l0.13-0.13l0.66-1.27l0.08-0.19l0.05-0.23l0.05-0.47l0.05-0.21l0.06-0.08l1.03-0.93
          l1.06-0.99l0.17-0.21l0.09-0.17l0.96-2.04l0.02-0.12l0.01-1.06v-0.26l-0.15-0.36l0.08-0.46l0.66-0.91l0.66-0.78l2.24-2.49
          l0.99,0.19l2.9-0.29l1.03-0.23l0.26-0.12l4.15-4.02l0.76-0.79l2.12-2.71l0.09-0.17l0.47-1.27l0.05-0.34l-0.01-0.12l-0.04-0.09
          l-0.05-0.08l-0.15-0.12l-0.17-0.09l-0.17-0.08l-0.26-0.25l-0.16-0.36l-0.12-0.4l-0.01-0.23l0.04-0.36l0.36-1l0.17-0.26l0.26-0.09
          l0.63,0.25l1.25,0.74l0.53,0.32l0.3,0.21l0.13,0.12l0.21,0.17l0.05,0.08l0.04,0.09l-0.15,0.05l-0.12-0.15l-0.08-0.06l-0.12-0.02
          l-0.25,0.13l-0.04,0.05l0.05,0.08l0.42,0.25l1.17,0.38l0.34,0.05l1.37,0.12h0.12l0.42-0.12l0.19-0.08l0.16-0.09l0.09-0.04
          l0.09-0.02l1.21,0.25l0.12,0.02l1.21,0.38l0.09,0.04l1.07,0.85l0.19,0.32l0.3,0.57v0.16l-0.09,0.7l-0.04,0.15l-0.08,0.17
          l-1.2,1.97l-0.12,0.16l-0.78,1.06l-0.47,0.59l-0.22,0.21l-0.09,0.08l-0.25,0.29l-0.13,0.26l-0.16,0.38l-0.05,0.21l-0.01,0.25
          l0.08,0.83l0.25,1.17l0.12,0.4l0.17,0.49l0.42,1.03l0.61,1.54l0.4,1.03l0.17,0.55l0.16,0.49l0.26,0.65l0.3,0.74l0.76,1.71
          l1.74,3.62l0.32,0.59l0.16,0.25l0.21,0.26l0.05,0.12l0.29,0.63l0.19,0.46l0.13,0.38l0.23,0.82l0.02,0.21l-0.02,0.25l0.3,0.17
          l0.32,0.32l0.51,0.66l0.51,0.76l0.06,0.36l-0.08,0.55l-0.17,0.4l-0.22,0.3l-0.25,0.4l-0.21,0.46l-0.08,0.32l0.02,0.36l0.32,0.89
          l0.34,1.1l0.3,2.6l-0.05,0.34l-0.49,0.96l-0.05,0.21l0.04,0.36l0.29,0.63l0.91,1.44l0.08,0.19l0.7,3.57l-0.02,0.4l-0.15,0.49
          l-0.4,0.82l-0.34,0.44l-0.49,0.3l-0.3,0.19l0.83,0.57l0.22,0.15l0.15,0.23l0.05,0.21l0.02,0.29l0.17,2.2l0.16,1.5l0.43,3.96
          l0.44,2.08l-0.09,0.21l-0.25,0.46l0.93,3.13l0.85,4.57l0.38,1.67l0.08,0.23l1,2.81l0.12,0.23l0.19,0.21l1.5,0.68l1.12,0.25
          l0.64,0.12l0.43,0.08l0.44,0.04l0.4,0.05l0.19,0.12l0.83,1.29l0.02,0.16l0.01,0.61l-0.05,0.23l-0.08,0.17l-0.4,0.66l-0.57,0.76
          l-0.57,0.76l-0.21,0.32l-0.25,0.55l-0.57,1.57l-0.55,1.57l-0.05,0.21l-0.05,0.36l0.04,0.23l0.09,0.4l0.09,0.17l0.72,0.61
          l0.12,0.17l0.05,0.19l-0.02,0.96l-0.05,0.21l-0.09,0.21L665.59,275.02L665.59,275.02z"/>
      </g>
    );
  } else if (props.state === 'MT') {
    stateSelected = (
      <g id="MT" transform="translate(-3550.8686,-814.85365)">
        <path id="path18" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M3833.82,1209.13l0.4-0.38l0.21-0.19l0.79-0.65l2.66-1.71
          l1.29-0.72l0.66-0.34l0.3-0.19l0.19-0.21l0.09-0.19l0.04-0.21l0.05-0.4l0.05-0.59l0.19-0.95l0.08-0.38l0.05-0.23l1.61-4.27
          l0.12-0.17l0.17-0.21l0.95-1.03l0.38-0.29l0.21-0.09l0.21-0.05l0.42-0.09l0.19-0.02l0.44-0.02l0.23-0.08l0.17-0.13l0.29-0.36
          l0.4-0.55l0.23-0.36l0.12-0.21l0.13-1.07l0.42-1.42l0.08-0.19l0.12-0.19l0.17-0.19l0.7-0.62l0.42-0.44l0.15-0.19l0.42-0.59
          l0.08-0.19l0.65-2.6l0.09-2.04l-0.34-0.55l-0.12-0.23l-0.16-0.42l-0.05-0.26l-0.04-0.21l-0.02-0.25l-0.02-0.61l0.02-0.57l0.04-0.3
          l0.04-0.68l-0.02-0.25l-0.04-0.21l-1.71-3.62l-0.16-0.21l-0.19-0.05l-0.21-0.02l-0.17,0.02l-0.38,0.16l-0.23,0.04l-0.25-0.09
          l-0.15-0.19l-0.19-0.34l-0.12-0.42l-0.3-1.99l-0.12-1.38l-0.02-0.21l0.02-0.42l0.05-0.93l0.12-0.17l0.21-0.17l0.82-0.57l0.63-0.53
          l1.65-1.99l0.13-0.21l0.09-0.21l0.08-0.21l0.02-0.26l-0.08-0.19l-1.59-4.15l-0.19-0.16l-1.2-0.46l-1.27-0.13l-0.17-0.02l-3.91,0.05
          l-0.19,0.08l-0.19,0.02l-0.19-0.09l-1.1-1.03l-0.17-0.17l0.09-0.38l0.26-0.36l0.02-0.09l-1.54-0.02l-19.41,0.02l0.57-5.74
          l0.53-5.02l-0.17-0.59l-0.13-0.21l-0.17-0.21l-0.17-0.16l-0.21-0.16l-0.12-0.21l-0.82-1.78l-0.08-0.23l-0.29-2.01l0.04-0.96
          l0.17-0.62l0.25-0.51l0.44-1.63l0.38-1.42l-0.38-2.03l-0.09-0.42l1.08-1.86l0.16-0.21l-0.57-1.1l-0.53-1.61l-0.17-1.59l-0.02-0.17
          l-0.3-0.66l-0.44-0.16l-0.23-0.12l-0.23-0.21l-0.12-0.21l-0.04-0.23l0.04-0.21l0.21-1.16l0.87-3.23l0.05-0.23l0.4-1.33l0.19-0.38
          l0.34-0.64l0.38-0.61l0.02-0.19l-0.12-0.4l-0.22-0.44l-0.33-0.47l-0.15-0.19l-0.79-0.55l-0.83-0.53l7.06-0.02l2.84-0.05l3.57-0.05
          l4.21,0.04l8.61-0.16l3.62,0.09l7.13-0.09l4.35-0.09l3.75,0.01l4.24-0.05l3.26-0.05l3.36-0.02l2.42,0.04l1.46-0.89l0.76-3.58
          l0.63-2.66l0.09-0.21l0.15-0.38l0.79-2.21l0.7-2.33l-0.05-0.63l-0.25-1.03l-0.25-0.82l-0.16-0.38l-0.51-0.89l-0.13-0.38l-0.09-0.55
          l-0.02-0.17l-0.02-0.21l0.02-0.19l0.04-0.21l0.08-0.19l0.12-0.19l0.29-0.36l0.38-0.34l0.57-0.34l0.19-0.19l1.13-1.74l0.23-0.38
          l0.29-0.59l0.09-0.38l-0.02-0.23l-0.04-0.17l-0.25-0.4l-0.15-0.38l-0.02-0.19l0.06-0.74l0.05-0.19l0.23-0.62l1.25-1.31l0.19-0.16
          l0.86,0.72l0.21,0.19l0.36,0.4l0.61,0.87l0.82,1.21l0.16,0.23l1.44,2.62l0.21,0.49l0.17,1.21l0.12,0.46l1.12,3.32l0.47,1.34
          l0.09,0.19l0.13,0.19l1.48,2.11l0.93,1.97l-0.26,1.29l-0.02,1.67l0.59,2.9l0.89,2.67l1.76,1.29l0.23,0.12l2.35,2.29l0.08,0.25
          l0.99,0.68l0.25,0.13l2.11,1.04l1.82,0.79l0.09,0.17l0.09,0.26l0.08,0.4l0.08,0.46l-0.05,0.82l-0.02,0.43l0.04,0.17l0.16,0.38
          l0.13,0.21l0.19,0.09l0.95,0.3l0.4-0.19l0.19-0.01l0.4,0.01l1.08,0.21l0.4,0.09l0.4,0.17l0.21,0.17l0.17,0.21l0.16,0.17l0.25,0.4
          l-0.05,0.42l0.01,0.21l0.19,0.55l0.12,0.19l0.13,0.21l0.17,0.12l0.19,0.02l0.25-0.02l0.21-0.05l0.44-0.25l0.17-0.12l0.19-0.08
          l0.21-0.02l1.94,0.63l0.19,0.13l2.49,1.04l4.41,0.32l5.5,0.4l10.18,0.72l10.87,0.74l3.07,0.21l3.36,0.23l0.76,0.05l3.37,0.26
          l8.76,0.53l8.63,0.49l4.32,0.25l7.63,0.42l7.97,0.46l5.65,0.3l6.64,0.36l1.99,0.12l8.63,0.46l10.3,0.51l0.33,0.04l4.32,0.21
          l1.71,0.08l-0.51,0.68l-0.29,0.53l-0.3,0.91l-0.12,0.83l-0.08,0.19l-0.19,0.34l-0.15,0.25l-0.16,0.25l-0.12,0.16l-0.15,0.13
          l-0.21,0.05l-0.08,0.05l-0.47,0.59l-0.05,0.08l-0.21,0.59l-0.04,0.36l0.04,0.7l0.08,0.3l-0.02,0.38l-0.34,1.42l-0.15,0.4
          l-0.04,0.09l-0.22,0.44l-0.19,0.21l-0.23,0.16l-0.25,0.16l-0.55,1.9l-0.53,1.54l-0.4,0.76l-0.08,0.05l-0.68,2.07l-0.44,1.63
          l-0.22,1.95l-0.06,1.69l0.02,1.86l-0.7,2.35l-0.7,1.78l-0.3,0.91l-0.26,0.82l-0.04,0.09l-0.29,1.2l-0.02,0.13l-0.02,0.74l0.08,0.19
          l0.05,0.08l0.12,0.16l0.34,0.46l0.15,0.12l0.36,0.17l0.44,0.23l0.19,0.21l0.05,0.08l0.02,0.12v0.12l-0.02,0.12l-0.09,0.72
          l-0.05,0.21l-0.05,0.08l-0.08,0.05l-0.17,0.09l-0.47,0.46l-0.25,0.29l-0.02,0.12l-0.04,0.55l0.68,1.57l0.36,0.79l0.12,0.04
          l0.09,0.04l0.13,0.13v0.12l-0.02,0.32l-0.55,3.47l-0.04,0.23l-0.04,0.23l0.04,1.54l0.02,0.12l0.08,0.19l0.15,0.25l0.09,0.04h0.12
          l0.12,0.04l0.05,0.08l0.05,0.08l0.08,0.19l0.16,0.99l0.12,0.89l0.05,0.46l0.12,0.99l0.02,0.25v0.09l-0.49,1.69l-0.42,1.42
          l0.91,3.19l0.26,0.79l0.05,0.04l0.7,0.38l0.46,0.21l0.96,0.02l0.04,0.05l0.05,0.3l0.02,0.25l-0.05,0.32l-0.26,1.08l-0.08,0.19
          l-0.29,0.38l-0.08,0.05l-0.09,0.04l-0.57,0.34l-0.21,0.19l-0.05,0.08l-0.26,0.91l0.05,0.63l0.05,0.19l0.02,0.23l0.09,2.04
          l-0.08,0.32l-0.36,0.99l-0.13,0.26l-1.2,2.03l-1.17,1.63l-0.83,1.95l-0.42,1.2l-0.17,0.23l-0.21,0.19l-0.3,0.25l-0.12,0.02h-0.13
          l-0.12,0.02l-0.38,0.16l-0.13,0.13l0.02,0.12l0.23,1.78l-0.02,4.24l-1.08,2.01l-0.85,1.65l-0.51,2.15l-0.04,0.21l-0.15,0.91
          l0.26,0.76l0.04,0.09l0.25,0.4l0.08,0.19l-0.01,0.93l-0.04,0.09l-0.49,1.21l-0.91,2.01l-1.03,3.44l-0.04,0.12l-1.67,1.65
          l-1.12,0.72l-0.25,0.12l-0.23,0.04l-0.25-0.02l-0.12-0.04l-0.16-0.12l-0.09-0.16l-0.76-0.49l-1.25,0.29l-0.09,0.04l-1.97,1.2
          l-2.29,2.21l-0.12,0.17l-0.4,0.79l-0.38,2.07l-0.23,1.41l-0.09,0.83l-0.05,0.47l-0.04,0.09l-0.12,0.15l-0.76,0.96l-1.52,3.45
          l-0.09,0.17l-0.82,1.33l-0.26,0.26l-0.25,0.16l-1.9,0.61l-1.2,0.34l-0.25-0.02l-2.15,0.04l-0.25,0.01l-0.66,0.06l-0.08,0.05
          l-0.04,0.08l-0.72,1.42l-0.85,1.5l-0.05,0.08l-0.15,0.12l-0.74,0.3l-0.51,0.13l-0.17,0.08l-0.17,0.09l-0.36,0.3l-0.09,0.19
          l-0.13,0.59l-0.02,0.26l0.02,0.46l0.08,0.42l-1,1.69l-0.34,0.17l-0.26,0.13l-0.59,0.49l-0.26,0.29l-0.19,0.32l-0.36,0.63l-0.16,0.4
          l-0.17,0.7l-0.06,0.38l-0.01,0.25l-0.06,0.49l-0.08,0.34l-0.08,0.21l-1.42,2.16l-0.17,0.25l-0.26,0.29l-1.16,1l-0.21,0.19
          l-0.49,0.42l-0.26,0.13l-1.12,0.43l-1.12,0.44l-0.19,0.08l-0.53,0.55l-0.19,0.21l-0.08,0.21l-0.22,1.25l-0.12,1.57l-0.06,0.38
          l-0.05,0.23l-0.29,0.4l-0.06,0.08l-0.99,1.21l-1.44,2.07l-0.09,0.19l-0.17,0.4l-0.04,0.09l-0.02,0.12l-0.46,3.7l-0.01,0.25
          l0.01,0.12l0.08,0.4l0.05,0.19l0.05,0.08l0.16,0.12l0.13,0.13l0.12,0.16l0.61,0.83l0.7,1.25l0.16,1.67l-0.12,0.47v0.36l0.02,0.23
          l0.13,1.08l0.04,0.32l0.04,0.09l0.25,0.53l0.17,0.23l0.32,0.59l0.34,0.68l0.05,0.3l-0.46,0.21l-1.34,0.17h-0.21l-0.17-0.08
          l-0.21-0.12l-0.17-0.16l-0.19-0.13l-0.46-0.17l-0.21-0.05l-0.4-0.05l-0.57-0.01l-3.24,0.09l-0.46,0.55l-0.17,0.13l-0.23,0.01
          l-5.48-0.36l-0.63-0.25l-0.27-0.25l-0.4-0.4l-0.42-0.38l-0.44-0.21l-0.79-0.19l-0.78,0.05l-0.19-0.62l0.25-0.53l0.21-0.36
          l1.71-2.84l0.17-0.17l0.17-0.16l0.19-0.09l0.74-0.23l0.79-0.13l0.17-0.06l0.16-0.17l0.3-1.9l0.38-2.25l0.29-1.2l0.19-0.59
          l0.15-0.61l0.05-0.4l0.02-0.7l-0.96-0.04l-0.36,0.05l-0.3,0.13l-1.24,0.96l-0.13,0.17l-0.12,0.19l-0.82,1.1l-0.79,0.95l-3.16,3.24
          l-0.34,0.32l-0.21,0.12l-0.19,0.02l-0.44-0.09l-0.21-0.02l-0.46,0.02l-0.64,0.16l-1.07,0.38l-0.21,0.09l-0.25,0.17l-0.36,0.21
          l-0.17,0.09l-0.4-0.02l-0.63-0.05l-0.19-0.04l-0.17-0.17l-0.05-0.19l-0.12-0.53l-0.02-0.38l0.04-0.19l-0.04-0.17l-0.12-0.17
          l-0.19-0.12l-1.86-1.16l-0.4-0.13l-0.95-0.02h-0.38l-1.24,0.05l-0.38,0.09l-1.38,0.63l-0.21,0.34l-0.26,0.55l-0.21,0.17l-1.44,0.83
          l-0.42,0.17l-0.21,0.04l-3.02,0.46l-0.17,0.02l-1.59,0.08h-0.21l-0.47-0.17l-0.53-0.25l-0.42-0.25l-0.66-0.44l-0.42-0.32
          l-0.23-0.34l-0.19-0.19l-0.19-0.12l-1.8-0.53l-0.99-0.3l-0.4-0.21l-0.38-0.21l-0.91-1.1l-0.09-0.19l-0.23-0.86l-0.12-0.16
          l-0.72-0.34l-1.5-0.21l-1.71-0.34l-3.32-1.42l-0.17-0.09l-0.13-0.13l-0.25-0.29l-0.06-0.21l-0.04-0.36l-0.29-0.26l-0.16-0.09
          l-0.19-0.08l-0.25-0.02l-0.89-0.08l-0.26-0.02l-1.84,0.4l-0.4,0.16l-0.17,0.08l-0.15,0.12l-0.29,0.25l-0.09,0.17l-0.38,0.44
          l-0.29,0.25l-0.34,0.19l-1.91,0.61h-0.23l-0.36-0.19l-0.36-0.17l-0.21-0.08l-1.8,0.3l-1.94,0.04l-1.21-0.08l-0.59,0.49l-1.07,1.08
          l-0.12,0.26l0.02,0.34l0.04,0.23l-0.09,0.57l-0.05,0.21l-2.45,3.33l-1.25,1.31l-0.76,0.72l-0.49,0.32l-2.73,0.83l-4.11,1.46
          l-2.62-1.78l-1.25-1.29l-0.87-0.25l-0.17-0.08l-0.13-0.13l-0.49-0.57l-0.38-0.93l0.19-0.4l0.13-0.16l0.4-0.16l-0.04-0.16
          l-0.25-0.25l-0.61-0.51l-1.76-1.46l-0.4-0.29l-1.48,0.7l-0.17,0.08l-0.42-0.06l-0.23-0.08l-1.31-0.76l-0.32-0.32l-0.19-0.4
          l-0.05-0.17l-2.42-1.55l-0.86-0.51l-0.4-0.12l-0.23-0.02l-0.43-0.09l-0.38-0.16l-0.29-0.25l-0.12-0.16l-0.12-0.17l-0.12-0.44
          l-0.12-0.68l0.05-0.99l-0.02-0.62l-0.3-1.82l-0.17-0.65l-0.08-0.19l-0.09-0.17l-0.12-0.15l-0.17-0.36l-0.08-0.21l-0.04-0.23
          l-0.02-0.25l-0.23-3.28l0.02-0.89l0.12-0.49l0.08-0.19l0.65-0.93l0.23-0.3l0.42-0.4l0.62-0.46l0.12-0.13l0.25-0.3l0.42-2.11
          l-0.02-1.71l-0.04-0.53l-1.12-0.02l-0.08,0.19l-0.13,0.26l-0.12,0.16l-0.13,0.15l-0.17,0.12l-0.29,0.13l-0.21,0.02l-1.86-0.05
          l-8.21-0.3l-9.84-0.38l-0.82-0.04l-9.01-0.36l-1.94-0.08l-1.03-0.05l-0.46-5.25v-0.26l-0.82-9.42l-4.04-4.52l-2.5-2.75l3.57-0.09
          l1.76,0.04l0.65-0.02l-0.02-1.08l-0.08-2.35l-0.23-3.94l-0.09-1.59l-1.25-2.67l-1.54-3.03l-0.4-0.53l-0.08-0.21l-0.57-2.11
          l-0.02-0.13l0.04-0.34l0.25-0.82l0.05-0.19l0.12-0.16l0.49-0.3l0.25-0.29l0.36-0.57l0.3-0.76l0.02-0.12l-1.65-3.37l-0.23-0.16
          l-1.91-0.59l-1.76-0.57l-1.31-1.08L3833.82,1209.13L3833.82,1209.13z"/>
      </g>
    );
  } else if (props.state === 'AC') {
    stateSelected = (
      <g id="AC" transform="translate(-3.5336616e-4,1.6815118e-4)">
        <g transform="translate(-3550.8686,-814.85365)">
          <path id="path1243-7" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M3616.27,1139.58l-0.79-0.02l0.34-0.95l0.33-1.16
            l0.01-0.09l-0.01-0.12l-0.26-0.91l-0.15-0.38l-0.08-0.17l-0.05-0.08l-0.23-0.17l-0.55-0.25l-0.21-0.05l-0.29-0.13l-0.25-0.16
            l-0.05-0.08l-0.16-0.23l0.02-0.25l0.02-0.12l0.12-0.42l0.08-0.19l0.05-0.21l0.05-0.47l-0.02-0.12l-0.85-2.24l-0.16-0.12
            l-1.16-0.68l-0.21-0.08l-0.47-0.13l-3.91-0.72l-2.35-0.63l-2.11-0.02l-0.4,0.02l-2.25-0.02l-4.15-0.05h-0.13l0.12-0.25l0.17-0.36
            l0.12-0.17l0.13-0.13l0.38-0.29l0.74-0.59l1.82-1.76l1.08-1.48l0.21-0.46l0.09-0.44l0.04-0.25v-0.26l-0.05-0.46l-0.17-0.99
            l-0.08-0.19l-0.61-0.85l-0.13-0.13l-0.17-0.12l-0.76-0.32l-0.08-0.05l-0.13-0.13l-0.05-0.08l-1.08-1.67l-0.21-0.32l-0.17-0.49
            l-0.02-0.12l-0.19-0.47l-0.21-0.32l-0.12-0.16l-0.15-0.12l-0.25-0.16l-0.47-0.19l-0.42-0.12l-0.12-0.02l-0.25,0.02l-0.33-0.09
            l-0.16-0.12l-1.17-1.21l-0.09-0.17l-1.08-2.81l-1.41-0.99l-0.93-0.66l-0.12-0.16l-0.04-0.09l-0.04-0.21l-0.08-0.7l-0.29-1.03
            l-0.68-2.29l-0.12-0.29l-0.89-1.73l-0.12-0.16l-0.08-0.05l-0.95-0.51l-0.19-0.08l-0.53-0.16l-0.29-0.12l-0.08-0.05l-0.08-0.08
            l-0.13-0.25l-0.29-1.16l0.04-0.23l0.05-0.08l0.09-0.04l0.12,0.02l0.55,0.25l0.12,0.02l0.12-0.02l0.09-0.04l0.08-0.05l0.13-0.13
            l0.17-0.23l0.15-0.25l0.02-0.12l0.02-0.12l0.04-0.51l-0.04-0.23l-0.05-0.21l-0.12-0.16l-0.13-0.13l-0.08-0.05l-0.19-0.08
            l-0.53-0.16l-1.2-0.42l-0.29-0.13l-0.23-0.17l-0.05-0.08l-0.12-0.15l-0.09-0.29l-0.16-0.25l-0.12-0.16l-1.21-1.2l-0.79-0.66
            l-0.42-0.25l-0.15-0.12l-0.05-0.05l-0.04-0.09l-0.02-0.12l0.02-0.12l0.05-0.08l0.08-0.05l0.12-0.02l0.19,0.04l0.13,0.05l0.12,0.02
            l0.12-0.02l0.17-0.09l0.08-0.05l0.49-1.2l0.02-0.12v-0.13l-0.02-0.12l-0.08-0.19l0.04-1.42l3.47-0.44l0.12-0.01l0.12-0.02
            l0.29-0.12l0.16-0.12l0.19-0.21l0.04-0.09l0.04-0.23l-0.38-1.38l-1.03-1.61l-0.05-0.05l-0.34-0.25l2.33,1l4.69,1.97l5.7,2.32
            l2.46,0.96l2.75,1.12l3.85,1.69l0.29,0.09l1.99,0.47l2.12,0.51l6.35,1.52l2.25,0.53l6.11,1.44l5.31,1.25l1.59,0.38l2.12,0.51
            l0.93,0.23l1.55,0.38l1.46,0.38l11.68,3.02l0.21,0.09l1.73,0.93l5.82,3.13l3.19,1.71l1.86,1l0.53,0.29l5.7,3.03l6.64,3.53
            l0.53,0.29l6.16,3.26l1.73,0.78l5.14,2.25l2.41,1.04l0.26,0.12l4.02,1.73l1.74,0.72l1.86,0.76l3.62,1.5l8.11,3.37l1.08,0.46
            l0.93,0.4l0.79,0.34l1.21,0.51l1.48,0.61l0.4,0.16l0,0l0.04,0.02l0.79,0.32l2.67,1.08l0.12,0.09v0.08l-0.09,0.3l-0.05,0.08
            l-0.16,0.12l-0.09,0.04l-0.19,0.08l-0.38,0.16l-0.12,0.02l-0.68,0.25l-0.53,0.26l-2.42,1.82l-0.21,0.19l-0.47,0.47l-0.06,0.08
            l-0.43,0.49l-0.4,0.4l-1.44,1.37l-0.51,0.42l-0.15,0.12l-1.78,1l-0.23-0.02l-0.21-0.05l-0.4-0.16l-0.21-0.05l-1.34,0.12
            l-0.25,0.02l-0.21,0.05l-0.05,0.08l-0.02,0.12l0.05,0.21l0.05,0.34l-0.04,0.09l-0.16,0.12l-2.21,1.37l-1.13,0.59l-0.38,0.15
            l-1.03,0.44l-0.47,0.46l-0.08,0.08l-1.21,1.67l-0.53,1.42l-0.68,0.36l-0.12-0.02l-0.79-0.53l-0.57-0.36l-0.19-0.08l-0.34-0.08
            l-0.38-0.02l-2.73,0.02l-0.3,0.09l-0.66,0.26l-0.4,0.26l-0.38,0.29l-0.3,0.38l-0.04,0.09l-0.02,0.38l-0.02,0.12l-0.08,0.19
            l-1.97,2.81l-0.89,1.24l-0.08,0.05l-0.17,0.12l-0.46,0.21l-0.47,0.19l-0.12,0.02l-0.25-0.02l-0.87,0.23l-2.18,0.7l-0.15,0.12
            l-0.12,0.16l-0.4,0.4l-0.33,0.21l-0.26,0.12l-0.72,0.19l-0.65,0.16l-0.87,0.19l-1.08,0.12l-0.12-0.02l-0.21-0.06l-0.08-0.05
            l-0.09-0.17l-1.24-2.07l-4.32-0.93l-2.79-0.3l-3.61-0.25l-0.61,0.17l-0.12,0.02l-0.68,0.05l-1.03,0.02l-0.51-0.04h-0.36
            l-1.52,0.06l-0.44,0.09l-0.79,0.13l-0.12-0.04l-0.08-0.05l-0.05-0.08l-0.47-0.47l-0.25-0.16l-0.12-0.02l-2.6-0.16l-0.12,0.02
            l-0.3,0.08l-0.21,0.05l-0.26,0.13l-0.59,0.34l-0.99,0.61l-1.95,0.96l-0.4,0.16l-0.87,0.26l-0.21,0.05l-1.84,0.29l-0.46-0.05
            l-0.95-0.4l-0.47-0.21l-0.08-0.05l-0.16-0.12l-0.19-0.21l-0.12-0.17l-0.08-0.17l-0.23-0.3l-0.47-0.47l-0.16-0.12l-0.32-0.21
            l-0.25-0.13l-0.93,0.74l-0.95,0.72l-0.02-0.65l0.02-3.7l0.01-3.29l0.02-5.14v-2.9v-2.24l0.02-2.29v-1l0.02-1.16l0.09-0.36
            l0.13-0.27l0.12-0.15l0.26-0.26l0.26-2.62l-0.19-0.21l-0.05-0.08l-0.08-0.19l-0.19-0.83l0.59-0.7l0.61-0.72l0.59-1.38l-0.89,0.09
            l-0.25,0.02l-0.19,0.08l-0.15,0.12l-0.96,0.89l-2.58,2.18l-1.24,0.76l-0.74,0.47l-1.61,1.71l-0.12,0.16l-0.13,0.29l-0.12,0.29
            l-0.05,0.08l-0.08,0.08l-0.21,0.17l-0.17,0.12l-0.19,0.08l-2.01,0.63l-0.19,0.08l-0.08,0.05l-0.21,0.19l-0.09,0.17l-0.09,0.29
            l-0.21,0.46l-0.16,0.23l-0.08,0.05l-2.08,1.1l-0.09,0.04l-1.31,0.16h-1.74h-3.49h-7.81L3616.27,1139.58L3616.27,1139.58z"/>
        </g>
      </g>
    );
  } else if (props.state === 'TO') {
    stateSelected = (
      <g id="TO" transform="translate(-3550.8686,-814.85365)">
        <path id="path496" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4106.12,1193.68l-2.94,0.63l-2.12,1.61l-3.13,1.33
          l-0.91,0.13l-0.96-0.12l-5.52,1.2l-3.09,2.49l-2.16,1.08l-0.55-0.29l-0.64-1.06l-1.21-2.07l-0.66-0.04l-0.4,1.91l-0.23,2.01
          l-0.83,0.09l-4.09-0.82l-3.83-1.74l0.68-1.88l-0.26-0.89h-1.24l-4.3,0.63l-2.45-0.38l-0.91-0.04l-0.68,0.65l-0.38,1.2l-0.91,0.91
          l-1.38-0.06l-0.76-3.71l-1.34-4.44l-1.27-2.25l-1.41-1.57l-1.44,1.16l-1.78,2.86l-1.57,5.19l-0.42,1.91l-0.76,0.26l-4.97-1.71
          l-3.77-2.04l-1.91-0.26l-2.88-0.21l-2.67-1.24l-2.94-0.47l0.65-2.81l1.48-2.25l0.04-2.04l-0.55,0.23l-0.26,0.25l-2.07,2.37
          l-0.82,1.48l-0.13,0.49l-0.08,0.68l-0.04,1.06l-0.42,0.53l-0.96-0.02l-0.46-0.21l-0.7-0.38l-0.05-0.04l-0.26-0.79l-0.91-3.19
          l0.42-1.42l0.49-1.69v-0.09l-0.01-0.25l-0.12-0.99l-0.05-0.46l-0.12-0.89l-0.16-0.99l-0.08-0.19l-0.06-0.08l-0.05-0.08l-0.12-0.04
          h-0.12l-0.09-0.04l-0.15-0.25l-0.08-0.19l-0.02-0.12l-0.04-1.54l0.04-0.23l0.04-0.23l0.55-3.47l0.02-0.32v-0.12l-0.13-0.13
          l-0.09-0.04l-0.12-0.04l-0.36-0.79l-0.68-1.57l0.04-0.55l0.02-0.12l0.25-0.29l0.47-0.46l0.17-0.09l0.08-0.05l0.05-0.08l0.05-0.21
          l0.09-0.72l0.02-0.12v-0.12l-0.02-0.12l-0.05-0.08l-0.19-0.21l-0.44-0.23l-0.36-0.17l-0.16-0.12l-0.34-0.46l-0.12-0.16l-0.05-0.08
          l-0.08-0.19l0.02-0.74l0.02-0.13l0.29-1.2l0.04-0.09l0.26-0.82l0.3-0.91l0.7-1.78l0.7-2.35l-0.02-1.86l0.05-1.69l0.23-1.95
          l0.43-1.63l0.68-2.07l0.08-0.06l0.4-0.76l0.53-1.54l0.55-1.9l0.25-0.16l0.22-0.16l0.19-0.21l0.23-0.44l0.04-0.09l0.15-0.4
          l0.34-1.42l0.02-0.38l-0.08-0.3l-0.04-0.46l0.04-0.61l0.21-0.59l0.06-0.08l0.47-0.59l0.08-0.05l0.21-0.05l0.15-0.13l0.12-0.16
          l0.15-0.25l0.16-0.25l0.19-0.34l0.08-0.19l0.12-0.83l0.3-0.91l0.29-0.53l0.51-0.68l0.53-0.79l1.1-2.67l0.34-0.99l0.47-2.01
          l0.09-0.74l0.08-0.34l0.08-0.32l0.63-1.91l0.09-0.17l0.47-0.72l0.89-1.1l0.51-0.55l0.21-0.05l0.15-0.12l0.21-0.19l0.38-0.42
          l1.46-1.99l0.05-0.08l0.6-1.12l0.34-0.72l0.21-0.38l0.13-0.12l1.5-1l0.08-0.05l0.09-0.04l0.95-0.08l0.21-0.04l0.17-0.08l0.08-0.06
          l0.7-0.93l1.04-1.65l0.09-0.17l0.05-0.21l-0.05-0.46l0.01-0.13l0.02-0.09l0.17-0.36l2.08-3.94l0.21-0.34l0.09-0.04l0.12-0.02
          l0.29-0.12l0.55-0.38l0.15-0.25l2.07-6.09l0.04-0.12l0.49-2.39l0.3-2.35l0.02-0.25l-0.13-0.21l-0.13-0.05l-0.33-0.08l-0.12-0.02
          l-0.4-0.26l-1.16-1.08l-0.19-0.21l-0.61-0.7l-0.17-0.23l-0.61-0.96l-0.08-0.19l-0.09-0.42l-0.08-0.55l0.16-0.65l0.04-0.09
          l0.38-0.55l0.05-0.08l0.72-0.85l0.4-0.38l0.27-0.26l0.99-1.54l0.43-0.89l0.08-1.27l-0.15-1.99l-0.57-2.39l0.02-0.36l0.09-0.17
          l0.05-0.08l0.19-0.21l2.77-1.97l0.3-0.21l1.78-0.68l1.12-0.3l1.1-0.3l2.84-1.29l0.17-0.09l0.08-0.05l0.22-0.3l0.04-0.34l0.04-0.76
          l-0.02-0.12l-0.09-0.29l-0.09-0.16l-0.06-0.13l0.04-0.38l0.96-1.44l0.17-0.23l1.69-1.46l0.16-0.12l0.79-0.19l0.36,0.04l0.36,0.04
          l0.23-1.65l-0.38-1.31v-0.12l0.02-0.12l0.04-0.09l0.21-0.34l0.13-0.16l0.21-0.17l0.33-0.04l0.74-0.16l0.4-0.12l0.09-0.04l0.25-0.13
          l0.15-0.09l0.21-0.19l0.12-0.16l0.05-0.09l1.06-3.15l0.02-0.25l-0.04-0.09l-0.21-0.32l-0.13-0.13l-0.21-0.17l-0.29-0.25l-0.25-0.26
          l-0.13-0.26l-0.12-0.51l-0.09-0.53l-0.01-0.36l0.05-0.21l0.15-0.21l0.21-0.19l0.17-0.09l0.32-0.05l0.23-0.04l0.43,0.08l0.23-0.02
          l0.36-0.15l0.32-0.19l0.08-0.05l0.05-0.08l0.34-0.59l0.04-0.09l0.05-0.21l0.05-0.49l-0.02-0.12l-0.04-0.09l-0.05-0.08l-1.27-1.25
          l-0.09-0.04l-0.21-0.05l-0.12,0.02l-0.26-0.02l-0.36-0.04l-0.09-0.02l-0.25-0.16l-0.23-0.17l-0.13-0.13l-0.06-0.08l-0.57-0.99
          l-0.38-0.78l-0.17-0.21l-0.08-0.05l-0.21-0.05l-0.12-0.02l-1.8,0.05l-0.21,0.04l-0.19,0.08l-0.76,0.13l-0.12,0.02l-0.38-0.02
          l-0.12-0.02l-0.76-0.17l-0.21-0.05l-0.3-0.09l-1.44-0.64l0.19-0.26l0.23-0.29l0.12-0.13l0.23-0.17l3.03-2.16l0.09-0.04l1.48-0.47
          l0.21-0.04l0.55-0.09l0.25-0.02l0.4,0.02l0.49,0.05l0.08,0.02l0.4,0.25l0.36,0.3l0.49,0.3l1.42,0.72l0.17,0.08l0.4,0.15l0.21,0.05
          l1.42,0.08h0.19l0.34-0.02l0.12-0.02l0.08-0.05l0.13-0.13l0.12-0.16l0.19-0.21l0.08-0.05l0.19-0.08l0.12-0.02l0.66,0.09l0.86,0.17
          l0.12,0.02l0.29,0.12l0.09,0.04l0.09,0.05l0.19,0.21l0.12,0.16l0.23,0.42v0.23l-0.04,0.36l0.08,0.57l0.04,0.09l0.16,0.23l0.13,0.13
          l0.15,0.08l0.33,0.08l0.25,0.01l0.61-0.01l0.17-0.09l0.09-0.04h0.25l3.79,1.69l0.05,0.05l0.25,0.29l0.47,0.57l0.29,0.38l0.09,0.17
          l0.17,0.47l0.17,1l-0.02,0.74l-0.02,0.12l0.22,1.84l0.19,0.34l0.57,1.44l0.02,0.12l-0.12,1.57l-0.15,0.61l-0.04,0.3v0.53l0.04,1.5
          l0.21,1.2l0.08,0.19l0.09,0.16l0.38,0.4l0.12,0.16l0.05,0.08l0.12,0.26l0.05,0.21l0.02,0.12l-0.02,0.47l-0.66,2.42l-0.85,2.79
          l-0.08,0.17l-0.53,2.04l-0.02,0.12l-0.02,0.12l-0.34,2.67v0.13l0.08,0.44l0.23,0.55l0.08,0.3l-0.34,1.8l-0.17,0.36l-1.95,2.12
          l-1.31,0.47l-0.23,0.02l-0.6,0.3l-0.19,0.34l-0.09,0.3v0.12l0.08,0.19l0.09,0.17l2.24,1.37l0.36-0.3l0.21-0.05l0.21-0.02l1.06,0.17
          l0.36,0.17l0.25,0.29l0.13,0.27l0.09,0.55l0.01,0.12l-0.01,0.25l-0.02,0.12l-0.08,0.16l-0.19,0.21l-0.25,0.15l-0.25,0.87l0.89,1.69
          l0.08,0.08l0.08,0.05l0.21-0.02l0.09-0.04l0.05-0.08l0.08-0.04l0.12-0.02l0.47,0.02l0.09,0.04l1.52,1.94l0.89,1.37l1.21,1.74
          l1,0.95l0.21,0.19l1.91,2.5l0.76,1.08l1.13-0.61l0.74-0.7l0.08-0.05l0.66-0.29l0.46-0.05l1.33-0.17l0.26-0.12l0.05-0.05l0.12-0.16
          l0.16-0.12l0.21-0.05l2.45-0.42l0.12-0.02l0.12,0.02l0.23,0.05l0.09,0.04l1.73,1.2l0.05,0.08l0.08,0.19l0.04,0.21l0.02,0.51v1.37
          l-0.29,1.94l-0.49,2.01l0.47,1.65l-3.96,0.4l-0.34,0.05l-0.66,0.25l-0.72,0.32l-0.08,0.05l-0.19,0.17l-0.42,0.38l-0.17,0.23
          l-0.05,0.09l-0.57,1.17l-0.15,0.38l-0.13,0.4l-0.76,2.84l0.05,0.34l0.04,0.09l0.16,0.12l0.13,0.13l0.04,0.09l0.02,0.12l0.04,0.65
          l-0.02,0.12l-0.09,0.17l-0.29,0.25l-0.29,0.25l-0.08,0.05l-1.21,1.03l-1.2,1.2l-0.12,0.16l-0.05,0.09l-0.21,0.59l-0.02,0.26
          l0.05,0.21l0.09,0.16l0.08,0.05l0.29,0.12l0.44,0.12l0.25,0.04l0.26,0.02l0.7-0.08l0.26,0.02l0.25,0.04l0.25,0.16l0.57,0.36
          l0.38,0.29l0.15,0.21l0.46,0.72l0.09,0.16l0.08,0.19l0.21,0.7l0.02,0.12l0.05,0.32v0.12l-0.05,0.61l-0.02,0.4l0.02,0.36l0.09,0.29
          l0.26,0.53l0.09,0.16l0.17,0.23l0.12,0.16l0.13,0.13l0.57,0.36l1.38,0.76l0.55,0.25l0.42,0.13l0.49,0.17l0.7,0.25l0.17,0.08
          l0.33,0.21l0.08,0.08l0.08,0.17l0.05,0.21l-0.01,0.25l-0.04,0.12l-0.04,0.09l-1.95,2.01l0.12,1.99h0.12l2.49,1.9l0.13,0.13
          l0.44,0.87l0.05,0.21v0.49l-0.02,0.25l0.68,1.57l1.65,2.42l0.23,0.17l0.21,0.08l0.61-0.04l0.55-0.09l0.62-0.17l0.09-0.04l0.12-0.02
          l0.51,0.05l0.21,0.05l1.1,0.32l0.59,0.21l0.33,0.23l0.19,0.21l0.25,0.4l0.29,0.38l0.13,0.13l0.21,0.17l0.7,0.36l0.29,0.13
          l0.34,0.09l1.76,0.23l1.31,0.04l0.38,0.04l-0.26,0.12l-0.08,0.05l-0.13,0.16l-0.25,0.42l-0.01,0.25v0.25l-0.02,0.12l-0.06,0.23
          l-0.12,0.29l-0.13,0.13l-0.49,0.44l-0.23,0.15l-0.59,0.32l-1.8,0.95l-1.65,0.79l-0.51,0.29l-2.12,1.54l-1.67,1.5l-0.26,0.25
          l-0.05,0.08l-0.05,0.21v0.12l0.04,0.09l0.79,0.74l0.72,0.99l0.08,0.19l0.02,0.12l-0.02,0.12l-0.05,0.09l-0.05,0.05l-0.26,0.13
          l-0.21,0.05l-0.53,0.12l-0.61,0.17l-0.59,0.32l-0.22,0.17l-0.21,0.19l-0.12,0.13l-0.32,0.62l-0.61,1.31l-0.12,0.3l-0.17,0.49
          l-0.09,0.3l-0.21,0.59l-0.17,0.38l-0.25,0.29l-0.17,0.09l-0.17,0.08l-0.38,0.16l-0.17,0.09l-0.22,0.3l-0.3,0.61l-0.13,0.29
          l-0.17,0.51l-0.02,0.25l0.02,0.25l0.02,0.12l0.12,0.29l0.09,0.17l0.12,0.16l1.69,1.88l0.26,0.25l0.16,0.12l0.19,0.08l1.21,0.16
          l1.21,0.16l0.49,0.05l0.33,0.09l1.04,0.3l0.59,0.21l0.57,0.25l0.19,0.08l0.43,0.23l0.4,0.29l0.16,0.12l0.05,0.08l0.04,0.09
          l0.02,0.23l-0.02,0.38l-0.02,0.12l-0.04,0.09l-0.13,0.13l-0.47,0.3l-0.17,0.09l-0.59,0.21l-0.4,0.12l-0.3,0.09l-0.46,0.19
          l-0.55,0.25l-0.15,0.09l-0.16,0.13l-0.12,0.15l-0.05,0.08l-0.04,0.09l-0.08,0.32l-0.02,0.38l0.05,0.46l0.05,0.21l0.08,0.19
          l0.13,0.13l0.08,0.04h0.13l0.32-0.08l0.3-0.09l0.32-0.06l0.25-0.01h0.12l0.25,0.04l0.22,0.05l0.47,0.21l0.25,0.16l0.32,0.23
          l0.19,0.21l0.16,0.25l0.3,0.47l0.02,0.12l-0.04,0.12l-0.08,0.05l-1.03,0.68l-0.17,0.09l-0.4,0.13l-0.42,0.12l-0.38,0.16l-0.25,0.16
          l-2.16,1.8l-0.13,0.13l-0.12,0.3l-0.08,0.74l-0.05,2.03l-0.02,1.48l0.19,0.96l0.05,0.21l0.09,0.17l0.21,0.32l0.13,0.13l0.23,0.17
          l0.63,0.3l0.12,0.04l0.47,0.08l0.66,0.15l0.19,0.08l0.34,0.19l0.08,0.05l0.12,0.16l0.08,0.19l0.04,0.36l0.04,1.1l-0.04,0.36
          l-0.15,0.26l-0.08,0.05l-0.08,0.06l-0.34,0.46l-0.26,0.4l-0.16,0.25l-0.3,0.49l-0.09,0.17l-0.16,0.36l-0.08,0.32v0.13l0.04,0.47
          l0.08,0.19l0,0L4106.12,1193.68L4106.12,1193.68z"/>
      </g>
    );
  } else if (props.state === 'GO') {
    stateSelected = (
      <path id="GO" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M538.13,440.8l-0.23,0.61l-0.02,0.12l0.13,0.74l0.12,0.51
        l0.16,0.32l0.01,0.12l-0.05,0.21l-0.43,1.08l-0.36,0.7l-0.53,0.83l-0.12,0.16l-0.36,0.3l-0.25,0.16l-0.13,0.13l-0.19,0.79
        l-0.19,0.83l0.21,0.44l0.04,0.08l0.12,0.16l0.57,0.64l0.05,0.06l0.16,0.13l0.38,0.17l0.36,0.08l0.21,0.08l1.74,1.21l1.88,4.99
        l0.13,0.38l0.08,0.43l-0.04,0.25l-0.08,0.19l-2.77,3.03l-3.92,3.49l-0.05,0.08l-0.15,0.46l-0.36,1.84l0.02,0.32l0.89,0.99l0.25,0.17
        l0.09,0.05h0.12l0.09-0.04l0.08-0.05l0.17-0.23l0.16-0.12l0.09-0.05l0.4-0.13l0.13,0.02l1.16,0.32l0.21,0.08l0.38,0.19l0.25,0.17
        l0.15,0.13l0.17,0.23l0.08,0.17l0.08,0.19l0.13,0.38l0.01,0.23l0.02,0.47l-0.05,0.79l-0.04-0.04l-0.19,0.09l-0.08,0.06l-0.22,0.19
        l-0.42,0.66l-0.16,0.26l-0.34,0.89l-0.3,0.87l-0.02,0.12l0.02,0.12l0.16,0.13l0.05,0.08l0.34,0.89l0.91,2.45l0.09,0.3l0.04,0.26
        l-3.77,2.41l-0.49,0.21l-0.46,0.12l-0.02-0.12l-0.08-0.06l-0.13-0.02l-0.12,0.02l-0.29,0.12l-0.3,0.23l-0.61,0.76l-0.29,0.86
        l0.04,0.09l-0.25,0.3l-0.16,0.12l-1.07,0.74l-1.84,0.99l-2.73,1.31l-0.26,0.12l-0.05-0.19l-0.19-0.21l-0.61-0.49l-5.12-2.18
        l-0.21-0.02l-0.08,0.05l-0.53,0.42l-0.05,0.08l-0.02,0.12l-0.68,0.02l-2.12,0.02l-1.13-0.13l-0.61-0.17l-0.09-0.06l-2.25,0.04
        l-1.07,0.09l-1.33,0.05l-0.68-0.19l-0.08-0.05l-1.55-0.76l-0.12-0.04h-0.12l-4.95,3.09l-3.3,3.26l-0.23,0.17l-0.09,0.04l-0.23-0.02
        l-0.21-0.08l-1.5-1.61l-1.86-0.36l-3.71,1.86l-0.09,0.04l-0.25,0.02l-2.62-0.36l-0.79-0.23l-0.32-0.12l-0.36-0.08h-0.25l-0.19,0.08
        l-2.86,1.08l-1.99,0.34l-0.51,0.16l-0.19,0.08l-0.17,0.12l-0.06,0.08l-1.54,2.32l-1.48,2.86l0.08,0.96v0.12l-0.02,0.13l-0.04,0.12
        l-0.21,0.34l-0.57,0.81l-0.21,0.21l-0.08,0.05l-0.47,0.19l-0.43,0.05l-0.4-0.01l-0.65-0.17l-0.46,0.12l-0.17,0.09l-1.06,0.79
        l-1.74,2.12l-0.25,0.3l-0.76,1.93l-0.02,0.12l0.02,0.12l0.05,0.08l0.08,0.08l0.53,0.42l0.08,0.08l0.09,0.17l-0.02,0.25l-0.12,0.16
        l-0.16,0.12l-0.17,0.08l-0.12,0.02l-0.12-0.02l-0.27-0.13l-0.59-0.42l-0.16-0.32l-0.12-0.15l-0.13-0.16l-3.07-2.45l-0.17-0.09
        l-0.21-0.09l-0.25-0.05l-1.51-0.25l-0.74-0.04l-0.49-0.05l-0.47-0.12l-0.17-0.12l-1.12-1.06l-0.53-0.55l-0.17-0.12l-0.21-0.08
        l-2.07-0.42l-0.21-0.02l-0.38,0.02l-0.23,0.02l-0.42,0.01l-0.7-0.08l-0.82-0.23l-0.53-0.19l-2.63-1.1L452.1,497l-0.17-0.34
        l-0.23-0.3l-0.13-0.16l-0.16-0.13l-0.17-0.12l-0.76-0.36l-0.23-0.05l-2.56-1.24l-0.12-0.16l-0.29-0.26l-0.47-0.36l-0.74-0.4
        l-0.38-0.19l-0.63-0.25l-0.66-0.21l-0.25-0.02l-0.47,0.04l-2.6-1.42l-0.61-0.49l-0.17-0.12l-0.23-0.05l-0.25-0.02l-0.62,0.08
        l-0.82,0.13l-1.41,0.12l-1.84-0.17l-1.03-0.17l-1.41-0.32l-0.38-0.13l-0.16-0.38l0.08-0.38l0.08-0.21l0.16-0.38l0.26-0.57l0.42-0.79
        l0.29-0.4l0.51-0.61l0.49-0.59l0.29-0.38l0.08-0.17l0.02-0.21l-0.08-0.19l-0.19-0.13l-1.52-0.65l-0.42-0.17l-0.4-0.12l-0.21-0.01
        l-0.17,0.01l-0.17,0.06l-0.61,0.46l-0.4,0.25l-0.19,0.05l-0.23,0.02l-0.21-0.05l-0.19-0.12l-0.17-0.17l-0.26-0.4l-0.23-0.74
        l-0.05-0.57l0.08-0.82l0.05-0.23l0.12-0.78l0.09-1.2l0.04-0.95l-0.05-0.36l-0.23-0.76l-0.06-0.3l-0.34-0.68l-0.32-0.59l-0.17-0.23
        l-0.25-0.53l-0.04-0.09l-0.04-0.32l-0.13-1.08l-0.02-0.23v-0.36l0.12-0.47l-0.16-1.67l-0.7-1.25l-0.61-0.83l-0.12-0.16l-0.13-0.13
        l-0.16-0.12l-0.05-0.08l-0.06-0.19l-0.08-0.4l-0.02-0.12l0.02-0.25l0.46-3.7l0.02-0.12l0.04-0.09l0.17-0.4l0.09-0.19l1.44-2.07
        l0.99-1.21l0.05-0.08l0.29-0.4l0.05-0.23l0.06-0.38l0.12-1.57l0.23-1.25l0.08-0.21l0.19-0.21l0.53-0.55l0.19-0.08l1.12-0.43
        l1.12-0.44l0.26-0.13l0.49-0.42l0.21-0.19l1.16-1l0.26-0.29l0.17-0.25l1.42-2.16l0.08-0.21l0.08-0.34l0.05-0.49l0.02-0.25l0.05-0.38
        l0.17-0.7l0.16-0.4l0.36-0.62l0.19-0.32l0.26-0.29l0.59-0.49l0.26-0.13l0.34-0.17l1-1.69l-0.08-0.42l-0.02-0.21l0.02-0.49l0.13-0.59
        l0.09-0.19l0.36-0.3l0.17-0.09l0.17-0.08l0.51-0.13l0.74-0.3l0.16-0.12l0.05-0.08l0.85-1.5l0.72-1.42l0.04-0.08l0.08-0.05l0.66-0.05
        l0.25-0.02l2.15-0.04l0.25,0.02l1.2-0.34l1.9-0.61l0.25-0.16l0.26-0.26l0.82-1.33l0.09-0.17l1.52-3.45l0.76-0.96l0.12-0.16
        l0.04-0.09l0.05-0.47l0.09-0.83l0.23-1.41l0.38-2.07l0.4-0.79l0.12-0.17l2.29-2.22l1.97-1.2l0.09-0.04l1.25-0.29l0.76,0.49
        l0.09,0.16l0.16,0.12l0.12,0.04l0.25,0.02l0.23-0.04l0.25-0.12l1.12-0.72l1.67-1.65l0.04-0.12l1.03-3.44l0.91-2.01l0.49-1.21
        l0.04-0.09l0.02-0.12l-0.08-1l-0.25-0.4l-0.04-0.09l-0.27-0.76l0.16-0.91l0.04-0.21l0.51-2.15l0.86-1.65l1.08-2.01l0.02-4.24
        l-0.23-1.78l-0.02-0.12l0.13-0.13l0.38-0.16l0.12-0.02h0.13l0.12-0.01l0.3-0.25l0.21-0.19l0.17-0.23l0.42-1.2l0.83-1.95l1.17-1.63
        l1.2-2.03l0.13-0.26l0.36-0.99l0.08-0.32l-0.09-2.04l-0.02-0.23l-0.05-0.19l-0.05-0.62l0.26-0.91l0.05-0.08l0.21-0.19l0.57-0.34
        l0.09-0.04l0.08-0.05l0.29-0.38l0.08-0.19l0.26-1.08l0.06-0.32l-0.02-0.25l-0.05-0.3l-0.04-0.05l0.42-0.53l0.04-1.07l0.08-0.68
        l0.13-0.49l0.82-1.48l2.07-2.37l0.26-0.25l0.55-0.23l-0.04,2.04l-1.48,2.25l-0.65,2.81l2.94,0.47l2.67,1.24l2.88,0.21l1.91,0.26
        l3.77,2.04l4.97,1.71l0.76-0.26l0.42-1.91l1.57-5.19l1.78-2.86l1.44-1.16l1.41,1.57l1.27,2.25l1.34,4.44l0.76,3.72l1.38,0.05
        l0.91-0.91l0.38-1.2l0.68-0.65l0.91,0.04l2.45,0.38l4.3-0.62h1.24l0.26,0.89l-0.68,1.88l3.83,1.74l4.1,0.82l0.83-0.09l0.23-2.01
        l0.4-1.91l0.66,0.04l1.21,2.07l0.64,1.06l0.55,0.29l2.16-1.08l3.09-2.49l5.52-1.2l0.96,0.12l0.91-0.13l3.13-1.33l2.12-1.61
        l2.94-0.63l3.61-1.06l1.08,1.16l0.34,0.19l1.21,0.79l0.59,0.61l0.09,0.17l0.04,0.09l0.04,0.09l0.02,0.12l-0.02,0.12l-0.04,0.09
        l-0.12,0.16l-0.68,0.38l-0.89,0.57l-0.09,0.17l-0.08,0.17l-0.09,0.3l-0.04,0.22l-0.34,2.15v0.25l0.05,0.21l0.13,0.13l0.43,0.25
        l1,0.34l0.34,0.05l0.05,0.08l0.08,0.19l0.01,0.09l0.02,0.12l-0.02,0.49l-0.08,0.34l-0.06,0.21l-0.15,0.26l-0.21,0.19l-0.63,0.46
        l-0.32,0.19l-0.29,0.12l-0.51,0.26l-0.13,0.16l-0.09,0.17l-1.3,4.31l-0.02,0.12l-0.04,0.65l-0.02,1.16l0.08,0.82l0.12,1.02
        l0.12,0.66l0.26,1.12l0.17,0.59l0.19,0.47l1.8,3.54l0.09,0.16l0.13,0.13l1.55,1.04l2.12,1.74l0.05,0.05l0.09,0.16l0.02,0.12
        l-0.02,0.26l-0.04,0.25l-0.09,0.17l-0.16,0.25l-0.55,1.04l-0.09,0.17l-0.04,0.12l-0.08,0.47l0.04,0.34l0.21,0.7l0.04,0.09l0.21,0.32
        l0.32,0.68l0.04,0.09l0.02,0.12l0.17,0.74l0.04,0.21l0.04,0.34l-0.02,0.22l-0.19,0.62l-0.04,0.12l-0.12,0.16l-0.13,0.13l-0.29,0.25
        l-0.08,0.05l-0.16,0.12l-0.23,0.3l-0.82,1.2l-0.04,0.09l-0.04,0.16l-1,1.12l-0.17,0.09l-0.12,0.01l-3.79-0.34l-0.15-0.12l-0.08-0.19
        l-0.04-0.21l-0.08-0.3l-0.09-0.29l-0.08-0.19l-0.09-0.17l-0.05-0.08l-0.53-0.66l-0.34-0.3l-0.32-0.23l-0.25-0.15l-1.88-1.08
        l-0.09-0.04l-0.12,0.01l-0.16,0.12l-0.32,0.34l-0.49,0.57l-0.22,0.3l-0.19,0.34l-0.02,0.25l0.13,1.74l0.34,0.86l0.04,0.09l0.21,1.63
        l-0.01,0.12l-0.55,0.49l-0.25,0.16l-0.19,0.08l-0.12,0.02h-0.13l-0.12-0.02l-0.49-0.19l-0.19-0.09l-0.89-0.46l-0.7-0.38l-0.42-0.13
        l-0.47-0.08h-0.13l-0.49,0.02l-0.34,0.05l-0.4,0.13l-0.19,0.08l-0.17,0.09l-0.13,0.13l-0.17,0.21l-0.09,0.17l-0.04,0.12l-0.79,3.22
        v0.13l0.05,0.08l0.09,0.04h0.13l0.3-0.09l0.22-0.02l0.12,0.02l0.19,0.21l0.06,0.08l0.36,0.78l0.08,0.17l0.02,0.23l-0.02,0.12
        l-0.04,0.09l-0.66,0.95l-0.25,0.29l-0.25,0.17l-0.38,0.29l-0.05,0.08l-0.05,0.23l0.12,2.04l0.05,0.21l0.04,0.09l0.09,0.17l0.05,0.05
        l0.22,0.17l0.09,0.04l0.47,0.08l0.17,0.08l0.05,0.08l0.04,0.09l0.05,0.21l0.64,4.62v0.13l-0.16,0.25l-0.08,0.08l-0.42,0.23
        l-0.96,0.34l-0.09,0.04l-0.83,0.21l-0.12,0.02l-2.11,0.46l-2.69,1.74l-0.09,0.04l-0.23,0.02l-1.33,0.09L538.13,440.8L538.13,440.8z"
        />
    );
  } else if (props.state === 'DF') {
    stateSelected = (
      <path id="DF" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M537.54,438.73l-0.08-0.19l-0.01-0.12l0.08-1.61l0.04-0.49
        l0.05-0.57l0.02-0.12l0.13-0.13l0.09-0.05l0.06-0.08l0.16-0.26l0.13-0.29l0.23-0.59l0.04-0.12l0.01-0.68l-0.12-1.88l-2.11-2.33
        L536,429.2l-0.82,0.02h-8.98l-4.07-0.02l-0.57,0.02l-0.46,4.41l-0.95,1.88l-0.15,0.12l-0.05,0.09l-0.02,0.13l0.02,0.12l0.55,3.61
        l0.04,0.09l0.05,0.08l0.29,0.02l2.18,0.01h12.04l3.02-0.01h0.26l0.21-0.04l-0.82-0.72L537.54,438.73L537.54,438.73z"/>
    );
  } else if (props.state === 'MS') {
    stateSelected = (
      <g id="MS" transform="translate(-3550.8686,-814.85365)">
        <path id="path1126" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4018.89,1331.98l-0.44,2.25l-0.08,0.21l-0.12,0.17
          l-0.13,0.16l-0.16,0.12l-0.09,0.04l-1.65,0.78l-0.4,0.13l-0.09,0.01h-0.13l-0.4-0.08l-0.12-0.04l-0.26-0.04l-0.34,0.04l-0.44,0.25
          l-0.4,0.29l-0.16,0.12l-0.38,0.3l-0.43,0.38l-0.21,0.21l-1.29,1.52l-1.04,1.48l-0.79,0.7l-0.19,0.09h-0.36l-0.12,0.02l-0.59,0.21
          l-0.12,0.16l-0.7,1.86l-0.09,0.78l-0.08,1.57l0.04,0.89l-0.09,0.23l-0.29,0.42l-0.12,0.15l-2.67,3.44l-0.05,0.08l-0.16,0.12
          l-0.29,0.13l-0.3,0.09l-0.36,0.17l-0.08,0.05l-0.13,0.16l-0.13,0.3l-0.02,0.09l0.05,0.78l0.17,0.38l0.13,0.38l0.06,0.43l0.01,0.22
          l-0.09,0.96l-0.3,0.57l-1.17,2.11l-0.57,0.7l-0.36,0.23l-0.32,0.04l-1.25,1.48l0.13,0.26l0.3,0.63l0.08,0.19l-0.21,1.2l-0.09,0.19
          l-0.08,0.08l-0.15,0.12l-0.17,0.12l-0.3,0.09l-0.38,0.17l-0.72,0.59l-0.05,0.05l-0.12,0.17l-0.12,0.3l-0.15,0.59l-0.04,0.26
          l-0.05,0.08l-0.13,0.16l-0.79,0.59l-1.08,1l-0.21,0.23l-0.05,0.09l-0.09,0.34l-0.21,0.51l-0.16,0.26l-0.91,1.46l-0.32,0.38
          l-0.83,0.83l-0.3,0.25l-0.3,0.23l-0.47,0.34l-0.25,0.15l-0.26,0.16l-0.74,0.34l-0.93,0.4l-2.96,1.69l-4.21,2.49l-1.52,1.27
          l-0.05,0.05l-0.12,0.16l-0.13,0.3l-0.62,1.25l-1.03,1.21l-0.46,0.25l-0.19,0.08l-0.47,0.21l-0.19,0.08l-1.02,0.32l-1.03,0.36
          l-0.59,0.21l-1.37,0.53l-1.16,0.46l-0.27,0.16l-0.83,0.53l-0.34,0.25l-0.12,0.15l-0.09,0.19l-0.09,0.19l-0.34,0.82l-0.53,1.37
          l0.01,0.46l0.02,0.23v0.47l-0.04,0.26l-1.2,2.94l-0.09,0.21l-0.38,0.62l-0.17,0.25l-0.26,0.29l-0.46,0.36l-1.94,1.08l-0.19,0.08
          l-0.44,0.09l-0.4-0.04l-0.42,0.26l-0.3,0.25l-0.08,0.08l-0.06,0.08l-0.09,0.21l-0.02,0.7l-0.53,2.32l-0.04,0.12l-0.44,1.31
          l-0.72,3.98l-0.04,0.26l-0.04,0.12l-0.21,0.53l-0.17,0.26l-0.15,0.13l-2.24,1.35l-0.17,0.09l-0.29,0.13l-0.09,0.04l-0.85-0.63
          l-2.25-1.97l-2.52-1.31l-1.55-0.79l-0.13-0.02l-0.12,0.02l-4.66,2.16l-0.08,0.05l-0.08,0.08l-0.23,0.34l-0.13,0.16l-0.08,0.08
          l-0.17,0.12l-0.29,0.13l-1.9,0.49l-2.25,0.42l-0.47,0.08h-0.27l-0.3-0.04l-2.6-0.46l-0.25-0.05l-0.23-0.08l-0.21-0.09l-0.38-0.21
          l-0.16-0.13l-0.13-0.16l-0.05-0.08l-0.06-0.15l-0.04-0.21l-0.02-0.34v-0.72l-0.13-1.44l-0.09-0.99l-0.12-0.74l-0.04-0.21
          l-0.12-0.26l-0.19-0.34l-0.12-0.17l-0.19-0.23l-0.15-0.13l-0.17-0.12l-0.19-0.12l-0.23-0.21l-0.17-0.21l-0.04-0.09l-0.29-2.63
          l0.02-0.13l0.04-0.12l0.59-1.37l-0.74-0.82l-0.05-0.08l-0.09-0.17l-0.01-0.09l-0.02-0.12v-0.12l0.08-0.36l-0.05-2.86l-0.66-0.99
          l-0.44-1.24l-0.72-2.99l-0.04-0.21l-0.05-0.55l0.02-0.25l0.05-0.21l0.09-0.21l0.12-0.17l0.08-0.08l0.16-0.29l0.21-0.38l0.09-0.21
          l0.02-0.25l-0.02-1.41l-0.04-0.21l-0.04-0.08l-0.05-0.08l-0.09-0.05l-0.21-0.08l-0.13-0.01l-0.34-0.12l-0.3-0.16l-0.34-0.23
          l-0.3-0.25l-0.13-0.16l-0.21-0.32l-0.13-0.27l-0.05-0.19l-0.02-0.12l-0.09-0.87l-0.02-0.46l-0.02-0.46l0.02-0.36l-0.04-0.21
          l-0.06-0.08l-1.99-2.03l-2.66-0.3l-1.04,0.09l-0.12,0.02l-0.38,0.15l-0.29,0.12l-0.12,0.02l-1.42-0.19l-0.13-0.04l-0.53-0.13
          l-0.12-0.04l-1.97-1.34l-0.38-0.32l-0.53-0.46l-0.05-0.08l-0.13-0.26l-0.04-0.21l-0.04-0.32l-0.42-0.89l-0.06-0.08l-0.13-0.02
          l-1.02,0.19l-0.61,0.17l-0.13,0.05l-0.25,0.17l-0.29,0.29l-0.13,0.16l-0.3,0.42l-0.01,0.12l-0.08,0.25l-0.19,0.25l-0.25,0.3
          l-1.16,1.2l-0.76-0.25l-0.06-0.08l-0.17-0.22l-0.05-0.08l-0.09-0.06l-0.13-0.04l-0.12,0.02l-0.96,0.51l-0.23,0.13l-0.74,0.32
          l-1.31,0.17l-2.12-0.79l-3.13-0.55l-0.85,0.08l-0.65,0.08l-0.22,0.05l-0.13,0.01l-1.55,0.08l-0.87-0.02l-4.13-0.66l-0.29-0.12
          l-0.08-0.05l-0.05-0.08l-0.42-0.91l-2.42-0.16l-0.46,0.3l-0.59,0.26l-0.09,0.04l-1.12-0.29l-0.23-0.05l-0.53-0.17l-0.09-0.04
          l-1.03-0.47l-0.04-0.59l0.02-0.51l0.08-0.32l0.36-0.86l0.53-3.81l0.26-2.08l-0.19-0.21l-0.09-0.17l-0.05-0.21l-0.3-2.18l0.02-0.12
          l0.4-0.93l0.32-0.63l0.7-0.93l0.46-0.64l0.09-0.17l0.68-6.69v-0.26l-0.79-2.54l-0.02-1.9l-0.09-0.17l-0.79-0.95l-0.08-0.08
          l-0.25-0.15l-0.12-0.02l-0.12,0.02l-0.17,0.09l-0.05,0.08l-0.25,0.55l-0.05,0.08l-0.08,0.05l-0.09,0.04l-0.12-0.02l-0.08-0.08
          l-0.05-0.19l-0.25-1.46l-0.04-0.23l-0.23-1.72l-0.02-0.12v-0.38l-0.95-1.8l-0.74-1.14l-0.76-1.2l-0.36-0.96l-0.05-1.16l0.93-0.36
          l3.87-2.21l0.17-0.09l0.08-0.05l0.86-0.86l-0.12-0.13l-0.02-0.01l-4.72-4.02l-0.34-0.36l0.64-1.21l2.41-4.87l2.01-4.1l0.65-1.31
          l0.65-1.3l0.21-0.4l1.37-0.08l-0.23-2.08l-0.05-0.4l-0.13-0.02l-0.85-0.08l0.04-0.13l2.71-8.28l1.21-3.68l0.16-0.4l0.13-0.25
          l0.53-0.7l-1.16-2.04l-2.62-4.78l-0.04-1.33l0.05-0.7l0.02-0.21l0.87,0.25l1.25,1.29l2.62,1.78l4.11-1.46l2.73-0.83l0.49-0.32
          l0.76-0.72l1.25-1.31l2.45-3.34l0.06-0.21l0.09-0.57l-0.04-0.23l-0.02-0.34l0.12-0.26l1.07-1.08l0.59-0.49l1.21,0.08l1.93-0.04
          l1.8-0.3l0.21,0.08l0.36,0.17l0.36,0.19h0.23l1.91-0.61l0.34-0.19l0.29-0.25l0.38-0.43l0.09-0.17l0.29-0.25l0.15-0.12l0.17-0.08
          l0.4-0.16l1.84-0.4l0.26,0.01l0.89,0.08l0.25,0.02l0.19,0.08l0.16,0.09l0.29,0.26l0.04,0.36l0.05,0.21l0.25,0.29l0.13,0.13
          l0.17,0.09l3.32,1.42l1.71,0.34l1.5,0.21l0.72,0.34l0.12,0.15l0.23,0.86l0.09,0.19l0.91,1.1l0.38,0.21l0.4,0.21l0.99,0.3l1.8,0.53
          l0.19,0.12l0.19,0.19l0.22,0.34l0.42,0.32l0.66,0.44l0.42,0.25l0.53,0.25l0.47,0.17h0.21l1.59-0.08l0.17-0.02l3.02-0.46l0.21-0.04
          l0.42-0.17l1.44-0.83l0.21-0.17l0.27-0.55l0.21-0.34l1.38-0.62l0.38-0.09l1.24-0.05h0.38l0.95,0.02l0.4,0.13l1.86,1.16l0.19,0.12
          l0.12,0.17l0.04,0.17l-0.04,0.19l0.02,0.38l0.12,0.53l0.06,0.19l0.17,0.17l0.19,0.04l0.63,0.05l0.4,0.02l0.17-0.09l0.36-0.21
          l0.25-0.17l0.21-0.09l1.06-0.38l0.65-0.16l0.46-0.01l0.21,0.01l0.44,0.09l0.19-0.02l0.21-0.12l0.34-0.32l3.16-3.24l0.79-0.95
          l0.82-1.1l0.12-0.19l0.13-0.17l1.24-0.96l0.3-0.13l0.36-0.05l0.96,0.04l-0.02,0.7l-0.05,0.4l-0.15,0.61l-0.19,0.59l-0.29,1.2
          l-0.38,2.25l-0.3,1.9l-0.15,0.17l-0.17,0.05l-0.79,0.13l-0.74,0.23l-0.19,0.09l-0.17,0.16l-0.17,0.17l-1.71,2.84l-0.21,0.36
          l-0.25,0.53l0.19,0.63l0.78-0.05l0.79,0.19l0.44,0.21l0.42,0.38l0.4,0.4l0.26,0.25l0.63,0.25l5.48,0.36l0.23-0.02l0.17-0.13
          l0.46-0.55l3.24-0.09l0.57,0.02l0.4,0.05l0.21,0.05l0.46,0.17l0.19,0.13l0.17,0.16l0.21,0.12l0.17,0.08h0.21l1.35-0.17l0.46-0.21
          l0.23,0.76l0.05,0.36l-0.04,0.95l-0.09,1.2l-0.12,0.78l-0.05,0.23l-0.08,0.82l0.05,0.57l0.23,0.74l0.26,0.4l0.17,0.17l0.19,0.12
          l0.21,0.05l0.23-0.02l0.19-0.05l0.4-0.25l0.61-0.46l0.17-0.05l0.17-0.02l0.21,0.02l0.4,0.12l0.42,0.17l1.52,0.65l0.19,0.13
          l0.08,0.19l-0.02,0.21l-0.08,0.17l-0.29,0.38l-0.49,0.59l-0.51,0.61l-0.29,0.4l-0.42,0.79l-0.26,0.57l-0.16,0.38l-0.08,0.21
          l-0.08,0.38l0.15,0.38l0.38,0.13l1.41,0.32l1.03,0.17l1.84,0.17l1.41-0.12l0.82-0.13l0.63-0.08l0.25,0.02l0.23,0.05l0.17,0.12
          l0.61,0.49l2.6,1.42l0.47-0.04l0.25,0.02l0.66,0.21l0.63,0.25l0.38,0.19l0.74,0.4l0.47,0.36l0.29,0.26l0.12,0.16l2.56,1.24
          l0.23,0.05l0.76,0.36l0.17,0.12l0.16,0.13l0.13,0.16l0.23,0.3l0.17,0.34l0.19,0.09l2.64,1.1l0.53,0.19l0.82,0.23l0.7,0.08
          l0.42-0.02l0.23-0.02l0.38-0.02l0.21,0.02l2.07,0.42l0.21,0.08l0.17,0.12l0.53,0.55l1.12,1.06l0.17,0.12l0.47,0.12l0.25,0.05
          l0.99,0.04l1.52,0.25l0.25,0.05l0.21,0.09l0.17,0.09l3.07,2.45l0.13,0.16l0.12,0.16l0.15,0.32l-0.19-0.04l-0.23,0.02l-0.17,0.12
          l-0.13,0.16l-0.04,0.09l-0.04,0.09l-1.12,4.61l-0.02,0.13v0.47l0.08,0.55l0.05,0.19l0.19,0.34l0.09,0.29l0.3,1.54v0.25l-0.02,0.13
          l-0.21,1l-0.25,1.29l-0.04,0.25l0.05,0.21l0.05,0.16L4018.89,1331.98L4018.89,1331.98z"/>
      </g>
    );
  } else if (props.state === 'PA') {
    stateSelected = (
      <g id="PA" transform="translate(-3550.8686,-814.85365)">
        <path id="path1169" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4036.1,1130.03l-0.47,2.01l-0.34,0.99l-1.1,2.67
          l-0.53,0.79l-1.71-0.08l-4.32-0.21l-0.32-0.04l-10.3-0.51l-8.63-0.46l-1.99-0.12l-6.64-0.36l-5.65-0.3l-7.97-0.46l-7.62-0.42
          l-4.32-0.25l-8.63-0.49l-8.76-0.53l-3.37-0.26l-0.76-0.05l-3.36-0.23l-3.07-0.21l-10.86-0.74l-10.18-0.72l-5.49-0.4l-4.41-0.32
          l-2.49-1.04l-0.19-0.13l-1.71-0.63l-0.42,0.02l-0.19,0.08l-0.17,0.12l-0.44,0.25l-0.21,0.05l-0.25,0.02l-0.19-0.02l-0.17-0.12
          l-0.13-0.21l-0.12-0.19l-0.19-0.55l-0.01-0.21l0.05-0.42l-0.25-0.4l-0.16-0.17l-0.17-0.21l-0.21-0.17l-0.4-0.17l-0.4-0.09
          l-1.08-0.21l-0.4-0.02l-0.19,0.02l-0.4,0.19l-0.95-0.3l-0.19-0.09l-0.13-0.21l-0.16-0.38l-0.04-0.17l0.02-0.44l0.05-0.82
          l-0.08-0.46l-0.08-0.4l-0.09-0.26l-0.09-0.17l-1.82-0.79l-2.11-1.04l-0.25-0.13l-0.99-0.68l-0.08-0.25l-2.35-2.29l-0.23-0.12
          l-1.76-1.29l-0.89-2.67l-0.59-2.9l0.02-1.67l0.26-1.29l-0.93-1.97l-1.48-2.11l-0.13-0.19l-0.09-0.19l-0.47-1.34l-1.12-3.32
          l-0.12-0.46l-0.17-1.21l-0.21-0.49l-1.44-2.62l-0.15-0.23l-0.82-1.21l-0.61-0.87l-0.36-0.4l-0.21-0.19l-0.85-0.72l-0.36-0.29
          l-0.32-0.4l-0.12-0.44v-0.19l0.09-0.55l-0.16-1.21l-0.16-0.42l-0.09-0.21l-0.46-0.83l-0.17-0.17l-1.59-1.63l-0.21-0.17l-0.21-0.12
          l-0.66-0.26l-0.42-0.25l-0.13-0.19l-0.38-0.59l-0.23-0.44l-0.26-0.61l-0.16-0.42l-0.38-1.41l-0.06-0.38l-0.02-0.19l0.02-0.38
          l0.09-0.42l0.17-0.4l0.21-0.38l0.33-0.4l0.64-0.47l0.44-0.32l0.61-0.49l0.17-0.21l0.59-0.96l0.29-0.51l1.5-3.09l2.79-6.13
          l3.57-7.79l3.19-6.82l2.67-5.81l6.07-12.97l4.53-9.82l2.45-5.21l2.8-6.11l4.21-8.98l2.69-5.95l-0.51-0.53l-1.67-1.42l-0.05-0.23
          l0.17-0.72v-0.25l-0.09-0.3l-0.09-0.17l-0.08-0.05l-0.12-0.02l-0.34,0.05l-0.12,0.04l-0.99,0.34l-0.25,0.16l-0.23,0.17l-0.68,0.51
          l-0.96-0.32l-0.19-0.08l-0.17-0.08l-0.87-0.44l-0.16-0.12l-0.09-0.17l-0.02-0.25l-1.16-2.2l-0.38-0.13l-2.83-1.59l-0.15-0.12
          l-0.82-0.76l-0.13-0.13l-0.47-0.57l-0.12-0.17l-0.05-0.21l0.02-0.21l0.05-0.21l-0.05-0.19l-0.16-0.12l-1.31-0.76l-0.23-0.04
          l-0.25-0.02l-1.37-0.16l-1.57-0.44l-1.08-0.32l-0.17-0.05l-0.82-0.34l-0.21-0.12l-0.22-0.17l-0.34-0.29l-1.21-0.66l-0.62-0.29
          l-0.95-0.3l-0.61-0.17l-0.21-0.12l-1.25-1l-0.21-0.17l-0.15-0.21l-0.42-0.68l-0.19-0.25l-0.19-0.17l-2.96-1.73l-1-0.51l-1.08-0.53
          l-0.46-0.26l-1.48-1.12l-2.98-2.35l-1.2-1.16l-0.3-0.4l-0.09-0.19l-0.04-0.19l0.04-0.79l-0.02-0.23l-0.59-2.03l-0.15-0.23
          l-2.04-1.37l-0.25-0.05l-0.63,0.29l-0.21-0.04l-0.25-0.13l-0.19-0.19l-1.23-1.2l-0.53-0.61l-0.3-0.4l-0.12-0.21l-0.05-0.19
          l0.02-0.46l0.09-1.74l0.05-0.17l0.13-0.25l0.29-0.42l0.08-0.23l-0.04-0.19l-0.13-0.19l-0.19-0.09l-0.42-0.19l-0.61-0.23l-0.38-0.16
          l-0.21-0.16l-0.38-0.3l-0.17-0.36l-0.12-0.47l-0.13-1l-0.05-0.66l-0.06-0.7l0.02-0.42l0.17-0.76l0.13-0.47l0.17-0.44l0.12-0.23
          l-0.34-2.5l-0.21-3.36l-0.17-1.69l-0.3-3.92l-0.4-4.19l-0.33-2.45l-0.3-3.15l-0.25-2.73l-0.19-2.73l0.29,0.09l0.55,0.23l0.13,0.13
          l0.36,0.44l0.12,0.16l0.04,0.09l0.04,0.09l-0.04,0.09l-0.08,0.05l-0.09,0.17l0.02,0.12l0.05,0.08l0.21,0.17l0.29,0.25l0.16,0.12
          l0.57,0.23l0.21,0.05l0.23,0.04l0.33-0.05l0.72-0.32l0.26-0.13l0.36-0.3l0.12-0.16l0.19-0.46l0.02-0.09l0.08-0.19l0.17-0.23
          l0.12-0.02l2.24,0.25l0.87,0.12l0.09,0.04l0.12-0.02l0.09-0.04l0.15-0.12l0.46-0.61l0.16-0.25l0.05-0.21l0.02-0.25l-0.09-0.44
          l-0.59-1.69l1.9-0.34l0.3-0.08l0.08-0.05l0.05-0.05l1.55-1.9l0.55,0.23l2.67,1l2.16,0.12l0.09-0.02l0.08-0.05l0.05-0.08l0.3-0.83
          l0.09-0.3l0.15-0.51l0.02-0.25l1.84-0.85l1.95-0.91l0.17-0.08l0.19-0.05l0.47,0.04l0.23,0.04l0.4,0.12l0.3,0.12l0.49,0.16
          l0.33,0.08l0.12,0.02l1.25-0.12l0.19-0.05l0.49-0.29l0.21-0.17l0.19-0.21l1.03-1.33l0.12-0.16l0.04-0.05l0.34-0.83l0.02-0.36
          l-0.02-0.12l0.02-0.25l0.02-0.12l0.12-0.16l1.54-1.06l0.29-0.12l0.42-0.12l0.12-0.01l0.12-0.02l0.12,0.02l0.09,0.01l0.09,0.04
          l0.08,0.05l0.46,0.46l0.17,0.09l0.12-0.02l0.08-0.05l0.53-0.38l0.68-0.49l1.1-0.47l0.09-0.04l0.21,0.02l0.12,0.02l0.09,0.04
          l0.16,0.09l0.12,0.16l0.05,0.44l0.12,0.53l0.04,0.05l0.23,0.17l0.57,0.34l0.29,0.12l2.33,0.53l1.73,0.36l0.23-0.02l0.09-0.04
          l0.12-0.12l0.16-0.25l0.04-0.09l0.12-0.16l0.08-0.05l0.23-0.16l0.09-0.04l1.71-0.36l0.25-0.02l0.51,0.16l0.25,0.16l0.15,0.12
          l0.93-0.08l1.04-0.42l0.83,0.19l1.9,0.17l2.39,0.65l1.69,0.53l0.55,0.23l0.29,0.12l0.19,0.05l0.42,0.12l0.38,0.02l0.36-0.02
          l0.12-0.02l0.51-0.13l0.36-0.16l0.15-0.12l0.46-0.46l0.19-0.19l0.06-0.08l0.04-0.09l0.02-0.12l-0.04-2.49l-0.01-0.12l-0.12-0.16
          l-2.46-2.96l-1.52-0.76l0.61-2.07l1.59-1.73l0.02-0.12l0.29-1.24l0.12-0.16l0.26-0.13l0.12-0.02l0.25,0.02l0.21,0.05l0.29,0.12
          l0.17,0.09l0.38,0.29l0.25,0.26l0.16,0.23l0.13,0.13l0.08,0.05l2.5,1.29l0.23,0.04l0.34-0.04l3.72-0.72l7.45-1.69l0.38-0.16
          l2.28-0.38l0.12,0.16l1.82,1.95l0.06,0.05l0.21,0.05l0.34,0.05l0.38,0.02l0.23-0.02l0.09-0.04l0.25-0.15l0.25-0.23l0.26,0.13
          l0.22,0.15l0.13,0.13l0.05,0.08l0.04,0.09l0.05,0.21l0.44,2.67l-0.04,0.09l-0.7,1.52l-0.05,0.08l-0.09,1.59l-0.19,3.89l0.02,0.12
          l0.23,1.44l0.04,0.22l0.05,0.19l0.29,0.49l0.09,0.04l0.68,0.08l0.7-0.08l0.34-0.19l0.19-0.05l0.79-0.12l4.13,0.46l1.54,0.59
          l0.09,0.04l2.16,1.16l0.16,0.12l0.05,0.05l0.36,0.44l0.4,0.49l0.61,1.57l1.44,0.16l1.41,1.03l0.34,0.47l0.59,0.72l0.09,0.04
          l0.79,0.3l0.36,0.02l1.42-0.16l0.05-0.05l0.43-0.47l1.1-0.19l0.09,0.02l1.88,1.9l1.54,1.34l0.42-0.09l0.12-0.02l0.25-0.02
          l0.12,0.02l0.12,0.16l0.47,1.1l0.02,0.09l0.02,0.12l-0.04,0.09l-0.05,0.05l-0.09,0.04l-0.38,0.13l-0.38-0.02l-0.09,0.04l-0.05,0.08
          l-0.06,0.19l-0.01,0.25l0.01,0.38l0.09,0.44l0.82,3.02l0.05,0.08l1.91,2.28l0.19,0.19l0.21,0.17l0.21,0.17l0.34,0.19l0.59,0.19
          l0.3,0.08l0.7,0.09l0.89,0.16l0.19,0.05l0.08,0.05l0.05,0.08l0.05,0.19l0.04,0.23l0.02,0.51l-0.02,0.25l-0.15,0.62l-0.19,0.85
          l-0.19,1.34l0.02,0.36l0.17,2.2l0.02,0.12l0.02,0.12l0.12,0.29l0.08,0.19l0.57,1.27l0.16,0.23l0.16,0.13l0.15,0.12l0.25,0.16
          l0.13,0.13l0.08,0.19l0.63,1.99l0.04,0.22l0.02,0.25l-0.02,0.36l-0.02,0.12l-0.04,0.09l-0.09,0.42l-0.04,0.23l0.02,0.38l1.76,4.21
          l0.16,0.12l0.38,0.16l0.21,0.05l0.25,0.02l0.19-0.08l0.32-0.21l0.34-0.17l0.19-0.05l0.09,0.04l1.37,1.52l0.74,0.83l1.31,1.55
          l0.12,0.29v1.29l-0.02,0.47l-0.15,0.36l-0.02,0.36l0.04,0.36l0.16,0.51l0.19,0.46l0.09,0.16l0.13,0.13l0.08,0.05l0.21,0.05h0.26
          l0.36-0.04l0.12,0.02l0.09,0.04l0.15,0.12l0.16,0.25l0.04,0.09l0.08,0.3l0.29,1.41l0.04,0.23v0.13l-0.25,0.79l-0.02,0.12
          l-0.02,1.37l0.05,0.17l0.09,0.04l0.12,0.02l2.16,1.59l0.02,0.12l0.44,1.71l0.05,0.08l0.08,0.05l1.69,1.2l0.08,0.05l0.38,0.16
          l0.21,0.05l2.79,0.61l0.74,0.02l0.83-0.17l0.42-0.21l0.51-0.21l0.91,0.02l0.23,0.04l-0.25,0.16l-0.12,0.16l-0.21,0.3l-0.08,0.19
          l-0.02,0.23l0.25,0.79l0.12,0.93l-0.02,0.25l-0.12,0.4l-0.09,0.17l-0.13,0.13l-1.34,1.13l-0.17,0.09l-0.51,0.16l-0.17,0.04
          l-0.34,0.02l-0.3-0.02l-0.16-0.02l-0.3-0.05l-0.46-0.19l-0.63-0.59l-0.25-0.29l-0.3-0.23l-0.4-0.12l-0.34,0.05l-0.17,0.08
          l-3.74,1.74l-0.15,0.12l-0.16,0.21l-0.17,0.34l-0.12,0.16l-0.16,0.12l-1.67,0.68l-0.19,0.08l-0.59-0.08l-0.23,0.02l-0.4,0.13
          l-1.33,0.49l-0.09,0.17l-0.02,0.62l0.23-0.02l3.41-0.57l4.36-0.74l0.16-0.02l0.15,0.05l0.16,0.12l0.13,0.13l0.42,0.46l0.12,0.16
          l0.09,0.32l0.04,0.34l-0.02,0.64l-0.21,0.34l-0.55,0.26l0.36,0.44l0.78-0.25l0.26-0.49l0.33-0.42l0.47-0.32l0.47-0.16l0.76,0.13
          l0.65-0.32l0.63,0.16l0.21-0.04l1.12-0.46l0.17-0.08l1.24-0.61l0.16-0.12l0.4-0.4l0.57-0.47l2.84-1.9l0.25-0.09l0.4-0.12l0.44-0.08
          l0.25-0.02l0.61-0.17l1.5-0.61l0.66-0.34l0.17-0.09l3.58-2.03l0.15-0.12l0.13-0.13l0.12-0.16l0.4-0.89l0.09-0.17l0.13-0.13
          l0.51-0.36l0.32-0.19l0.87-0.44l0.93-0.36l0.19-0.08l0.33-0.19l0.13-0.13l0.74-0.82l-0.21-0.53l-0.08-0.17l-0.02-0.25l0.05-0.19
          l0.12-0.16l0.16-0.09l0.17-0.09l1.16-0.17l1.24,0.06l0.21,0.05l0.19,0.08l0.17,0.09l0.13,0.09l0.12,0.16l0.29,4.48l-0.02,0.57
          l-0.04,0.3l-0.12,0.44l-0.13,0.3l-0.12,0.3l-0.04,0.16l-0.17,0.96l0.02,0.36l0.34,1.65l0.08,0.19l0.95,1.88l0.42,0.36l0.68,0.61
          l0.26,0.26l0.12,0.16l0.08,0.17l0.08,0.19l0.34,1.74l-0.04,0.47l-0.05,0.21l-0.13,0.16l-2.04,2.11l-0.15,0.12l-0.21,0.08
          l-0.34,0.05l-0.96-0.61l-0.17-0.08l-2.12-0.61l-1.42-0.36l-0.12-0.02l-0.25,0.02l-0.83,0.19l-0.17-0.04l-2.45-1.21l-0.06-0.05
          l-0.36-0.57l0.02-0.12l0.08-0.17l0.05-0.21l-0.04-0.23l-0.05-0.08l-0.08-0.05l-0.36-0.29l-0.55-0.3l-0.08,0.05l-0.13,0.13
          l-1.44,2.43l-1.21,2.14l-0.26,0.78l-0.19,0.72l-0.21,0.99l-0.08,0.42l0.79,3.57l0.02,0.12l0.12,0.16l0.4,0.51l0.17,0.21l0.83,0.74
          l0.25,0.16l0.09,0.04l0.05-0.02l-0.13-0.36l-0.05-0.08l-0.4-0.38l-0.3-0.23l-0.21-0.17l-0.26-0.29l-0.09-0.17l-0.05-0.21
          l-0.43-2.99l0.13-0.64l0.04-0.09l1.86-3.2l1.12-1.9l0.05-0.05l0.09-0.04l0.25,0.02l0.09,0.04l1.94,1.1l0.53,0.34l0.13,0.55
          l1.21,1.07l1.52,1.48l0.26,0.27l0.12,0.16l0.02,0.12l-0.08,1.48l-0.21,0.83l-0.06,0.19l-0.15,0.25l-0.16,0.12l-0.46,0.19
          l-0.21,0.05l0.59,2.82l0.26,0.76l0.32,0.59l0.05,0.06l0.57,0.47l0.76,0.42l0.83,0.16l0.12,0.02l0.05,0.08l0.21,0.44l0.15,0.09
          l0.12,0.02l0.12-0.02l0.05-0.04l-0.47-0.7l-0.08-0.05l-0.17-0.08l-0.12-0.02l-0.36-0.02l-0.26-0.13l-0.08-0.05l-1.21-1l-0.19-0.19
          l-0.05-0.08l-0.13-0.26l-0.09-0.44l-0.02-0.25l0.13-2.52l0.29-2.45l0.02-0.12l0.89-1.31l0.05-0.05l0.08-0.05l0.12-0.02l1.61,0.95
          l0.99,1.31l0.05,0.19l-0.02,0.25l-0.12,0.53l-0.09,0.17l0.12,0.29l1.29,0.78l0.17,0.02l0.16-0.08l0.13-0.23l-0.02-0.16l-0.57-0.55
          l-0.65-0.93l-0.72-1.52l-0.08-0.17l-0.32-0.87l-0.02-0.47l0.02-0.12l0.3-0.34l2.43-2.46l1.97,0.12l2.28,0.74l0.16,0.12l0.3,0.47
          l0.17,0.3l-0.19,0.05l-0.51,0.17l-0.17,0.13l-0.08,0.16l0.02,0.15l0.15,0.16l0.17,0.12l0.19,0.02l0.49,0.02l0.72-0.05l0.33-0.06
          l0.15-0.08l0.13-0.08l0.47-0.46l0.34-0.21l0.74-0.4l4.86-1.16l0.21-0.04l0.87,0.26l1.48,0.83l0.09,0.17l0.55,0.3l3.75-0.49
          l0.17-0.02l0.15-0.04l1.1-0.46l0.74-0.62l0.21-0.32l0.15-0.17l0.29-0.25l0.44-0.3l0.43-0.25l0.34-0.16l1.95-0.57l0.78-0.09
          l0.16,0.08l0.09,0.16l0.04,0.17l-0.04,0.7l-0.09,0.29l-0.16,0.3l-1.48,2.5l-0.76,1.82l-0.29,1.73l-0.02,0.23l-0.47,1.1l-0.17,0.23
          l-0.3,0.21l-0.13,0.13l-0.49,0.82l-0.02,1.08l0.19,0.49l0.19,0.3l0.21,0.55l0.09,0.4l-0.09,1.42l-0.29,1.61l0.74-0.89l0.33-0.47
          l0.06-0.21l-0.06-0.46l-0.02-0.25l0.04-0.74l0.02-0.23l0.05-0.21l0.25-0.55l0.09-0.17l0.85-1.5l0.65-1.2l0.44-1.12l0.21-0.86
          l0.68-1.88l0.09-0.17l1.31-1.84l0.25-0.29l0.15-0.12h0.86l0.59-0.43l0.25,0.02l0.46,0.05l0.93-0.09l0.23-0.04l0.38-0.16l0.17-0.09
          l0.15-0.12l0.87-1.54l1.84-2.07l0.83-1.38l0.76-1.33l0.13-0.13l0.57-0.49l0.47,0.3l0.49,0.3l0.44-0.19l0.16-0.38l0.3-0.13
          l0.05,0.29l0.87,1.27l0.09,0.17l0.08,0.43l0.09,0.17l0.59,0.61l0.13,0.13l1.31,0.7l0.13-0.05l-0.01-0.47l-0.12-0.42l-0.16-0.4
          l-0.17-0.36l-0.25-0.29l0.87-1.1l1.27-0.04l3.09-0.47l-0.1-0.15l-0.15-0.12l-0.16-0.08l-0.36-0.09l-0.61-0.04l-0.66,0.02
          l-0.34,0.02l-0.47,0.04l-0.96,0.12l-0.32,0.04l-0.38,0.08l-0.3,0.13l-0.15,0.09l-0.17,0.05l-0.51-0.02l-0.23-0.02l-0.15-0.12
          l-0.05-0.17l0.08-0.95l0.22-1.88l0.16-0.17l0.16-0.12l0.34-0.17l0.4,0.05l0.26,0.26l0.15,0.12l0.19,0.05l1.04,0.19l0.19-0.08
          l0.08-0.17l1-2.88l0.15-0.76l-0.74-1.46l-0.08-0.16l-0.05-0.17l-0.04-0.16l0.02-0.32l0.05-0.19l0.08-0.17l0.36-0.66l1.03-1.48
          l3.37-2.99l0.16-0.12l0.13-0.05l0.15-0.04l0.3,0.13l0.47,0.57l0.06,0.16l0.01,0.16l0.05,0.16l0.12,0.17l0.3,0.13l0.19-0.04
          l1.12-0.79l0.15-0.13l0.16-0.13l0.65-0.57l1.27-0.87l0.17-0.09l0.19-0.08l0.22-0.02l0.19,0.08l0.36,0.42l0.08,0.19l0.12,0.42
          l-0.05,0.46l-0.4,0.4l-0.09,0.17l0.06,0.21l0.44-0.04l0.96-0.38l0.47-0.3l0.46-0.34l0.13-0.13l0.19-0.32l0.13-0.4l0.02-0.23
          l0.13-0.21l0.55,0.02l0.25,0.02l0.17,0.09l0.12,0.15l0.85,1.63l1.56,1.48l0.32-0.61l-0.13-0.55l-0.76-0.55l-0.4-0.09l-0.17-0.36
          l-0.16-1.41l0.17-0.51l0.08-0.16l0.49-0.16l2.67,0.29l0.21,0.05l1.2,0.65l0.15,0.12l0.23,0.3l0.51,0.99l1.99,0.87l1.91-0.99
          l0.19,0.05l0.62,2.49l-0.09,0.17l-0.15,0.12l-0.25,0.02l-0.17,0.09l-0.12,0.16l-0.08,0.19l-0.02,0.25l0.05,0.21l0.12,0.16
          l0.13,0.08l0.95-0.82l0.65-1.65l0.66-1.24l0.19-0.02l0.3,0.21l0.12,0.16l-0.05,0.57l-0.26,0.99l0.49,0.55l0.86-0.23l2.07-0.13
          l0.21,0.02l0.12,0.16l0.36,0.68l0.59,1.99l-0.05,0.16l-0.7,1.1l0.12-0.16l0.23-0.26l0.46-0.3l0.19-0.02l0.15,0.12l0.05,0.21
          l0.08,0.17l0.13,0.13l1.67,0.21l0.57-0.12l2.98-0.93l1.33-0.61l-0.38,1.94l-0.66,1.59l-0.25,0.55l-0.01,0.23l1.8-0.7l0.72-0.76
          l0.7,1.38l0.08,0.61l-0.78,0.42l-0.15,0.12l-0.08,0.08l-0.05,0.08l-0.47,0.95l-0.04,0.09l0.02,0.23l0.15,0.12l0.09,1.48l-0.47,3.53
          l-0.36,1.76l-0.01,0.12l-0.08,0.17l-0.47,0.7l-0.08,0.05l-0.09,0.04l-0.12-0.02l-0.3-0.09l-0.85,0.16l-0.09,0.04l-0.05,0.05
          l-0.06,0.08l-0.04,0.09l0.08,0.46l0.05,0.21l0.19,0.47l0.05,0.08l0.12,0.02l0.36-0.13l0.25-0.16l0.33-0.08l0.12,0.02l0.26,0.12
          l0.15,0.12l0.17,0.23l0.08,0.19l0.02,0.12l-0.08,1.74l-0.05,0.34l-1.06,3.53l-0.38,0.79l-0.05,0.08l-0.13,0.13l-1.52,1.41
          l-0.49,0.4l-0.61,2.81l0.12,0.64l0.08,0.17l-0.02,1.2l-0.04,0.23l-1.16,0.85l-1.65,1.24l-0.46,0.36l-0.93,0.76l-0.26,0.26
          l-0.08,0.19l0.01,0.12l0.04,0.09l0.79,1.16l0.46,0.61l-0.96,0.99l0.25,1.71l-0.02,0.53l-0.49,1.44l-0.12,0.29l-0.22,0.42l-0.68,1.2
          l-0.05,0.08l-1.76,2.12l-1.08,0.78l-0.29,0.08l-0.09,0.04l-0.05,0.05l-0.76,0.78l-0.23,0.29l-1.04,2.99l-0.79,2.56l0.02,0.12
          l-0.33,2.18l-0.05,0.19l-0.4,1.16l-0.08,0.17l-0.05,0.08l-0.19,0.19l-2.12,2.11l-0.49,0.47l-0.3,0.08l-0.3,0.02l-0.17,0.08
          l-0.66,0.34l-0.16,0.12l-0.04,0.09l-0.62,2.54l-0.08,0.46l0.05,0.4l-0.91,0.51l-0.44,0.34l-0.26,0.26l-0.25,0.29l-0.05,0.08
          l-0.05,0.23l-0.02,0.25l-0.05,0.21l-0.36,0.7l-1.41,2.03l-0.12,0.15l-0.29,0.38l-0.19,0.21l-1.25,0.96l-0.17,0.08l-0.74,0.02h-0.12
          l-0.38-0.16l-0.47-0.13l-1.08-0.05l-0.34,0.26l-4.07,3.3l-3.74,3.02l-1.71,1.37l-8.12,6.54l0.61,0.09l1.44,0.65l0.3,0.09l0.21,0.05
          l0.76,0.17l0.12,0.02l0.38,0.02l0.12-0.02l0.76-0.13l0.19-0.08l0.21-0.04l0.59-0.05l1.33,0.02l0.21,0.05l0.08,0.05l0.17,0.21
          l0.38,0.78l0.57,0.99l0.05,0.08l0.13,0.13l0.23,0.17l0.25,0.15l0.09,0.02l0.36,0.04l0.26,0.02l0.12-0.02l0.21,0.05l0.09,0.04
          l1.27,1.25l0.05,0.08l0.04,0.09l0.02,0.12l-0.05,0.49l-0.05,0.21l-0.04,0.09l-0.34,0.59l-0.05,0.08l-0.08,0.05l-0.33,0.19
          l-0.36,0.16l-0.22,0.02l-0.44-0.08l-0.22,0.04l-0.33,0.05l-0.17,0.09l-0.21,0.19l-0.15,0.21l-0.05,0.21l0.01,0.36l0.09,0.53
          l0.12,0.51l0.13,0.26l0.25,0.26l0.29,0.25l0.21,0.17l0.13,0.13l0.21,0.32l0.04,0.09l-0.02,0.25l-1.06,3.15l-0.06,0.09l-0.12,0.16
          l-0.21,0.19l-0.16,0.09l-0.25,0.13l-0.09,0.04l-0.4,0.12l-0.74,0.16l-0.33,0.04l-0.21,0.17l-0.13,0.16l-0.21,0.34l-0.04,0.09
          l-0.02,0.12v0.12l0.38,1.31l-0.22,1.65l-0.36-0.04l-0.36-0.04l-0.79,0.19l-0.16,0.12l-1.69,1.46l-0.17,0.23l-0.96,1.44l-0.04,0.38
          l0.05,0.13l0.09,0.16l0.09,0.29l0.02,0.12l-0.04,0.76l-0.04,0.34l-0.23,0.3l-0.08,0.05l-0.17,0.09l-2.85,1.29l-1.1,0.3l-1.12,0.3
          l-1.78,0.68l-0.3,0.21l-2.77,1.97l-0.19,0.21l-0.05,0.08l-0.09,0.17l-0.01,0.12l0.57,2.63l0.15,1.99l-0.08,1.27l-0.44,0.89
          l-0.99,1.54l-0.27,0.26l-0.39,0.38l-0.72,0.85l-0.05,0.08l-0.38,0.55l-0.04,0.09l-0.16,0.64l0.08,0.55l0.09,0.42l0.08,0.19
          l0.61,0.96l0.17,0.23l0.61,0.7l0.19,0.21l1.16,1.08l0.4,0.26l0.12,0.02l0.33,0.08l0.13,0.05l0.13,0.21l-0.02,0.25l-0.3,2.35
          l-0.49,2.39l-0.04,0.12l-2.07,6.09l-0.16,0.25l-0.55,0.38l-0.29,0.12l-0.12,0.02l-0.09,0.04l-0.21,0.34l-2.08,3.94l-0.17,0.36
          l-0.02,0.09l-0.02,0.13l0.06,0.46l-0.06,0.21l-0.09,0.17l-1.04,1.65l-0.7,0.93l-0.08,0.05l-0.17,0.08l-0.21,0.04l-0.95,0.08
          l-0.09,0.04l-0.08,0.05l-1.5,1l-0.13,0.12l-0.21,0.38l-0.34,0.72l-0.61,1.12l-0.05,0.08l-1.46,1.99l-0.38,0.42l-0.21,0.19
          l-0.16,0.12l-0.21,0.05l-0.51,0.55l-0.89,1.1l-0.47,0.72l-0.09,0.17l-0.63,1.91l-0.08,0.32l-0.08,0.34L4036.1,1130.03
          L4036.1,1130.03z"/>
        <path id="path1171" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4032.64,942.37l-0.15-0.02l-0.16-0.08l-0.09-0.17
          l-0.05-0.32l-0.05-0.32l-0.08-0.65l-0.02-0.65l0.02-0.36l0.04-0.16l0.12-0.32l1-1.2l0.42-0.42l2.9-1.13l0.17,0.02l0.15,0.08
          l0.16,0.12l0.55,0.7l0.05,0.16l0.04,0.36l0.02,0.34l-0.02,0.3l-2.38,2.45l-1.71,1.2l-0.33,0.17L4032.64,942.37L4032.64,942.37z"/>
        <path id="path1173" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4029.32,945.71l-1.03-0.3l-0.15-0.12l-0.21-0.3
          l-0.08-0.17l0.09-0.32l0.3-0.26l0.17-0.12l0.16-0.08l0.15-0.16l0.21-0.3l0.15-0.47l0.09-0.32l0.09-0.32l0.38-2.69l0.12-0.96
          l0.05-0.16l0.09-0.16l0.89-1.24l0.16-0.12l0.25,0.16l0.05,0.16l0.05,0.3l0.15,1.24l-0.05,0.7l-0.05,0.34l-0.04,0.44l0.12,1.12
          l0.02,0.16l0.08,0.17l0.3,0.47l0.08,0.15l0.12,0.29l0.02,0.29l-0.08,0.29l-0.3,0.79l-0.47,0.16l-0.16,0.02l-0.36,0.12l-0.29,0.19
          l-0.23,0.3l-0.47,0.76L4029.32,945.71L4029.32,945.71z"/>
        <path id="path1175" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4036.58,948.96l-0.46-0.17l-0.47-0.08l-0.29-0.04
          l-0.32-0.02l-1.52,0.05l-0.51,0.04l-0.23,0.02l-0.51-0.02l-0.49-0.05l-0.16-0.04l-0.15-0.05l-0.16-0.08l-0.15-0.12l-0.13-0.17
          l-0.12-0.3l-0.36-1.04l-0.08-1.21l0.09-0.3l0.13-0.16l0.15-0.05l1.48-0.3l0.17-0.02l0.32,0.12l1.55-0.05l0.34-0.02l0.3-0.12
          l0.15-0.12l0.17-0.12l0.21-0.21l0.47-0.36l0.78-0.53l0.49-0.16l0.15-0.02l0.51-0.02l1.8-0.13l1.94-0.25l1.24-0.23l0.3-0.12
          l0.33,0.02l0.15,0.05l0.16,0.05l0.3,0.19l0.51,0.47l0.29,0.29l0.05,0.16l0.04,0.16l-0.06,0.38l-0.09,0.3l-0.16,0.32l-0.3,0.19
          l-0.29,0.08l-0.47,0.23l-1.57,0.89l-0.49,0.34l-0.3,0.29l-0.15,0.17l-0.4,0.53l-0.25,0.36l-0.25,0.36l-0.21,0.34l-0.12,0.25
          l-0.17,0.4l-0.09,0.16l-0.17,0.16l-0.16,0.12l-1.13,0.57l-0.16,0.02l-1.03-1.06l-0.57-0.32L4036.58,948.96L4036.58,948.96
          L4036.58,948.96z"/>
        <path id="path1177" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4033.26,944.61l-0.17-0.04l-0.16-0.17l-0.02-0.16
          l0.06-0.17l0.12-0.19l0.19-0.16l1.29-0.95l0.32-0.08l0.76-0.12l0.29-0.02l0.42,0.13l0.13,0.16l-0.08,0.15l-0.34,0.26l-2.33,1.25
          l-0.16,0.05l-0.15,0.02L4033.26,944.61L4033.26,944.61L4033.26,944.61z"/>
        <path id="path1179" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4028.21,949.59l-0.34-0.13l-0.15-0.08l-0.47-0.38
          l-0.04-0.04l-0.44-0.44l-0.78-1.31l-0.36-0.61l-0.08-0.15l-0.05-0.3l0.05-0.16l0.25-0.29l0.99-0.7l0.21-0.05l0.16,0.08l1.9,1
          l0.15,0.15l0.17,0.3l0.05,0.17l0.05,0.34l0.26,1.67l0.02,0.17l-0.04,0.16l-0.04,0.16l-0.13,0.17l-0.17,0.12l-0.87,0.19
          L4028.21,949.59L4028.21,949.59z"/>
        <path id="path1181" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4043.56,951.87l-2.11-0.83l-0.17-0.08l-0.15-0.09
          l-0.15-0.32l-0.09-0.32l1.57-1.24l0.05-0.08l1.12-0.83l1.03-0.55l0.16-0.02l0.93-0.05l0.76,0.05l0.66,0.05l0.44,0.09l1.13,0.36
          l0.16,0.08l0.15,0.09l0.15,0.13l0.34,0.46l0.04,0.16v0.05l0.25,0.53l0.04,0.3l0.02,0.47l-0.55,0.66l-0.16,0.09l-0.36,0.19
          l-0.49,0.12l-1.2,0.19l-1.16,0.05l-0.15-0.02l-0.17-0.04l-0.3,0.02l-0.3,0.05l-0.79,0.21l-0.32,0.12L4043.56,951.87L4043.56,951.87
          z"/>
        <path id="path1183" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4022.39,949.56l-0.34,0.12l-0.34,0.08l-0.49,0.09
          l-0.3,0.04l-0.49-0.05l-0.13-0.16l-0.02-0.16l0.05-0.17l0.17-0.16l0.29-0.23l0.16-0.17l0.59-0.47l0.59-0.44l0.17-0.05l0.43-0.12
          l0.16-0.02l0.62,0.02l0.57,0.19l0.47,0.36l0.16,0.12l0.12,0.16l-0.02,0.16l-0.08,0.15l-0.17,0.06l-0.44,0.09L4022.39,949.56
          L4022.39,949.56z"/>
        <path id="path1185" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4021.44,954.15l-0.32-0.09l-0.29-0.02l-0.46,0.08
          l-1.57-0.66l-0.15-0.08l-0.16-0.12l-0.08-0.3l0.04-0.17l0.15-0.57l0.09-0.3l0.08-0.16l0.21-0.32l0.29-0.44l0.25-0.29l0.3-0.08
          l0.13,0.02l1.9-0.46l1.34-0.42l0.32-0.12l0.46-0.3l0.47-0.21l0.16-0.04l0.16-0.02l1.29,0.02l0.15,0.02l0.17,0.05l0.99,0.83
          l0.12,0.16l-2.35,1.99l-0.49,0.25l-0.16,0.02l-0.51,0.08l-0.15,0.09l-0.49,0.4l-0.66,0.59l-0.91,0.82L4021.44,954.15
          L4021.44,954.15z"/>
        <path id="path1187" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4015.21,950.43l0.12-0.16l0.33-0.19l0.43-0.08
          l0.76-0.02l0.44,0.02l-0.23,0.17l-0.68,0.38l-0.34,0.16l-0.3,0.13l-0.15,0.02l-0.3-0.04l-0.15,0.02l-0.29,0.21l-0.13,0.16
          L4015.21,950.43L4015.21,950.43z"/>
        <path id="path1189" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4059.01,977.58l-0.99,0.46l-4.08,1.71l-4.04,0.63
          l-2.52-0.47l-0.08,0.17l-0.68,1.24l-0.15,0.13l-2.16,1.38l-0.87-0.55v-0.21l-0.02-0.09l-0.51-1.76l-0.16-0.12l-0.16,0.05
          l-0.13,0.17l-0.05,0.16l-0.02,0.16l0.08,0.32l0.05,0.17l0.16,0.32l0.21,0.47l0.05,0.3l-0.21,0.36l-0.02,0.09l-0.09,0.21l-0.13,0.19
          l-0.09,0.05l-0.49,0.4l-0.3,0.05l-0.16-0.02l-1.21-0.51l-0.63-0.4l-0.61-0.34l-1.74-0.76l-0.17-0.05l-0.36,0.05l-0.34,0.29
          l-0.16,0.19l-0.08,0.21l-0.09,0.13l-0.15,0.12l-3.66,1.1l-1.24,0.32l-0.25,0.02l-1.1-0.12l-2.45-0.53l-0.21-0.05l-0.15-0.12
          l-0.78-0.61l-0.15-0.16l-0.08-0.21l-0.05-0.3l-0.05-1.52l-0.05-0.3l-0.12-0.22l-0.29-0.25l-0.72-0.59l-0.4-0.4l-0.23-0.3
          l-0.68-1.16l-0.46-0.59l-0.12-0.16l-0.23-1.9l0.04-0.36l0.61-3.24l0.76-0.47l0.63,0.25l0.65,0.04l0.21,0.05l0.68,0.09l1.13-0.93
          l0.38-0.63l-0.25-0.16l-0.17,0.09l-1.33,0.49l-0.21,0.05l-1.21-0.13l-0.13-0.04l-0.12-0.05l-0.12-0.05l-0.09-0.08l-0.17-0.16
          l-0.6-0.34l-0.09-0.17l-0.05-0.19l-0.16-1.16l-0.01-0.74l0.01-0.25l0.26-4.87l0.16-0.38l0.79-0.96l0.09-0.02l0.05,0.09l0.09,0.36
          l0.26,0.51l0.44,0.09l1.59,0.63l0.21,0.04l0.25,0.02l0.61-0.02l-0.09-0.17l-0.15-0.12l-0.51-0.29l-0.17-0.08l-0.21-0.05l-0.23-0.04
          l-0.46-0.05l-0.49-0.02l-0.17-0.08l-0.29-0.59l-0.05-0.09l-0.44-1.27l-0.23-0.66l0.44-2.07l0.21-0.57l0.59-1.34l0.08-0.17
          l0.21-0.32l1.16-1.2l0.38-0.4l0.16-0.12l0.66-0.4l0.44-0.25l0.61-0.29l0.29-0.12l0.15-0.05l0.49-0.12l0.15-0.02l1.1-0.13l0.44-0.02
          l3.57,0.51l0.65,0.13l5.65,1.29l1.86,0.49l1.17,0.4l4.65-0.43l-0.02-0.25l0.08-0.17l0.13-0.13l0.82-0.47l0.17-0.09l0.34-0.13
          l2.64-0.46l0.82,0.04l2.71,0.32l3.44,1l3.71,0.34l4.19,0.7l0.7,0.51l0.13,0.13l0.05,0.21v0.25l-0.13,0.89l-0.08,0.21l-0.09,0.17
          l-0.12,0.16l-0.13,0.12l-0.32,0.21l-0.13,0.13l-0.12,0.16l-0.17,0.34l-0.53,1.31l-0.08,0.19l-0.05,0.19l-0.01,0.25l0.04,0.47
          l-0.02,0.49l-0.34,1.74l-0.19,0.89l-0.57,2.25l-0.12,0.42l-0.13,0.4l-0.08,0.17l-0.13,0.13l-0.23,0.17l-0.17,0.09l-0.38,0.15
          l-0.4,0.13l-0.21,0.06l-0.15,0.12l-0.29,0.78l0.02,0.4l0.23,0.4l0.16,0.44l-0.08,0.19l-0.12,0.16l-2.33,3.05l-1.65,4.23l-0.08,0.15
          l-0.26,0.3l-0.3,0.19L4059.01,977.58L4059.01,977.58z"/>
        <path id="path1191" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4011.43,958.22l-0.15-0.16l-0.04-0.16l0.02-0.21
          l0.04-0.17l0.09-0.29l0.15-0.13l0.53-0.3l1.44-2.03l0.04-0.15l0.12-0.34l0.44-0.95l0.09-0.17l0.26-0.36l0.3-0.36l0.29-0.25
          l0.3-0.21l1.42-0.7l0.15-0.05l0.16,0.02l0.15,0.12l0.02,0.16l-0.32,1.63l-0.49,1.24l-1.57,1.57l-0.53,0.47l-1.21,0.93l-0.49,0.34
          l-0.81,0.49l-0.17,0.02H4011.43L4011.43,958.22z"/>
        <path id="path1193" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4020.97,955.87l-0.12-0.02l-0.08-0.05l0.02-0.12
          l0.05-0.08l0.05-0.05l0.53-0.51l2.21-1.71l0.08-0.05l0.08,0.02l0.04,0.09l-0.04,1.1l-0.05,0.21l-0.23,0.57l-0.26,0.4l-0.08,0.05
          l-0.42,0.09l-0.83,0.08L4020.97,955.87L4020.97,955.87z"/>
        <path id="path1195" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4020.46,955.38l-0.61-0.21l-1.29-1.13l-0.09-0.16
          l0.05-0.16l0.16-0.05l0.15,0.02l1.84,0.62l0.3,0.12l0.15,0.09l0.09,0.16l0.04,0.17l-0.05,0.17l-0.12,0.16l-0.16,0.12l-0.15,0.08
          l-0.16,0.02h-0.15V955.38L4020.46,955.38z"/>
        <path id="path1197" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4016.52,959.34l-0.17-0.05l-0.17-0.12l-0.02-0.16
          l0.02-0.16l0.34-2.2l0.08-0.16l1.55-2.12l0.08-0.05l0.17,0.04l0.16,0.09l1.46,0.95l0.08,0.16l-0.02,0.15l-0.08,0.16l-0.17,0.12
          l-0.15,0.05l-0.4,0.04l-0.51,0.13l-0.19,0.08l-0.46,0.3l-0.29,0.49l-0.08,0.15l-0.3,0.93l-0.19,0.51l-0.13,0.3l-0.19,0.3
          l-0.15,0.08L4016.52,959.34L4016.52,959.34z"/>
        <path id="path1199" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4019.85,958.93l1.27-2.32l0.08-0.16l0.15-0.16
          l0.46-0.19l0.13-0.04l0.47-0.02l0.55,0.09l0.17,0.06l0.15,0.08l0.25,0.32l0.05,0.16l0.05,0.16l0.02,0.17l-0.02,0.19l-0.05,0.17
          l-0.08,0.16l-0.34,0.38l-0.26,0.26l-0.15,0.13l-0.3,0.25l-0.16,0.08l-2.49,1.1l-0.47,0.17L4019.85,958.93L4019.85,958.93z"/>
        <path id="path1201" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4017.58,959.47l-0.02-0.12l-0.02-0.12l0.02-0.65
          l0.05-0.21l0.08-0.17l0.17-0.23l0.23-0.17l1.06-0.76l0.74-0.44l0.61-0.3l0.29-0.12l0.09-0.04l0.08,0.02l-0.19,0.61l-0.04,0.09
          l-0.89,1.73l-0.05,0.08l-0.78,0.64l-0.17,0.09l-1.16,0.12L4017.58,959.47L4017.58,959.47z"/>
        <path id="path1203" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4011.21,959.28l-0.16-0.04l-0.15-0.13l-0.05-0.16
          l0.09-0.16l0.25-0.29l0.29-0.13l1.46-0.51l1-0.29l0.3-0.08l0.15,0.05l0.08,0.08l0.04,0.12v0.16l-0.06,0.16l-0.32,0.29l-0.46,0.25
          l-0.47,0.23l-0.17,0.05l-0.66,0.23l-0.61,0.16l-0.38,0.05h-0.17V959.28L4011.21,959.28L4011.21,959.28z"/>
        <path id="path1205" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4001.67,977.11l-0.17-0.04l-0.16-0.08l-0.19-0.13
          l-0.13-0.16l-0.05-0.16l-0.05-0.17l-0.02-0.17l0.05-0.16l0.09-0.17l0.34-0.47l0.49-0.76l0.22-0.42l0.09-0.19l0.08-0.19l0.04-0.32
          l-0.05-0.3l-0.15-0.47l-0.04-0.19l-0.02-0.17l0.05-0.66l0.02-0.15l0.05-0.16l0.16-0.12l0.15-0.08l0.49-0.08l0.64-0.02l0.33-0.04
          l1.31-0.4l0.15-0.09l0.47-0.32l0.33-0.36l0.23-0.32l0.19-0.53v-0.16v-0.17l-0.08-0.34l-0.08-0.16l-0.04-0.17l-0.05-0.34l-0.05-0.7
          l0.04-0.91l0.13-0.51l0.93-2.67l1.08-1.41l0.15-0.16l0.33-0.29l0.47-0.36l0.3-0.19l1.71-1.12l0.3-0.17l0.15-0.02l1.34-0.09
          l1.99-0.12l0.05,0.04l0.19,0.38l0.12,0.29l0.55,1.61l0.08,0.34l-0.04,0.17l-0.08,0.16l-0.66,1.29l-0.29,0.53l-0.15,0.3l-0.19,0.49
          l-0.4,1.34l-0.33,1.48l-0.05,0.3l-0.15,0.49l-0.19,0.23l-1.16,0.61l-0.17,0.19l-0.26,0.36l-0.21,0.32l-0.19,0.32l-0.26,0.49
          l-0.63,0.89l-0.32,0.32l-0.33,0.32l-0.3,0.25l-1.42,1l-2.24,1.54l-1.2,0.79l-0.99,0.65l-0.17,0.08l-0.15,0.04l-1.46,0.36
          l-0.36,0.04L4001.67,977.11L4001.67,977.11z"/>
        <path id="path1207" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4017.82,961.54l-0.43-0.09l-0.09-0.04l-0.12-0.16
          l-0.04-0.09l0.02-0.12l0.08-0.17l0.44-0.74l0.38-0.29l0.25-0.13l0.09-0.04l0.12-0.02l0.13,0.02l0.08,0.05l0.09,0.16l0.08,0.17
          l0.04,0.23l-0.02,0.23l-0.08,0.19l-0.23,0.3l-0.12,0.16l-0.26,0.26l-0.15,0.12l-0.12,0.02L4017.82,961.54L4017.82,961.54z"/>
        <path id="path1209" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4016.21,967.15l-0.05-0.17l0.02-0.17l0.22-1.13
          l0.66-2.11l0.17-0.55l0.15-0.32l0.16-0.3l0.13-0.17l2.01-1.5l0.15-0.09l1-0.49l0.42-0.19l0.16-0.05l0.3-0.09l0.43-0.02l0.36,0.13
          l0.08,0.16l0.04,0.16l0.02,0.17l-0.17,1.57l-0.06,0.19l-0.15,0.08l-0.3,0.13l-0.19,0.04l-0.47,0.09l-0.51,0.08l-0.17,0.08
          l-0.17,0.09l-0.15,0.12l-0.12,0.16l-0.23,0.3l-0.19,0.3l-1.27,1.82l-1.51,1.48l-0.47,0.4l-0.16,0.05L4016.21,967.15L4016.21,967.15
          z"/>
        <path id="path1211" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4081.68,961.83l-0.04-0.12l-0.08-0.3l-0.04-0.25
          l0.3-0.82l0.09-0.16l0.51-0.29l0.12-0.02l0.09,0.02l0.16,0.25l0.26,0.51l0.02,0.12l-0.12,1.41l-0.47,0.29l-0.55,0.12
          L4081.68,961.83L4081.68,961.83z"/>
        <path id="path1213" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4076.73,962.07l-0.02-0.16l0.17-0.53l0.09-0.16
          l0.47-0.13l0.19,0.05l0.44,0.25l0.17,0.12l0.15,0.12l0.13,0.17l-0.09,0.23l-0.13,0.16l-0.29,0.21l-0.61,0.46l-0.32,0.09
          L4076.73,962.07L4076.73,962.07z"/>
        <path id="path1215" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4020.1,965.11l-0.33-0.09l-0.15-0.09l-0.02-0.16
          l0.02-0.17l0.05-0.16l0.82-1.55l0.15-0.09l0.16-0.02l0.61-0.04l0.38,0.02l0.26,0.09l0.09,0.19l0.02,0.49l-0.02,0.16l-0.17,0.78
          l-0.13,0.08l-1.12,0.49l-0.16,0.05l-0.29,0.02H4020.1L4020.1,965.11L4020.1,965.11L4020.1,965.11z"/>
        <path id="path1217" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4011.42,972.1l-0.02-0.21l0.04-0.23l0.16-0.36
          l0.51-0.79l0.25-0.29l0.78-0.79l0.15-0.12l0.49-0.29l0.3-0.21l0.12-0.16l0.09-0.17l0.15-0.38l0.13-0.4l0.23-0.76l0.04-0.3
          l0.01-0.32l-0.01-0.16l-0.02-0.44l0.02-0.3l0.04-0.16l0.05-0.16l0.08-0.16l0.3,0.17l0.12,0.16l0.12,0.23l0.02,0.38l0.01,0.87
          l-0.01,0.38l-0.02,0.25l-0.09,0.44l-0.06,0.19l-0.93,2.73l-0.19,0.32l-0.12,0.15l-0.13,0.13l-0.3,0.21l-0.38,0.16l-1.24,0.32
          l-0.21,0.05l-0.23,0.02L4011.42,972.1L4011.42,972.1z"/>
        <path id="path1219" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4020.35,965.53l0.19,0.04l-0.09,0.15l-0.51,0.42
          l-0.87,0.12l-0.26,0.26l-0.12,0.72l0.08,0.47l-0.09,0.19l-0.26,0.26l-0.7,0.63l-1.67,0.89l-0.3,0.16l-0.17-0.02l-0.12-0.16
          l0.33-1.1l0.21-0.34l0.36-0.44l1.33-1.42l0.32-0.29l0.17-0.13l0.29-0.21l0.17-0.08l0.34-0.13l0.29-0.04l0.26,0.05H4020.35
          L4020.35,965.53z"/>
        <path id="path1221" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4104.18,968.43l-0.08-0.05l-0.21-0.3l-0.08-0.19
          l-0.02-0.12l0.02-0.25l0.02-0.12l0.17-0.47l0.05-0.08l0.25-0.29l0.91-0.78l0.12-0.04l0.21,0.17l0.05,0.08l0.25,2.04l0.01,0.12
          l-0.89,0.23l-0.23,0.04l-0.47,0.04L4104.18,968.43L4104.18,968.43z"/>
        <path id="path1223" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4068.9,972.16l-0.12-0.02l-0.49-0.17l-0.79-0.64
          l-0.15-0.12l-0.05-0.08l-0.02-0.12l0.02-0.12l0.96-1.38l0.08-0.08l0.09-0.04l0.22-0.04l0.86-0.05l0.23,0.02l0.19,0.04l0.08,0.05
          l0.05,0.08l0.15,0.76l0.02,0.65l-0.12,0.68l-0.01,0.12l-0.4,0.4l-0.15,0.12L4068.9,972.16L4068.9,972.16z"/>
        <path id="path1225" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M3991.87,978.08l-0.17-0.04l-0.36-0.19l-0.09-0.44
          l0.08-0.19l0.12-0.13l0.34-0.19l4.27-1.99l0.16,0.05l0.59,0.83l0.05,0.19l-0.15,0.09l-0.95,0.49l-0.7,0.36l-2.39,0.99l-0.15,0.05
          l-0.49,0.09L3991.87,978.08L3991.87,978.08L3991.87,978.08z"/>
        <path id="path1227" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4065.22,977.3l-0.25-0.4l-0.09-0.44l0.02-0.25
          l0.06-0.21l0.15-0.36l0.12-0.16l0.34-0.42l0.08-0.05l0.12,0.02l0.12,0.17l0.04,0.09l0.29,2.28l0.02,0.25l-0.04,0.21l-0.12,0.02
          L4065.22,977.3L4065.22,977.3z"/>
        <path id="path1229" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4063.35,977.11l-0.05-0.05l-0.38-0.46l0.21-0.44
          l0.47-0.89l0.05-0.05l0.13,0.05l0.25,0.29l0.61,0.96l-0.02,0.12l-0.49,0.68l-0.16,0.12L4063.35,977.11L4063.35,977.11z"/>
        <path id="path1231" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M3996.21,979.16l-0.02-0.16l-0.08-0.16l-0.15-0.16
          l0.74-1.21l2.58-1.08l0.16-0.02l0.15,0.02l0.49,0.17l0.17,0.09l0.3,0.21l0.15,0.12l0.93,0.86l-0.02,0.15l-0.09,0.16l-0.17,0.17
          l-0.15,0.09l-0.65,0.32l-0.53,0.21l-0.47,0.13l-0.74,0.17l-0.47,0.08l-0.64-0.16l-0.17,0.02l-0.51,0.23l-0.16,0.09l-0.16,0.13
          l-0.49,0.72V979.16L3996.21,979.16L3996.21,979.16L3996.21,979.16z"/>
        <path id="path1233" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4057.43,983.25l-0.12-0.02l-0.61-0.3l-0.08-0.05
          l-0.04-0.09l-0.38-1.2l0.01-0.12l0.66-1.34l0.16-0.23l0.29-0.36l0.13-0.13l0.09-0.04l0.95-0.32l0.21-0.04l0.25,0.02l0.05,0.05
          l0.02,0.12l-0.05,0.83l-0.83,2.56l-0.08,0.19l-0.13,0.26l-0.12,0.16l-0.19,0.08L4057.43,983.25L4057.43,983.25z"/>
        <path id="path1235" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4054.36,984.56l-0.09-0.02l-0.08-0.05l-0.04-0.09
          l0.01-0.12l0.08-0.19l0.99-1.74l0.05-0.08l0.16-0.12l0.55-0.34l0.12-0.02l0.08,0.05l0.21,0.91l0.26,0.38l0.36,0.04l-0.01,0.09
          l-0.3,0.61l-0.42,0.36l-0.08,0.05l-0.09,0.02l-0.7-0.25l-0.89,0.51L4054.36,984.56L4054.36,984.56z"/>
        <path id="path1237" fill={`${props.baseColor}`} stroke="#FFFFFF" strokeWidth="1.5" d="M4021.17,987.02l-0.61-0.44l-0.46-0.21l-0.51,0.4
          l-0.33,0.17l-0.91-0.34l-0.23-0.17l-1.17-1.2l-0.66-0.78l-0.08-0.17l-0.02-0.12l0.02-0.38l0.05-0.08l0.09-0.04l1.44,0.02l0.12,0.02
          l1.48,0.47l0.17,0.08l1.99,1.03l0.08,0.08l0.29,1.33l0.05,0.44L4021.17,987.02L4021.17,987.02z"/>
      </g>
    );
  }

  let info;
  if (props.total > 0) {
    info = (
      <Flex flexDirection="column">
        <Text>{props.name}</Text>
        <Text fontWeight="light">Total de empresas: {props.total}</Text>
        <Text fontWeight="light">Matrizes: {props.holding}</Text>
        <Text fontWeight="light">Filiais: {props.subsidiary}</Text>
      </Flex>
    );
  } else {
    info = null;
  }

  if (stateSelected) {
    return (
      <Tooltip hasArrow label={info} placement="auto">
        {stateSelected}
      </Tooltip>
    );
  } else {
    return null;
  }
}
