import { Button } from "@chakra-ui/react";
import { ReactElement, useEffect, useState } from "react";

interface IFormButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: Boolean;
  children: ReactElement | String;
  onClick?: any | undefined;
  width?: any;
  height?: any;
  isLoading?: boolean;
  color?: string;
  _hover?: any;
  textColor?: string;
  border?: string;
}

export const FormButton = (props: IFormButtonProps) => {

  const [ isDisabled, setDisabled ] = useState<boolean>(props.disabled as boolean);

  useEffect(() => {
    setDisabled(props.disabled as boolean);
  }, [props.disabled]);

  return (
    <Button
      type={props.type && "button"}
      borderRadius="4px"
      bgColor={props.color ? props.color : "#4B4EFF"}
      isLoading={props.isLoading ? props.isLoading : false}
      color={props.textColor ? props.textColor : "white"}
      _hover={
        props._hover ??
        { bgColor: "#686AFF", cursor: "pointer", color:"white" }
      }
      border={props.border ? props.border : "none"}
      h="32px"
      width={props.width}
      height={props.height}
      fontSize="12px"
      fontWeight={400}
      fontFamily="Poppins-medium"
      isDisabled={isDisabled}
      disabled={isDisabled}
      _disabled={{
        bgColor: "#D9D9D9",
        cursor: "not-allowed",
        "&:hover": {
          cursor: "not-allowed",
          bgColor: "#D9D9D9",
        },
      }}
      onClick={props.onClick}
    >
    {props.children}
  </Button>
  );
}