/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color?: string;
  className: any;
}

export const IconInfo = ({
  color = "#00A3C4",
  className,
}: Props): JSX.Element => {
  return (
    <svg
      className={`icons-info ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.99936 5.33333C7.90736 5.33333 7.83269 5.408 7.83336 5.5C7.83336 5.592 7.90802 5.66666 8.00002 5.66666C8.09202 5.66666 8.16669 5.592 8.16669 5.5C8.16669 5.408 8.09202 5.33333 7.99936 5.33333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="path"
        d="M8.00002 8V11.3333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconInfo.propTypes = {
  color: PropTypes.string,
};
