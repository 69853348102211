import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
  Text,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormButton } from '../../../../../components/login/button.component';
import { postAddCompany } from '../../../../../_services/company.service';
import { AddIcon } from '@chakra-ui/icons';
import MiniLoading from '../../../../../components/miniLoading';
import { IClientCustomFields } from '../../../../../_services/interface/client.interface';
import { TaxlyInput } from '../../../../../components/Input';
import SelectCustomField from '../../../../../components/SelectCustomField';
import { getClient } from '../../../../../_services/client.service';

interface ICompanyForeignCreateForm {
  name: string;
  taxid: string;
  type: string;
  companyCode: string;
}

interface ICreateForeignCompanyModalProps {
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  guid_client: string;
}

export const CreateForeignCompanyModal = (
  props: ICreateForeignCompanyModalProps
) => {
  const YupCompanyChangeForm = Yup.object().shape({
    name: Yup.string().required('Razão Social'),
    taxid: Yup.string().required('Tax ID'),
  });

  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };

  const [field1, setField1] = useState<string>();
  const [field2, setField2] = useState<string>();
  const [field3, setField3] = useState<string>();
  const [customFields, setCustomFields] = useState<IClientCustomFields[]>([]);
  const [field1Guid, setField1Guid] = useState<string>();
  const [field2Guid, setField2Guid] = useState<string>();
  const [field3Guid, setField3Guid] = useState<string>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm<ICompanyForeignCreateForm>(resolverForm);
  const toast = useToast();

  //Check Form Errors
  useEffect(() => {
    if (errors) {
      setAccErrorMessage(
        Object.values(errors).map((_x) => _x.message) as string[]
      );
    }
  }, [errors]);

  useEffect(() => {
    if (isOpen) {
      //Reset all the fields
      reset({ name: '', taxid: '', type: '2' });
      //Reset any error message
      setAccErrorMessage([]);
      setSimpleErrorMessage('');

      configCustomFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const configCustomFields = async () => {
    if (!props.guid_client) {
      return;
    }
    const { status, response } = await getClient(props.guid_client);
    if (status === 200) {
      if ('clientCustomFields' in response && response.clientCustomFields) {
        setCustomFields(response.clientCustomFields);

        if (response.clientCustomFields[0]) {
          setField1Guid(response.clientCustomFields[0].guid_custom_field);
        }

        if (response.clientCustomFields[1]) {
          setField2Guid(response.clientCustomFields[1].guid_custom_field);
        }

        if (response.clientCustomFields[2]) {
          setField3Guid(response.clientCustomFields[2].guid_custom_field);
        }
      }
    }
  };

  const handleFormInfo = async (data: ICompanyForeignCreateForm) => {
    setSubmitDisabled(true);
    setIsLoading(true);

    const { status } = await postAddCompany(props.guid_client, {
      name: data.name,
      cnpj: data.taxid,
      companyCode: data.companyCode ? data.companyCode : null,
      type: 2,
      searchCnd: false,
      isScheduled: false,
      field1: Array.isArray(field1) && field1[0] ? field1[0] : null,
      field2: Array.isArray(field2) && field2[0] ? field2[0] : null,
      field3: Array.isArray(field3) && field3[0] ? field3[0] : null,
      field1Guid: field1Guid,
      field2Guid: field2Guid,
      field3Guid: field3Guid,
      state: 1,
      city: 1,
      isForeign: true,
    });

    if (status === 200) {
      toast({
        title: 'Empresa criada com sucesso',
        description: `A empresa ${data.name} foi criada com sucesso`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      //Reset the entire form
      reset({
        name: '',
        taxid: '',
      });
      //Refresh the list
      props.flushHook(true);
      //Close the modal
      onClose();
    } else {
      toast({
        title: 'Falha ao criar a empresa',
        description: `A empresa ${data.name} não foi criada, motivo: CNPJ Duplicado.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      //Refresh the list
      props.flushHook(true);
      //Close the modal
      onClose();
    }
    setIsLoading(false);
    setSubmitDisabled(false);
  };

  return (
    <>
      <ButtonGroup onClick={onOpen} size='sm' isAttached color='white'>
        <Button
          leftIcon={<AddIcon />}
          bgColor='#4B4EFF'
          color='white'
          _hover={{ bg: '#282be0' }}
        >
          Criar empresa estrangeira
        </Button>
      </ButtonGroup>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size='3xl'
      >
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) '
          alignItems='center'
        />
        <ModalContent
          flexGrow={1}
          flexShrink={1}
          borderRadius={8}
          borderLeft='12px solid #0263FF'
        >
          <ModalHeader fontFamily='Poppins-Medium'>
            Cadastro de Empresa Estrangeira
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily='Poppins-Medium'>
            {accErrorMessage.length > 0 ? (
              <Flex
                bg='#FFCFCF'
                color='#A93333'
                alignItems='flex-start'
                flexDirection='column'
                fontSize='12px'
                p={2}
                borderRadius={5}
              >
                <Text>Por favor, informe os campos obrigatórios:</Text>
                {accErrorMessage.map((_x, _i) => (
                  <Flex key={_i}>{_x}</Flex>
                ))}
              </Flex>
            ) : null}
            {simpleErrorMessage ? (
              <Flex
                bg='#FFCFCF'
                color='#A93333'
                alignItems='flex-start'
                flexDirection='column'
                fontSize='12px'
                p={2}
                borderRadius={5}
              >
                <Flex>{simpleErrorMessage}</Flex>
              </Flex>
            ) : null}
            <Flex
              alignItems='stretch'
              direction='column'
              gap={2}
              flexGrow={1}
              pb={4}
            >
              <Flex mt='10px'>
                <TaxlyInput
                  placeholder='Razão Social'
                  label={'Razão Social'}
                  isRequired={true}
                  {...register('name', { required: true })}
                />
              </Flex>

              <Flex direction='row' gap={2} flexGrow={1} mt='10px'>
                <Flex width='50%' flexDirection='column'>
                  <TaxlyInput
                    placeholder='Digite para preencher'
                    label={'Tax ID'}
                    isRequired={true}
                    {...register('taxid', { required: true })}
                  />
                </Flex>

                <Flex width='25%' direction='column'>
                  <Flex
                    flexDirection='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    fontSize='12px'
                  >
                    Tipo
                    <span style={{ color: 'red' }}>*</span>
                  </Flex>

                  <Select
                    variant='outline'
                    borderRadius='4px'
                    border='1px solid #E2E8F0'
                    fontSize='12px'
                    _disabled={{ bg: '#E2E8F0', color: '#CBD5E0' }}
                    {...register('type')}
                    isDisabled={true}
                  >
                    <option value='2'>Estrangeira</option>
                  </Select>
                </Flex>

                <Flex width='25%'>
                  <TaxlyInput
                    placeholder='Código da Empresa'
                    label={'Código da Empresa'}
                    {...register('companyCode')}
                  />
                </Flex>
              </Flex>

              {customFields.length > 0 &&
              customFields.some((field) => field.isActive) ? (
                <Flex mt='10px'>
                  <Text
                    color='#171923'
                    fontSize='12px'
                    fontFamily='Poppins-Medium'
                  >
                    Campos personalizados
                  </Text>
                </Flex>
              ) : null}

              <Flex direction='row' gap={2} flexGrow={1} mt='10px'>
                {customFields[0] && customFields[0].isActive && (
                  <Flex width='33%'>
                    <SelectCustomField
                      setSelectedValue={setField1}
                      selectedValue={field1}
                      customField={customFields[0]}
                      guid_client={props.guid_client}
                      showLabel={true}
                    />
                  </Flex>
                )}

                {customFields[1] && customFields[1].isActive && (
                  <Flex width='33%'>
                    <SelectCustomField
                      setSelectedValue={setField2}
                      selectedValue={field2}
                      customField={customFields[1]}
                      guid_client={props.guid_client}
                      showLabel={true}
                    />
                  </Flex>
                )}

                {customFields[2] && customFields[2].isActive && (
                  <Flex width='33%'>
                    <SelectCustomField
                      setSelectedValue={setField3}
                      selectedValue={field3}
                      customField={customFields[2]}
                      guid_client={props.guid_client}
                      showLabel={true}
                    />
                  </Flex>
                )}
              </Flex>

              <Flex
                alignItems='stretch'
                direction='row'
                justifyContent='flex-end'
                gap={2}
                flexGrow={1}
                mt={4}
              >
                <FormButton
                  color='white'
                  onClick={onClose}
                  textColor='#4B4EFF'
                  border='1px solid #4B4EFF'
                >
                  Fechar
                </FormButton>

                <FormButton
                  onClick={handleSubmit(handleFormInfo)}
                  disabled={submitDisabled}
                >
                  {isLoading ? <MiniLoading size={20} /> : 'Cadastrar empresa'}
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
