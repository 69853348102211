import { Text } from "@chakra-ui/react";
import { useState, useRef } from "react";
import { default as ReactSelect, components, InputAction } from "react-select";

export type Option = {
  value: number | string;
  label: string;
};

const MultiSelectWithCheckbox = (props: any) => {
  const [selectInput, setSelectInput] = useState<string>("");
  const isAllSelected = useRef<boolean>(false);
  const selectAllLabel = useRef<string>("Selecionar todos");
  const allOption = { value: "*", label: selectAllLabel.current };

  const filterOptions = (options: Option[], input: string) =>
    options?.filter(({ label }: Option) =>
      label.toLowerCase().includes(input.toLowerCase())
    );

  const comparator = (v1: Option, v2: Option) =>
    (v1.value as number) - (v2.value as number);

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props: any) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: 'start' }}>
        {props.value === "*" &&
        !isAllSelected.current &&
        filteredSelectedOptions?.length > 0 ? (
          <input
            key={props.value}
            type="checkbox"
            ref={(input) => {
              if (input) input.indeterminate = true;
            }}
          />
        ) : (
          <input
            key={props.value}
            type="checkbox"
            checked={props.isSelected || isAllSelected.current}
            onChange={() => {}}
          />
        )}
        <label style={{ marginLeft: "5px", marginTop: "-2px" }}>
          {props.label}
        </label>
      </div>
    </components.Option>
  );

  const Input = (props: any) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
          {props.children}
        </components.Input>
      ) : (
        <div style={{ border: "1px dotted gray" }}>
          <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
            {props.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const customFilterOption = ({ value, label }: Option, input: string) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (
    inputValue: string,
    event: { action: InputAction }
  ) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected: Option[]) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }: Option) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter((opt: Option) => opt.label === label)
                .length === 0
          ),
        ].sort(comparator)
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
        JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else
      return props.onChange([
        ...props.value?.filter(
          ({ label }: Option) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
  };

  const customStyles = {
    multiValue: (def: []) => ({
      ...def,
      borderRadius: "15px",
      backgroundColor: "var(--primary-100)",
    }),
    multiValueLabel: (def: []) => ({
      ...def,
      fontSize: "70%",
      color: "var(--primary-500)",
      borderRadius: "15px",
    }),
    multiValueRemove: (def: []) => ({
      ...def,
      color: "var(--primary-500)",
      borderRadius: "0 15px 15px 0",
      borderLeft: "1px solid",
      borderColor: "var(--primary-200)",
    }),
    noOptionsMessage: (def: []) => ({
      ...def,
      fontSize: "12px",
    }),
    valueContainer: (base: []) => ({
      ...base,
      maxHeight: "65px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#bdbec196",
        borderRadius: "20px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#a5a9b7",
        borderRadius: "20px",
      },
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: "11px",
    }),
    input: (base: any) => ({
      ...base,
      fontSize: "14px",
    }),
    option: (styles: any, { isSelected, isFocused }: any) => {
      return {
        ...styles,
        fontSize: "12px",
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
            ? styles.backgroundColor
            : isFocused && isSelected
            ? "#DEEBFF"
            : null,
        color: isSelected ? null : null,
      };
    },
    menu: (def: any) => ({ ...def, zIndex: 9999 }),
    menuList: (def: any) => {
      return {
        ...def,
        maxHeight: "200px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#bdbec196",
          borderRadius: "20px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#a5a9b7",
          borderRadius: "20px",
        },
      };
    },
  };

  const textQtd = (props: any) => {
    return (
      <Text
        style={{
          paddingLeft: "5px",
          fontWeight: "500",
          fontSize: "14px",
          color: "var(--black-gray-gray-500)",
        }}
      >
        { props.value?.length ? (`${props.value?.length}/${props.options.length}`) : ('(0)')}
      </Text>
    );
  };

  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) ===
      JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `Todos (${filteredOptions.length}) selecionados`;
      else
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} Selecionados`;
    } else selectAllLabel.current = "Selecionar Todos";

    allOption.label = selectAllLabel.current;

    return (
      <>
        {props.label && (
          <Text
            color="#171923"
            fontFamily="Poppins-medium"
            fontSize="14px"
            fontWeight={500}
            paddingBottom="4px"
            display={"flex"}
          >
            {props.label}
            {textQtd(props)}
          </Text>
        )}

        <ReactSelect
          {...props}
          inputValue={selectInput}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          options={[allOption, ...props.options]}
          onChange={handleChange}
          components={{
            Option: Option,
            Input: Input,
            ...props.components,
          }}
          filterOption={customFilterOption}
          menuPlacement={props.menuPlacement ?? "auto"}
          styles={customStyles}
          noOptionsMessage={() => 'Nenhuma opção'}
          isMulti
          closeMenuOnSelect={false}
          tabSelectsValue={false}
          backspaceRemovesValue={true}
          hideSelectedOptions={false}
          blurInputOnSelect={false}
          placeholder="Digite para buscar"
        />
      </>
    );
  }

  return (
    <>
      {props.label && (
        <Text
          color="#171923"
          fontFamily="Poppins-medium"
          fontSize="14px"
          fontWeight={500}
          paddingBottom="4px"
          display={"flex"}
        >
          {props.label}
          {textQtd(props)}
        </Text>
      )}
      <ReactSelect
        {...props}
        inputValue={selectInput}
        onInputChange={onInputChange}
        filterOption={customFilterOption}
        components={{
          Input: Input,
          ...props.components,
        }}
        styles={customStyles}
        menuPlacement={props.menuPlacement ?? "auto"}
        onKeyDown={onKeyDown}
        tabSelectsValue={false}
        hideSelectedOptions={true}
        backspaceRemovesValue={false}
        noOptionsMessage={() => 'Nenhuma opção'}
        blurInputOnSelect={true}
        placeholder="Digite para buscar"
      />
    </>
  );
};

export default MultiSelectWithCheckbox;
