import { Flex, ButtonGroup, Button, TabPanel, useToast, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Tooltip, Image, Accordion, AccordionItem, AccordionButton, Box, Text, AccordionIcon, AccordionPanel, Checkbox } from "@chakra-ui/react"
import { ICompanyResponse, IResponse } from "../../../../../_services/interface/user.interface";
import { useEffect, useState } from "react";
import { downloadAllFiles } from "../../_services/kanban.service";
import { IFiles } from "../../interfaces/IFiles";
import moment from "moment";
import LoadingComponent from "../../../../../components/loading";
import { DownloadIcon } from "@chakra-ui/icons";
import { ViewPdfModal } from "../../../CNDManager/modals/ViewPdfModal";
import { FiltersFile } from "./FiltersFile";
import ReactPlaceholder from "react-placeholder/lib";

interface IFile {
  guid_client: string;
  allFiles: IFiles[];
  isLoading: boolean;
}

export const FileTabPanel = (props: IFile) => {
  const toast = useToast();

  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [allFiles, setAllFiles] = useState<IFiles[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<IFiles[]>([]);
  const [checkedFiles, setCheckedFiles] = useState<string[]>([]);

  useEffect(() => {
    setAllFiles(props.allFiles);
  }, [props.allFiles]);

  useEffect(() => {
    setLoadingState(props.isLoading);
  }, [props.isLoading]);

  const handleSelectAllFiles = (files: string[]) => {
    const isSubset = files.every((file) => checkedFiles.includes(file));

    if (isSubset) {
      setCheckedFiles((prevCheckedFiles) =>
        prevCheckedFiles.filter((file) => !files.includes(file))
      );
    } else {
      files.forEach((file) => {
        if (!checkedFiles.includes(file)) {
          setCheckedFiles((prevCheckedFiles) => [...prevCheckedFiles, file]);
        }
      });
    }
  }

  const handleSelectOneFile = (file: string) => {
    if (checkedFiles.includes(file)) {
      setCheckedFiles(checkedFiles.filter((item) => item !== file));
    } else {
      setCheckedFiles([...checkedFiles, file]);
    }
  }

  const handleDownload = async () => {
    toast({
      title: 'Baixando arquivos',
      description: 'Baixando os arquivos selecionados',
      status: 'loading',
      duration: 6000,
      isClosable: true
    });
    setCheckedFiles([])
    const response = await downloadAllFiles(props.guid_client, checkedFiles);

    const link = document.createElement('a');
    link.href = response;
    link.setAttribute('download', `${moment.utc().format('MMM-DD-YYYY-X')}.zip`);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(response);
  }

  return (
      <>
        <TabPanel> 
          <Flex flexDirection="row" justifyContent="flex-end" mb={4} >
            {checkedFiles.length === 0 ? (
              <Tooltip label="É necessário selecionar ao menos um arquivo.">
                <ButtonGroup size="sm" mt="24px" color="white">
                  <Button leftIcon={<DownloadIcon />} bgColor="#4B4EFF" color="white" isLoading={isLoading} isDisabled={true} _hover={{ bg: '#282be0' }}>
                    Baixar arquivos
                  </Button>
                </ButtonGroup>
              </Tooltip>
            ) : (
              <ButtonGroup size ="sm" mt="24px" color="white">
                <Button leftIcon={<DownloadIcon />} bgColor="#4B4EFF" color="white" isLoading={isLoading} isDisabled={!isLoading} _hover={{ bg: '#282be0' }} onClick={() => handleDownload()}>
                  Baixar arquivos
                </Button> 
              </ButtonGroup>
            )}
          </Flex>

          <Flex marginBottom="15px;" flexDirection="column" width="100%">
            <FiltersFile
              guid_client={props.guid_client} 
              setFilteredFiles={setFilteredFiles}
              allFiles={allFiles}
            />
          </Flex>

          { 
            isLoading ? (
              Array.from({ length: 10 }, (_, index) => (
                <Accordion allowToggle>
                  <AccordionItem>  
                    <h2> 
                      <AccordionButton bg={index % 2 === 0 ? "#edf2f7" : "white"} p={4}>
                        <Box as="span" flex="1" textAlign="left">
                          <Flex alignItems="center" position="relative">
                            <Flex pl={4}>
                              <ReactPlaceholder showLoadingAnimation style={{width: Math.floor(Math.random() * (250 - 100 + 1)) + 100}}  type='text' ready={false} rows={1}>
                                <Text fontFamily="Poppins-Medium" fontSize={12}>1</Text>
                              </ReactPlaceholder>
                            </Flex>
                          </Flex>
                        </Box>
                        <Text fontFamily="Poppins-Medium" fontSize={14} mr={2}>
                          <ReactPlaceholder showLoadingAnimation  type='text' ready={false} rows={1}>
                            <Text fontFamily="Poppins-Medium" fontSize={12}>1</Text>
                          </ReactPlaceholder> arquivos
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                  </AccordionItem>
                </Accordion>
              ))
            )
            : filteredFiles.length > 0 ? (
            filteredFiles.map((file, key) => {
              return ( 
                <>
                  <Accordion allowToggle>
                    <AccordionItem>  
                      <h2> 
                        <AccordionButton bg={key % 2 === 0 ? "#edf2f7" : "white"} p={4}>
                          <Box as="span" flex="1" textAlign="left">
                            <Flex alignItems="center" position="relative">
                              <Flex pl={4}>
                                <Text fontFamily="Poppins-Medium" fontSize={12}>{file.company.name}</Text>
                              </Flex>
                            </Flex>

                          </Box>
                          <Text fontFamily="Poppins-Medium" fontSize={14} mr={2}>{file.files.length} arquivos</Text>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        {file.files.length > 0 ? (
                          <TableContainer backgroundColor="white" fontFamily="Poppins-medium" flexGrow="1" flexShrink="1" border="1px" borderColor="gray.200" borderRadius="6px">
                            <Table variant='striped' colorScheme='gray' fontSize="12px" borderRadius={5}>
                              <Thead>
                                <Tr>
                                  <Th fontFamily="Poppins-Medium">
                                    <Checkbox size='md' colorScheme='green'
                                      onChange={() => handleSelectAllFiles(file.files.map((item, _key) => item.guid_file))} />
                                  </Th>
                                  <Th fontFamily="Poppins-Medium" >Nome do arquivo</Th>
                                  <Th fontFamily="Poppins-Medium">Card relacionado</Th>
                                  <Th fontFamily="Poppins-Medium">Data de upload</Th>
                                  <Th fontFamily="Poppins-Medium">Arquivo</Th>
                                </Tr>
                              </Thead>
                              <Tbody alignItems="center" justifyContent="center">
                                {file.files.map((_file, _key) => {
                                  return (
                                    <Tr>
                                      <Td>
                                        <Checkbox size='md' colorScheme='green'
                                          isChecked={checkedFiles.includes(_file.guid_file)}
                                          onChange={() => handleSelectOneFile(_file.guid_file)} />
                                      </Td>
                                      <Td>{_file.filename}</Td>
                                      <Td style={{
                                        maxWidth: "500px",
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}>{_file.card.name}</Td>
                                      <Td>{moment.utc(_file.createdAt).format("DD/MM/YYYY")}</Td>
                                      <Td>
                                        <ViewPdfModal title="Visualizar Arquivo" file={_file.address} />
                                      </Td>
                                    </Tr>
                                  )
                                })}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        ) :
                          (
                            <Text textAlign="center" pl={2}>Não há nenhum arquivo para essa empresa</Text>
                          )
                        }
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </>
              )
            })
          ) : (
            <Text textAlign="center" pl={2}>Nenhum arquivo encontrado com o filtro aplicado</Text>
          )}
        </TabPanel>
      </>
  );
}
