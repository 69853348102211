import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/dashboard";
import { Logout } from "./pages/dashboard/Users/modais/Logout";
import Login from "./pages/Login";
import { CompleteRegistration } from './pages/Login/completeRegistration/CompleteRegistration';
import PrivateSignUp from "./pages/privateSignUp";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="complete-registration/:guid_user" element={<CompleteRegistration />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="logout" element={<Logout />} />
          <Route path="private-signup" element={<PrivateSignUp />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
