import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { postLoginSSO } from "../../../_services/auth.service";
import useLoginToken from "../../../store/login.store";
import { useCookies } from "react-cookie";
import { ILoginResponse } from "../../../_services/interface/login.interface";
import { SimpleFormError } from "../../../components/login/formError.component";
import { IErrorResponse } from "../../../_services/interface/IDefaults";


export const LoginFormSSO = () => {
  const [cookies, setCookie] = useCookies(["token"]);
  const { setToken } = useLoginToken();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [smplErrorMessage, setSmplErrorMessage] = useState<string>();
  const [logged, setLogged] = useState(false)

  const handleLogin = async (loginType: string) => {
    let isLogged: any
    if (loginType === "popup") {
      isLogged = await instance.loginPopup(loginRequest).catch(e => {
        // console.log(e);
      });
    }
    if(isLogged?.accessToken) setLogged(true)

  }

  useEffect(() => {
    //Check if the person is already logged and send this person to the dashboard
    if (cookies.token) {
      navigate('/dashboard');
    }
    const handleAuth = async () => {
      if (logged && (accounts[0] && accounts[0].username && accounts[0].name)) {
        const applicationId = accounts[0]?.idTokenClaims?.aud || "TAXLY_123"
        const token = generateToken(accounts[0].username, applicationId)
        const { status, response } = await postLoginSSO({ email: accounts[0].username, name: accounts[0].name, clientSideToken: token })
        if (status === 200) {
          const theToken = (response as ILoginResponse).accessToken;
          setToken(theToken);
          setCookie("token", theToken, { path: "/", sameSite: "lax" });
          navigate('/dashboard');
        } else {
          const err = (response as IErrorResponse).message;
          switch (err) {
            case 'LOGIN_USER_NOT_FOUND':
              setSmplErrorMessage('Usuário inválido ou não encontrado');
              break;
            case 'LOGIN_USER_INACTIVE':
              setSmplErrorMessage('Usuário inválido ou não encontrado');
              break;
            default:
              setSmplErrorMessage('Algo aconteceu. Tente novamente por favor.');
              break;
          }
          setLogged(false)
        }
      }
    }
    handleAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged])

  return (


    <Flex display="flex" fontFamily="Poppins-Medium" alignItems="center" width={369}>
      <Flex direction="column" w="100%">
        <Text fontSize="32px" textAlign="center">
          Login Statkraft
        </Text>
        <Box marginTop="50px">
          {smplErrorMessage ? (<SimpleFormError msg={smplErrorMessage} />) : null}
          <Flex direction="column" gap="16px" alignItems="stretch">
            <Button background="#4B4EFF" color="white" onClick={() => handleLogin("popup")}>Entre usando sua conta Microsoft</Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );

  function generateToken(email: string, appId: string): number {
    let emailSum = 0;
    let appIdSum = 0;
    for (let i = 0; i < email.length; i++) {
      emailSum += email.charCodeAt(i) * i+1;
    }
    for (let i = 0; i < appId.length; i++) {
      appIdSum += appId.charCodeAt(i) * i+1;
    }
    return emailSum * appIdSum;
  }
}