import { IStatus } from "../../../../_services/interface/certificate.interface";
import { ITaxCurrentAccountListData } from "../../../../_services/interface/taxCurrentAccount.interface";
import { ITag, ITagMultiTootlip } from "../../../../components/Table/table.interface";

export const taxCurrentAccountStatusForTagList = (taxCurrentAccount: ITaxCurrentAccountListData) => {
  const status = taxCurrentAccount.status as IStatus;

  if(!status) {
    return {
      text: "-",
      color: "#000",
      textColor: "#000",
      minWidth: "118px",
    } as ITag;
  }

  let tooltip = {};
  const tooltipIcon: ITagMultiTootlip[] = [];

  if(status.tooltip_icon) {
    tooltipIcon.push({
      tootltipIconText: status.tooltip_text as string,
      tootltipIconUrl: `../icons/${status.tooltip_icon}.svg`
    })
  }

  if (taxCurrentAccount.errorMessage) {
    tooltipIcon.push({
      tootltipIconText: taxCurrentAccount.errorMessage,
      tootltipIconUrl: "/icons/alert.svg"
    })
  }

  return {
    text: status.name,
    color: status.color,
    textColor: status.textColor ?? "#000",
    minWidth: "118px",
    tooltip: tooltipIcon,
  } as ITag;
};
