import {
  Flex,
  Image,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { DashInputMask } from "../../../Users/components/DashInput";
import { ptBR } from "date-fns/locale";
import moment from "moment";
import "../../../../../styles/components/customStyle.css";
import { EFrequencyType } from "../../../../../_services/enum/irs.enum";
import DatePickerRecurrencyCustom from "./DatePickerRecurrencyCustom";
import { ModalConfirm } from '../../../../../modal/ModalConfirm'

const customPtBR: Locale = {
  ...ptBR,
  localize: {
    ...ptBR.localize,
    ordinalNumber: ptBR.localize?.ordinalNumber || (() => ''),
    era: ptBR.localize?.era || (() => ''),
    quarter: ptBR.localize?.quarter || (() => ''),
    month: ptBR.localize?.month || (() => ''),
    day: (day: any, _options: any) => ptBR.localize?.day(day, _options)?.charAt(0) || '',
    dayPeriod: ptBR.localize?.dayPeriod || (() => ''),
  },
};

enum StepShowEnum {
  DATE = "DATE",
  RECURRENCY = "RECURRENCY",
}

interface DatePickerWithOptionsProps {
  selectedDay?: Date;
  setSelectedDay: (date: Date | undefined) => void;
  frequencyType: EFrequencyType | null;
  setFrequencyType: (frequencyType: EFrequencyType | null) => void;
  frequencyUnit: number | null;
  setFrequencyUnit: (frequencyUnit: number | null) => void;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  showOnlyFrequency?: boolean
}

const DatePickerWithOptions = (props: DatePickerWithOptionsProps) => {
  const [onOpen, setOpen] = useState<boolean>(false);

  const [stepShow, setStepShow] = useState<StepShowEnum>(StepShowEnum.DATE);

  const [selectedDay, setSelectedDay] = useState<Date | undefined>(props.selectedDay);

  const [month, setMonth] = useState<Date | undefined>(props.selectedDay);
  const [frequencyType, setFrequencyType] = useState<EFrequencyType | null>(null);
  const [frequencyUnit, setFrequencyUnit] = useState<number | null>(null);
  const [recurrency, setRecurrency] = useState<string | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  const [isOpenModalRemoveRecurrency, setIsOpenModalRemoveRecurrency] = useState<boolean>(false);

  useEffect(() => {
    setFrequencyType(props.frequencyType);
  }, [props.frequencyType])

  useEffect(() => {
    setFrequencyUnit(props.frequencyUnit);
  }, [props.frequencyUnit])

  useEffect(() => {
    if (props.showOnlyFrequency) {
      setStepShow(StepShowEnum.RECURRENCY);
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    setSelectedDay(props.selectedDay);
  }, [props.selectedDay]);

  const isRecurrencyDay = (day: any) => {
    return loadRecurrencyDays(month).some(
      (recurrencyDay) =>
        day.getDate() === recurrencyDay.getDate() &&
        day.getMonth() === recurrencyDay.getMonth() &&
        day.getFullYear() === recurrencyDay.getFullYear()
    );
  };

  const handleSelectDay = (date: Date | undefined) => {
    setSelectedDay(date);
    props.setSelectedDay(date);
    setMonth(date);
  };

  const getDayItem = (text: string, daysDiff: number) => {
    const date = new Date();
    date.setDate(date.getDate() + daysDiff);

    return (
      <ListItem
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
          padding: "8px",
          whiteSpace: "nowrap",
        }}
        onClick={() => handleSelectDay(date)}
        fontFamily="Poppins-Medium"
        fontSize="12px"
        _hover={{
          backgroundColor: "#ECF1FF",
        }}
      >
        {text}

        <span style={{ color: "var(--black-gray-gray-400, #A0AEC0)" }}>
          {date.toLocaleDateString("pt-BR", { month: "short", day: "2-digit" })}
        </span>
      </ListItem>
    );
  };

  const handleSelectRecurrency = (type: EFrequencyType, unit: number) => {
    setFrequencyType(type);
    props.setFrequencyType(type);

    setFrequencyUnit(unit);
    props.setFrequencyUnit(unit);
  };

  const getRecurrencyItem = (
    text: string,
    type: EFrequencyType,
    unit: number
  ) => {
    return (
      <ListItem
        _hover={{
          backgroundColor: "#ECF1FF",
        }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
          padding: "8px 12px 8px 12px",
          color: type === props.frequencyType && unit === props.frequencyUnit ? '#4B4EFF' : '#171923'
        }}
        onClick={() => handleSelectRecurrency(type, unit)}
      >
        {text}
      </ListItem>
    );
  };

  const showPersonalizedRecurrency = () => {
    return (
      (!!frequencyType && !!frequencyUnit) &&
      !(frequencyType === EFrequencyType.DAY && frequencyUnit === 1) &&
      !(frequencyType === EFrequencyType.WEEK && frequencyUnit === 1) &&
      !(frequencyType === EFrequencyType.WEEK && frequencyUnit === 2) &&
      !(frequencyType === EFrequencyType.MONTH && frequencyUnit === 1) &&
      !(frequencyType === EFrequencyType.YEAR && frequencyUnit === 1)
    );
  };

  const getMomentType = (type: EFrequencyType) => {
    if (type === EFrequencyType.DAY) {
      return "days";
    } else if (type === EFrequencyType.WEEK) {
      return "weeks";
    } else if (type === EFrequencyType.MONTH) {
      return "months";
    } else if (type === EFrequencyType.YEAR) {
      return "years";
    }
  };

  const translateFrequencyType = (type: EFrequencyType, unit: number|null): string => {
    let singularText = '';
    let pluralText = ''
    switch (type) {
      case EFrequencyType.DAY:
        singularText = 'Diário';
        pluralText = 'Dias';
        break;

      case EFrequencyType.WEEK:
        singularText = 'Semanal';
        pluralText = 'Semanas';
        break;

      case EFrequencyType.MONTH:
        singularText = 'Mensal';
        pluralText = 'Meses';
        break;

      case EFrequencyType.YEAR:
        singularText = 'Anual';
        pluralText = 'Anos';
        break;
    }

    if(unit === null || unit === 1) {
      return singularText;
    }

    return `${unit} ${pluralText}`;
  }

  const loadRecurrencyDays = (month: Date | undefined): Date[] => {
    if (month && frequencyType && frequencyUnit && selectedDay) {
      const dateReference = moment(month)
        .add(15, "days")
        .add(1, "months")
        .toDate();

      const recurrencyDays: Date[] = [];

      while (
        recurrencyDays.length === 0 ||
        recurrencyDays[recurrencyDays.length - 1] < dateReference
      ) {
        const dateToCalc =
          recurrencyDays.length === 0
            ? selectedDay
            : recurrencyDays[recurrencyDays.length - 1];
        recurrencyDays.push(
          moment(dateToCalc)
            .add(
              frequencyUnit,
              getMomentType(frequencyType as EFrequencyType)
            )
            .toDate()
        );
      }

      return recurrencyDays;
    }

    return [];
  };

  const removeRecurrency = () => {
    props.setFrequencyType(null);
    props.setFrequencyUnit(null);
  };

  useEffect(() => {
    if (!frequencyType || !frequencyUnit) {
      setRecurrency(null);
      return;
    }

    if (frequencyType === EFrequencyType.DAY && frequencyUnit === 1) {
      setRecurrency("Diário");
    } else if (frequencyType === EFrequencyType.WEEK && frequencyUnit === 1) {
      setRecurrency("Semanal");
    } else if (frequencyType === EFrequencyType.MONTH && frequencyUnit === 1) {
      setRecurrency("Mensal");
    } else if (frequencyType === EFrequencyType.YEAR && frequencyUnit === 1) {
      setRecurrency("Anual");
    } else {
      setRecurrency("Personalizado");
    }
  }, [frequencyType, frequencyUnit]);

  return (
    <div ref={ref}>
      <InputGroup>
        <Flex flexDirection="column" w="100%" onFocus={() => setOpen(true)}>
          <InputLeftElement
            height={8}
            pointerEvents="none"
            children={<Image src="/icons/calender.svg" />}
            style={{ cursor: 'pointer'}}
          />

          <DashInputMask
            id={props.id}
            disabled={props.disabled}
            pl="40px"
            type="text"
            isReadOnly
            style={{ cursor: 'pointer'}}
            border="none"
            borderBottom="none"
            borderRadius="3px"
            height="32px"
            placeholder={props.placeholder}
            fontSize="12px"
            value={props.frequencyType && props.showOnlyFrequency ? translateFrequencyType(props.frequencyType, props.frequencyUnit) : selectedDay?.toLocaleDateString("pt-BR") ?? ''}
            position={"relative"}
            {...(!props.showOnlyFrequency && { mask: "99/99/9999" })}
          />
        </Flex>
      </InputGroup>
      
      <Flex
        display={onOpen ? "flex" : "none"}
        position={"absolute"}
        z-Index={9999999}
        style={{
          backgroundColor: "white",
          border: "1px solid var(--chakra-colors-chakra-border-color)",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "21px",
        }}
        fontStyle={"Poppins"}
      >
        <Flex
          display={stepShow === StepShowEnum.DATE ? "flex" : "none"}
          w={"215px"}
          direction={"column"}
          borderRight={"1px solid var(--chakra-colors-chakra-border-color)"}
        >
          <Flex
            display={frequencyType && frequencyUnit ? "flex" : "none"}
            borderBottom={"1px solid var(--chakra-colors-chakra-border-color)"}
            justifyContent={"space-between"}
            fontFamily="Poppins-Medium"
            alignItems={"center"}
            style={{ cursor: "pointer" }}
            onClick={() => setIsOpenModalRemoveRecurrency(true)}
            h={"16%"}
            w={"100%"}
            p={"0 8px"}
            fontSize={12}
            _hover={{
              backgroundColor: "#ECF1FF",
            }}
          >
            <Flex>Remover repetição</Flex>

            <Flex>
              <Image src="/icons/x.svg" />
            </Flex>
          </Flex>

          <List
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-evenly"}
            p={"0"}
            h={"84%"}
            w={"100%"}
            borderBottom={"1px solid var(--chakra-colors-chakra-border-color)"}
          >
            {getDayItem("Amanhã", 1)}
            {getDayItem("Próxima semana", 7)}
            {getDayItem("Em duas semanas", 14)}
            {getDayItem("Em quatro semanas", 28)}
            {getDayItem("Em seis semanas", 42)}
          </List>

          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            h={"16%"}
            w={"100%"}
            p={"0 8px"}
            onClick={() => setStepShow(StepShowEnum.RECURRENCY)}
            style={{ cursor: "pointer" }}
            _hover={{
              backgroundColor: "#ECF1FF",
            }}
          >
            <Flex
              color={recurrency ? '#4B4EFF' : '#171923'}
              fontFamily="Poppins-Medium"
              fontSize={12}
            >
              <Image
                src={`/icons/${recurrency ? 'repeat-blue.svg' : 'repeat.svg'}`}
                paddingRight={"5px"}
              />

              {recurrency ?? "Repetir"}
            </Flex>

            <Flex>
              <Image src="/icons/arrow-chevron-black.svg" />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          display={stepShow === StepShowEnum.RECURRENCY ? "flex" : "none"}
          w={"215px"}
          direction={"column"}
          borderRight={"1px solid var(--chakra-colors-chakra-border-color)"}
          fontFamily="Poppins-Medium"
          fontSize="12px"
        >
          {!props.showOnlyFrequency && (
            <Flex
              borderBottom={"1px solid var(--chakra-colors-chakra-border-color)"}
              justifyContent={"space-between"}
              alignItems={"center"}
              h={"16%"}
              w={"100%"}
              p={"0 12px 0 12px"}
              style={{ cursor: "pointer" }}
              onClick={() => setStepShow(StepShowEnum.DATE)}
              _hover={{
                backgroundColor: "#ECF1FF",
              }}
            >
              <Flex>
                <Flex paddingRight={2}>
                  <img src="/icons/back.svg"  alt="Voltar" />
                </Flex>

                voltar
              </Flex>


              <Flex>
                <Image src="/icons/x.svg" />
              </Flex>
            </Flex>
          )}

          <Flex
            display={frequencyType && frequencyUnit ? "flex" : "none"}
            borderBottom={"1px solid var(--chakra-colors-chakra-border-color)"}
            justifyContent={"space-between"}
            alignItems={"center"}
            h={"16%"}
            w={"100%"}
            p={"0 12px 0 12px"}
            style={{ cursor: "pointer" }}
            onClick={() => setIsOpenModalRemoveRecurrency(true)}
            _hover={{
              backgroundColor: "#ECF1FF",
            }}
          >
            <Flex>
              Remover repetição
            </Flex>

            <Flex>
              <Image src="/icons/x.svg" />
            </Flex>
          </Flex>

          <ModalConfirm
            title={'Remover recorrência'}
            description={'Salvar esta alteração excluirá todas as tarefas desta recorrência, exceto essa tarefa selecionada.'}
            isOpen={isOpenModalRemoveRecurrency}
            setIsOpen={setIsOpenModalRemoveRecurrency}
            callback={() => removeRecurrency()}
            style={'delete'}
            buttonConfirmText={'Remover recorrência'}
          />

          <List
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-evenly"}
            p={"0"}
            h={"84%"}
            w={"100%"}
            mb={0}
            borderBottom={"1px solid var(--chakra-colors-chakra-border-color)"}
          >
            {getRecurrencyItem("Todo dia", EFrequencyType.DAY, 1)}
            {getRecurrencyItem("Toda semana", EFrequencyType.WEEK, 1)}
            {getRecurrencyItem(
              "A cada duas semanas",
              EFrequencyType.WEEK,
              2
            )}
            {getRecurrencyItem("A cada mês", EFrequencyType.MONTH, 1)}
            {getRecurrencyItem("A cada ano", EFrequencyType.YEAR, 1)}
          </List>

          <Flex
            alignItems={"center"}
            h={"16%"}
            w={"100%"}
            p={"0 12px 0 12px"}
            _hover={{
              backgroundColor: "#ECF1FF",
            }}
          >
            <DatePickerRecurrencyCustom 
              frequencyType={frequencyType}
              frequencyUnit={frequencyUnit}
              showSelected={showPersonalizedRecurrency()}
              callback={(type, unit) => handleSelectRecurrency(type, unit)}
            />
          </Flex>
        </Flex>

        {!props.showOnlyFrequency && (
          <DayPicker
            mode="single"
            showOutsideDays={true}
            fixedWeeks={true}
            selected={selectedDay}
            locale={customPtBR}
            onSelect={(date) => handleSelectDay(date)}
            modifiers={{
              recurrency: isRecurrencyDay,
            }}
            modifiersClassNames={{
              recurrency: "recurrency-day",
            }}
            disabled={{ before: moment().utc().toDate() }}
            onMonthChange={(month) => setMonth(month)}
            month={month}
          />
        )}
      </Flex>
    </div>
  );
};

export default DatePickerWithOptions;
