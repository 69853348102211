// Implement a hook that can be used throughout your React app:
import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import useLoginFormStore from '../store/useLoginFormStore';

export function useJune() {
  const { validate } = useLoginFormStore();
  const [analytics, setAnalytics] = useState<AnalyticsBrowser|undefined>(undefined);

  useEffect(() => {
    const loadAnalytics = async () => {
      if(!process.env.REACT_APP_JUNE_WRITE_KEY) {
        return analytics;
      }

      let response = AnalyticsBrowser.load({ 
        writeKey: process.env.REACT_APP_JUNE_WRITE_KEY
      });

      setAnalytics(response);
    };
    
    loadAnalytics();
  }, []);

  if(analytics && validate?.guid_user) {
    analytics.identify(validate.guid_user)
  }

  return analytics;
}