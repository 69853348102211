import { InfoIcon } from "@chakra-ui/icons";
import { useDisclosure, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, Text, Image } from "@chakra-ui/react";
import { FormButton } from "../../../../components/login/button.component";

export const BulkObligationExplanation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  //

  const modelDownload = () => {
    const link = document.createElement('a');
    link.href = `https://taxly-examples.s3.us-east-2.amazonaws.com/modelo-planilha-agendamento.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <Tooltip label="Clique para instruções sobre como é realizado o upload de obrigações em lote no sistema">
        <Image 
          _hover={{ cursor: 'pointer' }}
          onClick={onOpen}
          height={5}
          width={5}
          src="/icons/information-circle-blue.svg" 
          resize="none"
        />
      </Tooltip>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Obrigações em Lote</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={4}>
              <Text>
                O <strong>Taxly</strong> permite o upload de obrigações em lote, para isso será necessário criar um arquivo excel com pelo 
                menos três colunas, cada uma delas representando respectivamente o dia (opcional), o código darf (caso tenha) e uma descrição da obrigação 
                que está sendo inserida. Como na imagem abaixo:
              </Text>
              <Flex border="1px solid" borderColor="gray.400" borderRadius={5} overflow="hidden">
                <Image src="images/obligation-info.png" />
              </Flex>
              <Text>Após o upload o sistema irá preencher a tabela de agendamento.</Text>
              <Text color="gray.500">* Caso o dia seja especificado, o sistema irá tratar a obrigação como dia único, no entanto é possível alterar as regras.</Text>
              <Text color="gray.500">* O sistema sempre irá interpretar a primeira linha como "títulos" e portanto, será sempre ignorada.</Text>
              <Text color="gray.500">* A definição das regras para a conduta do sistema ainda precisa ser definida manualmente após o upload</Text>
              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <FormButton onClick={modelDownload} width="50%">Baixe um modelo</FormButton>
                <FormButton onClick={onClose} width="50%">Fechar</FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}