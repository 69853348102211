import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import Chart from "react-google-charts";
import { ICNDListData } from "../../../../_services/interface/cnd.interface";
import { ICardList } from "../../TaxKanban/interfaces/ICardList";
import { IWidgetIdent } from "../interfaces/IWidget";
import { WidgetBase } from "./WidgetBase";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { RectShape } from "react-placeholder/lib/placeholders";

interface ICndPieWidget extends IWidgetIdent {
    data?: ICNDListData[] | ICardList[];
    isLoading?: boolean;
    currentClient?: IClientDataResponse;
    certificates?: ICertificate[];
}

export const CertBar = (props: ICndPieWidget) => {
    const cndData = props.data ? props.data as ICNDListData[] : [];
    if (props.id && props.index !== undefined && props.currentClient) {
        
        const { cnd, cndt, fgts, sintegra, ctm } = props.currentClient.products;
        
        const refreshBarData = (cndData: ICNDListData[]) => {
            const months = [
                { month: 1, name: "Jan" },
                { month: 2, name: "Fev" },
                { month: 3, name: "Mar" },
                { month: 4, name: "Abr" },
                { month: 5, name: "Mai" },
                { month: 6, name: "Jun" },
                { month: 7, name: "Jul" },
                { month: 8, name: "Ago" },
                { month: 9, name: "Set" },
                { month: 10, name: "Out" },
                { month: 11, name: "Nov" },
                { month: 12, name: "Dez" },
            ];

            //Get the current month
            const currentMonth = parseInt(moment.utc().format("M"));
            //get the current year
            const currentYear = parseInt(moment.utc().format('YYYY'));
            const baseObject: any[] = [];
            let yearStart = currentYear;
            let monthStart = currentMonth - 1;
            for (let i = 1; i < 13; i++) {
                if (monthStart === 12) {
                    monthStart = 1;
                    yearStart++;
                } else {
                    monthStart++;
                }

                baseObject.push({
                    month: monthStart,
                    // eslint-disable-next-line no-loop-func
                    name: months.find((x) => x.month === monthStart)?.name,
                    year: yearStart,
                    cndcount: 0,
                    cndtcount: 0,
                    fgtscount: 0,
                    sintegracount: 0,
                    ctmcount: 0,
                })
            }

            cndData.forEach((_x: ICNDListData) => {
                //TODO Verificar porque aqui está dando erro. Não deveria haver nenhum valor undefined
                if (_x.validUntil) {
                    const base = baseObject.find((y) => y.month === parseInt(moment.utc(_x.validUntil).format("M")) && y.year === parseInt(moment.utc(_x.validUntil).format("YYYY")));
                    if (base) {
                        switch (_x.slug.split('_')[0]) {
                            case 'cnd':
                                baseObject.find((y) => y.month === parseInt(moment.utc(_x.validUntil).format("M")) && y.year === parseInt(moment.utc(_x.validUntil).format("YYYY"))).cndcount++;
                                break;
                            case 'cndt':
                                baseObject.find((y) => y.month === parseInt(moment.utc(_x.validUntil).format("M")) && y.year === parseInt(moment.utc(_x.validUntil).format("YYYY"))).cndtcount++;
                                break;
                            case 'fgts':
                                baseObject.find((y) => y.month === parseInt(moment.utc(_x.validUntil).format("M")) && y.year === parseInt(moment.utc(_x.validUntil).format("YYYY"))).fgtscount++;
                                break;
                            case 'sintegra':
                                baseObject.find((y) => y.month === parseInt(moment.utc(_x.validUntil).format("M")) && y.year === parseInt(moment.utc(_x.validUntil).format("YYYY"))).sintegracount++;
                                break;
                            case 'ctm':
                                baseObject.find((y) => y.month === parseInt(moment.utc(_x.validUntil).format("M")) && y.year === parseInt(moment.utc(_x.validUntil).format("YYYY"))).ctmcount++;
                                break;
                        }
                    }
                }
            });

            //this part of code search in the array "baseObject" for this and the 5 next's months
            let sixMonthsForNow = baseObject
                .slice(
                    baseObject.indexOf(baseObject.find((y) => y.month === parseInt(moment.utc().format("M")) && y.year === parseInt(moment.utc().format("YYYY")))),
                    baseObject.indexOf(baseObject.find((y) => y.month === parseInt(moment.utc().format("M")) + 7 && y.year === parseInt(moment.utc().format("YYYY"))))
                )
            const finalData: any[] = [];
            finalData.push(["Mês"])

            if (cnd) finalData[0].push("CND");
            if (cndt) finalData[0].push("CNDT");
            if (fgts) finalData[0].push("FGTS");
            if (sintegra) finalData[0].push("SINTEGRA");
            if (ctm) finalData[0].push("CTM");

            sixMonthsForNow.forEach(_x => {
                const row = [`${_x.name}/${_x.year.toString().substring(2, 4)}`];

                if (cnd) row.push(_x.cndcount);
                if (cndt) row.push(_x.cndtcount);
                if (fgts) row.push(_x.fgtscount);
                if (sintegra) row.push(_x.sintegracount);
                if (ctm) row.push(_x.ctmcount);

                finalData.push(row);
            });
            return finalData;
        }

        const showGraphs = () => {
            const bar = refreshBarData(cndData)
            return (
                <Flex alignItems="center" justifyContent="center" position="relative" gap={2} w="100%">
                    <Flex flexDirection="column" w="100%" gap={4}>
                        <Text fontWeight="bold" textAlign="center" color="gray.500">Quantidade de certidões</Text>
                        {
                            !props.isLoading ?
                            (
                                <Chart chartType="Bar" data={bar} options={{ chartArea: { width: "85%", height: "85%" }, legend: { font: 'none' } }} />
                            ) : (
                                <RectShape color='#E0E0E0' style={{ width: 835, height: 200, marginBottom:'5px' }} />
                            )
                        }
                    </Flex>
                </Flex>
            )
        }

        return (
            <WidgetBase title="Gerenciador de Certidões" id={props.id} index={props.index}>
                {showGraphs()}
            </WidgetBase>
        );
    } else {
        return null;
    }
}