import {
  ECompanyStatus,
  EPriority,
} from '../../../../../../_services/enum/irs.enum'
import { Image } from '@chakra-ui/react'
import { IOption } from '../Interface/IEditTask'
import { FilterRow, FilterTypeEnum } from '../../../../../../components/Filters'

export const priorityOptions = [
  { value: EPriority.URGENT, label: 'Urgente', icon: <Image w="22px" h="22px" src="/icons/flags/red.svg" /> },
  { value: EPriority.HIGH, label: 'Alta', icon: <Image w="22px" h="22px" src="/icons/flags/orange.svg" /> },
  { value: EPriority.REGULAR, label: 'Normal', icon: <Image w="22px" h="22px" src="/icons/flags/blue.svg" /> },
  { value: EPriority.LOW, label: 'Baixa', icon: <Image w="22px" h="22px" src="/icons/flags/gray.svg" /> },
];

export const optionsLote: IOption[] = [
  {value: ECompanyStatus.PENDENCY, label:"Pendente"},
  {value: ECompanyStatus.DOING, label:"Fazendo"},
  {value: ECompanyStatus.DONE, label:"Finalizado"}
];

export const optionsLoteObligation: IOption[] = [
  { value: ECompanyStatus.open, label: 'Aberto'},
  { value: ECompanyStatus.rectified, label: 'Retificado'},
  { value: ECompanyStatus.filled, label: 'Preenchido'},
  { value: ECompanyStatus.reviewed, label: 'Revisado'},
  { value: ECompanyStatus.transmitted, label: 'Transmitido'},
  { value: ECompanyStatus.executed, label: 'Executado'},
  { value: ECompanyStatus.inprogress, label: 'Em Andamento'},
  { value: ECompanyStatus.pending, label: 'Pendente'},
  { value: ECompanyStatus.paidout, label: 'Pago'},
  { value: ECompanyStatus.concluded, label: 'Concluído'},
];

export const filterRows = [
  {
    rowFields: [
      {
        name: FilterTypeEnum.companyFilter,
        position: 1,
        size: 6,
      },
      {
        name: FilterTypeEnum.companyGroupFilter,
        position: 2,
        size: 6,
      },
    ],
    position: 1
  }
] as FilterRow[];

export const getTag = (type: string) => {
  const tagTypes: any[] = [
    {
      text: 'Federal',
      value: 'federal',
      textColor: 'var(--colors-cyan-cyan-900)',
      color: 'var(--colors-cyan-cyan-100)',
    },
    {
      text: 'Municipal',
      value: 'municipal',
      textColor: 'var(--colors-lime-lime-900)',
      color: 'var(--colors-lime-lime-100)',
    },
    {
      text: 'Estadual',
      value: 'state',
      textColor: 'var(--colors-purple-purple-900)',
      color: 'var(--colors-purple-purple-100)',
    },
  ];

  return tagTypes.find(tag => tag.value === type);
}