import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, useDisclosure, useToast, Text, Checkbox, ButtonGroup, Button, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IValueLabel } from "./EditCompanyModal";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { FormButton } from "../../../../../components/login/button.component";
import { getCityByState, getStates } from "../../../../../_services/region.service";
import { ICityResponse, IRegionResponse } from "../../../../../_services/interface/region.interface";
import { getCnpjInformation, postAddCompany } from "../../../../../_services/company.service";
import { validate as cnpjValidator } from "cnpj";
import { AddIcon } from "@chakra-ui/icons";
import useLoginFormStore from "../../../../../store/useLoginFormStore";
import { IResponse } from "../../../../../_services/interface/user.interface";
import { clearCnpj } from "../util/cnpj";
import MiniLoading from "../../../../../components/miniLoading"
import { getParamsForLocation } from "../../../../../_services/certificate.service";
import { ICertificateParamsForLocation } from "../../../../../_services/interface/certificate.interface";
import { IClientCustomFields, IClientValuesResponse } from "../../../../../_services/interface/client.interface";
import { checkCollaboratorPermission } from "../../../Users/utils/checkPermission";
import { TaxlyInput } from "../../../../../components/Input";
import SelectCustomField from "../../../../../components/SelectCustomField";
import { getClient } from "../../../../../_services/client.service";
import CustomAsyncSelect from "../../../../../components/CustomAsyncSelect";

interface ICompanyCreateForm {
  name: string;
  cnpj: string;
  stateReg: string;
  municipalReg: string;
  cep: string;
  situation?: string|null;
  type: string;
  state: string;
  city: string;
  companyCode: string;
  isScheduled: boolean;
  searchCnd: boolean;
}

interface IClientSelect {
  value: string;
  label: string;
}

interface ICreateCompanyModalProps {
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  guid_client: string;
}

export const CreateCompanyModal = (props: ICreateCompanyModalProps) => {

  const YupCompanyChangeForm = Yup.object().shape({
    name: Yup.string().required('Nome'),
    cnpj: Yup.string().required('CNPJ'),
    state: Yup.string().required('Estado'),
    city: Yup.string().required('Municipio'),
    situation: Yup.string().nullable(),
    cep: Yup.string().when([], {
      is: () => cepIsRequired(),
      then: Yup.string().required('CEP'),
    }),
    stateReg: Yup.string().when([], {
      is: () => regIsRequired('state'),
      then: Yup.string().required('Inscrição Estadual'),
    }),
    municipalReg: Yup.string().when([], {
      is: () => regIsRequired('municipal'),
      then: Yup.string().required('Inscrição Municipal'),
    }),
    isScheduled: Yup.bool(),
    searchCnd: Yup.bool(),
  });

  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };

  const [field1, setField1] = useState<string>();
  const [field2, setField2] = useState<string>();
  const [field3, setField3] = useState<string>();
  const [customFields, setCustomFields] = useState<IClientCustomFields[]>([]);
  const [field1Guid, setField1Guid] = useState<string>();
  const [field2Guid, setField2Guid] = useState<string>();
  const [field3Guid, setField3Guid] = useState<string>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allStates, setAllStates] = useState<IValueLabel[]>([]);
  const [allCities, setAllCities] = useState<IValueLabel[]>([]);
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [selectedState, setSelectedState] = useState<number>();
  const [isStateChanged, setStateChanged] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isParams, setParams] = useState<ICertificateParamsForLocation | null>(null);
  const { validate } = useLoginFormStore();
  const { formState: { errors }, setValue, register, getValues, watch, handleSubmit, reset } = useForm<ICompanyCreateForm>(resolverForm);
  const toast = useToast();

  const [cnpjIsValid, setCnpjIsValid] = useState<boolean>(true);
  const [searchingCnpjInformation, setSearchingCnpjInformation] = useState<boolean>(false);
  const [searchedCnpjInformation, setSearchedCnpjInformation] = useState<boolean>(false);
  const [errorWhenSearchingCnpjInformation, setErrorWhenSearchingCnpjInformation] = useState<boolean>(false);

  const getMandatoryParams = async () => {
    const { status, response } = await getParamsForLocation(props.guid_client, 'number');
    if (status === 200) {
      setParams(response as ICertificateParamsForLocation);
    }
  }

  const autoFillData = async () => {
    setSearchedCnpjInformation(false);
    setSearchingCnpjInformation(true);
    setErrorWhenSearchingCnpjInformation(false);
    const cnpj = getValues('cnpj');
    if (cnpj && cnpjValidator(cnpj)) {
      const { status, response } = await getCnpjInformation(clearCnpj(cnpj));

      if(status === 200 && response.cnpj) {
        setValue('name', response.name);
        setValue('cep', response.cep);
        setValue('situation', response.situacao_cadastral);
        setValue('type', response.tipo as string === 'MATRIZ' ? '0' : '1');

        const state = allStates.find(item => item.initials === response.uf as string);
        if(state) {
          setValue('state', state.value.toString());

          const municipio = response.municipio as string;
          cityList(state.value, municipio);
        }

        setSearchedCnpjInformation(true);
      } else {
        setErrorWhenSearchingCnpjInformation(true);
      }
    }
    setSearchingCnpjInformation(false);
  }

  useEffect(() => {
    if (getValues('cnpj')) {
      const cnpj = getValues('cnpj').replace(/[^0-9]/g, '');
      if (cnpj.length === 14 && !cnpjValidator(cnpj)) {
        setCnpjIsValid(false);
      }

      if (cnpj.length === 14 && cnpjValidator(cnpj)) {
        setCnpjIsValid(true);
        autoFillData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('cnpj')]);

  const stateList = async () => {
    const { status, response } = await getStates();
    if (status === 200) {
      const organizeStates: IValueLabel[] = [];
      (response as IRegionResponse[]).forEach((_e) => {
        organizeStates.push({
          value: _e.id_state,
          label: _e.name,
          initials: _e.initials
        })
      });

      //Load all the states into a state
      setAllStates(organizeStates);
      //Set the state
      setValue("state", '1');

      //Set the state to indicate if its changed or not
      setSelectedState(1);
      //Load the cities of the state 1 (Acre)
      cityList(1);
    } else {
      toast({
        title: 'Falha ao listar os estados',
        description: `Ocorreu um erro ao tentar listar os estados, por favor, tente novamente.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const cityList = async (id_state: number, municipio: string | null = null) => {
    const { status, response } = await getCityByState(id_state);
    if (status === 200) {
      const orgnizeCities: IValueLabel[] = [];
      (response as ICityResponse).cities.forEach((_x) => {
        orgnizeCities.push({
          value: _x.id_city,
          label: _x.name,
          initials: ""
        });
      });

      setAllCities(orgnizeCities);
      if (municipio) {
        const limparString = (str: string) => {
          str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          str = str.replace(/\s+/g, "");
          str = str.toUpperCase();
          return str;
        }

        const city = orgnizeCities.find(item => limparString(item.label) === limparString(municipio));
        
        if (city) {
          setValue('city', city.value.toString());
        }
      } else {
        setValue('city', orgnizeCities[0].value.toString());
      }
    } else {
      toast({
        title: 'Falha ao listar os estados',
        description: `Ocorreu um erro ao tentar listar as cidades, por favor, tente novamente.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  //Check Form Errors
  useEffect(() => {
    if (errors) {
      setAccErrorMessage(Object.values(errors).map((_x) => _x.message) as string[]);
    }
  }, [errors]);

  useEffect(() => {
    if (isOpen) {
      //Only when the window opens, reset everything
      stateList();
      //Reset all the fields
      reset({ name: '', cnpj: '', cep: '', situation: '', searchCnd: true, isScheduled: false, type: '0' });
      //Reset any error message
      setAccErrorMessage([]);
      setSimpleErrorMessage("");

      getMandatoryParams();
      setCnpjIsValid(true);
      setSearchedCnpjInformation(false);
      setErrorWhenSearchingCnpjInformation(false);
      setSearchingCnpjInformation(false);
      configCustomFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const cepIsRequired = () => {
    const statesParams = isParams?.mandatory.states[watch().state!];
    if (statesParams?.includes('cep')) {
      return true;
    }

    const citiesParams = isParams?.mandatory.cities[watch().city!];
    if (citiesParams?.includes('cep')) {
      return true;
    }
    return false;
  }

  const regIsRequired = (jurisdiction: 'state'|'municipal') => {
    const statesParams = isParams?.mandatory.states[watch().state!];
    const citiesParams = isParams?.mandatory.cities[watch().city!];

    if (jurisdiction === 'state') {
      return statesParams?.includes('stateReg') || citiesParams?.includes('stateReg');
    } else if (jurisdiction === 'municipal') {
      return statesParams?.includes('municipalReg') || citiesParams?.includes('municipalReg');
    }
    return false;
  }

  const configCustomFields = async () => {
    if(!props.guid_client) {
      return;
    }
    const { status, response } = await getClient(props.guid_client);
    if (status === 200) {
      if('clientCustomFields' in response && response.clientCustomFields) {
        setCustomFields(response.clientCustomFields);

        if(response.clientCustomFields[0]) {
          setField1Guid(response.clientCustomFields[0].guid_custom_field);
        }

        if(response.clientCustomFields[1]) {
          setField2Guid(response.clientCustomFields[1].guid_custom_field);
        }

        if(response.clientCustomFields[2]) {
          setField3Guid(response.clientCustomFields[2].guid_custom_field);
        }
      }
    }
  }

  useEffect(() => {
    if (isStateChanged) {
      cityList(isStateChanged)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStateChanged]);

  const handleFormInfo = async (data: ICompanyCreateForm) => {
    setSubmitDisabled(true);
    setIsLoading(true)
    if (cnpjValidator(data.cnpj)) {
      const { status } = await postAddCompany(props.guid_client, {
        name: data.name,
        cnpj: clearCnpj(data.cnpj),
        companyCode: data.companyCode ? data.companyCode : null,
        type: parseInt(data.type),
        searchCnd: data.searchCnd,
        situation: data.situation,
        isScheduled: data.isScheduled,
        cep: data.cep ? data.cep : null,
        stateReg: data.stateReg ? data.stateReg.toString() : null,
        municipalReg: data.municipalReg ? data.municipalReg.toString() : null,
        state: parseInt(data.state),
        city: parseInt(data.city),
        field1: Array.isArray(field1) && field1[0] ? field1[0] : null,
        field2: Array.isArray(field2) && field2[0] ? field2[0] : null,
        field3: Array.isArray(field3) && field3[0] ? field3[0] : null,
        field1Guid: field1Guid,
        field2Guid: field2Guid,
        field3Guid: field3Guid,
      });

      if (status === 200) {
        toast({
          title: 'Empresa criada com sucesso',
          description: `A empresa ${data.name} foi criada com sucesso`,
          status: 'success',
          duration: 5000,
          isClosable: true
        });

        //Reset the entire form
        reset({
          name: '',
          cnpj: '',
          stateReg: '',
          municipalReg: '',
          cep: '',
          state: '',
          city: '',
          situation: '',
        });
        //Refresh the list
        props.flushHook(true);
        //Close the modal
        onClose();
      } else {
        toast({
          title: 'Falha ao criar a empresa',
          description: `A empresa ${data.name} não foi criada, motivo: CNPJ Duplicado.`,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        //Refresh the list
        props.flushHook(true);
        //Close the modal
        onClose();
      }

    } else {
      setSimpleErrorMessage('CNPJ Inválido');
    }
    setIsLoading(false)
    setSubmitDisabled(false);
  };

  return (
    <>
      <ButtonGroup onClick={onOpen} size="sm" isAttached color="white" >
        <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
          Criar empresa
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) ' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF">

          <ModalHeader fontFamily="Poppins-Medium">Cadastro de Empresa</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-Medium">
            {accErrorMessage.length > 0 ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Text>Por favor, informe os campos obrigatórios:</Text>
                {accErrorMessage.map((_x, _i) => (
                  <Flex key={_i}>{_x}</Flex>
                ))}
              </Flex>
            ) : null}
            {simpleErrorMessage ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Flex>{simpleErrorMessage}</Flex>
              </Flex>
            ) : null}
            <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} pb={4}>

              <Flex flexDirection="column">
                <TaxlyInput 
                  placeholder="Digite para preencher" 
                  label={'CNPJ'}
                  isRequired={true}
                  mask="99.999.999/9999-99"
                  isDisabled={searchingCnpjInformation}
                  {...register("cnpj", { required: true })}
                />
                {
                  !cnpjIsValid ? 
                    <>
                      <Flex flexDirection="row" mt="5px">
                        <Image 
                          src="/icons/icon-close-red.svg" 
                          alt="Close Red" 
                          marginRight="3px" 
                          width="16px"
                        />
                        <Text
                          fontSize={12}
                          color="#DC2626"
                        >
                          CNPJ não é valido
                        </Text>
                      </Flex>
                    </>
                  : null
                }

                {
                  searchedCnpjInformation && cnpjIsValid && !searchingCnpjInformation ? 
                    <>
                      <Flex flexDirection="row" mt="5px">
                        <Image 
                          src="/icons/check-green.svg" 
                          alt="Close Red" 
                          marginRight="3px" 
                          width="16px"
                        />
                        <Text
                          fontSize={12}
                          color="#16A34A"
                        >
                          Encontramos as suas informações!
                        </Text>
                      </Flex>
                    </>
                  : null
                }

                {
                  errorWhenSearchingCnpjInformation && cnpjIsValid && !searchingCnpjInformation ? 
                    <>
                      <Flex flexDirection="row" mt="5px">
                        <Image 
                          src="/icons/alert-triangle.svg" 
                          alt="Close Red" 
                          marginRight="3px" 
                          width="16px"
                        />
                        <Text
                          fontSize={12}
                          color="#F97316"
                        >
                          Tivemos um problema ao buscar as informações. Favor, preencha os dados manualmente.
                        </Text>
                      </Flex>
                    </>
                  : null
                }

                {
                  searchingCnpjInformation && cnpjIsValid ? 
                    <>
                      <Flex flexDirection="row" mt="5px">
                        <Image 
                          className="rotate-forever"
                          src="/icons/loading-blue.svg" 
                          alt="Close Red" 
                          marginRight="3px" 
                          width="16px"
                        />
                        <Text
                          fontSize={12}
                          color="#4B4EFF"
                        >
                          Estamos pesquisando as informações do CNPJ informado.
                        </Text>
                      </Flex>
                    </>
                  : null
                }
              </Flex>

              <Flex mt="10px">
                <TaxlyInput 
                  placeholder="Razão social" 
                  label={'Razão social'}
                  isRequired={true}
                  isDisabled={!searchedCnpjInformation && !errorWhenSearchingCnpjInformation}
                  {...register("name", { required: true })}
                />
              </Flex>

              <Flex direction="row" gap={2} flexGrow={1} mt="10px">
                <Flex width="25%" direction="column">
                  <Flex
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    fontSize="12px"
                  >
                    Estado
                    <span style={{ color: 'red' }}>*</span>
                  </Flex>

                  <CustomAsyncSelect
                    placeholder="Selecione o estado"
                    options={allStates}
                    setValue={(value: string | number) => {
                      setValue('state', String(value));
                      setStateChanged(parseInt(String(value)));
                    }}
                    value={getValues('state')}
                    isDisabled={!searchedCnpjInformation && !errorWhenSearchingCnpjInformation}
                  />
                </Flex>

                <Flex width="25%" direction="column">
                  <Flex
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    fontSize="12px"
                  >
                    Cidade
                    <span style={{ color: 'red' }}>*</span>
                  </Flex>

                  <CustomAsyncSelect
                    placeholder="Selecione a cidade"
                    options={allCities}
                    setValue={(value: string | number) => {
                      setValue('city', String(value));
                    }}
                    value={getValues('city')}
                    isDisabled={!searchedCnpjInformation && !errorWhenSearchingCnpjInformation}
                  />
                </Flex>

                <Flex width="25%" direction="column">
                  <Flex
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    fontSize="12px"
                  >
                    Tipo
                    <span style={{ color: 'red' }}>*</span>
                  </Flex>

                  <Select 
                    variant="outline" 
                    borderRadius="4px" 
                    border="1px solid #E2E8F0" 
                    fontSize="12px" 
                    _disabled={{ bg: '#E2E8F0', color: '#CBD5E0'}}
                    {...register("type")}
                    isDisabled={!searchedCnpjInformation && !errorWhenSearchingCnpjInformation}
                  >
                    <option value="0">Matriz</option>
                    <option value="1">Filial</option>
                  </Select>
                </Flex>

                <Flex width="25%" direction="column">
                  <Flex
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    fontSize="12px"
                  >
                    Situação
                  </Flex>

                  <Select 
                    variant="outline" 
                    borderRadius="4px" 
                    border="1px solid #E2E8F0" 
                    fontSize="12px" 
                    _disabled={{ bg: '#E2E8F0', color: '#CBD5E0'}}
                    {...register("situation")}
                    isDisabled={true}
                  >
                    <option value="ATIVA">Ativa</option>
                    <option value="SUSPENSA">Suspensa</option>
                    <option value="INAPTA">Inapta</option>
                    <option value="BAIXADA">Baixada</option>
                    <option value="NULA">Nula</option>
                  </Select>
                </Flex>
              </Flex>

              <Flex direction="row" gap={2} flexGrow={1} mt="10px">
                <Flex width="25%">
                  <TaxlyInput 
                    placeholder="CEP" 
                    label={'CEP'}
                    mask="99.999-999"
                    {...register("cep")}
                    isDisabled={!searchedCnpjInformation && !errorWhenSearchingCnpjInformation}
                  />
                </Flex>

                <Flex width="25%">
                  <TaxlyInput 
                    placeholder="Inscrição Estadual" 
                    label={'Inscrição Estadual'}
                    {...register("stateReg")}
                  />
                </Flex>

                <Flex width="25%">
                  <TaxlyInput 
                    placeholder="Inscrição Municipal" 
                    label={'Inscrição Municipal'}
                    {...register("municipalReg")}
                  />
                </Flex>

                <Flex width="25%">
                  {/* <Tooltip label="Insira aqui o código interno ou do seu sistema de gestão para facilitar localizar o CNPJ pesquisado"> */}
                    <TaxlyInput 
                      placeholder="Código da Empresa" 
                      label={'Código da Empresa'}
                      {...register("companyCode")}
                    />
                  {/* </Tooltip> */}
                </Flex>
              </Flex>

              {
                customFields.length > 0
                && customFields.some((field) => field.isActive)
                  ? (
                    <Flex mt="10px">
                      <Text
                        color="#171923"
                        fontSize="12px"
                        fontFamily="Poppins-Medium"
                      >
                        Campos personalizados
                      </Text>
                    </Flex>
                  ) : null
              }

              <Flex direction="row" gap={2} flexGrow={1} mt="10px">

                {
                  customFields[0] && customFields[0].isActive &&
                    <Flex width="33%">
                      <SelectCustomField
                        setSelectedValue={setField1}
                        selectedValue={field1}
                        customField={customFields[0]}
                        guid_client={props.guid_client}
                        showLabel={true}
                      />
                    </Flex>
                }

                {
                  customFields[1] && customFields[1].isActive &&
                    <Flex width="33%">
                      <SelectCustomField
                        setSelectedValue={setField2}
                        selectedValue={field2}
                        customField={customFields[1]}
                        guid_client={props.guid_client}
                        showLabel={true}
                      />
                    </Flex>
                }

                {
                  customFields[2] && customFields[2].isActive &&
                    <Flex width="33%">
                      <SelectCustomField
                        setSelectedValue={setField3}
                        selectedValue={field3}
                        customField={customFields[2]}
                        guid_client={props.guid_client}
                        showLabel={true}
                      />
                    </Flex>
                }
              </Flex>

              {checkCollaboratorPermission(validate as IResponse, props.guid_client, 'cnd.write') ? (
                <Flex direction="column" gap={2} flexGrow={1} mt={4}>
                  <Checkbox defaultChecked size='sm' colorScheme='green' {...register("searchCnd")}>
                    <Text fontSize={14}>Pesquisar Certidões ao terminar o cadastro</Text>
                    <Text fontSize="11px" opacity={0.5}>
                      Ao selecionar esta opção, o sistema irá realizar a busca das Certidões desta empresa ao término deste cadastro.
                    </Text>
                  </Checkbox>

                  <Checkbox defaultChecked size='sm' colorScheme='green' {...register("isScheduled")}>
                    <Text fontSize={14}>Agendar pesquisa recorrente das Certidões</Text>
                    <Text fontSize="11px" opacity={0.5}>
                      Ao selecionar esta opção, o sistema irá programar a atualização recorrente das Certidões desta empresa.
                    </Text>
                  </Checkbox>
                </Flex>
              ) : null}


              <Flex alignItems="stretch" direction="row" justifyContent="flex-end" gap={2} flexGrow={1} mt={4}>
                <FormButton 
                  color="white" 
                  onClick={onClose} 
                  textColor="#4B4EFF"
                  border="1px solid #4B4EFF"
                >
                  Fechar
                </FormButton>

                <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>
                  {isLoading ? <MiniLoading size={20} /> : 'Cadastrar empresa'}
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
}