import { Button, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface ILogoutModalProps {
  st: 'complete' | 'simple'
}

export const LogoutModal = (props: ILogoutModalProps) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      {props.st === 'complete' ? 
        (
          <Flex gap="8px" alignItems="center" onClick={onOpen} _hover={{ cursor: "pointer" }}>
            <Image w="22px" h="16px" src="/icons/logout.svg" />
            <Text fontSize="12px">sair</Text>
          </Flex>
        ) : (
          <Tooltip label="Sair da Conta">
            <Flex  justifyContent="center" onClick={onOpen} _hover={{ cursor: "pointer" }}>
              <Image w="22px" h="16px" src="/icons/logout.svg" />
            </Flex>
          </Tooltip>
        )}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Sair da Conta</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              Deseja sair de sua conta?

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <Button fontSize={12} width="50%" bg="#4B4EFF" onClick={() => navigate('/logout')} color="white" _hover={{ bgColor: "#BABBFF", cursor: "pointer" }}>Sim</Button>
                <Button fontSize={12} width="50%" bg="#4B4EFF" onClick={onClose} color="white" _hover={{ bgColor: "#BABBFF", cursor: "pointer" }}>Não</Button>
              </Flex>

            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}