import { Text } from "@chakra-ui/react";
import { useState } from "react";
import { default as ReactSelect, components, InputAction } from "react-select";

export type Option = {
  value: number | string;
  label: string;
};

const SingleSelect = (props: any) => {
  const [selectInput, setSelectInput] = useState<string>("");

  const customFilterOption = ({ label }: Option, input: string) =>
    label.toLowerCase().includes(input.toLowerCase());

  const onInputChange = (
    inputValue: string,
    event: { action: InputAction }
  ) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const handleChange = (selected: Option[]) => {
    props.onChange(selected.length > 0 ? [selected[selected.length - 1]] : []);
  };

  const customStyles = {
    multiValue: (def: any) => ({
      ...def,
      borderRadius: "15px",
      backgroundColor: "var(--primary-100)",
    }),
    multiValueLabel: (def: any) => ({
      ...def,
      fontSize: "70%",
      color: "var(--primary-500)",
      borderRadius: "15px",
    }),
    multiValueRemove: (def: any) => ({
      ...def,
      color: "var(--primary-500)",
      borderRadius: "0 15px 15px 0",
      borderLeft: "1px solid",
      borderColor: "var(--primary-200)",
    }),
    noOptionsMessage: (def: []) => ({
      ...def,
      fontSize: "12px",
    }),
    valueContainer: (base: any) => ({
      ...base,
      maxHeight: "65px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#bdbec196",
        borderRadius: "20px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#a5a9b7",
        borderRadius: "20px",
      },
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: "11px",
    }),
    input: (base: any) => ({
      ...base,
      fontSize: "14px",
    }),
    option: (styles: any, { isSelected, isFocused }: any) => {
      return {
        ...styles,
        fontSize: "12px",
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
            ? styles.backgroundColor
            : isFocused && isSelected
            ? "#DEEBFF"
            : null,
        color: isSelected ? null : null,
      };
    },
    menu: (def: any) => ({ ...def, zIndex: 9999 }),
    menuList: (def: any) => {
      return {
        ...def,
        maxHeight: "200px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#bdbec196",
          borderRadius: "20px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#a5a9b7",
          borderRadius: "20px",
        },
      };
    },
  };

  const textQtd = (props: any) => {
    return (
      <Text
        style={{
          paddingLeft: "5px",
          fontWeight: "500",
          fontSize: "14px",
          color: "var(--black-gray-gray-500)",
        }}
      >
        {props.value?.length ? "(1)" : "(0)"}
      </Text>
    );
  };

  return (
    <>
      {props.label && (
        <Text
          color="#171923"
          fontFamily="Poppins-medium"
          fontSize="14px"
          fontWeight={500}
          paddingBottom="4px"
          display={"flex"}
        >
          {props.label}
          {textQtd(props)}
        </Text>
      )}
      <ReactSelect
        {...props}
        inputValue={selectInput}
        onInputChange={onInputChange}
        filterOption={customFilterOption}
        onChange={handleChange}
        components={{
          ...props.components,
        }}
        styles={customStyles}
        isMulti
        menuPlacement={props.menuPlacement ?? "auto"}
        noOptionsMessage={() => "Nenhuma opção"}
        placeholder="Digite para buscar"
      />
    </>
  );
};

export default SingleSelect;
