import { IValueLabel } from "../pages/dashboard/Companies/Company/modals/EditCompanyModal";
import { ICityResponse, IRegionResponse } from "../_services/interface/region.interface";
import { getCityByState, getStates } from "../_services/region.service";

export const cityList = async (id_state: number) => {
  const { status, response } = await getCityByState(id_state);
  if (status === 200) {
    const cities: IValueLabel[] = [];
    (response as ICityResponse).cities.forEach((_x) => {
      cities.push({
        value: _x.id_city,
        label: _x.name,
        initials: ""
      });
    });

    return cities;
  }

  return [];
}

export const stateList = async () => {
  const { status, response } = await getStates();

  if (status === 200) {
    const states: IValueLabel[] = [];
    (response as IRegionResponse[]).forEach((_e) => {
      states.push({
        value: _e.id_state,
        label: _e.name,
        initials: _e.initials
      })
    });

    return states;
  }

  return [];
}