import { Button, ButtonGroup, Checkbox, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { EToastStatus } from "../../../../components/ToastEnum";
import { downloadAllCnds, exportCnds } from "../../../../_services/cnd.service";
import { ICNDListData } from "../../../../_services/interface/cnd.interface";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { formatCnpj } from "../../Companies/Company/util/cnpj";
import { sendBulkEmail } from "../../../../_services/email.service";
import html2canvas from 'html2canvas';
import * as ExcelJS from 'exceljs';
import SelectMultiEmail from "../../../../components/SelectMultiEmail";
import { getCompanyPossibleEmails } from "../../../../_services/company.service";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { update } from "lodash";
import LoadingComponent from "../../../../components/loading";
import { formatCpf, formatDocument } from "../../Persona/utils/cpf";
import { useJune } from "../../../../components/June";


interface IMultiCndDownloadProps {
  guid_client: string;
  cndData: () => Promise<ICNDListData[]>;
  certificateName: string;
}

interface IMultiCndDownloadForm {
  exportCertificates: boolean;
  exportCertificatesListXls: boolean;
  exportCertificatesListCSV: boolean;
  exportGraphic: boolean;
}

export const MultiCndDownload = (props: IMultiCndDownloadProps) => {
  const analytics = useJune();

  const YupCompanyChangeForm = Yup.object().shape({
    exportCertificates: Yup.bool(),
    exportCertificatesListXls: Yup.bool(),
    exportCertificatesListCSV: Yup.bool(),
    exportGraphic: Yup.bool(),
  });

  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cndData, setCndData] = useState<ICNDListData[]>([]);
  const [cndDataFiltered, setCndDataFiltered] = useState<ICNDListData[]>([]);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);
  // eslint-disable-next-line
  const [emails, setEmails] = useState<string[]>([])
  const [sendByEmail, setSendByEmail] = useState<boolean>(false)
  const [ignoreError, setIgnoreError] = useState<boolean>(true)
  const { register, watch, handleSubmit, reset } = useForm<IMultiCndDownloadForm>(resolverForm);
  const { validate } = useLoginFormStore();

  const updateCndData = async () => {
    const cndData = await props.cndData();
    setCndData(cndData);
    setLoadingData(false);
  }

  useEffect(() => {
    if (isOpen) {
      setLoadingData(true);
      updateCndData();
    }
  }, [isOpen]);

  useEffect(() => {
    reset({
      exportCertificates: false,
      exportCertificatesListXls: false,
      exportCertificatesListCSV: false,
      exportGraphic: false
    });

    if (cndData.length > 0) {
      const cndDataFiltered: ICNDListData[] = [];

      cndData.forEach((_cnd) => {
        const status = _cnd.status;
        if (status?.show_file && _cnd.slug_status !== 'error' && _cnd.guid_file) {
          cndDataFiltered.push(_cnd);
        }
      });

      setCndDataFiltered(cndDataFiltered);
    }

    setCndData(cndData);
    // eslint-disable-next-line
  }, [ cndData ])

  const handleFormInfo = async (data: IMultiCndDownloadForm) => {
    setLoadingStatus(true);

    const toastLoading = toast({
      title: "Processando a Requisição.",
      description: "Aguarde o processamento da requisição, dependendo da quantidade de arquivos, pode demorar alguns segundos.",
      status: EToastStatus.LOADING,
      duration: null,
    });

    const emailLinks : string[] = [];

    if (analytics && (data.exportCertificatesListCSV || data.exportCertificatesListXls)) {
      analytics.group(props.guid_client);
      analytics.track('Exportar relatórios (XLS; CSV)', {
        xlsx: data.exportCertificatesListXls,
        csv: data.exportCertificatesListCSV,
        emails: emails.join(',')
      });
    }

    if (data.exportCertificates) {
      const response = await downloadAllCnds(props.guid_client, {
        guid_cnds: cndDataFiltered.map((x) => x.guid_cnd),
      });

      const link = document.createElement('a');
      link.href = response;
      link.setAttribute('download', `${moment.utc().format('MMM-DD-YYYY-X')}.zip`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(response);

      emailLinks.push(link.href);
    }

    try {
      if(data.exportCertificatesListXls || data.exportCertificatesListCSV) {
        const response = await exportCnds(props.guid_client, {
          guid_cnds: cndData.map((x) => x.guid_cnd),
          xlsx: data.exportCertificatesListXls,
          csv: data.exportCertificatesListCSV,
          zip: data.exportCertificates,
          ignoreErrors: ignoreError,
          emails: emails && emails.length > 0 && sendByEmail ? emails : [],
          certificateName: props.certificateName
        });

        if (response) {
          const downloadFile = (url: string, fileName: string) => {
            return new Promise((resolve, reject) => {
              if (url) {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setTimeout(resolve, 2000);
              } else {
                reject(new Error('Invalid URL'));
              }
            });
          };
        
          const downloadFilesSequentially = async () => {
            try {
              if ('urlXlsx' in response && response.urlXlsx) {
                const xslxFileName = `Taxly - ${props.certificateName} - ${moment.utc().format('DD_MM_YYYY')}.xlsx`;
                await downloadFile(response.urlXlsx as string, xslxFileName);
              }
        
              if ('urlCsv' in response && response.urlCsv) {
                const csvFileName = `Taxly CSV - ${props.certificateName} - ${moment.utc().format('DD_MM_YYYY')}.csv`;
                await downloadFile(response.urlCsv as string, csvFileName);
              }
            } catch (error) {
              console.error('Error downloading file:', error);
            }
          };
        
          downloadFilesSequentially();
        }        
      }
    } catch (error) {
      setLoadingStatus(false);
    }

    if (emails && emails.length > 0 && sendByEmail) {
      const searchUserData = async (emails: any) => {
        const emailList = [];

        for (const user of emails) {
          emailList.push(user);
        }

        return emailList.join();
      };

      const mappedEmails = await searchUserData(emails);
      await sendBulkEmail(
        {
          guid_client: props.guid_client,
          mailtype: 'reports',
          list: mappedEmails,
          data: {},
          links: emailLinks.join(','),
          attachments: [],
        }
      );
    }

    onClose();
    toast.close(toastLoading)
    setLoadingStatus(false);
  }

  const disabledButton = () => {
    const { exportCertificates, exportCertificatesListXls, exportCertificatesListCSV, exportGraphic } = watch();

    if (cndData.length > 0 && !isLoading && (exportCertificates || exportGraphic || exportCertificatesListXls || exportCertificatesListCSV)) {
      return false;
    }

    return true;
  }

  const getEmailsOptions = async (): Promise<string[]> => {
    let localEmails: string[] = [];
    const { status, response } = await getCompanyPossibleEmails(props.guid_client);
    if (status === 200 && Array.isArray(response)) {
      setEmails(response as string[]);
      localEmails = response as string[];
    }

    return localEmails;
  }

  const getSelectedEmails = async (): Promise<string[]> => {
    if(validate) {
      return [validate.email];
    }

    return [];
  }

  return (
    <Flex>
      <ButtonGroup onClick={onOpen} size="sm" mt="24px" isAttached color="white" >
        <Button color="white" bgColor="#4B4EFF" _hover={{ bg: '#282be0' }}>
          <Flex display="flex" justifyContent="center" alignItems="center">
            <Image height="15px" width="15px" marginRight="5px" src="/icons/download_icon.png" resize="none" />
            Exportar informações
          </Flex>
        </Button>
      </ButtonGroup>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="4xl" >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF" maxWidth={800}>
          <ModalHeader fontFamily="Poppins-Medium">Exportar arquivos</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-Medium">
            {!isLoadingData ? (
              <Flex direction="column" fontSize="12px" gap={2} mb={4}>
                <Text>Selecione abaixo os arquivos que deseja exportar (considerando a visualização atual do sistema):</Text>
                <small>Relatórios serão gerados considerando a visualização e filtros aplicados</small>

                <Text marginTop="15px" fontSize="14px">Exportar relatório geral de certidões:</Text>

                <Checkbox
                  size="sm"
                  colorScheme="green"
                  {...register("exportCertificatesListXls")}>XLS
                </Checkbox>

                <Checkbox
                  size="sm"
                  colorScheme="green"
                  {...register("exportCertificatesListCSV")}>CSV
                </Checkbox>

                <Checkbox
                    marginTop="15px"
                    size="sm"
                    colorScheme="green"
                    {...register("exportCertificates")}
                >
                  Exportar certidões (arquivo ZIP)
                </Checkbox>
                <small>Total de certidões a serem baixadas: <strong>{cndDataFiltered.length}</strong></small>

                <Flex align="start" mt={6} _hover={{ cursor: "pointer" }} gap={3} onClick={() => setIgnoreError(!ignoreError)}>
                  {ignoreError ? (
                    <Image w="34px" src="/icons/switch-blue.svg" />
                  ) : (
                    <Image w="34px" src="/icons/switch-gray.svg" />
                  )}
                  <Flex direction="column">
                    <Text fontSize="12px">
                      Ignorar registros com erro, buscar ultima certidão sem erro (XLSX e CSV).
                    </Text>
                  </Flex>
                </Flex>

                <Flex align="start" mt={6} _hover={{ cursor: "pointer" }} gap={3} onClick={() => setSendByEmail(!sendByEmail)}>
                  {sendByEmail ? (
                    <Image w="34px" src="/icons/switch-blue.svg" />
                  ) : (
                    <Image w="34px" src="/icons/switch-gray.svg" />
                  )}
                  <Flex direction="column">
                    <Text fontSize="12px">
                    Gostaria de compartilhar por e-mail? (opcional)
                    </Text>
                  </Flex>
                </Flex>

                {sendByEmail && (
                    <Flex direction="column">
                      <Text opacity={0.9} mb={2} mt={2}>
                        Digite o(s) email(s) de quem irá receber a exportação (opcional)
                      </Text>
                      <SelectMultiEmail
                          onChange={(_emails: string[]) => {
                            setEmails(_emails);
                          }}
                          getEmailsOptions={getEmailsOptions}
                          getSelectedEmails={getSelectedEmails}
                      />
                    </Flex>
                )}



                <FormButton disabled={disabledButton() ? true : false} onClick={handleSubmit(handleFormInfo)}>Baixar arquivos</FormButton>
              </Flex>
            ) : (<LoadingComponent />)}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}