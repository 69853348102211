import { useEffect, useState } from "react";
import { IFiles } from "../../interfaces/IFiles";
import { FilterDataSelected, FilterRow, FilterTypeEnum, Filters } from "../../../../../components/Filters";

interface IFiltersFile {
  guid_client: string;
  allFiles: IFiles[];
  setFilteredFiles: any;
}

export const FiltersFile = (props: IFiltersFile) => {
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  useEffect(() => {
      //Send the data to be organized
      OrganizeData(props.allFiles);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allFiles, filterDataSelected]);

  //Organize the data in a way that the system can read
  const OrganizeData = (data: IFiles[]) => {
      let readableData = [...data];
      if (filterDataSelected) {
        if (filterDataSelected[FilterTypeEnum.kanbanFileFilter].length > 0) {
          readableData = readableData.filter((_c) => _c.files.some((r) => filterDataSelected[FilterTypeEnum.kanbanFileFilter].includes(r.guid_file)));
        }

        if (filterDataSelected[FilterTypeEnum.companyFilter].length > 0) {
          readableData = readableData.filter((_c) => filterDataSelected[FilterTypeEnum.companyFilter].includes(_c.company.guid_company));
        }
      }

      // Now the system organize by the data
      props.setFilteredFiles(readableData);
  }

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.kanbanFileFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyFilter,
          position: 2,
          size: 6,
        },
      ],
      position: 1
    },
  ] as FilterRow[];
  

  return (
      <>
        <Filters
          filters={filterRows}
          selectedValuesHook={setFilterDataSelected}
        />
      </>
  );
}

