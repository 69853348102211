
import styled from 'styled-components'

export const RetrieveCertificatesStyle = styled.div`
    .title {
        color: var(--black-gray-black-900);
        font-family: 'Poppins-Medium';
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }

    .modal-overlay {
        background: 'blackAlpha.300';
        backdrop-filter: 'blur(10px)';
        align-items: 'center';
    }
    

    .modal-content {
        flex-grow: '1';
        flex-shrink: '1'; 
        border-radius: '8';
        border-left: 12px solid #0263FF;

        header {
            padding: 30px 24px !important;

            .description {
                color: #A4A4A4;
                font-family: Poppins-Medium;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                display: flex;
                flex-direction: column;

                .description-text {
                    padding: 12px 0;
                }

                .blue-text {
                    color: var(--primary-500) !important;
                    line-height: 140%;
                }

                .limit-box {
                    display: flex;
                    padding: 8px;
                    align-items: center;
                    align-self: stretch;

                    border-radius: 6px;
                    background: var(--black-gray-gray-100, #EDF2F7);

                    .limit-message {
                        color: var(--black-gray-black-700);
                        font-family: Poppins-Medium;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                    }
                }
            }

            .text-filter {
                font-family: 'Poppins-Medium';
                color: var(--black-gray-black-900);
                font-size: 12px;
                font-weight: 500;
                line-height: 140%;
            }
        }
    }
`